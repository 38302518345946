import React, { StyleHTMLAttributes, useEffect, useRef } from "react";
import clsx from "clsx";

import "./style.css";

interface SplideProps {
  children: React.ReactNode;
  className?: string;
  options?: {
    paddingRight: string;
    gap: number;
    itemsToShow: number;
  };
}

const Splide: React.FC<SplideProps> = ({
  children,
  className,
  options: customOptions,
}) => {
  const slider = useRef<HTMLDivElement>(null);
  let isPress = useRef(false);
  let startX = useRef(0);
  let scrollLeft = useRef(0);

  useEffect(() => {
    if (slider.current) {
      slider.current.addEventListener("mousedown", handleMouseDown);
      slider.current.addEventListener("mouseleave", handleMouseLeave);
      slider.current.addEventListener("mouseup", handleMouseUp);
      slider.current.addEventListener("mousemove", handleMouseMove);

      return () => {
        slider.current?.removeEventListener("mousedown", handleMouseDown);
        slider.current?.removeEventListener("mouseleave", handleMouseLeave);
        slider.current?.removeEventListener("mouseup", handleMouseUp);
        slider.current?.removeEventListener("mousemove", handleMouseMove);
      };
    }
  }, []);

  const handleMouseDown = (event: MouseEvent) => {
    isPress.current = true;

    if (slider.current) {
      startX.current = event.pageX - slider.current.offsetLeft;
      scrollLeft.current = slider.current.scrollLeft;
    }
  };

  const handleMouseLeave = () => {
    isPress.current = false;
  };

  const handleMouseUp = () => {
    isPress.current = false;
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (!isPress.current) return;
    event.preventDefault();

    if (slider.current) {
      const x = event.pageX - slider.current.offsetLeft;
      const walk = x - startX.current;

      slider.current.scrollLeft = scrollLeft.current - walk;
    }
  };

  // Override default options with custom options.
  const options = {
    paddingRight: "150px",
    gap: 0,
    itemsToShow: 4,
    ...customOptions,
  };

  const style = {
    "--splide-items-to-show": options.itemsToShow,
    "--splide-gap-spaces": options.itemsToShow - 1,
    "--splide-gap": `${options.gap}px`,
    "--splide-padding-right": options.paddingRight,
  } as StyleHTMLAttributes<HTMLDivElement>;

  const dynamicClasses = {
    "co-splide--equal-items": Array.isArray(children)
      ? options.itemsToShow === children?.length
      : false,
  };

  return (
    <div className={clsx("co-splide", dynamicClasses)} style={style}>
      <div className={clsx("co-splide__content", className)} ref={slider}>
        {children}
      </div>
    </div>
  );
};

export default Splide;
