const FORM_STORAGE = "quotation.form_storage";

function setFormStorage(data) {
  sessionStorage.setItem(FORM_STORAGE, JSON.stringify(data));
}

function updateFormStorage(data) {
  const prevData = getFormStorage();
  setFormStorage({ ...prevData, ...data });
}

function getFormStorage() {
  const data = sessionStorage.getItem(FORM_STORAGE) ?? "{}";
  return JSON.parse(data);
}

function cleanFormStorage() {
  sessionStorage.removeItem(FORM_STORAGE);
}

export default {
  set: setFormStorage,
  update: updateFormStorage,
  get: getFormStorage,
  clean: cleanFormStorage,
};
