import virtualAdviceIcon from '../../../assets/icons/virtual-advice.svg'
import whatsappIcon from '../../../assets/icons/whatsapp.svg'
import skypeIcon from '../../../assets/icons/skype.svg'

export default [
  //{
  //  icon: virtualAdviceIcon,
  //  tag: 'Agendar una',
  //  label: 'asesoría virtual',
  //  path: 'https://calendarios.vecindariosuite.com/proyecto/coninsa-desde-el-exterior/agendar',
  //},
  {
    icon: whatsappIcon,
    tag: 'Escríbenos a través',
    label: 'de whatsapp',
    path: 'https://api.whatsapp.com/send?phone=573123636333&text=Hola!',
  },

  {
    icon: skypeIcon,
    tag: 'Llámanos desde',
    label: 'cualquier lugar',
    path: 'tel: +1 352 583 7943',
  },
]
