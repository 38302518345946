import React from 'react'
import { Box, Button } from 'theme-ui'

import { Icon } from 'ui'

function File({ value: { filename, url }, onRemove }) {
  return (
    <Box
      __css={{
        display: 'flex',
        alignItems: 'space-between',
        py: 'small',
        px: 'xsmall',
      }}
    >
      <Box mr='small'>
        <Icon name='fa-reg-file' mr='xxsmall' />
        <a href={url} target='_blank' rel='noreferrer'>
          {filename}
        </a>
      </Box>

      <Button
        type='button'
        sx={{ fontSize: 1, px: 'small', py: 'xsmall' }}
        onClick={onRemove}
      >
        Eliminar
      </Button>
    </Box>
  )
}

export default File
