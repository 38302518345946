import React from 'react'
import { navigate } from 'gatsby'
import { useForm } from 'react-hook-form'
import { useMutation, useApolloClient } from '@apollo/client'
import { Box } from 'theme-ui'

import { Heading } from 'ui'
import {
  getCotenantBasePath,
  updatePersonCotenants
} from 'modules/digital-studio/settings'

import {
  CREATE_NODE_LEGAL_PERSON_COTENANT,
  UPDATE_NODE_LEGAL_PERSON_COTENANT
} from '../../mutations'

import { LEGAL_COTENANT_APPLICATION } from '../../queries'

import BasicGeneralInformation from '../generic/BasicGeneralInformation'
import BasicTaxInformation from '../generic/BasicTaxInformation'
import BasicTributaryInformation from '../generic/BasicTributaryInformation'
import BasicLegalRepresentative from '../generic/BasicLegalRepresentative'
import BasicCustomer from '../generic/BasicCustomer'
import HeaderForm from '../../components/HeaderForm'
import FooterForm from '../../components/FooterForm'
import { getEntity, cleanData } from '../utils'

import { getQueryByBundleType } from '../co-natural/1-personal-infomation'

const initialValues = {
  title: 'temporary',
  fieldIsCustomer: '0',
  fieldSalesResponsible: 'comun'
}

function GeneralInformation(props) {
  const client = useApolloClient()

  const loadedCotenant = props?.loadedCotenant || {}
  const [createMutation] = useMutation(CREATE_NODE_LEGAL_PERSON_COTENANT)
  const [updateMutation] = useMutation(UPDATE_NODE_LEGAL_PERSON_COTENANT, {
    refetchQueries: [
      { query: LEGAL_COTENANT_APPLICATION, variables: { id: props.cid } }
    ]
  })

  const { register, handleSubmit, control, setValue } = useForm({
    defaultValues: {
      ...initialValues,
      ...loadedCotenant
    }
  })

  const onSubmit = async data => {
    const mutation = data?.id ? updateMutation : createMutation
    const variables = cleanData(data)

    return mutation({ variables })
      .then(response => {
        const entity = getEntity(response)

        if (entity) {
          const cacheQuery = getQueryByBundleType(props.bundle)
          const person = client.readQuery({
            query: cacheQuery,
            variables: { id: props.id }
          })

          const updatedCotenants = updatePersonCotenants(person, entity)

          client.cache.updateQuery(
            { query: cacheQuery, variables: { id: props.id } },
            data => ({ node: { ...data.node, fieldCotenants: updatedCotenants } })
          )

          const redirectTo = getCotenantBasePath(
            props.bundle,
            props.id,
            props.cotenant,
            entity.id
          )

          navigate(`${redirectTo}?path=/informacion-accionistas`)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <section>
      <HeaderForm step='1/4' title='Información General' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <BasicGeneralInformation register={register} />

        <Box mb='medium'>
          <Heading level='3' variant='form'>
            Información fiscal y de contacto
          </Heading>
          <BasicTaxInformation
            register={register}
            control={control}
            setValue={setValue}
          />
        </Box>

        {/* --- Temporarily hidden the tributary information section. ---
          <Box mb='medium'>
            <Heading level='3' variant='form'>
              Información Tributaria
            </Heading>
            <BasicTributaryInformation register={register} />
          </Box>
        */}

        <Box mb='medium'>
          <Heading level='3' variant='form'>
            Representante Legal
          </Heading>
          <BasicLegalRepresentative
            register={register}
            control={control}
            setValue={setValue}
          />
        </Box>

        <Box mb='medium'>
          <Heading level='3' variant='form'>
            Cliente Coninsa
          </Heading>
          <BasicCustomer register={register} />
        </Box>

        <FooterForm />
      </form>
    </section>
  )
}

export default GeneralInformation
