import React from "react";
import Layout from "../../../layout/default/Layout";
import { Router, navigate } from "@reach/router";

import { LeasesWithoutCoTenantPage } from "@coninsa-site/terms-and-conditions/pages/general";


function LeasesWithoutCoTenant() {
  return (
    <Layout>
      <Router basepath="/terminos-y-condiciones/generales/arrendamientos-sin-coarrendatario">
        <LeasesWithoutCoTenantPage path="*" navigate={navigate} />
      </Router>
    </Layout>
  );
}

export default LeasesWithoutCoTenant;
