import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import axios from 'axios'
import { Box, Input } from 'theme-ui'
import Toast from 'ui/Toast'
import { Button } from 'ui'
import { Field, InputMask } from 'ui/form'

const IntegrateZohoForm = ({convenio}) => {
  const Title = {
    h3: {
      color: '#00993B',
    },
  }
  const button = {
    bg: '#005FE2',
    color: 'white',
    width: '100%',
  }

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    register,
    reset,
  } = useForm({})

  const [showingToast, showToast] = useState(false)
    const list = [
      {
        id: 's1',
        title: 'Gracias!',
        description: 'Su información fue enviada con éxito.',
        backgroundColor: '#85bb25',
        icon: 'success',
      },
    ]

  // En data recibes toda la informacion del formulario.
  const onSubmit = async data => {
    const url = 'https://api.coninsa.co/api/v2/zohocrm-create-lead'

    const zohoInfo = {
      'data': [ 
        {
          First_Name: data.name,
          Last_Name: data.last_name,
          Owner: '2528071000218477940',
          Email: data.email,
          Mobile: data.phone,
          Regional: 'Medellin',
          Origen_de_Informaci_n: 'Digital',
          Medio_Publicitario: 'Portal Convenios',
          Creador_API: "www.coninsa.co",
          Estrategia_de_Campa_a:'',
          Tipo_de_Canal: 'Portal Convenios',
          Tipo_de_Contacto: 'Otros',
          Convenio: convenio,
          Layout: { id: '2528071000000091055' }
        }
      ]
    }
    
    return new Promise((resolve, reject) => {
      // Depende si necesites usar un GET o POST
      axios
        .post(url, zohoInfo)
        .then(response => {
          // Aqui escribes si el response es satisfactorio.
          reset()
          showToast(true)
          resolve(response)
          window.open('https://www.coninsa.co/gracias')
        })
        .catch(error => {
          console.error(error.message)
          reject(error)
        })
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {showingToast && (
        <Toast
          toastList={list}
          autoDelete
          autoDeleteTime='3000'
          position='bottom-right'
        />
      )}
      <Box sx={Title}>
        <h3>¡Obtén este descuento!</h3>
        <p>
          Déjanos tus datos para que un asesor comercial se contacte contigo en las
          próximas 24 horas.
        </p>
      </Box>
      <Field>
        <Input
          placeholder='Tus nombres'
          type='text'
          {...register('name')}
          required
        />
      </Field>
      <Field>
        <Input
          placeholder='Tus apellidos'
          type='text'
          {...register('last_name')}
          required
        />
      </Field>
      <Field>
        <Controller
          control={control}
          name='phone'
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputMask
              placeholder='Número de contacto'
              type='tel'
              format='+57##########'
              mask='_'
              onBlur={onBlur}
              onChange={onChange}
              checked={value}
              inputRef={ref}
              required
            />
          )}
        />
      </Field>
      <Field>
        <Input
          placeholder='Correo electrónico'
          type='email'
          {...register('email')}
          required
        />
      </Field>

      <Button sx={button} disabled={isSubmitting} loading={isSubmitting}>
        Obtener mi descuento
      </Button>
    </form>
  )
}

export default IntegrateZohoForm
