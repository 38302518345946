import React from "react";

import Card from "@coninsa-s2/card";
import Heading from "@coninsa-s2/heading";
import Button from "@coninsa-s2/button";
import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";

import data from "./data.js";

import ArrowRightIcon from "../../../icons/arrow-right.inline.svg";

export default function () {
  return (
    <Section tint="light-gray" ptop="small">
      <Container>
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
          {data?.map((item) => (
            <Card key={item.id} tint={item.tint}>
              <Heading tint={item.tint} size="2xl" condensed>
                {item?.subtitle} <br />
                <span className="is-highlighted">{item?.title}</span>
              </Heading>
              <p>{item?.description}</p>
              <Button href={item?.url} target="_blank">
                Conoce más
              </Button>
              <a href={item?.url} target="_blank">
                <ArrowRightIcon className="s2-card__content-icon" />
              </a>
            </Card>
          ))}
        </div>
      </Container>
    </Section>
  );
}
