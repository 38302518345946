/** @jsx jsx */
import { jsx } from 'theme-ui'
import NumberFormat from 'react-number-format'

const baseInputStyles = {
  display: 'block',
  width: '100%',
  p: 2,
  appearance: 'none',
  fontSize: 'inherit',
  lineHeight: 'inherit',
  border: '1px solid',
  borderRadius: 4,
  color: 'inherit',
  bg: 'transparent',
}

const InputMask = function (props) {
  const variant = props?.variant ? `forms.input.${props.variant}` : 'forms.input'
  return (
    <NumberFormat sx={{ ...baseInputStyles, variant }} {...props} />
  )
}

export function removeMaskFormat(value) {
  if (!value) {
    return 0;
  }

  return parseFloat(value.replaceAll(/\.|\$|\s/g, "").replace(',', '.'));
}

export default InputMask
