import React from "react";
import { useForm } from "react-hook-form";
import formStorage from "./storage/form";
import quotationStorage from "./storage/quotation";
import { t } from "./utils";

export default function StepTwo({ nextCallback, backCallback }) {
  const quotation = quotationStorage.get();
  const form = formStorage.get();
  const otherUnits = quotation.other_units;

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: async () => form,
  });

  const onSubmit = async (data) => {
    formStorage.update(data);

    if (nextCallback) {
      nextCallback();
    }
  };

  const user = form.user;

  return (
    <div className="co-card">
      <form className="co-form" onSubmit={handleSubmit(onSubmit)}>
        <h3 className="font-semibold">
          {user?.names} {user?.last_name}
        </h3>
        <p className="co-quotation__description">
          Recuerda que al agregar cualquiera de las siguientes opciones, el
          valor de tu inmueble seleccionado aumentará.
        </p>
        <div className="flex gap-4 flex-col">
          <div className="form-control flex-1">
            <label htmlFor="parking_lot">
              ¿Quieres agregar un cuarto útil y/o parqueadero?
            </label>

            <div className="co-quotation__table-wrapper">
              <table className="table">
                <thead>
                  <tr>
                    <th>Inmueble</th>
                    <th>Referencia</th>
                    <th>Valor del inmueble</th>
                    <th>Seleccionar</th>
                  </tr>
                </thead>
                <tbody>
                  {otherUnits.map((item) => {
                    if (item?.idUnidad) {
                      return (
                        <tr key={item.idUnidad}>
                          <td>{item?.descTipoUnidad}</td>
                          <td>{item?.nomenclatura}</td>
                          <td>
                            $
                            {Number.parseInt(item?.valor).toLocaleString(
                              "es-CO"
                            )}{" "}
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              className="accent-co-dark-cyan"
                              id={`other-unit-${item?.idUnidad}`}
                              value={item?.idUnidad}
                              {...register("additional_units")}
                            />
                          </td>
                        </tr>
                      );
                    }

                    return (
                      <tr key={item.descTipoUnidad}>
                        <th colSpan={4}>
                          {t(item?.descTipoUnidad)}{" "}
                          {t(item?.descTipoUnidad) !== item?.descTipoUnidad && (
                            <span className="text-slate-500">
                              ({item?.descTipoUnidad})
                            </span>
                          )}
                        </th>
                      </tr>
                    );
                  })}
                  {otherUnits.length === 0 && (
                    <tr>
                      <td colSpan={4}>
                        No hay inmuebles adicionales disponibles.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="form-actions flex gap-2 justify-between">
          <button
            type="button"
            onClick={backCallback}
            className="co-button is-secondary is-large has-fixed-icon"
          >
            <i className="co-icon icon-arrow"></i>
            Regresar
          </button>

          <button className="co-button is-progress-default">Siguiente</button>
        </div>
      </form>
    </div>
  );
}
