import React from 'react'
import Stepper from './Stepper'

function HeaderForm({ step, title, ...props }) {
  return (
    <div {...props}>
      <Stepper
        sx={{
          bg: 'secondary',
          color: 'white',
          borderRadius: '4px',
          display: 'inline-block',
          fontSize: '16px',
          mb: ['12px', '16px'],
          px: '12px',
          py: '4px',
        }}
      >
        Paso {step}
      </Stepper>
      <h3 className='bg-[#ededed] font-display font-medium mb-6 px-4 py-[14px] rounded-md text-coninsa-blue-900 text-base md:text-lg uppercase'>
        {title}
      </h3>
    </div>
  )
}

export default HeaderForm
