import React from "react";

import ProjectPopup from "@coninsa-s2/project-popup";
import Tag from "@coninsa-s2/tag";
import { getProjectStateData } from "@coninsa-site/home/src/utils";

const ProjectPopupSearch: React.FC = ({
  id,
  price = 0,
  name,
  code,
  location,
  url,
  imageUrl,

  state: stateId,
  isVis = false,
  areaFrom,
  areaTo,
  brandUrl,
}) => {
  const state = getProjectStateData(stateId);
  const areaElement = () => (
    <>
      <strong>
        Desde {areaFrom} m<sup>2</sup> hasta {areaTo} m<sup>2</sup>
      </strong>
      <br />
      <span className="text-sm">Áreas totales construidas</span>
    </>
  );

  const tagsElement = () => (
    <>
      {state && <Tag tint={state.tint}>{state.name}</Tag>}
      {isVis && <Tag tint="senary">VIS</Tag>}
    </>
  );

  return (
    <ProjectPopup
      title={name}
      code={code}
      price={price}
      location={location}
      url={url}
      imageUrl={imageUrl}
      logoUrl={brandUrl}
      area={areaElement}
      tags={tagsElement}
    />
  );
};

export default ProjectPopupSearch;
