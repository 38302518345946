import pageIcon from "../icons/window.svg";

export default [
  {
    id: "terms-2018-1",
    icon: pageIcon,
    label: "Plan de Referidos Cuéntale a todos y gana - Barranquilla",
    url: "/terminos-y-condiciones/generales/plan-de-referidos-cuentale-a-todos-y-gana-barranquilla",
  },
  {
    id: "terms-2018-2",
    icon: pageIcon,
    label: "Campaña Mundialista 2018",
    url: "/terminos-y-condiciones/generales/campana-mundialista-2018",
  },
  {
    id: "terms-2018-3",
    icon: pageIcon,
    label: "Plan Referidos Coninsa Ramón H. Inmobiliaria",
    url: "/terminos-y-condiciones/generales/plan-referidos-coninsa-ramon-h-inmobiliaria",
  },
  {
    id: "terms-2018-4",
    icon: pageIcon,
    label: "Plan referidos “Vecinos y Amigos” - 2018",
    url: "/terminos-y-condiciones/generales/plan-referidos-vecinos-y-amigos-2018",
  },
  {
    id: "terms-2018-5",
    icon: pageIcon,
    label: "Promoción Consignatón 2018",
    url: "/terminos-y-condiciones/generales/promocion-consignaton-2018",
  },
  {
    id: "terms-2018-6",
    icon: pageIcon,
    label: "Plan Referidos Inmobiliaria 2018",
    url: "/terminos-y-condiciones/generales/plan-referidos-inmobiliaria-2018",
  },
  {
    id: "terms-2018-7",
    icon: pageIcon,
    label: "Feria virtual proyectos vivienda Bogotá 2018",
    url: "/terminos-y-condiciones/generales/feria-virtual-proyectos-vivienda-bogota-2018",
  },
];
