import React from 'react'
import { Grid, Input } from 'theme-ui'

import Location from '../../Location'
import Control from '../../components/Control'

function BasicTaxInformation({ register, control, setValue }) {
  return (
    <Grid gap={4} columns={[2, '1fr 1fr']}>
      <Control label='Número celular' required>
        <Input {...register('fieldCellPhoneNumber')} type='text' required />
      </Control>
      <Control label='Email para envío de facturación electrónica'>
        <Input {...register('fieldEmail')} type='email' />
      </Control>
      <Control label='Dirección de correspondencia'>
        <Input {...register('fieldCorrespondenceAddress')} type='text' />
      </Control>
      <Control label='Nombre de persona de contacto' required>
        <Input {...register('fieldContactPersonName')} type='text' required />
      </Control>
      <Control label='Correo electrónico de contacto' required>
        <Input {...register('fieldContactEmail')} type='email' required />
      </Control>
      <Control label='Celular de contacto' required>
        <Input {...register('fieldContactCellPhone')} type='text' required />
      </Control>
      <Control label='Ciudad'>
        <Location name='fieldFiscalCity' setValue={setValue} control={control} />
      </Control>
    </Grid>
  )
}

export default BasicTaxInformation
