import React from 'react'
import PropTypes from 'prop-types'
import { Box, Container } from 'theme-ui'

import { Brand } from 'ui'
import Menu from '../Menu'
import HamburgerMenu from '../HamburgerMenu'

function Header({ brand, brandUrl, variant, links, sticky, ...props }) {
  const baseStyles = {
    boxShadow: '0px 2px 3px #00000040',
    position: sticky ? 'fixed' : 'sticky',
    top: '0',
    px: 'medium',
    zIndex: '10000',
  }

  return (
    <Box __css={baseStyles} variant={variant} {...props} __themeKey='headerBars'>
      <Container
        sx={{
          position: [null, 'relative'],
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Brand variant={variant} brand={brand} href={brandUrl} />

        <HamburgerMenu variant={variant}>
          <Menu variant={variant}>
            {links.map((link, index) => (
              <Menu.Link key={`main-${index}`} {...link} />
            ))}
          </Menu>
        </HamburgerMenu>
      </Container>
    </Box>
  )
}

Header.defaultProps = {
  brand: 'default',
  brandUrl: '',
  links: [],
  sticky: false,
  variant: 'default',
}

Header.propTypes = {
  brand: PropTypes.string,
  brandUrl: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape()),
  sticky: PropTypes.bool,
  variant: PropTypes.string,
}

export default Header
