import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import Footsteps from '@coninsa-ui/react-footsteps';
import { getQueryString } from '../../../utils/query-string';
import InputMask from 'components/form/InputMask';

export default function SimulatorForm({ projectId, code }) {
  // const [webformSubmit] = useMutation()
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    control,
    formState: { isSubmitting },
  } = useForm();
  const [step, setStep] = useState(null);
  const [units, setUnits] = useState([]);
  const [unit, setUnit] = useState(null);

  useEffect(function () {
    const step = getQueryString('step', window.location.search) ?? 1;

    if (step) {
      setStep(parseInt(step));
    }
  }, []);

  const onSubmit = async ({ ...data }) => {
    localStorage.setItem(`data_authorization_${step}`, JSON.stringify(data));

    setStep(step + 1);

    if (step === 4) {
      const step1 = JSON.parse(localStorage.getItem('data_authorization_1'));
      const step2 = JSON.parse(localStorage.getItem('data_authorization_2'));
      const step3 = JSON.parse(localStorage.getItem('data_authorization_3'));
      const step4 = JSON.parse(localStorage.getItem('data_authorization_4'));
      const stepsData = { ...step1, ...step2, ...step3, ...step4 };

      const body = {
        ...stepsData,
        webform_id: 'authorization_data',
      };

      // try {
      //   const response = await webformSubmit({
      //     variables: {
      //       values: JSON.stringify(body),
      //     },
      //   });
      // } catch (error) { }
    }
  };

  const onReset = () => {
    reset();
    setStep(1);
  };

  const handleClick = () => {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    fetch(`https://admindrupal.coninsa.co/cotizador/projects/${code}`, options)
      .then(response => response.json())
      .then(data => console.log(data));
  };

  const selectUnit = () => {
    setUnit(units[0]);
  };

  console.log(units);

  return (
    <div className='py-7'>
      <button onClick={() => handleClick()}>Click me!</button>

      {units.map(unit => (
        <div>Inmueble {unit?.areaConstruida}</div>
      ))}

      <button onClick={() => selectUnit()}>Seleccionar primera unidad</button>
      <div>Inmueble {unit?.areaConstruida}</div>

      <Footsteps
        steps={[
          { label: 1, desc: 'Datos Personales' },
          { label: 2, desc: 'Personaliza tu inmueble' },
          { label: 3, desc: 'Subsidio de vivienda' },
          { label: 4, desc: 'Forma de pago' },
          { label: 5, desc: 'Completo' },
        ]}
        className='mb-4'
        currentStep={step}
      />

      <div className='max-w-5xl mx-auto'>
        {step === 1 && (
          <div className='co-card'>
            <form className='co-form' onSubmit={handleSubmit(onSubmit)}>
              <div className='flex gap-4 flex-col md:flex-row'>
                <div className='form-control flex-1'>
                  <label for='names'>Nombres {projectId}</label>
                  <input {...register('names')} type='text' id='names' required />
                </div>
                <div className='form-control flex-1'>
                  <label for='last_name'>Apellidos</label>
                  <input
                    {...register('last_name')}
                    type='text'
                    id='last_name'
                    required
                  />
                </div>
              </div>

              <div className='flex gap-4 flex-col md:flex-row'>
                <div className='form-control flex-1'>
                  <label for='document_type'>Selecciona el tipo de documento</label>
                  <select {...register('document_type')} id='document_type' required>
                    <option value=''>Selecciona una opción</option>
                    <option value='cc'>CC</option>
                    <option value='nit'>NIT</option>
                  </select>
                </div>
                <div className='form-control flex-1'>
                  <label for='document'>Número de documento</label>
                  <input
                    {...register('document')}
                    type='number'
                    id='document'
                    required
                  />
                </div>
              </div>

              <div className='flex gap-4 flex-col md:flex-row'>
                <div className='form-control flex-1'>
                  <label for='phone'>Número de celular</label>
                  <input {...register('phone')} type='text' id='phone' required />
                </div>
                <div className='form-control flex-1'>
                  <label for='email'>Correo electrónico</label>
                  <input {...register('email')} type='text' id='email' required />
                </div>
              </div>

              <div className='flex gap-4 flex-col md:flex-row'>
                <div className='form-control flex-1'>
                  <label for='occupation'>Ocupación</label>
                  <input
                    {...register('occupation')}
                    type='text'
                    id='occupation'
                    required
                  />
                </div>
                <div className='form-control flex-1'>
                  <label for='income'>¿Cuáles son tus ingresos familiares?</label>
                  <Controller
                    control={control}
                    name='income'
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <InputMask
                        mask='_'
                        prefix='$ '
                        thousandSeparator='.'
                        decimalSeparator=','
                        fixedDecimalScale
                        decimalScale={2}
                        onBlur={onBlur}
                        onChange={onChange}
                        checked={value}
                        inputRef={ref}
                      />
                    )}
                  />
                </div>
              </div>

              <div className='form-actions flex justify-end'>
                <button className='co-button is-progress-default is-large'>
                  Continuar
                </button>
              </div>
            </form>
          </div>
        )}

        {step === 2 && (
          <div className='co-card'>
            <form className='co-form' onSubmit={handleSubmit(onSubmit)}>
              <p>
                Recuerda que al agregar cualquiera de las siguientes opciones, el
                valor de tu inmueble seleccionado aumentará.
              </p>
              <div className='flex gap-4 flex-col'>
                <div className='form-control flex-1'>
                  <label for='parking_lot'>¿Quieres agregar un parqueadero?</label>
                  <input
                    {...register('parking_lot')}
                    type='text'
                    id='parking_lot'
                    required
                  />
                </div>
                <div className='form-control flex-1'>
                  <label for='useful_room'>¿Quieres agregar un cuarto útil?</label>
                  <input
                    {...register('useful_room')}
                    type='text'
                    id='useful_room'
                    required
                  />
                </div>
              </div>

              <div className='form-actions flex gap-2 justify-between'>
                <button
                  type='button'
                  onClick={() => setStep(step - 1)}
                  className='co-button is-secondary is-large has-fixed-icon'
                >
                  <i className='co-icon icon-arrow'></i>
                  Regresar
                </button>
                <button className='co-button is-progress-default is-large'>
                  Siguiente
                </button>
              </div>
            </form>
          </div>
        )}

        {step === 3 && (
          <div className='co-card'>
            <form className='co-form' onSubmit={handleSubmit(onSubmit)}>
              <p>
                Recuerda que al agregar cualquiera de las siguientes opciones, el
                valor de tu inmueble seleccionado aumentará.
              </p>
              <div className='form-control flex-1'>
                <label for='parking_lot'>
                  ¿Quieres saber si aplicas al subsidio?
                </label>
                <input
                  {...register('parking_lot')}
                  type='text'
                  id='parking_lot'
                  required
                />
              </div>

              <div className='form-actions flex gap-2 justify-between'>
                <button
                  type='button'
                  onClick={() => setStep(step - 1)}
                  className='co-button is-secondary is-large has-fixed-icon'
                >
                  <i className='co-icon icon-arrow'></i>
                  Regresar
                </button>
                <button className='co-button is-progress-default is-large'>
                  Siguiente
                </button>
              </div>
            </form>
          </div>
        )}

        {step === 4 && (
          <div className='co-card'>
            <form className='co-form' onSubmit={handleSubmit(onSubmit)}>
              <h3>¿Con cuánto dinero quieres separar tu inmueble?</h3>
              <p>
                Recuerda que al agregar cualquiera de las siguientes opciones, el
                valor de tu inmueble seleccionado aumentará.
              </p>

              <fieldset className='form-group are-radios'>
                <legend>¿Tienes ahorros y/o cesantías?</legend>
                <div className='form-items flex gap-4'>
                  <div className='form-control is-radio'>
                    <input
                      {...register('have_savings')}
                      type='radio'
                      id='have_savings'
                      value='Si'
                      required
                    />
                    <label for='have_savings'>Si</label>
                  </div>
                  <div className='form-control is-radio'>
                    <input
                      {...register('have_savings')}
                      type='radio'
                      id='no_have_savings'
                      value='No'
                      required
                    />
                    <label for='no_have_savings'>No</label>
                  </div>
                </div>
              </fieldset>

              <div className='form-control flex-1'>
                <Controller
                  control={control}
                  name='amount'
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <InputMask
                      mask='_'
                      prefix='$ '
                      thousandSeparator='.'
                      decimalSeparator=','
                      fixedDecimalScale
                      decimalScale={2}
                      onBlur={onBlur}
                      onChange={onChange}
                      checked={value}
                      inputRef={ref}
                    />
                  )}
                />
              </div>

              <div className='form-actions flex gap-2 justify-center'>
                <button
                  className='co-button is-progress-default is-large'
                  disabled={isSubmitting}
                >
                  {!isSubmitting ? 'Enviar' : 'Enviando...'}
                </button>
              </div>
            </form>
          </div>
        )}

        {step === 5 && (
          <div className='co-card'>
            <div className='text-center max-w-3xl mx-auto'>
              <div className='flex flex-wrap justify-center py-6'>
                <div className='check'>
                  <i className='co-icon icon-check'></i>
                </div>
              </div>

              <h3 className='co-heading-4 mb-8' style={{ color: '#80C342' }}>
                ¡Has registrado la información correctamente!
              </h3>
              <p className='co-heading-6 mb-8 text-sm md:text-base'>
                Hemos recibido toda la información de tu inmueble y la estamos
                revisando. Un asesor se contactará contigo en el menor tiempo posible
                para continuar con el proceso.
              </p>
              <span className='co-heading-5'>¡Gracias por confiar en nosotros!</span>
              <div className='flex flex-wrap justify-center py-8'>
                <button
                  onClick={onReset}
                  className='co-button is-progress-default w-80'
                >
                  VOLVER AL INICIO
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
