import React from 'react'
import PropTypes from 'prop-types'
import BuildingCard from '../modules/microsites/architecture/components/BuildingCard.jsx'

const ContainerPropTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string.isRequired,
}

function POurProjects({ projects = [], openNewTab = false }) {
  const targetCard = openNewTab ? '_blank' : '_self'

  return (
    <div className='grid grid-cols-2 md:grid md:grid-cols-4 gap-4'>
      {projects.map(project => (
        <BuildingCard
          image={project.image?.meta?.derivative?.url}
          location={project.location}
          name={project.title}
          url={project.url}
          target={targetCard}
        />
      ))}
    </div>
  )
}

POurProjects.propTypes = {
  ...ContainerPropTypes,
  projects: PropTypes.oneOf([]),
  targetCard: PropTypes.bool,
}

export default POurProjects
