import React from 'react'
import { navigate } from 'gatsby'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'

import { UPDATE_NODE_NATURAL_PERSON_APPLICATION } from '../../mutations'
import { NATURAL_PERSON_APPLICATION } from '../../queries'

import BasicDocumentation from '../generic/BasicDocumentation'

import HeaderForm from '../../components/HeaderForm'
import FooterForm from '../../components/FooterForm'
import { getEntity, cleanData } from '../utils'

function Documentation(props) {
  const loadedPerson = props?.loadedPerson || {}
  const [mutation] = useMutation(UPDATE_NODE_NATURAL_PERSON_APPLICATION, {
    refetchQueries: [
      { query: NATURAL_PERSON_APPLICATION, variables: { id: props.id } },
    ],
  })
  const methods = useForm({ defaultValues: loadedPerson })
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = methods

  const onSubmit = async data => {
    const variables = cleanData(data)

    return mutation({ variables })
      .then(response => {
        const entity = getEntity(response)

        if (entity) {
          navigate('?path=/coarrendatarios')
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <section>
      <HeaderForm step='3/4' title='Documentación del arrendatario' />
      <p className='mb-4 font-medium'>Ingresa la documentación necesaria para continuar con el proceso, debes seleccionar los achivos y adjuntarlos.</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <BasicDocumentation
          control={control}
          extraFields={[
            'labor-certification',
            'rut',
            'proof-payroll',
            'additionals',
          ]}
        />
        <FooterForm back isSubmitting={isSubmitting} />
      </form>
    </section>
  )
}

export default Documentation
