import { graphql } from 'gatsby'
import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import { mapResolver } from '../mappers'
import Layout from '../layout/default/Layout'
import { paragraphResolver } from '../paragraphs'

function ServiceTemplate({ data }) {

  const info = data?.drupal?.node
  const description =  info?.body?.value
  const basicHero = mapResolver(info?.hero)
  const section = {
    type: 'section',
    content: [
      {
        type: 'Drupal_ParagraphRichText',
        body: data?.drupal?.node?.body,

        // Fix basic hero image overlaping.
        sx: {
          '&:before': {
            content: '""',
            display: ['none', 'block'],
            width: '320px',
            height: '50px',
            float: 'right',
          },
        },
      },
    ],
  }

  

  return (
    <Layout footerAds={info?.footerAds} headerAds={info?.headerAds} popupAds={info?.popUpAds}>
      <Helmet>
        <title>{info?.title} | Coninsa</title>
        <meta name="description" content="Coninsa.co se especializa en ofrecer servicios integrales de construcción, diseño arquitectónico y gestión de proyectos. Destaca por su enfoque en sostenibilidad y eficiencia, proporcionando soluciones innovadoras que cumplen con las necesidades específicas de cada cliente y proyecto. Su compromiso con la calidad y el detalle garantiza resultados excepcionales." />
      </Helmet>
      {paragraphResolver(basicHero)}
      {paragraphResolver(section)}
    </Layout>
  )
}

ServiceTemplate.propTypes = {
  data: PropTypes.oneOf({}).isRequired,
}

export default ServiceTemplate

export const pageQuery = graphql`
  query service($id: String!) {
    drupal {
      node: nodeById(id: $id) {
        id: nid

        headerAds: ads(type: "header") {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        popUpAds: ads(type: "pop_up") {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        footerAds: ads(type: "footer") {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title  
          }
        }

        ... on Drupal_NodeService {
          __typename
          title
          body {
            value
          }
          hero: fieldHero {
            entity {
              id
              ...ParagraphBasicHero
            }
          }
        }
      }
    }
  }
`
