const defaultVariant = {
  '.slick-dots li button:before': {
    color: 'white',
  },

  '.slick-prev, .slick-next': {
    color: 'white',
  },
}

const coloredVariant = {
  '.slick-dots li button:before': {
    color: 'gray',
  },

  '.slick-prev, .slick-next': {
    color: 'accent',
  },
}

export default {
  default: defaultVariant,
  colored: coloredVariant,
}
