import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

function Header({ children, variant, headline, upper, ...props }) {
  const baseStyles = {
    color: 'neutral',
  }

  const titleStyles = {
    fontFamily: 'Oswald',
    fontSize: ['26px', '36px'],
    fontWeight: '500',
    mb: ['small', 'medium'],
    textTransform: upper ? 'uppercase' : 'inherit',
  }

  const headlineStyles = {
    fontSize: ['22px', '30px'],
    fontWeight: '300',
  }

  return (
    <Box variant={variant} {...props} __css={baseStyles} __themeKey='headers'>
      {headline && (
        <Box
          variant={`${variant}.headline`}
          as='span'
          __css={headlineStyles}
          __themeKey='headers'
        >
          {headline}
        </Box>
      )}
      <Box as='h2' __css={titleStyles}>
        {children}
      </Box>
    </Box>
  )
}

Header.defaultProps = {
  headline: undefined,
  variant: 'default',
  upper: true,
}

Header.propTypes = {
  children: PropTypes.node.isRequired,
  headline: PropTypes.string,
  variant: PropTypes.string,
  upper: PropTypes.bool,
}

export default Header
