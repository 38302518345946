import React from 'react'
import { navigate } from 'gatsby'
import { Box } from 'theme-ui'
import { useForm } from 'react-hook-form'
import { useStateMachine } from 'little-state-machine'
import { useMutation } from '@apollo/client'

import {
  CREATE_NODE_NATURAL_PERSON_APPLICATION,
  UPDATE_NODE_NATURAL_PERSON_APPLICATION
} from '../../mutations'
import { NATURAL_PERSON_APPLICATION } from '../../queries'

import BasicNaturalPersonalInformation from '../generic/BasicNaturalPersonalInformation'
import BasicOccupation from '../generic/BasicOccupation'
import HeaderForm from '../../components/HeaderForm'
import FooterForm from '../../components/FooterForm'
import { cleanData, getEntity } from '../utils'

const initialValues = {
  title: 'temporary',
  fieldApplicationStatus: 'en-proceso',
  fieldCotenants: [],

  fieldIdType: 'cc',
  fieldIsCustomer: '0',
  fieldIncomeResponsible: '0',
  fieldSelfRetainer: '0',
  fieldGreatTaxpayer: '0',
  fieldPublicServer: '0',
  fieldManagePublicResources: '0',
  fieldPubliclyRecognized: '0',
  fieldFamilyPubliclyRecognized: '0',
  fieldInternalEmployee: '0',
  fieldHandlesForeignCurrency: '0',
  fieldInternationalOperations: '0',
  fieldHasInternationalAccount: '0',
  fieldEnablePdfApplication: '0',
  fieldSalesResponsible: 'comun'
}

function PersonalInformation(props) {
  const { state } = useStateMachine()
  const loadedPerson = props?.loadedPerson || {
    fieldPropertyCode: state.initialSettings?.propertyCode
  }
  const [createMutation] = useMutation(CREATE_NODE_NATURAL_PERSON_APPLICATION)
  const [updateMutation] = useMutation(UPDATE_NODE_NATURAL_PERSON_APPLICATION, {
    refetchQueries: [
      { query: NATURAL_PERSON_APPLICATION, variables: { id: props.id } }
    ]
  })
  const {
    register,
    formState: { isSubmitting },
    handleSubmit,
    control,
    setValue
  } = useForm({
    defaultValues: {
      ...initialValues,
      ...loadedPerson
    }
  })

  const onSubmit = async data => {
    const mutation = data?.id ? updateMutation : createMutation
    const variables = cleanData(data)

    return mutation({ variables })
      .then(response => {
        const entity = getEntity(response)

        if (entity) {
          const redirectTo = `/estudio-digital/solicitud/persona/natural/${entity.id}?path=/informacion-financiera`
          navigate(redirectTo)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <section className='px-5 md:px-0'>
      <HeaderForm step='1/4' title='Información personal del arrendatario' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb='medium'>
          <BasicNaturalPersonalInformation
            register={register}
            control={control}
            setValue={setValue}
            extraFields={['cell', 'phone']}
          />
        </Box>

        <Box mb='medium'>
          <h3 className='bg-[#ededed] font-display font-medium mb-6 px-4 py-[14px] rounded-md text-coninsa-blue-900 text-base md:text-lg uppercase'>
            Información laboral del arrendatario
          </h3>
            <BasicOccupation register={register} />
        </Box>

        <FooterForm isSubmitting={isSubmitting} />
      </form>
    </section>
  )
}

export default PersonalInformation
