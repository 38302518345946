import React from 'react'
import { Box } from 'theme-ui'

function Menu(props) {
  const baseStyles = {
    display: ['block', null, null, 'flex'],
    justifyContent: 'flex-end',
  }

  return <Box __css={baseStyles} {...props} />
}

export default Menu
