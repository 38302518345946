import questionIcon from "../../../icons/question.svg";
import fileIcon from "../../../icons/file.svg"
import gearIcon from "../../../icons/gear.svg"

export default [
  {
    id: 'quick-item-1',
    tint: 'green',
    icon: questionIcon,
    title: "Preguntas frecuentes",
    description:
      "¿Necesitas ayuda?, encuentra las respuestas que necesitas.",
    url: "/preguntas-frecuentes",
  },
  {
    id: 'quick-item-2',
    tint: 'dark-blue',
    icon: fileIcon,
    title: "Sobre Arrendamientos",
    description:
      "Conoce los beneficios del servicio de arrendamiento y sus términos y condiciones.",
    url: "/personas/arrendar-un-inmueble",
  },
  {
    id: 'quick-item-3',
    tint: 'mint',
    icon: gearIcon,
    title: "¿Tienes un requerimiento?",
    description:
      "Conoce nuestros medios habilitados para generar un requerimiento.",
    url: "/servicio-cliente",
  },
];
