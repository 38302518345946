import projectImg from "../../../images/project.png";
import propertyImg from "../../../images/property.png";
import HomeIcon from "../../../icons/home.svg";
import StoreIcon from "../../../icons/store.svg";

export default [
  {
    id: 'building-item-1',
    subtitle: 'Proyectos para',
    title: 'Vivienda',
    description: 'Explora nuestro portafolio de proyectos nuevos para que descubras tu futuro hogar.',
    imageUrl: projectImg,
    icon: HomeIcon,
    url: '/proyectos/nuevo/tipo-de-servicio/vivienda',
    tint: 'lime',
  },
  {
    id: 'building-item-2',
    subtitle: 'Inmuebles para',
    title: 'diferente de vivienda',
    description: 'Un espacio dedicado para consultorios, locales y otros, perfectos para tu negocio.',
    imageUrl: propertyImg,
    icon: StoreIcon,
    url: '/proyectos/nuevo/tipo-de-servicio/comercio',
    tint: 'green',
  },
]
