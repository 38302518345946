import { Description, Title } from "@radix-ui/react-dialog";

export default [
  {
    id: "info-item-1",
    label: "1",
    title: "Copia de las cédulas de los propietarios.",
  },
  {
    id: "info-item-2",
    label: "2",
    title: "Certificado de tradición y libertad del inmueble.",
  },
  {
    id: "info-item-3",
    label: "3",
    title: "Copia de tu RUT.",
  }
]
