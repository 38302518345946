import React from 'react'
import { Box, Grid, Input } from 'theme-ui'

import { Heading } from 'ui'
import Control from '../../components/Control'

function BasicReferences({ register }) {
  return (
    <Box>
      <Box sx={{ border: '1px solid', borderColor: 'transparent', mb: 'medium' }}>
        <Heading level='4' variant='form'>
          Comercial
        </Heading>
        <Grid gap={4} columns={[2, '1fr, 1fr']}>
          <Control label='Almacén'>
            <Input {...register('fieldCommercialReference.store')} type='text' />
          </Control>
          <Control label='Teléfono'>
            <Input {...register('fieldCommercialReference.phone')} type='text' />
          </Control>
        </Grid>
      </Box>
    </Box>
  )
}

export default BasicReferences
