import React from "react";
import clsx from "clsx";

import "./styles.css";

interface BannerProps {
  imageUrl?: string;
  imageUrlMobile?: string;
  height?: string;
  children?: React.ReactNode;

  className?: string;
}

const Banner: React.FC<BannerProps> = ({
  height = "auto",

  className,
  ...props
}) => {
  const dynamicClasses = {
    [`s2-banner--${height}`]: height,
  };

  return (
    <div className={clsx("s2-banner", dynamicClasses, className)} {...props}>
      <img
        className="s2-banner__image s2-banner__image--mobile"
        src={props.imageUrlMobile}
        alt="Banner"
      />
      <img
        className="s2-banner__image s2-banner__image--desktop"
        src={props.imageUrl}
        alt="Banner"
      />

      <div className="s2-banner__content">{props.children}</div>
    </div>
  );
};

export default Banner;
