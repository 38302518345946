import { gql } from '@apollo/client'

export const CREATE_NODE_LEGAL_PERSON_COTENANT = gql`
  mutation createNodeLegalPersonCoTenant(
    $title: String!
    $fieldShareholders: [NodeFieldShareholdersFieldInput]
    $fieldEconomicActivity: String
    $fieldAssets: String
    $fieldLastName: String
    $fieldSelfRetainer: String
    $fieldLegalRepresentativePhone: String
    $fieldContactCellPhone: String
    $fieldFiscalCity: NodeFieldFiscalCityFieldInput
    $fieldIsCustomer: String!
    $fieldLegalRepresentativeEmail: String
    $fieldContactEmail: String
    $fieldHasInternationalAccount: String
    $fieldIncomeStatement: NodeFieldIncomeStatementFieldInput
    $fieldUbication: NodeFieldUbicationFieldInput
    $fieldCorrespondenceAddress: String
    $fieldPropertyAddress: String
    $fieldAddress: String
    $fieldFiscalAddress: String
    $fieldIdentificationDocument: NodeFieldIdentificationDocumentFieldInput
    $fieldExpenses: String
    $fieldEmail: String
    $fieldFinancialState: NodeFieldFinancialStateFieldInput
    $fieldExistenceRepresentation: NodeFieldExistenceRepresentationFieldInput
    $fieldBankStatements: [NodeFieldBankStatementsFieldInput]
    $fieldExpeditionDate: NodeFieldExpeditionDateFieldInput
    $fieldGreatTaxpayer: String
    $fieldPropertyTax: NodeFieldPropertyTaxFieldInput
    $fieldIncome: String
    $fieldExpeditionPlace: NodeFieldExpeditionPlaceFieldInput
    $fieldHandlesForeignCurrency: String
    $fieldBrand: String
    $fieldPropertyRegistration: String
    $fieldModel: String
    $fieldNit: String
    $fieldContactPersonName: String
    $fieldFirstName: String
    $fieldCellPhoneNumber: String
    $fieldAccountNumber: String
    $fieldIdNumber: String!
    $fieldEmployeesNumber: String
    $fieldLiabilities: String
    $fieldLicensePlate: String
    $fieldProviderReferences: [NodeFieldProviderReferencesFieldInput]
    $fieldSocialReason: String
    $fieldInternationalOperations: String
    $fieldBankReferences: [NodeFieldBankReferencesFieldInput]
    $fieldCommercialReferences: [NodeFieldCommercialReferencesFieldInput]
    $fieldIcaResponsible: String
    $fieldIncomeResponsible: String
    $fieldSalesResponsible: String
    $fieldRut: NodeFieldRutFieldInput
    $fieldFiscalPhone: String
    $fieldHasPropertyMortgage: String
    $fieldHasGarment: String
    $fieldCompanyType: String
    $fieldIdType: String!
    $fieldBusiness: String
  ) {
    node: createNodeLegalPersonCoTenant(
      input: {
        title: $title
        fieldShareholders: $fieldShareholders
        fieldEconomicActivity: $fieldEconomicActivity
        fieldAssets: $fieldAssets
        fieldLastName: $fieldLastName
        fieldSelfRetainer: $fieldSelfRetainer
        fieldLegalRepresentativePhone: $fieldLegalRepresentativePhone
        fieldContactCellPhone: $fieldContactCellPhone
        fieldFiscalCity: $fieldFiscalCity
        fieldIsCustomer: $fieldIsCustomer
        fieldLegalRepresentativeEmail: $fieldLegalRepresentativeEmail
        fieldContactEmail: $fieldContactEmail
        fieldHasInternationalAccount: $fieldHasInternationalAccount
        fieldIncomeStatement: $fieldIncomeStatement
        fieldUbication: $fieldUbication
        fieldCorrespondenceAddress: $fieldCorrespondenceAddress
        fieldPropertyAddress: $fieldPropertyAddress
        fieldAddress: $fieldAddress
        fieldFiscalAddress: $fieldFiscalAddress
        fieldIdentificationDocument: $fieldIdentificationDocument
        fieldExpenses: $fieldExpenses
        fieldEmail: $fieldEmail
        fieldFinancialState: $fieldFinancialState
        fieldExistenceRepresentation: $fieldExistenceRepresentation
        fieldBankStatements: $fieldBankStatements
        fieldExpeditionDate: $fieldExpeditionDate
        fieldGreatTaxpayer: $fieldGreatTaxpayer
        fieldPropertyTax: $fieldPropertyTax
        fieldIncome: $fieldIncome
        fieldExpeditionPlace: $fieldExpeditionPlace
        fieldHandlesForeignCurrency: $fieldHandlesForeignCurrency
        fieldBrand: $fieldBrand
        fieldPropertyRegistration: $fieldPropertyRegistration
        fieldModel: $fieldModel
        fieldNit: $fieldNit
        fieldContactPersonName: $fieldContactPersonName
        fieldFirstName: $fieldFirstName
        fieldCellPhoneNumber: $fieldCellPhoneNumber
        fieldAccountNumber: $fieldAccountNumber
        fieldIdNumber: $fieldIdNumber
        fieldEmployeesNumber: $fieldEmployeesNumber
        fieldLiabilities: $fieldLiabilities
        fieldLicensePlate: $fieldLicensePlate
        fieldProviderReferences: $fieldProviderReferences
        fieldSocialReason: $fieldSocialReason
        fieldInternationalOperations: $fieldInternationalOperations
        fieldBankReferences: $fieldBankReferences
        fieldCommercialReferences: $fieldCommercialReferences
        fieldIcaResponsible: $fieldIcaResponsible
        fieldIncomeResponsible: $fieldIncomeResponsible
        fieldSalesResponsible: $fieldSalesResponsible
        fieldRut: $fieldRut
        fieldFiscalPhone: $fieldFiscalPhone
        fieldHasPropertyMortgage: $fieldHasPropertyMortgage
        fieldHasGarment: $fieldHasGarment
        fieldCompanyType: $fieldCompanyType
        fieldIdType: $fieldIdType
        fieldBusiness: $fieldBusiness
      }
    ) {
      violations {
        message
        path
        code
      }
      errors
      entity {
        bundle: entityBundle
        label: entityLabel
        id: entityId
        ... on NodeLegalPersonCoTenant {
          status
          title
          created
          changed
        }
      }
    }
  }
`

export const UPDATE_NODE_LEGAL_PERSON_COTENANT = gql`
  mutation UpdateNodeLegalPersonCoTenant(
    $id: String!
    $title: String!
    $fieldShareholders: [NodeFieldShareholdersFieldInput]
    $fieldEconomicActivity: String
    $fieldAssets: String
    $fieldLastName: String
    $fieldSelfRetainer: String
    $fieldLegalRepresentativePhone: String
    $fieldContactCellPhone: String
    $fieldFiscalCity: NodeFieldFiscalCityFieldInput
    $fieldIsCustomer: String!
    $fieldLegalRepresentativeEmail: String
    $fieldContactEmail: String
    $fieldHasInternationalAccount: String
    $fieldIncomeStatement: NodeFieldIncomeStatementFieldInput
    $fieldUbication: NodeFieldUbicationFieldInput
    $fieldCorrespondenceAddress: String
    $fieldPropertyAddress: String
    $fieldAddress: String
    $fieldFiscalAddress: String
    $fieldIdentificationDocument: NodeFieldIdentificationDocumentFieldInput
    $fieldExpenses: String
    $fieldEmail: String
    $fieldFinancialState: NodeFieldFinancialStateFieldInput
    $fieldExistenceRepresentation: NodeFieldExistenceRepresentationFieldInput
    $fieldBankStatements: [NodeFieldBankStatementsFieldInput]
    $fieldExpeditionDate: NodeFieldExpeditionDateFieldInput
    $fieldGreatTaxpayer: String
    $fieldPropertyTax: NodeFieldPropertyTaxFieldInput
    $fieldIncome: String
    $fieldExpeditionPlace: NodeFieldExpeditionPlaceFieldInput
    $fieldHandlesForeignCurrency: String
    $fieldBrand: String
    $fieldPropertyRegistration: String
    $fieldModel: String
    $fieldNit: String
    $fieldContactPersonName: String
    $fieldFirstName: String
    $fieldCellPhoneNumber: String
    $fieldAccountNumber: String
    $fieldIdNumber: String!
    $fieldEmployeesNumber: String
    $fieldLiabilities: String
    $fieldLicensePlate: String
    $fieldProviderReferences: [NodeFieldProviderReferencesFieldInput]
    $fieldSocialReason: String
    $fieldInternationalOperations: String
    $fieldBankReferences: [NodeFieldBankReferencesFieldInput]
    $fieldCommercialReferences: [NodeFieldCommercialReferencesFieldInput]
    $fieldIcaResponsible: String
    $fieldIncomeResponsible: String
    $fieldSalesResponsible: String
    $fieldRut: NodeFieldRutFieldInput
    $fieldFiscalPhone: String
    $fieldHasPropertyMortgage: String
    $fieldHasGarment: String
    $fieldCompanyType: String
    $fieldIdType: String!
    $fieldBusiness: String
  ) {
    node: updateNodeLegalPersonCoTenant(
      id: $id
      input: {
        title: $title
        fieldShareholders: $fieldShareholders
        fieldEconomicActivity: $fieldEconomicActivity
        fieldAssets: $fieldAssets
        fieldLastName: $fieldLastName
        fieldSelfRetainer: $fieldSelfRetainer
        fieldLegalRepresentativePhone: $fieldLegalRepresentativePhone
        fieldContactCellPhone: $fieldContactCellPhone
        fieldFiscalCity: $fieldFiscalCity
        fieldIsCustomer: $fieldIsCustomer
        fieldLegalRepresentativeEmail: $fieldLegalRepresentativeEmail
        fieldContactEmail: $fieldContactEmail
        fieldHasInternationalAccount: $fieldHasInternationalAccount
        fieldIncomeStatement: $fieldIncomeStatement
        fieldUbication: $fieldUbication
        fieldCorrespondenceAddress: $fieldCorrespondenceAddress
        fieldPropertyAddress: $fieldPropertyAddress
        fieldAddress: $fieldAddress
        fieldFiscalAddress: $fieldFiscalAddress
        fieldIdentificationDocument: $fieldIdentificationDocument
        fieldExpenses: $fieldExpenses
        fieldEmail: $fieldEmail
        fieldFinancialState: $fieldFinancialState
        fieldExistenceRepresentation: $fieldExistenceRepresentation
        fieldBankStatements: $fieldBankStatements
        fieldExpeditionDate: $fieldExpeditionDate
        fieldGreatTaxpayer: $fieldGreatTaxpayer
        fieldPropertyTax: $fieldPropertyTax
        fieldIncome: $fieldIncome
        fieldExpeditionPlace: $fieldExpeditionPlace
        fieldHandlesForeignCurrency: $fieldHandlesForeignCurrency
        fieldBrand: $fieldBrand
        fieldPropertyRegistration: $fieldPropertyRegistration
        fieldModel: $fieldModel
        fieldNit: $fieldNit
        fieldContactPersonName: $fieldContactPersonName
        fieldFirstName: $fieldFirstName
        fieldCellPhoneNumber: $fieldCellPhoneNumber
        fieldAccountNumber: $fieldAccountNumber
        fieldIdNumber: $fieldIdNumber
        fieldEmployeesNumber: $fieldEmployeesNumber
        fieldLiabilities: $fieldLiabilities
        fieldLicensePlate: $fieldLicensePlate
        fieldProviderReferences: $fieldProviderReferences
        fieldSocialReason: $fieldSocialReason
        fieldInternationalOperations: $fieldInternationalOperations
        fieldBankReferences: $fieldBankReferences
        fieldCommercialReferences: $fieldCommercialReferences
        fieldIcaResponsible: $fieldIcaResponsible
        fieldIncomeResponsible: $fieldIncomeResponsible
        fieldSalesResponsible: $fieldSalesResponsible
        fieldRut: $fieldRut
        fieldFiscalPhone: $fieldFiscalPhone
        fieldHasPropertyMortgage: $fieldHasPropertyMortgage
        fieldHasGarment: $fieldHasGarment
        fieldCompanyType: $fieldCompanyType
        fieldIdType: $fieldIdType
        fieldBusiness: $fieldBusiness
      }
    ) {
      violations {
        message
        path
        code
      }
      errors
      entity {
        bundle: entityBundle
        label: entityLabel
        id: entityId
      }
    }
  }
`
