import { gql } from '@apollo/client'

export const NATURAL_PERSON_APPLICATION = gql`
  query NaturalApplicationNode($id: String!) {
    node: nodeById(id: $id) {
      bundle: entityBundle
      ... on NodeNaturalPersonApplication {
        id: nid
        title
        fieldAccountNumber
        fieldApplicationStatus
        fieldAssets
        fieldBrand
        fieldCellPhoneNumber
        fieldCrmConsultant
        fieldCommercialReference {
          store
          phone
        }
        fieldEmail
        fieldEnablePdfApplication
        fieldExpenses
        fieldFirstName
        fieldFiscalPhone
        fieldHasPropertyMortgage
        fieldHandlesForeignCurrency
        fieldHasInternationalAccount
        fieldIdNumber
        fieldIdType
        fieldIncome
        fieldIndependentActivity
        fieldInternationalOperations
        fieldLastName
        fieldLiabilities
        fieldLicensePlate
        fieldMaritalStatus
        fieldModel
        fieldOccupation
        fieldOfficeAddress
        fieldOfficeCity
        fieldPersonalAddress
        fieldPersonalName
        fieldPersonalPhone
        fieldPersonalRelationship
        fieldPosition
        fieldProfession
        fieldPropertyAddress
        fieldPropertyCode
        fieldPropertyRegistration
        fieldFamilyReference {
          address
          name
          phone
          relationship
        }
        fieldBankReference {
          entity
          accountType
          accountNumber
          branchOffice
        }
        fieldSalary
        fieldWorkCompany
        fieldHasGarment
        fieldContractType
        fieldAdmissionDate {
          value
          date
        }
        fieldAdditionalDocuments {
          targetId
          display
          description
          entity {
            fid
            filename
            url
          }
        }
        fieldBankStatements {
          targetId
          display
          description
          entity {
            fid
            filename
            url
          }
        }
        fieldCityBirth {
          department
          city
          neighborhood
        }
        fieldCreditAnalyst {
          targetId
        }
        fieldDateBirth {
          value
          date
        }
        fieldExpeditionDate {
          value
          date
        }
        fieldExpeditionPlace {
          department
          city
          neighborhood
        }
        fieldFinancialState {
          targetId
          display
          description
          entity {
            fid
            filename
            url
          }
        }
        fieldIdentificationDocument {
          targetId
          display
          description
          entity {
            fid
            filename
            url
          }
        }
        fieldLaborCertification {
          targetId
          display
          description
          entity {
            fid
            filename
            url
          }
        }
        fieldProofPayroll {
          targetId
          display
          description
          entity {
            fid
            filename
            url
          }
        }
        fieldRut {
          targetId
          display
          description
          entity {
            fid
            filename
            url
          }
        }
        fieldRefPersonalMunicipality {
          department
          city
          neighborhood
        }

        fieldRefFamiliarMunicipality {
          department
          city
          neighborhood
        }

        fieldCotenants {
          targetId
          entity {
            bundle: entityBundle
            id: nid
            status
            title
            created
            changed
          }
        }
      }
    }
  }
`
