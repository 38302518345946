import React from "react";
import clsx from "clsx";

import "./styles.css";

function Card({
  tint = "dark-blue",
  featured = false,
  imageUrl = null,
  ...props
}) {
  const dynamicClasses = {
    [`s2-card--${tint}`]: tint,
    "s2-card--featured": featured,
  };

  return (
    <div className={clsx("s2-card", dynamicClasses)}>
      <div className="s2-card__content">{props.children}</div>

      {featured && imageUrl && (
        <div className="s2-card__image">
          <img src={imageUrl} />
        </div>
      )}
    </div>
  );
}

export default Card;
