import React, { useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import { useController } from "react-hook-form";

import "./style.css";
import { FILES_UPLOAD } from "./mutations";
import pictureURL from "../assets/picture.svg"

function FilesInput({ name, control, accept, max = 10, defaultValue = [] }) {
  const { field } = useController({ name, control, defaultValue });
  const [mutation, { loading }] = useMutation(FILES_UPLOAD);
  const inputRef = useRef(null);

  const handleChange = async (e) => {
    const { files } = e.target;

    if (files.length) {
      return mutation({ variables: { files } })
        .then((response) => {
          const { data } = response;
          const newValues = data.entities.map((entity) => ({
            targetId: entity.fid,
            entity,
          }));

          field.onChange([...field.value, ...newValues]);

          inputRef.current.value = "";
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const onRemove = (excludeIndex) => () => {
    const filteredValues = field.value.filter(
      (_, index) => index !== excludeIndex
    );

    field.onChange(filteredValues);
  };

  const inputId = `${name}-id`;

  const hasImages = field.value?.length > 0;

  return (
    <div className={`form-control is-dropzone ${hasImages ? "has-images" : ""}`}>
      {field?.value?.length < max && (
        <input
          accept={accept}
          type="file"
          onChange={handleChange}
          disabled={loading}
          multiple
          ref={inputRef}
          id={inputId}
        />
      )}

      {!hasImages && (
        <label htmlFor={inputId}>
          <img src={pictureURL} />
          {loading && (
            <p className="font-bold text-center leading-8">Cargando ...</p>
          )}

          {!loading && (
            <p className="font-bold text-center leading-8">
              Agrega o arrastra tus fotografías en este recuadro <br />
              <span className="font-light opacity-60">
                Formatos soportados JPG, JPEG2000, PNG
              </span>
            </p>
          )}
        </label>
      )}

      {hasImages && (
        <div className="dropzone__items">
          <label htmlFor={inputId}>
            <img src={pictureURL} />
            <p className="font-bold text-center text-xs">
              {!loading
                ? "Agrega o arrastra tus fotografías en este recuadro"
                : "Cargando ..."}
            </p>
          </label>

          {field.value
            .map((item) => item.entity)
            .map((item, index) => (
              <div key={item.fid} className="dropzone__item">
                <img src={item.url} title={item.filename} />
                <button
                  className="dropzone__item__remove"
                  onClick={onRemove(index)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path
                      fill="currentColor"
                      d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z"
                    />
                  </svg>
                </button>
              </div>
            ))}
        </div>
      )}

      <p className="help-text text-[#00706A]">
        Has cargado {field.value.length} de {max} fotografías recomendadas
      </p>
    </div>
  );
}

export default FilesInput;
