import React from 'react'
import PropTypes from 'prop-types'

import { Box } from 'theme-ui'

const CardTag = ({ variant, ...props }) => {
  const baseStyles = {
    color: 'tagColor',
    fontWeight: 'bold',
    mb: 'small',
  }

  return (
    <Box
      __css={baseStyles}
      variant={`${variant}.tag`}
      {...props}
      __themeKey='cards'
    />
  )
}

CardTag.defaultProps = {
  variant: 'default',
}

CardTag.propTypes = {
  variant: PropTypes.string,
}

export default CardTag
