const PROJECT_STATES = [
  {
    tid: 4683,
    name: "100% vendido",
    tint: "quaternary",
    machineName: "sold_out",
  },
  {
    tid: 4684,
    name: "En lanzamiento",
    tint: "quinary",
    machineName: "in_release",
  },
  {
    tid: 4685,
    name: "Entrega inmediata",
    tint: "tertiary",
    machineName: "immediate_delivery",
  },
  {
    tid: 4686,
    name: "Últimas unidades",
    tint: "secondary",
    machineName: "last_units",
  },
  {
    tid: 4687,
    name: "Nuevo",
    tint: null,
    machineName: "new",
  },
  {
    tid: 4947,
    name: "Nueva etapa",
    tint: "primary",
    machineName: "new_stage",
  },
  {
    tid: 5352,
    name: "Éxito en ventas",
    tint: "primary",
    machineName: "sales_success",
  }
];

const PROJECT_IMMEDIATE_DELIVERY_STATES = [
  {
    id: 0,
    name: "No VIS",
  },
  {
    id: 1,
    name: "VIS",
  },
];

const PROJECT_COMMERCE_HOUSING_STATES = [
  {
    id: 0,
    name: "Comercio",
  },
  {
    id: 1,
    name: "Vivienda",
  },
];

export function getProjectStateLabel(id) {
  const state = PROJECT_STATES.find((item) => item.tid == id);

  return state?.name ?? id;
}

export function getProjectImmediateDeliveryLabel(id) {
  const state = PROJECT_IMMEDIATE_DELIVERY_STATES.find((item) => item.id == id);

  return state?.name ?? id;
}

export function getProjectCommerceHousingLabel(id) {
  const state = PROJECT_COMMERCE_HOUSING_STATES.find((item) => item.id == id);

  return state?.name ?? id;
}
