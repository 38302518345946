import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'
import { Icon } from 'ui'

import Menu from '../Menu'

export const HamburgerMenuContext = React.createContext({})

const links = [
  {
    id: 'm1',
    label: 'Pagar factura',
    url: {
      path: 'https://www.coninsa.co/mi-coninsa-virtual/pagar-factura',
    },
    icon: { name: 'co-wallet-white', size: 'small' },
    variant: 'square',
    bg: 'secondary',
  },

  {
    id: 'm2',
    label: 'Mi cuenta',
    url: {
      path: 'https://www.coninsa.co/mi-coninsa-virtual?action=',
    },
    icon: { name: 'co-user-white', size: 'small' },
    variant: 'square',
    bg: 'primary',
  },
]

function HamburgerMenu({ children, variant, ...props }) {
  const [showLayer, setShowLayer] = useState(false)

  useEffect(() => {
    if (showLayer) {
      document.querySelector('html').classList.add('no-scroll')
    } else {
      document.querySelector('html').classList.remove('no-scroll')
    }
  }, [showLayer])

  const mobileStyles = {
    display: ['flex', null, null, 'none'],
    alignItems: 'center',
  }

  const displayValue = !showLayer ? 'none' : 'block'

  const contentStyles = {
    borderTop: ['1px solid', null, null, 'none'],
    borderTopColor: 'gray',
    display: [displayValue, null, null, 'block'],
    height: ['calc(100vh - 73px)', null, 'auto'],
    left: 0,
    overflowY: ['scroll', null, null, 'inherit'],
    position: ['absolute', null, null, 'inherit'],
    py: ['medium', null, null, 'inherit'],
    px: ['medium', null, null, 'inherit'],
    right: 0,
    zIndex: 1,
    mx: [null, -24, 0],
  }

  const iconStyles = {
    color: 'neutral',
    fontSize: '25px',
    my: 'medium',
    variant: `hamburgerMenus.${variant}.icon`,
  }

  const iconName = showLayer ? 'fa-times' : 'fa-bars'

  const context = {
    close: () => setShowLayer(false),
  }

  return (
    <HamburgerMenuContext.Provider value={context}>
      <Box variant={variant} {...props} __themeKey='hamburgerMenus'>
        <Box __css={mobileStyles}>
          {links.map(link => (
            <Menu.BaseLink key={link.id} {...link} mr='small' />
          ))}

          <Icon
            name={iconName}
            sx={iconStyles}
            onClick={() => setShowLayer(!showLayer)}
          />
        </Box>

        <Box
          __css={contentStyles}
          variant={`${variant}.content`}
          __themeKey='hamburgerMenus'
        >
          {children}
        </Box>
      </Box>
    </HamburgerMenuContext.Provider>
  )
}

HamburgerMenu.defaultProps = {
  variant: 'default',
}

HamburgerMenu.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default HamburgerMenu
