function basicHeroMapper({ body, background, media, ...entity }) {
  return {
    type: 'basic_hero',
    image: media?.entity?.image,
    background: background?.entity?.image,
    ...entity,
  }
}

export default basicHeroMapper
