import documentIcon from "../icons/pdf.svg";

export default [
  {
    id: "terms-2024-1",
    icon: documentIcon,
    label: "Campaña referidos",
    url: "https://d2xt7650da4t25.cloudfront.net/s3fs-public/2024-09/terminos%20y%20condiciones%20amigos%20coninsa_2024.pdf?VersionId=TMGu47pJJiwp__CTF1Insrz_9YJc6gLW",
  },
  {
    id: "terms-2024-2",
    icon: documentIcon,
    label: "Bolsa de mantenimientos",
    url: "https://d2xt7650da4t25.cloudfront.net/s3fs-public/2024-09/terminos%20y%20condiciones%20bolsa%20de%20mantemiento_2024.pdf?VersionId=cirBkpGb_lLMgr31jTaph5SuKpWZBwZr",
  },
  {
    id: "terms-2024-3",
    icon: documentIcon,
    label: "Renta Garantizada",
    url: "https://d2xt7650da4t25.cloudfront.net/s3fs-public/2024-09/Manual%20Cliente%20RGx4_compressed.pdf?VersionId=L9l9r6BeneQGwk.eFVaWtlBK1TonU4fw",
  },
  {
    id: "terms-2024-4",
    icon: documentIcon,
    label: "Crédito de Acabados Avanto",
    url: "https://d2xt7650da4t25.cloudfront.net/s3fs-public/2023-10/Terminos%20y%20condiciones%20Credito%20de%20acabados%20avanto_c.pdf?VersionId=36WOzGbsjiI1p_rS5xyKYA6DKmoUJzIM",
  },
  {
    id: "terms-2024-5",
    icon: documentIcon,
    label: "Plan porteros",
    url: "https://d2xt7650da4t25.cloudfront.net/s3fs-public/2024-10/Terminos%20y%20condiciones%20plan%20porteros%202024.pdf?VersionId=0JYP8GtZ2REiREdtFMkday.meQL19pRh",
  },
];
