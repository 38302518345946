export const t = (text: string): string => {
  const translations = {
    CUUT: "Cuarto útil",
    UTIL: "Cuarto útil",
    PARS: "Parqueadero sencillo",
    PADL: "Parqueadero doble lineal",
    PASU: "Parqueadero sencillo con cuarto útil",
    "PARS-PARS": "Parqueadero sencillo",
    "PASU-PASU": "Parqueadero sencillo con cuarto útil",
    "UTIL-UTIL": "Cuarto útil",
  };

  return translations[text] || text;
};
