import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Image } from 'theme-ui'

import mapIcon from '../../../assets/icons/maps-icon.svg'
import wazeIcon from '../../../assets/icons/waze-icon.svg'

function Schedules({ schedules, latLon, ...props }) {
  const wazeUrl = `https://www.waze.com/live-map/directions?navigate=yes&to=ll.${latLon?.lat}%2C${latLon?.lon}`
  const mapUrl = `https://maps.google.com/maps?q=${latLon?.lat},${latLon?.lon}`

  const titleStyles = {
    fontWeight: '600',
    fontSize: '14px',
  }

  const baseStyles = {
    fontSize: 1,
    mb: '12px',

    '& p': {
      color: 'text',
      display: 'block',
      mb: 2,
    },

    '& p strong': {
      ...titleStyles,
    },
  }

  const btnStyles = {
    bg: 'white',
    filter:
      'drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))',
    color: 'dark-4',
    cursor: 'pointer',
    fontWeight: '600',
    fontSize: ['14px', '16px'],
    px: 'medium',
    display: 'flex',
    alignItems: 'center',

    '&:hover': {
      filter:
        'drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1))',
    },
  }

  return (
    <Box {...props}>
      <Box sx={baseStyles} dangerouslySetInnerHTML={{ __html: `${schedules}` }} />
      {latLon?.lat !== 0 && latLon?.lon !== 0 && (
        <>
          <Box sx={titleStyles}>Cómo llegar:</Box>
          <Box sx={{ display: 'flex', gap: '16px', mt: 'small' }}>
            <Button
              as='a'
              href={wazeUrl}
              target='_blank'
              sx={btnStyles}
              variant='default'
            >
              <Image
                sx={{ width: '24px', height: '22px', mr: '6px' }}
                src={wazeIcon}
              />
              Waze
            </Button>

            <Button
              as='a'
              href={mapUrl}
              target='_blank'
              sx={btnStyles}
              variant='default'
            >
              <Image sx={{ width: '24px', height: '22px' }} src={mapIcon} />
              Google Maps
            </Button>
          </Box>
        </>
      )}
    </Box>
  )
}

Schedules.defaultProps = {
  schedules: '',
}

Schedules.propTypes = {
  schedules: PropTypes.string,
  latLon: PropTypes.shape({
    lat: PropTypes.string,
    lon: PropTypes.string,
  }).isRequired,
}

export default Schedules
