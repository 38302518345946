import React from "react";
import Splider from "component-v2/Splider";
import { SplideSlide } from "@splidejs/react-splide";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from 'gatsby'

import CountCard from "./../architecture/components/CountCard";
import SummaryProjects from "./../architecture/components/SummaryProjects";
import AchievementCard from "../architecture/components/AchievementCard";
import WorkCard from "../architecture/components/WorkCard";
import ContactSection from "../architecture/components/ContactSection";

import videoBanner from "./../assets/images/video-construction.mp4";
import constructionImg from "../assets/images/construction.jpg";
import bgGreen from "./../assets/images/construction-bg.jpg";
import sustainabilityBg from "../assets/images/sustainability-construction.jpg";
import bgBlue from "./../assets/images/construction-blue-bg.jpg";

import data from "./data";
import brandCompanies from "./../data/companies.js";

function ConstructionPage() {
  const queryData = useStaticQuery(graphql`
    query OurMainConstructionProjects {
      drupal {
        ourProjects: nodeQuery(
          filter: {
            conditions: [
              { operator: EQUAL, field: "status", value: ["1"] }
              { operator: EQUAL, field: "type", value: ["our_project"] }
              {
                operator: IN
                field: "nid"
                value: [
                  "911"
                  "829"
                  "864"
                  "922"
                  "863"
                  "912"
                  "910"
                  "859"
                  "907"
                  "777"
                ]
              }
            ]
          }
        ) {
          entities {
            id: entityId
            url: entityUrl {
              path
            }

            ... on Drupal_NodeOurProject {
              title
              type: fieldType {
                entity {
                  ... on Drupal_TaxonomyTermOurProjectTypes {
                    __typename
                    tid
                    name
                  }
                }
              }
              images: fieldGallery {
                entity {
                  ...MediaImageNoGatsby
                }
              }
              body {
                value
              }
              area: fieldBuiltArea
              consortium: fieldConsortium
              weight: fieldWeight
              location: fieldLocation
              owner: fieldOwner
              state: fieldProgress
              year: fieldBuiltYear
            }
          }
        }

        summaryTerms: countOurProjectsByTermGraphqlView {
          results {
            id
            name
            count
          }
        }
      }
    }
  `)

  const slideSettings = {
    autoplay: true,
    perMove: 1,
    perPage: 5,
    rewind: true,
    padding: { left: 0, right: "12rem" },
    breakpoints: {
      1024: {
        perPage: 4,
        padding: { left: 0, right: "8rem" },
      },
      768: {
        perPage: 4,
        padding: { left: 0, right: "4rem" },
      },
      640: {
        perPage: 2,
        padding: { left: 0, right: "4rem" },
      },
    },
  };

  return (
    <div>
      <Helmet>
        <title>Servicios de Construccion | Coninsa</title>
        <meta
          name="description"
          content="Contempla desde el diseño urbanístico hasta el arquitectónico y de interiorismo, en los que llevamos más de cinco décadas trabajando en todo el país con soluciones para diferentes sectores."
        />
        <link rel="canonical" href="https://www.coninsa.co/construccion/" />
      </Helmet>

      <video width="100%" autoplay="" muted loop>
        <source src={videoBanner} type="video/mp4" />
      </video>

      <div className="bg-coninsa-gray-300 py-10 md:py-14">
        <div className="block md:container md:flex justify-between gap-4 px-5 md:px-0">
          {data.map((item) => (
            <CountCard
              key={item.id}
              seconds={item?.number}
              highlighted={item?.title}
              text={item?.subtitle}
              measure={item?.measure}
              time={item?.time}
              start={item?.start}
              limit={item?.limit}
            />
          ))}
        </div>
      </div>

      <div className="py-10 md:py-16 bg-coninsa-blue-900">
        <div className="px-5 md:grid md:grid-cols-2 gap-4 md:px-0 container">
          <div className="md:w-[602px] md:h-[336px] object-cover mb-4 md:mb-0">
            <a
              href="https://www.youtube.com/watch?v=Eywfr759xso"
              target="_blank"
            >
            <img
              src={constructionImg}
              alt="Construction image"
              className="rounded-xl w-full h-full"
            />
            </a>
          </div>

          <div>
            <h2 className="font-display leading-8 md:leading-[44px] mb-4 md:mb-6 text-3xl md:text-[44px] text-coninsa-white-300 uppercase">
              Construcción
              <span className="block font-semibold text-coninsa-green-400">
                pública y privada
              </span>
            </h2>
            <p className="text-coninsa-white-300 text-sm md:text-lg md:leading-6">
              <strong>Por nuestra calidad y cumplimiento</strong>, empresas de
              todo el país, nos confían el desarrollo de obras a su medida.
              Ofrecemos soluciones innovadoras y competitivas como respuesta a
              sus necesidades de diseño, construcción y de trabajo en equipo con
              sus Diseñadores, Técnicos, Interventores, Consultores, Proveedores
              y Subcontratistas.
            </p>
          </div>
        </div>
      </div>

      <div className="pt-10 md:pt-16">
        <div id="experiencias" className="mb-5 md:mb:0">
          <div className="px-5 md:px-0 container">
            <h2 className="font-display leading-8 md:leading-[44px] mb-4 md:mb-6 text-3xl md:text-[44px] text-coninsa-green-500 uppercase">
              Nuestra
              <span className="block font-semibold text-coninsa-blue-900">
                experiencia
              </span>
            </h2>
            <p className="text-sm md:text-base">
              Contempla desde el diseño urbanístico hasta el arquitectónico y de
              interiorismo, en los que <br /> llevamos más de cinco décadas
              trabajando en todo el país con soluciones para diferentes
              sectores.
            </p>
          </div>
        </div>

        <div
          className="md:relative md:bg-coninsa-green-300 md:before:content md:before:block md:before:h-56 md:before:bg-white
          before:absolute before:top-0 before:w-full before:z-0"
          style={{ backgroundImage: `url(${bgGreen})` }}
        >
          <div className="md:absolute md:bottom-0 md:w-full md:z-0 content block md:bg-coninsa-gray-300 md:h-32" />

          <div className="container md:flex py-8">
            <SummaryProjects data={queryData?.drupal} baseCategoryUrl='/construccion/proyectos' />
          </div>

          <a
            href="/arquitectura/nuestra-experiencia"
            target="_blank"
            className="block hero-compact md:!h-[360px] z-10 container md:rounded-2xl border overflow-hidden relative py-4"
            style={{ backgroundImage: `url(${sustainabilityBg})` }}
          >
            <div className="hero-compact__container !bg-[#223983] md:!bg-transparent md:container z-10">
              <div className="hero-compact__content !px-0 md:!px-8">
                <span className="font-heading font-[300] text-white text-xl md:text-2xl">
                  Conoce
                </span>
                <h1 className="hero-compact__title font-heading">
                  <span className="!text-2xl md:!text-4xl">
                    cómo aplicamos la
                  </span>
                  <span className="!text-coninsa-green-300">
                    sostenibilidad
                  </span>
                  <span className="!text-2xl md:!text-4xl">
                    en nuestros proyectos
                  </span>
                </h1>
                <span className="font-body text-end text-white text-xl md:text-[26px] font-medium">
                  y las certificaciones obtenidas
                </span>
                <div className="text-center mt-5 md:mt-6">
                  <a
                    href="/arquitectura/nuestra-experiencia"
                    className="bg-transparent text-white text-sm font-semibold border border-white rounded-[21px]
                      py-[10px] px-3 md:px-6 hover:bg-white hover:text-coninsa-blue-900"
                  >
                    Conoce más información
                  </a>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>

      <div
        className="md:relative md:before:absolute md:before:bottom-0 md:before:w-full md:before:z-0 md:before:content md:before:block
         md:before:h-24 bg-coninsa-gray-300 pt-10 pb-5 md:py-16"
      >
        <div className="px-5 md:px-0 container">
          <div className="z-10">
            <Splider slot="content" options={slideSettings}>
              {brandCompanies.map((brand) => (
                <SplideSlide>
                  <div className="flex gap-4 mb-8">
                    <div className="rounded-lg px-2 py-3 shadow-[0px_3px_6px_#00000029]">
                      <img src={brand.icon} />
                    </div>
                  </div>
                </SplideSlide>
              ))}
            </Splider>
          </div>
        </div>
      </div>

      <div
        className="bg-coninsa-blue-900 py-10 md:py-16"
        style={{ backgroundImage: `url(${bgBlue})` }}
      >
        <div
          className="container inline-block md:grid grid-cols-[30%_70%] gap-4 items-center px-5 md:px-0"
          id="premios-y-reconocimientos"
        >
          <div>
            <h2 className="font-display leading-8 mb-4 text-3xl md:leading-10 md:mb-6 md:text-[44px] text-white uppercase">
              <span className="block font-bold text-coninsa-green-400">
                Logros
              </span>
              y reconocimientos
            </h2>
            <p className="text-white text-sm md:text-base mb-5 md:mb-0">
              Conoce los logros y reconocimientos que hemos obtenido a lo largo
              de estos más de 50 años.
            </p>
          </div>
          <AchievementCard />
        </div>
      </div>

      <div className="py-10 md:py-14">
        <div className="container px-5 md:px-0">
          <div className="mb-8">
            <h2 className="font-display leading-8 mb-4 text-3xl md:leading-10 md:mb-6 md:text-[44px] text-coninsa-green-500 uppercase">
              <span className="block font-bold text-coninsa-blue-900">
                Conoce nuestro blog
              </span>
              arquitectura y grandes obras
            </h2>

            <button className="bg-coninsa-green-300 font-bold px-6 py-3 rounded-lg text-white text-sm uppercase hover:bg-coninsa-green-500">
              Todos los artículos
            </button>
          </div>

          <WorkCard />
        </div>
      </div>

      <ContactSection />
    </div>
  );
}

export default ConstructionPage;
