import React from 'react';
import clsx from "clsx";

import "./styles.css";

function CtaCard({ tint = "white", href, className, ...props }) {
  const dynamicClasses = {
    [`s2-cta-card--${tint}`]: tint,
  };

  return (
    <a href={href} target="_blank" className={clsx("s2-cta-card", dynamicClasses, className)}>
      {props.children}
    </a>
  );
}

export default CtaCard;
