// import {Injectable} from '@angular/core';
// import {NotificacionesService} from './notificaciones.service';
// import {ApiService} from './api.service';
// import {PropuestaConstructor} from '../models/propuestaConstructor';
import { User } from "../models/user";
// import {ApiConinsaService} from './apiConinsa.service';
// import {UnidadOpcionada} from '../models/unidadOpcionada';
// import {Opcion} from '../models/opcion';
// import {Projects} from '../models/projects';
// import {Filtros} from '../models/filtros';
import { Cotizacion } from "../models/cotizacion";
// import {ClienteService} from '../../cliente/services/cliente.service';
import { CreditoHipotecario } from "../models/creditoHipotecario";
// import {Parametros} from '../models/parametros';
// import { CurrencyConvertService } from './currencyConvert.service';
import { QuotationCurrencyConvert } from "./quotationCurrencyConvert.service";

// declare var moment: any;
// declare var $: any;

// @Injectable()

export class CotizadorService {
  // private _path: any;
  private _opcionado: number;
  // private opcion: Opcion;
  // public cotizacion: Cotizacion;
  public creditoHipoticario: CreditoHipotecario[] = [];

  constructor(
    //     private _ns: NotificacionesService,
    //     private _as: ApiService,
    //     private _acs: ApiConinsaService,
    //     private _cl: ClienteService,
    private _qcc: QuotationCurrencyConvert
  ) {
    this._opcionado = 0;
  }

  // get opcionado(): number {
  //     return this._opcionado;
  // }

  // set opcionado(value: number) {
  //     this._opcionado = value;
  // }

  // get path(): any {
  //     return this._path;
  // }

  // set path(value: any) {
  //     this._path = value;
  // }

  // /**
  //  * Genera PDF
  //  * @param propuestaConstructor
  //  * @param tipoPersonaJuridica
  //  * @param user
  //  */
  public async generarPdf(
    cotizacion: Cotizacion,
    tipoPersonaJuridica: number,
    user: User,
    presentaPropuesta: boolean,
    creditYear: number,
    currency: string = "cop",
    exchange: number = 1,
    note: string = ""
  ) {
    const quotationConvert = this._qcc.convertQuotation(
      cotizacion,
      creditYear,
      currency,
      exchange,
      note
    );
    const url = "https://admin-cotizador.coninsa.co/";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(quotationConvert),
    };

    const response = await fetch(url + "api/simulations/pdf", options);
    const data = await response.json();

    return data;

    // this._ns.downdloadAlert('Generando PDF');
    // this._as.generarPdfService(quotationConvert).subscribe(
    //   response => {
    //     this._path = response.data;
    //     this._ns.closeAlert();
    //     if (this._opcionado === 1) {
    //         this._ns.successAlert('El inmueble ' + cotizacion.propuestaConstructor.unidades[0].nomenclatura + ' del proyecto ' + cotizacion.propuestaConstructor.proyecto + ' fue opcionado exitosamente al contacto con Id ' + cotizacion.propuestaConstructor.id_zoho + ' hasta el ' + cotizacion.propuestaConstructor.unidades[0].fechaOpcion + '. Tenga en cuenta que al momento de confirmar la venta del inmueble, este contacto debe ser el Comprador principal. \n La cotización ya se encuentra relacionada en ZOHO CRM.');
    //     }
    //     if (cotizacion.propuestaConstructor.id_zoho) {
    //       this.saveQuotationsZoho(cotizacion.propuestaConstructor, tipoPersonaJuridica, user, presentaPropuesta);
    //     }

    //     // Se reinicia opcionado
    //     this._opcionado = 0;
    //   },
    //   error => {
    //     this._ns.errorAlert('Error generando el PDF, por favor comunicarse con el administrador del sistema');
    //   }
    // );
  }

  // /**
  //  * Guarda la información de la cotización en zoho
  //  * @param propuestaConstructor
  //  * @param tipoPersonaJuridica
  //  * @param user
  //  */
  // public saveQuotationsZoho(propuestaConstructor: PropuestaConstructor, tipoPersonaJuridica: number, user: User, presentaPropuesta: boolean): any {
  //     this._path.url_history = window.location.host + `/consulta/listado-cotizaciones?id_zoho=${propuestaConstructor.id_zoho}`;
  //     const parametros: Object = {
  //         id_zoho: propuestaConstructor.id_zoho,
  //         ultimaCotizacion: this._path.url_last,
  //         historialCotizaciones: this._path.url_history,
  //         opcionado: (this._opcionado === 1) ? true : false,
  //         inmuebleOpcionado: propuestaConstructor.unidades[0].nomenclatura,
  //         fechaVigenciaOpcion: moment(propuestaConstructor.unidades[0].fechaOpcion, 'DD-MM-YYYY').format('MM/DD/YYYY'),
  //         tipoPersona: tipoPersonaJuridica,
  //         company: user.primerNombre,
  //         tipoIdentificacion: propuestaConstructor.type_document,
  //         firstName: user.primerNombre + ' ' + user.segundoNombre,
  //         lastName: user.primerApellido + ' ' + user.segundoApellido,
  //         documentoId: user.cedula,
  //         email: user.email,
  //         mobile: user.telefono,
  //         areaConstruida: propuestaConstructor.unidades[0].areaConstruida,
  //         presentaPropuesta: presentaPropuesta
  //     };

  //     this._acs.getTokenZoho().subscribe(
  //         token => {
  //             this._acs.saveQuotationsZohoService(parametros, token.access_token).subscribe(
  //                 response => {

  //                 },
  //                 error => {
  //                     this._ns.closeAlert();
  //                     this._ns.errorAlert('Error al obtener el access token de zoho');
  //                 }
  //             );
  //         },
  //         error => {
  //             this._ns.closeAlert();
  //             this._ns.errorAlert('Error al guardar el lead en zoho');
  //         }
  //     );
  // }

  // public procesoOpcionarGenerarPdf(cotizacion: Cotizacion, user: User, selectProject: Projects, tipoPersonaJuridica: number, filtros: Filtros, presentaPropuesta:boolean, creditYear: number, currency: string = 'cop', exchange: number = 1, note: string = ''): any {
  //     this._ns.downdloadAlert('Opcionando Unidades');
  //     var fechaCotizacion = moment().format('DD-MM-YYYY');
  //     var unidadesOpcionadas = [];
  //     var fechaInicialOpcionUnidad = cotizacion.propuestaConstructor.unidades[0].fechaFinalOpcion != '' ? cotizacion.propuestaConstructor.unidades[0].fechaFinalOpcion.trim() : fechaCotizacion;
  //     var fechaFinalOpcionUnidad = moment(cotizacion.propuestaConstructor.unidades[0].fechaFinalOpcion != '' ? cotizacion.propuestaConstructor.unidades[0].fechaFinalOpcion.trim() : fechaCotizacion, 'DD-MM-YYYY').add(selectProject[0].diasSepara, 'days').format('DD-MM-YYYY');

  //     cotizacion.propuestaConstructor.unidades.forEach(x => {
  //         if (x.tipo == 1) {
  //             unidadesOpcionadas.push(
  //                 new UnidadOpcionada(
  //                     x.idUnidad,
  //                     x.tipo
  //                 )
  //             );
  //         }
  //     });

  //     // Se crea el objeto que se enviará al servicio opcionar
  //     this.opcion = new Opcion(
  //         filtros.empresa,
  //         selectProject[0].idProyecto,
  //         unidadesOpcionadas,
  //         moment(fechaInicialOpcionUnidad, 'DD-MM-YYYY').format('DD/MM/YYYY'),
  //         moment(fechaFinalOpcionUnidad, 'DD-MM-YYYY').format('DD/MM/YYYY'),
  //         user.type_document,
  //         user.cedula,
  //         user.primerNombre,
  //         user.segundoNombre,
  //         user.primerApellido,
  //         user.segundoApellido
  //     );
  //     // Se llama la funcion que apunta al servicio opcionar
  //     this._acs.opcionarService(this.opcion).subscribe(
  //         response => {
  //             cotizacion.propuestaConstructor.unidades[0].fechaOpcion = fechaFinalOpcionUnidad;
  //             this._ns.closeAlert();
  //             // Ocultar el boton de opcionar
  //             $('#btn-opcionar').hide();
  //             // Indicamos que se debe opcionar en zoho
  //             this._opcionado = 1;
  //             this._ns.successAlert('EL inmueble fue opcionado exitosamente al contacto con id ' + user.cedula + ' hasta la fecha ' + cotizacion.propuestaConstructor.unidades[0].fechaOpcion + ' Recuerde que al momento de confirmar la venta este contacto debe ser el comprador principal.');
  //             // Genera pdf cotización y guardado de registro en zoho
  //             this.cotizacion = cotizacion;
  //             this.generarPdf(this.cotizacion, tipoPersonaJuridica, user, presentaPropuesta, creditYear, currency, exchange, note);
  //         },
  //         error => {
  //             this._ns.closeAlert();
  //             this._ns.errorAlert();
  //         }
  //     );
  // }

  public calcularCreditoHipotecario(
    valorCredito: number,
    valorTotal: number,
    periods: any,
    vis: any
  ) {
    const credit = periods.map((period, i) => {
      const porEndeudamiento =
        (vis === "1"
          ? period.indebtedness_vis_percentage
          : period.indebtedness_percentage) / 100;
      const data = new CreditoHipotecario(
        period.period,
        valorCredito,
        period.rate,
        0,
        0,
        0,
        0
      );
      data.valorCuota =
        i != 3
          ? valorCredito *
            Number(
              vis === "1"
                ? period.multiplying_factor_vis
                : period.multiplying_factor
            )
          : 0;
      data.ingresosReq = i != 3 ? data.valorCuota / porEndeudamiento : 0;
      data.valorCuotaUVR =
        valorCredito *
        Number(
          vis === "1"
            ? period.uvr_multiplying_factor_vis
            : period.uvr_multiplying_factor
        );
      data.ingresosReqUVR = data.valorCuotaUVR / Number(porEndeudamiento);

      return data;
    });
    return credit;
  }

  // public cargarParametros(): Parametros[] {
  //     var parametros: Parametros[];
  //     parametros = JSON.parse(sessionStorage.getItem('parametros'));
  //     return parametros;
  // }
}
