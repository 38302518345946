import React, { useEffect, useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import InputMask, { removeMaskFormat } from "components/form/InputMask";
import { Helmet } from "react-helmet";
import Footsteps from "@coninsa-ui/react-footsteps";
import Layout from "../layout/default/Layout";
import {
  getRegional,
  getConsignors,
  getEmailConsignor,
} from "modules/authorization-data/data/consignor";
import bannerImage from "../images/generic-banner.png";
import budgetIcon from "../assets/icons/rounded/budget.svg";
import adviceIcon from "../assets/icons/rounded/advice.svg";
import documentsIcon from "../assets/icons/rounded/documents.svg";
import rateImg from "../images/rate.jpg";
import { getQueryString, setQueryString } from "../utils/query-string.js";
import { WEBFORM_SUBMIT } from "../modules/authorization-data/mutations.js";

import FileInput from "../modules/file/FileInput.js";
import FilesInput from "../modules/file/FilesInput.js";

import { ADVISORS_QUERY } from "../modules/authorization-data/data/queries";

function getTotalRate(propertyType, buildingServiceType) {
  const info = {
    Vivienda: {
      "Súper Premium": 11,
      Premium: 10.8,
      Estándar: 10.5,
      Básica: 10,
    },
    Comercio: {
      Premium: 10,
      Estándar: 9,
      Básica: 8,
    },
  };

  return info[propertyType][buildingServiceType];
}

function getTotalProtections(propertyType, buildingProtections) {
  const info = {
    Vivienda: {
      "Amparos de servicios públicos": 0.4,
      "Amparos de daños y faltantes": 0.6,
      "Amparo de vacancia": 2.9,
      "Bolsa de mantenimiento": 0.0,
    },
    Comercio: {
      "Amparos de servicios públicos": 0.4,
      "Amparos de daños y faltantes": 0.6,
    },
  };

  const protections = info[propertyType];
  const totalProtections = buildingProtections.reduce(
    (accumulator, currentValue) => {
      accumulator += protections[currentValue];
      return accumulator;
    },
    0
  );

  return totalProtections;
}

function getMaintenanceBag(buildingServiceType) {
  const info = {
    "Súper Premium": 500000,
    Premium: 300000,
    Estándar: 200000,
    Básica: 0,
  };

  return info[buildingServiceType];
}

const data = [
  {
    icon: budgetIcon,
    title: "Administramos",
    body: "más de 6000 inmuebles a nivel nacional.",
  },

  {
    icon: adviceIcon,
    title: "Atendemos",
    body: "más de 23.000 clientes entre personas naturales y jurídicas.",
  },

  {
    icon: documentsIcon,
    title: "Contamos con",
    body: "dos líneas especializadas de negocio que son vivienda y comercio e industria.",
  },
];

export default function DataAuthorization({ location }) {
  const ref = useRef(null);
  const { data: advisorsQueryData, loading } = useQuery(ADVISORS_QUERY);

  const [webformSubmit] = useMutation(WEBFORM_SUBMIT);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    control,
    formState: { isSubmitting },
  } = useForm();
  const [consignorOptions, setConsignorOptions] = useState([]);
  const [step, setStep] = useState(null);

  const [totalRate, setTotalRate] = useState(0);
  const [totalProtections, setTotalProtections] = useState(0);
  const [maintenanceBag, setMaintenanceBag] = useState(0);

  const regional = watch("regional");
  const consignorConsultantName = watch("consignor_consultant_name");
  const buildingServiceType = watch("building_service_type");
  const personType = watch("person_type");
  const serviceType = watch("service_type");
  const propertyType = watch("property_type");
  const parkingType = watch("building_parking_lot_type");
  const sid = watch("sid");
  const buildingProtections = watch("building_protections");

  useEffect(() => {
    if (serviceType) {
      setQueryString("service_type", serviceType);
    }
  }, [serviceType]);

  useEffect(() => {
    if (propertyType) {
      setQueryString("property_type", propertyType);
    }
  }, [propertyType]);

  useEffect(() => {
    if (regional) {
      setValue("consignor", "");
      setValue("consignor_consultant_email", "");

      setConsignorOptions(getConsignors(advisorsQueryData, regional));
    }
  }, [regional]);

  useEffect(() => {
    if (consignorConsultantName) {
      const email = getEmailConsignor(
        advisorsQueryData,
        regional,
        consignorConsultantName
      );
      setValue("consignor_consultant_email", email);
    }
  }, [consignorConsultantName]);

  useEffect(() => {
    if (buildingServiceType) {
      const totalValue = getTotalRate(propertyType, buildingServiceType);
      setTotalRate(totalValue);
    }
  }, [buildingServiceType]);

  useEffect(() => {
    if (buildingProtections) {
      const totalValue = getTotalProtections(propertyType, buildingProtections);
      setTotalProtections(totalValue);

      if (buildingProtections.includes("Bolsa de mantenimiento")) {
        const maintenanceBag = getMaintenanceBag(buildingServiceType);
        setMaintenanceBag(maintenanceBag);
      }
    }
  }, [buildingProtections]);

  useEffect(function () {
    const step = getQueryString("step", location.search) ?? 1;
    const sid = getQueryString("sid", location.search);
    const serviceType = getQueryString("service_type", location.search);
    const personType = getQueryString("person_type", location.search);
    const propertyType = getQueryString("property_type", location.search);

    if (step) {
      setStep(parseInt(step));
    }

    if (sid) {
      setValue("sid", sid);
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }

    if (serviceType) {
      setValue("service_type", serviceType);
    }

    if (personType) {
      setValue("person_type", personType);
    }

    if (propertyType) {
      setValue("property_type", propertyType);
    }
  }, []);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const regionalOptions = getRegional(advisorsQueryData);

  const onSubmit = async ({ price, ...data }) => {
    if (price) {
      const cleanedPrice = removeMaskFormat(price);
      data.price = cleanedPrice;
    }

    localStorage.setItem(`data_authorization_${step}`, JSON.stringify(data));

    setStep(step + 1);

    if (step === 5) {
      const step1 = JSON.parse(localStorage.getItem("data_authorization_1"));
      const step2 = JSON.parse(localStorage.getItem("data_authorization_2"));
      const step3 = JSON.parse(localStorage.getItem("data_authorization_3"));
      const step4 = JSON.parse(localStorage.getItem("data_authorization_4"));
      const step5 = JSON.parse(localStorage.getItem("data_authorization_5"));
      const stepsData = { ...step1, ...step2, ...step3, ...step4, ...step5 };

      const body = {
        ...stepsData,
        webform_id: "authorization_data",
      };

      if (body.tradition_freedom_certificate) {
        body.tradition_freedom_certificate =
          body.tradition_freedom_certificate.map((file) => file.targetId);
      }

      if (body.id_document) {
        body.id_document = body.id_document.targetId;
      }

      if (body.chamber_of_commerce) {
        body.chamber_of_commerce = body.chamber_of_commerce.targetId;
      }

      if (!body.building_protections) {
        body.building_protections = [];
      }

      try {
        const response = await webformSubmit({
          variables: {
            values: JSON.stringify(body),
          },
        });
      } catch (error) {}
    }
  };

  const onReset = () => {
    reset();
    setStep(1);
  };

  const totalAmount = (totalRate + totalProtections).toFixed(1);

  return (
    <Layout>
      <Helmet>
        <title>Autorización de inmuebles | Coninsa</title>
        <meta
          name="description"
          content="Nuestra gestión integral de todo lo relacionado con el negocio de bienes raíces nos permite ofrecerte líneas especializadas de servicios y productos que te ayuden a administrar de forma ágil y fácil tus inmuebles. Estamos ubicados en Antioquia, Bogotá y Costa Atlántica donde te ofrecemos un acompañamiento completo en el proceso de la administración de tus propiedades."
        />
        <link
          rel="canonical"
          href="https://www.coninsa.co/autorizacion-consignacion-inmuebles/"
        />
      </Helmet>
      <div
        style={{ backgroundImage: `url(${bannerImage})` }}
        className="bg-contain bg-no-repeat px-5 py-8 md:bg-cover md:px-0 md:py-44"
      >
        <div className="md:container">
          <h2 className="font-display text-lg font-medium uppercase leading-6 text-coninsa-blue-900 md:w-[420px] md:text-4xl">
            Estamos a un solo paso de administrar tu propiedad
          </h2>
        </div>
      </div>

      <div className="container py-8">
        <p className="text-sm md:text-base">
          Nuestra gestión integral de todo lo relacionado con el negocio de
          bienes raíces nos permite ofrecerte líneas especializadas de servicios
          y productos que te ayuden a administrar de forma ágil y fácil tus
          inmuebles. Estamos ubicados en Antioquia, Bogotá y Costa Atlántica
          donde te ofrecemos un acompañamiento completo en el proceso de la
          administración de tus propiedades.
        </p>
      </div>

      <div className="container py-6 md:py-12">
        <div className="mb-4 block grid-cols-3 gap-x-4 gap-y-24 px-5 text-sm md:grid md:px-0 md:text-base">
          {data?.map((item) => (
            <div
              className="border-transparentpx-4 mb-[70px] rounded-lg border border-solid bg-coninsa-white-300 px-4 pb-4 opacity-100 shadow-[0px_3px_6px_#00000029] last:mb-0 md:mb-0 md:px-5 md:pb-5"
            >
              <div className="relative mb-4 -translate-y-5 border md:mb-7 md:ml-4">
                <img
                  alt="item-icon"
                  src={item?.icon}
                  className="absolute -left-1 -top-8 z-10 block h-[85px] w-[85px] md:-left-4 md:-top-10 md:h-[110px] md:w-[110px]"
                />
                <div className="rounded-[4px] bg-coninsa-green-400 py-4" />

                <span
                  className="border-b-[20px]border-b-cobg-coninsa-green-400 absolute right-8 top-8 h-0 w-0 rotate-180 border border-r-[22px] border-t-[20px] border-l-transparent border-r-transparent border-t-transparent md:right-9 md:top-9"
                />
              </div>

              <h5 className="text-base font-bold text-coninsa-blue-900 md:text-[17px]">
                {item?.title}
              </h5>
              <p className="leading-5 text-coninsa-gray-900">{item?.body}</p>
            </div>
          ))}
        </div>

        <p className="mb-6 text-sm md:mb-12 md:text-base">
          *Este formulario es únicamente para continuar con el proceso de
          promoción y administración del inmueble. Si tienes otro tipo de
          solicitud te invitamos a realizarla por{" "}
          <a
            href="https://www.coninsa.co/servicio-cliente/"
            target="_blank"
            rel="noreferrer"
            className="text-coninsa-blue-300 hover:underline"
          >
            https://www.coninsa.co/servicio-al-cliente
          </a>
        </p>

        <h3 className="mb-8 font-display text-lg font-semibold uppercase leading-6 text-coninsa-blue-900 md:text-3xl">
          Formulario de autorización, promoción del inmueble y condiciones
          comerciales
        </h3>

        <div
          className="mb-8 grid w-full flex-shrink-0 grid-cols-1 items-center justify-items-center gap-2 rounded-lg bg-coninsa-blue-900 p-4 opacity-100 md:px-6 md:py-9 lg:grid-cols-[70%_30%]"
        >
          <p className="text-base font-light text-white md:text-lg">
            <span className="block font-bold">Estimado cliente,</span>
            agradecemos tu confianza depositada en nuestra Compañía al
            entregarnos el inmueble para la comercialización y administración
            del contrato de arrendamiento. En este formulario te daremos a
            conocer los beneficios con los que puedes contar, para que nuestra
            relación comercial sea la mejor.
          </p>
          <div>
            <button
              onClick={handleClick}
              type="button"
              className="inline-block cursor-pointer rounded-md border border-white px-5 py-[10px] text-sm font-bold uppercase text-white hover:bg-white hover:text-coninsa-green-300 md:text-base"
            >
              Iniciar aquí
            </button>
          </div>
        </div>

        <div className="mb-4">
          <h4 className="mb-3 text-base font-bold md:mb-4 md:text-lg">
            Ten en cuenta lo siguiente:
          </h4>
          <p className="mb-3 text-sm md:text-base">
            <span className="block font-medium">
              Arriendo por administración:
            </span>
            se cobra tarifa mensual +IVA seleccionada por el cliente al momento
            del cierre del negocio, este se cobrará después de que el inquilino
            ocupe el inmueble (estado arrendado).
          </p>
          <div className="mb-3">
            <p className="mb-3 text-sm md:text-base">
              <span className="block font-medium">
                Arriendo por colocación:
              </span>
              se cobra un canon de arrendamiento +IVA si el contrato es a 12
              meses. Si es superior a 2 años se cobran 2 cánones de
              arrendamiento.
            </p>
            <p className="text-sm md:text-base">
              en dado caso que la administración del contrato de arrendamiento
              del inmueble quede a cargo del propietario, les presentamos
              nuestros honorarios por la presentación del cliente: duración del
              contrato de arrendamiento:
            </p>
            <ul className="list-inside list-disc text-sm md:text-base">
              <li>Hasta 24 meses: un (1) canon de arriendo.</li>
              <li>Entre 25 y 60 meses: dos (2) cánones de arriendo.</li>
              <li>más de 61 meses: tres (3) cánones de arriendo.</li>
            </ul>
          </div>
          <p className="text-sm md:text-base">
            <span className="block font-medium">Venta de inmueble:</span>
            recuerda que nuestra tarifa es del 3% de comisión +IVA. En Coninsa
            se cobra el 100% de la comisión a la firma de la promesa de
            compraventa.
          </p>
        </div>
      </div>

      <div
        ref={ref}
        className="bg-zinc-50 py-8 md:py-16"
        id="authorization-data"
      >
        <div className="container">
          <div className="mx-auto mb-6 max-w-2xl text-center md:mb-12">
            <h3 className="mb-4 font-display font-semibold uppercase leading-6 text-coninsa-blue-900 md:mb-8 md:text-3xl">
              Formulario de autorización, promoción del inmueble y condiciones
              comerciales
            </h3>
            <p className="text-base md:text-xl">
              Diligencie los datos del formulario lo más completo posible para
              iniciar el proceso.
            </p>
          </div>

          <Footsteps
            steps={[
              { label: 1, desc: "Datos Personales" },
              { label: 2, desc: "Información general" },
              { label: 3, desc: "Información del inmueble" },
              { label: 4, desc: "Información del propietario" },
              { label: 5, desc: "Información especial" },
              { label: 6, desc: "Completo" },
            ]}
            className="mb-4"
            currentStep={step}
          />

          <div className="mx-auto max-w-5xl">
            {step === 1 && (
              <div className="co-card">
                <form className="co-form" onSubmit={handleSubmit(onSubmit)}>
                  <div className="flex flex-col gap-4 md:flex-row">
                    <div className="form-control flex-1">
                      <label for="full_name">
                        Nombre completo ó Razón Social
                      </label>
                      <input
                        {...register("full_name")}
                        type="text"
                        id="full_name"
                        required
                      />
                    </div>

                    <div className="form-control flex-1">
                      <label for="document_number">
                        Número de documento ó NIT
                      </label>
                      <input
                        {...register("document_number")}
                        type="text"
                        id="document_number"
                        required
                      />
                    </div>
                  </div>

                  <div className="flex flex-col gap-4 md:flex-row">
                    <div className="form-control flex-1">
                      <label for="email">Correo electrónico</label>
                      <input
                        {...register("email")}
                        type="text"
                        id="email"
                        required
                      />
                    </div>

                    <div className="form-control flex-1">
                      <label for="address">Dirección de residencia</label>
                      <input
                        {...register("address")}
                        type="text"
                        id="address"
                        required
                      />
                    </div>
                  </div>

                  <div className="flex flex-col gap-4 md:flex-row">
                    <div className="form-control flex-1">
                      <label for="phone">Número de celular</label>
                      <input
                        {...register("phone")}
                        type="text"
                        id="phone"
                        required
                      />
                    </div>

                    <div className="form-control flex-1">
                      <label for="person_type">
                        Selecciona el tipo de persona
                      </label>
                      <select
                        {...register("person_type")}
                        id="person_type"
                        required
                      >
                        <option value="">Selecciona una opción</option>
                        <option value="Jurídica">Jurídica</option>
                        <option value="Natural">Natural</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-control">
                    <fieldset className="form-group are-radios">
                      <legend>
                        ¿Único propietario por certificado de libertad?
                      </legend>
                      <div className="form-items">
                        <div className="form-control is-radio">
                          <input
                            {...register("sole_owner")}
                            type="radio"
                            id="sole_owner"
                            value="Si"
                            required
                          />
                          <label for="sole_owner">Si</label>
                        </div>
                        <div className="form-control is-radio">
                          <input
                            {...register("sole_owner")}
                            type="radio"
                            id="no_sole_owner"
                            value="No"
                            required
                          />
                          <label for="no_sole_owner">No</label>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <div className="form-actions flex justify-end">
                    <button className="co-button is-progress-default is-large">
                      Continuar
                    </button>
                  </div>
                </form>
              </div>
            )}

            {step === 2 && (
              <div>
                <form
                  className="co-card co-form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-control">
                    <label for="regional">
                      Selecciona la regional al que pertenece el inmueble a
                      promocionar
                    </label>
                    <select {...register("regional")} id="regional" required>
                      <option value="">Selecciona una opción</option>

                      {regionalOptions.map((regional) => (
                        <option key={regional} value={regional}>
                          {regional}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-control">
                    <label for="consignor_consultant_name">
                      Selecciona el Consultor Consignador Inmobiliario que esta
                      con su proceso de consignación
                    </label>
                    <select
                      {...register("consignor_consultant_name")}
                      id="consignor_consultant_name"
                      required
                      disabled={!regional}
                    >
                      <option value="">Selecciona una opción</option>
                      {consignorOptions.map((consignor) => (
                        <option key={consignor.id} value={consignor.name}>
                          {consignor.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-control is-hidden">
                    <input
                      {...register("consignor_consultant_email")}
                      type="text"
                      disabled
                    />
                  </div>

                  <div className="form-control">
                    <label for="service_type">
                      Selecciona el tipo de servicio
                    </label>
                    <select
                      {...register("service_type")}
                      id="service_type"
                      required
                    >
                      <option value="">Selecciona una opción</option>
                      <option value="Arriendo">Arriendo</option>
                      <option value="Venta">Venta</option>
                    </select>
                  </div>

                  <div className="form-control">
                    <label for="property_type">
                      Selecciona el tipo de inmueble que deseas promocionar
                    </label>
                    <select
                      {...register("property_type")}
                      id="property_type"
                      required
                    >
                      <option value="">Selecciona una opción</option>
                      <option value="Comercio">Comercio</option>
                      <option value="Vivienda">Vivienda</option>
                    </select>
                  </div>

                  <div className="form-actions flex justify-between gap-2">
                    <button
                      type="button"
                      onClick={() => setStep(step - 1)}
                      className="co-button is-secondary is-large has-fixed-icon"
                    >
                      <i className="co-icon icon-arrow"></i>
                      Regresar
                    </button>
                    <button className="co-button is-progress-default is-large">
                      Siguiente
                    </button>
                  </div>
                </form>
              </div>
            )}

            {step === 3 && (
              <div>
                <h2 className="mb-8 text-2xl leading-none text-coninsa-blue-900">
                  Solicitud de promoción{" "}
                  <span className="block font-display font-semibold uppercase">
                    persona {personType}
                  </span>
                </h2>

                <form
                  className="co-form co-card"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="flex flex-col gap-4 md:flex-row">
                    <div className="form-control flex-1 grow">
                      <label>Dirección detallada del inmueble:</label>
                      <input
                        {...register("property_address")}
                        type="text"
                        required
                      />
                      <p className="help-text">
                        Unidad, Torre, Interior, Número
                      </p>
                    </div>

                    {serviceType !== "Venta" && (
                      <div className="form-control flex-1 grow">
                        <label for="payment_through">
                          El pago de la cuota de administración se hará a través
                          de:
                        </label>
                        <select
                          {...register("payment_through")}
                          type="text"
                          id="payment_through"
                          required
                        >
                          <option value="">Selecciona una opción</option>
                          <option value="Coninsa">Coninsa</option>
                          <option value="Propietario">Propietario</option>
                          <option value="Arrendatario">Arrendatario</option>
                        </select>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col gap-4 md:flex-row">
                    <div className="form-control flex-1 grow">
                      <label for="building_property_type">
                        Selecciona el tipo de inmueble:
                      </label>
                      <select
                        {...register("building_property_type")}
                        type="text"
                        id="building_property_type"
                        required
                      >
                        <option value="">Selecciona una opción</option>
                        <option value="Apartamento">Apartamento</option>
                        <option value="Bodega">Bodega</option>
                        <option value="Casa">Casa</option>
                        <option value="Centro Logístico">
                          Centro Logístico
                        </option>
                        <option value="Consultorio">Consultorio</option>
                        <option value="Cuarto Útil">Cuarto Útil</option>
                        <option value="Edificio">Edificio</option>
                        <option value="Finca">Finca</option>
                        <option value="Local">Local</option>
                        <option value="Lote">Lote</option>
                        <option value="Oficina">Oficina</option>
                        <option value="Parqueadero">Parqueadero</option>
                        <option value="Unidad Habitacional">
                          Unidad Habitacional
                        </option>
                      </select>
                    </div>
                    {serviceType !== "Venta" && (
                      <div className="form-control flex-1 grow">
                        <label>
                          ¿Cuál es la expectativa Canon de arrendamiento?:
                        </label>
                        <Controller
                          control={control}
                          name="price"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <InputMask
                              mask="_"
                              prefix="$ "
                              thousandSeparator="."
                              decimalSeparator=","
                              fixedDecimalScale
                              decimalScale={2}
                              onBlur={onBlur}
                              onChange={onChange}
                              checked={value}
                              inputRef={ref}
                              required
                            />
                          )}
                        />
                      </div>
                    )}

                    {serviceType === "Venta" && (
                      <div className="form-control flex-1 grow">
                        <label>¿Cuál es el precio de venta?:</label>
                        <Controller
                          control={control}
                          name="price"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <InputMask
                              mask="_"
                              prefix="$ "
                              thousandSeparator="."
                              decimalSeparator=","
                              fixedDecimalScale
                              decimalScale={2}
                              onBlur={onBlur}
                              onChange={onChange}
                              checked={value}
                              inputRef={ref}
                              required
                            />
                          )}
                        />
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col gap-4 md:flex-row">
                    <div className="form-control flex-1 grow">
                      <label>
                        Escribe el área del inmueble m<sup>2</sup>:
                      </label>
                      <input
                        {...register("building_area")}
                        type="number"
                        id="building_area"
                        required
                      />
                    </div>
                  </div>

                  <div className="flex flex-col gap-4 md:flex-row">
                    <div className="form-control flex-1 grow">
                      <label for="building_parking_lot_type">
                        El inmueble cuenta con parqueadero:
                      </label>
                      <select
                        {...register("building_parking_lot_type")}
                        type="text"
                        id="building_parking_lot_type"
                        required
                      >
                        <option value="">Selecciona una opción</option>
                        <option value="Ninguno">Ninguno</option>
                        <option value="Sencillo">Sencillo</option>
                        <option value="Doble-lineal">Doble-lineal</option>
                        <option value="Con cuarto útil">Con cuarto útil</option>
                        <option value="Techado">Techado</option>
                      </select>
                    </div>

                    {parkingType !== "Ninguno" && (
                      <div className="form-control flex-1 grow">
                        <label>Número de parqueadero:</label>
                        <input
                          {...register("parking_number")}
                          type="number"
                          id="parking_number"
                          required
                        />
                      </div>
                    )}
                  </div>

                  <div className="md:w-1/2">
                    <fieldset className="form-group mb-4">
                      <legend className="text-sm md:text-base">
                        Cuenta con cuarto útil
                      </legend>
                      <div className="form-items flex gap-4">
                        <div className="form-control is-radio">
                          <input
                            {...register("building_has_useful_room")}
                            type="radio"
                            id="building_has_useful_room"
                            value="Si"
                            required
                          />
                          <label for="building_has_useful_room">Si</label>
                        </div>
                        <div className="form-control is-radio">
                          <input
                            {...register("building_has_useful_room")}
                            type="radio"
                            id="building_has_no_useful_room"
                            value="No"
                            required
                          />
                          <label for="building_has_no_useful_room">No</label>
                        </div>
                      </div>
                    </fieldset>

                    <div className="form-control">
                      <label>
                        Si la respuesta es "Si" indícanos el número del cuarto
                        útil:
                      </label>
                      <input
                        {...register("building_useful_room_number")}
                        type="number"
                        id="building_useful_room_number"
                      />
                    </div>
                  </div>

                  <div className="form-actions flex justify-between gap-2">
                    <button
                      type="button"
                      onClick={() => setStep(step - 1)}
                      className="co-button is-secondary is-large has-fixed-icon"
                    >
                      <i className="co-icon icon-arrow"></i>
                      Regresar
                    </button>
                    <button className="co-button is-progress-default is-large">
                      Siguiente
                    </button>
                  </div>
                </form>
              </div>
            )}

            {step === 4 && (
              <div>
                <h2 className="mb-6 text-2xl leading-none text-coninsa-blue-900">
                  Información del propietario
                  <span className="block font-display font-semibold uppercase">
                    {personType}
                  </span>
                </h2>

                <form
                  className="co-form co-card"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <input
                    {...register("status")}
                    value="pending approval"
                    type="hidden"
                  />

                  <div className="flex flex-col gap-4 md:flex-row">
                    <div className="form-control flex-1 grow">
                      <label>Dirección de residencia:</label>
                      <input
                        {...register("residence_address")}
                        type="text"
                        id="residence_address"
                        required
                      />
                    </div>
                    <div className="form-control flex-1 grow">
                      <label>Ciudad:</label>
                      <input
                        {...register("city")}
                        type="text"
                        id="city"
                        required
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-4 md:flex-row">
                    <div className="form-control flex-1 grow">
                      <label>
                        Correo electrónico para la firma del contrato:
                      </label>
                      <input
                        {...register("contract_signing_email")}
                        type="text"
                        id="contract_signing_email"
                        required
                      />
                      <p className="help-text">
                        Email personal de cada propietario
                      </p>
                    </div>
                    <div className="form-control flex-1 grow">
                      <label>
                        Número de celular de todos los propietarios:
                      </label>
                      <input
                        {...register("phone_number_owners")}
                        type="text"
                        id="phone_number_owners"
                        required
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-4 md:flex-row">
                    <div className="form-control flex-1 grow">
                      <label>Entidad bancaria:</label>
                      <input
                        {...register("banking_entity")}
                        type="text"
                        id="banking_entity"
                        required
                      />
                    </div>
                    <div className="form-control flex-1 grow">
                      <label for="account_type">Tipo de cuenta:</label>
                      <select
                        {...register("account_type")}
                        type="text"
                        id="account_type"
                        required
                      >
                        <option value="">Selecciona una opción</option>
                        <option value="Ahorros">Ahorros</option>
                        <option value="Corriente">Corriente</option>
                      </select>
                    </div>
                  </div>

                  <div className="flex flex-col gap-4 md:flex-row">
                    <div className="form-control flex-1 grow">
                      <label>Número cuenta bancaria:</label>
                      <input
                        {...register("account_number")}
                        type="text"
                        id="account_number"
                        required
                      />
                    </div>
                    {serviceType !== "Venta" && (
                      <div className="form-control flex-1 grow">
                        <label for="contract_term">
                          Vigencia del contrato:
                        </label>
                        <select
                          {...register("contract_term")}
                          type="text"
                          id="contract_term"
                          required
                        >
                          <option value="">Selecciona una opción</option>
                          <option value="6 meses">6 meses</option>
                          <option value="12 meses">12 meses</option>
                          <option value="24 meses">24 meses</option>
                        </select>
                      </div>
                    )}
                  </div>

                  {serviceType !== "Venta" && (
                    <div>
                      <fieldset className="form-group mb-4 flex-1">
                        <legend className="text-sm md:text-base">
                          ¿Quién se hará cargo de pagar la cuota de
                          administración?
                        </legend>
                        <div className="form-items">
                          <div className="form-control is-radio mb-2">
                            <input
                              {...register("cancel_fee")}
                              type="radio"
                              id="cancel_fee_true"
                              value="Si"
                              required
                            />
                            <label for="cancel_fee_true">
                              Coninsa, se descuenta del canon mensual
                            </label>
                          </div>
                          <div className="form-control is-radio">
                            <input
                              {...register("cancel_fee")}
                              type="radio"
                              id="cancel_fee_false"
                              value="No"
                              required
                            />
                            <label for="cancel_fee_false">
                              El propietario directamentamente a la copropiedad
                            </label>
                          </div>
                        </div>
                      </fieldset>

                      <div className="form-control flex-1">
                        <label>Si es otro, escríbe cuál:</label>
                        <input
                          {...register("wich_one")}
                          type="text"
                          id="wich_one"
                        />
                      </div>
                    </div>
                  )}

                  <div className="form-actions flex justify-between gap-2">
                    <button
                      type="button"
                      onClick={() => setStep(step - 1)}
                      className="co-button is-secondary is-large has-fixed-icon"
                    >
                      <i className="co-icon icon-arrow"></i>
                      Regresar
                    </button>
                    <button className="co-button is-progress-default is-large">
                      Siguiente
                    </button>
                  </div>
                </form>
              </div>
            )}

            {step === 5 && (
              <div>
                <h2 className="mb-8 text-2xl leading-none text-coninsa-blue-900">
                  Solicitud de promoción{" "}
                  <span className="block font-display font-semibold uppercase">
                    {personType}
                  </span>
                </h2>

                <form
                  className="co-form co-card"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  {serviceType !== "Venta" && (
                    <>
                      <h3 className="form-title !text-2xl">Tarifas</h3>

                      <p className="text-sm md:text-base">
                        Nuestro portafolio de servicios te ofrece varias
                        opciones de tarifas para escojas la que más te guste.
                        <br />
                        Te compartimos las tarifas de administración para
                        confirmar cuál eliges
                      </p>

                      {propertyType === "Vivienda" && (
                        <div className="overflow-x-auto md:overflow-x-hidden">
                          <table className="table w-full">
                            <thead>
                              <tr>
                                <th>Servicios</th>
                                <th>Súper Premium</th>
                                <th>Premium</th>
                                <th>Estándar</th>
                                <th>Básica</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>Tarifa</th>
                                <td>11%</td>
                                <td>10.8%</td>
                                <td>10.5%</td>
                                <td>10%</td>
                              </tr>
                              <tr>
                                <th>Garantía canon*</th>
                                <td>SI</td>
                                <td>SI</td>
                                <td>SI</td>
                                <td>SI</td>
                              </tr>
                              <tr>
                                <th>Fecha de giro</th>
                                <td>Día 1 hábil</td>
                                <td>Día 5 hábil</td>
                                <td>Día 10 hábil</td>
                                <td>Final de mes</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}

                      {propertyType === "Comercio" && (
                        <div className="overflow-x-auto md:overflow-x-hidden">
                          <table className="table w-full">
                            <thead>
                              <tr>
                                <th>Servicios</th>
                                <th>Premium</th>
                                <th>Estándar</th>
                                <th>Básica</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>Tarifa</th>
                                <td>10%</td>
                                <td>9%</td>
                                <td>8%</td>
                              </tr>
                              <tr>
                                <th>Garantía canon*</th>
                                <td>SI</td>
                                <td>SI</td>
                                <td>SI</td>
                              </tr>
                              <tr>
                                <th>Fecha de giro</th>
                                <td>Día 5 hábil</td>
                                <td>Día 10 hábil</td>
                                <td>Último día del mes</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}

                      <p className="text-sm md:text-base">
                        Las tarifas antes indicadas no incluyen IVA.
                      </p>
                      <p className="text-sm md:text-base">
                        * El tiempo de la garantía del canon se define en el
                        momento del estudio del cliente arrendatario por parte
                        de la aseguradora, puede cubrir por mora de 6 a 36
                        meses.
                      </p>
                      <p className="text-sm md:text-base">
                        Aplican términos y condiciones. Conócelos en{" "}
                        <a
                          href="https://www.coninsa.co/terminos-y-condiciones"
                          target="_blank"
                          className="co-link font-medium"
                        >
                          coninsa.co/terminos
                        </a>
                      </p>

                      <div className="form-control">
                        <label for="building_service_type">
                          Cuéntanos cuál es la tarifa que quieres seleccionar
                        </label>
                        {propertyType === "Vivienda" && (
                          <select
                            id="building_service_type"
                            {...register("building_service_type")}
                            required
                          >
                            <option value="">Selecciona una opción</option>
                            <option value="Súper Premium">Súper Premium</option>
                            <option value="Premium">Premium</option>
                            <option value="Estándar">Estándar</option>
                            <option value="Básica">Básica</option>
                          </select>
                        )}

                        {propertyType === "Comercio" && (
                          <select
                            id="building_service_type"
                            {...register("building_service_type")}
                            required
                          >
                            <option value="">Selecciona una opción</option>
                            <option value="Premium">Premium</option>
                            <option value="Estándar">Estándar</option>
                            <option value="Básica">Básica</option>
                          </select>
                        )}
                      </div>

                      <h3 className="form-title !text-xl">
                        Amparos adicionales
                      </h3>

                      {propertyType === "Vivienda" && (
                        <div className="overflow-x-auto md:overflow-x-hidden">
                          <table className="is-alternative table w-full">
                            <thead>
                              <tr>
                                <th>Servicios</th>
                                <th>Tarifa</th>
                                <th>Súper Premium</th>
                                <th>Premium</th>
                                <th>Estándar</th>
                                <th>Básica</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>Amparos servicios públicos*</th>
                                <td>0.4%</td>
                                <td>11.4%</td>
                                <td>11.2%</td>
                                <td>10.9%</td>
                                <td>10.4%</td>
                              </tr>
                              <tr>
                                <th>Amparos daños y faltantes*</th>
                                <td>0.6%</td>
                                <td>12%</td>
                                <td>11.8%</td>
                                <td>11.5%</td>
                                <td>11%</td>
                              </tr>
                              <tr>
                                <th>Amparo vacancia</th>
                                <td>2.9%</td>
                                <td>14.9%</td>
                                <td>14.7%</td>
                                <td>14.4%</td>
                                <td>13.9%</td>
                              </tr>
                              <tr>
                                <th>Bolsa de mantenimiento</th>
                                <td>Incluida</td>
                                <td>$500,000</td>
                                <td>$300,000</td>
                                <td>$200,000</td>
                                <td>N/A</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}

                      {propertyType === "Comercio" && (
                        <div className="overflow-x-auto md:overflow-x-hidden">
                          <table className="is-alternative table w-full">
                            <thead>
                              <tr>
                                <th>Servicios</th>
                                <th>Tarifa</th>
                                <th>Premium</th>
                                <th>Estándar</th>
                                <th>Básica</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>Amparos servicios públicos*</th>
                                <td>0.4%</td>
                                <td>11.2%</td>
                                <td>10.9%</td>
                                <td>10.4%</td>
                              </tr>
                              <tr>
                                <th>Amparos daños y faltantes*</th>
                                <td>0.6%</td>
                                <td>12%</td>
                                <td>11.8%</td>
                                <td>11%</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}

                      <p className="text-sm md:text-base">
                        Todas las tarifas se facturarán más IVA. <br />
                        Aplican términos y condiciones. Conócelos en{" "}
                        <a
                          href="https://www.coninsa.co/terminos-y-condiciones"
                          target="_blank"
                          className="co-link font-medium"
                        >
                          coninsa.co/terminos
                        </a>
                      </p>

                      <fieldset className="form-group are-compact-checkboxes">
                        <legend>
                          Selecciona los amparos que deseas incluir
                        </legend>
                        {propertyType === "Vivienda" && (
                          <div className="form-items">
                            <div className="form-control is-radio">
                              <input
                                {...register("building_protections")}
                                type="checkbox"
                                id="building_protections_1"
                                value="Amparos de servicios públicos"
                              />
                              <label for="building_protections_1">
                                Amparos de servicios públicos
                              </label>
                            </div>
                            <div className="form-control is-radio">
                              <input
                                {...register("building_protections")}
                                type="checkbox"
                                id="building_protections_2"
                                value="Amparos de daños y faltantes"
                              />
                              <label for="building_protections_2">
                                Amparos de daños y faltantes
                              </label>
                            </div>
                            <div className="form-control is-radio">
                              <input
                                {...register("building_protections")}
                                type="checkbox"
                                id="building_protections_3"
                                value="Amparo de vacancia"
                              />
                              <label for="building_protections_3">
                                Amparo de vacancia
                              </label>
                            </div>
                            <div className="form-control is-radio">
                              <input
                                {...register("building_protections")}
                                type="checkbox"
                                id="building_protections_4"
                                value="Bolsa de mantenimiento"
                              />
                              <label for="building_protections_4">
                                Bolsa de mantenimiento
                              </label>
                            </div>
                          </div>
                        )}

                        {propertyType === "Comercio" && (
                          <div className="form-items">
                            <div className="form-control is-radio">
                              <input
                                {...register("building_protections")}
                                type="checkbox"
                                id="building_protections_1"
                                value="Amparos de servicios públicos"
                              />
                              <label for="building_protections_1">
                                Amparos de servicios públicos
                              </label>
                            </div>
                            <div className="form-control is-radio">
                              <input
                                {...register("building_protections")}
                                type="checkbox"
                                id="building_protections_2"
                                value="Amparos de daños y faltantes"
                              />
                              <label for="building_protections_2">
                                Amparos de daños y faltantes
                              </label>
                            </div>
                          </div>
                        )}
                        {totalAmount > 0 && (
                          <p className="font-semibold text-coninsa-green-500">
                            *El total de la tarifa y amparos seleccionados es:{" "}
                            {totalAmount}%
                            {maintenanceBag > 0 &&
                              ` + $${parseInt(
                                maintenanceBag
                              ).toLocaleString()} por bolsa de mantenimiento`}
                          </p>
                        )}
                      </fieldset>

                      <fieldset className="form-group are-radios">
                        <legend className="text-sm md:text-base">
                          Inmueble con renta garantizada:
                        </legend>
                        <div className="form-items">
                          <div className="form-control is-radio">
                            <input
                              {...register("with_guarateed_rent")}
                              type="radio"
                              id="with_guarateed_rent_true"
                              value="Si"
                              required
                            />
                            <label for="with_guarateed_rent_true">Sí</label>
                          </div>
                          <div className="form-control is-radio">
                            <input
                              {...register("with_guarateed_rent")}
                              type="radio"
                              id="with_guarateed_rent_false"
                              value="No"
                              required
                            />
                            <label for="with_guarateed_rent_false">No</label>
                          </div>
                        </div>
                      </fieldset>

                      <fieldset className="form-group are-radios">
                        <legend className="text-sm md:text-base">
                          Indica si a la tarifa seleccionada se le aplicó algún descuento:
                        </legend>
                        <div className="form-items">
                          <div className="form-control is-radio">
                            <input
                              {...register("any_discount_was_applied")}
                              type="radio"
                              id="any_discount_was_applied_true"
                              value="Si"
                              required
                            />
                            <label for="any_discount_was_applied_true">Sí</label>
                          </div>
                          <div className="form-control is-radio">
                            <input
                              {...register("any_discount_was_applied")}
                              type="radio"
                              id="any_discount_was_applied_false"
                              value="No"
                              required
                            />
                            <label for="any_discount_was_applied_false">No</label>
                          </div>
                        </div>
                      </fieldset>

                      <div className="form-control">
                        <label>
                          Si tu respuesta fue si, indica cuál fue el valor del descuento
                        </label>
                        <input
                          {...register("discount_amount")}
                          type="number"
                          id="discount_amount"
                        />
                    </div>

                      <h2 className="form-title !text-xl">
                        Autorización propietario
                      </h2>

                      <p className="text-sm md:text-base">
                        Autorizo a Coninsa Inmobiliaria publicar, promocionar y
                        arrendar mi inmueble en su base de datos de acuerdo a la
                        información suministrada.
                      </p>

                      <fieldset className="form-group are-radios">
                        <legend className="text-sm md:text-base">
                          <a
                            href=" https://www.coninsa.co/politica-de-tratamiento-de-datos-personales-de-coninsa-ramon-h-sa"
                            target="_blank"
                            className="hover:underline"
                          >
                            Acepto las Políticas de Tratamiento de Datos de
                            Coninsa Ramón S.A.
                          </a>
                        </legend>
                        <div className="form-items">
                          <div className="form-control is-radio">
                            <input
                              {...register("building_owner_authorization")}
                              type="radio"
                              id="building_owner_authorization_true"
                              value="Si"
                              required
                            />
                            <label for="building_owner_authorization_true">
                              Sí
                            </label>
                          </div>
                          <div className="form-control is-radio">
                            <input
                              {...register("building_owner_authorization")}
                              type="radio"
                              id="building_owner_authorization_false"
                              value="No"
                              required
                            />
                            <label for="building_owner_authorization_false">
                              No
                            </label>
                          </div>
                        </div>
                      </fieldset>
                    </>
                  )}
                  {serviceType === "Venta" && (
                    <div>
                      <img
                        src={rateImg}
                        alt="rate"
                        className="mx-auto w-7/12"
                      />
                      <fieldset className="form-group are-radios">
                        <legend className="text-sm md:text-base">
                          ¿Estás de acuerdo con la comisión?
                        </legend>
                        <div className="form-items">
                          <div className="form-control is-radio">
                            <input
                              {...register("agree_with_commission")}
                              type="radio"
                              id="agree_with_commission_true"
                              value="Si"
                              required
                            />
                            <label for="agree_with_commission_true">Sí</label>
                          </div>
                          <div className="form-control is-radio">
                            <input
                              {...register("agree_with_commission")}
                              type="radio"
                              id="agree_with_commission_false"
                              value="No"
                              required
                            />
                            <label for="agree_with_commission_false">No</label>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  )}

                  {personType === "Natural" && (
                    <div>
                      <div className="form-control mb-4 flex-1 grow">
                        <label for="file" className="mb-2">
                          Certificado de tradición y libertad actualizado máximo{" "}
                          <strong>30 días de expedición </strong> por cada
                          matrícula inmobiliaria
                        </label>

                        <FilesInput
                          name="tradition_freedom_certificate"
                          control={control}
                          accept=".pdf"
                          required
                        />
                        <p className="help-text">
                          Recuerda que debes de adjuntar tus documentos en
                          formato PDF.
                        </p>
                      </div>

                      <div className="form-control flex-1 grow">
                        <label for="file">Copia cédula ciudadania</label>

                        <FileInput
                          name="id_document"
                          control={control}
                          accept=".pdf"
                          required
                        />
                        <p className="help-text">
                          Recuerda que debes de adjuntar tus documentos en
                          formato PDF.
                        </p>
                      </div>
                    </div>
                  )}

                  {personType === "Jurídica" && (
                    <div className="form-control flex-1 grow">
                      <label for="file">
                        Copia de la cámara de comercio. (no mayor a 30 días de
                        expedición)
                      </label>

                      <FileInput
                        name="chamber_of_commerce"
                        control={control}
                        accept=".pdf"
                      />
                      <p className="help-text">
                        Recuerda que debes de adjuntar tus documentos en formato
                        PDF.
                      </p>
                    </div>
                  )}

                  <input {...register("sid")} type="hidden" />
                  <input
                    {...register("status")}
                    value="completed"
                    type="hidden"
                  />

                  <div className="form-actions flex justify-between gap-2">
                    <button
                      type="button"
                      onClick={() => setStep(step - 1)}
                      className="co-button is-secondary is-large has-fixed-icon"
                    >
                      <i className="co-icon icon-arrow"></i>
                      Regresar
                    </button>
                    <button
                      className="co-button is-progress-default is-large"
                      disabled={isSubmitting}
                    >
                      {!isSubmitting ? "Enviar" : "Enviando..."}
                    </button>
                  </div>
                </form>
              </div>
            )}

            {step === 6 && (
              <div className="co-card">
                <div className="mx-auto max-w-3xl text-center">
                  <div className="flex flex-wrap justify-center py-6">
                    <div className="check">
                      <i className="co-icon icon-check"></i>
                    </div>
                  </div>

                  <h3
                    className="co-heading-4 mb-8"
                    style={{ color: "#80C342" }}
                  >
                    ¡Has registrado la información correctamente!
                  </h3>
                  <p className="co-heading-6 mb-8 text-sm md:text-base">
                    Hemos recibido toda la información de tu inmueble y la
                    estamos revisando. Un asesor se contactará contigo en el
                    menor tiempo posible para continuar con el proceso.
                  </p>
                  <span className="co-heading-5">
                    ¡Gracias por confiar en nosotros!
                  </span>
                  <div className="flex flex-wrap justify-center py-8">
                    <button
                      onClick={onReset}
                      className="co-button is-progress-default w-80"
                    >
                      VOLVER AL INICIO
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}
