import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { Box, Checkbox, Input, Label, Textarea } from 'theme-ui'
import IntlTelInput from 'react-intl-tel-input'

function ContactForm() {
  const { register, handleSubmit, reset } = useForm({})
  const [submitted, setSubmitted] = useState(false)
  const [phone, setPhone] = useState()

  const onChange = (_, phoneNumber, country) => {
    const dialCode = country.dialCode
    const expression = `\\+${dialCode}`
    const regex = new RegExp(expression)
    const withoutDial = phoneNumber.replace(regex, '')
    const internationalNumber = `+${country.dialCode}` + `${withoutDial}`
    setPhone(internationalNumber)
  }

  const phoneStyles = {
    mb: ['xsmall', 'none'],
    '.allow-dropdown.intl-tel-input': { display: 'block' },
    input: {
      width: '100%',
      border: '1px solid #d0d1d1',
      borderRadius: '4px',
      lineHeight: 'inherit',
      py: '12px',
    },
  }

  const checkboxStyles = {
    'input:checked ~ &': {
      color: 'accent',
    },

    'input:focus ~ &': {
      color: 'accent',
    },
  }

  const onSubmit = async data => {
    const zohoInfo = {
      data: [
        {
          First_Name: data.first_name,
          Last_Name: data.last_name,
          Owner: { id: '2528071000370702608' },
          Email: data.email,
          Mobile: phone,
          Regional: 'Bogotá',
          Origen_de_Informaci_n: 'Digital',
          Medio_Publicitario: 'www.coninsa.co',
          Creador_API: 'www.coninsa.co',
          Estrategia_de_Campa_a: 'Arrendar un Inmueble',
          Tipo_de_Canal: 'Digital',
          Tipo_de_Contacto: 'Otros',
          Layout: {
            id: '2528071000000091055',
          },
          Proyecto_de_interes: {
            id: '2528071000024260071',
          },
          Destino_del_Inmueble: 'Para Habitar',
        },
      ],
    }

    const url = 'https://api.coninsa.co/api/v2/zohocrm-create-lead'

    return new Promise((resolve, reject) => {
      axios
        .post(url, zohoInfo)
        .then(response => {
          reset()
          setSubmitted(true)
          resolve(response)
          window.open('https://www.coninsa.co/gracias')
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  return (
    <div>
      {!submitted && (
        <form className='flex flex-col gap-4' onSubmit={handleSubmit(onSubmit)}>
          <Input
            {...register('first_name')}
            id='first_name'
            placeholder='Nombres'
            required
          />

          <Input
            {...register('last_name')}
            id='last_name'
            placeholder='Apellidos'
            required
          />

          <Box sx={phoneStyles}>
            <IntlTelInput
              {...register('phone')}
              id='phone'
              placeholder='Número de contacto'
              defaultCountry={'co'}
              onPhoneNumberChange={onChange}
              telInputProps={{ required: true }}
            />
          </Box>

          <Input
            {...register('email')}
            id='email'
            placeholder='Correo electrónico'
            required
          />

          <Input
            {...register('city')}
            id='city'
            placeholder='Ciudad de residencia'
            required
          />

          <Input
            {...register('building_leasing')}
            id='building_leasing'
            placeholder='Tipo de Inmueble a arrendar'
            required
          />

          <Textarea
            rows={4}
            placeholder='Escríbenos los detalles de tu solicitud'
            {...register('description')}
          />

          <Label sx={{ alignItems: 'center', mt: 'small' }}>
            <Checkbox sx={checkboxStyles} required />
            <Box
              sx={{
                fontSize: [0, 1],
              }}
            >
              Autorizo el tratamiento y la
              <Box
                as='a'
                href='https://www.coninsa.co/politica-de-tratamiento-de-datos-personales-de-coninsa-ramon-h-sa'
                sx={{ color: 'accent', ml: '3px', textDecoration: 'underline' }}
                target='_blank'
              >
                política de datos personales
              </Box>
            </Box>
          </Label>

          <button
            type='submit'
            className='bg-coninsa-green-300 rounded-lg font-bold py-3 text-sm text-white uppercase md:text-base md:py-4 hover:bg-coninsa-green-500'
          >
            Contactarme
          </button>
        </form>
      )}

      {submitted && (
        <p className='text-center'>
          Gracias por confiar en nosotros, pronto un asesor se comunicará con usted.
        </p>
      )}
    </div>
  )
}

export default ContactForm
