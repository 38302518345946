import React, { useState } from "react";
import { useLazyQuery } from "@apollo/client";

import Modal from "@coninsa-s2/modal";
import Heading from "@coninsa-s2/heading";
import Button from "@coninsa-s2/button";

import "@coninsa-s2/form/src/input.styles.css";

import { useForm } from "react-hook-form";

import { SEARCH_PROPERTY_BY_CODE } from "../sections/banner/queries";

function SearchModal() {
  const { register, handleSubmit, reset } = useForm({});
  const [resultCount, setResultCount] = useState(-1);

  const [isOpen, setOpen] = useState(false);
  const closeModal = () => {
    reset();
    setResultCount(-1);
    setOpen(false);
  };

  const [getRentProperties, { loading }] = useLazyQuery(
    SEARCH_PROPERTY_BY_CODE,
    { variables: { code: "" } }
  );

  const onSubmit = async (data) => {
    return getRentProperties({ variables: { code: data.code } })
      .then(({ data }) => {
        const building = data?.building;

        if (building !== null) {
          window.open(data?.building?.propertyUrl?.path, "_self");
        } else {
          setResultCount(0);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  return (
    <div>
      <div className="flex items-center justify-center md:justify-end">
        <button
          onClick={() => setOpen(true)}
          className="cursor-pointer rounded-lg border border-white bg-transparent px-3 py-1 text-sm font-semibold text-white hover:bg-slate-300 hover:text-slate-700 md:px-4 md:text-base"
        >
          Ingresa el código del inmueble
        </button>
      </div>

      {isOpen && (
        <Modal header={<Heading weight="bold">Buscar por código</Heading>}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3 md:mb-6">
              <label className="mb-3 block">
                Ingresa el código del inmueble
              </label>
              <input
                {...register("code")}
                className="s2-input w-full"
                id="code"
                placeholder="Código de inmueble"
                type="number"
                required
              />
              {resultCount === 0 && (
                <p className="pt-2 text-red-600">
                  Código incorrecto o no existe.
                </p>
              )}
            </div>
            <div className="flex justify-between">
              <Button variant="ghost" type="button" onClick={closeModal}>
                Cancelar
              </Button>

              <Button type="submit" disabled={loading}>
                {!loading ? "Buscar" : "Buscando ..."}
              </Button>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
}

export default SearchModal;
