import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "@apollo/client";
import InputMask, { removeMaskFormat } from 'components/form/InputMask';

import Alert from "../components/Alert";

import { UPDATE_BUILDING_PROPERTY } from "../graphql/mutations";

function Step5({ onSuccess, onBack, id }) {
  const { register, handleSubmit, watch, setValue, setError, control } = useForm();
  const [mutation] = useMutation(UPDATE_BUILDING_PROPERTY);

  const onSubmit = async (data) => {
    const response = await mutation({
      variables: {
        id,
        input: {
          fieldLeaseValue: removeMaskFormat(data?.lease_value).toString(),
          fieldAdministrationValue: removeMaskFormat(data?.administration_value).toString(),
          fieldSectorDescription: {
            value: data?.description,
            format: "basic_html"
          },
          fieldIncludesAdministration: data?.without_administration_value ? "1" : "0",
          fieldObservations: {
            value: data?.instructions,
            format: "basic_html"
          },
        }
      }
    })

    const violations = response?.data?.updateBuildingProperty?.violations;
    const errors = response?.data?.updateBuildingProperty?.errors;

    if (violations?.length === 0 && errors?.length === 0) {
      return onSuccess({ id });
    } else {
      setError("Algo ocurrió mal. Vuelva a intentarlo más tarde.");
    }
  };

  const noAdministration = watch("without_administration_value");
  const lease_value = watch("lease_value", 0);
  const administration_value = watch("administration_value", 0);
  const lease = removeMaskFormat(lease_value);
  const administration = removeMaskFormat(administration_value);
  const total = lease + administration;

  useEffect(() => {
    if (noAdministration) {
      setValue("administration_value", "");
    }
  }, [noAdministration]);


  const description = watch('description');
  const characterLimit = 589;
  const [length, setLength] = useState(0);

  useEffect(() => {
    if (description) {
      setLength(description.length);
    }
  }, [description])


  return (
    <div className="co-card max-w-4xl mx-auto">
      <form className="co-form gap-y-8" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-wrap gap-4">
          <div className="form-control grow">
            <label>Valor mensual del arriendo</label>
            <Controller
              control={control}
              name="lease_value"
              render={({ field }) => (
                <InputMask
                  mask='_'
                  prefix='$ '
                  thousandSeparator='.'
                  decimalSeparator=','
                  fixedDecimalScale
                  decimalScale={2}
                  placeholder="Ingresa el valor en COP"
                  required
                  {...field}
                />
              )}
            />
          </div>
        </div>

        <div className="flex flex-wrap gap-4">
          <div className="form-control grow">
            <label>Valor mensual de la administración</label>
            <Controller
              control={control}
              name="administration_value"
              render={({ field }) => (
                <InputMask
                  mask="_"
                  prefix="$ "
                  thousandSeparator="."
                  decimalSeparator=","
                  fixedDecimalScale
                  decimalScale={2}
                  placeholder="Ingresa el valor en COP"
                  {...field}
                />
              )}
            />
          </div>
        </div>

        <div className="form-control is-checkbox">
          <input
            type="checkbox"
            id="without_administration_value"
            {...register("without_administration_value")}
          />
          <label
            htmlFor="without_administration_value"
            className="data-policy"
          >
            No se cobra administración
          </label>
        </div>
        <div className="flex flex-wrap gap-4 place-content-between font-bold text-xl flex-col md:flex-row">
          <h3>Valor Total</h3>
          <h3>COP$ {isNaN(total) ? 0 : total} / mes</h3>
        </div>
        <Alert>
          <p>
            Según nuestros datos, el valor total debería sumar entre $ 1.9M y
            $ 2.3M COP
          </p>
        </Alert>
        <div className="flex flex-wrap gap-4">
          <div className="form-control grow">
            <label>Descripción del espacio</label>
            <textarea
              rows={8}
              id="description"
              {...register("description")}
              placeholder="Escribe en tus palabras y en menos de 1000 caracteres la descripción de tu inmueble."
              required
              maxLength={characterLimit}
            ></textarea>
            <div className="help-text text-co-dark-cyan">
              Has escrito {length} de {characterLimit} caracteres recomendados.
            </div>
          </div>
        </div>
        <div className="flex flex-wrap gap-4">
          <div className="form-control grow">
            <label>Instrucciones para las visitas</label>
            <input
              {...register("instructions")}
              type="text"
              id="instructions"
              placeholder="¿Qué instrucciones necesitamos para visitar el inmueble? el valor en COP"
              required
            />
          </div>
        </div>
        <div className="flex justify-between gap-2 md:flex-row">
          <button type="button" onClick={onBack} className="co-button is-secondary is-large has-fixed-icon">
            <i className="co-icon icon-arrow"></i>
            Regresar
          </button>

          <button className="co-button is-progress-default is-large w-full md:w-auto">
            Continuar
          </button>
        </div>
      </form>
    </div>
  );
}

export default Step5;
