import React from "react";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Breadcrumb, { BreadcrumbItem } from "@coninsa-s2/breadcrumb";
import Heading from "@coninsa-s2/heading";

export default function ColombiansAbroad() {
  const breadcrumbItems = [
    {
      label: "Inicio",
      url: "/terminos-y-condiciones",
    },
    {
      label: "Términos y condiciones generales",
      url: "/terminos-y-condiciones/generales",
    },
    {
      label: "Colombianos en el exterior 2019",
    },
  ];

  return (
    <Section>
      <Container>
        <div className="mb-8">
          <Breadcrumb>
            {breadcrumbItems.map((item) => (
              <BreadcrumbItem>
                {item.url && <a href={item.url}>{item.label}</a>}
                {!item.url && item.label}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        </div>
        <div className="mb-7">
          <span className="text-lg text-s2-dark-blue lg:text-[22px]">
            Términos y condiciones
          </span>
          <Heading size="2xl">Colombianos en el exterior 2019</Heading>
        </div>
        <div className="prose">
          <ol>
            <li>
              <h4>Vigencia:</h4>
              <p>
                La actividad Colombianos en el exterior. 2019 se realizará entre
                los días 6 de noviembre de 2019 hasta 06 de diciembre de 2019,
                en las ciudades de Bogotá, Barranquilla, Santa Marta y Medellín.
              </p>
            </li>
            <li>
              <h4>El organizador:</h4>
              <p>
                Coninsa Ramón H S.A., sociedad comercial con Nit 890911431-1 con
                domicilio principal en Medellín, Regional Antioquia, Calle 55
                No. 45 - 55, (57+4) 448 88 28, Regional Bogotá (57+1) 6014800 y
                Regional Caribe: (57+5) 319 96 103.
              </p>
            </li>
            <li>
              <h4>Objeto y mecánica de la actividad:</h4>
              <p>
                Colombianos en el Exterior. 2019, es una actividad en la cual se
                otorgará a los colombianos que viven en el exterior la
                oportunidad de adquirir un inmuebles.
              </p>
            </li>
            <li>
              <h4>Disposiciones legales:</h4>
              <p>
                Las áreas publicadas podrán sufrir modificaciones como
                consecuencia de los cambios ordenados por la autoridad
                competente en la expedición de los correspondientes permisos o
                por condiciones en la comercialización. El valor podrá variar
                por condiciones de mercado y disponibilidad de unidades antes de
                la separación del inmueble.
              </p>
              <p>
                El precio final del inmueble en pesos colombianos estará basado
                en el valor de la TRM (tasa representativa del mercado) del día
                en que se realice la negociación.
              </p>
              <p>
                Los datos aquí publicados podrán variar sin previo aviso. Antes
                de tomar su decisión de adquisición, por favor infórmese en la
                Sala de Negocios y con el Asesor Comercial acerca de las
                condiciones y características vigentes del Proyecto.
              </p>
              <p>Fecha de publicación: noviembre 2019.</p>
            </li>
          </ol>
        </div>
      </Container>
    </Section>
  );
}
