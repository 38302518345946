/** @jsx jsx */
import React, { Children } from 'react'
import { Box, jsx } from 'theme-ui'
import { Splide, SplideTrack } from '@splidejs/react-splide'
import PropTypes from 'prop-types'

import { Icon } from 'ui'

import '@splidejs/react-splide/css'

function Splider({ children, options, ...props }) {
  const slideSettings = {
    perMove: 1,
    gap: '1rem',
    pagination: false,
    breakpoints: {
      768: {
        perPage: 2,
      },
      640: {
        perPage: 1,
      },
    },

    ...options,
  }

  const arrowStyles = {
    display: ['none', 'flex'],
    gap: '1rem',
    bottom: 0,
    justifyContent: 'flex-end',

    '.splide__arrow': {
      boxShadow: '0px 3px 6px #00000029',
      bg: 'white',
      height: '3.25rem',
      width: '3.25rem',
      fontSize: '1rem',
      position: 'relative',
      top: 'initial',
      transform: 'initial',
      svg: {
        fill: '#00706A',
      },
    },

    '.splide__arrow--prev': {
      left: 'initial',
    },
    '.splide__arrow--next': {
      right: 'initial',
    },
  }

  return (
    <Splide hasTrack={false} options={slideSettings} {...props}>
      <SplideTrack>{children}</SplideTrack>

      <Box sx={arrowStyles} className='splide__arrows'>
        <Box as='button' className='splide__arrow splide__arrow--prev'>
          <Icon name='f-arrow-right' />
        </Box>
        <Box as='button' className='splide__arrow splide__arrow--next'>
          <Icon name='f-arrow-right' />
        </Box>
      </Box>
    </Splide>
  )
}

Splider.defaultProps = {}

Splider.propTypes = {
  settings: PropTypes.bool,
}

export default Splider
