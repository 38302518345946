import React from 'react'
import { Button } from 'theme-ui'

function CallButton() {
  const baseStyles = {
    bg: ['secondary', 'secondary'],
    borderRadius: '7px',
    color: 'white',
    fontSize: 1,
    fontWeight: 'bold',
    mt: 'xsmall',
    py: '10px',
    textTransform: 'uppercase',
    width: '100%',

    '&:hover': {
      bg: 'green-3',
    },
  }

  return (
    <Button sx={baseStyles} type='submit' variant='sharing'>
      Recibir una llamada
    </Button>
  )
}

export default CallButton
