import { getProjectStateLabel } from "./facets";

export const SEARCH_PROJECTS_BASE_URL = "/proyectos/nuevo";

export const DEFAULT_VALUE = "Todos";
export const ALL_OPTION_VALUE = "Todos";
export const EMPTY_STRING_VALUE = "";

export interface FacetConfig {
  pretty: string;
  facetName: string;
  fieldName: string;
  defaultValue: string;
  useLabel: boolean;
  translate: (string) => string;
  type: "pathname" | "query";
}

interface SeachProjectConfigs {
  text: FacetConfig;
  property_type: FacetConfig;
  department_project: FacetConfig;
  field_state: FacetConfig;
}

export const SEARCH_PROJECTS_FACET_CONFIGS: SeachProjectConfigs = {
  text: {
    pretty: "text",
    facetName: "text",
    fieldName: "text",
    defaultValue: EMPTY_STRING_VALUE,
    useLabel: false,
    translate: (t) => t,
    type: "query",
  },
  property_type: {
    pretty: "tipo-de-inmueble",
    facetName: "property_type",
    fieldName: "property_type",
    defaultValue: DEFAULT_VALUE,
    useLabel: false,
    translate: (t) => t,
    type: "pathname",
  },

  department_project: {
    pretty: "region",
    facetName: "department_project",
    fieldName: "department_project",
    defaultValue: DEFAULT_VALUE,
    useLabel: false,
    translate: (t) => t,
    type: "pathname",
  },

  field_state: {
    pretty: "estado",
    facetName: "field_state",
    fieldName: "field_state",
    defaultValue: DEFAULT_VALUE,
    useLabel: true,
    translate: getProjectStateLabel,
    type: "pathname",
  },
};
