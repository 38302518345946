import { gql } from '@apollo/client'

export const LEGAL_COTENANT_APPLICATION = gql`
  query LegalCotenantApplicationNode($id: String!) {
    node: nodeById(id: $id) {
      bundle: entityBundle
      
      ... on NodeLegalPersonCoTenant {
        id: nid
        title
        fieldShareholders {
          document
          name
          share
        }
        fieldEconomicActivity
        fieldAssets
        fieldLastName
        fieldSelfRetainer
        fieldLegalRepresentativePhone
        fieldContactCellPhone
        fieldFiscalCity {
          department
          city
          neighborhood
        }
        fieldIsCustomer
        fieldLegalRepresentativeEmail
        fieldContactEmail
        fieldHasInternationalAccount
        fieldIncomeStatement {
          targetId
          display
          description
        }
        fieldUbication {
          department
          city
          neighborhood
        }
        fieldCorrespondenceAddress
        fieldPropertyAddress
        fieldAddress
        fieldFiscalAddress
        fieldIdentificationDocument {
          targetId
          display
          description
          entity {
            fid
            filename
            url
          }
        }
        fieldExpenses
        fieldEmail
        fieldFinancialState {
          targetId
          display
          description
          entity {
            fid
            filename
            url
          }
        }
        fieldExistenceRepresentation {
          targetId
          display
          description
          entity {
            fid
            filename
            url
          }
        }
        fieldBankStatements {
          targetId
          display
          description
          entity {
            fid
            filename
            url
          }
        }
        fieldExpeditionDate {
          value
          date
        }
        fieldGreatTaxpayer
        fieldPropertyTax {
          targetId
          display
          description
          entity {
            fid
            filename
            url
          }
        }
        fieldIncome
        fieldExpeditionPlace {
          department
          city
          neighborhood
        }
        fieldHandlesForeignCurrency
        fieldBrand
        fieldPropertyRegistration
        fieldModel
        fieldNit
        fieldContactPersonName
        fieldFirstName
        fieldCellPhoneNumber
        fieldAccountNumber
        fieldIdNumber
        fieldEmployeesNumber
        fieldLiabilities
        fieldLicensePlate
        fieldProviderReferences {
          socialReason
          phone
        }
        fieldSocialReason
        fieldInternationalOperations
        fieldBankReferences {
          entity
          accountNumber
          branchOffice
          accountType
        }
        fieldCommercialReferences {
          store
          phone
        }
        fieldIcaResponsible
        fieldIncomeResponsible
        fieldSalesResponsible
        fieldRut {
          targetId
          display
          description
          entity {
            fid
            filename
            url
          }
        }
        fieldFiscalPhone
        fieldHasPropertyMortgage
        fieldHasGarment
        fieldCompanyType
        fieldIdType
        fieldBusiness
      }
    }
  }
`