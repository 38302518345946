import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Image } from 'theme-ui'
import Carousel from 'nuka-carousel'
import { Splide, SplideSlide } from '@splidejs/react-splide'

import { Icon, Modal } from 'ui'
import { SimpleOverlay } from './PropertyProjectGallery'
import BaseImage from 'images/base-image.jpg'

const leftControlStyles = {
  mr: 0,
  borderColor: 'transparent',
  borderRadius: '50%',
  boxShadow: '0px 3px 6px #00000029',
  cursor: 'pointer',
  height: ['36px', '48px'],
  width: ['36px', '48px'],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'white',
  opacity: 1,
  transform: ['translateX(-10px)', 'translateX(-60px)'],
}

const rightControlStyles = {
  backgroundColor: 'white',
  borderColor: 'transparent',
  borderRadius: '50%',
  boxShadow: '0px 3px 6px #00000029',
  cursor: 'pointer',
  height: ['36px', '48px'],
  width: ['36px', '48px'],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  opacity: 1,
  transform: ['translateX(10px)', 'translateX(60px)'],
}

export const renderLeftControl = previousSlide => (
  <Box as='button' onClick={previousSlide} sx={leftControlStyles}>
    <Icon
      name='ri-arrow-left-line'
      sx={{ color: 'accent', fontSize: ['28px', '36px'] }}
    />
  </Box>
)

export const renderRightControl = nextSlide => (
  <Box as='button' onClick={nextSlide} sx={rightControlStyles}>
    <Icon
      name='ri-arrow-right-line'
      sx={{ color: 'accent', fontSize: ['28px', '36px'] }}
    />
  </Box>
)

export const renderTopControls = (currentSlide, total) => (
  <Box
    sx={{
      color: 'white',
      cursor: 'pointer',
      fontFamily: 3,
      fontWeight: 'bold',
      transform: 'translateY(40px)',
    }}
  >
    {Math.ceil(currentSlide) + 1} / {total}
  </Box>
)

export function ButtonOverlay({ children, handler, label, bottom, icon = 'fi-camera' }) {

  return (
    <Box sx={{ position: 'relative' }}>
      {children}
      <Box
        as='button'
        sx={{
          backgroundColor: 'dark-2',
          borderRadius: '8px',
          opacity: '1',
          border: '1px solid white',
          color: 'white',
          cursor: 'pointer',
          display: 'flex',
          fontWeight: 'bold',
          justifyContent: 'center',
          position: 'absolute',
          bottom: bottom,
          right: 0,
          alignItems: 'center',
          px: 'small',
          py: 'xsmall',
          m: 4,
        }}
        onClick={handler}
      >
        <Icon name={icon} sx={{ mr: 'xsmall' }} />
        {label}
      </Box>
    </Box>
  )
}

function PropertyBanner({ images, title }) {
  const [state, setState] = useState(false)
  const handler = () => setState(true)
  const closeHandler = () => {
    setState(false)
  }

  const propertyGallery = images.slice(1, 4);
  const totalImages = `${images.length}+`

  const options = {
    rewind: true,
    gap: '1rem',

    breakpoints: {
      640: {
        gap: '.5rem',
        perPage: 1,
      }
    }
  }

  return (
    <div>
      <Box sx={{ display: ['none', 'block'], mb: ['small', 'none'] }}>
        {images?.length > 0 && (
          <Box
            sx={{
              display: ["block", "grid"],
              gap: 4,
              gridTemplateColumns: "1fr 1fr",
            }}
          >
            <Box
              onClick={handler}
              sx={{
                height: ['100%', '396px'],
                overflow: 'hidden',
                borderRadius: '8px',
                border: 'none',
                width: '100%',
                margin: '0 auto',
              }}
            >
              <Image
                sx={{ borderRadius: '8px', width: '100%', height: '100%', objectFit: 'cover' }}
                src={images[0]?.derivative?.url}
                alt={title}
              />
            </Box>
            <div className="grid grid-cols-2 gap-4">
              {propertyGallery.map((image, index) => (
                <img key={index} src={image?.derivative?.url} alt={title} className="rounded-lg w-full h-[190px] object-cover" />
              ))}
              {images?.length === 6 && (
                <Box sx={{ cursor: 'pointer' }} onClick={handler}>
                  <img className="rounded-lg w-full h-[190px] object-cover" src={images[5]?.derivative?.url} />
                </Box>
              )}

              {images?.length > 6 && (
                <SimpleOverlay handler={handler} label='Ver más fotos'>
                  <img className="rounded-lg w-full h-[190px] object-cover" src={images[5]?.derivative?.url} />
                </SimpleOverlay>
              )}
            </div>
          </Box>
        )}

        {images.length === 0 && <Image src={BaseImage} sx={{ borderRadius: '8px', width: '100%', height: '396px', objectFit: 'cover' }} />}

        <Modal
          sx={{ width: ['100%', '640px'] }}
          open={state}
          onClose={closeHandler}
          variant='fullDark'
          showCloseControl={true}
        >
          <Modal.Content variant='fullDark' sx={{ px: 0 }}>
            {images?.length > 0 && (
              <Box
                sx={{
                  width: ['80vw', 'auto'],
                  height: [280, 'auto'],
                }}
              >
                <Carousel
                  renderAnnounceSlideMessage={({ currentSlide, slideCount }) =>
                    `Slide ${currentSlide + 1} of ${slideCount}`
                  }
                  renderBottomCenterControls={({ currentSlide }) =>
                    renderTopControls(currentSlide, images.length)
                  }
                  renderCenterLeftControls={({ previousSlide }) =>
                    renderLeftControl(previousSlide)
                  }
                  renderCenterRightControls={({ nextSlide }) =>
                    renderRightControl(nextSlide)
                  }
                >
                  {images.map(image => (
                    <Box>
                      <Box
                        sx={{
                          height: ['268px', '459px'],
                          overflow: 'hidden',
                          borderRadius: '8px',
                          border: 'none',
                          m: '0 auto',
                          width: ['345px', '612px'],
                        }}
                      >
                        <Image
                          sx={{ width: '100%', height: '100%' }}
                          src={image?.derivative?.url}
                        />
                      </Box>
                    </Box>
                  ))}
                </Carousel>
              </Box>
            )}

            {images.length === 0 && <Image src={BaseImage} alt={title} />}
          </Modal.Content>
        </Modal>
      </Box>

      <Box sx={{ display: ['block', 'none'] }}>
        {images?.length > 1 && (
          <Splide options={options}>
            {images.map((image, index) => (
              <SplideSlide>
                <img key={index} src={image?.derivative?.url} className="rounded-lg w-full h-[264px] object-cover" />
              </SplideSlide>
            ))}
          </Splide>
        )}

        {images.length === 0 && <Image src={BaseImage} sx={{ borderRadius: '8px', width: '100%', height: '264px', objectFit: 'cover' }} />}

      </Box>
    </div>
  )
}

PropertyBanner.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

ButtonOverlay.defaultProps = {
  bottom: 0,
}

ButtonOverlay.propTypes = {
  children: PropTypes.node.isRequired,
  handler: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  bottom: PropTypes.string,
}

export default PropertyBanner
