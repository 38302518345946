import alkostoIcon from '../assets/icons/alkosto.svg'
import aktIcon from '../assets/icons/akt.png'
import nutresaIcon from '../assets/icons/nutresa.png'
import yamahaIcon from '../assets/icons/yamaha.png'
import lasAmericasIcon from '../assets/icons/las-americas.png'
import acrecerIcon from '../assets/icons/acrecer.png'
import armyIcon from '../assets/icons/army.png'
import casaLukerIcon from '../assets/icons/casa-luker.png'
import coronaIcon from '../assets/icons/corona.png'
import decameronIcon from '../assets/icons/decameron.png'
import eafitIcon from '../assets/icons/eafit.png'
import eiaIcon from '../assets/icons/eia.png'
import elRosarioIcon from '../assets/icons/el-rosario.png'
import floridaIcon from '../assets/icons/florida.png'
import fourPointsIcon from '../assets/icons/four-points.png'
import hamtonIcon from '../assets/icons/hamton.png'
import iberoamericanIcon from '../assets/icons/iberoamerican.png'
import interplazaIcon from '../assets/icons/interplaza.png'
import javerianaIcon from '../assets/icons/javeriana.png'
import laSalleIcon from '../assets/icons/la-salle.png'
import londonoIcon from '../assets/icons/londono.png'
import metroIcon from '../assets/icons/metro.png'
import pepsicoIcon from '../assets/icons/pepsico.png'
import pgIcon from '../assets/icons/pg.png'
import portoalegreIcon from '../assets/icons/portoalegre.png'
import sanJuanIcon from '../assets/icons/san-juan.png'
import sanVicenteIcon from '../assets/icons/san-vicente.png'
import unicentroIcon from '../assets/icons/unicentro.png'
import uniminutoIcon from '../assets/icons/uniminuto.png'

export default [
  {icon: alkostoIcon},
  {icon: aktIcon},
  {icon: nutresaIcon},
  {icon: yamahaIcon},
  {icon: lasAmericasIcon},
  {icon: acrecerIcon},
  {icon: casaLukerIcon},
  {icon: coronaIcon},
  {icon: decameronIcon},
  {icon: eafitIcon},
  {icon: eiaIcon},
  {icon: elRosarioIcon},
  {icon: floridaIcon},
  {icon: fourPointsIcon},
  {icon: hamtonIcon},
  {icon: iberoamericanIcon},
  {icon: interplazaIcon},
  {icon: javerianaIcon},
  {icon: laSalleIcon},
  {icon: londonoIcon},
  {icon: metroIcon},
  {icon: pepsicoIcon},
  {icon: portoalegreIcon},
  {icon: sanJuanIcon},
  {icon: sanVicenteIcon},
  {icon: unicentroIcon},
  {icon: uniminutoIcon}
]