import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";

import FacetItem from "@coninsa-s2/facet-item";
import Button from "@coninsa-s2/button";

import ModalExposedForm from "./modal-exposed-form";

import {
  getProjectStateLabel,
  getProjectImmediateDeliveryLabel,
  getProjectCommerceHousingLabel,
} from "../../../facets";

const ALL_OPTION_VALUE = "Todos";

interface FacetType {
  name: string;
  values: Array<{ filter: string; count: number }>;
}

interface ExposedFormProps {
  facets: Array<FacetType>;
  parentFormValues: object;
  parentDefaultValues: object;

  resultCount: number;
  loading: boolean;

  setParentValue: (string, any) => void;
  closeModal: () => void;
}

const ExposedForm: React.FC<ExposedFormProps> = ({
  facets,
  parentDefaultValues,
  parentFormValues,

  resultCount,
  loading,

  setParentValue,
  closeModal,
}) => {
  const { register, handleSubmit, watch, control, setValue } = useForm({
    defaultValues: {
      exposed: parentFormValues,
    },
  });

  useEffect(() => {
    const subscription = watch((data, { name, type }) => {
      if (name) {
        const parentName = name.replace("exposed.", "");
        setParentValue(parentName, data.exposed[parentName]);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const onsubmit = () => {};

  function getFacetValues(facetKey) {
    const facet = facets.find(({ name }) => name === facetKey);
    return facet?.values ? [{ filter: ALL_OPTION_VALUE }, ...facet.values] : [];
  }

  function resetFormValues() {
    for (const [filter, value] of Object.entries(parentDefaultValues)) {
      if (filter !== "text") {
        setValue(`exposed.${filter}`, value);
      }
    }
  }

  return (
    <ModalExposedForm
      resultCount={resultCount}
      loading={loading}
      closeModal={closeModal}
      resetValues={resetFormValues}
    >
      <form onSubmit={handleSubmit(onsubmit)} className="grid gap-8">
        <fieldset className="s2-fieldset s2-fieldset--tags">
          <legend className="s2-fieldset__legend">
            Precio desde
            <Button
              type="button"
              variant="ghost"
              shape="rounded"
              size="compact"
              onClick={() => {
                setValue("exposed.field_sale_price_from", "");
                setValue("exposed.field_sale_price_to", "");
              }}
            >
              Limpiar
            </Button>
          </legend>
          <div className="s2-fieldset__content">
            <div className="flex gap-4">
              <Controller
                control={control}
                name="exposed.field_sale_price_from"
                render={({ field }) => (
                  <NumberFormat
                    className="s2-input w-1/2"
                    placeholder="Min"
                    thousandSeparator="."
                    decimalSeparator=","
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name="exposed.field_sale_price_to"
                render={({ field }) => (
                  <NumberFormat
                    className="s2-input w-1/2"
                    placeholder="Max"
                    thousandSeparator="."
                    decimalSeparator=","
                    {...field}
                  />
                )}
              />
            </div>
          </div>
        </fieldset>

        <fieldset className="s2-fieldset s2-fieldset--tags">
          <legend className="s2-fieldset__legend">Ubicación</legend>
          <div className="s2-fieldset__content">
            {getFacetValues("department_project").map((item, index) => {
              const filterId = `exposed-department_project-${index}`;

              return (
                <FacetItem key={filterId}>
                  <input
                    type="radio"
                    {...register("exposed.department_project")}
                    value={item.filter}
                    id={filterId}
                  />
                  <label htmlFor={filterId} tabIndex={0}>
                    {item.filter}
                    <span>({item.count})</span>
                  </label>
                </FacetItem>
              );
            })}
          </div>
        </fieldset>

        <fieldset className="s2-fieldset s2-fieldset--tags">
          <legend className="s2-fieldset__legend">Tipo de inmueble</legend>
          <div className="s2-fieldset__content">
            {getFacetValues("property_type").map((item, index) => {
              const filterId = `exposed-property_type-${index}`;

              return (
                <FacetItem key={filterId}>
                  <input
                    type="radio"
                    {...register("exposed.property_type")}
                    value={item.filter}
                    id={filterId}
                  />
                  <label htmlFor={filterId} tabIndex={0}>
                    {item.filter}
                    <span>({item.count})</span>
                  </label>
                </FacetItem>
              );
            })}
          </div>
        </fieldset>

        <fieldset className="s2-fieldset s2-fieldset--tags">
          <legend className="s2-fieldset__legend">Estado</legend>
          <div className="s2-fieldset__content">
            {getFacetValues("field_state").map((item, index) => {
              const filterId = `exposed-field_state-${index}`;

              return (
                <FacetItem key={filterId}>
                  <input
                    type="radio"
                    {...register("exposed.field_state")}
                    value={item.filter}
                    id={filterId}
                  />
                  <label htmlFor={filterId} tabIndex={0}>
                    {getProjectStateLabel(item.filter)}
                    <span>({item.count})</span>
                  </label>
                </FacetItem>
              );
            })}
          </div>
        </fieldset>

        <fieldset className="s2-fieldset s2-fieldset--tags">
          <legend className="s2-fieldset__legend">Tipo</legend>
          <div className="s2-fieldset__content">
            {getFacetValues("field_immediate_delivery").map((item, index) => {
              const filterId = `exposed-field_immediate_delivery-${index}`;

              return (
                <FacetItem key={filterId}>
                  <input
                    type="radio"
                    {...register("exposed.field_immediate_delivery")}
                    value={item.filter}
                    id={filterId}
                  />
                  <label htmlFor={filterId} tabIndex={0}>
                    {getProjectImmediateDeliveryLabel(item.filter)}
                    <span>({item.count})</span>
                  </label>
                </FacetItem>
              );
            })}
          </div>
        </fieldset>

        <fieldset className="s2-fieldset s2-fieldset--tags">
          <legend className="s2-fieldset__legend">Tipo de servicio</legend>
          <div className="s2-fieldset__content">
            {getFacetValues("commerce_housing").map((item, index) => {
              const filterId = `exposed-commerce_housing-${index}`;

              return (
                <FacetItem key={filterId}>
                  <input
                    type="radio"
                    {...register("exposed.commerce_housing")}
                    value={item.filter}
                    id={filterId}
                  />
                  <label htmlFor={filterId} tabIndex={0}>
                    {getProjectCommerceHousingLabel(item.filter)}
                    <span>({item.count})</span>
                  </label>
                </FacetItem>
              );
            })}
          </div>
        </fieldset>
      </form>
    </ModalExposedForm>
  );
};

export default ExposedForm;
