/** @jsx jsx */
import React, { useContext } from 'react'
import { Box, jsx } from 'theme-ui'
import { SplideSlide } from '@splidejs/react-splide'
import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'

import { Icon } from 'ui'
import { CurrencyContext } from 'modules/currency/CurrencyContext'
import CurrencyPrice from 'modules/currency/CurrencyPrice'
import Splider from '../../component-v2/Splider'
import { formatPrice } from '../../modules/search-building/utils'
import { SEARCH_PROJECTS_QUERY } from '../queries'

// Default theme
import '@splidejs/react-splide/css'

function removeTags(str) {
  if (str === null || str === '') return false
  str = str.toString()

  return str.replace(/(<([^>]+)>)/gi, '')
}

function NewProjectsSlider({ withFilters, perPage }) {
  const currencySettings = useContext(CurrencyContext)
  const { loading, error, data, refetch } = useQuery(SEARCH_PROJECTS_QUERY, {
    variables: {
      searchText: '',
    },
  })

  const slideSettings = {
    perPage,
    padding: { left: 0, right: '4rem' },
  }

  if (loading) {
    return <div>Cargando ...</div>
  }

  if (error) {
    return <div>Error ...</div>
  }

  const projects = data?.searchAPISearch?.documents

  const regions = [
    { key: 'Antioquia', label: 'Antioquia' },
    { key: 'Cundinamarca', label: 'Cundinamarca' },
    { key: 'Atlántico', label: 'Atlántico' },
    { key: 'Magdalena', label: 'Magdalena' },
  ]

  return (
    <Box>
      {withFilters && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 'medium',
            justifyContent: 'space-between',
          }}
        >
          <div className='flex items-center flex-wrap gap-2'>
            <div className='basis-full md:basis-auto'>Por ubicación:</div>

            {regions.map(region => (
              <div>
                <a
                  className='inline-flex cursor-pointer rounded-2xl border border-solid border-coninsa-green-500
                    px-6 py-1 text-coninsa-green-500 font-semibold hover:bg-coninsa-green-500 hover:text-coninsa-white-300'
                  onClick={() => refetch({ searchText: `${region?.key}` })}
                >
                  {region?.label}
                </a>
              </div>
            ))}
          </div>

          <button
            className='hidden md:block border border-coninsa-green-300 bg-coninsa-green-300 font-bold text-sm text-white rounded-lg py-3 px-5 uppercase
            hover:border-coninsa-green-500 hover:bg-coninsa-green-500'
            onClick={() => window.open('/proyectos', '_blank')}
          >
            Todos los proyectos
          </button>
        </Box>
      )}

      <Splider options={slideSettings} aria-label='Proyectos nuevos'>
        {projects.map(project => {
          const imageUrl = project?.images?.[0]
          const summaryDesc = removeTags(project.description)
          const location = project?.location?.toLowerCase()

          const summaryDescStyles = {
            display: '-webkit-box',
            WebkitLineClamp: '4',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            fontSize: 1,
            mb: 2,
          }

          const locationStyles = {
            flexShrink: 0,
            fontSize: 2,
            mr: 1,
            '& path': {
              stroke: 'accent',
            },
          }

          const price = (project?.price || 0) / currencySettings.divider

          return (
            <SplideSlide>
              <co-card url={project.url} class='mb-4'>
                <img slot='image' src={imageUrl} />

                <outline-heading
                  slot='content'
                  level='h5'
                  level-style='normal'
                  class='text-coninsa-blue-900 font-display'
                  use-ellipsis
                >
                  {project.name}
                </outline-heading>

                <Box as='p' slot='content' sx={summaryDescStyles}>
                  {summaryDesc}
                </Box>

                <Box slot='content' sx={{ display: 'flex' }}>
                  <Icon slot='content' sx={locationStyles} name='gr-location' />

                  <div className='capitalize text-sm text-coninsa-blue-300 font-medium h-9'>
                    {location}
                  </div>
                </Box>

                <p slot='content' className='font-semibold mt-0'>
                  Desde
                  <span className='font-medium font-display ml-2 text-2xl'>
                    {formatPrice(price, currencySettings.symbol)}
                  </span>
                </p>
              </co-card>
            </SplideSlide>
          )
        })}
      </Splider>

      <Box slot='description' mb='1rem'>
        <CurrencyPrice />
      </Box>

      <button
        className='block w-full min-w-[7.125rem] md:hidden border border-coninsa-green-300
          bg-coninsa-green-300 font-bold text-sm text-white rounded-lg py-3 px-5 uppercase'
        onClick={() => window.open('/proyectos', '_blank')}
      >
        Todos los proyectos
      </button>
    </Box>
  )
}

NewProjectsSlider.defaultProps = {
  withFilters: true,
  perPage: 4,
}

NewProjectsSlider.propTypes = {
  withFilters: PropTypes.bool,
  perPage: PropTypes.number,
}

export default NewProjectsSlider
