import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'
import moment from 'moment'

import { CurrencyContext } from './CurrencyContext'

function CurrencyPrice({ isCenter }) {
  const currencySettings = useContext(CurrencyContext)
  const date = moment().format('DD/MM/YYYY')

  const baseStyles = {
    display: 'flex',
    gap: 2,
    mb: 'xsmall',
    justifyContent: isCenter ? 'center' : 'flex-start',
  }

  return (
    <Box>
      <Box sx={baseStyles}>
        {['cop', 'usd', 'eur'].map(currencyType => (
          <co-button
            key={`bnt-${currencyType}`}
            caps
            slot='description'
            variant={
              currencyType === currencySettings.currency
                ? 'currency-active'
                : 'currency'
            }
            onClick={() => currencySettings.setCurrency(currencyType)}
          >
            {currencyType}
          </co-button>
        ))}
      </Box>

      <Box sx={{ fontSize: 0, maxWidth: '37rem' }}>
        Este precio está calculado según la TRM del día {date}, tomada del Banco de
        la República y corresponde a una aproximación sobre el precio en pesos
        colombianos. Los términos y condiciones del proyecto, son los aplicados al
        valor en pesos.
      </Box>
    </Box>
  )
}

CurrencyPrice.defaultProps = {
  isCenter: false,
}

CurrencyPrice.propTypes = {
  isCenter: PropTypes.bool,
}

export default CurrencyPrice
