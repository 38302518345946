import React from "react";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import Heading from "@coninsa-s2/heading";
import Button from "@coninsa-s2/button";
import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";

import SearchPropertyCard from "@coninsa-site/property/src/enhancers/SearchPropertyCard";

export default function PropertyCards({ properties = [] }) {
  const sliderOptions = {
    arrowPath:
      "M9.392,28.344h21.7l-7.467,7.467a1.392,1.392,0,1,0,1.969,1.968l9.843-9.843h0a1.4,1.4,0,0,0,.173-.212c.023-.035.04-.073.06-.11a1.394,1.394,0,0,0,.068-.13,1.238,1.238,0,0,0,.045-.144c.011-.039.026-.076.034-.115a1.391,1.391,0,0,0,.027-.273h0s0,0,0,0a1.4,1.4,0,0,0-.027-.269c-.008-.042-.024-.08-.036-.121a1.292,1.292,0,0,0-.043-.138,1.441,1.441,0,0,0-.072-.138c-.019-.034-.034-.069-.055-.1a1.371,1.371,0,0,0-.174-.214L25.59,16.124a1.392,1.392,0,1,0-1.969,1.969l7.467,7.467H9.392a1.392,1.392,0,1,0,0,2.784Z",
    arrows: true,
    gap: 0,
    padding: { left: 0, right: "160px" },
    perPage: 3,
    perMove: 1,
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: 0,
        perPage: 1,
        padding: { left: 0, right: "1.5rem" },
        pagination: true,
      },
    },
  };

  return (
    <Section tint="white">
      <Container>
        <div className="md:flex justify-between gap-4 mb-8 items-center">
          <div>
            <Heading tint="teal" size="4xl" condensed className="mb-3">
              ¿Necesitas un inmueble{" "}
              <span className="is-highlighted">en arriendo?</span>
            </Heading>

            <p className="mb-4">
              Te presentamos nuestros inmuebles{" "}
              <strong>destacados en arriendo</strong>.
            </p>
          </div>

          <div className="flex gap-5">
            <Button href="/arrendamientos/comercio" target="_blank">
              Para comercio
            </Button>
            <Button href="/arrendamientos/vivienda" target="_blank">
              Para vivienda
            </Button>
          </div>
        </div>

        <Splide options={sliderOptions} className="s2-slider s2-slider--lg">
          {properties?.map((item) => (
            <SplideSlide key={item.id}>
              <div className="px-2 pt-2 pb-4 h-full">
                <SearchPropertyCard {...item} />
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </Container>
    </Section>
  );
}
