const dwellingRates = {
  d1: {
    id: "d1",
    label: "Súper Premium",
    percentage: "11%",
    description: "Fecha de pago el primer día hábil del mes.",
    note: "Si tomas dos de nuestros amparos adicionales te llevas la bolsa de mantenimiento",
    protections: [
      {
        id: "d1p1",
        label: "Amparos servicios públicos*",
        rate: "0.4%",
        amount: "11.4%",
        description: "Cubre hasta $1.000.000 por concepto de facturas de servicios domiciliarios dejados de pagar por tu ex arrendatario.",
      },
      {
        id: "d1p2",
        label: "Amparos daños y faltantes*",
        rate: "0.6%",
        amount: "12%",
        description: "Cubre hasta $1.000.000 por concepto de daños y faltantes dejados por tu arrendatario al final del contrato.",
      },
      {
        id: "d1p3",
        label: "Amparo vacancia",
        rate: "2.9%",
        amount: "14.9%",
        description: "Olvídate del lucro cesante cuando tu inmueble se desocupe, te cubrimos el 50% del canon por tres meses.",
      },
      {
        id: "d1p4",
        label: "Bolsa de mantenimiento",
        rate: "Incluida",
        amount: "$500.000",
        description: "Tomando las tarifas ESTÁNDAR, PREMIUM o SUPER PREMIUM y sumando dos de los tres amparos adicionales Coninsa te dará de forma automática el beneficio de una bolsa de mantenimiento que usaremos para atender las reparaciones de tu inmueble sin llamar a molestarte.",
      },
    ],
  },
  d2: {
    id: "d2",
    label: "Premium",
    percentage: "10.8%",
    description: "Fecha de pago del quinto día hábil del mes.",
    note: "Si tomas dos de nuestros amparos adicionales te llevas la bolsa de mantenimiento",
    protections: [
      {
        id: "d2p1",
        label: "Amparos servicios públicos*",
        rate: "0.4%",
        amount: "11.2%",
        description: "Cubre hasta $1.000.000 por concepto de facturas de servicios domiciliarios dejados de pagar por tu ex arrendatario.",
      },
      {
        id: "d2p2",
        label: "Amparos daños y faltantes*",
        rate: "0.6%",
        amount: "11.8%",
        description: "Cubre hasta $1.000.000 por concepto de daños y faltantes dejados por tu arrendatario al final del contrato.",
      },
      {
        id: "d2p3",
        label: "Amparo vacancia",
        rate: "2.9%",
        amount: "14.7%",
        description: "Olvídate del lucro cesante cuando tu inmueble se desocupe, te cubrimos el 50% del canon por tres meses.",
      },
      {
        id: "d2p4",
        label: "Bolsa de mantenimiento",
        rate: "Incluida",
        amount: "$300.000",
        description: "Tomando las tarifas ESTÁNDAR, PREMIUM o SUPER PREMIUM y sumando dos de los tres amparos adicionales Coninsa te dará de forma automática el beneficio de una bolsa de mantenimiento que usaremos para atender las reparaciones de tu inmueble sin llamar a molestarte.",
      },
    ],
  },
  d3: {
    id: "d3",
    label: "Estándar",
    percentage: "10.5%",
    description: "Fecha de pago el décimo día hábil del mes.",
    note: "Si tomas dos de nuestros amparos adicionales te llevas la bolsa de mantenimiento",
    protections: [
      {
        id: "d3p1",
        label: "Amparos servicios públicos*",
        rate: "0.4%",
        amount: "10.9%",
        description: "Cubre hasta $1.000.000 por concepto de facturas de servicios domiciliarios dejados de pagar por tu ex arrendatario.",
      },
      {
        id: "d3p2",
        label: "Amparos daños y faltantes*",
        rate: "0.6%",
        amount: "11.5%",
        description: "Cubre hasta $1.000.000 por concepto de daños y faltantes dejados por tu arrendatario al final del contrato.",
      },
      {
        id: "d3p3",
        label: "Amparo vacancia",
        rate: "2.9%",
        amount: "14.4%",
        description: "Olvídate del lucro cesante cuando tu inmueble se desocupe, te cubrimos el 50% del canon por tres meses.",
      },
      {
        id: "d3p4",
        label: "Bolsa de mantenimiento",
        rate: "Incluida",
        amount: "$200.000",
        description: "Tomando las tarifas ESTÁNDAR, PREMIUM o SUPER PREMIUM y sumando dos de los tres amparos adicionales Coninsa te dará de forma automática el beneficio de una bolsa de mantenimiento que usaremos para atender las reparaciones de tu inmueble sin llamar a molestarte.",
      },
    ],
  },
  d4: {
    id: "d4",
    label: "Básica",
    percentage: "10%",
    description: "Fecha de pago último día del mes.",
    note: "Si tomas dos de nuestros amparos adicionales te llevas la bolsa de mantenimiento",
    protections: [
      {
        id: "d4p1",
        label: "Amparos servicios públicos*",
        rate: "0.4%",
        amount: "10.4%",
        description: "Cubre hasta $1.000.000 por concepto de facturas de servicios domiciliarios dejados de pagar por tu ex arrendatario.",
      },
      {
        id: "d4p2",
        label: "Amparos daños y faltantes*",
        rate: "0.6%",
        amount: "11%",
        description: "Cubre hasta $1.000.000 por concepto de daños y faltantes dejados por tu arrendatario al final del contrato.",
      },
      {
        id: "d4p3",
        label: "Amparo vacancia",
        rate: "2.9%",
        amount: "13.9%",
        description: "Olvídate del lucro cesante cuando tu inmueble se desocupe, te cubrimos el 50% del canon por tres meses.",
      },
      {
        id: "d4p4",
        label: "Bolsa de mantenimiento",
        rate: "Incluida",
        amount: "N/A",
        description: "Tomando las tarifas ESTÁNDAR, PREMIUM o SUPER PREMIUM y sumando dos de los tres amparos adicionales Coninsa te dará de forma automática el beneficio de una bolsa de mantenimiento que usaremos para atender las reparaciones de tu inmueble sin llamar a molestarte.",
      },
    ],
  },
};

const commerceRates = {
  c1: {
    id: "c1",
    label: "Premium",
    percentage: "10%",
    description: "Fecha de pago del quinto día hábil del mes.",
    note: "Garantía de canon",
    protections: [
      {
        id: "c1p1",
        label: "Amparos servicios públicos*",
        rate: "0.4%",
        amount: "10.4%",
        description: "Cubre hasta $1.000.000 por concepto de facturas de servicios domiciliarios dejados de pagar por tu ex arrendatario.",
      },
      {
        id: "c1p2",
        label: "Amparos daños y faltantes*",
        rate: "0.6%",
        amount: "11%",
        description: "Cubre hasta $1.000.000 por concepto de daños y faltantes dejados por tu arrendatario al final del contrato.",
      },
    ],
  },
  c2: {
    id: "c2",
    label: "Estándar",
    percentage: "9%",
    description: "Fecha de pago el décimo día hábil del mes.",
    note: "Garantía de canon",
    protections: [
      {
        id: "c2p1",
        label: "Amparos servicios públicos*",
        rate: "0.4%",
        amount: "9.4%",
        description: "Cubre hasta $1.000.000 por concepto de facturas de servicios domiciliarios dejados de pagar por tu ex arrendatario.",
      },
      {
        id: "c2p2",
        label: "Amparos daños y faltantes*",
        rate: "0.6%",
        amount: "10%",
        description: "Cubre hasta $1.000.000 por concepto de daños y faltantes dejados por tu arrendatario al final del contrato.",
      },
    ],
  },
  c3: {
    id: "c3",
    label: "Básica",
    percentage: "8%",
    description: "Fecha de pago último día del mes.",
    note: "Garantía de canon",
    protections: [
      {
        id: "c3p1",
        label: "Amparos servicios públicos*",
        rate: "0.4%",
        amount: "8.4%",
        description: "Cubre hasta $1.000.000 por concepto de facturas de servicios domiciliarios dejados de pagar por tu ex arrendatario.",
      },
      {
        id: "c3p2",
        label: "Amparos daños y faltantes*",
        rate: "0.6%",
        amount: "9%",
        description: "Cubre hasta $1.000.000 por concepto de daños y faltantes dejados por tu arrendatario al final del contrato.",
      },
    ],
  },
};

export default {
  // Apartamento
  2433: dwellingRates,
  // Casa
  2434: dwellingRates,

  // Bodega
  2435: commerceRates,
  // Local
  2436: commerceRates,
  // Oficina
  2440: commerceRates,
  // Consultorio
  2437: commerceRates,
};
