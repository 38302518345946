import React from 'react'
import { Box } from 'theme-ui'

import { Field, Select } from 'ui/form'
import { useController } from 'react-hook-form'
import LocationField from '../services/LocationField'

const getSelectValue = name => (name ? { value: name, label: name } : null)

export function Input({
  control,
  name,
  options,
  placeholder,
  handleChange,
  disabled,
  ...props
}) {
  const {
    field: { ref, value, onChange },
  } = useController({
    name,
    control,
    defaultValue: '',
  })

  return (
    <Select
      value={getSelectValue(value)}
      // isClearable
      options={options}
      placeholder={placeholder}
      onChange={selected => {
        if (selected) {
          onChange(selected.value)
        }

        handleChange && handleChange(selected)
      }}
      ref={ref}
      isDisabled={disabled}
      {...props}
    />
  )
}

function Location({ name, setValue, control, placeholders }) {
  return (
    <Box>
      <LocationField>
        {({
          departments,
          cities,
          neighborhoods,
          updateCities,
          updateNeighborhoods,
        }) => (
          <>
            <Field>
              <Input
                name={`${name}.department`}
                control={control}
                options={departments}
                placeholder={placeholders.department}
                handleChange={selected => {
                  setValue(`${name}.city`, null)
                  setValue(`${name}.neighborhood`, null)

                  if (selected) {
                    updateCities(selected)
                  }
                }}
              />
            </Field>

            <Box sx={{ display: [null, 'flex'] }}>
              <Field sx={{ width: [null, '50%'], mr: [null, 2] }}>
                <Input
                  name={`${name}.city`}
                  control={control}
                  options={cities}
                  placeholder='Ciudad'
                  handleChange={selected => {
                    setValue(`${name}.neighborhood`, null)

                    if (selected) {
                      updateNeighborhoods(selected)
                    }
                  }}
                  disabled={!cities?.length}
                />
              </Field>

              <Field sx={{ width: [null, '50%'] }}>
                <Input
                  name={`${name}.neighborhood`}
                  control={control}
                  options={neighborhoods}
                  placeholder='Barrio'
                  disabled={!neighborhoods?.length}
                />
              </Field>
            </Box>
          </>
        )}
      </LocationField>
    </Box>
  )
}

Location.defaultProps = {
  placeholders: {
    department: 'Departamento',
    city: 'Ciudad',
    neighborhood: 'Barrio',
  },
}

export default Location
