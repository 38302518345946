import React from "react";
import Layout from "../../../layout/default/Layout";
import { Router, navigate } from "@reach/router";

import { PropertyTermsAndConditionsPage } from "@coninsa-site/terms-and-conditions";


function PropertyLanding() {
  return (
    <Layout>
      <Router basepath="/terminos-y-condiciones/arrendamientos">
        <PropertyTermsAndConditionsPage path="*" navigate={navigate} />
      </Router>
    </Layout>
  );
}

export default PropertyLanding;
