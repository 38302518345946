export default {
  grayly: {
    color: 'neutral',
  },

  bluey: {
    color: 'white',
  },

  greenly: {
    color: 'neutral',
  },

  whitely: {
    color: 'neutral',
  },

  darkly: {
    color: 'white',
  },

  lightly: {
    color: 'green-3',
    headline: {
      color: 'white',
    },
  },
}
