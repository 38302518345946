import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { AuthContext } from './AuthDrupalContext'

export const PRIVATE_ROOT_PAGE = '/inicio'
export const PUBLIC_DEFAULT_PAGE = '/'

export const PrivateRoute = ({ render: Component, redirectTo, ...props }) => {
  const auth = useContext(AuthContext)
  const redirect = redirectTo || PUBLIC_DEFAULT_PAGE

  if (!auth.isAuthenticated) {
    navigate(redirect)
  }

  return <Component {...props} />
}

PrivateRoute.defaultProps = {
  redirectTo: null,
}

PrivateRoute.propTypes = {
  redirectTo: PropTypes.string,
  render: PropTypes.func.isRequired,
}

export const PublicRoute = ({ render: Component, redirectTo, ...props }) => {
  const auth = useContext(AuthContext)
  const redirect = redirectTo || PRIVATE_ROOT_PAGE

  if (auth.isAuthenticated) {
    navigate(redirect)
  }

  return <Component {...props} />
}

PublicRoute.defaultProps = {
  redirectTo: null,
}

PublicRoute.propTypes = {
  redirectTo: PropTypes.string,
  render: PropTypes.func.isRequired,
}
