import React from 'react';
import clsx from "clsx";

import "./styles.css";

function Shape({ variant, tint, ...props }) {
  const classes = {
    "s2-shape": true,
    [`s2-shape--${variant}`]: variant,
    [`s2-shape--${tint}`]: tint,
  }

  return (
    <div className={clsx(classes)}>
      {props.children}
    </div>
  );
}

export default Shape;
