import MemberCard from './MemberCard'
import MemberCardName from './MemberCardName'
import MemberCardPhoto from './MemberCardPhoto'
import MemberCardTag from './MemberCardTag'

MemberCard.Name = MemberCardName
MemberCard.Photo = MemberCardPhoto
MemberCard.Tag = MemberCardTag

export default MemberCard
