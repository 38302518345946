import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

function CountCard({ highlighted, text, measure, start, time, limit }) {
  const [seconds, setSeconds] = useState(start)
  const [runTimer, setRunTimer] = useState(true)

  useEffect(() => {
    let interval;

    if (runTimer) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds + 1)
      }, time);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval)
  }, [runTimer])

  useEffect(() => {
    if (seconds === limit) {
      setRunTimer(false)
    } 
  }, [seconds])

  return (
    <div className='mb-3 md:mb-0 rounded-lg px-6 py-4 shadow-[0px_3px_6px_#00000029] text-center'>
      <h2 className='font-bold text-5xl md:text-6xl text-coninsa-green-500'>
        <span className='text-coninsa-green-300'>+ </span>
        {seconds}{measure}
      </h2>
      <div className='text-xl text-coninsa-blue-900'>
        <p className='font-bold '>{highlighted}</p>
        <p>{text}</p>
      </div>
    </div>
  )
}

CountCard.defaultProps = {
  measure: ''
}

CountCard.propTypes = {
  seconds: PropTypes.string.isRequired,
  highlighted: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  measure: PropTypes.string,
  start: PropTypes.number.isRequired,
  time: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired
}

export default CountCard