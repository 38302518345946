import React from "react";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Breadcrumb, { BreadcrumbItem } from "@coninsa-s2/breadcrumb";
import Heading from "@coninsa-s2/heading";

export default function GloballCampaign() {
  const breadcrumbItems = [
    {
      label: "Inicio",
      url: "/terminos-y-condiciones",
    },
    {
      label: "Términos y condiciones generales",
      url: "/terminos-y-condiciones/generales",
    },
    {
      label: "Campaña mundialista 2018",
    },
  ];

  return (
    <Section>
      <Container>
        <div className="mb-8">
          <Breadcrumb>
            {breadcrumbItems.map((item) => (
              <BreadcrumbItem>
                {item.url && <a href={item.url}>{item.label}</a>}
                {!item.url && item.label}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        </div>
        <div className="mb-7">
          <span className="text-lg text-s2-dark-blue lg:text-[22px]">
            Términos y condiciones
          </span>
          <Heading size="2xl">Campaña mundialista 2018</Heading>
        </div>

        <div class="prose">
          <p>
            Proyectos Vivienda Bogotá junio y julio 2018 (segunda fase) Coninsa
            Ramón H. 2018
          </p>
          <ol>
            <li>
              <h4>Vigencia:</h4>
              <p>
                La actividad campaña mundialista Coninsa Ramón H. 2018 (SEGUNDA
                FASE) se realizará del 1 de junio al 31 de julio de 2018, en la
                ciudad de Bogotá.
              </p>
            </li>
            <li>
              <h4>El organizador:</h4>
              <p>
                La presente actividad está organizada por Coninsa Ramón H S.A
                con Nit 890911431-1 con domicilio principal en la Carrera 45 #55
                – 45 en Medellín teléfonos en Medellín: (57+4) 448 88 28, Región
                Bogotá: (57+1) 6014800 y Región Caribe: (57+5) 319 96 10, es una
                promoción que aplica únicamente para quienes cierren y legalicen
                negocios en compra de vivienda nueva de los proyectos que
                participen de esta actividad, cuya vigencia se especifica en el
                primer punto de estos términos y condiciones.
              </p>
            </li>
            <li>
              <h4>Objeto y mecánica de la promoción</h4>
              <p>
                Objeto: Campaña mundialista Coninsa Ramón H. vivienda Bogotá
                (segunda fase) es una actividad que premia durante la vigencia
                descrita a los compradores de vivienda de los proyectos
                participantes (Castilla la Nueva, Reserva 67, Sue, Akros, Think,
                Reserva de Mallorca).
              </p>
              <p>El mecanismo de la promoción será la siguiente:</p>
              <ol>
                <li>
                  Coninsa Ramón H. S.A lanzará la actividad del 1 de junio al 31
                  de julio en su segunda fase.
                </li>
                <li>
                  Segunda fase proyectos Castilla La nueva y Reserva 67, Akros,
                  Think, Reserva de Mallorca: En esta segunda etapa se entregará
                  como premio a la persona que cierre negocio durante la
                  vigencia de la actividad (punto 3.1) un descuento sobre el
                  valor de la vivienda.
                  <p>
                    Se considera cierre de negocio: cuando el Cliente firme en
                    la sala de negocios el contrato de vinculación.
                  </p>
                </li>
                <li>
                  Segunda fase proyecto Sue: En esta segunda etapa se entregará
                  como premio a la persona que cierre negocio durante la
                  vigencia de la actividad (punto 3.1) un (1) balón de fútbol
                  No. 5 marca Golty, tradicional dualtech, tecnología dual,
                  cosido y laminado, material PVC (cumple con las
                  especificaciones técnicas internacionales). Aplica un (1)
                  balón por cada cierre de negocio.
                  <p>
                    Se considera cierre de negocio: cuando el Cliente firme en
                    la sala de negocios el contrato de vinculación.
                  </p>
                </li>
                <li>
                  Definición de cierre de negocio (se considera cierre de
                  negocio cuando se firme el contrato de vinculación del
                  inmueble comprado).
                </li>
                <li>
                  Las personas ganadoras de los premios mencionados autorizan a
                  Coninsa Ramón H. S.A a difundir y/o publicar cualquier dato
                  que haya sido suministrado en su inscripción, en caso que
                  decida identificarlo como ganador de la promoción o para
                  cualquier otro fin comercial que la empresa organizadora
                  considere necesario, sin obligación de compensación alguna.
                </li>
                <li>
                  Los derechos que le asisten como Titular de los datos
                  personales objeto de Tratamiento son los señalados en el
                  artículo 8 de la Ley 1581 de 2012.
                </li>
              </ol>
            </li>
            <li>
              <h4>Disposiciones legales</h4>
              <ol>
                <li>
                  Una vez los Clientes ganadores hayan recibido el valor del
                  descuento o el balón de fútbol (dependiendo de cuál aplique de
                  acuerdo al proyecto en el que se haya comprado el inmueble) no
                  se admitirán cambios por dinero, valores o cualquier otro
                  bien. El derecho al canje no es transferible, negociable, ni
                  puede ser comercializado o canjeado por dinero en efectivo, ni
                  por ningún otro premio.
                </li>
                <li>
                  El solo hecho de participar en esta actividad implica la total
                  aceptación de los presentes términos y condiciones.
                </li>
                <li>
                  Los participantes se comprometen a eximir de responsabilidad a
                  Coninsa Ramón H. S.A., sus accionistas, empleados o
                  colaboradores y frente a cualquier acción, daño, costes, y
                  otras responsabilidades en las que pudiera incurrir Coninsa
                  Ramón H. S.A. como resultado de cualquier tipo de
                  incumplimiento por parte suya de alguno de alguno de los
                  términos y condiciones de un uso o manipulación indebido de
                  los premios que incluye la presente actividad en cualquiera de
                  sus fases.
                </li>
                <li>
                  Al aceptar estos términos y condiciones los participantes
                  reconocen que de manera libre, voluntaria y espontánea han
                  decidido participar en esta actividad, por lo que dentro de
                  los límites permitidos por la ley Colombiana, renuncian a
                  cualquier reclamación o acción de cualquier naturaleza en
                  contra de Coninsa Ramón H. S.A., sus accionistas, empleados o
                  colaboradores, que tengan por objeto reclamar indemnización
                  por daños o perjuicios que les haya causado su participación
                  en la actividad, o de cualquier manera relacionados con esta,
                  o por las actividades que realizare como consecuencia de dicha
                  participación. Si el premio no es aceptado o sus condiciones,
                  se considerará que ha renunciado al mismo y no tendrá derecho
                  a reclamo o indemnización alguna, ni siquiera parcialmente.
                </li>
                <li>
                  La responsabilidad inherente a la actividad por parte de
                  Coninsa Ramón H. S.A. culmina con la entrega del premio que
                  corresponda al Cliente ganador que haya cumplido las
                  condiciones de la actividad.
                </li>
              </ol>
            </li>
            <li>
              <h4>Condiciones y restricciones</h4>
              <ol>
                <li>
                  Podrán participar en la actividad todas aquellas personas
                  naturales mayores de edad, residentes en Colombia que cumplan
                  los términos y condiciones señalados en este documento,
                  excepto empleados de Coninsa Ramón H. S.A.
                </li>
                <li>
                  Aplica únicamente para negocios que sean separados y
                  legalizados durante la vigencia de la promoción en su segunda
                  fase (vigencia punto 3.1)
                </li>
                <li>
                  Ningún premio podrá ser canjeado total o parcialmente por
                  dinero en efectivo, ni por ningún otro bien, sin excepciones.
                </li>
                <li>
                  Serán entregados los premios correspondientes únicamente una
                  vez se legalice el negocio de compra del inmueble (firma del
                  contrato de vinculación en sala de negocios).
                </li>
                <li>
                  Se tendrán varios valores de descuentos (que aplican solo para
                  los proyectos mencionados en el punto 3.2). Los descuentos
                  pueden ser de hasta de $20 millones (pudiendo ser menores a
                  este valor). Cada valor de descuento se da de acuerdo a unas
                  condiciones de negocio específicas que aplican de acuerdo al
                  proyecto, inmueble comprado, forma de pago del inmueble, plazo
                  de pago, entre otros.
                </li>
                <li>
                  Para los balones Golty el Cliente tendrá un plazo de 8 días
                  hábiles posterior a la entrega de los mismos para hacer la
                  reclamación en la Sala de negocios en la cual se le haya
                  entregado el premio (esta garantía se da por defectos de
                  fabricación bajo condiciones de uso normal). Una vez pasado
                  este tiempo no se harán devoluciones por ningún motivo.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </Container>
    </Section>
  );
}
