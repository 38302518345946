import React from 'react'
import { navigate } from 'gatsby'
import { Box } from 'theme-ui'
import PropTypes from 'prop-types'
import { Button } from 'ui'

function Control({ targetId, entity, editUrl, ...props }) {
  const handleEditClick = () => {
    navigate(editUrl)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        border: '1px dashed #ccc',
        py: 'small',
        px: 'small',
        mb: 'xsmall',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box as='strong' sx={{ textTransform: 'capitalize', mr: 'medium' }}>
        {entity.title}
      </Box>

      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button type='button' size='small' variant='plain' onClick={handleEditClick}>
          Editar
        </Button>
        <Button type='button' size='small' variant='plain'>
          Eliminar
        </Button>
      </Box>
    </Box>
  )
}

Control.defaultProps = {
  children: null,
  label: '',
  required: false,
}

Control.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  required: PropTypes.bool,
}

export default Control
