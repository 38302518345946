import React from 'react'
import Splider from 'component-v2/Splider'
import { SplideSlide } from '@splidejs/react-splide'

import achievementsInfo from '../../data/achievements.js'

function AchievementCard() {
  const slideSettings = {
    autoplay: true,
    perMove: 1,
    perPage: 2,
    rewind: true,
    padding: { left: 0, right: '12rem' },
    breakpoints: {
      1024: {
        perPage: 2,
        padding: { left: 0, right: '8rem' },
      },
      768: {
        perPage: 2,
        padding: { left: 0, right: '4rem' },
      },
      640: {
        perPage: 1,
        padding: { left: 0, right: '4rem' },
      },
    },
  }

  return (
    <Splider slot='content' options={slideSettings}>
      {achievementsInfo.map(achievement => (
        <SplideSlide>
          <div className='flex gap-4 mb-8'>
            <div>
              <img src={achievement?.image} className='rounded-t-xl w-full' />
              <div className='bg-white rounded-b-xl p-5 shadow-[0px_3px_6px_#00000029] h-56'>
                <p className='font-bold text-xl text-coninsa-green-500 mb-6 md:mb-8'>
                  {achievement?.year}
                </p>
                <p className='font-normal'>{achievement?.description}</p>
              </div>
            </div>
          </div>
        </SplideSlide>
      ))}
    </Splider>
  )
}

export default AchievementCard
