import { useStaticQuery, graphql } from 'gatsby'
import { paragraphResolver } from '../../paragraphs'
import { awardMapper } from './OurAwards'

function ConstructionAwards() {
  const data = useStaticQuery(graphql`
    query ConstructionAwards {
      drupal {
        query: nodeQuery(
          filter: {
            conditions: [
              { field: "type", value: ["award"] }
              { field: "field_microsite", value: ["2"] }
              { field: "status", value: ["1"] }
            ]
          }
          sort: [{ field: "field_date", direction: DESC }]
        ) {
          awards: entities {
            id: entityId
            ... on Drupal_NodeAward {
              title
              body {
                value
              }
              image: fieldPicture {
                alt
                derivative(style: IMAGECARD) {
                  url
                }
              }
              date: fieldDate {
                value
              }
              created
            }
          }
        }
      }
    }
  `)

  const rawAwards = data?.drupal?.query?.awards || []
  const awards = rawAwards.map(awardMapper)

  const sliderStructure = {
    arrowsPosition: 'bottom',
    autoplay: true,
    autoplaySpeed: 3000,
    items: awards,
    slidesToScroll: 2,
    slidesToShow: [1, 4],
    type: 'slider',
    variant: 'default',
    responsive: [
      {
        breakpoint: 480,
        settings: {
          dots: false,
          slidesToScroll: 1,
          slidesToShow: 1,
        },
      },
    ],
  }

  return paragraphResolver(sliderStructure)
}

export default ConstructionAwards
