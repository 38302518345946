import React, { Component } from 'react'
import axios from 'axios'
import moment from 'moment'

export const CurrencyContext = React.createContext({})

export class CurrencyProvider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currency: 'cop',
      divider: 1,
      symbol: '$',
      language: 'es-CO'
    }
  }

  async getExchangeValue() {
    const response = await axios.get(
      'https://admindrupal.coninsa.co/current/currencies'
    )

    return response?.data
  }

  async initCurrencySettings(yesterday) {
    const currencyValues = await this.getExchangeValue()

    localStorage.setItem('coninsa.currency.init', 1)
    localStorage.setItem('coninsa.currency.type', 'cop')
    localStorage.setItem('coninsa.currency.usd', currencyValues.usd)
    localStorage.setItem('coninsa.currency.eur', currencyValues.eur)
    localStorage.setItem('coninsa.currency.last_request', yesterday)
  }

  async componentDidMount() {
    const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD')
    const isInizalizing = localStorage.getItem('coninsa.currency.init')

    // Validating if the currency value is stored on the local storage.
    if (!isInizalizing) {
      await this.initCurrencySettings(yesterday)
    } else {
      const lastDate = localStorage.getItem('coninsa.currency.last_request')

      // Validating if the currency exchange is appropriate for the current date.
      if (lastDate !== yesterday) {
        await this.initCurrencySettings(yesterday)
      }

      const currencyType = localStorage.getItem('coninsa.currency.type')

      this.setCurrency(currencyType)
    }
  }

  getDivider = currencyType => {
    const currencyMap = {
      cop: 1,
      usd: localStorage.getItem('coninsa.currency.usd'),
      eur: localStorage.getItem('coninsa.currency.eur'),
    }

    return currencyMap[currencyType]
  }

  getSymbol = currencyType => {
    const symbolMap = {
      cop: '$',
      usd: '$ USD',
      eur: '€',
    }

    return symbolMap[currencyType]
  }

  getLanguage = currencyType => {
    const languageMap = {
      cop: 'es-CO',
      usd: 'en-US',
      eur: 'es-ES',
    }

    return languageMap[currencyType]
  }

  setCurrency = type => {
    localStorage.setItem('coninsa.currency.type', type)

    this.setState({
      currency: type,
      divider: this.getDivider(type),
      symbol: this.getSymbol(type),
      language: this.getLanguage(type),
    })
  }

  render() {
    const { currency, divider, symbol, language } = this.state
    const currencyProps = {
      currency,
      divider,
      symbol,
      language,

      setCurrency: this.setCurrency,
    }

    const { children } = this.props

    return (
      <CurrencyContext.Provider value={currencyProps}>
        {children}
      </CurrencyContext.Provider>
    )
  }
}
