import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import { Box } from 'theme-ui'

import { Icon } from 'ui'

import BaseLink from './BaseLink'
import BaseWrapper from './BaseWrapper'
import MenuWrapper from './MenuWrapper'
import SubMenuLink from './SubMenuLink'

import { HamburgerMenuContext } from '../HamburgerMenu/HamburgerMenu'

function MenuLink({
  columns,
  end,
  icon,
  label,
  links,
  target,
  url,
  variant,
  wrapper,
  ...props
}) {
  const [open, setOpen] = useState(false)
  const isTabletOrMobile = useMediaQuery({ maxWidth: 991 })
  const { close: closeHamburger } = useContext(HamburgerMenuContext)

  const openBaseStyles = open
    ? {
        background: 'white',
        position: 'absolute',
        top: '1px',
        left: '0',
        right: '0',
        bottom: '0',
        padding: 'medium',
        overflowY: 'scroll',
      }
    : {}

  const desktopStyles = !isTabletOrMobile
    ? {
        '&:hover': {
          '& > .menu-wrapper': {
            borderTop: '1px solid',
            borderTopColor: 'secondary',
            display: 'grid',
            left: 0,
            right: 0,
            position: ['relative', 'absolute'],
            zIndex: 1,
          },
        },
      }
    : {}

  const baseStyles = {
    ...desktopStyles,
    ...openBaseStyles,
  }

  const contentLayout = isTabletOrMobile && open ? 'grid' : 'none'

  const wrapperStyles = {
    boxShadow: ['none', null, null, '0 5px 10px rgba(0, 0, 0, 0.2)'],
    display: contentLayout,
  }

  const hasChildren = links.length > 0

  const handleClick = event => {
    if (isTabletOrMobile && hasChildren) {
      setOpen(true)
      event.preventDefault()
    }

    if (end) {
      closeHamburger()
    }
  }

  const backStyles = {
    fontWeight: '100',
    textTransform: 'uppercase',
    fontSize: 0,
    cursor: 'pointer',
  }

  const moreIconStyles = {
    fontSize: '10px',
    ml: [3, 1],
    color: 'secondary',
    display: [null, null, 'none', null, 'inline'],
  }

  const moreIconName = isTabletOrMobile ? 'fa-chevron-right' : 'fa-chevron-down'

  return (
    <Box
      __css={baseStyles}
      variant={`${variant}.parent`}
      {...props}
      __themeKey='links'
    >
      {open && (
        <Box sx={backStyles} onClick={() => setOpen(false)}>
          <Icon name='fa-chevron-left' sx={{ fontSize: '10px' }} /> Volver
        </Box>
      )}
      <BaseLink
        icon={icon}
        url={url}
        variant={variant}
        more={hasChildren.toString()}
        onClick={handleClick}
        target={target}
      >
        {label}
        {!open && hasChildren && <Icon name={moreIconName} sx={moreIconStyles} />}
      </BaseLink>

      {hasChildren && (
        <BaseWrapper
          className='menu-wrapper'
          columns={columns}
          sx={wrapperStyles}
          {...wrapper}
        >
          {links.map((link, index) => {
            const keyValue = `ml-${index}`

            return link.wrapper.enabled ? (
              <MenuWrapper key={keyValue} {...link} />
            ) : (
              <SubMenuLink key={keyValue} {...link} />
            )
          })}
        </BaseWrapper>
      )}
    </Box>
  )
}

MenuLink.defaultProps = {
  columns: '',
  end: false,
  icon: {},
  links: [],
  target: null,
  url: { path: '#' },
  variant: 'main',
  wrapper: {},
}

MenuLink.propTypes = {
  columns: PropTypes.string,
  end: PropTypes.bool,
  icon: PropTypes.shape({}),
  label: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.object),
  target: PropTypes.string,
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  variant: PropTypes.string,
  wrapper: PropTypes.shape({}),
}

export default MenuLink
