import craneIcon from "../assets/icons/teal-crane.svg"
import handshakeIcon from "../assets/icons/teal-handshake.svg"
import budgetIcon from "../assets/icons/teal-budget.svg"
import tabletIcon from "../assets/icons/teal-tablet.svg"
import toolsIcon from "../assets/icons/teal-tools.svg"

export default [
  {
    id: 'offer-1',
    icon: craneIcon,
    title: 'Construcción de obras',
    description: 'Por Administración Delegada, Precios Unitarios, Suma Global y Llave en Mano para obras públicas y privadas.',
  },
  {
    id: 'offer-2',
    icon: handshakeIcon,
    title: 'Alianzas',
    description: 'en la conformación de Consorcios o Uniones Temporales con Diseñadores y otros Constructores tanto nacionales como internacionales.',
  },
  {
    id: 'offer-3',
    icon: budgetIcon,
    title: 'Presupuestos',
    description: 'De construcción preliminares y detallados.',
  },
  {
    id: 'offer-4',
    icon: tabletIcon,
    title: 'Programación',
    description: 'Y control de obras',
  },
  {
    id: 'offer-5',
    icon: toolsIcon,
    title: 'Diseño',
    description: 'De Proyectos de construcción integrales.',
  },
]
