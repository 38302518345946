import React from "react";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { arrowPath } from "@coninsa-s2/slider";

import Section from "@coninsa-s2/section";
import Heading from "@coninsa-s2/heading";
import Shape from "@coninsa-s2/shape";
import IconCard from "@coninsa-s2/icon-card";
import Container from "@coninsa-s2/container";
import Cloud from "@coninsa-s2/cloud";

import items from "./data";

function OurExperience() {
  const options = {
    arrowPath,
    arrows: true,
    gap: 0,
    padding: { left: 0, right: "100px" },
    perPage: 2,
    perMove: 1,
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: 0,
        perPage: 1,
        padding: { left: 0, right: "1.5rem" },
        pagination: true,
      },
    },
  };

  return (
    <Section tint="white">
      <Container className="grid gap-6 grid-cols-1 md:grid-cols-4 items-center">
        <div>
          <Heading size="4xl" className="mb-6" condensed>
            <span className="is-highlighted">Nuestra Experiencia</span>
          </Heading>
          <p className="md:text-xl md:leading-6">
            Desde hace <span className="font-bold"> más de 50 años</span> <br />
            Construimos Bienestar
          </p>
        </div>
        <div className="md:col-span-3 relative">
          <Cloud
            className="absolute -left-2 md:-left-6 -top-6 md:-top-10"
            iconDirection="right"
            iconPosition="left-top"
            tint="teal"
            variant="rectangle"
          />

          <Splide options={options} className="s2-slider s2-slider--lg">
            {items.map((item) => (
              <SplideSlide key={item.id}>
                <div className="px-2 pt-2 h-full pb-4">
                  <IconCard>
                    <Shape>
                      <img src={item.icon} alt="Icon" />
                    </Shape>
                    <Heading size="lg">{item.title}</Heading>
                    <p>{item.description}</p>
                  </IconCard>
                </div>
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </Container>
    </Section>
  );
}

export default OurExperience;
