import { graphql } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { Box, Container, Image } from 'theme-ui'
import moment from 'moment'
import 'moment/locale/es'

import FacebookPixel from '../analytics/facebook-pixel'

import { Header, Heading, List, Section, Tabs } from 'components'
import InterestCards from 'modules/search-building/components/InterestCards'
import PropertyMap from 'modules/search-building/components/PropertyMap'
import Layout from '../layout/default/Layout'
import RelatedProjects from '../modules/search-building/slices/RelatedProjects'
import ProjectBanner from '../modules/search-building/slices/ProjectBanner'
import ProjectGallery from '../modules/search-building/slices/ProjectGallery'
import IdealPlace from '../modules/search-building/slices/IdealPlace'
import ProjectInfo from '../modules/search-building/components/ProjectInfo'
import ProjectTerms from '../modules/search-building/components/ProjectTerms'
import Schedules from '../modules/search-building/slices/Schedules'
import Timeline from '../modules/search-building/components/Timeline'
import InterestPoints from '../modules/search-building/slices/InterestPoints'
import { formatPrice } from '../modules/search-building/utils'
import FloatingWhatsappBtn from '../modules/home/components/FloatingWhatsappBtn'
import BuildingMobileFloatingBar from '../modules/search-building/components/BuildingMobileFloatingBar'
import AmenitiesTable from '../modules/search-building/slices/AmenitiesTable'

moment.locale('es')

const normalizeMediaAsset = asset => {
  const nAsset = {}

  // eslint-disable-next-line default-case
  switch (asset.entity.bundle) {
    case 'remote_video':
      nAsset.video = asset?.entity?.video
      nAsset.thumbnail = asset?.entity?.thumbnail?.entity?.url
      break
    case 'document':
      nAsset.document = asset?.entity?.document?.entity?.url
      nAsset.name = asset?.entity?.document?.entity?.filename
      break
  }

  return nAsset
}

const normalizeWorkProgressItems = (items = []) => {
  const nItems = items
    .map(item => item?.entity)
    .sort((a, b) => new Date(b.date.date) - new Date(a.date.date))
    .map(item => ({
      date: moment(item.date.date).format('MMMM Y'),
      assets: item?.assets?.map(normalizeMediaAsset),
      title: item?.title,
    }))
  // Uncomment this line if it's necessary.
  // .reverse()

  return nItems
}

function ProjectTemplate({ data, ...props }) {
  const info = data?.drupal?.entity
  const buildingPath = info?.projectUrl.path
  const codeProject = info?.codeProject
  const code = info?.code
  const rawCanonFrom = info?.canonFrom
  const rawCanonTo = info?.canonTo
  const areaFrom = info?.areaFrom
  const areaTo = info?.areaTo
  const paymentMethod = info?.paymentMethod
  const name = info?.name
  const bannerImage = info?.bannerImage?.derivative?.url
  const subtitle = info?.subtitle
  const description = info?.description?.value
  const link = info?.link?.url?.path
  const state = info?.state?.entity
  const soldOut = info?.state?.entity?.machineName
  const isSoldOut = soldOut === 'sold_out'
  const isVis = info?.isVis
  const brand = info?.brand?.url
  const typicals = info?.typicalFloors
  const plans = info?.plansImages
  const schedules = info?.schedules?.value
  const cityImage = info?.cityImage?.url
  const rawCityDesc = info?.cityDesc?.value
  const pdfLink = info?.pdfLink?.url?.path
  const cityDesc = rawCityDesc
  const whatsapp = info?.whatsapp
  const location = [
    info?.location?.entity?.parent[0]?.entity?.name,
    info?.location?.entity?.parent[0]?.entity?.parent[0]?.entity?.name,
  ].join(' en ')
  const latLon = info?.latLon
  const projects = info?.related?.results || []

  const canonFrom = formatPrice(rawCanonFrom)
  const canonTo = formatPrice(rawCanonTo)

  const videos = info?.videos?.map(({ entity }) => entity)
  const images = info?.images
  const workProgressItems = normalizeWorkProgressItems(info?.workProgress)
  const workProgressDesc = info?.workProgressDesc
  const tabs = info?.tabs
  const commonAreas = info?.commonAreas || []
  const interestPoints = info?.interestPoints || []
  const simulatorScript = info?.simulatorScript

  const buildingLocation = [
    tabs?.areas?.property?.location?.entity?.parent[0]?.entity?.parent[0]?.entity
      ?.name,
    info?.location?.entity?.parent[0]?.entity?.name,
    info?.location?.entity?.name,
  ].join(', ')

  const whatsappMsg = `
    Hola, quiero compartir contigo estos proyectos de Coninsa Ramón H.,
    que creo te interesarán. Haz clic en el link del proyecto para conocerlo y/o
    contactarte con el Asesor. ${name} Código: ${code} Precio desde: ${canonFrom} -
    hasta: ${canonTo} Área desde: ${areaFrom} m2 - hasta: ${areaTo} m2
    https://www.coninsa.co${buildingPath}`

  const number = '573123636333'
  const whatsappChatMsg = `Hola! los vi en coninsa.co, me gustaría obtener más información. Proyecto: ${code}`
  const canonical = `https://www.coninsa.co${buildingPath}`

  const pointStyles = {
    pl: 0,
    listStyle: 'none',
    textAlign: 'left',
    '& li:before': {
      color: '#80C342',
      display: 'inline-block',
      content: '"•"',
      width: '.75rem',
      fontWeight: '600',
    },
  }

  const zohoDetail = {
    owner: info?.owner,
    interestedProject: info?.interestedProject,
    regional: info?.regional,
  };

  return (
    <Layout
      footerAds={info?.footerAds}
      headerAds={info?.headerAds}
      popupAds={info?.popUpAds}
    >
      <Helmet>
        <title>{name} | Coninsa</title>
        <meta name="description" content="Coninsa se destaca en el sector inmobiliario con proyectos de venta innovadores y sostenibles. Ofrecen opciones residenciales y comerciales que combinan diseño moderno, funcionalidad y eficiencia energética. Sus proyectos están diseñados para enriquecer la vida urbana y promover un estilo de vida contemporáneo, asegurando una inversión valiosa y duradera para todos los compradores." />
        <link rel="canonical" href={canonical} />
      </Helmet>

      {code == '11114' && (
        <FacebookPixel trackingId='1760318298106846' />
      )}

      <Box sx={{ display: ['none', 'block'] }}>
        <FloatingWhatsappBtn path='https://api.whatsapp.com/send?phone=573123636333&text=Hola!' />
      </Box>

      <BuildingMobileFloatingBar
        buildindType='proyecto'
        label='Contactar'
        path={`https://api.whatsapp.com/send?phone=${number}&text=${whatsappChatMsg}`}
      />

      <Box {...props}>
        <Container>
          <ProjectBanner
            areaFrom={areaFrom}
            areaTo={areaTo}
            brand={brand}
            canonFrom={canonFrom}
            canonTo={canonTo}
            code={code}
            name={name}
            subtitle={subtitle}
            state={state}
            isVis={isVis}
            path={buildingPath}
            videos={videos}
            banner={bannerImage}
            whatsappMsg={whatsappMsg}
          />

          <Box
            sx={{
              display: ['block', 'grid'],
              gridTemplateColumns: '75% 25%',

              mb: 'large',
              px: [4, 0],
            }}
          >
            <Box
              sx={{
                flex: '1 1 auto',
                mb: ['medium', 0],
                mr: 4,
              }}
            >
              <Tabs activeIndex={0}>
                <Tabs.Item label='Descripción'>
                  <Box px={[5, 0]}>
                    <Heading variant='label'>{name}</Heading>
                    <Box
                      sx={{ fontSize: 1, mb: '24px' }}
                      dangerouslySetInnerHTML={{ __html: `${description}` }}
                    />

                    {commonAreas.length > 0 && (
                      <AmenitiesTable commonAreas={commonAreas} />
                    )}

                    <ProjectTerms link={link} />
                  </Box>
                </Tabs.Item>

                {plans?.length > 0 && (
                  <Tabs.Item label='Urbanismo'>
                    <Box px={[5, 0]}>
                      <Box sx={{ fontSize: 1 }}>
                        <Box>
                          {plans?.map(plan => (
                            <Image src={plan.url} />
                          ))}
                        </Box>
                        <Box>
                          <ProjectTerms link={link} />
                        </Box>
                      </Box>
                    </Box>
                  </Tabs.Item>
                )}
                {typicals?.length > 0 && (
                  <Tabs.Item label='Plantas típicas'>
                    <Box px={[5, 0]}>
                      <Box>
                        {typicals?.map(typical => (
                          <Image src={typical.url} />
                        ))}
                      </Box>
                    </Box>
                  </Tabs.Item>
                )}

                {workProgressItems?.length > 0 && (
                  <Tabs.Item id={`avance-${code}`} label='Avance de proyecto' >
                    <Box px={[5, 0]}>
                      <Timeline
                        name={name}
                        desc={workProgressDesc}
                        items={workProgressItems}
                      />
                    </Box>
                  </Tabs.Item>
                )}
              </Tabs>
            </Box>
            {!isSoldOut && (
              <ProjectInfo
                name={name}
                number={number}
                whatsappChatMsg={whatsappChatMsg}
              />
            )}
          </Box>
        </Container>

        <ProjectGallery images={images} />
        {tabs?.length > 0 && (
          <IdealPlace
            method={paymentMethod}
            projectPath={buildingPath}
            codeProject={codeProject}
            pdfLink={pdfLink}
            tabs={tabs}
            terms={link}
            buildingLocation={buildingLocation}
            number={whatsapp}
            code={code}
            simulatorScript={simulatorScript}
            isSoldOut={isSoldOut}
            state={state}
            zohoDetail={zohoDetail}
          />
        )}

        {/* <InterestPoints /> */}

        {schedules && (
          <Section>
            <Box
              sx={{
                display: ['block', 'grid'],
                gridTemplateColumns: 'repeat(2, 1fr)',
              }}
            >
              <Box>
                <Box mb='medium'>
                  <Header>Horarios de atención</Header>
                  <Box px={[5, 0]}>
                    <Schedules schedules={schedules} latLon={latLon} />
                  </Box>
                </Box>
                {interestPoints?.length > 0 && (
                  <Box>
                    <Header>Puntos de interés</Header>
                    {interestPoints &&
                      interestPoints.map(point => (
                        <Box as='ul' sx={pointStyles}>
                          <Box as='li' sx={{ fontSize: 1, py: '3px' }}>
                            {point?.entity?.name}
                          </Box>
                        </Box>
                      ))}
                  </Box>
                )}
              </Box>

              <PropertyMap latLon={latLon} />
            </Box>
          </Section>
        )}

        {!schedules && (
          <Section>
            <PropertyMap latLon={latLon} />
          </Section>
        )}

        <Section
          title={<Header color='white'>De tu interés</Header>}
          variant='bluey'
        >
          <InterestCards image={cityImage} desc={cityDesc} location={location} />
        </Section>

        {projects?.length > 0 && <RelatedProjects projects={projects} />}
      </Box>

      <script src='https://suite-simulator-embedded.s3.us-east-2.amazonaws.com/script-simula.js' />
    </Layout>
  )
}

ProjectTemplate.propTypes = {
  data: PropTypes.oneOf({}).isRequired,
  path: PropTypes.string.isRequired,
}

export default ProjectTemplate

export const pageQuery = graphql`
  query project($id: String!) {
    drupal {
      entity: buildingById(id: $id) {
        id
        projectUrl: entityUrl {
          path
        }
        headerAds: ads(type: "header") {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        popUpAds: ads(type: "pop_up") {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        footerAds: ads(type: "footer") {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }

        ... on Drupal_BuildingProject {
          __typename
          code
          name
          bannerImage: fieldBannerImage {
            alt
            derivative(style: PROJECTBANNER) {
              url
            }
          }
          codeProject: code
          location: fieldLocation {
            entity {
              name
              parent {
                entity {
                  name
                  parent {
                    entity {
                      name
                    }
                  }
                }
              }
            }
          }
          canonFrom: fieldSalePriceFrom
          canonTo: fieldSalePriceTo
          areaFrom: fieldAreaFrom
          areaTo: fieldAreaTo
          paymentMethod: fieldPaymentMethod
          latLon: fieldLatLong {
            value
            geoType
            lat
            lon
            left
            top
            right
            bottom
            geohash
            latlon
          }
          typicalFloors: fieldTypicalFloors {
            alt
            url
          }
          name
          subtitle: fieldSubtitle
          description: fieldDescription {
            value
          }
          link: fieldLegalLink {
            url {
              path
            }
          }
          pdfLink: fieldPdfLink {
            url {
              path
            }
          }
          plansImages: fieldPlansImages {
            alt
            url
          }
          state: fieldState {
            entity {
              id: tid
              name
              ... on Drupal_TaxonomyTermProjectStatus {
                machineName: fieldMachineName
                variant: fieldVariant
              }
            }
          }
          interestPoints: fieldInterestPoints {
            entity {
              id: tid
              name
            }
          }
          isVis: fieldImmediateDelivery
          brand: fieldBrand {
            alt
            url
          }
          plansImages: fieldPlansImages {
            alt
            url
          }
          schedules: fieldHeadquartersSchedules {
            value
          }
          whatsapp: fieldWhatsapp
          cityTitle: fieldCityTitle
          sectorTitle: fieldSectorTitle
          cityImage: fieldCityImage {
            alt
            url
          }
          cityDesc: fieldCityDescription {
            value
          }
          videos: fieldVideos {
            entity {
              ... on Drupal_ParagraphFeaturedVideo {
                id
                url: fieldUrl
                title: fieldTitle
                is_highlighted: fieldIsHighlighted
                icon: fieldMediaIcon
              }
            }
          }
          images: fieldImages {
            alt
            derivative(style: IMAGENESDETALLE) {
              url
            }
          }
            interestedProject: fieldIdZoho
            owner: fieldIdUserZoho
            regional: fieldBranchOffices
          workProgressDesc: fieldWorkProgressDesc
          workProgress: fieldWorkProgress {
            entity {
              ... on Drupal_ParagraphProjectItem {
                id
                assets: fieldAssets {
                  entity {
                    bundle: entityBundle
                    ... on Drupal_MediaDocument {
                      document: fieldMediaDocument {
                        entity {
                          ... on Drupal_File {
                            url
                            filename
                          }
                        }
                      }
                    }
                    ... on Drupal_MediaRemoteVideo {
                      video: fieldMediaOembedVideo
                      thumbnail {
                        entity {
                          ... on Drupal_File {
                            url
                          }
                        }
                      }
                    }
                  }
                }
                date: fieldDate {
                  date
                }
                title: fieldItemTitle
              }
            }
          }
          commonAreas: fieldCommonAreas {
            entity {
              tid
              name
              ... on Drupal_TaxonomyTermCommonAreas {
                name
                icon: fieldSvgIcon {
                  entity {
                    url
                  }
                  iconName: alt
                }
              }
            }
          }
          tabs {
            name
            code
            areas {
              title
              property {
                id
                url: entityUrl {
                  path
                }
                ... on Drupal_BuildingProperty {
                  code
                  area: fieldArea
                  bedrooms: fieldBedrooms
                  bathrooms: fieldBathrooms
                  saleValue: saleValue
                  type: fieldPropertyType {
                    entity {
                      name
                    }
                  }
                  serviceType: fieldServiceType
                  smmlvNote: fieldSmmlvNote
                  plans: fieldPlansImages {
                    alt
                    url
                  }
                  location: fieldLocation {
                    entity {
                      name
                      parent {
                        entity {
                          name
                          parent {
                            entity {
                              name
                            }
                          }
                        }
                      }
                    }
                  }
                  separationQuota: fieldSeparationQuota
                  quotasNumber: fieldQuotasNumber
                  totalAmountOfQuotas: fieldTotalAmountOfQuotas
                  remainingValue: fieldRemainingValue
                  explanatoryNote: fieldExplanatoryNote
                }
              }
            }
          }
          related: relatedContent {
            results {
              entityId
              ... on Drupal_BuildingProject {
                id
                images: fieldImages {
                  alt
                  derivative(style: IMAGECARD) {
                    url
                  }
                }
                url: entityUrl {
                  path
                }
                code
                name: fieldProjectDescription
                path {
                  alias
                }
                serviceType: fieldServiceType
                propertyType: fieldPropertyType {
                  entity {
                    name
                  }
                }
                state: fieldState {
                  entity {
                    name
                  }
                }
                area: fieldAreaFrom
                canon: fieldSalePriceFrom

                location: fieldLocation {
                  entity {
                    name
                    parent {
                      entity {
                        name
                        parent {
                          entity {
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          simulatorScript: fieldSimulatorScript
        }
      }
    }
  }
`
