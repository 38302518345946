import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import Layout from 'layout/default/Layout'

import IntlTelInput from 'react-intl-tel-input'
import HabeasData from 'component-v2/HabeasData'

function ReferralLanding() {
  const { register, handleSubmit, reset } = useForm({})
  const [submitted, setSubmitted] = useState(false)
  const [phone, setPhone] = useState()
  const [refPhone, setRefPhone] = useState()

  const onChangePhone = (_, phoneNumber, country) => {
    const dialCode = country.dialCode
    const expression = `\\+${dialCode}`
    const regex = new RegExp(expression)
    const withoutDial = phoneNumber.replace(regex, '')
    const internationalNumber = `+${country.dialCode}` + `${withoutDial}`

    setPhone(internationalNumber)
  }

  const onChangeRefPhone = (_, phoneNumber, country) => {
    const dialCode = country.dialCode
    const expression = `\\+${dialCode}`
    const regex = new RegExp(expression)
    const withoutDial = phoneNumber.replace(regex, '')
    const internationalNumber = `+${country.dialCode}` + `${withoutDial}`

    setRefPhone(internationalNumber)
  }

  const onSubmit = (data) => {
    const hubspotInfo = {
      properties: [
        {
          property: 'nombre_referidor',
          value: data.referrer_name,
        },
        {
          property: 'apellido_referidor',
          value: data.referrer_last_name,
        },
        {
          property: 'correo_referidor',
          value: data.referrer_email,
        },
        {
          property: 'telefono_referidor',
          value: phone,
        },
        {
          property: 'pais_referidor',
          value: data.referrer_country,
        },
        {
          property: 'tipo_referidor',
          value: data.referrer_type,
        },
        {
          property: 'firstname',
          value: data.referred_name,
        },
        {
          property: 'lastname',
          value: data.referred_last_name,
        },
        {
          property: 'email',
          value: data.referred_email,
        },
        {
          property: 'phone',
          value: refPhone,
        },
        {
          property: 'proyecto_de_interes',
          value: data.referred_project,
        },
        {
          property: 'pais',
          value: data.referred_country,
        },
      ],
    }

    const saleUrl = `https://api.coninsa.co/api/v2/hubspot-create-contact/${data.referred_email}`

    return new Promise((resolve, reject) => {
      axios
        .post(saleUrl, hubspotInfo)
        .then(response => {
          reset()
          setSubmitted(true)
          resolve(response)
          window.open('https://www.coninsa.co/gracias')
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  return (
    <Layout>
      <Helmet>
        <title>Proyectos referidos | Coninsa</title>
        <meta name="description" content="Tus amigos y familiares merecen las mejores recomendaciones de inversión, cuéntales acerca de nuestros proyectos. Sé parte de nuestro plan de referidos y obtén grandes beneficios.*" />
        <link rel="canonical" href="https://www.coninsa.co/proyectos-referidos/" />
      </Helmet>
      <div class='banner pb-16 md:pb-0'>
        <div class='container banner-container'>
          <img src='/system-ui/images/banner.jpg' class='banner-image hidden md:block' />
          <div class='banner-content'>
            <h1 class='banner-title'>
              Tus amigos y familiares merecen las mejores recomendaciones de
              inversión,
              <span class='is-highlighted'>
                {' '}
                cuéntales acerca de nuestros proyectos.
              </span>
            </h1>
            <p class='banner-text'>
              Sé parte de nuestro plan de referidos y obtén grandes beneficios.*
            </p>
          </div>

          <img src='/system-ui/images/line-sun.png' class='line-sun' />
          <img src='/system-ui/images/line.png' class='line' />
        </div>
      </div>

      <div class='from-white-to-green'>
        <div class='container'>
          <div class='co-card -translate-y-16'>
            <h2 class='co-heading-2 is-primary mb-8'>
              Conoce el paso a paso
              <br />
              <span class='is-light'>para referir a tus amigos o familiares</span>
            </h2>

            <ol class='co-order-list'>
              <li>
                <img
                  class='right-arrow-image'
                  src='/system-ui/images/right-arrow.png'
                />
                <p>
                  <span class='font-semibold'>
                    {' '}
                    Cuéntales a tus amigos o familiares{' '}
                  </span>
                  sobre nuestros proyectos para que seleccionen el de su interés.
                </p>
              </li>

              <li>
                <img
                  class='right-arrow-image'
                  src='/system-ui/images/right-arrow.png'
                />
                <p>
                  <span class='font-semibold'>
                    Ingresa tus datos y los de la persona que refieres,{' '}
                  </span>
                  seleccionando el proyecto de interés.
                </p>
              </li>

              <li>
                <img
                  class='right-arrow-image'
                  src='/system-ui/images/right-arrow.png'
                />
                <p>
                  Verificaremos que tu referido
                  <span class='font-semibold'>
                    {' '}
                    no se encuentre activo en nuestros proyectos{' '}
                  </span>
                  y así pueda ser contactado por uno de nuestros consultores.
                </p>
              </li>

              <li>
                <p>
                  <span class='font-semibold'>
                    Si tu referido realiza la compra en nuestro proyecto,{' '}
                  </span>
                  uno de nuestros consultores se comunicarán contigo para confirmar
                  los beneficios.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>

      <div class='px-5 md:px-0'>
        {!submitted && (
          <div class='form' onSubmit={handleSubmit(onSubmit)}>
            <div class='container form-container'>
              <div class='form-header'>
                <i class='co-icon icon-team'></i>
                <h1 class='form-title'>
                  Diligencia el formulario{' '}
                  <span class='is-not-bold'>
                    refiere un amigo o familiar y gana beneficios
                  </span>
                </h1>
              </div>

              <form class='form-subject-matter md:w-3/4'>
                <div class='form-content'>
                  <div class='title'>
                    <div class='circle'>1</div>
                    <h3 class='information'>Tus datos</h3>
                  </div>
                  <div class='co-form'>
                    <div class='form-control'>
                      <input
                        {...register('referrer_name')}
                        type='text'
                        id='referrer_name'
                        placeholder='Nombres'
                        required
                      />
                    </div>
                    <div class='form-control'>
                      <input
                        {...register('referrer_last_name')}
                        type='text'
                        id='referrer_last_name'
                        placeholder='Apellidos'
                        required
                      />
                    </div>
                    <div class='form-control is-intl-tel'>
                      <IntlTelInput
                        {...register('phone')}
                        id='referrer_phone'
                        placeholder='Número de contacto'
                        defaultCountry={'co'}
                        onPhoneNumberChange={onChangePhone}
                        telInputProps={{ required: true }}
                      />
                    </div>
                    <div class='form-control'>
                      <input
                        {...register('referrer_email')}
                        type='email'
                        id='referrer_email'
                        placeholder='Email'
                        required
                      />
                    </div>
                    <div class='form-control'>
                      <select
                        {...register('referrer_country')}
                        class='text-area'
                        id='referrer_country'
                        required
                      >
                        <option value=''>País de residencia</option>
                        <option value='Aruba'>Aruba</option>
                        <option value='Australia'>Australia</option>
                        <option value='Colombia'>Colombia</option>
                        <option value='Espana'>España</option>
                        <option value='Estados Unidos'>Estados Unidos</option>
                        <option value='Mexico'>Mexico</option>
                        <option value='Republica Dominicana'>Republica Dominicana</option>
                        <option value='Otro'>Otro</option>
                      </select>
                    </div>
                    <div class='form-control'>
                      <select
                        {...register('referrer_type')}
                        class='text-area'
                        id='referrer_type'
                        required
                      >
                        <option value=''>¿Eres comprador?</option>
                        <option value='Referido Cliente digital'>Soy comprador</option>
                        <option value='Referido Amigo - pariente digital'>No soy comprador</option>
                        <option value='Referido empleado digital'>Soy empleado de Coninsa</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class='form-content'>
                  <div class='title'>
                    <div class='circle'>2</div>
                    <h3 class='information'>Datos de tu referido</h3>
                  </div>

                  <div class='co-form'>
                    <div class='form-control'>
                      <input
                        {...register('referred_name')}
                        type='text'
                        id='referred_name'
                        placeholder='Nombres'
                        required
                      />
                    </div>
                    <div class='form-control'>
                      <input
                        {...register('referred_last_name')}
                        type='text'
                        id='referred_last_name'
                        placeholder='Apellidos'
                        required
                      />
                    </div>
                    <div class='form-control is-intl-tel'>
                      <IntlTelInput
                        {...register('referred_phone')}
                        id='referred_phone'
                        placeholder='Número de contacto'
                        defaultCountry={'co'}
                        onPhoneNumberChange={onChangeRefPhone}
                        telInputProps={{ required: true }}
                        required
                      />
                    </div>
                    <div class='form-control'>
                      <input
                        {...register('referred_email')}
                        type='email'
                        id='referred_email'
                        placeholder='Email'
                        required
                      />
                    </div>
                    <div class='form-control'>
                      <select
                        class='text-area'
                        {...register('referred_project')}
                        id='referred_project'
                        required
                      >
                        <option value=''>Proyecto a referir</option>
                        <option value='Loira'>Loira</option>
                        <option value='Sendero Silvestre'>Sendero Silvestre</option>
                        <option value='La Riviere'>La Riviere</option>
                        <option value='Terravita'>Terravita</option>
                        <option value='Civita'>Civita</option>
                        <option value='Vida Park'>Vida Park</option>
                        <option value='Reserva 67'>Reserva 67</option>
                        <option value='Torem'>Torem</option>
                        <option value='Natal'>Natal</option>
                        <option value='Bosques De Monticello'>
                          Bosques De Monticello
                        </option>
                        <option value='Poblado Niquía'>Poblado Niquía</option>
                        <option value='Ciudadela Monteazul'>
                          Ciudadela Monteazul
                        </option>
                        <option value='Trend'>Trend</option>
                        <option value='Madeira'>Madeira</option>
                        <option value='Torres de las Palmas'>
                          Torres de las Palmas
                        </option>
                        <option value='Ferrara'>Ferrara</option>
                        <option value='Álamos del Caribe'>Álamos del Caribe</option>
                        <option value='Montiara'>Montiara</option>
                        <option value='Altos de Monticello'>
                          Altos de Monticello
                        </option>
                        <option value='Nuvoli 44'>Nuvoli 44</option>
                        <option value='Leven'>Leven</option>
                        <option value='Mocca'>Mocca</option>
                        <option value='Sue Natura'>Sue Natura</option>
                        <option value='Nuvoli 53'>Nuvoli 53</option>
                        <option value='Centrik Town'>Centrik Town</option>
                        <option value='Amantina'>Amantina</option>
                        <option value='Kaizen'>Kaizen</option>
                        <option value='Quality Center'>Quality Center</option>
                        <option value='Soho Bavaria'>Soho Bavaria</option>
                        <option value='Altos de Monticello'>
                          Altos de Monticello
                        </option>
                        <option value='Castilla Urbana'>Castilla Urbana</option>
                        <option value='Castelli'>Castelli</option>
                        <option value='Felisa Condominium'>
                          Felisa Condominium
                        </option>
                        <option value='Ponteverdi'>Ponteverdi</option>
                        <option value='Miravento'>Miravento</option>
                        <option value='Soho Bavaria'>Soho Bavaria</option>
                        <option value='Sue'>Sue</option>
                        <option value='Piemonti'>Piemonti</option>
                      </select>
                    </div>

                    <div class='form-control'>
                      <select
                        {...register('referred_country')}
                        class='text-area'
                        id='referred_country'
                        required
                      >
                        <option value=''>País de residencia</option>
                        <option value='Aruba'>Aruba</option>
                        <option value='Australia'>Australia</option>
                        <option value='Colombia'>Colombia</option>
                        <option value='Espana'>España</option>
                        <option value='Estados Unidos'>Estados Unidos</option>
                        <option value='Mexico'>Mexico</option>
                        <option value='Republica Dominicana'>Republica Dominicana</option>
                        <option value='Otro'>Otro</option>
                      </select>
                    </div>

                    <HabeasData />

                    <div class='form-control is-checkbox'>
                      <input type='checkbox' id='data-policy' required />
                      <label for='data-policy' class='data-policy'>
                        Autorizo el tratamiento y la{' '}
                        <a
                          class='co-link is-primary'
                          href='https://www.coninsa.co/politica-de-tratamiento-de-datos-personales-de-coninsa-ramon-h-sa'
                          target='_blank'
                        >
                          Política de datos personales
                        </a>
                      </label>
                    </div>

                    <div class='form-actions'>
                      <button class='co-button is-progress-default w-full'>
                        Enviar mi referido
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
        {submitted && (
          <p className='font-bold text-center mb-8'>
            Gracias por confiar en nosotros, pronto un asesor se comunicará con
            usted.
          </p>
        )}
      </div>

      <div class='from-gray-to-green'>
        <div class='container max-w-5xl'>
          <div class='co-card is-primary is-horizontal'>
            <div>
              <h3 class='co-heading-3 mb-2'>
                <span class='font-bold'>Conoce nuestros proyectos</span>
              </h3>
              <p class='md:text-lg text-base'>
                Encuentra la oferta de proyectos nuevos de vivienda que tenemos en
                <span class='font-bold'> Antioquia, Cundinamarca y Atlántico.</span>
              </p>
            </div>

            <div class='mb-5 text-center md:mb-0'>
              <a
                class='co-button is-tertiary'
                href='https://busquedas.coninsa.co/proyectos'
                target='_blank'
              >
                Ver proyectos de vivienda
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class='co-section is-secondary py-8'>
        <div class='container max-w-3xl flex flex-col items-center text-center'>
          <h2 class='co-heading-2 is-teal mb-6'>
            <span class='is-normal'>
              Si conoces a alguien que quiera comprar o invertir{' '}
            </span>
            desde el Exterior,
            <span class='is-normal'> también puedes referirlo.</span>
          </h2>

          <p class='text-center text-lg md:text-xl max-w-xl'>
            Contamos con <span class='font-bold'> un equipo exclusivo </span>
            para brindar asesoría personalizada logrando un
            <span class='boosting-container-is-bold'> proceso fácil y ágil.</span>
          </p>
        </div>
      </div>

      <div class='co-section is-teal py-6'>
        <div class='container text-center'>
          <a class='co-link text-base md:text-xl' href='https://www.coninsa.co/legal-referidos-proyectos'>
            * Conoce los términos y condiciones de esta promoción
          </a>
        </div>
      </div>
    </Layout>
  )
}

export default ReferralLanding
