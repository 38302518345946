import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import { layoutResolver } from "../layout";
import { ProsePage } from "@coninsa-site/faq/templates/prose-page";

function PageTemplate(props) {
  const { layout } = props?.pageContext;
  const content = props?.data?.content;

  const Layout = layoutResolver(layout);

  return (
    <Layout>
      <ProsePage {...content} />
    </Layout>
  );
}

PageTemplate.propTypes = {
  pageContext: PropTypes.shape({
    layout: PropTypes.shape({}),
  }).isRequired,
  data: PropTypes.shape({}).isRequired,
};

export default PageTemplate;

export const pageQuery = graphql`
  query yamlPage($id: String!) {
    content: contentYaml(id: { eq: $id }) {
      headline
      headlineIcon
      icon
      title
      body
      list {
        url
        label
      }
      breadcrumbs {
        url
        label
      }
    }
  }
`;
