import React, { useState } from 'react'
import { Box, Field, Input } from 'theme-ui'
import { useMutation } from '@apollo/client'
import { useController } from 'react-hook-form'

import File from './components/File'
import { FILE_UPLOAD } from './mutations'

function FileInput({ accept, name, control, required }) {
  const [mutation, { loading }] = useMutation(FILE_UPLOAD)
  const {
    field: { ref, value, onChange, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    // rules: { required: true },
    defaultValue: {},
  })

  const handleChange = e => {
    const { files } = e.target

    if (files.length) {
      const file = files[0]

      mutation({ variables: { file } })
        .then(response => {
          const { data } = response

          onChange({ targetId: data.entity.fid, ...data })
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  const onRemove = () => {
    onChange({})
  }

  return (
    <Box>
      {value?.entity ? (
        <File value={value?.entity} onRemove={onRemove} />
      ) : (
        <Input
          accept={accept}
          name={name}
          type='file'
          onChange={handleChange}
          disabled={loading}
          required={required}
        />
      )}
    </Box>
  )
}

FileInput.defaultProps = {
  required: false,
}

export default FileInput
