import React from 'react'
import { Box, Container, Link } from 'theme-ui'

import { Collapse, Header } from 'ui'
import Banner from 'ui/Banner'
import JobDetails from './JobDetails'
import JobOffersForm from './WorkWithUsForm'
import BannerImage from '../../images/banner-trabaja-nosotros-2022.jpeg'

function JobOffers() {
  const baseStyles = {
    height: '350px',
  }

  const titleStyles = {
    color: 'neutral',
    fontFamily: 'Oswald',
    fontSize: ['25px', '32px'],
    fontWeight: '500',
    mb: ['small', 'medium']
  }

  return (
    <Box>
      <Banner bannerImageUrl={BannerImage} sx={baseStyles} />

      <Box sx={{ mx:['medium', 0], fontSize: [1, 2] }}>
        <Container>
          <Header sx={{ my: 'medium' }}>Trabaje con nosotros</Header>
          <Box sx={{ display: 'flex', flexDirection: ['column', 'row'] }} mb='medium'>
            <Link sx={{ color: 'accent', mb: ['small', 0], mr: [0, 'medium'], ':hover': { textDecoration: 'underline' } }} href='/trabaje-con-nosotros'>Haz parte de nuestro equipo</Link>
            <Link sx={{ color: 'accent', fontWeight: 'bold' }} href='#'>Oportunidades laborales</Link>
          </Box>

          
          <Box mb='medium'>
            <strong>¡Seleccionamos el mejor talento para nuestra Compañía!. En Coninsa Ramón H</strong>, todos somos líderes y trabajamos juntos para desarrollar comportamientos que
            permitan la gestión de sí mismo, la  gestión de otros y gestión de resultados, siendo un líder  que proyecta confianza y credibilidad al asumir retos
            con responsabilidad y autonomía, buscamos personas con disposición genuina y constante en hacer parte de la construcción y ejecución de objetivos 
            compartidos que permitan crear y fortalecer vínculos de calidad, sentimientos de confianza y conversaciones constructivas que orienten hacia la 
            consolidación del propósito superior de Coninsa Ramón H. “Construir Bienestar” y que desde su rol, entregue lo mejor para que los clientes nos noten,
            nos prefieran y nos refieran. Sabemos que cada Cliente es importante, entendemos sus necesidades para entregarle experiencias memorables que generen
            valor cumpliendo sus expectativas.
          </Box>

          <Box sx={{ mb: ['medium', 'large'] }}>
            <strong>En esta sección conocerás las oportunidades laborales activas que tenemos a nivel nacional.</strong> Si quieres hacer parte de nuestro equipo, y cumples con el
            perfil requerido en alguna de las oportunidades laborales, te invitamos a postularte diligenciando el formulario, adjuntando tu hoja de vida y 
            certificaciones laborales que la validen.  
          </Box>

          <Box mb='large'>
            <Box sx={titleStyles}>Oportunidades laborales vigentes</Box>
            <Box>
              <Collapse variant='grayly'>
                <Collapse.Item title='Medellín' index={1}>
                  <JobDetails highlighted='PROFESIONAL EN REVISORÍA FISCAL: '>
                   Profesional en Contaduría Pública o profesiones afines, con especialización preferiblemente afín a la profesión (Revisoría Fiscal, Impuestos, Finanzas, Control Interno, NIIF). Conocimientos en normas internacionales de información financiera (NIIF), normas internacionales de aseguramiento (NIAS), impuestos, nómina, análisis financiero, elaboración y presentación de informes. Conocimientos en office (Excel Avanzado, Word, PowerPoint, Outlook) y sistemas contables. Experiencia general de 3 años en Auditoría, Control interno y de Gestión y específica de 1 año en Auditoría de estados financieros. 
                  </JobDetails>

                  <JobDetails highlighted='INSPECTOR SISOMA 1A: '>
                   Formación Técnica o Tecnología en Seguridad y Salud en el Trabajo y/o Ambiental con licencia vigente en salud ocupacional. 2 años de experiencia laboral en el área de SST Seguridad, Salud en el Trabajo, adquirida en empresas del sector real de gran tamaño. Quien será responsable de acompañar la implementación del sistema de seguridad y salud en el trabajo, revisión de la documentación del personal de obra, realización de inducción y apoyo la implementación del plan de formación SST y ambiental, entrega de elementos de protección personal, realización de permisos de trabajo para tareas de alto riesgo, apoyo a los programas de gestión sisoma e inspecciones de obra, apoyo a la gestión de las actividades derivadas de accidentes de trabajo. (Atención de lesionados, reporte e implementación del plan de acción de accidentes) Debe tener curso de coordinador de alturas. Curso virtual de 50 horas del SGSST, Apoyo al plan de emergencias de la obra.
                  </JobDetails>

                  <JobDetails highlighted='RESIDENTE SISOMA: '>
                   Profesional en Salud Ocupacional o profesionales en otra área con especialización en S&ST. Formación en Sistema de Gestión en Salud Ocupacional OHSAS 18001 y Gestión Ambiental ISO 14001:2015. Formación en actividades de alto riesgo y plan de emergencia. Con certificación en coordinación de trabajos en altura. Experiencia general de 4 años en S&ST adquirida en empresas del sector real de gran tamaño y 2 años de experiencia específica en Construcción Seguridad y Salud en el Trabajo. Quien será responsable de controlar los riesgos laborales que puedan afectar la salud de los colaboradores, daños en los materiales, equipos e instalaciones de la compañía y los impactos ambientales.
                  </JobDetails>

                  <JobDetails highlighted='INGENIERO ELÉCTRICO: '>
                    Profesional en Ingeniería de Sistemas o Informática con tarjeta profesional. Especialista en transformación digital, gerencia de proyectos, o fines. Experiencia general de 7 años y experiencia específica de 3 años en Gerencia de Proyectos y Transformación Digital. Quien será responsable de diseñar y administrar el mapa de ruta de la estrategia de transformación digital, relacionamiento con el ecosistema digital, vigilancia tecnológica y referenciación, definición, gestión y evolución de los proyectos y plataformas a cargo, adecuada gestión y negociación de los contratos con los diferentes proveedores, asegurar la adopción de las diferentes herramientas entregadas a la compañía, definir y acompañar la estrategia de inteligencia analítica.
                  </JobDetails>

                  <JobDetails highlighted='AUXILIAR DE RENOVACIONES: '>
                    Formación Técnica o Tecnológica en Administración o estudiante de Derecho o afines, con experiencia de 2 años, 1 año en finca raíz y conocimiento de las normas que regulan los arrendamientos. Estará a cargo de los procedimientos para la terminación, prorrogas y renovaciones de los contratos de inmuebles, cumpliendo los requisitos legales y contractuales, con el fin de garantizar los incrementos, la restitución de inmuebles y la oportuna respuesta a los requerimientos de los clientes.
                  </JobDetails>

                  <JobDetails highlighted='AUXILIAR DE INGENIERÍA CONCRETOS: '>
                    Formación Tecnológica en construcción o a fines con tarjeta profesional. Experiencia general de 1 año y experiencia específica de 6 meses en producción de concreto, laboratorio de concreto y procesos constructivos. Manejo de office y Excel intermedio. Conocimiento de materiales para la producción de concreto y ensayos de laboratorio. Quien tendrá a cargo la programación de abastecimiento de materiales, personal operativo y disponibilidad de equipos para la producción de concreto requerida por la obra, realizar seguimiento a la producción de concretos y morteros según diseño de mezcla, verificar su adecuado uso y direccionar volúmenes de material mediante salidas de almacén. Realizar control y seguimiento estadístico de producción de concreto y costos mediante informes semanales con su respectivo direccionamiento al control presupuestal, garantizar el control de inventario de materias primas e inspeccionar orden y aseo durante y después del proceso productivo.
                  </JobDetails>

                  <JobDetails highlighted='RESIDENTE DE OBRA III (Apartadó Antioquia): '>
                    Profesional en Ingeniería Civil, Arquitectura, Construcciones Civiles con tarjeta profesional. Con dominio de Excel, AutoCAD y Project. Experiencia general de 3 años y 1 año de experiencia específica como residente de obra en urbanismo y construcción de obras civiles de edificaciones, control de programación, manejo y control de presupuestos, elaboración de vales de obra y cálculo de cantidades de obra.
                  </JobDetails>

                </Collapse.Item>
              </Collapse>
            </Box>

            <Box>
              <Collapse variant='grayly'>
                <Collapse.Item title='Bogotá' index={2}>
                  <JobDetails highlighted='RESIDENTE DE OBRA II: '>
                   Profesional en Ingeniería Civil, Arquitectura, Arquitecto Constructor con tarjeta profesional. Conocimientos en sistema operativo office (Word, Excel intermedio), AutoCAD, manejo y control de presupuesto (APU) y programación de obra (Project). Experiencia general de 5 a 3 años y 2 años de experiencia específica en estructura y sistema industrializado. Quien será responsable de planear, verificar y controlar detalladamente la ejecución de las actividades técnico administrativas, de acuerdo con las normas de construcción, procedimientos administrativos y de aseguramiento.
                  </JobDetails>

                  <JobDetails highlighted='INSPECTOR SISOMA 1A: '>
                   Formación Técnica o Tecnología en Seguridad y Salud en el Trabajo y/o Ambiental con licencia vigente en salud ocupacional. 2 años de experiencia laboral en el área de SST Seguridad, Salud en el Trabajo, adquirida en empresas del sector real de gran tamaño. Quien será responsable de acompañar la implementación del sistema de seguridad y salud en el trabajo, revisión de la documentación del personal de obra, realización de inducción y apoyo la implementación del plan de formación SST y ambiental, entrega de elementos de protección personal, realización de permisos de trabajo para tareas de alto riesgo, apoyo a los programas de gestión sisoma e inspecciones de obra, apoyo a la gestión de las actividades derivadas de accidentes de trabajo. (Atención de lesionados, reporte e implementación del plan de acción de accidentes) Debe tener curso de coordinador de alturas. Curso virtual de 50 horas del SGSST, Apoyo al plan de emergencias de la obra.
                  </JobDetails>

                  <JobDetails highlighted='PROFESIONAL DE PROPUESTAS Y PRESUPUESTOS: '>
                   Profesional en Ingeniería Civil, Arquitectura, Arquitecto Constructor o profesiones afines con tarjeta profesional, especialista en gerencia de proyectos o de construcción. Experiencia general de 5 años y específica de 2 años (1 año en obra) en la elaboración de presupuestos de edificaciones (vivienda, comercial, industrial, institucional, salud) e infraestructura, y licitaciones públicas y privadas bajo cualquier modalidad de pago: Administración Delegada, Precios Unitarios, Suma Global. Manejo de SINCO ERP, REVIT y conocimiento en metodología BIM. Quien se encargará de gestionar las actividades para la elaboración de presupuestos y propuestas.
                  </JobDetails>

                  <JobDetails highlighted='INGENIERO ELÉCTRICO: '>
                   Profesional en Ingeniería Eléctrica o profesiones afines con tarjeta profesional. Con conocimientos en Normatividad aplicable al sector eléctrico (NTC 2050, RETIE, RETILAP, RITEL, Normas EPM), manejo y control de presupuesto (APU), manejo de Microsoft Office, AutoCAD. Experiencia general de 5 años y experiencia especifica de 3 años en la construcción de proyectos residenciales y/o comerciales. Quien será responsable de ejecutar y hacer seguimiento a los presupuestos, planos y avances de obra de la parte eléctrica, de acuerdo con la normatividad vigente y políticas de compañía, con el fin de garantizar el cumplimiento del presupuesto, calidad y tiempo de entrega a los proyectos.
                  </JobDetails>

                  <JobDetails highlighted='CONSULTOR CONSIGNADOR: '>
                   Formación técnica, tecnológica o estudiante profesional en el área comercial, ventas, mercadeo o afines, con 3 años de experiencia en el área comercial y 1 año de experiencia en el sector inmobiliario. Quien será responsable de buscar al propietario para que deje en consignación el inmueble con la compañía, garantizando una excelente asesoría y logrando la atención oportuna en los inmuebles disponibles para arriendo.
                  </JobDetails>

                  <JobDetails highlighted='CONSULTOR VIVIENDA: '>
                   Estudiante de carrera técnicas, tecnológicas o profesionales con 3 años de experiencia general y 1 año de experiencia específica en el área de comercial, en atención al cliente en el sector inmobiliario. Quien será responsable de la asesoría al cliente de los servicios y procesos de arrendamientos y generar la búsqueda del inquilino para el inmueble disponible, indispensable tener moto con documentos al día.
                  </JobDetails>

                  <JobDetails highlighted='CONSULTOR ABR: '>
                   Estudiante de carreras técnicas, tecnológicas o profesionales. Experiencia comercial de 2 años en atención a clientes del sector real, financiero o de servicios. Preferiblemente experiencia realizando trámites de arrendamientos. Con habilidades comerciales y administrativas. Quien se encargará de realizar seguimiento en campo a las consignaciones realizadas. Indispensable contar con moto.
                  </JobDetails>

                  <JobDetails highlighted='OPERADOR SUPERNUMERARIO: '>
                   Bachiller, con experiencia especifica de mínimo 2 años como conductor Mixer, transportar y descargar la mezcla en la obra, según programación y de acuerdo con los parámetros de calidad y seguridad establecidos.
                  </JobDetails>

                  <JobDetails highlighted='PROFESIONAL DE CALIDAD: '>
                   Profesional en Ingeniería Civil o afines, Administración, áreas afines con tarjeta profesional. Experiencia general de 3 años y experiencia específica de 1 año en calidad en procesos del sector de la construcción e inmobiliario y en procesos de auditoría y sistemas de gestión. Quien será responsable de asegurar las actividades de aseguramiento de calidad, implementar y mantener el sistema de gestión de la calidad, realizar la verificación del cumplimiento de estándares y compromisos de los sistemas de gestión, medir, controlar y comunicar los resultados de los indicadores del sistema de gestión de la calidad y entregar los resultados de las auditorías a los otros sistemas de gestión.
                  </JobDetails>

                  <JobDetails highlighted='ANALISTA PLANEACION FINANCIERA: '>
                   Profesional en Ingeniería Financiera, Administración Financiera, Economía con tarjeta profesional o profesiones afines. Conocimiento en Office (Bueno), Excel avanzado, Ingles: Medio - alto. Experiencia específica de 1 año Apoyando procesos financieros corporativos. Quien será responsable de consolidar, clarificar, analizar y presentar la información financiera de acuerdo a las políticas de la compañía, con el fin de garantizar información confiable, oportuna y de calidad para la toma de decisiones.
                  </JobDetails>

                  <JobDetails highlighted='MAESTRO: '>
                   Formación técnica en construcción con tarjeta profesional COPNIA. Con conocimiento en obras con estructura en sistema industrializado, redes y urbanismo, excavación, armado y vaciado pilas, conocimientos en interpretación de planos, procesos constructivos, conocimientos topográficos y manejo de materiales. Experiencia general de 15 años y experiencia específica de 10 años en obras con estructura en sistema industrializado, redes y urbanismo.
                  </JobDetails>

                  <JobDetails highlighted='MAESTRO ELECTRICISTA: '>
                   Estudiante de últimos semestres o Profesional en Ingeniería Industrial, o de procesos con tarjeta profesional. Manejo de Word, PowerPoint y Excel intermedio. Preferiblemente conocimientos en Power BI. Experiencia de 6 meses en manejo de análisis, métodos y mediciones de tiempos. Será responsable de analizar la información de los procesos constructivos, identificación, medición, control y reducción de los desperdicios generados por la actividad constructiva bajo la metodología Kaizen, Lean Six sigma o mejora rápida, según sea el caso y realizar visitas a los diferentes proyectos de la compañía con el fin de con el fin de identificar oportunidades de mejora en los procesos a implementar, analizar los datos obtenidos del proceso, acompañar en la Implementación de las acciones para mejorar los procesos, realizar el seguimiento, divulgar las mejoras y realizar seguimiento a las réplicas de las mejoras.
                  </JobDetails>

                  <JobDetails highlighted='AYUDANTE ENTENDIDO ELECTRICISTA: '>
                   Bachiller, experiencia mínima de 1 año en el área de construcción en actividades como: Apoyo en labores de instalación, adecuación y mantenimiento de redes eléctricas en edificaciones. Con certificado de trabajo en alturas.
                  </JobDetails>

                  <JobDetails highlighted='PROFESIONAL DE PROPUESTAS Y PRESUPUESTOS: '>
                   Profesional en Ingeniería Civil, Arquitectura, Arquitecto Constructor o profesiones afines con tarjeta profesional, especialista en gerencia de proyectos o de construcción. Experiencia general de 5 años y específica de 2 años (1 año en obra) en la elaboración de presupuestos de edificaciones (vivienda, comercial, industrial, institucional, salud) e infraestructura, y licitaciones públicas y privadas bajo cualquier modalidad de pago: Administración Delegada, Precios Unitarios, Suma Global. Manejo de SINCO ERP, REVIT y conocimiento en metodología BIM. Quien se encargará de gestionar las actividades para la elaboración de presupuestos y propuestas.Profesional en Ingeniería Civil, Arquitectura, Arquitecto Constructor o profesiones afines con tarjeta profesional, especialista en gerencia de proyectos o de construcción. Experiencia general de 5 años y específica de 2 años (1 año en obra) en la elaboración de presupuestos de edificaciones (vivienda, comercial, industrial, institucional, salud) e infraestructura, y licitaciones públicas y privadas bajo cualquier modalidad de pago: Administración Delegada, Precios Unitarios, Suma Global. Manejo de SINCO ERP, REVIT y conocimiento en metodología BIM. Quien se encargará de gestionar las actividades para la elaboración de presupuestos y propuestas.
                  </JobDetails>

                  <JobDetails highlighted='DIRECTOR DE OBRA: '>
                   Profesional en Ingeniería Civil, Arquitectura y/o Construcciones Civiles, con tarjeta profesional, preferiblemente con Especialización en Gestión de Proyectos o Gestión de Procesos Constructivos. Con conocimiento en control de costos y presupuesto, manejo de programación de obra, manejo de las herramientas ofimáticas y Excel intermedio. Conocimiento y aplicación en los sistemas de calidad ISO 90001, OHSAS 18000, ISO 14001, seguridad industrial y ambiental. Conocimiento en las normas técnicas vigentes NSR, RETIE, RETILAB, RITEL entre otras. Quien será responsable de planear y verificar la ejecución del proyecto, entregar y liquidar las obras a cargo y liderar la aplicación de la gestión contractual. Experiencia general de 10 años y experiencia específica de 5 años como Director de obra en proyectos de vivienda de rango medio y alto administrando contratos por precio unitario, administración delegada y/o precio global fijo.
                  </JobDetails>

                  <JobDetails highlighted='RESIDENTE DE ESTRUCTURA: '>
                   Profesional en Ingeniería Civil, Arquitectura, Construcciones Civiles, con tarjeta profesional, dominio de Excel. Experiencia general de 5 años y 3 años de experiencia específica en estructuras y cimentaciones profundas. Quien será responsable de planear, verificar y controlar detalladamente la ejecución de las actividades técnicas del proyecto.
                  </JobDetails>

                  <JobDetails highlighted='ANALISTA DE INNOVACIÓN: '>
                   Profesional en Administración de Empresas, Mercadeo, Economía, Negocios Internacionales, Ingeniería industrial, Ingeniería diseño de producto o afines. Cursos o énfasis en Innovación/ metodologías ágiles/ indicadores. Conocimientos en Investigación y lectura de mercados, Gestión de portafolio de actividades de innovación, metodologías ágiles, habilidad de comprensión lectora en inglés. Experiencia general de 2 años y experiencia específica de 1 año en gestión del cambio, mercadeo, sistema de gestión de mejora, sistemas de innovación. Quien será responsable de ejecutar el plan de trabajo de la capacidad de innovación, proponer y gestionar las parillas de comunicaciones del área, apoyar el levantamiento y recolección de información para los diferentes proyectos, movilizar los equipos internos hacia la gestión de innovación, impulsar la identificación de soluciones innovadoras en la compañía, acompañar los proyectos de innovación desde la gestión logística y recolectar la información para hacer seguimiento a los indicadores.
                  </JobDetails>

                  <JobDetails highlighted='AUXILIAR DE CARTERA: '>
                   Técnico Administrativo, contable y financiero o afines. Con tarjeta Profesional. 2 años atención al cliente, gestión de cobranza, conciliaciones bancarias, experiencia en empresas de gran tamaño. Verificar la facturación, la cobranza, el recaudo y la conciliación de estos clientes, para optimizar el recaudo oportuno y efectivo. Generar los distintos reportes de saldos, controlar y manejar los contratos reasegurados; mantener actualizada los datos de la cartera corriente, pre- jurídico y jurídico y mantener informados a los propietarios y al coordinador de cartera sobre la gestión de cobro, el recaudo y la conciliación de los pagos.
                  </JobDetails>

                  <JobDetails highlighted='AUXILIAR DE IMPUESTOS: '>
                   Tecnólogo, estudiante de últimos semestres de contaduría o Contador Público recién titulado con tarjeta profesional, con conocimientos y experiencia en la nueva normatividad contable, procesos contables y financieros, Conocimientos tributarios de sociedades, Información exógena municipal, Información exógena DIAN, Impuestos municipales en general e Impuestos Nacionales y manejo de Excel intermedio. Estará encargado de conciliar, registrar y preparar información contable tributaria para atender las necesidades de los clientes internos, externos y entidades gubernamentales, teniendo en cuenta la regulación contable, tributaria y financiera de los diferentes tipos de negocios de la Compañía, garantizando información confiable, oportuna y de calidad para la toma de decisiones, evitando que la compañía incurra en sanciones por inoportunidad o inexactitud, afectando el flujo de caja.
                  </JobDetails>

                  <JobDetails highlighted='OFICIAL ELECTRICISTA. (APARTADÓ ANTIOQUIA): '>
                   Preferiblemente bachiller, con experiencia en instalación de redes eléctricas en edificaciones, interpretación de planos, conocimientos básicos de aritmética y operaciones matemáticas básicas. Certificado vigente de trabajo en alturas y Certificado CONTE.
                  </JobDetails>

                  <JobDetails highlighted='OFICIAL 1A: '>
                   Preferiblemente bachiller, con experiencia especifica de mínimo 2 años en ejecución y apoyo en labores de, instalaciones hidráulicas, redes hidrosanitarias, plomería. Acometidas y todas las actividades relacionadas a la ejecución del cargo.
                  </JobDetails>

                  <JobDetails highlighted='ALMACENISTA: '>
                   Bachiller, con conocimientos en manejo de aplicaciones para la administración de obra, almacén, patio de materiales. Direccionamiento de ítems e insumos del presupuesto, inventarios y Kardex. Experiencia general 10 años y 5 años de experiencia específica en facturación, movimientos de almacén, archivo, control y manejo de personal, pedidos y programación de materiales, correcta disposición de materiales dentro del almacén como en patio. Quien será responsable de atender los requerimientos de los diferentes frentes de la obra y dependencias administrativas del proyecto, en cuanto al suministro de materiales, repuestos y equipos, recibiéndolos, clasificándolos, codificándolos, despachándolos e inventariándolos.
                  </JobDetails>

                </Collapse.Item>
              </Collapse>
            </Box>

            <Box mb='large'>
            <Collapse variant='grayly'>
              <Collapse.Item title='Barranquilla' index={3}>

                <JobDetails highlighted='DIRECTOR DE CONSTRUCCIONES: '>
                 Profesional en Ingeniería Civil con tarjeta profesional con especialización en Construcciones o a fines. Experiencia general de 15 años y 10 años de experiencia específica como Director de Construcción liderando la ejecución de por lo menos de 4 proyectos en simultaneo; En proyectos a terceros, por administración delegada o precios unitarios y proyectos propios de vivienda. Con conocimiento en la elaboración y/o control de presupuestos y programación, tramitación de obras y normatividad de planeación, conocimientos en normatividad jurídica, laboral y contractual, verificación de diseños arquitectónicos y técnicos, tramitación de licencias requeridos para la ejecución de los proyectos. Dominio en herramientas como SINCO o similar, Project intermedio, entre otras.
                </JobDetails>

                <JobDetails highlighted='AYUDANTE ENTENDIDO: '>
                 Bachiller, experiencia mínima de 1 año en el área de construcción, experiencia en acabados y mampostería. Con certificado vigente de trabajo en alturas.
                </JobDetails>

                <JobDetails highlighted='CONSULTOR ABR: '>
                 Estudiante de carreras técnicas, tecnológicas o profesionales. Experiencia comercial de 2 años en atención a clientes del sector real, financiero o de servicios. Preferiblemente experiencia realizando trámites de arrendamientos. Con habilidades comerciales y administrativas. Quien se encargará de realizar seguimiento en campo a las consignaciones realizadas. Indispensable contar con moto.
                </JobDetails>

                <JobDetails highlighted='MAESTRO ELECTRICISTA 1B: '>
                 Bachiller, técnico electricista o con estudios relacionados. Con conocimientos en interpretación de planos, procesos constructivos, manejo de materiales y de circuitos eléctricos de baja y media tensión, debe contar con matrícula profesional y cumplimiento técnico RETIE (Conte) (Preferiblemente). Con experiencia mínima de 5 años desempeñando el cargo. Quien se encargará de supervisar y controlar la ejecución de las diferentes actividades programadas en obra, de acuerdo a las políticas de la compañía y la normatividad vigente de Colombia, con el fin de garantizar la adecuada realización de las misma.
                </JobDetails>

              </Collapse.Item>
            </Collapse>
            </Box>

            <Box sx={{ fontWeight: 'heading' }}>
              Si estás interesado en alguno de estos cargos, usa el siguiente formulario para registrar tu hoja de vida. Recuerda indicar a qué
              cargo estás aplicando y adjuntar los certificados laborales que especifiquen la experiencia.
            </Box>
          </Box>
          
          <Box>
            <JobOffersForm />
          </Box>
          
        </Container>
      </Box>
    </Box>
  )
}

export default JobOffers
