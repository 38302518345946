import React from "react";
import Splider from "component-v2/Splider";
import { SplideSlide } from "@splidejs/react-splide";
import { useStaticQuery, graphql } from "gatsby";

import CountCard from "./components/CountCard";

import AchievementCard from "./components/AchievementCard";
import WorkCard from "./components/WorkCard";
import ContactSection from "./components/ContactSection";
import SummaryProjects from "./components/SummaryProjects";
import { Helmet } from "react-helmet";

import videoBanner from "../assets/images/video-banner.mp4";
import bgGreen from "../assets/images/bg-green.jpg";
import bgBlue from "../assets/images/bg-blue.jpg";
import sustainabilityUrl from "../assets/images/sustainability-image.jpg";

import brandCompanies from "../data/companies.js";

import "../../leasing-property/style.css";

function ArchitecturePage() {
  const data = useStaticQuery(graphql`
    query OurMainProjects {
      drupal {
        ourProjects: nodeQuery(
          filter: {
            conditions: [
              { operator: EQUAL, field: "status", value: ["1"] }
              { operator: EQUAL, field: "type", value: ["our_project"] }
              {
                operator: IN
                field: "nid"
                value: [
                  "855"
                  "769"
                  "794"
                  "764"
                  "765"
                  "755"
                  "763"
                  "914"
                  "761"
                ]
              }
            ]
          }
        ) {
          entities {
            id: entityId
            url: entityUrl {
              path
            }

            ... on Drupal_NodeOurProject {
              title
              type: fieldType {
                entity {
                  ... on Drupal_TaxonomyTermOurProjectTypes {
                    __typename
                    tid
                    name
                  }
                }
              }
              images: fieldGallery {
                entity {
                  ...MediaImageNoGatsby
                }
              }
              body {
                value
              }
              area: fieldBuiltArea
              consortium: fieldConsortium
              weight: fieldWeight
              location: fieldLocation
              owner: fieldOwner
              state: fieldProgress
              year: fieldDesignYear
            }
          }
        }

        summaryTerms: countOurProjectsByTermGraphqlView {
          results {
            id
            name
            count
          }
        }
      }
    }
  `);

  const slideSettings = {
    autoplay: true,
    perMove: 1,
    perPage: 5,
    rewind: true,
    padding: { left: 0, right: "12rem" },
    breakpoints: {
      1024: {
        perPage: 4,
        padding: { left: 0, right: "8rem" },
      },
      768: {
        perPage: 4,
        padding: { left: 0, right: "4rem" },
      },
      640: {
        perPage: 2,
        padding: { left: 0, right: "4rem" },
      },
    },
  };

  return (
    <div>
      <Helmet>
        <title>Diseño Arquitectonico | Coninsa</title>
        <meta
          name="description"
          content="Contempla desde el diseño urbanístico hasta el arquitectónico y de interiorismo, en los que llevamos más de cinco décadas trabajando en todo el país con soluciones para diferentes sectores."
        />
        <link rel="canonical" href="https://www.coninsa.co/arquitectura/" />
      </Helmet>
      <video width="100%" autoplay="" muted loop>
        <source src={videoBanner} type="video/mp4" />
      </video>
      <div className="bg-coninsa-gray-300 py-10 md:py-14">
        <div className="block md:container md:flex justify-between gap-4 px-5 md:px-0">
          <CountCard
            seconds="460"
            highlighted="Proyectos diseñados"
            text="a nivel nacional"
            time={1}
            start={100}
            limit={460}
          />

          <CountCard
            seconds="20"
            measure="mm"
            highlighted="De metros cuadrados"
            text="diseñados"
            time={60}
            start={5}
            limit={20}
          />

          <CountCard
            seconds="9"
            highlighted="Sectores productivos"
            text="del país"
            time={70}
            start={1}
            limit={9}
          />

          <CountCard
            seconds="50"
            highlighted="Años de experiencia"
            text="en diseño"
            time={30}
            start={15}
            limit={50}
          />
        </div>
      </div>
      <div className="mb-8 pt-10 md:pt-16" id="experiencias">
        <div className="px-5 md:px-0 container">
          <h2 className="font-display leading-8 md:leading-[44px] mb-4 md:mb-6 text-3xl md:text-[44px] text-coninsa-green-500 uppercase">
            Nuestra
            <span className="block font-semibold text-coninsa-blue-900">
              experiencia
            </span>
          </h2>
          <p>
            Contempla desde el diseño urbanístico hasta el arquitectónico y de
            interiorismo, en los que <br /> llevamos más de cinco décadas
            trabajando en todo el país con soluciones para diferentes sectores.
          </p>
        </div>
      </div>
      <div
        className="md:relative md:bg-coninsa-green-300 md:before:content md:before:block md:before:h-56 md:before:bg-white
          before:absolute before:top-0 before:w-full before:z-0"
        style={{ backgroundImage: `url(${bgGreen})` }}
      >
        <div className="md:absolute md:bottom-0 md:w-full md:z-0 content block md:bg-coninsa-gray-300 md:h-32" />
        <div className="container inline-block md:flex pb-10">
          <SummaryProjects
            data={data?.drupal}
            baseCategoryUrl="/arquitectura/proyectos"
          />
        </div>

        <a
          href="/arquitectura/nuestra-experiencia"
          target="_blank"
          className="block hero-compact md:!h-[356px] z-10 container md:rounded-2xl overflow-hidden relative"
          style={{ backgroundImage: `url(${sustainabilityUrl})` }}
        >
          <div className="hero-compact__container !bg-coninsa-green-500 md:!bg-transparent md:container z-10">
            {/* <img className='hero-compact__image' src={sustainabilityImage} /> */}
            <div className="hero-compact__content !px-0 md:!px-8">
              <span className="font-heading font-[300] text-white text-xl md:text-2xl">
                Conoce
              </span>
              <h1 className="hero-compact__title font-heading leading-9">
                <span className="!text-2xl md:!text-4xl">
                  cómo aplicamos la
                </span>
                <span className="!text-coninsa-green-300">sostenibilidad</span>
                <span className="!text-2xl md:!text-4xl">
                  en nuestros proyectos
                </span>
              </h1>
              <span className="font-body text-end text-white text-xl md:text-[26px] font-medium">
                y las certificaciones obtenidas
              </span>
              <div className="text-center mt-5 md:mt-6">
                <a
                  href="/arquitectura/nuestra-experiencia"
                  className="bg-transparent text-white text-sm font-semibold border border-white rounded-[21px]
                      py-[10px] px-3 md:px-6 hover:bg-white hover:text-coninsa-blue-900"
                >
                  Conoce más información
                </a>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div
        className="md:relative md:before:absolute md:before:bottom-0 md:before:w-full md:before:z-0 md:before:content md:before:block
         md:before:h-24 bg-coninsa-gray-300 pt-10 pb-5 md:py-16"
      >
        <div className="px-5 md:px-0 container">
          <div className="z-10">
            <Splider slot="content" options={slideSettings}>
              {brandCompanies.map((brand) => (
                <SplideSlide>
                  <div className="flex gap-4 mb-8">
                    <div className="rounded-lg px-2 py-3 shadow-[0px_3px_6px_#00000029]">
                      <img src={brand.icon} />
                    </div>
                  </div>
                </SplideSlide>
              ))}
            </Splider>
          </div>
          <div className="block md:flex gap-4">
            {/* <div className='pt-5'>
              <h2 className='font-display leading-8 mb-4 text-3xl md:leading-10 md:mb-6 md:text-[44px] text-coninsa-green-500 uppercase'>
                Lo que dicen
                <span className='block font-bold text-coninsa-blue-900'>
                  nuestros clientes
                </span>
              </h2>

              <p className='mb-5'>
                Conoce sobre lo que dicen nuestros clientes de los proyectos en los
                que hemos trabajado en conjunto.
              </p>

              <a
                href='https://wa.link/1hfzce'
                target='_blank'
                className='inline-block bg-coninsa-green-300 font-bold mb-4 px-6 py-3 rounded-lg text-white text-sm uppercase md:mb-0 hover:bg-coninsa-green-500'
              >
                Quiero una asesoría
              </a>
            </div> */}

            {/* <div className='z-10'>
              <UserCard />
            </div> */}
          </div>
        </div>
      </div>
      <div
        className="bg-coninsa-blue-900 py-10 md:py-16"
        style={{ backgroundImage: `url(${bgBlue})` }}
      >
        <div
          className="container inline-block md:grid grid-cols-[30%_70%] gap-4 items-center px-5 md:px-0"
          id="premios-y-reconocimientos"
        >
          <div>
            <h2 className="font-display leading-8 mb-4 text-3xl md:leading-10 md:mb-6 md:text-[44px] text-white uppercase">
              <span className="block font-bold">Logros</span>y reconocimientos
            </h2>
            <p className="text-white mb-5 md:mb-0">
              Conoce los logros y reconocimientos que hemos obtenido a lo largo
              de estos más de 50 años.
            </p>
          </div>
          <AchievementCard />
        </div>
      </div>
      <div className="py-10 md:py-14">
        <div className="container px-5 md:px-0">
          <div className="mb-8">
            <h2 className="font-display leading-8 mb-4 text-3xl md:leading-10 md:mb-6 md:text-[44px] text-coninsa-green-500 uppercase">
              <span className="block font-bold text-coninsa-blue-900">
                Conoce nuestro blog
              </span>
              arquitectura y grandes obras
            </h2>

            <button className="bg-coninsa-green-300 font-bold px-6 py-3 rounded-lg text-white text-sm uppercase hover:bg-coninsa-green-500">
              Todos los artículos
            </button>
          </div>

          <WorkCard />
        </div>
      </div>

      <ContactSection />
    </div>
  );
}

export default ArchitecturePage;
