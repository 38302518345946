import React from 'react'
import PropTypes from 'prop-types'

export default function RateCard({ title, rate, desc, children }) {
  return (
    <div className='mb-5 md:mb-0'>
      <div className='rounded-t-lg px-5 pb-2 pt-4 shadow-[0px_3px_6px_#00000029] text-center'>
        <h4 className='font-bold mb-3 text-xl'>{title}</h4>
        <h3 className='font-bold text-[34px] text-coninsa-green-300 leading-10'>
          {rate}
        </h3>
        <p className='font-medium leading-4 text-sm'>{desc}</p>
      </div>
      <div className='rounded-b-lg bg-coninsa-green-500 py-2 shadow-[0px_3px_6px_#00000029]'>
        {children}
      </div>
    </div>
  )
}

RateCard.propTypes = {
  title: PropTypes.string.isRequired,
  rate: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
}
