import React from "react";

import Section from "@coninsa-s2/section";
import Banner from "@coninsa-s2/banner";
import Container from "@coninsa-s2/container";
import Heading from "@coninsa-s2/heading";
import DialogBox from "@coninsa-s2/dialog-box";
import IconCard from "@coninsa-s2/icon-card";
import Shape from "@coninsa-s2/shape";
import Button from "@coninsa-s2/button";

import referralBanner from "./images/referral-banner.jpg";
import referralBannerMobile from "./images/referral-banner-mobile.png";

import data from "./data";

function Referral() {
  return (
    <>
      <Banner imageUrl={referralBanner} imageUrlMobile={referralBannerMobile}>
        <Container>
          <div className="md:w-[470px]">
            <h2 className="text-center font-heading text-6xl text-white md:text-[100px]">
              <span className="block font-handwritting text-5xl leading-6 text-co-green md:text-[90px] md:leading-[100px]">
                Amigos
              </span>
              Coninsa
            </h2>
            <DialogBox tint="teal" className="">
              <span className="text-lg font-normal leading-6 text-white md:text-[28px]">
                Conoce más información sobre nuestro programa de referidos.
              </span>
            </DialogBox>
          </div>
        </Container>
      </Banner>
      <p className="mb-7 bg-[#EAEAEA] px-5 py-8 text-center md:px-44 md:py-12 md:text-xl">
        Nuestro programa Amigos Coninsa es una actividad que otorgará a quienes
        refieran personas para que adquieran un inmueble en los proyectos nuevos
        o necesiten un inmueble en arriendo,
        <span className="font-bold"> un beneficio de parte de Coninsa.</span>
      </p>

      <Section>
        <Container>
          <div className="flex flex-col justify-center gap-8 md:flex-row md:gap-4">
            {data.map((item) => (
              <div key={item.id} className="px-5 md:w-[396px] md:px-0">
                <IconCard>
                  <Shape variant="circle" tint="lime">
                    <img src={item.icon} alt="Icon" />
                  </Shape>
                  <div className="mb-4">
                    <p className="md:!text-lg">{item.text}</p>
                    <Heading
                      size="lg"
                      tint="teal"
                      className="!font-extrabold leading-5"
                    >
                      {item.highlighted}
                    </Heading>
                  </div>
                  <Button href={item.url} target="_blank">
                    Referir a un amigo o familiar
                  </Button>
                </IconCard>
              </div>
            ))}
          </div>
        </Container>
      </Section>
    </>
  );
}

export default Referral;
