import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../../layout/default/Layout";
import ReferProjects from "@coninsa-site/refer-projects"

function ReferrerProjects() {
  return (
    <Layout>
      <Helmet>
        <title>Referidos Proyectos</title>
        <link rel="canonical" href="https://www.coninsa.co/referidos/proyectos" />
      </Helmet>

      <ReferProjects />
    </Layout>
  );
}

export default ReferrerProjects;