import React from 'react'
import PropTypes from 'prop-types'

import { Box } from 'theme-ui'

const SectionTitle = props => {
  const baseStyles = {
    color: 'neutral',
    fontSize: ['22px', '30px'],
    fontWeight: '300',
  }

  return <Box as='span' __css={baseStyles} {...props} />
}

SectionTitle.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SectionTitle
