import React from 'react'
import PropTypes from 'prop-types'

import { Box } from 'theme-ui'

const HeroContent = ({ children, ...props }) => {
  const baseStyles = {
    bg: 'secondary',
    color: 'white',
    fontSize: ['14px', '16px'],
    p: 'medium',
  }

  return (
    <Box __css={baseStyles} {...props}>
      {children}
    </Box>
  )
}

HeroContent.propTypes = {
  children: PropTypes.node.isRequired,
}

export default HeroContent
