import React from 'react'
import Layout from '../../layout/default/Layout'
import IndexPage from '../../modules/leasing-property'

function LeasingPropertyPage() {
  return (
    <Layout>
      <IndexPage />
    </Layout>
  )
}

export default LeasingPropertyPage
