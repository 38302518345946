import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";

import { CHECK_ADVERTISER_USER_CODE } from "../graphql/mutations";

export default function Auth1({ onSuccess }) {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  const [mutation] = useMutation(CHECK_ADVERTISER_USER_CODE);

  const onSubmit = async (data) => {
    const code =
      data["code_1"] +
      data["code_2"] +
      data["code_3"] +
      data["code_4"] +
      data["code_5"] +
      data["code_6"];
    const phone = window.localStorage.getItem("advertiser_phone");
    const response = await mutation({ variables: { phone, code } });
    const status = response?.data?.checkAdvertiserUserCode?.status;
    const type = response?.data?.checkAdvertiserUserCode?.type;
    const message = response?.data?.checkAdvertiserUserCode?.message;

    if (status === "fail") {
      setError("code_1", { message, type });
    } else {
      onSuccess();
    }
  };

  return (
    <div>
      <div className="co-card short-form-container">
        <h4 className="co-heading-4">Te enviamos un código</h4>
        <p>
          Ingresa el código de 6 dígitos que hemos enviado a tu cuenta de
          WhatsApp.
        </p>
        <form className="co-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-control is-required">
            <div className="line-up">
              <input
                {...register("code_1")}
                type="text"
                maxLength="1"
                required
              />
              <input
                {...register("code_2")}
                type="text"
                maxLength="1"
                required
              />
              <input
                {...register("code_3")}
                type="text"
                maxLength="1"
                required
              />
              <input
                {...register("code_4")}
                type="text"
                maxLength="1"
                required
              />
              <input
                {...register("code_5")}
                type="text"
                maxLength="1"
                required
              />
              <input
                {...register("code_6")}
                type="text"
                maxLength="1"
                required
              />
            </div>

            {errors?.code_1?.type === "WRONG_CODE" && (
              <p className="form-error">Su código es inválido. </p>
            )}
          </div>

          <button className="co-button">Validar</button>
        </form>
      </div>
      <div className="co-card footnote">
        {true ? (
          <a>¿Aún no has recibido el código?</a>
        ) : (
          <>
            <div class="loading-animation"></div>
            <p>Validando...</p>
          </>
        )}
      </div>
    </div>
  );
}
