import { graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { Box, Container, Image } from 'theme-ui';
import moment from 'moment';
import 'moment/locale/es';

import { Tabs, Icon, Divider } from 'components';
import Layout from '../layout/default/Layout';
import ProjectInfo from '../modules/search-building/components/ProjectInfo';
import ProjectTerms from '../modules/search-building/components/ProjectTerms';
import Timeline from '../modules/search-building/components/Timeline';
import { formatPrice } from '../modules/search-building/utils';
import FloatingWhatsappBtn from '../modules/home/components/FloatingWhatsappBtn';
import BuildingMobileFloatingBar from '../modules/search-building/components/BuildingMobileFloatingBar';

moment.locale('es');

const normalizeMediaAsset = asset => {
  const nAsset = {};

  // eslint-disable-next-line default-case
  switch (asset.entity.bundle) {
    case 'remote_video':
      nAsset.video = asset?.entity?.video;
      nAsset.thumbnail = asset?.entity?.thumbnail?.entity?.url;
      break;
    case 'document':
      nAsset.document = asset?.entity?.document?.entity?.url;
      nAsset.name = asset?.entity?.document?.entity?.filename;
      break;
  }

  return nAsset;
};

const normalizeWorkProgressItems = (items = []) => {
  const nItems = items
    .map(item => item?.entity)
    .sort((a, b) => new Date(b.date.date) - new Date(a.date.date))
    .map(item => ({
      date: moment(item.date.date).format('MMMM Y'),
      assets: item?.assets?.map(normalizeMediaAsset),
      title: item?.title,
    }));
  // Uncomment this line if it's necessary.
  // .reverse()

  return nItems;
};

function WorkProcessProjectTemplate({ data, ...props }) {
  const info = data?.drupal?.entity;
  const buildingPath = info?.projectUrl.path;
  const codeProject = info?.codeProject;
  const code = info?.code;
  const rawCanonFrom = info?.canonFrom;
  const rawCanonTo = info?.canonTo;
  const areaFrom = info?.areaFrom;
  const areaTo = info?.areaTo;
  const name = info?.name;
  const soldOut = info?.state?.entity?.machineName;
  const isSoldOut = soldOut === 'sold_out';
  const brand = info?.brand?.url;
  const typicals = info?.typicalFloors;
  const whatsapp = info?.whatsapp;

  const canonFrom = formatPrice(rawCanonFrom);
  const canonTo = formatPrice(rawCanonTo);

  const workProgressItems = normalizeWorkProgressItems(info?.workProgress);
  const workProgressDesc = info?.workProgressDesc;
  const tabs = info?.tabs;

  const projectTitle = name[0].toUpperCase() + name.slice(1);
  const projectLocation = [
    info?.location?.entity?.name,
    info?.location?.entity?.parent[0]?.entity?.name,
  ].join(', ');

  const projectDescription = `
    Te presentamos el histórico de avance de obra de nuestro proyecto en el cual has depositado tus sueños,
    haciéndonos acreedores de tu confianza.
  `

  const whatsappMsg = `
    Hola, quiero compartir contigo estos proyectos de Coninsa Ramón H.,
    que creo te interesarán. Haz clic en el link del proyecto para conocerlo y/o
    contactarte con el Asesor. ${name} Código: ${code} Precio desde: ${canonFrom} -
    hasta: ${canonTo} Área desde: ${areaFrom} m2 - hasta: ${areaTo} m2
    https://www.coninsa.co${buildingPath}`;

  const number = '573123636333';
  const whatsappChatMsg = `Hola! los vi en coninsa.co, me gustaría obtener más información. Proyecto: ${code}`;

  const titleStyles = {
    color: 'neutral',
    fontFamily: 'Oswald',
    fontSize: ['22px', '36px'],
    fontWeight: '500',
    mb: ['8px', '1.2rem'],
    lineHeight: '20px',
  };

  const buttonStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '.5rem',
    alignItems: 'center',
    border: '1px solid',
    borderColor: 'secondary',
    borderRadius: '7px',
    color: 'secondary',
    fontSize: '14px',
    fontWeight: 'bold',
    cursor: 'pointer',
    px: '10px',
    py: '8px',

    '&:hover': {
      bg: 'green-3',
      borderColor: 'green-3',
      color: 'white',
    },
  };

  return (
    <Layout
      footerAds={info?.footerAds}
      headerAds={info?.headerAds}
      popupAds={info?.popUpAds}
    >
      <Helmet>
        <title>{name}</title>
      </Helmet>

      <Box sx={{ display: ['none', 'block'] }}>
        <FloatingWhatsappBtn path='https://api.whatsapp.com/send?phone=573123636333&text=Hola!' />
      </Box>

      <BuildingMobileFloatingBar
        buildindType='proyecto'
        label='Contactar'
        path={`https://api.whatsapp.com/send?phone=${number}&text=${whatsappChatMsg}`}
      />

      <Box {...props}>
        <Container>
          <Box sx={{ py: ['medium', 'large'] }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: ['1rem', '4rem'], px: ['1rem', 0] }}>
              <Box
                sx={{
                  borderRadius: '8px',
                  boxShadow: '0px 0px 29px #00000017',
                  height: ['100px', '180px'],
                  width: ['100px','180px'],
                  mr: ['small', 'medium'],
                  p: 'small',
                }}
              >
                <Image src={brand} />
              </Box>

              <Box mt='.5rem'>
                <Box sx={{ mb: [0, '2.5rem'] }}>
                  <Box sx={titleStyles}>{projectTitle}</Box>
                  <Box sx={{ fontSize: [1, 2], lineHeight: ['15px', '17px'] }}>
                    {projectLocation}
                  </Box>
                </Box>
                <Box sx={{ display: ['none', 'block'], fontSize: 2, }}>
                  <Divider sx={{ width: '400px', border: '1px solid' }} />
                  {projectDescription}
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: ['block', 'none'], fontSize: 1, mb: '2rem', px: '1rem' }}>
              <Divider sx={{ width: '300', border: '1px solid' }} />
              {projectDescription}
            </Box>

            <Box
              sx={{
                display: 'grid',
                gridGap: '1rem',
                gridTemplateColumns: ['1fr', 'repeat(4, 1fr)'],
                gridTemplateRows: [null, 'repeat(2, 1fr)'],
                mb: 'large',
                px: ['1rem', 0]
              }}
            >
              {workProgressItems.map(item => (
                <Box
                  sx={{
                    bg: 'white',
                    borderRadius: '5px',
                    boxShadow: '0px 3px 6px #00000029',
                    mb: 'small',
                    px: '14px',
                    py: ['.75rem', '1rem'],
                  }}
                >
                  <Box sx={{ fontSize: ['14px', '16px'], textAlign: 'center' }}>
                    Avance de obra
                  </Box>

                  <Box
                    sx={{
                      color: 'neutral',
                      fontFamily: 'heading',
                      fontSize: ['24px', '30px'],
                      fontWeight: 'heading',
                      textAlign: 'center',
                      textTransform: 'capitalize',
                      mb: ['1.5rem', '2rem'],
                    }}
                  >
                    {item?.date}
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      gap: '.5rem',
                      alignContent: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {item?.assets?.map(asset =>
                      asset?.video ? (
                        <Box
                          as='a'
                          href={asset?.video}
                          target='_blank'
                          sx={buttonStyles}
                        >
                          <span>Ver video</span>
                          <Icon name='fa-youtube' sx={{ fontSize: '24px' }} />
                        </Box>
                      ) : (
                        <Box
                          as='a'
                          href={asset?.document}
                          target='_blank'
                          sx={buttonStyles}
                        >
                          <span>Ver PDF</span>
                          <Icon name='fi-download' sx={{ fontSize: '24px' }} />
                        </Box>
                      )
                    )}
                  </Box>
                </Box>
              ))}
            </Box>

            <div className='co-poligon-banner rounded-2xl !bg-co-dark-cyan pb-' style={{ '--co-poligon-banner--background': 'url(/system-ui/images/banner.jpg)' }}>
              <img
                src='/system-ui/images/banner.jpg'
                className='banner-image'
              />
              <div className='container banner-container rounded-2xl'>
                <div className='banner banner-content !bg-co-dark-cyan'>
                  <h1 class='banner-title !leading-9'>
                    Tus amigos y familiares merecen las mejores recomendaciones de
                    inversión,
                    <span class='is-highlighted'>
                      {' '}
                      cuéntales acerca de nuestros proyectos.
                    </span>
                  </h1>
                  <p class='banner-text leading-6 mb-5'>
                    Sé parte de nuestro plan de referidos y obtén grandes beneficios.*
                  </p>
                  <div className='text-center md:text-left'>
                    <a
                      href='https://www.coninsa.co/referidos/proyectos'
                      target='_blank'
                      className='text-white border border-white inline-block rounded-md text-sm font-bold px-5 py-[10px]
                      cursor-pointer uppercase hover:bg-white hover:text-co-dark-cyan'
                    >
                      Quiero ser parte del programa
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Container>
      </Box>
      <script src='https://suite-simulator-embedded.s3.us-east-2.amazonaws.com/script-simula.js' />
    </Layout>
  );
}

WorkProcessProjectTemplate.propTypes = {
  data: PropTypes.oneOf({}).isRequired,
  path: PropTypes.string.isRequired,
};

export default WorkProcessProjectTemplate;

export const pageQuery = graphql`
  query workProgressProject($id: String!) {
    drupal {
      entity: buildingById(id: $id) {
        id
        projectUrl: entityUrl {
          path
        }
        headerAds: ads(type: "header") {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        popUpAds: ads(type: "pop_up") {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        footerAds: ads(type: "footer") {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }

        ... on Drupal_BuildingProject {
          __typename
          code
          name
          codeProject: code
          location: fieldLocation {
            entity {
              name
              parent {
                entity {
                  name
                  parent {
                    entity {
                      name
                    }
                  }
                }
              }
            }
          }
          canonFrom: fieldSalePriceFrom
          canonTo: fieldSalePriceTo
          areaFrom: fieldAreaFrom
          areaTo: fieldAreaTo
          typicalFloors: fieldTypicalFloors {
            alt
            url
          }
          state: fieldState {
            entity {
              id: tid
              name
              ... on Drupal_TaxonomyTermProjectStatus {
                machineName: fieldMachineName
                variant: fieldVariant
              }
            }
          }
          brand: fieldBrand {
            alt
            url
          }
          whatsapp: fieldWhatsapp
          workProgressDesc: fieldWorkProgressDesc
          workProgress: fieldWorkProgress {
            entity {
              ... on Drupal_ParagraphProjectItem {
                id
                assets: fieldAssets {
                  entity {
                    bundle: entityBundle
                    ... on Drupal_MediaDocument {
                      document: fieldMediaDocument {
                        entity {
                          ... on Drupal_File {
                            url
                            filename
                          }
                        }
                      }
                    }
                    ... on Drupal_MediaRemoteVideo {
                      video: fieldMediaOembedVideo
                      thumbnail {
                        entity {
                          ... on Drupal_File {
                            url
                          }
                        }
                      }
                    }
                  }
                }
                date: fieldDate {
                  date
                }
                title: fieldItemTitle
              }
            }
          }
        }
      }
    }
  }
`;
