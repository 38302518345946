import React from "react";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import StepCard from "@coninsa-s2/step-card";

import data from "./data";

export default function ForCbrUsers() {
  return (
    <Section tint="light-gray">
      <Container>
        <div className="mb-8 lg:mb-10">
          <h3 className="mb-3 font-display text-2xl text-s2-dark-blue lg:mb-6 lg:text-3xl">
            Si eres usuario de Proyectos
          </h3>
          <p className="mb-5 text-sm text-s2-gray-600 lg:text-base">
            Te damos la bienvenida a Mi Coninsa Virtual, una herramienta que te
            permitirá hacer un seguimiento detallado y personalizado del proceso
            que adelantas con nosotros, referente a la compra del proyecto en el
            cual depositaste tus sueños.
          </p>

          <span className="text-sm font-bold text-s2-gray-600 lg:text-base">
            Toma nota de lo que puedes hacer:
          </span>
        </div>

        {data.map((item) => (
          <StepCard
            key={item.id}
            title={item.title}
            tint="green"
            variant="outline"
          >
            <div className="font-bold">{item.subtitle}</div>
            <p className="text-sm lg:text-base">{item.description}</p>
          </StepCard>
        ))}
      </Container>
    </Section>
  );
}
