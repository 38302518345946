export class TotalesValoresPresentes {
  constructor(
    public valorPresenteConstructorDescuento: number,
    public valorPresenteConstructorIntereses: number,
    public valorPresenteClienteDescuento: number,
    public valorPresenteClienteIntereses: number,
    public diferenciaDescuento: number,
    public diferenciaIntereses: number
  ) {
  }
}
