import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

import { Icon } from 'ui'
import { getBaseTabsItemStyles } from './styles'

const TabsItem = ({ active, disabled, step, label, icon, variant, onActivate, ...props }) => {
  const baseTabsItemStyles = getBaseTabsItemStyles(disabled)

  const tabsItemStyles = {
    ...baseTabsItemStyles,
  }

  const onClickHandler = () => {
    onActivate && onActivate()
  }

  const defaultProps = !disabled ? { onClick: onClickHandler } : {}
  const className = active ? 'active' : undefined

  return (
    <Box
      variant={`${variant}.item`}
      __themeKey='tabs'
      __css={tabsItemStyles}
      className={className}
      {...defaultProps}
      {...props}
    >
      {icon && <Icon name={icon} mr='xsmall' size='16' />}
      <Box className='label'>{label}</Box>
    </Box>
  )
}

TabsItem.defaultProps = {
  active: false,
  disabled: false,
  icon: undefined,
  variant: 'default',
  step: 1,
  onActivate: undefined,
}

TabsItem.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  step: PropTypes.number,
  onActivate: PropTypes.func,
}

export default TabsItem
