import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { Box } from 'theme-ui'
import { Button } from 'ui'

function FooterForm({ back, backRedirect = -1, isSubmitting }) {
  return (
    <Box pt='medium'>
      <Button type='submit' disabled={isSubmitting} loading={isSubmitting}>
        Siguiente
      </Button>

      {back && (
        <Button
          ml='small'
          type='button'
          variant='default'
          onClick={() => navigate(backRedirect)}
        >
          Atras
        </Button>
      )}
    </Box>
  )
}

FooterForm.defaultProps = {
  back: false,
  isSubmitting: false,
}

FooterForm.propTypes = {
  back: PropTypes.bool,
  isSubmitting: PropTypes.bool,
}

export default FooterForm
