import React from 'react'
import PropTypes from 'prop-types'
import { Box, Container } from 'theme-ui'

const Section = ({ background, children, direction, title, variant, ...props }) => {
  const baseStyles = {
    pb: ['medium', 'large'],
    pt: ['medium', 'large'],
    px: ['medium', null, null, null, 0],
    backgroundImage: background?.src ? `url(${background.src})` : null,
  }

  const isHorizontal = direction === 'horizontal'

  const containerStyles = isHorizontal
    ? {
        display: 'grid',
        gridGap: 'medium',
        gridTemplateColumns: ['1fr', '23% 77%'],
      }
    : {}

  const headerStyles = isHorizontal
    ? {
        alignSelf: 'center',
        textAlign: 'left',
      }
    : {
        textAlign: 'center',
      }

  return (
    <Box __css={baseStyles} __themeKey='sections' variant={variant} {...props}>
      <Container sx={containerStyles}>
        {title && <Box __css={headerStyles}>{title}</Box>}
        <Box>{children}</Box>
      </Container>
    </Box>
  )
}

Section.defaultProps = {
  direction: 'default',
  variant: 'default',
  background: {},
}

Section.propTypes = {
  background: PropTypes.oneOf({}),
  children: PropTypes.node.isRequired,
  direction: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object]),
  variant: PropTypes.string,
}

export default Section
