import React from 'react'

import WorkWithUsPage from 'modules/work-with-us/page'
import Layout from '../../layout/default/Layout'

function WorkWithUs() {
  return (
    <Layout>
      <WorkWithUsPage />
    </Layout>
  )
}

export default WorkWithUs
