import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

function HelpText({ text }) {
  return (
    <Box
      __css={{
        color: 'accent',
        fontSize: 1,
        fontStyle: 'italic',
        fontWeight: 'heading',
        ml: 'xsmall',
      }}
    >
      {text}
    </Box>
  )
}

HelpText.propTypes = {
  text: PropTypes.string.isRequired,
}

export default HelpText
