import React from 'react'
import { SplideSlide } from '@splidejs/react-splide'
import Splider from 'component-v2/Splider'

import Layout from '../../layout/architecture/Layout'
import ArchitecturalDesignCards from '../../modules/microsites/architecture/components/ArchitecturalDesign'
import StaffCard from '../../modules/microsites/architecture/components/StaffCard'
import IconItem from '../../modules/microsites/architecture/components/IconItem'
import ContactSection from '../../modules/microsites/architecture/components/ContactSection'

import heroBannerImageUrl from '../../modules/microsites/assets/images/architecture-mobile-image.jpg'
import heroBannerUrl from '../../modules/microsites/assets/images/architecture-banner.jpg'
import bgAlternativeBlue from '../../modules/microsites/assets/images/bg-alternative-blue.jpg'
import bgWhite from '../../modules/microsites/assets/images/bg-white.jpg'
import screenImage from '../../modules/microsites/assets/images/screen.png'

import standardIcon from '../../modules/microsites/assets/icons/standard.svg'
import designIcon from '../../modules/microsites/assets/icons/design-integration.svg'
import efficientIcon from '../../modules/microsites/assets/icons/efficient-coordination.svg'
import optimizationIcon from '../../modules/microsites/assets/icons/optimization.svg'
import craneIcon from '../../modules/microsites/assets/icons/crane.svg'

import architectsInfo from '../../modules/microsites/data/architect-staff'
import technicalInfo from '../../modules/microsites/data/technical-leaders'

import '../../modules/leasing-property/style.css'

function AboutUsPage() {
  const slideSettings = {
    autoplay: true,
    perMove: 1,
    perPage: 2,
    rewind: true,
    padding: { left: 0, right: '12rem' },
    breakpoints: {
      1024: {
        perPage: 2,
        padding: { left: 0, right: '8rem' },
      },
      768: {
        perPage: 2,
        padding: { left: 0, right: '4rem' },
      },
      640: {
        perPage: 1,
        padding: { left: 0, right: '2rem' },
      },
    },
  }

  return (
    <Layout>
      <div>
        <div
          className='hero hero--right-content bg-coninsa-green-300'
          style={{ backgroundImage: `url(${heroBannerUrl})` }}
        >
          <div className='hero__container container'>
            <img className='hero__image' src={heroBannerImageUrl} />

            <div className='hero__content md:!w-1/2 !px-0 md:!px-6'>
              <h1 className='text-white font-heading leading-none text-4xl'>
                <span className='text-4xl leading-5 md:text-5xl'>Soluciones</span>
                <span className='block font-handwritting text-coninsa-green-500 text-5xl md:text-7xl text-center mb-3'>
                  integrales
                </span>
                <span className='text-[20px] md:text-4xl'>En diseños arquitectónicos</span>
                <span className='text-[20px] md:text-4xl block text-end'>y técnicos</span>
              </h1>
            </div>
          </div>
        </div>

        <div
          className='bg-coninsa-blue-900 py-10 md:py-24'
          style={{ backgroundImage: `url(${bgWhite})` }}
        >
          {/* <div className='border-b-[16px] border-solid border-coninsa-green-400 rounded-r-md w-1/2 transform -translate-y-[104px]' /> */}

          <p className='container px-5 text-xl md:text-2xl md:px-0 text-center max-w-2xl'>
            La creatividad y la calidad nos inspiran para
            <span className='font-semibold bg-coninsa-green-300 inline-block leading-9 p-1 md:px-4 md:py-2 rounded-md text-white'>
              ofrecer diseños que perduran en el tiempo,
            </span>
            <br />
            en obras y espacios donde se cumplen los sueños de los Clientes que
            buscan bienestar y rentabilidad para su empresa.
          </p>

          {/* <div className='border-b-[16px] border-solid border-coninsa-green-400 rounded-l-md w-1/2 transform translate-y-[104px] translate-x-[850px]' /> */}
        </div>

        <div
          className='pt-10 pb-0 md:py-16 relative before:content before:block before:h-56 before:bg-coninsa-green-300
          before:absolute before:bottom-0 before:w-full before:z-0'
        >
          <div className='container px-5 z-10 md:px-0'>
            <div className='mb-12'>
              <h2 className='font-display leading-8 mb-4 text-3xl md:leading-[44px] md:mb-6 md:text-[44px] text-coninsa-green-500 uppercase'>
                Nuestra oferta en
                <span className='block font-bold text-coninsa-blue-900'>
                  diseños arquitectónicos y técnicos
                </span>
              </h2>

              <p>
                Conoce la oferta de servicios que tenemos disponible para realizar
                los proyectos para tu empresa.
              </p>
            </div>

            <div className='relative z-10'>
              <ArchitecturalDesignCards />
            </div>
          </div>
        </div>

        <div className='relative bg-coninsa-green-300'>
          <div className='container px-5 md:px-0 z-10 relative'>
            <div className='text-center mb-8'>
              <h2 className='font-heading text-coninsa-blue-900 mb-8'>
                <span className='text-white text-4xl md:text-6xl'>Gestionamos</span>
                <br />
                <span className='text-3xl'>proyectos y obras </span>
                <span className='font-handwritting text-5xl md:text-6xl'>
                  exitosas
                </span>
              </h2>

              <p className='font-normal text-white'>
                Agregamos valor a{' '}
                <span className='font-bold bg-coninsa-green-500 px-2'>nuestros</span>
                clientes y aliados a través de la{' '}
                <span className='font-bold bg-coninsa-green-500 px-2'>
                  innovación
                </span>{' '}
                y la{' '}
                <span className='font-bold bg-coninsa-green-500 px-2'>
                  tecnología
                </span>
              </p>
            </div>

            <div className='flex flex-wrap justify-center gap-4 text-center'>
              <IconItem icon={standardIcon} label='Estandarización' />
              <IconItem icon={designIcon} label='Integración de diseños' />
              <IconItem icon={efficientIcon} label='Coordinación eficiente' />
              <IconItem icon={optimizationIcon} label='Optimización' />
              <IconItem icon={craneIcon} label='Constructibilidad' />
            </div>
          </div>
        </div>

        <div
          className='pt-10 md:pt-16 relative before:content before:block before:h-24 md:before:h-44 before:bg-coninsa-green-300
          before:absolute before:top-0 before:w-full before:z-0'
        >
          <div className='container inline-block md:flex gap-4 px-5 md:px-0'>
            <div>
              <h2 className='font-heading text-3xl md:text-4xl text-coninsa-blue-900 mb-0 md:mb-3 relative z-10 text-center md:text-left'>
                <span className='leading-5 md:leading-[110px]'>Metodología</span>
                <span className='block text-5xl md:text-9xl text-coninsa-green-500'>
                  BIM
                </span>
              </h2>
              <p className='font-bold text-coninsa-green-500 text-lg mb-6 text-center md:text-left'>
                Entorno Común de Datos (CDE)
              </p>

              <ul className='list-disc list-inside marker:text-coninsa-green-300'>
                <li>
                  <span className='font-bold'>Trazabilidad</span> de cambios y
                  versión.
                </li>
                <li>
                  <span className='font-bold'>Manejo de información digital</span>
                  del proyecto que incluye a las partes interesadas.
                </li>
                <li>
                  <span className='font-bold'>Revisiones</span> gerenciales de
                  plantas y modelos.
                </li>
                <li>
                  <span className='font-bold'>Centralización</span> y almacenamiento
                  de la información en la nube.
                </li>
                <li>
                  <span className='font-bold'>Visualización</span> de modelos 3D.
                </li>
              </ul>
            </div>

            <div className='z-10'>
              <img src={screenImage} />
            </div>
          </div>
        </div>

        <div
          className='bg-coninsa-blue-900 py-10 md:py-16'
          style={{ backgroundImage: `url(${bgAlternativeBlue})` }}
        >
          <div className='container inline-block md:grid grid-cols-[40%_60%] gap-4 px-5 md:px-0'>
            <div className='mb-8 md:mb-0'>
              <h2 className='font-display leading-8 mb-4 text-3xl md:leading-[44px] md:mb-6 md:text-[44px] text-white uppercase'>
                Conoce a <br /> nuestro equipo
                <span className='block font-bold'>de líderes arquitectos</span>
              </h2>
              <p className='text-white'>
                La satisfacción de nuestros Clientes es lo más importante, por eso
                contamos con un Equipo que le imprime todo su{' '}
                <span className='font-bold'>
                  {' '}
                  humanismo, liderazgo, actitud de servicio y pasión{' '}
                </span>
                al diseño de tus sueños y los de tu empresa.
              </p>
            </div>

            <Splider slot='content' options={slideSettings}>
              {architectsInfo.map(arch => (
                <SplideSlide>
                  <div className='flex gap-4 mb-8'>
                    <StaffCard
                      photo={arch?.photo}
                      firstName={arch?.firstName}
                      lastName={arch?.lastName}
                      position={arch?.position}
                      linkedin={arch?.linkedin}
                    />
                  </div>
                </SplideSlide>
              ))}
            </Splider>
          </div>
        </div>

        <div className='bg-coninsa-green-900 py-10 md:py-16'>
          <div className='container inline-block md:grid grid-cols-[40%_60%] gap-4 px-5 md:px-0'>
            <div className='mb-8 md:mb-0'>
              <h2 className='font-display leading-8 mb-4 text-3xl md:leading-[44px] md:mb-6 md:text-[44px] text-white uppercase'>
                Conoce a <br /> nuestro equipo
                <span className='block font-bold'>de líderes técnicos</span>
              </h2>
              <p className='text-white'>
                Contamos con un excelente equipo de profesionales y especialistas que
                le imprime todo su
                <span className='font-bold'>
                  humanismo, liderazgo, actitud de servicio y pasión{' '}
                </span>
                a la realización de los proyectos compuesto por Arquitectos,
                Diseñadores Estructurales, Gestores de Sistemas, Gerentes de
                Proyecto, Administradores, Financieros, y por supuesto Ingenieros
                Civiles, Hidráulicos, Ambientales, Mecánicos, Eléctricos y
                Estructurales.
              </p>
            </div>

            <Splider slot='content' options={slideSettings}>
              {technicalInfo.map(tech => (
                <SplideSlide>
                  <div className='flex gap-4 mb-8'>
                    <StaffCard
                      photo={tech?.photo}
                      firstName={tech?.firstName}
                      lastName={tech?.lastName}
                      position={tech?.position}
                      linkedin={tech?.linkedin}
                    />
                  </div>
                </SplideSlide>
              ))}
            </Splider>
          </div>
        </div>

        <ContactSection />
      </div>
    </Layout>
  )
}

export default AboutUsPage
