import React from "react";

import Banner from "./src/banner";
import Content from "./src/content";

function CookiePolicy() {
  return (
    <div>
      <Banner />
      <Content />
    </div>
  );
}

export default CookiePolicy;
