import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Splider from 'component-v2/Splider'
import { SplideSlide } from '@splidejs/react-splide'
import { formatPrice } from '../../search-building/utils'

function UsedProperties() {
  const data = useStaticQuery(graphql`
    query SearchUsedProperties {
      drupal {
        searchAPISearch(
          index_id: "search_properties"
          conditions: [
            { name: "field_service_type", value: "CO", operator: "=" }
            { name: "status", value: "1", operator: "=" }
            { name: "field_publish", value: "1", operator: "=" }
            # { name: "string_location", value: "!", operator: "<>" }
            # { name: "field_sale_value", value: "0", operator: "<>" }
          ]
          range: { offset: 0, limit: 8 }
        ) {
          result_count
          documents {
            ... on Drupal_SearchPropertiesDoc {
              id: property_id
              url
              mainImageUrl: building_image
              propertyType: property_type
              serviceType: field_service_type
              code
              area: field_area
              location: ngram_location
              price: sale_value
            }
          }
        }
      }
    }
  `)

  const properties = data?.drupal?.searchAPISearch?.documents || []

  const slideSettings = {
    perPage: 4,
    padding: { left: 0, right: '4rem' },
  }

  return (
    <div className='container pb-10'>
      <Splider slot='content' options={slideSettings}>
        {properties.map(property => {
          const serviceType = property?.serviceType === 'CO' ? 'Venta' : ''

          return (
            <SplideSlide key={property?.id}>
              <co-property-card
                class='mb-4'
                url={property?.url}
                imageUrl={property?.mainImageUrl}
                type={property?.propertyType}
                serviceType={serviceType}
                code={property?.code}
                area={`${property?.area} m²`}
                location={property?.location}
                canon={`${formatPrice(property?.price)}`}
              />
            </SplideSlide>
          )
        })}
      </Splider>

      <button
        slot='description'
        onClick={() => window.open('/inmuebles/compra', '_blank')}
        class='co-button is-progress-default'
      >
        Todos los inmuebles
      </button>
    </div>
  )
}

export default UsedProperties
