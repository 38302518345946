import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

import { Icon } from 'ui'

function BuildingFaq({ bg, cardBg, description, icon, label, url, ...props }) {
  const baseStyles = {
    position: 'relative',
    display: 'block',

    '&:hover div': {
      bg: cardBg,
    },

    '&:hover': {
      cursor: 'pointer',
    },

    '&:hover h3': {
      color: 'white',
    },

    '&:hover p': {
      color: 'white',
    },
  }

  const cardStyles = {
    px: '4',
    pb: 'small',
    pt: '77px',
    height: ['225px', '200px'],
    borderRadius: '8px',
    boxShadow: '0px 3px 6px #00000029',
    textAlign: 'center',
    mx: ['8px', 'none'],
    mt: '-3rem',
    zIndex: 'auto',
    fontWeight: '500',
  }

  const circleStyles = {
    cursor: 'pointer',
    p: 'medium',
    borderRadius: '50%',
    boxShadow: '0px 0px 9px #00000029',
    color: 'white',
    width: '114px',
    height: '114px',
    mx: 'auto',
    mb: [2, 'medium'],
    transform: 'translateY(2rem)',
    zIndex: '1',
  }

  const labelStyles = {
    color: 'accent',
    fontSize: [2, 3],
    fontWeight: 'bold',
    mb: 'xsmall',
  }

  return (
    <Box as='a' href={url} target='_blank' __css={baseStyles} {...props}>
      <Box sx={circleStyles} bg={bg}>
        <Icon
          name={icon}
          sx={{
            fontSize: '70px',
          }}
        />
      </Box>

      <Box sx={cardStyles}>
        <Box as='h3' sx={labelStyles}>
          {label}
        </Box>
        <Box as='p' sx={{ color: 'dark-2', fontSize: 1 }}>
          {description}
        </Box>
      </Box>
    </Box>
  )
}

BuildingFaq.defaultProps = {
  bg: '',
  cardBg: '',
  description: '',
  url: '',
}

BuildingFaq.propTypes = {
  bg: PropTypes.string,
  cardBg: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  url: PropTypes.string,
}

export default BuildingFaq
