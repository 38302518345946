import React from 'react'
import PropTypes, { shape } from 'prop-types'
import { Box } from 'theme-ui'

import FeaturedList from 'ui/FeaturedList'
import { Header } from 'ui'

function PFeaturedList(props) {
  const { items, title } = props
  return (
    <FeaturedList>
      <Header>{title}</Header>

      {items.map(item => (
          <FeaturedList.Item icon={item.icon}>
            <Box>
              <FeaturedList.SubTitle>{item.title}</FeaturedList.SubTitle>
              <FeaturedList.Body>{item.description}</FeaturedList.Body>
            </Box>
          </FeaturedList.Item>
        ))}
    </FeaturedList>
  )
}

PFeaturedList.propTypes = {
  items: PropTypes.arrayOf(
    shape({
      icon: PropTypes.string,
      description: PropTypes.string,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
}
export default PFeaturedList
