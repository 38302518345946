import awardOneImage from '../assets/images/award-2023.jpg'
import awardTwoImage from '../assets/images/award-2021.jpg'
import awardThreeImage from '../assets/images/award-2019.jpg'
import awardFourImage from '../assets/images/award-2015.jpg'
import awardFiveImage from '../assets/images/award-2014.jpg'
import awardSixImage from '../assets/images/award-2013.jpg'
import awardSevenImage from '../assets/images/award-2010.jpg'
import awardEightImage from '../assets/images/award-2008.jpg'
import awardTenImage from '../assets/images/award-1999.jpg'
import awardElevenImage from '../assets/images/award-2023-II.png'
import awardTwelveImage from '../assets/images/award-2023-III.png'
import awardThirteenImage from '../assets/images/award-2024-I.png'
import awardFourteenImage from '../assets/images/award-2024-II.png'

export default [
  {
    image: awardFourteenImage,
    year: '2024',
    description:
      'Premio a la Responsabilidad Social Empresarial de Camacol Atlántico - Mejor Gestión Sociolaboral',
  },
  {
    image: awardThirteenImage,
    year: '2024',
    description:
      'Premio a la Responsabilidad Social Camacol Antioquia - Mejor Práctica Ambiental. Por el programa Gobernanza para la Gestión Ambiental y Legal',
  },
  {
    image: awardTwelveImage,
    year: '2023',
    description:
      'Premio excelencia BIM Colombia 2023 de la Cámara Colombiana de la Construcción - Segmento Diseño',
  },
  {
    image: awardElevenImage,
    year: '2023',
    description:
      'Sello de sostenibilidad Corantioquia Categoría A. En reconocimiento por incorporar en nuestro proceso productivo criterios de sostenibilidad',
  },
  {
    image: awardOneImage,
    year: '2023',
    description:
      'Primer puesto concurso privado ampliación centro comercial Chipichape, Cali',
  },
  {
    image: awardTwoImage,
    year: '2021',
    description:
      'Ganador del concurso para los diseños del Nuevo Palacio Municipal de Envigado - Antioquia',
  },
  {
    image: awardThreeImage,
    year: '2019',
    description:
      'La Sociedad Antioqueña de Ingenieros y Arquitectos - SAI nos condecoró como Institución Emblemática de Antioquia',
  },
  {
    image: awardFourImage,
    year: '2015',
    description:
      'Primer Puesto concurso privado Valores Simesa A-15, Ciudad del Río - Medellín',
  },
  {
    image: awardFiveImage,
    year: '2014',
    description:
      'Primer Puesto concurso privado Clínica las Américas del Sur, Envigado - Antioquia',
  },
  {
    image: awardSixImage,
    year: '2013',
    description:
      'Primer Puesto concurso privado Edificio Administrativo Central La Miel I, Norcasia -Caldas',
  },
  {
    image: awardSevenImage,
    year: '2010',
    description:
      'Primer Puesto concurso público Diseño Urbano Paisajístico Av. Santander y Av. Bocagrande, desde Crespo hasta El Laguito',
  },
  {
    image: awardEightImage,
    year: '2008',
    description:
      'Concurso Compañía Nacional de Chocolates plan maestro Villas del Rosario, Medellín - Antioquia',
  },
  {
    image: awardTenImage,
    year: '1999',
    description:
      'Concurso centro comercial Unicentro por diseño Edificio de parqueaderos, Bogotá - Cundinamarca',
  },
]
