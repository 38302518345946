import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { Router, navigate } from "@reach/router";

import Layout from "../../layout/default/Layout.js";
import { SearchProgressViewPage } from "@coninsa-site/project";
import FloatingWhatsappBtn from "modules/home/components/FloatingWhatsappBtn";

export default function ThankYou({ data }) {
  return (
    <Layout
      headerAds={data?.drupal?.ads?.header}
      popupAds={data?.drupal?.ads?.popUp}
      footerAds={data?.drupal?.ads?.footer}
    >
      <Helmet>
        <title>Resultados de Búsqueda de Proyectos Inmobiliarios | Coninsa</title>
        <meta name="description" content="Encuentra el proyecto inmobiliario ideal en Colombia con Coninsa. Explora nuestra selección de resultados de búsqueda que incluye modernos apartamentos, casas, oficinas y locales comerciales en las mejores ubicaciones. Simplifica tu búsqueda de la propiedad perfecta con nuestras herramientas avanzadas y experiencia en el mercado." />
        <link rel="canonical" href="https://www.coninsa.co/proyectos/nuevo/" />
      </Helmet>

      <FloatingWhatsappBtn path="https://api.whatsapp.com/send?phone=573123636333&text=Hola!" />

      <Router basepath="/proyectos/avance-de-obra">
        <SearchProgressViewPage path="*" navigate={navigate} />
      </Router>
    </Layout>
  );
}

export const query = graphql`
  query NewProjectProgressPageQuery {
    drupal {
      ads: getAdsByUrl(path: "/proyectos/avance-de-obra") {
        header {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        popUp {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        footer {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
      }
    }
  }
`;
