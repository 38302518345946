import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

const Picture = ({ image, variant, alt, ...props }) => {
  const baseStyles = {
    width: '100%',

    '& > div': {
      width: '100%',
    },
  }

  return (
    <Box
      as='img'
      alt={alt}
      __css={baseStyles}
      __themeKey='cards'
      variant={`${variant}.image`}
      src={image?.url || image?.derivative?.url}
      {...props}
    ></Box>
  )
}

Picture.defaultProps = {
  variant: 'default',
  alt: undefined
}

Picture.propTypes = {
  alt: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
    derivate: PropTypes.shape({}),
  }).isRequired,
  variant: PropTypes.string,
}

export default Picture
