import { gql } from '@apollo/client'

import { NATURAL_PERSON_APPLICATION } from './natural-person'
import { LEGAL_PERSON_APPLICATION } from './legal-person'
import { NATURAL_COTENANT_APPLICATION } from './co-natural-person'
import { LEGAL_COTENANT_APPLICATION } from './co-legal-person'


const USER_APPLICATIONS_QUERY = gql`
  query UserApplicants($uid: String!) {
    nodeQuery(
      filter: {
        conditions: [
          { field: "status", value: ["1"] }
          {
            field: "type"
            value: ["legal_person_application", "natural_person_application"]
          }
          { field: "uid", value: [$uid] }
        ]
      }
      limit: 100
    ) {
      entities {
        id: entityId
        title: entityLabel
        bundle: entityBundle
        created: entityCreated(format: "Y-m-d h:m a")

        ... on Node {
          uid {
            targetId
          }
        }
      }
    }
  }
`

export {
  NATURAL_PERSON_APPLICATION,
  LEGAL_PERSON_APPLICATION,
  NATURAL_COTENANT_APPLICATION,
  LEGAL_COTENANT_APPLICATION,
  USER_APPLICATIONS_QUERY
}
