import consultantTwo from '../assets/images/consultant-two.png'
import consultantThree from '../assets/images/consultant-three.png'

export default [
  {
    photo: consultantTwo,
    names: 'YANELIS ARAQUE',
    position: 'Consultor en ventas Barranquilla',
    contact: '3015233253'
  },

  {
    photo: consultantThree,
    names: 'MAURICIO COBALEDA LONDOÑO',
    position: 'Consultor en ventas Antioquia',
    contact: '3245248227'
  },
]
