import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid } from 'theme-ui'
import Carousel from 'nuka-carousel'

import { Splide, SplideSlide } from '@splidejs/react-splide'

import { Section, Header } from 'components'
import { formatPrice, getNormalizerServiceType } from '../utils'

const getNormalizedLocationProject = (location = {}) =>
  [
    location?.entity?.parent[0]?.entity?.parent[0]?.entity?.name,
    location?.entity?.parent[0]?.entity?.name,
    location?.entity?.name,
  ]
    .filter(value => value)
    .join(', ')

const getNormalizedProjectState = (project = {}) => {
  const serviceType = getNormalizerServiceType(project?.serviceType)
  const propertyType = project?.propertyType?.entity?.name
  const state = project?.state?.entity?.name ? project?.state?.entity?.name : ''

  return `${serviceType} de ${propertyType} ${state}`
}

function RelatedProjects({ projects, ...props }) {
  const options = {
    arrows: false,
    rewind: true,
    perPage: 4,
    gap: '1rem',

    breakpoints: {
      640: {
        perPage: 1,
        padding: { left: 0, right: '4rem' },
        pagination: false,
      },
    },
  }

  return (
    <Section
      title={<Header mb='large'>Otros proyectos que te pueden interesar</Header>}
      variant='grayly'
      {...props}
    >
      <Splide options={options}>
        {projects.map(project => (
          <SplideSlide>
            <co-project-vertical-card
              class='mb-4'
              imageUrl={project?.images[0]?.derivative?.url}
              key={project?.id}
              url={project?.url?.path}
              state={getNormalizedProjectState(project)}
              type={project?.name}
              code={project?.code}
              area={`${project?.area} m²`}
              canon={`Desde ${formatPrice(project?.canon)}`}
              location={getNormalizedLocationProject(
                project?.location
              )?.toLowerCase()}
            />
          </SplideSlide>
        ))}
      </Splide>
    </Section>
  )
}

RelatedProjects.defaultProps = {
  projects: [],
}

RelatedProjects.propTypes = {
  projects: PropTypes.arrayOf({}),
}

export default RelatedProjects
