import React from "react";
import clsx from "clsx";

import "./styles.css";

interface StepCardProps {
  title: string;
  children: React.ReactNode;

  tint?: "teal" | "blue" | "lime" | "green";
  variant?: "default" | "outline";
  className?: string;
}

const StepCard: React.FC<StepCardProps> = ({
  tint = "teal",
  variant = "default",

  title,
  children,

  className,
  ...props
}) => {
  const dynamicClasses = {
    [`s2-step-card--${tint}`]: tint,
    [`s2-step-card--${variant}`]: variant,
  };

  return (
    <div className={clsx("s2-step-card", dynamicClasses, className)} {...props}>
      <h4 className="s2-step-card__title">{title}</h4>
      <div className="s2-step-card__content">{children}</div>
    </div>
  );
};

export default StepCard;
