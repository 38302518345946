import React from "react";

import IntlTelInput from "react-intl-tel-input-18";
import "react-intl-tel-input-18/dist/main.css";

import "./styles.css";

interface IntlPhoneProps {
  required?: boolean;
  value?: string;
  onChange: (any) => void;
}

const intlPhone: React.FC<IntlPhoneProps> = ({
  value,
  required,
  onChange,
  ...props
}) => {
  const handleChange = (_, number) => {
    onChange(number);
  };

  const handleBlur = (_, number, country) => {
    const dial = country.dialCode;
    const phoneNumber = !number.startsWith(`+${dial}`)
      ? `+${dial}${number}`
      : number;

    onChange(phoneNumber);
  };

  const preprocessValue = (value) => {
    return value;
  };

  return (
    <IntlTelInput
      onPhoneNumberChange={handleChange}
      onPhoneNumberBlur={handleBlur}
      telInputProps={{ required, type: "tel" }}
      value={preprocessValue(value)}
      {...props}
    />
  );
};

export default intlPhone;
