import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../../layout/default/Layout";
import Referral from "@coninsa-site/referral"

function Referrer() {
  return (
    <Layout>
      <Helmet>
        <title>Referidos</title>
        <link rel="canonical" href="https://www.coninsa.co/referidos" />
      </Helmet>
      <Referral />
    </Layout>
  );
}

export default Referrer;