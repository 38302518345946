import React from "react";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import ImageCard from "@coninsa-s2/image-card";
import Heading from "@coninsa-s2/heading";
import Button from "@coninsa-s2/button";

import data from "./data";

import ArrowRightIcon from "../../../../home/icons/arrow-right.inline.svg";

export default function () {
  return (
    <Section tint="dark-blue" topper>
      <Container>
        <div className="flex flex-col md:grid gap-4 md:grid-cols-2 lg:grid-cols-3">
          {data?.map((item) => (
            <ImageCard key={item.id} image={item?.image} slipped>
              <Heading size="xl" condensed>
                {item?.title}
              </Heading>
              <p>{item?.description}</p>
              <div className="s2-card__content-btn">
                <Button href={item?.url} target="_blank" inverted>
                  Conoce más
                </Button>
                <a href={item?.url} target="_blank">
                  <ArrowRightIcon className="s2-card__content-image" />
                </a>
              </div>
            </ImageCard>
          ))}
        </div>
      </Container>
    </Section>
  );
}
