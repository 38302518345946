import { gql } from '@apollo/client'

export const CREATE_NODE_LEGAL_PERSON_APPLICATION = gql`
  mutation createNodeLegalPersonApplication(
    $title: String!
    $fieldEconomicActivity: String
    $fieldLastName: String!
    $fieldLegalRepresentativePhone: String
    $fieldContactCellPhone: String!
    $fieldCrmConsultant: String
    $fieldContactEmail: String!
    $fieldCorrespondenceAddress: String
    $fieldEmail: String
    $fieldApplicationStatus: String!
    $fieldEnablePdfApplication: String
    $fieldPropertyCode: String
    $fieldNit: String!
    $fieldContactPersonName: String!
    $fieldFirstName: String
    $fieldIdNumber: String!
    $fieldEmployeesNumber: String
    $fieldSocialReason: String!
    $fieldIcaResponsible: String
    $fieldCompanyType: String
    $fieldIdType: String!
    $fieldPropertyTax: NodeFieldPropertyTaxFieldInput
    $fieldShareholders: [NodeFieldShareholdersFieldInput]
    $fieldCreditAnalyst: NodeFieldCreditAnalystFieldInput
    $fieldFiscalCity: NodeFieldFiscalCityFieldInput
    $fieldIncomeStatement: NodeFieldIncomeStatementFieldInput
    $fieldIdentificationDocument: NodeFieldIdentificationDocumentFieldInput
    $fieldAdditionalDocuments: [NodeFieldAdditionalDocumentsFieldInput]
    $fieldFinancialState: NodeFieldFinancialStateFieldInput
    $fieldExistenceRepresentation: NodeFieldExistenceRepresentationFieldInput
    $fieldBankStatements: [NodeFieldBankStatementsFieldInput]
    $fieldCommercialReference: NodeFieldCommercialReferenceFieldInput
    $fieldRut: NodeFieldRutFieldInput
    $fieldCotenants: [NodeFieldCotenantsFieldInput]
  ) {
    node: createNodeLegalPersonApplication(
      input: {
        title: $title
        fieldEconomicActivity: $fieldEconomicActivity
        fieldLastName: $fieldLastName
        fieldLegalRepresentativePhone: $fieldLegalRepresentativePhone
        fieldContactCellPhone: $fieldContactCellPhone
        fieldCrmConsultant: $fieldCrmConsultant
        fieldContactEmail: $fieldContactEmail
        fieldCorrespondenceAddress: $fieldCorrespondenceAddress
        fieldEmail: $fieldEmail
        fieldApplicationStatus: $fieldApplicationStatus
        fieldEnablePdfApplication: $fieldEnablePdfApplication
        fieldPropertyCode: $fieldPropertyCode
        fieldNit: $fieldNit
        fieldContactPersonName: $fieldContactPersonName
        fieldFirstName: $fieldFirstName
        fieldIdNumber: $fieldIdNumber
        fieldEmployeesNumber: $fieldEmployeesNumber
        fieldSocialReason: $fieldSocialReason
        fieldIcaResponsible: $fieldIcaResponsible
        fieldCompanyType: $fieldCompanyType
        fieldIdType: $fieldIdType
        fieldShareholders: $fieldShareholders
        fieldCreditAnalyst: $fieldCreditAnalyst
        fieldFiscalCity: $fieldFiscalCity
        fieldIncomeStatement: $fieldIncomeStatement
        fieldIdentificationDocument: $fieldIdentificationDocument
        fieldAdditionalDocuments: $fieldAdditionalDocuments
        fieldFinancialState: $fieldFinancialState
        fieldExistenceRepresentation: $fieldExistenceRepresentation
        fieldBankStatements: $fieldBankStatements
        fieldPropertyTax: $fieldPropertyTax
        fieldCommercialReference: $fieldCommercialReference
        fieldRut: $fieldRut
        fieldCotenants: $fieldCotenants
      }
    ) {
      violations {
        message
        path
        code
      }
      errors
      entity {
        bundle: entityBundle
        label: entityLabel
        id: entityId
      }
    }
  }
`

export const UPDATE_NODE_LEGAL_PERSON_APPLICATION = gql`
  mutation updateNodeLegalPersonApplication(
    $id: String!
    $title: String!
    $fieldEconomicActivity: String
    $fieldLastName: String!
    $fieldLegalRepresentativePhone: String
    $fieldContactCellPhone: String!
    $fieldCrmConsultant: String
    $fieldContactEmail: String!
    $fieldCorrespondenceAddress: String
    $fieldEmail: String
    $fieldApplicationStatus: String!
    $fieldEnablePdfApplication: String
    $fieldPropertyCode: String
    $fieldNit: String!
    $fieldContactPersonName: String!
    $fieldFirstName: String
    $fieldIdNumber: String!
    $fieldEmployeesNumber: String
    $fieldSocialReason: String!
    $fieldIcaResponsible: String
    $fieldCompanyType: String
    $fieldIdType: String!
    $fieldPropertyTax: NodeFieldPropertyTaxFieldInput
    $fieldShareholders: [NodeFieldShareholdersFieldInput]
    $fieldCreditAnalyst: NodeFieldCreditAnalystFieldInput
    $fieldFiscalCity: NodeFieldFiscalCityFieldInput
    $fieldIncomeStatement: NodeFieldIncomeStatementFieldInput
    $fieldIdentificationDocument: NodeFieldIdentificationDocumentFieldInput
    $fieldAdditionalDocuments: [NodeFieldAdditionalDocumentsFieldInput]
    $fieldFinancialState: NodeFieldFinancialStateFieldInput
    $fieldExistenceRepresentation: NodeFieldExistenceRepresentationFieldInput
    $fieldBankStatements: [NodeFieldBankStatementsFieldInput]
    $fieldCommercialReference: NodeFieldCommercialReferenceFieldInput
    $fieldRut: NodeFieldRutFieldInput
    $fieldCotenants: [NodeFieldCotenantsFieldInput]
  ) {
    node: updateNodeLegalPersonApplication(
      id: $id
      input: {
        title: $title
        fieldEconomicActivity: $fieldEconomicActivity
        fieldLastName: $fieldLastName
        fieldLegalRepresentativePhone: $fieldLegalRepresentativePhone
        fieldContactCellPhone: $fieldContactCellPhone
        fieldCrmConsultant: $fieldCrmConsultant
        fieldContactEmail: $fieldContactEmail
        fieldCorrespondenceAddress: $fieldCorrespondenceAddress
        fieldEmail: $fieldEmail
        fieldApplicationStatus: $fieldApplicationStatus
        fieldEnablePdfApplication: $fieldEnablePdfApplication
        fieldPropertyCode: $fieldPropertyCode
        fieldNit: $fieldNit
        fieldContactPersonName: $fieldContactPersonName
        fieldFirstName: $fieldFirstName
        fieldIdNumber: $fieldIdNumber
        fieldEmployeesNumber: $fieldEmployeesNumber
        fieldSocialReason: $fieldSocialReason
        fieldIcaResponsible: $fieldIcaResponsible
        fieldCompanyType: $fieldCompanyType
        fieldIdType: $fieldIdType
        fieldShareholders: $fieldShareholders
        fieldCreditAnalyst: $fieldCreditAnalyst
        fieldFiscalCity: $fieldFiscalCity
        fieldIncomeStatement: $fieldIncomeStatement
        fieldIdentificationDocument: $fieldIdentificationDocument
        fieldAdditionalDocuments: $fieldAdditionalDocuments
        fieldFinancialState: $fieldFinancialState
        fieldExistenceRepresentation: $fieldExistenceRepresentation
        fieldBankStatements: $fieldBankStatements
        fieldPropertyTax: $fieldPropertyTax
        fieldCommercialReference: $fieldCommercialReference
        fieldRut: $fieldRut
        fieldCotenants: $fieldCotenants
      }
    ) {
      violations {
        message
        path
        code
      }
      errors
      entity {
        bundle: entityBundle
        label: entityLabel
        id: entityId
      }
    }
  }
`