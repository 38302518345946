import areaIcon from './icons/area.svg';
import homeIcon from './icons/home.svg';
import personIcon from './icons/person.svg';
import buildingIcon from './icons/building.svg';
import handshakeIcon from './icons/handshake.svg';

export default [
  {
    id: 'experience-item-1',
    icon: areaIcon,
    title: '+ 13 millones de m2',
    description: 'Construidos y diseñado más de 490 proyectos en diferentes sectores productivos del país.',
  },
  {
    id: 'experience-item-2',
    icon: homeIcon,
    title: '+ 26.000 viviendas',
    description: 'vendidas y entregadas en el país y actualmente comercializamos más de 45 proyectos.',
  },
  {
    id: 'experience-item-3',
    icon: personIcon,
    title: '+ 23.000 clientes',
    description: 'en arrendamiento. Top 5 en Antioquia Top 10 en Colombia + de 6.000 activos en renta.',
  },
  {
    id: 'experience-item-4',
    icon: buildingIcon,
    title: 'Sedes',
    description: 'ubicadas en Bogotá, Medellín y Barranquilla.',
  },
  {
    id: 'experience-item-5',
    icon: handshakeIcon,
    title: '+ 5.800',
    description: 'Empleados entre directos e indirectos.',
  },
];
