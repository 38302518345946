import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Splider from 'component-v2/Splider'
import { SplideSlide } from '@splidejs/react-splide'
import { formatPrice } from '../../search-building/utils'

function Properties() {
  const data = useStaticQuery(graphql`
    query SearchLeasingProperties {
      drupal {
        searchAPISearch(
          index_id: "search_properties"
          conditions: [
            { name: "field_service_type", value: "AR", operator: "=" }
            { name: "status", value: "1", operator: "=" }
            { name: "field_publish", value: "1", operator: "=" }
            { name: "string_location", value: "!", operator: "<>" }
            { name: "field_lease_value", value: "0", operator: "<>" }
            { name: "field_everyone_else", value: "1", operator: "=" }
          ]
          range: { offset: 0, limit: 8 }
        ) {
          result_count
          documents {
            ... on Drupal_SearchPropertiesDoc {
              id: property_id
              url
              mainImageUrl: building_image
              propertyType: property_type
              serviceType: field_service_type
              code
              area: field_area
              location: ngram_location
              price: field_lease_value
            }
          }
        }
      }
    }
  `)

  const properties = data?.drupal?.searchAPISearch?.documents || []

  const slideSettings = {
    perPage: 3,
    padding: { left: 0, right: '4rem' },
  }

  return (
    <co-section class='bg-coninsa-gray-300'>
      <outline-heading slot='title' level='h3' level-style='extralight'>
        CONOCE NUESTRO <strong className='font-semibold'>PORTAFOLIO</strong>
      </outline-heading>

      <p slot='description' className='mb-8'>
        Siéntete tranquilo, cómodo y seguro con el respaldo integral que te brindamos
        en todo momento, porque cuando se trata de arrendar, somos expertos.
      </p>
      <div
        slot='description'
        className='text-center flex gap-3 items-center md:block'
      >
        <button
          slot='description'
          onClick={() => window.open('/inmuebles/arriendo/comercio', '_blank')}
          class='bg-coninsa-green-300 border border-coninsa-green-300 md:mb-4 rounded-lg font-bold text-xs md:text-sm
          text-coninsa-white-300 px-3 md:px-5 py-[10px] uppercase hover:bg-coninsa-green-500 hover:border-coninsa-green-500'
        >
          Inmuebles comercio
        </button>

        <button
          slot='description'
          onClick={() => window.open('/inmuebles/arriendo', '_blank')}
          class='bg-coninsa-green-300 border border-coninsa-green-300 rounded-lg font-bold text-xs md:text-sm
          text-coninsa-white-300 px-3 md:px-5 py-[10px] uppercase hover:bg-coninsa-green-500 hover:border-coninsa-green-500'
        >
          Inmuebles vivienda
        </button>
      </div>

      <Splider slot='content' options={slideSettings}>
        {properties.map(property => {
          const serviceType = property?.serviceType === 'AR' ? 'Arriendo' : ''

          return (
            <SplideSlide key={property?.id}>
              <co-property-card
                class='mb-4'
                url={property?.url}
                imageUrl={property?.mainImageUrl}
                type={property?.propertyType}
                serviceType={serviceType}
                code={property?.code}
                area={`${property?.area} m²`}
                location={property?.location}
                canon={`${formatPrice(property?.price)}`}
              />
            </SplideSlide>
          )
        })}
      </Splider>

      {/* <SliderCard title='Novedades Arrendamientos' images={nodes} linkText='Ver más inmuebles' linkUrl='https://www.coninsa.co/inmuebles/arriendo' /> */}
    </co-section>
  )
}

export default Properties
