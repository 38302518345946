import React from 'react';

import AreaIcon from "./icons/area.inline.svg";
import PinIcon from "./icons/pin.inline.svg";
import BedIcon from "./icons/bed.inline.svg";
import BathIcon from "./icons/bath.inline.svg";
import StratumIcon from "./icons/stratum.inline.svg"
import StatusIcon from "./icons/status.inline.svg"
import CarIcon from "./icons/car.inline.svg"

import { clsx } from "clsx";
import "./styles.css";

function FeatureItem({ icon, label, className, ...props }) {
  const iconMap = {
    area: AreaIcon,
    pin: PinIcon,
    bed: BedIcon,
    bath: BathIcon,
    stratum: StratumIcon,
    status: StatusIcon,
    car: CarIcon,
  };

  const Icon = iconMap[icon] ? iconMap[icon] : () => null;

  return (
    <div className={clsx("s2-feature-item", className)}>
      <div className='s2-feature-item__label'>
        {label}
      </div>

      <div className='s2-feature-item__container'>
        <div className="s2-feature-item__icon">
          <Icon />
        </div>
        <div className="s2-feature-item__content">
          <p>{props.children}</p>
        </div>
      </div>
    </div>
  );
}

export default FeatureItem;
