import React from "react";
import Layout from "../../layout/default/Layout";
import { Router, navigate } from "@reach/router";

import { ProjectTermsAndConditionsPage } from "@coninsa-site/terms-and-conditions";


function ProjectLanding() {
  return (
    <Layout>
      <Router basepath="/terminos-y-condiciones/proyectos">
        <ProjectTermsAndConditionsPage path="*" navigate={navigate} />
      </Router>
    </Layout>
  );
}

export default ProjectLanding;
