export default [
  {
    id: 'item-1',
    number: 770,
    title: 'Proyectos construidos',
    subtitle: 'a nivel nacional',
    time: 1,
    start: 300,
    limit: 770,
  },
  {
    id: 'item-2',
    number: 13,
    measure: 'mm',
    title: 'De metros cuadrados',
    subtitle: 'construidos',
    time: 60,
    start: 3,
    limit: 13,
  },
  {
    id: 'item-3',
    number: 9,
    title: 'Sectores productivos',
    subtitle: 'del país',
    time: 70,
    start: 1,
    limit: 9,
  },
  {
    id: 'item-4',
    number: 50,
    title: 'Años de experiencia',
    subtitle: 'en construcción',
    time: 30,
    start: 15,
    limit: 50,
  },
]
