import React from "react";
import Layout from "layout/default/Layout";

import { LandingPage as TermsAndConditionsPage } from "@coninsa-site/terms-and-conditions";

function Landing() {
  return (
    <Layout>
      <TermsAndConditionsPage />
    </Layout>
  )
}

export default Landing;
