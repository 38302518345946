import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

import { Icon } from 'ui'

function CardInfo({ children, icon, ...props }) {
  const baseStyles = {
    border: '1px solid #ddd',
    px: 'small',
    py: 'medium',
    textAlign: 'center',
  }

  const iconStyles = {
    color: 'accent',
    fontSize: '25px',
  }

  return (
    <Box __css={baseStyles} {...props}>
      <Icon name={icon} sx={iconStyles} />
      {children}
    </Box>
  )
}

CardInfo.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.string.isRequired,
}

export default CardInfo
