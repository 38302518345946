import locations from "./locations.json";

const getKeyAsSelectOptions = (object) => {
  return Object.keys(object).map((name) => ({
    value: name,
    label: name,
  }));
};

export const getDepartments = () => {
  return getKeyAsSelectOptions(locations);
};

export const getCities = (department) => {
  if (!department) {
    return [];
  }

  return getKeyAsSelectOptions(locations[department]);
};

export const getNeighborhoods = (department, city) => {
  if (!city) {
    return [];
  }

  return (locations[department][city]).map((name) => ({
    value: name,
    label: name,
  }));
};