import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'

import { CURRENT_USER_CONTEXT_QUERY } from './queries'

export const AuthContext = React.createContext({ auth: {} })

export function AuthProvider(props) {
  const { loading, error, data } = useQuery(CURRENT_USER_CONTEXT_QUERY, {
    variables: { document },
    fetchPolicy: 'no-cache',
  })

  if (error) {
    console.error(error)
  }

  const clearUserData = () => {
    // Run a logout request.
  }

  const authProps = {
    isAuthenticated: !!data?.user?.mail,
    user: data?.user,

    clearUserData,
  }

  return (
    !loading && (
      <AuthContext.Provider value={authProps}>{props.children}</AuthContext.Provider>
    )
  )
}

AuthProvider.propTypes = {
  children: PropTypes.any,
}

export default AuthProvider
