import documentIcon from "../icons/pdf.svg";
import pageIcon from "../icons/window.svg";

export default [
  {
    id: "terms-2021-1",
    icon: documentIcon,
    label: "Plan Referidos Porteros 2021 Medellín",
    url: "https://coninsa-pro.s3.amazonaws.com/s3fs-public/2021-06/terminos-y-condiciones-referidos-medellin-2021-v1.pdf",
  },
  {
    id: "terms-2021-2",
    icon: documentIcon,
    label: "Plan Referidos Porteros 2021 Bogotá y Costa",
    url: "https://coninsa-pro.s3.amazonaws.com/s3fs-public/2021-06/terminos-y-condiciones-referidos-bog-costa-2021-v1.pdf",
  },
  {
    id: "terms-2021-3",
    icon: documentIcon,
    label: "Plan Referidos Inmobiliaria 2021",
    url: "https://coninsa-pro.s3.amazonaws.com/s3fs-public/2021-06/terminos-y-condiciones-referidos-2021-v2.pdf",
  },
  {
    id: "terms-2021-4",
    icon: pageIcon,
    label: "Anticipo de renta",
    url: "/terminos-y-condiciones/arrendamientos/anticipo-de-renta",
  },
];
