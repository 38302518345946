import React from "react";
import { useForm, Controller } from "react-hook-form";
import NumberFormat from "@coninsa-ui/number-format";
import IntlPhone from "@coninsa-s2/intl-phone";
import parsePhoneNumber from "libphonenumber-js";
import formStorage from "./storage/form";

import HabeasData from "./components/HabeasData";

export default function StepOne({ nextCallback }) {
  const {
    register,
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: async () => formStorage.get(),
  });

  const onSubmit = (data) => {
    formStorage.set(data);

    if (nextCallback) {
      nextCallback();
    }
  };

  return (
    <div className="co-card">
      <form className="co-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex gap-4 flex-col md:flex-row">
          <div className="form-control is-required flex-1">
            <label htmlFor="names">Nombres</label>
            <input
              {...register("user.names")}
              type="text"
              id="names"
              required
            />
          </div>
          <div className="form-control is-required flex-1">
            <label htmlFor="last_name">Apellidos</label>
            <input
              {...register("user.last_name")}
              type="text"
              id="last_name"
              required
            />
          </div>
        </div>

        <div className="flex gap-4 flex-col md:flex-row">
          <div className="form-control is-required flex-1">
            <label htmlFor="document_type">
              Selecciona el tipo de documento
            </label>
            <select
              {...register("user.document_type")}
              id="document_type"
              required
            >
              <option value="">Selecciona una opción</option>
              <option value="cc">CC</option>
              <option value="ce">CE</option>
              <option value="lm">LM</option>
              <option value="pa">PA</option>
              <option value="rc">RC</option>
              <option value="vi">VI</option>
              <option value="ti">TI</option>
            </select>
          </div>
          <div className="form-control is-required flex-1">
            <label htmlFor="document">Número de documento</label>
            <input
              {...register("user.document")}
              type="number"
              id="document"
              required
            />
          </div>
        </div>

        <div className="flex gap-4 flex-col md:flex-row">
          <div className="form-control is-required is-intl-tel flex-1">
            <label htmlFor="phone">Número de celular</label>
            <Controller
              control={control}
              name="user.phone"
              rules={{
                validate: (value) =>
                  parsePhoneNumber(value)?.isValid() || "Invalid phone number",
              }}
              render={({ field: { onChange, value } }) => (
                <IntlPhone
                  id="phone"
                  defaultCountry={"co"}
                  onChange={onChange}
                  required
                  value={value}
                />
              )}
            />

            {errors?.user?.phone && (
              <p className="form-error">{errors?.user?.phone?.message}</p>
            )}
          </div>

          <div className="form-control is-required flex-1">
            <label htmlFor="email">Correo electrónico</label>
            <input
              {...register("user.email")}
              type="email"
              id="email"
              required
            />
          </div>
        </div>

        <div className="flex gap-4 flex-col md:flex-row">
          <div className="form-control is-required flex-1">
            <label htmlFor="occupation">Ocupación</label>
            <select {...register("user.occupation")} id="occupation" required>
              <option value="">Selecciona una opción</option>
              <option value="Ama de Casa">Ama de Casa</option>
              <option value="Empleado">Empleado</option>
              <option value="Empleado Socio">Empleado Socio</option>
              <option value="Independiente">Independiente</option>
              <option value="Estudiante">Estudiante</option>
              <option value="Pensionado">Pensionado</option>
              <option value="Desempleado">Desempleado</option>
              <option value="Empleado Pensionado">Empleado Pensionado</option>
            </select>
          </div>
          <div className="form-control is-required flex-1">
            <label htmlFor="income">¿Cuáles son tus ingresos familiares?</label>
            <Controller
              control={control}
              name="user.income"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <NumberFormat
                  mask="_"
                  thousandSeparator="."
                  decimalSeparator=","
                  onBlur={onBlur}
                  onChange={onChange}
                  required
                  prefix={"$"}
                  value={value}
                />
              )}
            />
          </div>
        </div>
        <div className="form-group">
          <HabeasData />
          <div className="form-control is-checkbox">
            <input
              type="checkbox"
              className="checkbox-sign accent-co-dark-cyan"
              id="accept_privacy_police"
              {...register("accept_privacy_police")}
              required
            />
            <label htmlFor="accept_privacy_police">
              <span>Autorizo el tratamiento y la </span>
              <a
                href="https://www.coninsa.co/politica-de-tratamiento-de-datos-personales-de-coninsa-ramon-h-sa"
                target="_blank"
                className="text-co-dark-cyan cursor-pointer font-medium no-underline"
              >
                política de datos personales
              </a>
            </label>
          </div>
        </div>
        <div className="form-actions flex justify-end">
          <button className="co-button is-progress-default is-large">
            Continuar
          </button>
        </div>
      </form>
    </div>
  );
}
