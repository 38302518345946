import React from 'react'
import { Box ,Image,Grid, Button, Container } from 'theme-ui'
import Layout from '../../layout/default/Layout'
import Banner from 'ui/Banner'
import bannerImage from '../../images/portalalianzas-banner.png'
import { paragraphResolver } from '../../paragraphs'
import IntegrateZohoForm from './forms/IntegrateZohoForm'
import whatsapp from '../../images/whatsapp_icon.svg'
const InterPage = ({ pageContext }) => {

  const Slider = {
    sections: [{
        type: 'section',
        header: {
          title: 'LO QUE DICEN TUS COMPAÑEROS',
        },
        variant: 'bluey',
        content: [
          {
            type: 'slider',
            variant: 'default',
            slidesToShow: [1, 1],
            items: [
              {
                type: 'slide',
                icon: '',
                title: 'Alexander Cruz Páez',
                description:
                  'Fue muy satisfactoria la compra de nueva casa con Coninsa, gracias a este descuento y a la facilidad de sus trámites. Los recomiendo abiertamente.',
              },
              {
                type: 'slide',
                icon: '',
                title: 'Yessica Dev',
                description:
                  'Fue muy satisfactoria la compra de nueva casa con Coninsa, gracias a este descuento y a la facilidad de sus trámites. Los recomiendo abiertamente.',
              }
            ],
          },
        ],
      }]
}

    const BannerStyles = {
        backgroundImage: `url(${bannerImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        height: '250px',
        position: 'relative',
      }

    const BoxStyle = {
        boxShadow: '0 3px 6px #00000029',
        borderRadius: "10px",
        position: 'absolute',
        top: 150,
    }

    const ButtonStyle = {
      boxShadow: '0 3px 6px #00000029',
      borderRadius: "10px",
      color: '#005FE2',
      bg: 'white',
      position: 'absolute',
      top: 220,
    }

    const Title ={
      'h1':{
        color:"#273475"
      },
      'hr':{
        borderColor:"#F2F2F2",
        bg:"#F2F2F2",
        height: "1px"

      }
    }

    const button ={
      bg:"#00993B",
      color: 'white',
      width: '100%',
      mt: "10px"
    }

    const LinkStyle ={
      'h3':{
        color: '#00993B'
      },
      'li::marker':{
        color: '#00993B'
      }
    }
    const form = {
      bg: "#F2F2F2",
      p: "40px"
    }

    const responsiveContent = {
      '@media screen and (max-width: 576px)': {
        'display':'block'
      },
    }

  // Prevent render on SSR.
  if (typeof window === 'undefined') return null
  const whatsapp_asesor = "https://api.whatsapp.com/send?phone=57"

  return (

      <Layout>
        <Box bg='white' py='large' px='medium'>
            <Banner sx={BannerStyles}>
            <Box>
              <Grid gap={1} columns={[3, '1fr 1fr 1fr']}>
                <Box><Button sx={ButtonStyle} onClick={() => window.history.back()}>Regresar</Button></Box>
                <Box><Image sx={BoxStyle} src={pageContext.logo} /></Box>
                <Box></Box>
              </Grid>
            </Box>
        </Banner>
        </Box>
        <Box bg='white' py='large' px='medium'>
            <Container sx={Title}>
            <h1>{pageContext.name}</h1>
            <hr></hr>
              <Grid sx={responsiveContent} gap={1} columns={[2, '2fr 1fr']}>
                <Box sx={LinkStyle}>
                  <div dangerouslySetInnerHTML={{ __html: pageContext.descripcion }}></div>
                  <h3>Términos y condiciones</h3>
                  <ul>
                    <li>{pageContext.terminos[0].termino_1}</li>
                    <li>{pageContext.terminos[0].termino_2}</li>
                    <li>{pageContext.terminos[0].termino_3}</li>
                  </ul>
                </Box>
                <Box sx={form}>
                  <IntegrateZohoForm
                    convenio={pageContext.name}
                    />
                  <Box>
                  <a href={whatsapp_asesor + pageContext.asesor}><Button sx={button}>
                    <Image src={whatsapp}/> Contactar a un asesor
                    </Button>
                  </a>
                  </Box>
                </Box>
              </Grid>
            </Container>
        </Box>
        {Slider.sections.map(section => paragraphResolver(section))}
      </Layout>
  )
}

export default InterPage