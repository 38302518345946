import { gql } from '@apollo/client'

export const NATURAL_COTENANT_APPLICATION = gql`
query NaturalCotenantApplicationNode($id: String!) {
  node: nodeById(id: $id) {
    bundle: entityBundle
    
    ... on NodeNaturalPersonCoTenant {
      id: nid
      title
      fieldIndependentActivity
      fieldAssets
      fieldLastName
      fieldSelfRetainer
      fieldPosition
      fieldSpousePosition
      fieldSpouseCellPhone
      fieldLaborCertification {
        targetId
        display
        description
        entity {
          fid
          filename
          url
        }
      }
      fieldSpouseCity {
        department
        city
        neighborhood
      }
      fieldCityBirth {
        department
        city
        neighborhood
      }
      fieldOfficeCity
      fieldIsCustomer
      fieldHasInternationalAccount
      fieldMegaDiscountLocation {
        department
        city
        neighborhood
      }
      fieldUbication {
        department
        city
        neighborhood
      }
      fieldPersonalAddress
      fieldPropertyAddress
      fieldOfficeAddress
      fieldAddress
      fieldFiscalAddress
      fieldSpouseOfficeAddress
      fieldIdentificationDocument {
        targetId
        display
        description
        entity {
          fid
          filename
          url
        }
      }
      fieldExpenses
      fieldEmail
      fieldInternalEmployee
      fieldWorkCompany
      fieldSpouseWorkCompany
      fieldMaritalStatus
      fieldFinancialState {
        targetId
        display
        description
        entity {
          fid
          filename
          url
        }
      }
      fieldBankStatements {
        targetId
        display
        description
        entity {
          fid
          filename
          url
        }
      }
      fieldFamilyPubliclyRecognized
      fieldExpeditionDate {
        value
        date
      }
      fieldAdmissionDate {
        value
        date
      }
      fieldDateBirth {
        value
        date
      }
      fieldGreatTaxpayer
      fieldGender
      fieldIncome
      fieldExpeditionPlace {
        department
        city
        neighborhood
      }
      fieldHandlesForeignCurrency
      fieldManagePublicResources
      fieldBrand
      fieldPropertyRegistration
      fieldModel
      fieldPersonalName
      fieldSpouseFullName
      fieldFirstName
      fieldCellPhoneNumber
      fieldAccountNumber
      fieldSpouseIdNumber
      fieldIdNumber
      fieldOccupation
      fieldPersonalRelationship
      fieldLiabilities
      fieldDependents
      fieldLicensePlate
      fieldProfession
      fieldPubliclyRecognized
      fieldInternationalOperations
      fieldBankReferences {
        entity
        accountNumber
        branchOffice
        accountType
      }
      fieldCommercialReferences {
        store
        phone
      }
      fieldFamilyReferences {
        address
        name
        relationship
        phone
      }
      fieldIcaResponsible
      fieldIncomeResponsible
      fieldSalesResponsible
      fieldSalary
      fieldSpouseSalary
      fieldPublicServer
      fieldPersonalPhone
      fieldFiscalPhone
      fieldSpouseOfficePhone
      fieldHasPropertyMortgage
      fieldHasGarment
      fieldContractType
      fieldIdType
      fieldSpouseId
      fieldBusiness
    }
  }
}
`