import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

import Hero from 'ui/Hero'
import { Header, Video } from 'ui'

function PHero({ body, title, variant, video }) {
  const baseStyles = {
    alignItems: 'center',
    display: ['block', 'flex'],
    width: '100%',
  }

  const hasContent = !!body

  return (
    <Hero hasContent={hasContent}>
      <Header sx={{ mb: ['4rem', 'xlarge'] }} variant={variant}>
        {title}
      </Header>

      {body && (
        <Hero.Content sx={{ position: 'relative', zIndex: 1 }}>
          <Box __css={baseStyles}>
            <Box dangerouslySetInnerHTML={{ __html: body }} />
            {video?.url && (
              <Video
                sx={{
                  flexShrink: 0,
                  ml: ['-24px', 'medium'],
                  mr: ['-24px', 0],
                  mb: ['-24px', 0],
                  position: 'relative',
                  width: ['auto', '514px'],
                  height: ['auto', '306px'],
                }}
                url={video.url}
                controls
                variant='featured'
              />
            )}
          </Box>
        </Hero.Content>
      )}
    </Hero>
  )
}

PHero.defaultProps = {
  body: '',
  variant: 'default',
  video: {},
}

PHero.propTypes = {
  body: PropTypes.string,
  title: PropTypes.string.isRequired,
  variant: PropTypes.string,
  video: PropTypes.shape({
    url: PropTypes.string,
  }),
}

export default PHero
