import { gql } from '@apollo/client'

  export const SEARCH_PROJECTS_QUERY = gql`
  query SearchProjects($text: String!) {
    search: searchAPISearch(
      index_id: "search_projects"
      facets: [
        {
          field: "department_project"
          min_count: 1
          missing: true
          operator: "="
          limit: 1000
        }
        {
          field: "property_type"
          min_count: 1
          missing: false
          operator: "="
          limit: 100
        }
      ]
      conditions: [
        { name: "field_final_publication", value: "1", operator: "=" }
        { name: "field_for_sale", value: "1", operator: "=" }
        { name: "field_unpublish_permanently", value: "1", operator: "<>" }
      ]
      fulltext: { keys: [$text] }
    ) {
      result_count
      facets {
        name
        values {
          count
          filter
        }
      }
    }
  }
`
