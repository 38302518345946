import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../layout/default/Layout'
import ContactForm from '../component-v2/ContactformPayment'

function BussinessEthic() {
  const showBodyCard = bodyCardId => event => {
    const bodyElements = document.querySelectorAll('.tab-body')
    const tabElements = document.querySelectorAll('.tab-label[data-active]')

    bodyElements.forEach(element => {
      element.classList.add('hidden')
    })

    tabElements.forEach(element => {
      delete element.dataset.active
    })

    const buttonElement = event.currentTarget

    buttonElement.dataset.active = true

    // Look for body card element.
    const bodyElement = document.querySelector(bodyCardId)

    if (bodyElement) {
      bodyElement.classList.remove('hidden')
    }
  }

  return (
    <Layout>
      <Helmet>
        <title>Nau Hosue Apartamentos Reservas | Coninsa</title>
        <meta name="description" content="Reserva y asegura tu apartamento en Nau House Apartamentos, el proyecto de vivienda ideal en una ubicación privilegiada. Realiza pagos en línea de forma rápida y segura para garantizar tu futuro hogar. Descubre un espacio moderno, cómodo y accesible en el que vivirás experiencias únicas. Reserva hoy mismo y da el primer paso hacia el hogar de tus sueños." />
        <link rel="canonical" href="https://www.coninsa.co/nau-house-pago-de-reservas/" />
      </Helmet>

      <div className='bg-coninsa-green-300 pb-10 pt-10 md:pt-16 md:pb-16 mt-10'>
        <div className='container px-5 md:px-0 text-center mb-8'>
          <h3 className='font-display text-white text-[28px] md:text-[33px]'>
            Reserva tu espacio en Nau House Apartamentos
          </h3>
          <p className='text-white text-base md:text-lg mb-4'>
            Completa el siguiente formulario para asegurar tu reserva en el proyecto Nau House. 
            Acepta la política de tratamiento de datos de www.coninsa.co al proporcionar tu información.
          </p>
          <div className='border border-solid border-white w-[302px] my-0 mx-auto'></div>
        </div>
      </div>

      <div className='bg-white relative pb-10 md:pb-16'>
        <div className='absolute bg-coninsa-green-300 h-48 left-0 right-0 top-0'></div>
        <div className='container relative'>
          <div className='px-5 md:px-40'>
            <div className='bg-white rounded-lg opacity-100 p-6 md:p-10 shadow-[0px_3px_6px_#00000029]'>
              <p className='text-base md:text-lg text-center mb-4 font-medium'>
                Ingrese la información solicitada para completar su reserva.
              </p>
              <p className='text-sm text-gray-600 text-center mb-6'>
                Los datos proporcionados serán utilizados exclusivamente para gestionar su reserva en el proyecto Nau House Apartamentos.
              </p>
              <ContactForm />
            </div>
          </div>
          <p className='text-xs text-gray-500 text-center mt-4'>
            El proceso de recaudo para la reserva en el proyecto Nau House Apartamentos es gestionado por Csoft Cloud Technology, 
            aliado estratégico de Coninsa SAS, en calidad de facilitador de pagos en línea. Al completar el formulario, 
            usted acepta que su información será utilizada exclusivamente para la gestión de su reserva en este proyecto y que los pagos 
            serán procesados de manera segura a través de las plataformas habilitadas (ePayco) por Csoft Cloud Technology.
          </p>
          <p className='text-xs text-gray-500 text-justify mt-4'>
            ANTICIPO DE GASTOS VINCULACIÓN PROYECTO NAU HOUSE.
            La suma de un millón de pesos m/cte ($2.000.000) que será transferida a través del link
            suministrado, corresponde a un anticipo de gastos inmobiliarios para el Proyecto Nau House, respecto del cual estoy interesado. 
            Conozco y acepto que el PROYECTO NAU HOUSE se desarrolla a través de un
            esquema fiduciario administrado por Alianza Fiduciaria S.A.
            Me obligo a surtir el trámite de vinculación al Fideicomiso en un término no superior a
            quince (15) días calendario, cumpliendo con los requisitos exigidos por Alianza Fiduciaria S.A.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default BussinessEthic
