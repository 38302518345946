import React, { useState } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { compose, pathOr, map } from 'ramda'
import { Helmet } from 'react-helmet'
import POurProjects from 'paragraphs/POurProjects'
import { ourProjectTeaserMapper } from '../mappers/ourProjectsMapper'

import { layoutResolver } from '../layout'

import bgGreen from '../modules/microsites/assets/images/bg-green.jpg'

function Button({children, ...props}) {
  return (
    <button
      className='border border-coninsa-green-500 font-semibold px-8 py-2 rounded-full
        text-coninsa-green-500 hover:bg-coninsa-green-500 hover:text-white
        aria-selected:bg-coninsa-green-500 aria-selected:text-white'
      {...props}
    >
      {children}
    </button>
  )
}

function OurProjectsTemplate({ data, ...props }) {
  const { layout, name } = props?.pageContext
  const Layout = layoutResolver(layout)
  const defaultProjects = compose(
    map(ourProjectTeaserMapper),
    map(entity => ({ entity })),
    pathOr([], ['drupal', 'projects', 'entities'])
  )(data);

  const [projects, setProjects] = useState(defaultProjects);

  const handleClick = (region) => (event) => {
    const button = event.target;

    if (!button.hasAttribute('aria-selected')) {
      const filteredProjects = defaultProjects.filter(project => project?.location?.includes(region));
      setProjects(filteredProjects);

      const buttons = document.querySelectorAll('#region-filter button')

      buttons.forEach(element => {
        element.removeAttribute('aria-selected')
      });

      button.setAttribute('aria-selected', 'true');
    } else {
      setProjects(defaultProjects);
      button.removeAttribute('aria-selected');
    }
  }

  return (
    <Layout>
      <Helmet>
        <title>{name} | Coninsa</title>
        <meta name="description" content="Descubre los proyectos de arquitectura y construcción de Coninsa en Colombia. Ofrecemos soluciones innovadoras y sostenibles en diseño arquitectónico, construcción de viviendas, edificios comerciales e infraestructura. Confía en nuestra experiencia y compromiso con la calidad para llevar a cabo tus proyectos con éxito." />
      </Helmet>
      <div
        className='bg-coninsa-green-900 py-10 md:py-16'
        style={{ backgroundImage: `url(${bgGreen})` }}
      />
      <div className='container px-5 md:px-0'>
        <h1
          className='bg-coninsa-green-500 font-display inline-block leading-7 text-3xl md:leading-[44px] md:text-[44px]
          text-white uppercase px-6 py-3 md:py-5 rounded-lg transform -translate-y-11  md:-translate-y-20'
        >
          {name}
        </h1>

        <div className='mb-6' id='region-filter'>
            <p className='font-normal mb-3 text-center'>Filtrar por ubicación</p>
            <div className='flex gap-2 justify-end'>
              <Button onClick={handleClick('Antioquia')}>
                Antioquia
              </Button>

              <Button onClick={handleClick('Cundinamarca')}>
                Cundinamarca
              </Button>

              <Button onClick={handleClick('Atlántico')}>
                Atlántico
              </Button>

              <Button onClick={handleClick('Magdalena')}>
                Magdalena
              </Button>
            </div>
          </div>

        <div className='mb-12'>
          {projects?.length > 0 && (<POurProjects projects={projects} openNewTab />)}
          {projects?.length === 0 && (<p className='text-lg text-coninsa-blue-900 text-center py-6'>No se encontraron proyectos para esta región.</p>)}
        </div>
      </div>
    </Layout>
  )
}

OurProjectsTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
  pageContext: PropTypes.shape({
    layout: PropTypes.shape({}),
    name: PropTypes.string,
  }).isRequired,
}

export const pageQuery = graphql`
  query teaserOurProjects($id: String!, $microsite: String!) {
    drupal {
      projects: nodeQuery(
        filter: {
          conditions: [
            { operator: EQUAL, field: "status", value: ["1"] }
            { operator: EQUAL, field: "type", value: ["our_project"] }
            { operator: IN, field: "field_type", value: [$id] }
            { operator: IN, field: "field_microsite", value: [$microsite] }
          ]
        }
        sort: [{ field: "field_weight", direction: DESC }]
        limit: 10000
      ) {
        entities {
          id: entityId
          ...TeaserNodeOurProject
        }
      }
    }
  }
`

export default OurProjectsTemplate
