import React from 'react'
import PropTypes from 'prop-types'

function BuildingCard({ url, target, image, location, name }) {

  return (
    <a
      href={url}
      target={target}
      className='bg-white rounded-b-lg shadow-[0px_3px_6px_#00000029]'
    >
      <div>
        <img src={image} className='rounded-t-lg' />
      </div>
      <div className='px-4 pb-4 pt-7'>
        <div className='border-t-[6px] border-coninsa-green-400 my-0 mx-auto rounded w-28 transform -translate-y-8' />
        <p className='text-coninsa-blue-900'>{location}</p>
        <h4 className='font-bold text-xl text-coninsa-blue-900'>{name}</h4>
      </div>
    </a>
  )
}

export default BuildingCard

BuildingCard.propTypes = {
  image: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}
