import React from "react";

interface T_props {
  loading: boolean;
  itemsCount: number;
  resultCount: number;
}

const SearchSummary: React.FC<T_props> = ({ loading, itemsCount, resultCount }) => {
  return (
    <div className="s2-search-summary">
      <div className="text-sm">
        {!loading && (
          <div>
            {resultCount > 1 && (
              <p>
                <span className="hidden md:inline-flex">Estas viendo</span>{" "}
                <strong> {itemsCount} </strong> de <strong>{resultCount}</strong>{" "}
                resultados
              </p>
            )}
            {resultCount === 1 && (
              <p>
                <span className="hidden md:inline-flex">Estas viendo</span>{" "}
                <strong> {itemsCount} </strong> de <strong>{resultCount}</strong>{" "}
                resultado
              </p>
            )}
            {resultCount === 0 && <p>No hay resultados</p>}
          </div>
        )}

        {loading && <p>Cargando ...</p>}
      </div>
    </div>
  );
};

export default SearchSummary;
