import TechOne from '../assets/images/tech-one.jpg'
import TechTwo from '../assets/images/tech-two.jpg'
import TechThree from '../assets/images/tech-three.jpg'
import TechFour from '../assets/images/tech-four.jpg'
import TechFive from '../assets/images/tech-five.jpg'

export default [
  {
    photo: TechOne,
    firstName: 'Daniel',
    lastName: 'Hoyos Ariztizábal',
    position: 'Director Técnico Nacional',
    linkedin: '#'
  },
  {
    photo: TechTwo,
    firstName: 'Clara Inés',
    lastName: 'Meza López',
    position: 'Directora de Estructuración Técnica y Constructiva de Proyectos',
    linkedin: '#'
  },
  {
    photo: TechThree,
    firstName: 'Marie',
    lastName: 'Lorena Vergara',
    position: 'Directora Propuestas y Presupuestos Medellín',
    linkedin: '#'
  },
  {
    photo: TechFour,
    firstName: 'Jaime',
    lastName: 'Rodriguéz',
    position: 'Director Propuestas y Presupuestos Bogotá y Caribe',
    linkedin: '#'
  },
  {
    photo: TechFive,
    firstName: 'Mario',
    lastName: 'León Hincapié',
    position: 'Coordinador Estructuración Técnica y Constructiva',
    linkedin: '#'
  }
]