import GoalkeeperReferralPlan from "./pages/goalkeeper-referral-plan";
import LeasesWithoutCoTenant from "./pages/leases-without-co-tenant";
import OwnersLeaseFair from "./pages/owners-lease-fair";
import ColombiansAbroad from "./pages/colombians-abroad";
import TellEveryoneAndWinReferralPlan from "./pages/tell-everyone-and-win-referral-plan";
import GlobalCampaign from "./pages/global-campaign";
import RealEstateCrhReferralPlan from "./pages/real-estate-crh-referral-plan";
import NeighborsAndFriendsReferralPlan from "./pages/neighbors-and-friends-referral-plan";
import ConsignathonPromotion from "./pages/consignathon-promotion";
import RealEstateReferralPlan from "./pages/real-estate-referral-plan";
import VirtualFairHousingProjects from "./pages/virtual-fair-housing-projects";

export const GoalkeeperReferralPlanPage = GoalkeeperReferralPlan;
export const LeasesWithoutCoTenantPage = LeasesWithoutCoTenant;
export const OwnersLeaseFairPage = OwnersLeaseFair;
export const ColombiansAbroadPage = ColombiansAbroad;
export const TellEveryoneAndWinReferralPlanPage =
  TellEveryoneAndWinReferralPlan;
export const GlobalCampaignPage = GlobalCampaign;
export const RealEstateCrhReferralPlanPage = RealEstateCrhReferralPlan;
export const NeighborsAndFriendsReferralPlanPage =
  NeighborsAndFriendsReferralPlan;
export const ConsignathonPromotionPage = ConsignathonPromotion;
export const RealEstateReferralPlanPage = RealEstateReferralPlan;
export const VirtualFairHousingProjectsPage = VirtualFairHousingProjects;
