import React from 'react'
import { navigate } from 'gatsby'

export default function ThankYou() {
  const redirectTo = () => navigate('/estudio-digital/solicitud')

  return (
    <div className='container py-16'>
      <div className='text-center mb-7'>
        <h4 className='text-2xl text-coninsa-blue-900 font-bold mb-3'>
          ¡Tu solicitud de arrendamiento se ha enviado con éxito!
        </h4>
        <p className='text-lg'>Gracias por confiar en nosotros y permitirnos ser parte en la búsqueda de tu inmueble.</p>
        <p className='text-lg'>Esperamos ser tu primera opción de arrendamientos.</p>
      </div>
      <div className='text-center'>
        <button onClick={redirectTo} className='bg-coninsa-yellow-300 font-bold text-coninsa-blue-900
          px-6 py-3 rounded-[4px] uppercase hover:bg-coninsa-blue-900 hover:text-coninsa-yellow-300'
        >
          Volver al inicio
        </button>
      </div>
    </div>
  )
}