import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button } from 'theme-ui'

import { Icon } from 'ui'

function BuildingMobileFloatingBar({ buildindType, label, path }) {
  const baseStyles = {
    bg: 'white',
    borderRadius: ['none', '8px'],
    boxShadow: '0px -3px 6px #00000029',
    display: ['flex', 'none'],
    justifyContent: 'space-between',
    opacity: '1',
    p: 'small',
  }

  const infoStyles = {
    color: 'neutral',
    fontFamily: 'heading',
    fontSize: 2,
    fontWeight: 'heading',
    height: '20px',
    lineHeight: 1.8,
    textTransform: 'uppercase',
  }

  const buttonStyles = {
    bg: 'green-1',
    color: 'white',
    fontWeight: 'body',
    fontSize: 2,
    lineHeight: 0,
    textTransform: 'inherit',
  }

  return (
    <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1 }}>
      <Box sx={baseStyles}>
        <Box>
          <Box sx={infoStyles}>Quiero más información</Box>
          <Box sx={{ fontSize: 1 }}>Sobre este {buildindType}</Box>
        </Box>

        <Box sx={{ color: 'white', display: 'flex' }}>
          <Button as='a' sx={buttonStyles} href={path} target='_blank'>
            <Icon
              name='fa-whatsapp'
              sx={{ fontSize: 3, mr: 1, verticalAlign: 'middle' }}
            />
            {label}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

BuildingMobileFloatingBar.propTypes = {
  buildindType: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
}

export default BuildingMobileFloatingBar
