import React from "react";

import Layout from "../../layout/default/Layout";
import { Helmet } from "react-helmet";
import DataProcessingPolicy from "@coninsa-site/data-processing-policy";

export default function Landing() {
  return (
    <Layout>
      <Helmet>
        <title>Política de tratamiento de datos personales | Coninsa</title>
        <meta
          name="description"
          content="Consulta nuestra Política de Tratamiento de Datos Personales. Conoce cómo recopilamos, utilizamos y protegemos tu información, cumpliendo con las normativas de privacidad y garantizando tus derechos."
        />
        <link
          rel="canonical"
          href="https://www.coninsa.co/politica-de-tratamiento-de-datos-personales-de-coninsa"
        />
      </Helmet>
      <DataProcessingPolicy />
    </Layout>
  );
}
