import React from 'react'
import { Grid, Input, Select } from 'theme-ui'

import Control from '../../components/Control'

import {
  getOptions,

  // Options
  activities,
  occupations,
} from '../options'

function BasicOccupation({ register }) {
  return (
    <Grid gap={4} columns={[2, '1fr 1fr']}>
      <Control label='Ocupación'>
        <Select {...register('fieldOccupation')}>
          {getOptions(occupations).map(option => (
            <option value={option.value}>{option.label}</option>
          ))}
        </Select>
      </Control>

      <Control label='Actividad independiente (no profesional)'>
        <Select {...register('fieldIndependentActivity')}>
          {getOptions(activities).map(option => (
            <option value={option.value}>{option.label}</option>
          ))}
        </Select>
      </Control>

      <Control label='Cargo'>
        <Input {...register('fieldPosition')} type='text' />
      </Control>
      <Control label='Salario $' helpText='(ingresar solo números sin puntos y sin comas)'>
        <Input {...register('fieldSalary')} type='text' />
      </Control>
    </Grid>
  )
}

export default BasicOccupation
