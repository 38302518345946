import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import IntlTelInput from "react-intl-tel-input-18";
import "react-intl-tel-input-18/dist/main.css";
import parsePhoneNumber from "libphonenumber-js";

import { CREATE_ADVERTISER_USER } from "../graphql/mutations";

export default function Auth0({ onSuccess }) {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const [phone, setPhone] = useState();
  const [mutation] = useMutation(CREATE_ADVERTISER_USER);

  const onChange = (_, number, country) => {
    const phoneNumber = parsePhoneNumber(number, country.iso2);

    if (phoneNumber && phoneNumber?.isValid()) {
      setPhone(phoneNumber.number);
    }
  };

  const onSubmit = async () => {
    const response = await mutation({ variables: { phone } });
    const status = response?.data?.createAdvertiserUser?.status;
    const type = response?.data?.createAdvertiserUser?.type;
    const message = response?.data?.createAdvertiserUser?.message;

    window.localStorage.setItem("advertiser_phone", phone);

    if (status === "fail") {
      setError("phone", { message, type });
    } else {
      onSuccess();
    }
  };

  return (
    <div className="co-card short-form-container">
      <h4 className="co-heading-4">Primero, ingresa tu número móvil</h4>
      <p className="text-sm md:text-base">
        No dudamos que seas tu, pero te enviaremos un código de seguridad de 6
        dígitos a tu WhatsApp para confirmar tu número móvil y así crear tu
        cuenta.
      </p>

      <form className="co-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-control is-intl-tel">
          <IntlTelInput
            {...register("phone")}
            id="phone"
            placeholder="Número móvil"
            defaultCountry={"co"}
            onPhoneNumberChange={onChange}
            telInputProps={{ required: true }}
          />
          {errors?.phone?.type === "DUPLICATED_USER" && (
            <p className="form-error">
              El usuario ya ha sido registrado, por favor revisa tu teléfono
              para obtener tu código de verificación.{" "}
              <a onClick={onSuccess} className="text-co-teal cursor-pointer">
                Continuar
              </a>
            </p>
          )}
        </div>

        <button className="co-button is-progress is-large" disabled={!phone}>
          Recibir código por SMS
        </button>
      </form>

      <p className="agreement">
        Al continuar aceptas nuestros{" "}
        <a href="#" target="_blank">
          Términos y condiciones
        </a>{" "}
        y la{" "}
        <a href="#" target="_blank">
          Política de datos personales
        </a>
      </p>
    </div>
  );
}
