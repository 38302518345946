import React from "react";
import clsx from "clsx";

import "./styles.css";

function FacetItem({ children }) {
  return <div className={clsx("s2-facet-item")}>{children}</div>;
}

export default FacetItem;
