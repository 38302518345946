import React from 'react'
import { Box, Button } from 'theme-ui'

import { Icon } from 'ui'

function FileList({ items = [], onRemove }) {
  const baseStyles = {
    borderCollapse: 'collapse',

    thead: {
      textAlign: 'left',
    },

    'tbody td': {
      py: 'xsmall',
    },

    th: {
      py: 'small',
    },
  }

  return (
    <Box>
      <Box as='table' width='100%' __css={baseStyles}>
        <thead>
          <tr>
            <th>Información de archivo</th>
            <th>Operaciones</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={item.fid}>
              <td>
                <Icon name='fa-reg-file' mr='xxsmall' />
                <a href={item.url} target='_blank' rel='noreferrer'>
                  {item.filename}
                </a>
              </td>
              <td>
                <Button
                  type='button'
                  sx={{ fontSize: 1, px: 'small', py: 'xsmall' }}
                  onClick={onRemove(index)}
                >
                  Eliminar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Box>
    </Box>
  )
}

export default FileList
