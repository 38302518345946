import { mapResolver } from '.'

function horizontalSectionMapper({ title, headline, content, ...entity }) {
  return {
    type: 'horizontal_section',
    header: {
      title,
      headline,
    },
    content: content.map(mapResolver),
    ...entity,
  }
}

export default horizontalSectionMapper
