import React from 'react'
import * as Tabs from '@radix-ui/react-tabs'
import TabCard from './TabCard'
import '../../../../radix-ui/tabs.css'

const string = require('string')

const cleanName = name => {
  const rawName = name.toLowerCase().replace(':', '')
  const cleanedName = string(rawName).slugify().dasherize().s

  return cleanedName
}

function cleanProjects(rows = [], baseCategoryUrl) {
  return rows.map(row => {
    const categoryName = row?.type?.[0]?.entity?.name
    const categoryUrl = `${baseCategoryUrl}/${cleanName(categoryName)}`

    return {
      id: row.id,
      image: row.images?.[0]?.entity?.fieldMediaImage?.url,
      category: {
        name: row?.type?.[0]?.entity?.name,
        id: row?.type?.[0]?.entity?.tid,
      },
      title: row?.title,
      area: parseFloat(row?.area),
      year: parseInt(row?.year),
      url: categoryUrl,
    }
  })
}

function SummaryProjects({ data, baseCategoryUrl }) {
  const projects = cleanProjects(data?.ourProjects?.entities ?? [], baseCategoryUrl)
  const summaryTerms = data?.summaryTerms?.results

  return (
    <Tabs.Root className='co-tabs z-10' defaultValue={`tab-${projects[0]?.id}`}>
      <div className='block md:flex md:items-center'>
        <div className='bg-coninsa-green-500 rounded-t-lg md:rounded-t-none md:rounded-l-lg'>
          <Tabs.List
            className='co-tabs-horizontal-list'
            aria-label='Manage your account'
          >
            {projects?.map(project => (
              <Tabs.Trigger
                key={project?.id}
                className='co-tabs-horizontal-trigger'
                value={`tab-${project?.id}`}
              >
                {project?.category?.name}
              </Tabs.Trigger>
            ))}
          </Tabs.List>
        </div>

        {projects?.map(project => (
          <Tabs.Content
            key={project?.id}
            className='co-tabs-content'
            value={`tab-${project?.id}`}
          >
            <TabCard
              projectName={project?.title}
              location='Medellín, Antioquia'
              area={project?.area}
              year={project?.year}
              image={project?.image}
              url={project?.url}
              displayUrl={summaryTerms.find(
                term => term?.id == project?.category?.id && parseInt(term?.count) > 1
              )}
            />
          </Tabs.Content>
        ))}
      </div>
    </Tabs.Root>
  )
}

export default SummaryProjects
