import { graphql } from 'gatsby'
import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import iframeResize from 'iframe-resizer/js/iframeResizer'
import { Helmet } from 'react-helmet'

import { mapResolver } from '../mappers'
import Layout from '../layout/default/Layout'
import { paragraphResolver } from '../paragraphs'

const SITE_URL = 'https://busquedas.coninsa.co'

function GenericTemplate({ data, ...props }) {
  useEffect(() => {
    const iframe = document.querySelector('.webform-container iframe')

    if (iframe) {
      iframeResize(iframe)
    }
  }, [])

  const node = data?.drupal?.node
  const basicHero = mapResolver(node?.hero)
  const formMachineName = (node?.form?.id ?? '').replaceAll('_', '-')
  const description = node?.body?.value
  const url = node.url.path
  const canonical = `https://www.coninsa.co${url}/`;

  const section = {
    type: 'section',
    content: [
      {
        type: 'Drupal_ParagraphRichText',
        body: node?.body,

        // Fix basic hero image overlaping.
        sx: {
          '&:before': {
            content: '""',
            display: 'block',
            width: '320px',
            height: [0, '50px'],
            float: 'right',
          },
        },
      },
    ],
  }

  const form = {
    type: 'webform',
    id: 'services_contact',
    form: { id: 'services_contact' },
  }

  return (
    <Layout
      footerAds={node?.footerAds}
      headerAds={node?.headerAds}
      popupAds={node?.popUpAds}
      {...props}
    >
      <Helmet>
        <title>{node?.title} | Coninsa</title>
        <meta name="description" content="Coninsa ofrece contenido destacado sobre innovación en construcción y urbanismo, brindando análisis profundos y soluciones sostenibles que lideran la industria. Sus publicaciones son esenciales para profesionales que buscan estar al día con las últimas tendencias y tecnologías."/>
        <link rel="canonical" href={canonical} />
      </Helmet>
      {paragraphResolver(basicHero)}
      {paragraphResolver(section)}

      {formMachineName && (
        <div className='webform-container container mb-6'>
          <iframe
            src={`${SITE_URL}/form/${formMachineName}`}
            className='w-full'
          ></iframe>
        </div>
      )}

    </Layout>
  )
}

GenericTemplate.propTypes = {
  data: PropTypes.oneOf({}).isRequired,
}

export default GenericTemplate

export const pageQuery = graphql`
  query generic($id: String!) {
    drupal {
      node: nodeById(id: $id) {
        id: nid

        headerAds: ads(type: "header") {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        popUpAds: ads(type: "pop_up") {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        footerAds: ads(type: "footer") {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }

        ... on Drupal_NodeGenericPage {
          __typename
          title
          url: entityUrl {
            path
          }
          body {
            value
          }
          fieldService {
            targetId
          }
          hero: fieldHero {
            entity {
              id
              ...ParagraphBasicHero
            }
          }
          form: fieldForm {
            id: targetId
          }
        
        }
      }
    }
  }
`
