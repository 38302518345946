import PropTypes from 'prop-types'
import React from 'react'
import { Container } from 'theme-ui'
import { Header } from 'ui'

import PersonalInformation from '../forms/co-natural/1-personal-infomation'
import TaxInformation from '../forms/co-natural/2-tax-information'
import AdditionalInformation from '../forms/co-natural/3-additional-information'
import FinantialInformation from '../forms/co-natural/4-financial-information'
import Documentation from '../forms/co-natural/5-documentation'

function CoNaturalPersonForm(props) {
  const { location } = props
  const params = new URLSearchParams(location.search)
  const path = params.get('path') || '/'

  const mapForms = {
    '/informacion-fiscal': TaxInformation,
    '/informacion-adicional': AdditionalInformation,
    '/informacion-financiera': FinantialInformation,
    '/documentacion': Documentation,
  }
  const FormComponent = mapForms[path] || PersonalInformation

  return (
    <Container sx={{ my: 'large' }}>
      <Header
        sx={{ lineHeight: 1.15, mb: ['32px', '6'], px: ['20px', '0'] }}
        headline='Solicitud de Arrendamiento'
      >
        Persona Natural
        <h3 className='text-xl md:text-3xl font-light lowercase font-body'>
          Coarrendatarios
        </h3>

      </Header>

      <FormComponent {...props} />
    </Container>
  )
}

CoNaturalPersonForm.propTypes = {
  headline: PropTypes.any,
  location: PropTypes.shape({
    search: PropTypes.any,
  }),
}

export default CoNaturalPersonForm
