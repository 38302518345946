import React from 'react'
import PropTypes from 'prop-types'

import { Slider } from 'ui'
import { paragraphResolver } from '.'

function PSlider({ slidesToShow, items, variant, ...props }) {
  const [slidesToShowMobile, slidesToShowDesktop] = slidesToShow
  const calcSlidesToShowDesktop =
    items.length < slidesToShowDesktop ? items.length : slidesToShowDesktop

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: calcSlidesToShowDesktop,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: slidesToShowMobile,
        },
      },
    ],
  }

  return (
    <Slider {...settings} {...props} variant={variant}>
      {items.map(paragraphResolver)}
    </Slider>
  )
}

PSlider.defaultProps = {
  slidesToShow: [1, 1],
  items: [],
  variant: 'default',
}

PSlider.propTypes = {
  items: PropTypes.arrayOf(),
  slidesToShow: PropTypes.arrayOf(),
  title: PropTypes.string.isRequired,
  variant: PropTypes.string,
}

export default PSlider
