import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, Input, Label, Radio } from 'theme-ui'

import Control from '../../components/Control'

import {
  getOptions,

  // Options
  idTypes,
} from '../options'

function BasicNaturalPersonalInformation({ register, extraFields }) {
  return (
    <Grid sx={{ columnGap: 'medium' }} columns={[1, '1fr 1fr']}>
      <Control label='Nombres' required>
        <Input {...register('fieldFirstName')} type='text' required />
      </Control>

      <Control label='Apellidos' required>
        <Input {...register('fieldLastName')} type='text' required />
      </Control>

      <Control label='Tipo de identificación' required>
        <Box sx={{ display: 'flex' }}>
          {getOptions(idTypes).map(option => (
            <Label>
              <Radio {...register('fieldIdType')} value={option.value} required />
              {option.label}
            </Label>
          ))}
        </Box>
      </Control>

      <Control label='Número de identificación' required>
        <Input {...register('fieldIdNumber')} type='text' required />
      </Control>

      {extraFields.includes('cell') && (
        <Control label='Número celular' required>
          <Input {...register('fieldCellPhoneNumber')} type='text' required />
        </Control>
      )}

      <Control label='Email para envio de facturación electrónica' required>
        <Input {...register('fieldEmail')} type='email' required />
      </Control>
    </Grid>
  )
}
BasicNaturalPersonalInformation.defaultProps = {
  extraFields: [],
}

BasicNaturalPersonalInformation.propTypes = {
  extraFields: PropTypes.shape([]),
}

export default BasicNaturalPersonalInformation
