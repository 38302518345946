// import {Injectable} from '@angular/core';
import {PropuestaCliente} from '../../shared/models/propuestaCliente';
import {ValorPresente} from '../../shared/models/valorPresente';
import {TotalesValoresPresentes} from '../../shared/models/TotalesValoresPresentes';
import {Cotizacion} from '../../shared/models/cotizacion';
import {PropuestaConstructor} from '../../shared/models/propuestaConstructor';
import {constantes} from '../../shared/constants/app.constant';

// @Injectable()

export class ClienteService {

    /**
     * Atributos con las constantes del sistema
     */
    private constantes: any;

    /**
     * Constructor
     */
    constructor() {
        this.constantes = constantes;
    }

    /**
     * Inicializa objeto PropuestaCliente
     */
    public inicializarCliente(
        cuotas: any[] = [],
        fechaCotizacion: string = '',
        fechaCredito: any = null,
        valorCuotaInicial: number = 0,
        valorApartar: number = 0,
        valorCredito: number = 0,
        porSeparacion: number = 0,
        porCuotaInicial: number = 0,
        porCredito: number = 0,
        numCuotas: number = 0,
        cuotaExtInicial: number = 0,
        cuotaExtCredito: number = 0,
        tower: number = 0,
        floor: number = 0,
        features: string = '',
        advisor: string = '',
        observation: string = '',
    ): PropuestaCliente {
        return new PropuestaCliente(
            cuotas,
            fechaCotizacion,
            fechaCredito,
            valorCuotaInicial,
            valorApartar,
            valorCredito,
            porSeparacion,
            porCuotaInicial,
            porCredito,
            numCuotas,
            cuotaExtInicial,
            cuotaExtCredito,
            tower,
            floor,
            features,
            advisor,
            observation
        );
    }

    /**
     * Inicializa objeto Cotizacion
     */
    public inicializarCotizacion(
        propuestaConstructor: PropuestaConstructor = null,
        propuestaCliente: PropuestaCliente = null,
        valorDescuentoCliente: number = null,
        valorInteresesCliente: number = null,
        valorTotalPagar: number = null,
        fechaAplicacionDiferencia: any = null,
        diasAplicacionDiferencia: number = null,
        valorAplicacionDiferencia: number = null,
        tasaDescuento: number = 0,
        tasaFinanciera: number = 0,
        sumaAplicacionDiferenciaInteresesDifCuotas: number = 0,
        cobroInteres: boolean = false,
        showIntDesc: boolean = true
    ): Cotizacion {
        return new Cotizacion(
            propuestaConstructor,
            propuestaCliente,
            valorDescuentoCliente,
            valorInteresesCliente,
            valorTotalPagar,
            fechaAplicacionDiferencia,
            diasAplicacionDiferencia,
            valorAplicacionDiferencia,
            tasaDescuento,
            tasaFinanciera,
            sumaAplicacionDiferenciaInteresesDifCuotas,
            cobroInteres,
            showIntDesc
        );
    }

    /**
     * Inicializa objeto ValorPresente
     * @param fecha
     * @param dias
     * @param valorDesc
     * @param valorIntereses
     * @return ValorPresente
     */
    public inicializarValorPresente(fecha: string = '',
                                    dias: number = null,
                                    valorDesc: number = null,
                                    valorIntereses: number = null,
                                    conceptoCuotaId: number,
                                    conceptoCuota: string,
                                    conceptoCuotaCat: number): ValorPresente {
        return new ValorPresente(
            fecha,
            dias,
            valorDesc,
            valorIntereses,
            conceptoCuotaId,
            conceptoCuota,
            conceptoCuotaCat
        );
    }

    /**
     * Inicializa objeto TotalesValoresPresentes
     * @param valorPresenteConstructorDescuento
     * @param valorPresenteConstructorIntereses
     * @param valorPresenteClienteDescuento
     * @param valorPresenteClienteIntereses
     * @param diferenciaDescuento
     * @param diferenciaIntereses
     * @return TotalesValoresPresentes
     */
    public inicializarValoresPresentesTotales(
        valorPresenteConstructorDescuento: number = null,
        valorPresenteConstructorIntereses: number = null,
        valorPresenteClienteDescuento: number = null,
        valorPresenteClienteIntereses: number = null,
        diferenciaDescuento: number = null,
        diferenciaIntereses: number = null
    ) {
        return new TotalesValoresPresentes(
            valorPresenteConstructorDescuento,
            valorPresenteConstructorIntereses,
            valorPresenteClienteDescuento,
            valorPresenteClienteIntereses,
            diferenciaDescuento,
            diferenciaIntereses
        );
    }

    /**
     * Calcula los dias diferencia entre dos fecha de una año de 360 dias (EXCEL DIAS360)
     * @param sd
     * @param fd
     * @param m
     */
    public ano360Dias(sd, fd, m) {
        var d1 = new Date(sd);
        var d2 = new Date(fd);
        var d1_1 = d1;
        var d2_1 = d2;
        var method = m || false;
        var d1_y = d1.getFullYear();
        var d2_y = d2.getFullYear();
        var dy = 0;
        var d1_m = d1.getMonth();
        var d2_m = d2.getMonth();
        var dm = 0;
        var d1_d = d1.getDate();
        var d2_d = d2.getDate();
        var dd = 0;
        if (method) {
            // euro
            if (d1_d === 31) {
                d1_d = 30;
            }
            if (d2_d === 31) {
                d2_d = 30;
            }
        } else {
            // american NASD
            if (d1_d === 31) {
                d1_d = 30;
            }
            if (d2_d === 31) {
                if (d1_d < 30) {
                    if (d2_m === 11) {
                        d2_y = d2_y + 1;
                        d2_m = 0;
                        d2_d = 1;
                    } else {
                        d2_m = d2_m + 1;
                        d2_d = 1;
                    }
                } else {
                    d2_d = 30;
                }
            }
        }
        dy = d2_y - d1_y;
        dm = d2_m - d1_m;
        dd = d2_d - d1_d;
        return parseFloat(String(dy * 360 + dm * 30 + dd));
    }

    /**
     * Suma todos valores de una propiedad en un array de objetos
     * @param arreglo
     * @param propiedad
     */
    public sumarArreglo(arreglo: any[], propiedad: string, itemArreglo: string = this.constantes.VACIO, proceso: string = this.constantes.VACIO): number {
        let sumatoria = 0;
        arreglo.forEach(item => {
            if (proceso === this.constantes.EXCLUIR && item.conceptoCuota !== itemArreglo) {
                sumatoria += Number(item[propiedad]);
            }
            if (proceso === this.constantes.INCLUIR && item.conceptoCuota === itemArreglo) {
                sumatoria += Number(item[propiedad]);
            }
            if (proceso === this.constantes.VACIO) {
                sumatoria += Number(item[propiedad]);
            }
        });
        return sumatoria;
    }

    /**
     * Suma todos valores de una propiedad en un array de objetos
     * @param arreglo
     * @param propiedad
     */
    public sumarConceptos(arreglo: any[], propiedad: string, categorias: number[]): number {
        var sumatoria = 0;
        arreglo.forEach(item => {
            var index = categorias.findIndex(categoria => item.conceptoCuotaCat == categoria);
            if (index != -1) {
                sumatoria += Number(item[propiedad]);
            }
        });
        return sumatoria;
    }

    /**
     * Contar el numero de veces que aparece un concepto
     * @param arreglo
     * @param propiedad
     */
    public contarConceptos(arreglo: any[], propiedad: string, categorias: number[]): number {
        var i = 0;
        arreglo.forEach(item => {
            var index = categorias.findIndex(categoria => item.conceptoCuotaCat == categoria);
            if (index != -1) {
                i++;
            }
        });
        return i;
    }

    /**
     * Calcula el valor futuro (EXCEL VF)
     * @param rate
     * @param nper
     * @param pmt
     * @param pv
     * @param type
     * @return fv
     */
    public valorFuturo(rate, nper, pmt, pv = 0, type = 0) {
        var pow = Math.pow(1 + rate, nper), fv;
        if (rate) {
            fv = (pmt * (1 + rate * type) * (1 - pow) / rate) - pv * pow;
        } else {
            fv = -1 * (pv + pmt * nper);
        }
        return fv.toFixed(2);
    }
}
