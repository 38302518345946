import React from "react";

import Heading from "@coninsa-s2/heading";
import IconCard from "@coninsa-s2/icon-card";
import Shape from "@coninsa-s2/shape";
import ArrowRightIcon from "@coninsa-site/home/icons/arrow-right.inline.svg";

import homeIcon from "./assets/icons/home.svg";
import keepingKeyIcon from "./assets/icons/keeping-key.svg";
import settingsIcon from "./assets/icons/settings.svg";
import userIcon from "./assets/icons/user.svg";
import briefcaseIcon from "./assets/icons/briefcase.svg";

import "./styles.css";

const data = [
  {
    id: "faq-1",
    icon: homeIcon,
    title: `<strong>Eres comprador</strong> <br /> de vivienda nueva`,
    url: "/servicio-cliente/preguntas-frecuentes/eres-comprador-de-vivienda-nueva",
  },
  {
    id: "faq-2",
    icon: keepingKeyIcon,
    title: `<strong>Eres propietario o arrendatario</strong> <br /> de un inmueble con Coninsa`,
    url: "/servicio-cliente/preguntas-frecuentes/eres-propietario-o-arrendatario-de-un-inmueble-con-coninsa",
  },
  {
    id: "faq-3",
    icon: settingsIcon,
    title: `Conoce sobre <br /> <strong>Nuestros otros servicios</strong>`,
    url: "/servicio-cliente/preguntas-frecuentes/conoce-otros-servicios",
  },
  {
    id: "faq-4",
    icon: userIcon,
    title: `Conoce más sobre <br /> <strong>Servicio al Cliente</strong>`,
    url: "/servicio-cliente/preguntas-frecuentes/servicio-al-cliente",
  },
  {
    id: "faq-5",
    icon: briefcaseIcon,
    title: `<strong>Soy proveedor</strong> <br /> y/o quiero trabajar con Coninsa`,
    url: "/servicio-cliente/preguntas-frecuentes/soy-proveedor-quiero-trabajar-con-coninsa",
  },
];

interface T_props {
  children: React.ReactNode;
}

function Landing({ children }: T_props) {
  return (
    <div>
      <div className="mb-6">
        <Heading>Preguntas frecuentes</Heading>
        <p className="text-sm text-s2-gray-600 lg:text-base">
          Hemos recopilado las preguntas más frecuentes de nuestros Clientes y
          Usuarios y construido las respuestas con el equipo Coninsa Ramón H.
          para tí.{" "}
          <span className="font-bold">
            Utiliza el buscador o sólo busca la temática que te interesa.
          </span>
        </p>
      </div>

      {children && <div className="mb-6">{children}</div>}

      <div className="s2-faq-cards">
        {data.map((item) => (
          <a key={item.id} href={item.url} className="s2-faq-cards__item">
            <IconCard highlighted>
              <Shape>
                <img
                  src={item.icon}
                  alt="icon"
                  className="h-20 w-20 lg:h-28 lg:w-28"
                />
              </Shape>
              <h4 dangerouslySetInnerHTML={{ __html: item.title }}></h4>
              <ArrowRightIcon className="s2-icon-card__arrow-icon" />
            </IconCard>
          </a>
        ))}
      </div>
    </div>
  );
}

export default Landing;
