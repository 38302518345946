import React from "react";
import Layout from "../../../layout/default/Layout";
import { Router, navigate } from "@reach/router";

import { ColombiansAbroadPage } from "@coninsa-site/terms-and-conditions/pages/general";


function ColombiansAbroad() {
  return (
    <Layout>
      <Router basepath="/terminos-y-condiciones/generales/colombianos-en-el-exterior-2019">
        <ColombiansAbroadPage path="*" navigate={navigate} />
      </Router>
    </Layout>
  );
}

export default ColombiansAbroad;
