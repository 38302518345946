import React from 'react'
import { Box } from 'theme-ui'

function MemberCardTag({ ...props }) {
  const baseStyles = {
    color: 'white',
    fontSize: 1,
    textTransform: 'uppercase',
    fontWeight: 'heading',
    textAlign: 'center',
  }
  return <Box __css={baseStyles} {...props} />
}

export default MemberCardTag
