import React from 'react';
import clsx from "clsx";

import "./styles.css";

function Tag({ tint = 'gray', variant, className, ...props }) {
  const classes = {
    "s2-tag": true,
    [`s2-tag--${tint}`]: tint,
    [`s2-tag--${variant}`]: variant,
  }
  return (
    <span className={clsx(classes, className)}>{props.children}</span>
  )
}

export default Tag;
