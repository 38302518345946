import React from 'react'
import PropTypes from 'prop-types'

import { Card } from 'ui'

function PCard({ image, tag, title, variant, ...props }) {
  return (
    <Card variant={variant} mx={2} {...props}>
      <Card.Image mb='small' alt={image.alt} src={image.src} variant={variant} />
      {tag && <Card.Tag variant={variant}>{tag}</Card.Tag>}
      <Card.Content variant={variant}>{title}</Card.Content>
    </Card>
  )
}

PCard.defaultProps = {
  variant: 'default',
  tag: undefined,
}

PCard.propTypes = {
  image: PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
  tag: PropTypes.string,
  variant: PropTypes.string,
}
export default PCard
