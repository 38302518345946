import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

const CallToActionSubTitle = props => {
  const baseStyles = {
    color: '#80C342',
    fontSize: ['16px', '20px'],
    mb: 'xxsmall',
  }

  return <Box as='h3' __css={baseStyles} {...props} />
}

CallToActionSubTitle.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CallToActionSubTitle
