import React from 'react'
import PropTypes from 'prop-types'
import { Text } from 'theme-ui'

import { Slide, Cap } from 'ui'

function PSlide({ title, description, icon, ...props }) {
  return (
    <Slide {...props}>
      <Cap sx={{ mb: 'medium', mx: 'auto' }} icon={icon} />

      <Slide.SubTitle>{title}</Slide.SubTitle>
      <Text
        __css={{
          fontSize: ['14px', '16px'],
          mb: [0, 'large'],
          px: ['medium', null, 'large', 'xxlarge'],
        }}
      >
        {description}
      </Text>
    </Slide>
  )
}

PSlide.defaultProps = {
  icon: undefined,
}

PSlide.propTypes = {
  icon: PropTypes.string,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default PSlide
