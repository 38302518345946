import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

function JobDetails({ highlighted, children }){
  const baseStyles = {
    color: 'accent',
    fontSize: 1,
    fontWeight: 'bold',
  }

  return (
    <Box mb='medium'>
      <Box as='span' sx={baseStyles}>{highlighted}</Box>
        {children}
    </Box>
  )
}

JobDetails.defaultProps = {
  highlighted: '',
}

JobDetails.propTypes = {
  children: PropTypes.node.isRequired,
  highlighted: PropTypes.string,
}

export default JobDetails
