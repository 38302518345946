import React from 'react'

import buildingIcon from '../../assets/icons/building.svg'
import personIcon from '../../assets/icons/person.svg'
import designIcon from '../../assets/icons/design.svg'
import moneyIcon from '../../assets/icons/money.svg'
import earthIcon from '../../assets/icons/earth.svg'
import rulerIcon from '../../assets/icons/ruler.svg'

import InfoCard from '../../../leasing-property/components/InfoCard.js'

function ArchitecturalDesign() {
  return (
    <div className='ml-11'>
      <div className='inline-block md:grid grid-cols-3 justify-between gap-16 mb-12'>
        <InfoCard icon={buildingIcon}>
          <div className='pl-14 md:pl-10 pt-8'>
            <h4 className='font-medium text-coninsa-blue-900 text-base md:text-lg leading-5 mb-4 md:mb-5'>
              Análisis para <br />
              <span className='font-bold'>la selección de lotes</span>
            </h4>
          </div>
          <div>
            <ul className='list-disc list-inside marker:text-coninsa-green-400 md:space-y-1'>
              <li>Normativo y técnico</li>
              <li>Implantación / Ubicación</li>
              <li>Esquema arquitectónico</li>
            </ul>
          </div>
        </InfoCard>

        <InfoCard icon={personIcon}>
          <div className='pl-14 md:pl-10 pt-8'>
            <h4 className='font-medium text-coninsa-blue-900 text-base md:text-lg leading-5 mb-4 md:mb-5'>
              Nuestro <br /> <span className='font-bold'>Portal Web</span>
            </h4>
          </div>
          <div>
            <ul className='list-disc list-inside marker:text-coninsa-green-400 space-y-1'>
              <li>
                Liderar, coordinar y administrar el equipo interdisciplinario durante
                el desarrollo del proyecto.
              </li>
              <li>Acompañamiento arquitectónico.</li>
            </ul>
          </div>
        </InfoCard>

        <InfoCard icon={designIcon}>
          <div className='pl-14 md:pl-10 pt-8'>
            <h4 className='font-medium text-coninsa-blue-900 text-base md:text-lg leading-5 mb-4 md:mb-5'>
              Nuestro <br /> <span className='font-bold'>Portal Web</span>
            </h4>
          </div>
          <div>
            <ul className='list-disc list-inside marker:text-coninsa-green-400 space-y-1'>
              <li>Diseño Arquitectónico</li>
              <li>Paisajismo</li>
              <li>Diseño Urbano</li>
              <li>Interiorismo</li>
              <li>Diseños Técnicos</li>
            </ul>
          </div>
        </InfoCard>

        <InfoCard icon={moneyIcon}>
          <div className='pl-14 md:pl-10 pt-8'>
            <h4 className='font-medium text-coninsa-blue-900 text-base md:text-lg leading-5 mb-4 md:mb-5'>
              Nuestro <br /> <span className='font-bold'>Portal Web</span>
            </h4>
          </div>
          <div>
            <ul className='list-disc list-inside marker:text-coninsa-green-400 space-y-1'>
              <li>
                Análisis de costos, planeación, metodología, programas y plazos.
              </li>
              <li>
                Resultado: Confiabilidad y Competitividad para el proyecto (diseño -
                factibilidad) y las obras (diseño - construcción).
              </li>
            </ul>
          </div>
        </InfoCard>

        <InfoCard icon={earthIcon}>
          <div className='pl-14 md:pl-10 pt-8'>
            <h4 className='font-medium text-coninsa-blue-900 text-base md:text-lg leading-5 mb-4 md:mb-5'>
              Nuestro <br /> <span className='font-bold'>Portal Web</span>
            </h4>
          </div>
          <div>
            <ul className='list-disc list-inside marker:text-coninsa-green-400 space-y-1'>
              <li>Proyectos eficientes y sostenibles para un mundo mejor.</li>
              <li>
                Apoyamos a nuestros clientes para que sus proyectos se certifiquen
                LEED, EDGE o CASA.
              </li>
            </ul>
          </div>
        </InfoCard>

        <InfoCard icon={rulerIcon}>
          <div className='pl-14 md:pl-10 pt-8'>
            <h4 className='font-medium text-coninsa-blue-900 text-base md:text-lg leading-5 mb-4 md:mb-5'>
              Nuestro <br /> <span className='font-bold'>Portal Web</span>
            </h4>
          </div>
          <div>
            <ul className='list-disc list-inside marker:text-coninsa-green-400 space-y-1'>
              <li>Gestión de conflictos - detección y solución.</li>
              <li>
                Gestión de Cambios - trazabilidad, control, informes y estadísticas.
              </li>
            </ul>
          </div>
        </InfoCard>
      </div>
    </div>
  )
}

export default ArchitecturalDesign
