import React from 'react'
import { Box, Grid, Input, Label, Radio } from 'theme-ui'

import Control from '../../components/Control'

import { getOptions, idTypes } from '../options'

function BasicLegalRepresentative({ register, control, setValue }) {
  return (
    <Grid gap={4} columns={[2, '1fr 1fr']}>
      <Control label='Nombres' required>
        <Input {...register('fieldFirstName')} type='text' required />
      </Control>
      <Control label='Apellidos' required>
        <Input {...register('fieldLastName')} type='text' required />
      </Control>
      <Control label='Tipo de identificación' required>
        <Box sx={{ display: 'flex' }}>
          {getOptions(idTypes).map(option => (
            <Label>
              <Radio {...register('fieldIdType')} value={option.value} required />
              {option.label}
            </Label>
          ))}
        </Box>
      </Control>
      <Control label='Número de identificación' required>
        <Input {...register('fieldIdNumber')} type='text' required />
      </Control>

      <Control label='Celular'>
        <Input {...register('fieldLegalRepresentativePhone')} type='text' />
      </Control>
    </Grid>
  )
}

export default BasicLegalRepresentative
