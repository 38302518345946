import React from 'react'
import PropTypes from 'prop-types'
import { Box, Paragraph } from 'theme-ui'

import { Icon } from 'ui'

function BuildingAd({ code, showPdf }) {
  const baseStyles = {
    bg: 'accent',
    borderRadius: '8px',
    color: 'white',
    display: ['block', 'grid', null],
    gap: 'medium',
    gridTemplateColumns: showPdf ? '60% 40%' : '65% 35%',
    p: showPdf ? ['medium', 'large'] : 'medium',
  }

  const titleStyles = {
    fontFamily: 'heading',
    fontSize: showPdf ? 5 : 4,
    fontWeight: 'heading',
    mb: ['small', 'medium'],
    textAlign: ['center', 'left'],
    textTransform: 'uppercase',
  }

  const buttonStyles = {
    bg: 'white',
    borderRadius: '8px',
    color: 'accent',
    display: 'flex',
    fontSize: showPdf ? [3, '22px'] : 2,
    fontWeight: 'bold',
    gap: 'xsmall',
    // height: '60px',
    m: [0, 'auto'],
    py: showPdf ? ['small', '15px'] : 'xsmall',
    px: showPdf ? ['16px', '30px'] : 'small',
  }

  return (
    <Box sx={baseStyles}>
      <Box mb={['medium', 0]}>
        <Box sx={titleStyles}>¿Te gustó este inmueble?</Box>
        <Paragraph sx={{ fontSize: showPdf ? 2 : 1 }}>
          Si te gustó este inmueble en arriendo y quieres{' '}
          <strong>agilizar el proceso </strong>
          de arrendamiento, puedes comenzar diligenciando el formulario de estudio
          digital para que nuestros asesores se comuniquen contigo y continúen el
          proceso.
        </Paragraph>
      </Box>

      <Box
        as='a'
        sx={buttonStyles}
        href={`https://www.coninsa.co/estudio-digital/?code=${code}`}
        target='_blank'
      >
        <Icon name='fa-reg-file-alt' sx={{ mt: ['4px', 'xsmall'] }} />
        <Box>Iniciar mi estudio digital</Box>
      </Box>
    </Box>
  )
}

BuildingAd.defaultProps = {
  showPdf: true,
}

BuildingAd.propTypes = {
  code: PropTypes.string.isRequired,
  showPdf: PropTypes.bool,
}

export default BuildingAd
