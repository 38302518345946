import React from "react";

function IconCard({ icon, title, description, className = "", ...props }) {
  return (
    <div
      className={`flex gap-8 items-center relative shadow-[0px_3px_6px_#00000029] rounded-md bg-white py-10 pr-4 pl-[70px] md:pl-24 ml-7 md:ml-12 ${className}`}
      {...props}
    >
      <div className="flex absolute -left-10 md:-left-12 gap-1">
        <img className="w-28 h-28 md:w-auto md:h-auto" src={icon} alt="icon" />
      </div>
      <div className="flex flex-col">
        <h4 className="text-coninsa-blue-900 text-lg leading-5 mb-2 md:text-xl md:mb-0 font-bold">{title}</h4>
        <p className="text-sm md:text-base">{description}</p>
      </div>
    </div>
  );
}

export default IconCard;
