import React from 'react'
import PropTypes from 'prop-types'

import { Card, Cap } from 'ui'

function PCardIcon(props) {
  const { title, icon, description, variant } = props

  return (
    <Card variant={variant} mx={2} pt='medium'>
      <Cap icon={icon} mx='auto' mb='small' />
      <Card.Content variant={variant}>
        <Card.Title>{title}</Card.Title>
        {description}
      </Card.Content>
    </Card>
  )
}

PCardIcon.defaultProps = {
  icon: undefined,
  title: '',
  variant: 'default',
}

PCardIcon.propTypes = {
  description: PropTypes.string.isRequired,
  icon: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.string,
}
export default PCardIcon
