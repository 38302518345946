import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, Image, Paragraph } from 'theme-ui'

import ProjectShare from './ProjectShare'

function BuildingHeader({
  brand,
  title,
  subtitle,
  state,
  eyebrow,
  bg,
  showShareBtn,
  whatsappMsg,
  ...props
}) {
  const eyebrowStyles = {
    bg: `${bg}`,
    borderRadius: '3px',
    color: 'white',
    display: 'inline-block',
    fontSize: ['10px', 0],
    fontWeight: 'semibold',
    px: ['xsmall', 'small'],
    py: 'xxsmall',
    textTransform: 'uppercase',
  }

  const titleStyles = {
    color: 'neutral',
    fontFamily: 'Oswald',
    fontSize: ['22px', '32px'],
    fontWeight: '500',
  }

  const columns = ['1fr', showShareBtn ? '2fr 1fr' : '1fr']

  return (
    <Box>
      <Grid columns={columns} {...props}>
        <Box sx={{ display: 'flex', mb: ['16px', 'medium'] }}>
          {brand && (
            <Box
              sx={{
                borderRadius: '8px',
                boxShadow: '0px 0px 29px #00000017',
                height: '100px',
                mr: ['small', 'medium'],
                p: 'small',
                width: '100px',
              }}
            >
              <Image src={brand} />
            </Box>
          )}

          <Box>
            <Box sx={eyebrowStyles}>{eyebrow}</Box>
            <Box sx={titleStyles}>{title}</Box>

            <Paragraph sx={{ fontSize: [1, 2], lineHeight: ['15px', '17px'] }}>
              {subtitle}
            </Paragraph>
          </Box>
        </Box>

        {showShareBtn && (
          <ProjectShare showShareBtn={showShareBtn} whatsappMsg={whatsappMsg} />
        )}
      </Grid>
    </Box>
  )
}

BuildingHeader.defaultProps = {
  brand: '',
  showShareBtn: true,
  subtitle: '',
  whatsappMsg: '',
}

BuildingHeader.propTypes = {
  bg: PropTypes.string.isRequired,
  brand: PropTypes.string,
  eyebrow: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  state: PropTypes.string.isRequired,
  showShareBtn: PropTypes.bool,
  title: PropTypes.string.isRequired,
  whatsappMsg: PropTypes.string,
}

export default BuildingHeader
