import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Router } from '@reach/router'
import { StateMachineProvider, createStore } from 'little-state-machine'
import axios from 'axios'
import { Box } from 'theme-ui'

import Layout from 'layout/default/Layout'
import RequestPage from 'modules/digital-studio'

import { PrivateRoute, PublicRoute } from '../../modules/auth/utils'
import AuthDrupalContext from '../../modules/auth/AuthDrupalContext'
import IntroPage from '../../modules/digital-studio/pages/intro'

import AddPerson from '../../modules/digital-studio/crud/AddPerson'
import EditPerson from '../../modules/digital-studio/crud/EditPerson'

import AddCotenant from '../../modules/digital-studio/crud/AddCotenant'
import EditCotenant from '../../modules/digital-studio/crud/EditCotenant'

import LoadPerson from '../../modules/digital-studio/crud/LoadPerson'

import ThankYouPage from '../../modules/digital-studio/pages/thank-you'

createStore({
  initialSettings: {},
})

const DRUPAL_SING_IN_URL = 'https://admindrupal.coninsa.co/user/login?_format=json'

const Outcome = props => <div {...props} />

function Page(props) {
  const params = new URLSearchParams(props?.location?.search)
  const code = params.get('code') || null
  const pwd = params.get('pwd') || null
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function fetchUserLogin() {
      const [name, pass] = atob(pwd).split(':')
      const body = JSON.stringify({ name, pass })

      try {
        const response = await axios.post(DRUPAL_SING_IN_URL, body, {
          withCredentials: true,
        })

        if (response?.data?.current_user) {
          setLoading(false)
        }
      } catch (error) {
        setLoading(false)
      }
    }

    // Try a login request if the pwd token is defined.
    if (pwd) {
      fetchUserLogin()
    } else {
      setLoading(false)
    }
  }, [])

  if (code) {
    // Save code on session storage.
    sessionStorage.setItem('propertyCode', code)
  }

  return (
    <Layout>
      <StateMachineProvider>
        {!loading && (
          <AuthDrupalContext>
            <Router basepath='/estudio-digital'>
              <PublicRoute
                render={IntroPage}
                path='/'
                redirectTo='/estudio-digital/solicitud'
              />
              <PrivateRoute
                render={Outcome}
                path='/solicitud'
                redirectTo='/estudio-digital'
              >
                <RequestPage path='/' />

                <AddPerson path='/persona/:bundle/add' />
                <EditPerson path='/persona/:bundle/:id' />

                <LoadPerson path='/persona/:bundle/:id/coarrendatario/:cotenant'>
                  <AddCotenant path='/add' />
                  <EditCotenant path='/:cid' />
                </LoadPerson>

                <ThankYouPage path='/gracias' />
              </PrivateRoute>
            </Router>
          </AuthDrupalContext>
        )}

        {loading && <Box sx={{ p: 2 }}>Cargando ...</Box>}
      </StateMachineProvider>
    </Layout>
  )
}

Page.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
}

export default Page
