import React from 'react'
import { graphql } from 'gatsby'

import ServicesPage from 'modules/services/page'
import Layout from '../layout/default/Layout'

const Page = ({ data, ...props }) => {
  return (
    <Layout
      headerAds={data?.drupal?.ads?.header}
      popupAds={data?.drupal?.ads?.popUp}
      footerAds={data?.drupal?.ads?.footer}
    >
      <ServicesPage />
    </Layout>
  )
}

export const query = graphql`
  query ServiceClientPageQuery {
    drupal {
      ads: getAdsByUrl(path: "/servicio-cliente") {
        header {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        popUp {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        footer {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
      }
    }
  }
`

export default Page
