/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react'
import { ApolloProvider } from '@apollo/client'
import Amplify from 'aws-amplify'

// Import global styles.
// import 'normalize.css'
import './src/styles/grids.css'
import './src/styles/global.css'
import './src/styles/ckeditor.css'

import client from './apollo-client'
import config from './config'

Amplify.configure({
  Auth: {
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,

    authenticationFlowType: 'USER_PASSWORD_AUTH',

    // oauth: {
    //   domain: config.cognito.DOMAIN,
    //   redirectSignIn: config.cognito.REDIRECT_SIGN_IN,
    //   redirectSignOut: config.cognito.REDIRECT_SIGN_OUT,
    //   responseType: 'code',
    // },
  },
})

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>{element}</ApolloProvider>
)

export default {}
