import React from "react";

import referProjectImage from "../../images/refer-project-banner.png";
import referProjectImageMobile from "../../images/refer-project-mobile.png";

function ReferPropertyBanner() {
  return (
    <div className="grid grid-cols-1 bg-s2-teal lg:grid-cols-2">
      <img
        src={referProjectImage}
        className="hidden w-full object-cover lg:block"
      />
      <img src={referProjectImageMobile} className="w-full lg:hidden" />

      <div className="flex flex-col justify-center px-4 py-4 lg:px-0 lg:py-8 xl:w-[40vw]">
        <div className="lg:max-w-md">
          <h2 className="mb-4 text-xl font-extrabold !leading-none text-white md:text-2xl xl:text-4xl">
            Tus amigos y familiares merecen las mejores recomendaciones de
            inversión,{" "}
            <span className="text-co-teal">
              cuéntales acerca de nuestros proyectos.
            </span>
          </h2>

          <div className="text-white lg:text-xl">
            Sé parte de nuestro plan de referidos y obtén grandes beneficios.*
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReferPropertyBanner;
