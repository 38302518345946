import React from "react";
import clsx from "clsx";

import "./styles.css";

interface ModalProp {
  header: React.ReactNode;
  footer: React.ReactNode;
  width?: number;
  children: React.ReactNode;
  align: "left" | "center" | "right";
  variant: "compact" | "full-screen";
}

const Modal: React.FC<ModalProp> = ({
  header,
  footer,
  width = 450,
  align = "center",
  variant = "compact",
  children,
}) => {
  const dynamicClasses = clsx({
    [`s2-modal--align-${align}`]: align,
    [`s2-modal--${variant}`]: variant,
  });

  return (
    <div
      className={clsx("s2-modal", dynamicClasses)}
      style={{ "--modal--content-width": `${width}px` }}
    >
      <div className="s2-modal__background"></div>

      <div className="s2-modal__content">
        {header && <div className="s2-modal__header">{header}</div>}
        <div className="s2-modal__body">{children}</div>
        {footer && <div className="s2-modal__footer">{footer}</div>}
      </div>
    </div>
  );
};

export default Modal;
