import React from "react";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import StepCard from "@coninsa-s2/step-card";

import info from "./data.js";

export default function McvGuideInfo() {
  const sliderOptions = {
    arrows: false,
    gap: "2.5rem",
    perPage: 4,
    perMove: 1,
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: "1rem",
        perPage: 1,
        padding: { left: 0, right: "5rem" },
        pagination: true,
      },
    },
  };

  return (
    <Section tint="ligther-gray" className="!pb-2 lg:!pb-14">
      <Container>
        <div className="lg:px-36">
          <div className="mb-6">
            <h3 className="mb-4 font-display text-2xl font-extralight text-s2-dark-blue lg:text-3xl">
              ¿Cómo ingresar a{" "}
              <span className="block text-3xl font-bold text-s2-green lg:text-4xl">
                Mi Coninsa Virtual?
              </span>
            </h3>
            <p className="text-sm text-s2-gray-600 lg:text-base">
              Si ya te habías registrado en la versión anterior de nuestro
              portal Coninsa, puedes seguir identificándote con el mismo usuario
              y contraseña de antes.
            </p>
          </div>

          <div className="hidden grid-cols-2 gap-4 lg:grid">
            {info.map((item) => (
              <StepCard key={item.id} title={item.title} tint="teal">
                <p dangerouslySetInnerHTML={{ __html: item?.description }}></p>
              </StepCard>
            ))}
          </div>

          <div className="lg:hidden">
            <Splide
              options={sliderOptions}
              className="s2-slider s2-slider--light my-4"
            >
              {info.map((item) => (
                <SplideSlide className="pb-12">
                  <StepCard key={item.id} title={item.title} tint="teal">
                    <p
                      className="text-sm lg:text-base"
                      dangerouslySetInnerHTML={{ __html: item?.description }}
                    ></p>
                  </StepCard>
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </div>
      </Container>
    </Section>
  );
}
