import React from 'react'
import { Box } from 'theme-ui'
import { Icon } from 'ui'

function PrevArrow(props) {
  const baseStyle = {
    '&:before': {
      content: '""',
    },
  }

  return (
    <Box sx={baseStyle} {...props}>
      <Icon name='co-arrow-left' />
    </Box>
  )
}

export default PrevArrow
