import constOne from '../assets/images/const-one.png'
import constTwo from '../assets/images/const-two.png'
import constThree from "../assets/images/tech-one.jpg"
import constFour from '../assets/images/const-four.png'
import constFive from '../assets/images/const-five.png'
import constSix from '../assets/images/const-six.png'
import constSeven from '../assets/images/const-seven.png'
import constEight from '../assets/images/const-eight.png'
import constNine from '../assets/images/const-nine.png'
import constTen from '../assets/images/const-ten.png'
import constEleven from '../assets/images/tech-four.jpg'
import constTwelve from '../assets/images/tech-three.jpg'

export default [
  {
    id: 'constructor-1',
    photo: constOne,
    firstName: 'William',
    lastName: 'Saavedra',
    position: 'Gerente Contratación Pública y Privada',
    linkedin: '#'
  },
  {
    id: 'constructor-2',
    photo: constTwo,
    firstName: 'Julián',
    lastName: 'García Cadavid',
    position: 'Gerente de Operaciones',
    linkedin: '#'
  },
  {
    id: 'constructor-3',
    photo: constThree,
    firstName: 'Daniel ',
    lastName: 'Hoyos',
    position: 'Gerente Técnico Nacional',
    linkedin: '#'
  },
  {
    id: 'constructor-4',
    photo: constFour,
    firstName: 'Rafael',
    lastName: 'Munar',
    position: 'Director Nacional de Construcciones',
    linkedin: '#'
  },
  {
    id: 'constructor-5',
    photo: constFive,
    firstName: 'Mirtha',
    lastName: 'Fernández',
    position: 'Directora de Construcciones',
    linkedin: '#'
  },
  {
    id: 'constructor-6',
    photo: constSix,
    firstName: 'Adriana',
    lastName: 'Caro',
    position: 'Directora de Construcciones',
    linkedin: '#'
  },
  {
    id: 'constructor-7',
    photo: constSeven,
    firstName: 'Juan Felipe',
    lastName: 'Ríos',
    position: 'Director de Construcciones',
    linkedin: '#'
  },
  {
    id: 'constructor-8',
    photo: constEight,
    firstName: 'Hugo Octavio',
    lastName: 'Valdez',
    position: 'Director de Construcciones',
    linkedin: '#'
  },
  {
    id: 'constructor-9',
    photo: constNine,
    firstName: 'Jhin Henry',
    lastName: 'Moreno',
    position: 'Director de Construcciones',
    linkedin: '#'
  },
  {
    id: 'constructor-10',
    photo: constTen,
    firstName: 'José Mauricio',
    lastName: 'Loaiza',
    position: 'Director de Construcciones',
    linkedin: '#'
  },
  {
    id: 'constructor-11',
    photo: constEleven,
    firstName: 'Jaime',
    lastName: 'Rodríguez',
    position: 'Director de Propuestas y Presupuestos',
    linkedin: '#'
  },
  {
    id: 'constructor-12',
    photo: constTwelve,
    firstName: 'Marié Lorena',
    lastName: 'Vergara',
    position: 'Directora de Propuestas y Presupuestos',
    linkedin: '#'
  },
]
