import {
  pathOr,
  map,
  compose,
  filter,
  isEmpty,
  reject,
  anyPass,
  omit,
  isNil,
} from 'ramda'

export const getErrors = pathOr(null, ['data', 'node', 'errors'])
export const getViolations = pathOr(null, ['data', 'node', 'violations'])
export const getEntity = pathOr(null, ['data', 'node', 'entity'])

const nCotenant = contenant => ({
  targetId: contenant?.targetId?.toString(),
})

export const nCotenants = compose(
  map(nCotenant),
  filter(cotenant => cotenant?.targetId?.toString())
)

export const nFile = value => {
  if (value?.targetId) {
    return {
      targetId: value?.targetId?.toString(),
    }
  }

  return null
}

export const nFiles = (arr = []) => arr.map(nFile)

export const nDate = obj => (obj?.value ? { value: obj.value } : null)

const omitTypename = compose(omit(['__typename']), reject(anyPass([isEmpty, isNil])))
const omitTypenameInArray = map(omitTypename)

export const nLocation = omitTypename

export const normalizeFieldByName = (name, value) => {
  let nValue

  switch (name) {
    case 'fieldIdentificationDocument':
    case 'fieldIncomeStatement':
    case 'fieldFinancialState':
    case 'fieldPropertyTax':
    case 'fieldRut':
    case 'fieldExistenceRepresentation':
    case 'fieldLaborCertification':
      nValue = nFile(value)
      break

    case 'fieldProofPayroll':
    case 'fieldAdditionalDocuments':
    case 'fieldBankStatements':
      nValue = nFiles(Object.values(value))

      break

    case 'fieldCotenants':
      nValue = nCotenants(value)
      break

    case 'fieldDateBirth':
    case 'fieldExpeditionDate':
    case 'fieldAdmissionDate':
      nValue = nDate(value)
      break

    case 'fieldShareholders':
    case 'fieldBankReferences':
    case 'fieldFamilyReferences':
    case 'fieldCommercialReferences':
    case 'fieldProviderReferences':
      nValue = omitTypenameInArray(Object.values(value))
      break

    default:
      nValue = value
  }

  return nValue
}

// Remove null keys.
export const nVariables = rawVariables => {
  const keys = Object.keys(rawVariables)

  return keys.reduce((acc, key) => {
    if (rawVariables[key]) {
      acc[key] =
        typeof rawVariables[key] === 'object'
          ? nVariables(rawVariables[key])
          : rawVariables[key]
    }

    return acc
  }, {})
}

const notEmptyFields = reject(anyPass([isEmpty, isNil]))

export const normalizeFields = fields =>
  Object.keys(fields).reduce((acc, key) => {
    const type = typeof fields[key]
    const fieldValue = fields[key]

    if (!isEmpty(fieldValue)) {
      if (type === 'object') {
        const cleanFieldValue = omitTypename(fieldValue)
        acc[key] = normalizeFieldByName(key, cleanFieldValue)
      } else if (type === 'number' && key === 'id') {
        acc[key] = fieldValue.toString()
      } else {
        acc[key] = fields[key]
      }
    }

    return acc
  }, {})

export const cleanData = compose(normalizeFields, nVariables)
export const prepareForms2Drupal = compose(normalizeFields, nVariables)

function booleanStringToNumber(value) {
  return value ? '1' : '0'
}

export function prepareDrupal2Forms(params) {
  const keys = Object.keys(params)

  return keys.reduce((acc, key) => {
    const type = typeof params[key]
    const value = params[key]

    switch (type) {
      case 'boolean':
        acc[key] = booleanStringToNumber(value)
        break

      default:
        acc[key] = value
    }

    return acc
  }, {})
}
