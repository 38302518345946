import React from 'react'
import { Box } from 'theme-ui'

function Stepper(props) {
  const baseStyles = {
    fontWeight: '600',
    fontSize: 2,
    mb: 'small',
  }

  return <Box __css={baseStyles} {...props} />
}

export default Stepper
