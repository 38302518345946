import React from "react";
import Layout from "../../layout/default/Layout";
import { graphql } from 'gatsby';

import { LandingPage } from "@coninsa-site/project";
import FloatingWhatsappBtn from "modules/home/components/FloatingWhatsappBtn";


function Landing({ data }) {
  const projects = data?.drupal?.searchProjects?.documents || [];

  return (
    <Layout
      headerAds={data?.drupal?.ads?.header}
      popupAds={data?.drupal?.ads?.popUp}
      footerAds={data?.drupal?.ads?.footer}
    >
      <FloatingWhatsappBtn path="https://api.whatsapp.com/send?phone=573123636333&text=Hola!" />
      <LandingPage projects={projects} />
    </Layout>
  );
}

export const query = graphql`
  query ProjectsHomePageQuery {
    drupal {
      ads: getAdsByUrl(path: "/proyectos") {
        header {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        popUp {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        footer {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
      }

      # Search projects
      searchProjects: searchAPISearch(
        index_id: "search_projects"
        condition_group: {
          conjunction: AND
          groups: [
            {
              conjunction: OR
              conditions: [
                { name: "status", value: "1", operator: "=" }
                { name: "field_final_publication", value: "1", operator: "=" }
                { name: "field_for_sale", value: "1", operator: "=" }
              ]
            }
            {
              conjunction: OR
              conditions: [
                { name: "field_state", value: "4685", operator: "=" }
              ]
            }
          ]
        }
        sort: [{ field: "field_ordering", value: "DESC" }]
        range: { offset: 0, limit: 8 }
      ) {
        result_count
        documents {
          ... on Drupal_SearchProjectsDoc {
            id: project_id
            url: project_url
            imageUrl: project_image
            code: project_code
            name: field_project_description
            areaFrom: field_area_from
            areaTo: field_area_to
            price: field_sale_price_from
            location: project_location
            customNote: field_custom_note
            state: field_state
            isVis: field_immediate_delivery
            brandUrl: brand_url
          }
        }
      }
    }
  }
`;

export default Landing;
