import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

import { Icon } from 'ui'

export function OverlayButton({ children, ...props }) {
  return (
    <Box
      as='button'
      __css={{
        bg: 'transparent',
        border: 'none',
        color: 'white',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '14px',
        fontWeight: 'bold',
        justifyItems: 'center',
        gap: 1,
        p: 0,
        textTransform: 'uppercase',
        alignItems: 'center'


      }}

      {...props}
    >
      {children}
      <Icon sx={{ fontSize: 2 }} name='close' color='white' />
    </Box>
  )
}

function Overlay({ children, ...props }) {
  const [open, setOpen] = useState(true)

  useEffect(() => {
    document.querySelector('html').classList.add('no-scroll')
  }, [])

  const close = () => {
    document.querySelector('html').classList.remove('no-scroll')
    setOpen(false)
  }

  if (!open) {
    return null
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        bg: 'rgba(0, 0, 0, 0.4)',
        bottom: 0,
        right: 0,
        zIndex: 1,
      }}
      {...props}
    >
      <Box sx={{ position: 'relative' }}>
        {children}
        <OverlayButton
          onClick={close}
          sx={{
            position: 'absolute',
            right: 0,
            bottom: '-20px',
          }}
        >
          Cerrar
        </OverlayButton>
      </Box>
    </Box>
  )
}

Overlay.propTypes = {
  children: PropTypes.element.isRequired,
}

export default Overlay
