import React from "react";

import { CurrencyProvider } from "@coninsa-s2/currency";
import ProjectSearchCard from "../../containers/ProjectSearchCard";
import ProjectPopupSearch from "../../containers/ProjectPopupSearch";
import SearchBar from "./sections/search-bar";
import SearchProjectView from "./search-project-view";

import searchProjectsConfigs from "../../facets/search-preojects";

export interface ProjectSearchType {
  id: number;
  name: string;
  latLong: string;
  price: number;
}

interface SearchPageProps {
  navigate: (string) => void;
}

const SearchPage: React.FC<SearchPageProps> = ({
  navigate = (url) => console.log(url),
}) => {
  return (
    <CurrencyProvider>
      <SearchProjectView
        navigate={navigate}
        searchBar={SearchBar}
        itemCard={ProjectSearchCard}
        popupCard={ProjectPopupSearch}
        configs={searchProjectsConfigs}
      />
    </CurrencyProvider>
  );
};

export default SearchPage;
