import React from "react";
import clsx from "clsx";

import Tag from "@coninsa-s2/tag";
import Heading from "@coninsa-s2/heading";
import FeatureItem from "@coninsa-s2/feature-item";

import "./styles.css";

function ProjectPopup({
  code,
  price,
  location,
  imageUrl,
  title,
  logoUrl,
  url,
  area: AreaElement,
  tags: TagsElement,
  className,
  style = {},
}) {
  return (
    <a
      href={url}
      target="_blank"
      className={clsx("s2-project-popup", className)}
      style={style}
    >
      <div className="s2-project-popup__media">
        <img src={imageUrl} alt="Picture" loading="lazy" height={260} />
        <div className="s2-project-popup__tags">
          <TagsElement />
        </div>
      </div>

      <div className="s2-project-popup__content">
        <Tag
          variant="rounded"
          className="s2-project-popup__code absolute -top-[14px]"
        >
          Código: {code}
        </Tag>

        <img className="s2-project-popup__logo" src={logoUrl} alt="Picture" />

        <div className="s2-project-popup__body">
          <Heading tint="black" size="lg">
            {title}
          </Heading>

          <ul className="s2-project-popup__features">
            <li>
              <span>Desde</span>
              <div className="s2-project-popup__price">{price}</div>
            </li>
            {location && (
              <li>
                <FeatureItem icon="pin">
                  <strong>{location}</strong>
                </FeatureItem>
              </li>
            )}
            <li>
              <FeatureItem icon="area" className="leading-5">
                <AreaElement />
              </FeatureItem>
            </li>
          </ul>
        </div>
      </div>
    </a>
  );
}

export default ProjectPopup;
