import React from "react";
import NumberFormat from "react-number-format";

// const baseInputStyles = {
//   display: 'block',
//   width: '100%',
//   padding: 2,
//   appearance: 'none',
//   fontSize: 'inherit',
//   lineHeight: 'inherit',
//   border: '1px solid',
//   borderRadius: 4,
//   color: 'inherit',
//   background: 'transparent',
// }

const InputMask = function (props) {
  return (
    <NumberFormat {...props} />
  )
}

export function removeMaskFormat(value) {
  if (!value) {
    return 0;
  }

  return parseFloat(value.replaceAll(/\.|\$|\s/g, "").replace(',', '.'));
}

export default InputMask
