import React from "react";
import clsx from "clsx";

import LeftIcon from "./icons/left-outline.inline.svg";
import RightIcon from "./icons/right-outline.inline.svg";

import "./src/styles.css";

interface CloudProps {
  variant: "line" | "retangle";
  tint?: "teal" | "lime";
  iconDirection?: "left" | "right";
  iconPosition?: "left-top" | "left-bottom" | "right-top" | "right-bottom";
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const Cloud: React.FC<CloudProps> = ({
  variant,
  tint = "teal",
  iconDirection = "left",
  iconPosition = "right-top",
  className,
  style,
  children,
}) => {
  const dynamicClasses = {
    [`s2-cloud--${variant}`]: variant,
    [`s2-cloud--${tint}`]: tint,
    [`s2-cloud--${iconPosition}-position`]: iconPosition,
  };

  const iconMap = {
    left: LeftIcon,
    right: RightIcon,
  };

  const Icon = iconMap[iconDirection];

  return (
    <div className={clsx("s2-cloud", dynamicClasses, className)}>
      <div className="s2-cloud__content" style={style}>
        <Icon className="s2-cloud__icon" />
        {children}
      </div>
    </div>
  );
};

export default Cloud;
