export default [
  {
    id: "option-1",
    title: "Opción 1",
    subtitle: "Ver los proyectos asociados:",
    description:
      "Podrás visualizar los inmuebles que tienes asociados a tu compra.",
  },
  {
    id: "option-2",
    title: "Opción 2",
    subtitle: "Conocer tu plan de pagos:",
    description:
      "Podrás revisar tu estado de cuenta y descargarlo en PDF para llevar el control sobre tus pagos y cuotas.",
  },
  {
    id: "option-3",
    title: "Opción 3",
    subtitle: "Realizar el pago de tus cuotas:",
    description:
      "Podrás visualizar el valor a pagar y el botón pagar y redireccionarte a alianza fiduciaria donde se recaudan los fondos pagados.",
  },
  {
    id: "option-4",
    title: "Opción 4",
    subtitle: "Línea de tiempo:",
    description:
      "Podrás hacer seguimiento de tu proceso de compra, a través de una línea de tiempo, que te indica el momento en el que te encuentras como Cliente.",
  },
  {
    id: "option-5",
    title: "Opción 5",
    subtitle: "Documentación:",
    description:
      "Podrás visualizar o descargar los documentos entregados al momento de la compra del inmueble.",
  },
];
