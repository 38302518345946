import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Image } from 'theme-ui'

import { Button, Icon, Modal } from 'ui'

function InterestCard({ btn, image, openModal, summary, title, ...props }) {
  const [state, setState] = useState(false)
  const handler = () => setState(true)
  const closeHandler = () => {
    setState(false)
  }

  const baseStyles = {
    bg: 'white',
    borderRadius: '0 0 10px 10px',
    px: 4,
    pb: 'medium',
  }

  const summaryStyles = {
    color: 'text',
    fontSize: 1,
    height: '150px',
    overflow: 'hidden',

    '& p': {
      display: '-webkit-box',
      WebkitLineClamp: '5',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    },
  }

  return (
    <Box {...props}>
      <Box sx={{ height: '230px' }}>
        <Image
          sx={{ borderRadius: '10px 10px 0 0', height: '100%', width: '100%' }}
          src={image}
        />
      </Box>
      <Box sx={baseStyles}>
        <Box
          sx={{
            color: 'accent',
            fontFamily: 'heading',
            fontSize: 3,
            fontWeight: 'heading',
            lineHeight: '23px',
            mb: 1,
            pt: 4,
            textTransform: 'uppercase',
          }}
        >
          {title}
        </Box>
        <Box sx={summaryStyles} dangerouslySetInnerHTML={{ __html: `${summary}` }} />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {openModal && (
            <Button variant='more' onClick={handler}>
              <Box sx={{ display: 'flex' }}>
                <Box mr='medium'>{btn.text}</Box>
                <Box>
                  <Icon sx={{ fontSize: '20px' }} name='fi-arrow-right' />
                </Box>
              </Box>
            </Button>
          )}
          {!openModal && (
            <Button as='a' href={btn.url} variant='more' target='_blank'>
              <Box sx={{ display: 'flex' }}>
                <Box mr='medium'>{btn.text}</Box>
                <Box>
                  <Icon sx={{ fontSize: '20px' }} name='fi-arrow-right' />
                </Box>
              </Box>
            </Button>
          )}
        </Box>
      </Box>

      <Modal
        sx={{
          border: '1px solid',
          borderColor: 'transparent',
          borderRadius: '10px',
          width: ['100%', '520px'],
        }}
        open={state}
        onClose={closeHandler}
      >
        <Modal.Header
          sx={{
            bg: 'accent',
            color: 'white',
            fontWeight: 'heading',
            textTransform: 'uppercase',
          }}
        >
          {title}
        </Modal.Header>
        <Modal.Content>
          <Box dangerouslySetInnerHTML={{ __html: `${summary}` }} />
        </Modal.Content>
      </Modal>
    </Box>
  )
}

InterestCard.defaultProps = {
  btn: undefined,
  openModal: false,
}

InterestCard.propTypes = {
  btn: PropTypes.oneOf({
    text: PropTypes.string,
    url: PropTypes.string,
  }),
  image: PropTypes.string.isRequired,
  openModal: PropTypes.bool,
  title: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
}

export default InterestCard
