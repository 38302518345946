import PropTypes from 'prop-types'
import React from 'react'
import { navigate } from 'gatsby'
import { useForm } from 'react-hook-form'
import { Box, Grid, Select } from 'theme-ui'
import { useMutation } from '@apollo/client'

import { Button } from 'ui'
import { UPDATE_NODE_LEGAL_PERSON_APPLICATION } from '../../mutations'
import { LEGAL_PERSON_APPLICATION } from '../../queries'
import HeaderForm from '../../components/HeaderForm'
import Cotenant from '../../components/Cotenant'
import { getEntity, cleanData } from '../utils'
import {
  cotenantMap,
  getCotenantAddUrl,
  getCotenantEditUrl,
} from '../natural/4-co-tenant'
import Control from '../../components/Control'

function CoTenant(props) {
  const loadedPerson = props?.loadedPerson || {}
  const basePersonPath = props.location.pathname
  const [mutation] = useMutation(UPDATE_NODE_LEGAL_PERSON_APPLICATION, {
    refetchQueries: [
      { query: LEGAL_PERSON_APPLICATION, variables: { id: props.id } },
    ],
  })
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    errors,
    watch,
  } = useForm({ defaultValues: loadedPerson })

  const tempCotenantType = watch('temporary__cotenant_type', 'cotenant_legal')

  const addCotenant = () => {
    const redirectTo = getCotenantAddUrl(basePersonPath, tempCotenantType)
    navigate(redirectTo)
  }

  const onSubmit = async data => {
    const variables = cleanData(data)

    return mutation({ variables })
      .then(response => {
        const entity = getEntity(response)
        fetch(
          `https://admindrupal.coninsa.co/digital-studio/notification/${data.id}`,
          {
            credentials: 'include',
          }
        )

        if (entity) {
          navigate('/estudio-digital/solicitud/gracias')
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const allCotenants = loadedPerson?.fieldCotenants ?? []

  return (
    <section>
      <HeaderForm step='4/4' title='Coarrendatarios' />
      <h4 className='text-lg font-semibold'>¡No falta mucho para terminar!</h4>
      <p className='mb-4 font-medium'>
        Para continuar con tu solicitud es necesario ingresar un coarrendatario con
        el fin de agilizar tu estudio, será la persona de apoyo en tu proceso de
        arrendamiento.
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid mb='medium' sx={{ alignItems: 'end' }} columns='4fr 1fr'>
          <Control
            label='Identifica el tipo de coarrendatario, selecciona:'
            required
          >
            <Select {...register('temporary__cotenant_type')}>
              {cotenantMap.map(cotenant => (
                <option value={cotenant.value}>{cotenant.label}</option>
              ))}
            </Select>
          </Control>

          <Button
            sx={{ mb: 'small', py: 16 }}
            type='button'
            variant='secondary'
            onClick={addCotenant}
          >
            Agregar
          </Button>
        </Grid>

        <Box>
          {allCotenants?.map(cotenant => (
            <Cotenant
              key={cotenant.targetId}
              {...cotenant}
              editUrl={getCotenantEditUrl(
                basePersonPath,
                cotenant.entity.bundle,
                cotenant.targetId
              )}
            />
          ))}
        </Box>

        <Box pt='medium'>
          <Button
            type='submit'
            disabled={isSubmitting || allCotenants.length === 0}
            loading={isSubmitting}
          >
            Continuar
          </Button>

          <Button
            ml='small'
            type='button'
            variant='default'
            onClick={() => navigate(-1)}
          >
            Atras
          </Button>
        </Box>
      </form>
    </section>
  )
}

CoTenant.propTypes = {
  id: PropTypes.string.isRequired,
}

export default CoTenant
