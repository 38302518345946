import React from 'react'

import bigWorks from '../../data/big-works.js'

function WorkCard() {
  return (
    <div className='inline-block md:grid md:grid-cols-3 gap-4'>
      {bigWorks.map(work => (
        <div className='mb-4 md:mb-0'>
          <img src={work?.image} className='min-w-full rounded-t-xl' />
          <div className='bg-white rounded-b-xl p-5 shadow-[0px_3px_6px_#00000029] h-56'>
            <p className='font-normal mb-2'>{work?.tag}</p>
            <p className='font-display font-medium text-xl mb-4'>{work?.title}</p>
            <p className='text-xs mb-5'>{work?.date}</p>
            <p className='font-normal text-sm'>{work?.summary}</p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default WorkCard
