import React from "react";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Breadcrumb, { BreadcrumbItem } from "@coninsa-s2/breadcrumb";
import Heading from "@coninsa-s2/heading";

export default function NeighborsAndFriendsReferralPlan() {
  const breadcrumbItems = [
    {
      label: "Inicio",
      url: "/terminos-y-condiciones",
    },
    {
      label: "Términos y condiciones generales",
      url: "/terminos-y-condiciones/generales",
    },
    {
      label: "Plan referidos vecinos y amigos - 2018",
    },
  ];

  return (
    <Section>
      <Container>
        <div className="mb-8">
          <Breadcrumb>
            {breadcrumbItems.map((item) => (
              <BreadcrumbItem>
                {item.url && <a href={item.url}>{item.label}</a>}
                {!item.url && item.label}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        </div>
        <div className="mb-7">
          <span className="text-lg text-s2-dark-blue lg:text-[22px]">
            Términos y condiciones
          </span>
          <Heading size="2xl">Plan referidos “Vecinos y Amigos” - 2018</Heading>
        </div>

        <div className="prose">
          <p>Proyectos Vivienda Bogotá Coninsa Ramón H. 2018</p>
          <ol>
            <li>
              <h4>Vigencia:</h4>
              <p>
                La actividad referidos Coninsa Ramón H. 2018 proyectos vivienda
                Bogotá se realizará del 15 de junio de 2018 al 31 de diciembre
                de 2018, en la ciudad de Bogotá.
              </p>
            </li>
            <li>
              <h4>El organizador:</h4>
              <p>
                La presente actividad está organizada por Coninsa Ramón H S.A.
                con Nit 890911431-1 con domicilio principal en la Región Bogotá
                Av. Cra. 19 No. 114 – 65 piso 6 (57+1) 6014800, Región Antioquia
                (57+4) 448 88 28, y Región Caribe: (57+5) 319 96 10
              </p>
            </li>
            <li>
              <h4>Objeto y mecánica de la actividad:</h4>
              <p>
                Campaña referidos Coninsa Ramón H. proyectos de vivienda Bogotá
                es una actividad que premia durante la vigencia descrita, a
                aquellas personas que nos refieran un Cliente que compre un
                inmueble (cierre negocio) en los proyectos de Castilla La Nueva,
                Reserva de Mallorca III y Bali. (Se considera cierre de negocio
                cuando se haya hecho la legalización del inmueble comprado ante
                la fiducia). El referidor obtendrá un bono Alkosto por valor de
                $5.000.000, al referido se le dará un descuento de $3.000.000
                sobre el valor total del inmueble comprado y se entregará
                únicamente una vez se haga la legalización de dicho inmueble
                ante la fiducia.
              </p>

              <ol>
                <li>
                  Los ganadores (referidor y referido) autorizan a Coninsa Ramón
                  H. S.A. a difundir y/o publicar cualquier dato que haya sido
                  suministrado en su inscripción (en el desprendible del
                  talonario de la campaña “Vecinos & Amigos Coninsa Ramón H.”),
                  en caso que decida identificarlo como ganador de la actividad
                  o para cualquier otro fin comercial que la empresa
                  organizadora considere necesario, sin obligación de
                  compensación alguna.
                </li>
                <li>
                  Los derechos que le asisten como Titular de los datos
                  personales objeto de Tratamiento son los señalados en el
                  artículo 8 de la Ley 1581 de 2012.
                </li>
              </ol>
            </li>
            <li>
              <h4>Disposiciones legales:</h4>
              <ol>
                <li>
                  Una vez los ganadores hayan recibido el bono Alkosto (para el
                  referidor) y el descuento de $3.000.000 (para el referido) no
                  se admitirán cambios por dinero, valores o cualquier otro
                  bien. El derecho al canje no es transferible, negociable, ni
                  puede ser comercializado o canjeado por dinero en efectivo, ni
                  por ningún otro premio.
                </li>
                <li>
                  El solo hecho de participar en esta actividad implica la total
                  aceptación de los presentes términos y condiciones.
                </li>
                <li>
                  Los participantes se comprometen a eximir de responsabilidad a
                  Coninsa Ramón H. S.A., sus accionistas, empleados o
                  colaboradores y frente a cualquier acción, daño, costes, y
                  otras responsabilidades en las que pudiera incurrir Coninsa
                  Ramón H. S.A. como resultado de cualquier tipo de
                  incumplimiento por parte suya de alguno de alguno de los
                  términos y condiciones.
                </li>
                <li>
                  Al aceptar estos términos y condiciones los participantes
                  reconocen que de manera libre, voluntaria y espontánea han
                  decidido participar en esta actividad, por lo que dentro de
                  los límites permitidos por la ley Colombiana, renuncian a
                  cualquier reclamación o acción de cualquier naturaleza en
                  contra de Coninsa Ramón H. S.A., sus accionistas, empleados o
                  colaboradores, que tengan por objeto reclamar indemnización
                  por daños o perjuicios que les haya causado su participación
                  en la actividad, o de cualquier manera relacionados con esta,
                  o por las actividades que realizare como consecuencia de dicha
                  participación. Si el premio (bono y descuento) no es aceptado
                  o sus condiciones, se considerará que ha renunciado al mismo y
                  no tendrá derecho a reclamo o indemnización alguna, ni
                  siquiera parcialmente.
                </li>
                <li>
                  La responsabilidad inherente a la actividad por parte de
                  Coninsa Ramón H. S.A. culmina con la entrega del bono Alkosto
                  y la legalización del descuento del inmueble comprado.
                </li>
              </ol>
            </li>
            <li>
              <h4>Condiciones y restricciones:</h4>
              <ol>
                <li>
                  Válido un (1) bono Alkosto por valor de $5.000.000 que se
                  entregará a la persona referidora (aplica solamente para los
                  proyectos Castilla La Nueva, Reserva de Mallorca III y Bali).
                </li>
                <li>
                  Válido un descuento único por valor de $3.000.000 que se
                  entregará a la persona referida que compre inmueble (aplica
                  solamente para los proyectos Castilla La Nueva, Reserva de
                  Mallorca III y Bali).
                </li>
                <li>
                  Se considera cierre de negocio (compra del inmueble),
                  únicamente cuando se haya hecho la legalización del inmueble
                  comprado ante la fiducia.
                </li>
                <li>Promoción no válida para empleados de la compañía.</li>
                <li>
                  Para reclamar el bono y el descuento se deberán dar las
                  condiciones descritas en el presente documento y se realizará
                  en la sala de negocios del proyecto en el cual se haya hecho
                  la compra del inmueble.
                </li>
                <li>
                  Ni el bono, ni el descuento son canjeables en dinero en
                  efectivo.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </Container>
    </Section>
  );
}
