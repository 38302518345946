import React from 'react'
import { Box, Container, Button } from 'theme-ui'

import SEO from 'ui/Seo'

import Layout from '../layout/default/Layout'

const NotFoundPage = () => {
  const Styles = {
    'ul li': {
      p: '10px',
    },
    ul: {
      'text-align': 'center',
      'list-style': 'none',
    },
  }

  return (
    <Layout>
      <SEO title='404: Not found' />
      <Container>
        <h1>"¡Ups! Algo salió mal" </h1>
        <h2>
          La página que buscas no ha sido encontrada, algo esta por suceder! 
        </h2>
        <h3>¿Por ahora qué quieres hacer?</h3>

        <Box sx={Styles}>
          <ul>
            <li>
              <a href='https://www.coninsa.co/arrendamientos/'>
                <Button>ENCONTRAR UN INMUEBLE EN ARRIENDO</Button>
              </a>
            </li>
            <li>
              <a href='https://www.coninsa.co/proyectos/'>
                <Button>ENCONTRAR UN PROYECTO EN VENTA</Button>
              </a>
            </li>
            <li>
              <a href='https://www.coninsa.co/servicio-cliente'>
                <Button>INGRESAR A SERVICIO AL CLIENTE</Button>
              </a>
            </li>
            <li>
              <a href='https://www.coninsa.co/preguntas-frecuentes'>
                <Button>CONSULTAR PREGUNTAS FRECUENTES</Button>
              </a>
            </li>
            <li>
              <a href='https://www.coninsa.co/'>
                <Button>VOLVER A NUESTRA PAGINA DE INICIO</Button>
              </a>
            </li>
          </ul>
        </Box>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
