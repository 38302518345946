import React from 'react'
import { Container } from 'theme-ui'

import { Header } from 'ui'
import GeneralInformation from '../forms/co-legal/1-general-information'
import ShareholdersInformation from '../forms/co-legal/2-shareholders-information'
import FinancialInformation from '../forms/co-legal/3-financial-information'
import Documentation from '../forms/co-legal/4-documentation'

function CoLegalPersonForm({ headline, ...props }) {
  const params = new URLSearchParams(props.location.search)
  const path = params.get('path') || '/'

  const mapForms = {
    '/informacion-accionistas': ShareholdersInformation,
    '/informacion-financiera': FinancialInformation,
    '/documentacion': Documentation,
  }

  const FormComponent = mapForms[path] || GeneralInformation

  return (
    <Container sx={{ my: 'large' }}>
      <Header sx={{ lineHeight: 1.15, mb: '6' }} headline={headline}>
        + Coarrendatario Persona Jurídica
      </Header>
      <FormComponent {...props} />
    </Container>
  )
}

export default CoLegalPersonForm
