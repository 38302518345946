import React from "react";

import ProjectThumbnailSearchCard from "../../containers/ProjectThumbnailSearchCard";
import SearchBar from "./sections/search-bar";
import SearchProjectView from "./search-project-view";
import searchProjectsConfigs from "../../facets/search-progress-projects";

export interface ProjectSearchType {
  id: number;
  name: string;
  latLong: string;
  price: number;
}

interface SearchPageProps {
  navigate: (string) => void;
}

const SearchProgressPage: React.FC<SearchPageProps> = ({
  navigate = (url) => console.log(url),
}) => {
  return (
    <SearchProjectView
      navigate={navigate}
      searchBar={SearchBar}
      itemCard={ProjectThumbnailSearchCard}
      configs={searchProjectsConfigs}
    />
  );
};

export default SearchProgressPage;
