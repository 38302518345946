import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";

import { UPDATE_BUILDING_PROPERTY } from "../graphql/mutations";

function Step3({ onSuccess, onBack, id }) {
  const { register, handleSubmit, setError } = useForm();

  const [mutation] = useMutation(UPDATE_BUILDING_PROPERTY);

  const stratumOptions = [
    { value: "", label: "Selecciona una opción" },
    { value: "1", label: "Estrato 1" },
    { value: "2", label: "Estrato 2" },
    { value: "3", label: "Estrato 3" },
    { value: "4", label: "Estrato 4" },
    { value: "5", label: "Estrato 5" },
    { value: "6", label: "Estrato 6" },
  ];
  const ageOptions = [
    { value: "", label: "Selecciona una opción" },
    { value: "Remodelado", label: "Remodelado" },
    { value: "Entre 0 y 5 años", label: "Entre 0 y 5 años" },
    { value: "Entre 5 y 10 años", label: "Entre 5 y 10 años" },
    { value: "Entre 10 y 20 años", label: "Entre 10 y 20 años" },
  ];


  const onSubmit = async (data) => {
    const response = await mutation({
      variables: {
        id,
        input: {
          fieldArea: data?.property_area,
          fieldBathrooms: data?.bathrooms,
          fieldBedrooms: data?.bedrooms,
          fieldGarages: data?.garages,
          fieldStratum: data?.stratum,
          fieldFurnished: data?.furnished_apartment,
          fieldPetsAllowed: data?.pets_allowed
        }
      }
    })

    const violations = response?.data?.updateBuildingProperty?.violations;
    const errors = response?.data?.updateBuildingProperty?.errors;

    if (violations?.length === 0 && errors?.length === 0) {
      return onSuccess({ id });
    } else {
      setError("Algo ocurrió mal. Vuelva a intentarlo más tarde.");
    }
  };

  return (
    <div className="co-card max-w-4xl mx-auto">
      <form
        className="co-form gap-y-8"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-wrap gap-4">
          <div className="form-control grow">
            <label>Área del inmueble (m2)</label>
            <input
              {...register("property_area")}
              type="text"
              id="property_area"
              placeholder="Ingresa el área de tu inmueble"
              required
            />
          </div>

          <fieldset className="form-group are-radios">
            <legend>¿Aceptas mascotas?</legend>
            <div className="form-items flex-col md:flex-row">
              <div className="form-control is-radio">
                <input
                  {...register("pets_allowed")}
                  type="radio"
                  id="pets_allowed_true"
                  value="1"
                  required
                />
                <label htmlFor="pets_allowed_true">Sí, claro</label>
              </div>
              <div className="form-control is-radio">
                <input
                  {...register("pets_allowed")}
                  type="radio"
                  id="pets_allowed_false"
                  value="0"
                  required
                />
                <label htmlFor="pets_allowed_false">No por esta vez</label>
              </div>
            </div>
          </fieldset>
        </div>

        <div className="flex justify-between flex-wrap gap-4 flex-col md:flex-row">
          <fieldset className="form-group are-compact-radios">
            <legend>Número de baños</legend>
            <div className="form-items">
              <div className="form-control">
                <input
                  {...register("bathrooms")}
                  type="radio"
                  id="bathrooms_1"
                  value="1"
                  required
                />
                <label htmlFor="bathrooms_1">1</label>
              </div>
              <div className="form-control">
                <input
                  {...register("bathrooms")}
                  type="radio"
                  id="bathrooms_2"
                  value="2"
                  required
                />
                <label htmlFor="bathrooms_2">2</label>
              </div>
              <div className="form-control">
                <input
                  {...register("bathrooms")}
                  type="radio"
                  id="bathrooms_3"
                  value="3"
                  required
                />
                <label htmlFor="bathrooms_3">3+</label>
              </div>
            </div>
          </fieldset>

          <fieldset className="form-group are-compact-radios">
            <legend>Número de alcobas</legend>
            <div className="form-items">
              <div className="form-control">
                <input
                  {...register("bedrooms")}
                  type="radio"
                  id="bedrooms_1"
                  value="1"
                  required
                />
                <label htmlFor="bedrooms_1">1</label>
              </div>
              <div className="form-control">
                <input
                  {...register("bedrooms")}
                  type="radio"
                  id="bedrooms_2"
                  value="2"
                  required
                />
                <label htmlFor="bedrooms_2">2</label>
              </div>
              <div className="form-control">
                <input
                  {...register("bedrooms")}
                  type="radio"
                  id="bedrooms_3"
                  value="3"
                  required
                />
                <label htmlFor="bedrooms_3">3+</label>
              </div>
            </div>
          </fieldset>

          <fieldset className="form-group are-compact-radios">
            <legend>Número de garajes</legend>
            <div className="form-items">
              <div className="form-control">
                <input
                  {...register("garages")}
                  type="radio"
                  id="garages_0"
                  value="0"
                  required
                />
                <label htmlFor="garages_0">0</label>
              </div>
              <div className="form-control">
                <input
                  {...register("garages")}
                  type="radio"
                  id="garages_1"
                  value="1"
                  required
                />
                <label htmlFor="garages_1">1</label>
              </div>
              <div className="form-control">
                <input
                  {...register("garages")}
                  type="radio"
                  id="garages_2"
                  value="2"
                  required
                />
                <label htmlFor="garages_2">2+</label>
              </div>
            </div>
          </fieldset>
        </div>

        <div className="flex justify-between flex-wrap gap-4">
          <div className="form-control grow">
            <label htmlFor="apartment">
              Estrato
            </label>
            <select {...register("stratum")} id="stratum" required>
              {stratumOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div className="form-control grow">
            <label>Piso del apartamento</label>
            <input
              {...register("apartment_floor")}
              type="text"
              id="apartment_floor"
              placeholder="Ingresa el piso del apartamento"
              required
            />
          </div>
        </div>

        <div className="flex justify-between flex-wrap gap-4 flex-col md:flex-row">
          <div className="form-control grow">
            <label htmlFor="apartment">
              Antigüedad
            </label>
            <select {...register("age")} id="age" required>
              {ageOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <fieldset className="form-group are-radios">
            <legend>¿Se entrega amoblado?</legend>
            <div className="form-items">
              <div className="form-control is-radio">
                <input
                  {...register("furnished_apartment")}
                  type="radio"
                  id="furnished_apartment_true"
                  value="1"
                  required
                />
                <label htmlFor="furnished_apartment_true">Sí</label>
              </div>
              <div className="form-control is-radio">
                <input
                  {...register("furnished_apartment")}
                  type="radio"
                  id="furnished_apartment_false"
                  value="0"
                  required
                />
                <label htmlFor="furnished_apartment_false">No</label>
              </div>
            </div>
          </fieldset>
        </div>

        <div className="flex justify-between gap-2 md:flex-row">
          <button type="button" onClick={onBack} className="co-button is-secondary is-large has-fixed-icon">
            <i className="co-icon icon-arrow"></i>
            Regresar
          </button>

          <button className="co-button is-progress-default is-large w-full md:w-auto">
            Continuar
          </button>
        </div>
      </form>
    </div>
  );
}

export default Step3;
