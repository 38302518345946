import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

const Slide = ({ children, ...props }) => {
  const baseStyles = {
    pt: 'medium',
    px: ['medium', 'medium', 0],

    color: 'white',
    textAlign: 'center',
  }

  return (
    <Box __css={baseStyles} {...props} __themeKey='slides'>
      {children}
    </Box>
  )
}

Slide.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Slide
