import React from "react"

import Layout from "../../layout/default/Layout"
import { Helmet } from "react-helmet";
import CookiePolicy from "@coninsa-site/cookie-policy"

export default function Landing() {
  return (
    <Layout>
      <Helmet>
        <title>Politica de cookies | Coninsa</title>
        <meta name="description" content="Descubre cómo utilizamos cookies para mejorar tu experiencia en nuestro sitio web. Aprende sobre su uso, gestión y cómo puedes controlar las configuraciones para proteger tu privacidad." />
        <link rel="canonical" href="https://www.coninsa.co/politica-de-cookies" />
      </Helmet>
      <CookiePolicy />
    </Layout>
  )
}
