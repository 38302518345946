import {
  CurrencyContext as Context,
  CurrencyProvider as Provider,
} from "./src/CurrencyContext";
import Selectors from "./src/CurrencySelectors";

export const CurrencyContext = Context;
export const CurrencyProvider = Provider;
export const CurrencySelectors = Selectors;

export function getFormattedCurrencyValue(value, currency) {
  const dividedValue = (value ?? 0) / currency.divider;
  const roundedValue = Math.round(dividedValue);

  if (currency.currency == "cop") {
    return `$${roundedValue.toLocaleString(currency.language)}`;
  }

  return `${currency.symbol} ${roundedValue.toLocaleString(currency.language)}`;
}
