import React from 'react'
import { navigate } from 'gatsby'
import { useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { Button } from 'ui'

import { UPDATE_NODE_LEGAL_PERSON_COTENANT } from '../../mutations'
import { LEGAL_COTENANT_APPLICATION } from '../../queries'

import BasicDocumentation from '../generic/BasicDocumentation'
import HeaderForm from '../../components/HeaderForm'
import { cleanData, getEntity } from '../utils'

export function getBasePersonPath(personType, id) {
  return `/estudio-digital/solicitud/persona/${personType}/${id}`
}

function Documentation(props) {
  const loadedCotenant = props?.loadedCotenant || {}
  const [mutation] = useMutation(UPDATE_NODE_LEGAL_PERSON_COTENANT, {
    refetchQueries: [
      { query: LEGAL_COTENANT_APPLICATION, variables: { id: props.cid } },
    ],
  })

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    setError,
  } = useForm({ defaultValues: loadedCotenant })

  const onSubmit = async data => {
    const variables = cleanData(data)

    return mutation({ variables })
      .then(response => {
        const entity = getEntity(response)

        if (entity) {
          const basePath = getBasePersonPath(props.bundle, props.id)
          navigate(`${basePath}?path=/coarrendatarios`)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <section>
      <HeaderForm step='4/4' title='Documentación' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <BasicDocumentation
          control={control}
          extraFields={[
            'rut',
            'income-statement',
            'existence-representation',
            'property-tax',
          ]}
        />

        <Button type='submit' disabled={isSubmitting} loading={isSubmitting}>
          Guardar
        </Button>
      </form>
    </section>
  )
}

export default Documentation
