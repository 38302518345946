import React from "react";
import { clsx } from "clsx";
import "./styles.css";

function Heading({
  size = "lg",
  tint = "blue",
  condensed = false,
  weight = 'default',
  as = "h4",
  className,
  ...props
}) {
  const Tag = as;
  const dynamicClasses = {
    [`s2-heading--${size}`]: size,
    [`s2-heading--${tint}`]: tint,
    "s2-heading--condensed": condensed,
    [`s2-heading--${weight}`]: weight,
  };

  return (
    <Tag className={clsx("s2-heading", dynamicClasses, className)} {...props}>
      {props.children}
    </Tag>
  );
}

export default Heading;
