import React from 'react'
import { navigate } from 'gatsby'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { Box, Grid, Input } from 'theme-ui'

import { Collapse, Heading } from 'ui'
import { UPDATE_NODE_NATURAL_PERSON_COTENANT } from '../../mutations'
import { NATURAL_COTENANT_APPLICATION } from '../../queries'

import Location from '../../Location'
import Control from '../../components/Control'
import BasicProperties from '../generic/BasicProperties'
import BasicFinancialInformation from '../generic/BasicFinancialInformation'
import HeaderForm from '../../components/HeaderForm'
import FooterForm from '../../components/FooterForm'
import { getEntity, cleanData } from '../utils'

function FinancialInformation(props) {
  const loadedCotenant = props?.loadedCotenant || {}
  const [mutation] = useMutation(UPDATE_NODE_NATURAL_PERSON_COTENANT, {
    refetchQueries: [
      { query: NATURAL_COTENANT_APPLICATION, variables: { id: props.cid } },
    ],
  })
  const { register, handleSubmit, control, setValue } = useForm({
    defaultValues: loadedCotenant,
  })

  const onSubmit = async data => {
    const variables = cleanData(data)

    return mutation({ variables })
      .then(response => {
        const entity = getEntity(response)

        if (entity) {
          navigate('?path=/documentacion')
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <section>
      <HeaderForm step='4/5' title='Información financiera' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Collapse variant='grayly'>
          <Collapse.Item title='Propiedades' index={1}>
            <BasicProperties register={register} />
          </Collapse.Item>
        </Collapse>

        <Box mb='medium'>
          <Heading level='3' variant='form'>
            Información Financiera
          </Heading>
          <BasicFinancialInformation register={register} />
        </Box>

        <Collapse variant='grayly'>
          <Collapse.Item title='Referencias' index={1}>
            <Box mb='medium'>
              <Heading level='3' variant='form'>
                Bancaria (I)
              </Heading>
              <Grid gap={4} columns={[2, '1fr, 1fr']}>
                <Control label='Entidad'>
                  <Input {...register('fieldBankReferences.0.entity')} type='text' />
                </Control>
                <Control label='Sucursal'>
                  <Input
                    {...register('fieldBankReferences.0.branchOffice')}
                    type='text'
                  />
                </Control>
                <Control label='Número de cuenta'>
                  <Input
                    {...register('fieldBankReferences.0.accountNumber')}
                    type='text'
                  />
                </Control>
                <Control label='Tipo de cuenta'>
                  <Input
                    {...register('fieldBankReferences.0.accountType')}
                    type='text'
                  />
                </Control>
              </Grid>
            </Box>

            <Box mb='medium'>
              <Heading level='3' variant='form'>
                Bancaria (II)
              </Heading>
              <Grid gap={4} columns={[2, '1fr, 1fr']}>
                <Control label='Entidad'>
                  <Input {...register('fieldBankReferences.1.entity')} type='text' />
                </Control>
                <Control label='Sucursal'>
                  <Input
                    {...register('fieldBankReferences.1.branchOffice')}
                    type='text'
                  />
                </Control>
                <Control label='Número de cuenta'>
                  <Input
                    {...register('fieldBankReferences.1.accountNumber')}
                    type='text'
                  />
                </Control>
                <Control label='Tipo de cuenta'>
                  <Input
                    {...register('fieldBankReferences.1.accountType')}
                    type='text'
                  />
                </Control>
              </Grid>
            </Box>

            <Box
              sx={{ border: '1px solid', borderColor: 'transparent', mb: 'medium' }}
            >
              <Heading level='3' variant='form'>
                Comercial (I)
              </Heading>
              <Grid gap={4} columns={[2, '1fr, 1fr']}>
                <Control label='Almacén'>
                  <Input {...register('fieldCommercialReferences.0.store')} type='text' />
                </Control>
                <Control label='Teléfono'>
                  <Input {...register('fieldCommercialReferences.0.phone')} type='text' />
                </Control>
              </Grid>
            </Box>

            <Box
              sx={{ border: '1px solid', borderColor: 'transparent', mb: 'medium' }}
            >
              <Heading level='3' variant='form'>
                Comercial (II)
              </Heading>
              <Grid gap={4} columns={[2, '1fr, 1fr']}>
                <Control label='Almacén'>
                  <Input {...register('fieldCommercialReferences.1.store')} type='text' />
                </Control>
                <Control label='Teléfono'>
                  <Input {...register('fieldCommercialReferences.1.phone')} type='text' />
                </Control>
              </Grid>
            </Box>

            <Box
              sx={{ border: '1px solid', borderColor: 'transparent', mb: 'medium' }}
            >
              <Heading level='3' variant='form'>
                Personal
              </Heading>
              <Grid gap={4} columns={[2, '1fr, 1fr']}>
                <Control label='Nombre'>
                  <Input {...register('fieldPersonalName')} type='text' />
                </Control>
                <Control label='Dirección'>
                  <Input {...register('fieldPersonalAddress')} type='text' />
                </Control>
                <Control label='Teléfono'>
                  <Input {...register('fieldPersonalPhone')} type='text' />
                </Control>
                <Control label='Parentesco'>
                  <Input {...register('fieldPersonalRelationship')} type='text' />
                </Control>
                <Control label='Ubicación'>
                  <Location
                    name='fieldRefPersonalMunicipality'
                    setValue={setValue}
                    control={control}
                  />
                </Control>
              </Grid>
            </Box>

            <Box
              sx={{ border: '1px solid', borderColor: 'transparent', mb: 'medium' }}
            >
              <Heading level='3' variant='form'>
                Familiar (I)
              </Heading>
              <Grid gap={4} columns={[2, '1fr, 1fr']}>
                <Control label='Nombre'>
                  <Input {...register('fieldFamilyReferences.0.name')} type='text' />
                </Control>
                <Control label='Dirección'>
                  <Input
                    {...register('fieldFamilyReferences.0.address')}
                    type='text'
                  />
                </Control>
                <Control label='Teléfono'>
                  <Input
                    {...register('fieldFamilyReferences.0.phone')}
                    type='text'
                  />
                </Control>
                <Control label='Parentesco'>
                  <Input
                    {...register('fieldFamilyReferences.0.relationship')}
                    type='text'
                  />
                </Control>
                <Control label='Ubicación'>
                  <Location
                    name='fieldRefFamiliarMunicipality'
                    setValue={setValue}
                    control={control}
                  />
                </Control>
              </Grid>
            </Box>

            <Box
              sx={{ border: '1px solid', borderColor: 'transparent', mb: 'medium' }}
            >
              <Heading level='3' variant='form'>
                Familiar (II)
              </Heading>
              <Grid gap={4} columns={[2, '1fr, 1fr']}>
                <Control label='Nombre'>
                  <Input {...register('fieldFamilyReferences.1.name')} type='text' />
                </Control>
                <Control label='Dirección'>
                  <Input
                    {...register('fieldFamilyReferences.1.address')}
                    type='text'
                  />
                </Control>
                <Control label='Teléfono'>
                  <Input
                    {...register('fieldFamilyReferences.1.phone')}
                    type='text'
                  />
                </Control>
                <Control label='Parentesco'>
                  <Input
                    {...register('fieldFamilyReferences.1.relationship')}
                    type='text'
                  />
                </Control>
                <Control label='Ubicación'>
                  <Location
                    name='fieldRefFamiliarMunicipality'
                    setValue={setValue}
                    control={control}
                  />
                </Control>
              </Grid>
            </Box>
          </Collapse.Item>
        </Collapse>
        <FooterForm back />
      </form>
    </section>
  )
}

export default FinancialInformation
