import React from 'react'
import { useForm } from 'react-hook-form'
import { navigate } from 'gatsby'
import { Select } from 'theme-ui'
import { useStateMachine } from 'little-state-machine'
import { Button } from 'ui'

import ApplicationList from './container/RequestedList'
import { setInitialSettings } from './actions'
import Control from './components/Control'
import { applicantConfigs } from './settings'

function Page() {
  const { register, handleSubmit } = useForm()
  const { actions } = useStateMachine({ setInitialSettings })

  const onSubmit = data => {
    const applicant = applicantConfigs[data.type]
    const code = sessionStorage.getItem('propertyCode')

    // Set global property code.
    actions.setInitialSettings({
      propertyCode: code,
      applicant,
    })

    navigate(applicant.basePath)
  }

  return (
    <div className='container py-10 md:py-14 px-5 md:px-0'>
      <div className='mb-8 md:mb-12'>
        <h2 className='text-coninsa-blue-900 font-light mb-4 md:mb-10 text-[22px] md:text-3xl'>
          Solicitud de Arrendamiento
        </h2>
        <p className='font-light'>
          Para dar inicio a tu proceso es importante identificar el tipo de persona
          que eres,
        </p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Control label='selecciona:' required>
            <Select {...register('type')}>
              {Object.keys(applicantConfigs).map(key => (
                <option key={key} value={key}>
                  {applicantConfigs[key].label}
                </option>
              ))}
            </Select>
          </Control>
          <Button>Iniciar solicitud</Button>
        </form>
      </div>

      <ApplicationList />
    </div>
  )
}

export default Page
