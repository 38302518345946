const greenColor = '#80c342'
const yellowColor = '#FDC800'

const dark100 = '#111'
// const dark = '#222'
// const dark = '#333'
// const dark = '#444'
const dark400 = '#555'

const light100 = '#FFF'
const light200 = '#FAFAFA'
// const light = '#F8F8F8'
// const light = '#F2F2F2'
// const light = '#EDEDED'
// const light = '#DADADA'
// const light = '#DADADA'
// const light = '#DADADA'

const colors = {
  border: 'rgba(255, 255, 255, 0.33)',
  text: '#3E3E3E',
  background: '#fff',
  gray: '#D0D1D1',

  primary: yellowColor,
  secondary: greenColor,

  alwaysGreen: greenColor,
  alwaysYellow: yellowColor,

  accent: '#005FE2',
  muted: '#f6f6f6',
  neutral: '#273475',

  success: '#00C781',
  error: '#FF4040',
  warning: '#FFAA15',

  'green-1': '#16993b',
  'green-2': '#06A54D',
  'green-3': '#00706A',

  'dark-0': 'black',
  'dark-1': '#222',
  'dark-2': '#333',
  'dark-3': '#444',
  'dark-4': '#555',

  'red-1': '#DA2037',

  dark100,
  dark400,

  'light-0': '#FAFAFA',
  'light-1': '#F8F8F8',
  'light-2': '#F2F2F2',
  'light-3': '#EDEDED',
  'light-4': '#DADADA',
  'light-5': '#DADADA',
  'light-6': '#DADADA',

  light100,
  light200,

  'alpha-25': 'rgba(255, 255, 255, .25)',
  'alpha-50': 'rgba(255, 255, 255, .5)',
  'alpha-75': 'rgba(255, 255, 255, .75)',
  'alpha-100': 'rgba(255, 255, 255, 1)',

  'd-alpha-25': 'rgba(0, 0, 0, .25)',

  // Card colors
  tagColor: '#005FE2',

  modes: {
    dark: {
      secondary: yellowColor,
      primary: greenColor,
      light100: dark400,

      // Card component.
      tagColor: light100,
    },
  },
}

export default colors
