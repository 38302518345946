import React from "react";

import SearchPropertyCard from "../../enhancers/SearchPropertyCard";
import SearchPropertyPopup from "../../enhancers/SearchPropertyPopup";

import SearchBar from "./sections/search-bar";
import SearchView from "@coninsa-site/project/src/pages/search-view/search-view";

import searchRentalHousingConfigs from "../../facets/search-rental-housing";

import Button from "@coninsa-s2/button";
import StarIcon from "../../../icons/star.inline.svg";

interface SearcRentalPageProps {
  navigate: (string) => void;
}

export const SearcRentalPage: React.FC<SearcRentalPageProps> = ({
  navigate = (url) => console.log(url),
}) => {
  return (
    <div>
      <Button href="/inmuebles/destacados" target="_blank" vertically>
        <StarIcon />
        Ver recomendados
      </Button>
      <SearchView
        navigate={navigate}
        searchBar={SearchBar}
        itemCard={SearchPropertyCard}
        popupCard={SearchPropertyPopup}
        configs={searchRentalHousingConfigs}
      />
    </div>
  );
};

export default SearcRentalPage;
