import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../../layout/default/Layout";
import McvGuide from "../../../packages/site/mcv-guide";

function McvPageGuide() {
  return (
    <Layout>
      <Helmet>
        <title>Guia para usuarios de MCV</title>
        <link rel="canonical" href="https://www.coninsa.co/corporativo/guia-para-usuarios-de-mi-coninsa-virtual" />
      </Helmet>

      <McvGuide />
    </Layout>
  );
}

export default McvPageGuide;