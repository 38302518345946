import React, { useEffect } from "react";

const Hotjar = ({ trackingId }) => {
  useEffect(() => {
    const inlineScript = document.createElement("script");

    inlineScript.innerHTML = `
    (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:${trackingId},hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `;
    document.head.appendChild(inlineScript);

    return () => {
      document.head.removeChild(inlineScript);

      const generatedScript = document.head.querySelector(
        `[src*="${trackingId}"]`
      );

      document.head.removeChild(generatedScript);
    };
  }, [trackingId]);

  return null;
};

export default Hotjar;
