import React, { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import usePortal from 'ui/utils/use-portal'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

import ModalWrapper from './ModalWrapper'
import ModalContext from './ModalContext'

import Backdrop from '../Backdrop'
import { Icon } from 'ui'

const Modal = ({ children, open, onClose, variant, showCloseControl, color, pr, pb, ...props }) => {
  const portal = usePortal('modal')
  const [visible, setVisible] = useState(false)

  const closeModal = arg => {
    onClose && onClose(arg)
    setVisible(false)
  }

  useEffect(() => {
    setVisible(open)
  }, [open])

  const modalConfig = {
    close: closeModal,
  }

  // Check If the portal is defined or not before continue (SSR).
  if (!portal) {
    return null
  }

  const closeStyles = {
    cursor: 'pointer',
    color: color,
    display: 'flex',
    fontSize: ['14px', '16px'],
    fontWeight: 'bold',
    gap: 1,
    alignItems: 'center',
    bottom: 0,
    textTransform: 'uppercase',
    zIndex: 10001,
    paddingRight: pr,
    paddingBottom: pb,
  }

  return createPortal(
    <ModalContext.Provider value={modalConfig}>
      <Backdrop visible={visible} variant={variant} sx={{}}>
        <ModalWrapper variant={variant} {...props}>
          {children}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {visible && showCloseControl && (
              <Box as='button' onClick={closeModal} sx={closeStyles}>
                Cerrar
                <Icon name='close' sx={{ fontSize: [3, 4] }} />
              </Box>
            )}
          </Box>
        </ModalWrapper>
      </Backdrop>
    </ModalContext.Provider>,
    portal
  )
}

Modal.defaultProps = {
  open: false,
  variant: '',
  showCloseControl: false,
  color: 'white',
  pr: '',
  pb: ''
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
  showCloseControl: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.string,
  color: PropTypes.string,
  pr: PropTypes.string,
  pb: PropTypes.string,
}
export default Modal
