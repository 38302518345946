import React from 'react'
import PropTypes from 'prop-types'
import { Box, Image } from 'theme-ui'

import Hero from 'ui/Hero'
import { Header } from 'ui'

const PBasicHero = props => {
  const { background, image, title, variant } = props
  const baseStyles = {
    display: ['block', 'flex'],
    width: '100%',
    zIndex: 1,
    position: 'relative',
    alignItems: 'flex-end',
  }

  return (
    <Hero variant={variant} bgImages={[background?.src, background?.src]}>
      <h1 hidden>{title}</h1>
      <Header 
        sx={{
          mb: [0, 'xlarge'],
          pb: ['small', 0],
          width: [null, 'calc(100% - 308px)'],
        }}
      >
        {title}
      </Header>

      <Box __css={baseStyles}>
        {image?.src && (
          <Box
            __css={{
              display: ['none', 'block'],
              flexShrink: 0,
              mt: ['medium', null],
              ml: [null, 'medium'],
              width: [null, '220px'],
              border: '6px solid',
              position: 'absolute',
              borderColor: 'neutral',
              right: 0,
              bottom: '-45px',
              height: '220px',
              overflow: 'hidden',
            }}
          >
            <Image sx={{ height: '100%', objectFit: 'cover' }} src={image.src} />
          </Box>
        )}
      </Box>
    </Hero>
  )
}

PBasicHero.defaultProps = {
  video: {},
  image: {},
  variant: 'default',
  background: [],
}

PBasicHero.propTypes = {
  background: PropTypes.shape({
    src: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string,
  }),
  variant: PropTypes.string,
  video: PropTypes.shape({
    url: PropTypes.string,
  }),
}

export default PBasicHero
