import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

import { CallToAction, Header } from 'ui'

function PCallToAction({ description, image, subtitle, variant, title, ...props }) {
  return (
    <CallToAction variant={variant} {...props}>
      <Box sx={{ alignSelf: 'center' }}>
        <Header variant={variant}>{title}</Header>
        <CallToAction.SubTitle>{subtitle}</CallToAction.SubTitle>
        <CallToAction.Body dangerouslySetInnerHTML={{ __html: description }} />
      </Box>
      <Box
        sx={{
          textAlign: 'right',
          alignSelf: 'center',
        }}
      >
        <CallToAction.Image alt={image.alt} src={image.src} />
      </Box>
    </CallToAction>
  )
}

PCallToAction.defaultProps = {
  description: '',
  image: {},
  subtitle: '',
  variant: 'default',
}

PCallToAction.propTypes = {
  description: PropTypes.string,
  image: PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string,
  }),
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  variant: PropTypes.string,
}

export default PCallToAction
