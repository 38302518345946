import React from "react";
import PropTypes from "prop-types";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Box } from "theme-ui";

import { Section } from "components";
import { normalizeCanon } from "../utils";
import Heading from "@coninsa-s2/heading";
import { arrowPath } from "@coninsa-s2/slider";

import EntityPropertyCard from "@coninsa-site/property/src/enhancers/EntityPropertyCard.tsx";

const getNormalizedLocationProperty = (location = {}) =>
  [
    location?.entity?.parent[0]?.entity?.parent[0]?.entity?.name,
    location?.entity?.parent[0]?.entity?.name,
    location?.entity?.name,
  ].join(", ");

function RelatedProperties({ properties, ...props }) {
  if (!properties.length) {
    return null;
  }

  const options = {
    arrowPath: arrowPath,
    arrows: true,
    gap: 0,
    padding: { left: 0, right: "160px" },
    perPage: 3,
    perMove: 1,
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: 0,
        perPage: 1,
        padding: { left: 0, right: "1.5rem" },
        pagination: true,
      },
    },
  };

  // filter property only with at least one image.
  const propertiesWithImages = properties.filter(
    (property) => property?.images.length !== 0
  );

  if (propertiesWithImages.length === 0) {
    return null;
  }

  return (
    <Section variant="grayly" {...props}>
      <Heading tint="teal" size="3xl" className="mb-6" condensed>
        Inmuebles similares
        <span className="is-highlighted"> a la búsqueda</span>
      </Heading>

      <Box>
        <Splide options={options} className="s2-slider s2-slider--lg">
          {propertiesWithImages.map((property) => (
            <SplideSlide>
              <div className="px-2 pt-2 pb-4 h-full">
                <EntityPropertyCard
                  key={property?.id}
                  code={property?.code}
                  price={normalizeCanon(
                    property?.leaseValue,
                    property?.saleValue
                  )}
                  bedrooms={property?.bedrooms}
                  bathrooms={property?.bathrooms}
                  url={property?.url?.path}
                  area={property?.area}
                  images={property?.images}
                  serviceType={property?.serviceType}
                  location={getNormalizedLocationProperty(
                    property?.relatedLocation
                  )}
                  propertyType={property?.propertyType?.entity?.name}
                  state={property?.state?.entity}
                />
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </Box>
    </Section>
  );
}

RelatedProperties.defaultProps = {
  properties: [],
};

RelatedProperties.propTypes = {
  properties: PropTypes.arrayOf({}),
};

export default RelatedProperties;
