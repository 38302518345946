import React from "react";
import { cn } from "@coninsa-s2/utils";

import "./styles.css";

interface T_props {
  image?: string;
  slipped?: boolean;
  active?: boolean;
  variant?: "default" | "compact";
  className?: string;
  children?: React.ReactNode;
}

function ImageCard({
  image,
  slipped = false,
  active = false,
  variant = "default",
  className,
  children,
  ...props
}: T_props) {
  const dynamicClasses = {
    "s2-image-card--slipped": slipped,
    "s2-image-card--active": active,
    [`s2-image-card--slipped-${variant}`]: variant,
  };

  return (
    <div className={cn("s2-image-card", dynamicClasses, className)} {...props}>
      {image && (
        <div className="s2-image-card__image">
          <img src={image} />
        </div>
      )}
      <div className="s2-image-card__content">{children}</div>
    </div>
  );
}

export default ImageCard;
