import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import { Box, Button, Input, Spinner, Textarea } from 'theme-ui'
import { Field, InputMask } from 'ui/form'
import { useMutation } from '@apollo/client'

import Toast from 'ui/Toast'
import { WEBFORM_SUBMIT } from '../../modules/services/mutations'

const ContactServicesForm = ({ form }) => {
  const [showToast, setShowToast] = useState(false)
  const [toastSettings] = useState({ position: 'bottom-right' })

  const [webformSubmit] = useMutation(WEBFORM_SUBMIT)
  const { 
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
    register
  } = useForm()

  const list = [
    {
      id: 'pss1',
      title: 'Gracias!',
      description: 'Su información fue enviada con éxito.',
      backgroundColor: '#85bb25',
      icon: 'success',
    },
  ]

  const onSubmit = async data => {
    const { fullName, email, phone, message } = data

    const normalizedData = {
      full_name: fullName,
      email,
      phone,
      message,
      origin: window.location.href,
      webform_id: form.id,
    }

    const webformData = JSON.stringify(normalizedData)

    return new Promise((resolve, reject) => {
      webformSubmit({
        variables: {
          values: webformData,
        },
      })
        .then(response => {
          const { errors: errorsForm } = response.data.submitForm

          if (errorsForm.length) {
            console.error(errorsForm)
            return
          }

          reset()
          setShowToast(true)
          resolve(response)
        })
        .catch(error => {
          console.error(error.message)
          reject(error)
        })
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {showToast && (
        <Toast
          toastList={list}
          {...toastSettings}
          autoDelete
          autoDeleteTime={3000}
        />
      )}

      <Field>
        <Input
          placeholder='Tu nombre completo'
          type='text'
          {...register('fullName')}
          required
        />
      </Field>

      <Box sx={{ display: [null, 'flex'] }}>
        <Field sx={{ width: [null, '50%'], mr: [null, 2] }}>
          <Controller
            control={control}
            name='phone'
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <InputMask
                placeholder='Teléfono de contacto'
                type='tel'
                format='+57 (###) ###-####'
                mask='_'
                onBlur={onBlur}
                onChange={onChange}
                checked={value}
                inputRef={ref}
                required
              />
            )}
          />
        </Field>

        <Field sx={{ width: [null, '50%'], ml: [null, 2] }}>
          <Input
            placeholder='Tu correo electrónico'
            type='email'
            {...register('email')}
            required
          />
        </Field>
      </Box>

      <Field>
        <Textarea
          rows={5}
          placeholder='Escribe los detalles de tu solicitud'
          {...register('message')}
        />
      </Field>

      <Button
        sx={{ width: ['100%', 'auto'] }}
        disabled={isSubmitting}
        loading={isSubmitting}
      >
        {isSubmitting ? (
          <Spinner variant='styles.spinner' size='20px' />
        ) : (
          'Solicitar información'
        )}
      </Button>
    </form>
  )
}


ContactServicesForm.propTypes = {
  form: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
}

export default ContactServicesForm
