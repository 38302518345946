import React from 'react'
import IframeResizer from 'iframe-resizer-react'
import { Helmet } from 'react-helmet'
import Layout from '../layout/default/Layout'

import bannerImage from '../images/generic-banner.png'
import saleServiceIcon from '../assets/icons/co-after-sale-service.svg'

const SITE_URL = 'https://busquedas.coninsa.co'

function BussinessEthic() {
  const showBodyCard = bodyCardId => event => {
    const bodyElements = document.querySelectorAll('.tab-body')
    const tabElements = document.querySelectorAll('.tab-label[data-active]')

    bodyElements.forEach(element => {
      element.classList.add('hidden')
    })

    tabElements.forEach(element => {
      delete element.dataset.active
    })

    const buttonElement = event.currentTarget

    buttonElement.dataset.active = true

    // Look for body card element.
    const bodyElement = document.querySelector(bodyCardId)

    if (bodyElement) {
      bodyElement.classList.remove('hidden')
    }
  }

  return (
    <Layout>
      <Helmet>
        <title>Ética y Transparencia Empresarial | Coninsa</title>
        <meta name="description" content="a sido creada para que los Miembros de Junta Directiva, Accionistas, Socios de negocios, Colaboradores, Proveedores, Contratistas, Clientes y el Público en General, puedan reportar anónimamente hechos reales o potenciales de corrupción, irregularidades, hechos incorrectos, delictivos o cualquier otro tipo de situaciones que afecten la Compañía." />
        <link rel="canonical" href="https://www.coninsa.co/etica-empresarial-y-transparencia/" />
      </Helmet>
      <div
        style={{ backgroundImage: `url(${bannerImage})` }}
        className='bg-contain bg-no-repeat md:bg-cover px-5 py-12 md:px-0 md:py-44'
      >
        <div className='container'>
          <h2 className='font-display text-coninsa-blue-900 font-medium text-xl md:text-4xl uppercase'>
            Ética y Transparencia Empresarial
          </h2>
        </div>
      </div>

      <div className='bg-coninsa-gray-300 '>
        <div className='container px-5 md:px-0 py-7 md:py-14 text-sm md:text-base'>
          <p className='mb-3'>
          Ha sido creada para que los Miembros de Junta Directiva, Accionistas, Socios de negocios, 
          Colaboradores, Proveedores, Contratistas, Clientes y el Público en General, 
          puedan reportar anónimamente hechos reales o potenciales de corrupción, irregularidades,
          hechos incorrectos, delictivos o cualquier otro tipo de situaciones que afecten la Compañía.         
          </p>

          <p className='mb-3'>
            
          Éste es un canal donde la información recibida tiene un 
          tratamiento bajo parámetros estrictos de confidencialidad, anonimato y respeto.
          Las personas que se contacten permanecerán en el anonimato y bajo ninguna circunstancia 
          se revelará la identidad de las mismas sin su consentimiento.
          Los temas que se podrán tratar o denunciar a través de la Línea Ética y de Transparencia 
          de Coninsa hacen referencia a las infracciones al Programa de Transparencia y Ética Empresarial (PTEE), 
          al Código de Buen Gobierno y al SAGRILAFT (Sistema de Autocontrol y Gestión del Riesgo Integral del Lavado de Activos, 
          Financiación del Terrorismo y la Proliferación de Armas de Destrucción Masiva).
          </p>

          <p className='mb-3'>
           
          Muy específicamente, son todos aquellos delitos, operaciones intentadas o 
          sospechosas relacionadas con el conflicto de intereses, soborno transnacional, 
          cohecho, tráfico de influencias, estafa, fraude, lavado de activos, 
          financiación del terrorismo y de la proliferación de armas de destrucción masiva, 
          violación a los sistemas y a la seguridad de la información y cualquier 
          tipo de conducta que vaya en contra de los valores, la misión, visión y buenas prácticas establecidas por la Compañía.
          A través de este canal también podrás reportar situaciones internas al Comité de Convivencia Laboral, 
          el cual tiene por objeto prevenir las conductas de aparente acoso laboral y atenderlas en caso de presentarse. 
          El Comité procurará promover relaciones laborales propicias para la salud mental y 
          el respeto a la dignidad de los colaboradores, mediante estrategias de promoción, 
          prevención e intervención para la resolución de conflictos.

 
          </p>

          <p>
          Se debe tener en cuenta que los temas comerciales relacionados 
          con la prestación de los servicios y la calidad y garantía de 
          los productos serán atendidos por los canales de atención al cliente.
          </p>
        </div>
      </div>

      <div className='container py-4 md:py-8'>
        <div className='py-6'>
          <div className='flex flex-col md:flex-row gap-4 mb-4 md:justify-center px-5 py-3'>
            <button
              className='tab-label bg-neutral-white flex cursor-pointer flex-col items-center rounded-lg border-none
                px-5 py-4 text-coninsa-blue-900 shadow-[0px_1px_4px_#00000029]
                hover:bg-coninsa-gray-300 data-[active=true]:bg-coninsa-blue-300 data-[active=true]:text-white'
              onClick={showBodyCard('#one')}
            >
              <outline-icon class='mb-3' src={saleServiceIcon} />
              Formulario de delitos o alertas de <br />
              <strong className='font-semibold'>
                operaciones sospechosas o intentadas
              </strong>
            </button>

            <button
              className='min-w-[336px] tab-label bg-neutral-white flex cursor-pointer flex-col items-center rounded-lg border-none
                px-5 py-4 text-coninsa-blue-900 shadow-[0px_1px_4px_#00000029]
                hover:bg-coninsa-gray-300 data-[active=true]:bg-coninsa-blue-300 data-[active=true]:text-white'
              onClick={showBodyCard('#two')}
            >
              <outline-icon class='mb-3' src={saleServiceIcon} />
              Formulario de comité de
              <br />
              <strong className='font-semibold'>convivencia laboral</strong>
            </button>
          </div>
        </div>

        <div id='one' className='tab-body hidden'>
          <IframeResizer
            className='w-full'
            src={`${SITE_URL}/form/suspicious-attempted-crimes`}
          ></IframeResizer>
        </div>

        <div id='two' className='tab-body hidden'>
          <IframeResizer
            className='w-full'
            src={`${SITE_URL}/form/labor-coexistence-committee`}
          ></IframeResizer>
        </div>
      </div>
    </Layout>
  )
}

export default BussinessEthic
