export default {
  grayly: {
    bg: 'light-0',
  },

  bluey: {
    bg: 'neutral',
    color: 'white',
  },

  greenly: {
    bg: 'secondary',
    color: 'white',
  },

  whitely: {
    bg: 'white',
  },

  darkly: {
    bg: 'dark-1',
    color: 'white',
  },
}
