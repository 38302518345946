import React, { useState, useEffect } from "react";
import * as Dialog from "@coninsa-s2/dialog";

import SuggestionForm from "./suggestion-form";
import { DialogPortal } from "@radix-ui/react-dialog";

export default function SuggestionFormModal({ baseUrl }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      const suggestionFormStatus = localStorage.getItem(
        "suggestion_form.status"
      );

      if (!open && suggestionFormStatus !== "dirty") {
        setOpen(true);
        localStorage.setItem("suggestion_form.status", "clean");
      }
    }, 600000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <DialogPortal>
        <Dialog.Overlay />
        <Dialog.Content>
          <Dialog.Title asChild>
            <h4 className="font-bold text-s2-dark-blue lg:text-lg">
              ¿No encontraste lo que buscabas?
            </h4>
          </Dialog.Title>
          <div>
            <div className="mb-5">
              <p className="text-sm text-slate-600">
                Déjanos tus datos para ayudarte en tu búsqueda.
              </p>
            </div>
            <SuggestionForm baseUrl={baseUrl} />
          </div>
        </Dialog.Content>
      </DialogPortal>
    </Dialog.Root>
  );
}
