import { gql } from '@apollo/client'

export const LEGAL_PERSON_APPLICATION = gql`
  query LegalApplicationNode($id: String!) {
    node: nodeById(id: $id) {
      bundle: entityBundle
      ... on NodeLegalPersonApplication {
        id: nid
        title
        fieldEconomicActivity
        fieldLastName
        fieldLegalRepresentativePhone
        fieldContactCellPhone
        fieldCrmConsultant
        fieldContactEmail
        fieldCorrespondenceAddress
        fieldEmail
        fieldApplicationStatus
        fieldEnablePdfApplication
        fieldPropertyCode
        fieldNit
        fieldContactPersonName
        fieldFirstName
        fieldIdNumber
        fieldEmployeesNumber
        fieldSocialReason
        fieldIcaResponsible
        fieldCompanyType
        fieldIdType
        fieldShareholders {
          document
          name
          share
        }
        fieldCreditAnalyst {
          targetId
        }
        fieldFiscalCity {
          department
          city
          neighborhood
        }
        fieldIncomeStatement {
          targetId
          display
          description
          entity {
            fid
            filename
            url
          }
        }
        fieldIdentificationDocument {
          targetId
          display
          description
          entity {
            fid
            filename
            url
          }
        }
        fieldAdditionalDocuments {
          targetId
          display
          description
          entity {
            fid
            filename
            url
          }
        }
        fieldFinancialState {
          targetId
          display
          description
          entity {
            fid
            filename
            url
          }
        }
        fieldExistenceRepresentation {
          targetId
          display
          description
          entity {
            fid
            filename
            url
          }
        }
        fieldBankStatements {
          targetId
          display
          description
          entity {
            fid
            filename
            url
          }
        }
        fieldPropertyTax {
          targetId
          display
          description
          entity {
            fid
            filename
            url
          }
        }
        fieldCommercialReference {
          store
          phone
        }
        fieldRut {
          targetId
          display
          description
          entity {
            fid
            filename
            url
          }
        }

        fieldCotenants {
          targetId
          entity {
            bundle: entityBundle
            id: nid
            status
            title
            created
            changed
          }
        }
      }
    }
  }
`