import React, { useEffect, useState } from "react";

import virtualIcon from "../assets/icons/co-virtual.svg"

import './style.css'

export default function ThankYouPage() {
  const [redirectTo, setRedirectTo] = useState('/')

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const destination = urlParams.get('destination');

    if (destination) {
      setRedirectTo(destination)
    }
  }, [])

  return (
    <div>
      <div className="linear-bg" data-cid="wxz1201">
        <div className="hero h-[576px] md:h-[470px]">
          <div className="hero-container md:container">
            <div className="hero-content sm:w-2/3 md:w-1/2 px-6 md:px-0 py-8 md:py-0 text-center">
              <h1 className="font-heading text-white leading-6 text-[32px] md:text-[40px] md:leading-10 mb-3 md:mb-6">
                <span className="block font-handwritting font-light text-[var(--color-secondary)] text-6xl md:text-7xl">
                  ¡Gracias
                </span>
                Por tu interés!
              </h1>
              <p className="font-light text-white text-lg md:text-2xl mb-4 leading-6">
                Recibimos tus datos, pronto nos pondremos en contacto contigo para brindarte mayor información.
              </p>
              <a href={redirectTo} className="co-button is-tertiary--alternative">
                Volver al inicio
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className='container py-8 md:py-16'>
        <div className='grid lg:grid-cols-3 gap-4'>
          <div className="co-card md:py-10 text-center">
            <h3
              className='font-display font-semibold mb-6 text-[var(--color-primary)] text-[26px] md:text-[33px] uppercase'
            >
              ¿Necesitas ayuda?
            </h3>

            <div className='flex gap-4 items-center'>
              <img src={virtualIcon} alt="Virtual icon" class='w-20 rounded-full' />
              <p
                className='text-[var(--color-neutral-700)] leading-4 text-left md:text-xl md:leading-5'
              >
                Habla con la asistente virtual Coninsa {" "}
                <span className='font-bold'>
                  a través de {" "}
                  <a
                    href='https://api.whatsapp.com/send?phone=573123636333&text=Hola!'
                    className='text-[var(--color-green-400)]'
                    target='_blank' rel="noreferrer"
                  >
                    WhatsApp
                  </a>
                </span>
              </p>
            </div>
          </div>

          <div className="co-card md:py-10 text-center">
            <h3
              className="font-display font-semibold mb-8 text-[var(--color-primary)] text-[26px] md:text-[33px] leading-8 uppercase"
            >
              Proyectos nuevos
              <span className="block font-extralight">para comprar</span>

            </h3>

            <a
              className="co-button is-progress-default uppercase"
              href="/proyectos"
              target="_blank"
            >
              Conoce nuestros proyectos
            </a>
          </div>

          <div className="co-card md:py-10 text-center">
            <h3
              className="font-display font-semibold mb-8 text-[var(--color-primary)] text-[26px] md:text-[33px] leading-8  uppercase"
            >
              <span className="block font-extralight">¿Necesitas un inmueble</span>
              en arriendo?
            </h3>

            <a
              className="co-button is-progress-default uppercase"
              href="/inmuebles/arriendo"
              target="_blank"
            >
              Todos los inmuebles
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
