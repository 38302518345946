import React from "react";

import Button from "@coninsa-s2/button";
import Modal from "@coninsa-s2/modal";
import Heading from "@coninsa-s2/heading";
import CloseIcon from "@coninsa-s2/icon/assets/close.inline.svg";

import "@coninsa-s2/form/src/input.styles.css";

interface ModalExposedFormProps {
  resultCount: number;
  loading: boolean;
  closeModal: () => void;
  resetValues: () => void;
  children: React.ReactNode;
}

const ModalExposedForm: React.FC<ModalExposedFormProps> = ({
  resultCount,
  loading,
  children,
  closeModal,
  resetValues,
}) => {
  return (
    <Modal
      width={600}
      align="right"
      variant="full-screen"
      header={
        <>
          <Heading size="lg" weight="bold">
            Más filtros
          </Heading>

          <Button
            onClick={closeModal}
            variant="ghost"
            samePadding
            aria-label="Cerrar"
          >
            <CloseIcon />
          </Button>
        </>
      }
      footer={
        <>
          <Button
            tint="lime"
            variant="ghost"
            type="button"
            onClick={resetValues}
          >
            Limpiar filtros
          </Button>

          <Button type="button" onClick={closeModal} disabled={loading}>
            {loading && "Cargando ..."}
            {!loading && resultCount > 1 && `Mostrar ${resultCount} resultados`}
            {!loading &&
              resultCount === 1 &&
              `Mostrar ${resultCount} resultado`}
            {!loading && resultCount === 0 && `No hay resultados`}
          </Button>
        </>
      }
    >
      {children}
    </Modal>
  );
};

export default ModalExposedForm;
