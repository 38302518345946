import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

import "./styles.css";

function Banner({ mobileSlideImages = [], slideImages = [], ...props }) {
  const bannerRef = useRef();
  const [height, setHeight] = useState(500);

  const handleWindowSizeChange = () => {
    setHeight(bannerRef.current.offsetHeight);
  };

  useEffect(() => {
    if (bannerRef.current) {
      setHeight(bannerRef.current.offsetHeight);
    }

    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const options = {
    arrows: false,
    type: "fade",
    autoplay: true,
    rewind: true,
    perPage: 1,
    interval: 10000,
    pagination: false,
  };

  return (
    <div className={clsx("s2-slide-banner")} ref={bannerRef}>
      <div className="s2-slide-banner__image--mobile">
        <Splide options={options}>
          {mobileSlideImages.map((url, index) => (
            <SplideSlide key={index}>
              <div
                rel="preload"
                className="s2-slide-banner__bg"
                style={{
                  backgroundImage: `url(${url})`,
                  height: `${height}px`,
                }}
              />
            </SplideSlide>
          ))}
        </Splide>
      </div>

      <div className="s2-slide-banner__slider">
        <Splide options={options}>
          {slideImages.map((url, index) => (
            <SplideSlide key={index}>
              <div
                rel="preload"
                className="s2-slide-banner__bg"
                style={{
                  backgroundImage: `url(${url})`,
                  height: `${height}px`,
                }}
              />
            </SplideSlide>
          ))}
        </Splide>
      </div>

      <div className="s2-slide-banner__content">{props.children}</div>
    </div>
  );
}

export default Banner;
