import React, { useState } from 'react'
import { Box, Input } from 'theme-ui'
import { useForm } from 'react-hook-form'
import { Button } from 'ui'
import axios from 'axios'

const DRUPAL_SING_UP_APPLICANT_URL =
  'https://admindrupal.coninsa.co/api/auth/applicant/sign-up'

export const encodeFormData = data => {
  const body = new FormData()

  Object.keys(data).forEach(key => {
    body.append(key, data[key])
  })

  return body
}

function Register(props) {
  const [errorMsg, setErrorMsg] = useState()
  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {},
  })

  const onSubmit = async data => {
    try {
      await axios.post(DRUPAL_SING_UP_APPLICANT_URL, encodeFormData(data), {
        withCredentials: true,
      })

      window.location.reload()
    } catch (error) {
      const status = error?.response?.data?.status

      if (status === 'fail') {
        const { code, message } = error?.response?.data?.data || {}

        if (code === 'DUPLICATED_USER') {
          setErrorMsg(message)
        }
      }
    }
  }
  return (
    <Box>
      {errorMsg && <Box sx={{ mb: 4, color: 'red', fontSize: 1 }}>{errorMsg}</Box>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb='small'>
          <Input {...register('name')} placeholder='Nombres' required />
        </Box>
        <Box mb='small'>
          <Input {...register('lastName')} placeholder='Apellidos' required />
        </Box>
        <Box mb='small'>
          <Input
            {...register('document')}
            placeholder='Número de identificación'
            required
          />
        </Box>
        <Box mb='small'>
          <Input {...register('email')} placeholder='Correo electrónico' required />
        </Box>
        <Box mb='small'>
          <Input {...register('phone')} placeholder='Número celular' required />
        </Box>

        <Box>
          <Button disabled={isSubmitting} loading={isSubmitting} type='submit'>
            Continuar
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default Register
