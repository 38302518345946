import React from 'react'
import { Box ,Container ,Grid, Image, Link } from 'theme-ui'

import json from '../data/stores.json'


const Stores = () => {
  
    const contentBannerStyles = {
        bg: 'white',
        color: 'black',
        fontSize: ['14px', '16px'],
        p: 'medium',
        boxShadow: '0 3px 6px #00000029',
        borderRadius: "10px",
        width: '100%',
        '@media screen and (max-width: 576px)': {
          p: 'large',
          mb: '10px'
        },
        bottom: 0,
      }
      const imagePin = {
        p: 'medium',
        right: 0,
      }

      const contentResponsiveStyles = {
        '@media screen and (max-width: 576px)': {
          'display':'list-item',
          'list-style': 'none',
          p: 'large',
        },
      }
  // Prevent render on SSR.
  if (typeof window === 'undefined') return null

  return (
    <Box bg='white' py='large' px='medium'>
        <Container>
            <Grid sx={contentResponsiveStyles} gap={4} columns={[4, '0.5fr 0.5fr 0.5fr 0.5fr']}>
            {json.stores.map((data, index) => {
                return (
                    <a href={data.url}><Box sx={contentBannerStyles}><Image sx={imagePin}  src={data.logo} /></Box></a>
                    )
            })}
            </Grid>
        </Container>
    </Box>
  )
}

export default Stores
