import React, { useContext } from "react";
import clsx from "clsx";
import Chip from "@coninsa-s2/chip";

import { CurrencyContext } from "./CurrencyContext";
import "./styles.css";

interface CurrentSelectorProps {
  align?: "left" | "right";
  className?: string;
  style: React.CSSProperties;
}

const CurrencySelectors: React.FC<CurrentSelectorProps> = ({
  align,
  className,
}) => {
  const currencySettings = useContext(CurrencyContext);
  const date = new Date().toLocaleDateString("es-CO");

  const dynamicClasess = {
    [`s2-currency-selector--${align}`]: align,
  };

  return (
    <div className={clsx("s2-currency-selector", dynamicClasess, className)}>
      <div className="s2-currency-selector__actions">
        {["cop", "usd", "eur"].map((currencyType) => (
          <Chip
            key={`bnt-${currencyType}`}
            active={currencyType === currencySettings.currency}
            onClick={() => currencySettings.setCurrency(currencyType)}
          >
            {currencyType.toUpperCase()}
          </Chip>
        ))}
      </div>

      <div className="s2-currency-selector__description">
        Este precio está calculado según la TRM del día {date}, tomada del Banco
        de la República y corresponde a una aproximación sobre el precio en
        pesos colombianos. Los términos y condiciones del proyecto, son los
        aplicados al valor en pesos.
      </div>
    </div>
  );
};

export default CurrencySelectors;
