import React from 'react'
import PropTypes from 'prop-types'

export default function InfoCard({ icon, children }) {
  return (
    <div className='bg-white rounded-lg px-6 py-2 shadow-[0px_3px_6px_#00000029] mb-12 md:mb-0 md:px-8'>
      <div className='relative -translate-y-5'>
        <img
          className='absolute block -left-10 -top-8 z-10 w-24 h-24 md:-left-24 md:-top-4 md:w-auto md:h-auto'
          alt='web-icon'
          src={icon}
        />
        {children}
      </div>
    </div>
  )
}

InfoCard.propTypes = {
  icon: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
}
