import React from 'react'
import { useQuery } from '@apollo/client'
import { Spinner, Container, Message } from 'theme-ui'

import { GET_PROPERTY_BY_CODE } from '../modules/search-building/queries'
import Layout from '../layout/default/Layout'
import Property from '../templates/property'

const validatePropertyPathname = (pathname = '') => {
  const arr = pathname.replace(/^\//, '').replace(/\/$/, '').split('/')
  const lastItem = arr[arr.length - 1]
  const rightNumberOfElement = arr.length == 5
  const isNumber = parseInt(lastItem) !== NaN

  return rightNumberOfElement && isNumber
}

const getCodePropertyFromPathname = (pathname = '') => {
  const arr = pathname.replace(/^\//, '').replace(/\/$/, '').split('/');
  const lastItem = arr[arr.length - 1];

  return parseInt(lastItem).toString();
}

function DynamicProperty(props) {
  const pathname = props.location.pathname;
  const isNotValid = !validatePropertyPathname(pathname)

  const { data, loading, error } = useQuery(GET_PROPERTY_BY_CODE, {
    variables: {
      code: getCodePropertyFromPathname(pathname),
    },
    skip: isNotValid
  })

  if (loading) {
    return (
      <Layout>
        <Container sx={{ display: 'flex', justifyContent: 'center', my: '6' }}><Spinner /></Container>
      </Layout>
    )
  }

  if (isNotValid) {
    return (
      <Layout>
        <Container sx={{ display: 'flex', justifyContent: 'center', my: '6', fontSize: 2 }}>
          <strong>
            Oops! Hay un error.
          </strong>
        </Container>
      </Layout>
    )
  }

  console.info('Dynamic Page!');

  // Build data structure as the Property template expect.
  const pData = { drupal: { entity: data?.building } }

  return (
    <Property {...props} data={pData} />
  )
}

export default DynamicProperty
