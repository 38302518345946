import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

const Divider = ({ variant, className, ...props }) => {
  const baseStyles = {
    border: 0,
    color: 'light-4'
  }

  return (
    <Box
      as='hr'
      {...props}
      variant={variant}
      className={className}
      __css={baseStyles}
      __themeKey='dividers'
    />
  )
}

Divider.defaultProps = {
  variant: 'horizontal'
}

Divider.propTypes = {
  variant: PropTypes.string
}

export default Divider
