import React, { useState } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { compose, pathOr, map } from 'ramda';

import { layoutResolver } from '../../layout'
import { ourProjectTeaserMapper } from '../../mappers/ourProjectsMapper'
import POurProjects from '../../paragraphs/POurProjects'

import Layout from '../../layout/architecture/Layout';
import BuildingCard from '../../modules/microsites/architecture/components/BuildingCard';
import SummaryCard from '../../modules/microsites/architecture/components/SummaryCard.jsx';

import bgGreen from '../../modules/microsites/assets/images/bg-green.jpg';
import buildingImage from '../../modules/microsites/assets/images/building-image.jpg';
import calendarIcon from '../../modules/microsites/assets/icons/calendar.svg';
import mapIcon from '../../modules/microsites/assets/icons/map.svg';

import '../../modules/leasing-property/style.css';

function Button({ children, ...props }) {
  return (
    <button
      className='border border-coninsa-green-500 font-semibold px-8 py-2 rounded-full
        text-coninsa-green-500 hover:bg-coninsa-green-500 hover:text-white
        aria-selected:bg-coninsa-green-500 aria-selected:text-white'
      {...props}
    >
      {children}
    </button>
  );
}

function ArchitecturePage({ data, ...props }) {
  const { layout, name } = props?.pageContext;
  const Layout = layoutResolver(layout);
  const defaultProjects = compose(
    map(ourProjectTeaserMapper),
    map(entity => ({ entity })),
    pathOr([], ['drupal', 'projects', 'entities'])
  )(data);

  console.log(data, 'DATA')
  console.log(defaultProjects, 'def')

  const [projects, setProjects] = useState(defaultProjects);


  const handleClick = region => event => {
    const button = event.target;

    if (!button.hasAttribute('aria-selected')) {
      const filteredProjects = defaultProjects.filter(project =>
        project?.location?.includes(region)
      );
      setProjects(filteredProjects);

      const buttons = document.querySelectorAll('#region-filter button');

      buttons.forEach(element => {
        element.removeAttribute('aria-selected');
      });

      button.setAttribute('aria-selected', 'true');
    } else {
      setProjects(defaultProjects);
      button.removeAttribute('aria-selected');
    }
  };

  return (
    <Layout>
      <div
        className='bg-coninsa-green-900 py-16'
        style={{ backgroundImage: `url(${bgGreen})` }}
      />

      <div className='pb-16'>
        <div className='container'>
          <h2
            className='bg-coninsa-green-500 font-display inline-block leading-[44px] mb-6 text-[44px]
            text-white uppercase px-6 py-5 rounded-lg transform -translate-y-20'
          >
            Nuestros proyectos
            <span className='block font-bold text-coninsa-yellow-200'>
              sotenibles
            </span>
          </h2>

          <div className='mb-6' id='region-filter'>
            <p className='font-normal mb-3 text-center'>Filtrar por ubicación</p>
            <div className='flex gap-2 justify-end'>
              <Button onClick={handleClick('Antioquia')}>Antioquia</Button>

              <Button onClick={handleClick('Cundinamarca')}>Cundinamarca</Button>

              <Button onClick={handleClick('Atlántico')}>Atlántico</Button>

              <Button onClick={handleClick('Magdalena')}>Magdalena</Button>
            </div>
          </div>

          <div className='mb-12'>
            {projects?.length > 0 && <POurProjects projects={projects} openNewTab />}
            {projects?.length === 0 && (
              <p className='text-lg text-coninsa-blue-900 text-center py-6'>
                No se encontraron proyectos para esta región.
              </p>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ArchitecturePage;

export const pageQuery = graphql`
  query ourProjects {
    drupal {
      projects: nodeQuery(
        filter: {
          conditions: [
            { operator: EQUAL, field: "status", value: ["1"] }
            { operator: EQUAL, field: "type", value: ["our_project"] }
          ]
        }
        limit: 100
      ) {
        entities {
          id: entityId
          url: entityUrl {
            path
          }

          ...TeaserNodeOurProject
        }
      }
    }
  }
`
