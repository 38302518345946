import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

export const RICH_TEXT_PARAGRAPH = 'Drupal_ParagraphRichText'

export function mapper(entity) {
  return entity
}

function PRichText({ body, ...props }) {
  const ref = useRef()

  const baseStyles = {
    '& a': {
      color: 'secondary',
    },

    '& h3': {
      color: 'secondary',
    },

    '& .accordion h3': {
      bg: 'primary',
      color: 'white',
      borderRadius: '5px',
      cursor: 'pointer',
      mb: 0,
      px: 'medium',
      py: 'small',
    },

    '& .accordion > div': {
      bg: 'light-2',
      display: 'none',
      px: 'medium',
      py: 'small',
    },

    '& .accordion > div.active': {
      display: 'block',
    },

    '.rtecenter, .text-align-center': {
      textAlign: 'center',
    },

    '.rteright, .text-align-right': {
      textAlign: 'right',
    },

    '.rtejustify, .text-align-justify': {
      textAlign: 'justify',
    },

    iframe: {
      minWidth: '100%',
    },
  }

  useEffect(() => {
    const dom = ref.current
    const items = dom.querySelectorAll('.accordion > h3')

    items.forEach(item => {
      item.addEventListener('click', event => {
        event.target.nextElementSibling.classList.toggle('active')
        event.preventDefault()
      })
    })
  }, [])

  return (
    <Box
      className='cke_editable'
      ref={ref}
      __css={baseStyles}
      dangerouslySetInnerHTML={{ __html: body?.value }}
      {...props}
    />
  )
}

PRichText.propTypes = {
  body: PropTypes.oneOf({
    value: PropTypes.string,
  }).isRequired,
}

export default PRichText
