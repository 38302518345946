import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import formStorage from "./storage/form";
import quotationStorage from "./storage/quotation";
import { bootCalculator } from "./proxy/calculator";
import ZohoApi from "./api/zoho-api";

/**
 * StepThree component
 */
export default function StepThree({ nextCallback, backCallback }) {
  const form = formStorage.get();
  const quotation = quotationStorage.get();
  const calculator = bootCalculator({ form, quotation });

  const [quotes, setQuotes] = useState([
    calculator?.cuotas[0]?.valorCuota,
    calculator?.cuotasDiferidas?.valorCuota,
  ]);

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { isSubmitting, defaultValues },
  } = useForm({
    defaultValues: async function () {
      if (form.initial_separation_value !== undefined) {
        return form;
      }

      return {
        has_savings: "1",
        separation_value: calculator.valorSeparacion,
        initial_separation_value: calculator.valorSeparacion,
      };
    },
  });

  const separationValue = watch("separation_value");
  const hasSavings = watch("has_savings");

  useEffect(() => {
    if (separationValue) {
      if (separationValue <= calculator.cuotaInicialTotal) {
        calculator.valorSeparacionVariable = separationValue;
        calculator.recalcularCotizacion();

        setQuotes([
          calculator?.cuotas[0]?.valorCuota,
          calculator?.cuotasDiferidas?.valorCuota,
        ]);
      } else {
        setValue("separation_value", calculator.cuotaInicialTotal);
      }
    }
  }, [separationValue]);

  useEffect(() => {
    if (hasSavings == 0) {
      setValue("separation_value", defaultValues.initial_separation_value);
    }
  }, [hasSavings]);

  const onSubmit = (data) => {
    formStorage.update(data);

    if (nextCallback) {
      nextCallback();
    }
  };

  return (
    <div className="co-card">
      <form className="co-form" onSubmit={handleSubmit(onSubmit)}>
        <h3 className="co-heading-4">
          ¿Con cuánto dinero quieres separar tu inmueble?
        </h3>
        <p className="co-quotation__description">
          Puedes agregar tus ahorros y/o cesantías en la separación de tu
          vivienda. Mientras más dinero aportes en la separación, las cuotas
          mensuales van a ser más bajas.
        </p>

        <fieldset className="form-group are-radios mb-2">
          <legend>¿Tienes ahorros y/o cesantías?</legend>
          <div className="form-items flex gap-4">
            <div className="form-control is-radio">
              <input
                {...register("has_savings")}
                type="radio"
                id="has_savings"
                value="1"
              />
              <label htmlFor="has_savings">Si</label>
            </div>
            <div className="form-control is-radio">
              <input
                type="radio"
                id="no_has_savings"
                value="0"
                {...register("has_savings")}
              />
              <label htmlFor="no_have_savings">No</label>
            </div>
          </div>
        </fieldset>

          <div className="text-xl font-medium md:text-2xl">
            ${Number.parseInt(separationValue).toLocaleString("es-CO")}
          </div>

          <div className="co-range">
            <div
              className="co-range__header"
              style={{
                width: `${
                  (calculator.cuotaInicialTotal / calculator.totalCalculado) *
                  100
                }%`,
              }}
            >
              <div className="co-range__label">
                <div className="co-range__label__title">Cuota inicial</div>
                <div className="co-range__label__value">
                  ${calculator.cuotaInicialTotal.toLocaleString("es-CO")}
                </div>
              </div>
            </div>

            <div>
              <input
                className="co-range__input"
                type="range"
                min={calculator.valorSeparacion}
                max={Number.parseInt(calculator.totalCalculado)}
                defaultValue={calculator.valorSeparacion}
                step="500000"
                disabled={hasSavings === "0"}
                {...register("separation_value")}
              />
            </div>

            <div className="co-range__footer">
              <div className="co-range__label">
                <span className="co-range__label__title">Separación</span>
                <div className="co-range__label__value">
                  ${calculator.valorSeparacion.toLocaleString("es-CO")}
                </div>
              </div>

              <div className="co-range__label">
                <div className="co-range__label__title">Precio total</div>
                <div className="co-range__label__value">
                  $
                  {Number.parseInt(calculator.totalCalculado).toLocaleString(
                    "es-CO"
                  )}
                </div>
              </div>
            </div>
          </div>

        <section className="mb-4">
          <h4 className="co-heading-4 mb-2">Cuota Inicial</h4>
          {quotes[0] !== 0 && (
            <div>
              {calculator?.cuotasDiferidas?.valorCuota ==
              calculator?.cuotas[0]?.valorCuota ? (
                <p className="co-quotation__description">
                  Se pagarán{" "}
                  <strong className="font-bold">
                    {calculator.nCuotasVariables} cuotas mensuales de $
                    {quotes[0]?.toLocaleString("es-CO")}
                  </strong>{" "}
                  hasta completar la cuota inicial del inmueble.
                </p>
              ) : (
                <p className="co-quotation__description">
                  Se pagaran{" "}
                  <strong className="font-bold">
                    {calculator.nCuotasVariables - 1} cuotas mensuales de $
                    {quotes[0].toLocaleString("es-CO")} y una cuota de $
                    {quotes[1].toLocaleString("es-CO")}
                  </strong>{" "}
                  hasta completar la cuota inicial del inmueble.
                </p>
              )}
            </div>
          )}

          {quotes[0] === 0 && (
            <p className="co-quotation__description">
              ¡Tu cuota de separación cubre por completo la cuota inicial!. Así
              que puedes seguir con el siguiente paso.
            </p>
          )}

          <input type="hidden" {...register("initial_separation_value")} />
        </section>

        <div className="form-actions flex justify-between gap-2">
          <button
            type="button"
            onClick={backCallback}
            className="co-button is-secondary is-large has-fixed-icon"
          >
            <i className="co-icon icon-arrow"></i>
            Regresar
          </button>
          <button className="co-button is-progress-default">Siguiente</button>
        </div>
      </form>
    </div>
  );
}
