import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

function MemberCard({ children, ...props }) {
  const baseStyles = {
    alignItems: 'center',
    display: ['block', 'flex'],
    textAlign: 'center',
    justifyContent: 'center',
  }
  return (
    <Box sx={baseStyles} {...props}>
      {children}
    </Box>
  )
}

MemberCard.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MemberCard
