import React from 'react'
import { Box, Input, Grid, Label, Radio } from 'theme-ui'

import { Field } from 'ui/form'
import { Heading } from 'ui'

function NaturalProperties({ register }) {
  return (
    <Box>
      <Box mb='medium'>
        <Heading level='3' variant='form'>
          Vehículos
        </Heading>
        <Grid gap={4} columns={[2, '1fr 1fr']}>
          <Field>
            <Label>Marca</Label>
            <Input {...register('fieldBrand')} type='text' />
          </Field>
          <Field>
            <Label>Modelo</Label>
            <Input {...register('fieldModel')} type='text' />
          </Field>
          <Field>
            <Label>Placa</Label>
            <Input {...register('fieldLicensePlate')} type='text' />
          </Field>
          <Box>
            <Box>Tiene prenda</Box>
            <Box sx={{ display: 'flex' }}>
              {['No', 'Si'].map((label, index) => (
                <Label>
                  <Radio {...register('fieldHasGarment')} value={index} />
                  {label}
                </Label>
              ))}
            </Box>
          </Box>
        </Grid>
      </Box>

      <Box mb='medium'>
        <Heading level='3' variant='form'>
          Inmuebles
        </Heading>
        <Grid gap={4} columns={[2, '1fr 1fr']}>
          <Field>
            <Label>Dirección del inmueble</Label>
            <Input {...register('fieldPropertyAddress')} type='text' />
          </Field>
          <Field>
            <Label>Matrícula</Label>
            <Input {...register('fieldPropertyRegistration')} type='text' />
          </Field>
          <Box>
            <Label>Tiene hipoteca</Label>
            <Box sx={{ display: 'flex' }}>
              {['No', 'Si'].map((label, index) => (
                <Label>
                  <Radio {...register('fieldHasPropertyMortgage')} value={index} />
                  {label}
                </Label>
              ))}
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}

export default NaturalProperties
