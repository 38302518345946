import React from 'react'
import IndexPage from '../../modules/microsites/architecture'
import Layout from '../../layout/architecture/Layout'

import FloatingWhatsappBtn from '../../modules/home/components/FloatingWhatsappBtn'

function ArchitecturePage() {
  return (
    <Layout>
      <FloatingWhatsappBtn path='https://wa.link/1hfzce' />
      <IndexPage />
    </Layout>
  )
}

export default ArchitecturePage
