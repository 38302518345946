import { gql } from '@apollo/client'

export const FILE_UPLOAD = gql`
  mutation FileUpload($file: Upload!) {
    entity: fileUpload(input: $file) {
      fid
      filename
      filemime
      url
    }
  }
`

export const FILES_UPLOAD = gql`
  mutation FilesUpload($files: [Upload!]) {
    entities: filesUpload(input: $files) {
      fid
      filename
      filemime
      url
    }
  }
`
