import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid } from 'theme-ui'

import { Divider, Icon } from 'ui'
import Tab from 'modules/search-building/components/Tab'
import { getNormalizerServiceType } from 'modules/search-building/utils'

function PropertyBanner({
  adminValue,
  area,
  bathrooms,
  bedrooms,
  code,
  fairPrice,
  fairText,
  leaseCanon,
  propertyValue,
  saleCanon,
  serviceType,
  scratchPrices,
  status,
  stratum,
  garage,
}) {
  return (
    <Box>
      {leaseCanon && getNormalizerServiceType(serviceType) === 'Arriendo' && (
        <Box sx={{ display: 'flex' }}>
          <Icon
            sx={{ fontSize: 5, mt: ['xsmall', '7px'], mr: 'small' }}
            name='bi-wallet'
          />
          <Box
            sx={{
              fontSize: scratchPrices ? 2 : 5,
              textDecoration: scratchPrices ? 'line-through' : 'none',
              fontFamily: 'heading',
              fontWeight: 'heading',
            }}
          >
            {leaseCanon}
            <Box as='span' sx={{ color: '#909090', fontSize: [1, 2] }}>
              {' '}
              /mes
            </Box>
          </Box>
          {scratchPrices && <Box sx={{ color: 'error' }}>{fairPrice}</Box>}
        </Box>
      )}

      {saleCanon &&
        (getNormalizerServiceType(serviceType) === 'Venta' ||
          getNormalizerServiceType(serviceType) === 'Corretaje') && (
          <Box sx={{ display: 'flex' }}>
            <Icon
              sx={{ fontSize: 5, mt: ['xsmall', '7px'], mr: 'small' }}
              name='bi-wallet'
            />
            <Box
              sx={{
                fontSize: scratchPrices ? 2 : 5,
                textDecoration: scratchPrices ? 'line-through' : 'none',
                fontFamily: 'heading',
                fontWeight: 'heading',
              }}
            >
              {saleCanon}
            </Box>
            {scratchPrices && <Box sx={{ color: 'error' }}>{fairPrice}</Box>}
          </Box>
        )}

      <Divider mb={0} />

      <Grid columns={3} sx={{ columnGap: 0, mb: 'medium' }}>
        <Tab icon='gr-tag' label='Código:'>
          {code}
        </Tab>

        {fairText && (
          <Tab icon='fi-percent' label='Oportunidad:'>
            <Box sx={{ color: 'error' }}>{fairText}</Box>
          </Tab>
        )}

        {area && (
          <Tab icon='bi-area' label='Área:'>
            {area} m²
          </Tab>
        )}
        {status && (
          <Tab icon='bi-award' label='Estado:'>
            {status}
          </Tab>
        )}
        {stratum && (
          <Tab icon='ti-printer' label='Estrato:'>
            {stratum}
          </Tab>
        )}

        {bedrooms && bedrooms !== 0 && (
          <Tab icon='bi-bed' label='Alcobas:'>
            {bedrooms}
          </Tab>
        )}
        {garage !== 0 && garage > 0 && (
          <Tab icon='fa-car' label='Parqueadero:'>
            {garage}
          </Tab>
        )}
        {bathrooms !== 0 && bathrooms > 0 && (
          <Tab icon='bi-bath' label='Baños:'>
            {bathrooms}
          </Tab>
        )}

        {adminValue && adminValue === 0 && (
          <Tab icon='bi-area' label='Incluye Admon:'>
            {adminValue}
          </Tab>
        )}
        {propertyValue && propertyValue === 0 && (
          <Tab icon='bi-area' label='Catastro:'>
            {propertyValue}
          </Tab>
        )}
      </Grid>

      {getNormalizerServiceType(serviceType) === 'Venta' && <Box />}
    </Box>
  )
}

PropertyBanner.propTypes = {
  adminValue: PropTypes.string.isRequired,
  area: PropTypes.string.isRequired,
  bathrooms: PropTypes.string.isRequired,
  bedrooms: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  fairPrice: PropTypes.string.isRequired,
  fairText: PropTypes.string.isRequired,
  leaseCanon: PropTypes.string.isRequired,
  propertyValue: PropTypes.string.isRequired,
  saleCanon: PropTypes.string.isRequired,
  serviceType: PropTypes.string.isRequired,
  scratchPrices: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  stratum: PropTypes.string.isRequired,
  garage: PropTypes.string.isRequired,
}

export default PropertyBanner
