export const getNormalizerServiceType = service => {
  if (service === 'AR') {
    return 'Arriendo'
  }

  if (service === 'VE') {
    return 'Venta'
  }

  if (service === 'CO') {
    return 'Venta'
  }

  return 'Venta'
}

export const formatPrice = (price, symbol = '$') => {
  const formatedPrice = new Intl.NumberFormat('co', {
    style: 'currency',
    currency: 'COP',
  }).format(price)

  return formatedPrice.slice(0, -3).replace('COP', symbol).replace(/,/g, '.')
}

export const formatted = item => {
  if (item === null) {
    return 0
  }
  return item
}

export const getNormalizedRegionAR = region => {
  let whatsapp = []

  switch (region) {
    case 'Antioquia':
      whatsapp = ['3023602351']
      break
    case 'Atlántico':
      whatsapp = ['3023647063']
      break
    case 'Bolívar':
      whatsapp = ['3023647063']
      break
    case 'Magdalena':
      whatsapp = ['3023647063']
      break
    case 'Cundinamarca':
      whatsapp = ['3023635747']
      break
    default:
      whatsapp = ['3023602351']
      break
  }

  return whatsapp[Math.floor(Math.random() * whatsapp.length)]
}

export const normalizeCanon = (lease, sale) => (lease !== '0' ? lease : sale)

export const getNormalizeRegionPhone = region => {
  let phone = ''

  switch (region) {
    case 'Antioquia':
      phone = '300 9122060'
      break
    case 'Cundinamarca':
      phone = '300 9126657'
      break
    case 'Atlántico':
      phone = '300 9126642'
      break
    case 'Bolívar':
      phone = '300 9126642'
      break
    case 'Magdalena':
      phone = '300 9126642'
      break
    default:
      phone = '300 9122060'
  }

  return phone
}

export const getNormalizedRegionVE = region => {
  let phone = ''

  switch (region) {
    case 'Antioquia':
      phone = '3103986904'
      break
    case 'Cundinamarca':
      phone = '3023635747'
      break
    case 'Atlántico':
      phone = '3015233253'
      break
    case 'Bolívar':
      phone = '3015233253'
      break
    case 'Magdalena':
      phone = '3015233253'
      break
    default:
      phone = '3103986904'
  }

  return phone
}
