import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Box, Input, Label, Button, Select, Checkbox } from 'theme-ui';
import IntlTelInput from 'react-intl-tel-input';

function ContactForm() {
  const { register, handleSubmit, reset } = useForm({});
  const [phone, setPhone] = useState();
  const [paymentLink, setPaymentLink] = useState('');
  const [availableApartments, setAvailableApartments] = useState([]);

  const phoneStyles = {
    mb: ['xsmall', 'none'],
    '.allow-dropdown.intl-tel-input': { display: 'block' },
    input: {
      width: '100%',
      border: '1px solid #d0d1d1',
      borderRadius: '4px',
      lineHeight: 'inherit',
    },
  };

  const CallStyles = {
    bg: ['secondary', 'secondary'],
    borderRadius: '7px',
    color: 'white',
    fontSize: 1,
    fontWeight: 'bold',
    mt: 'xsmall',
    py: '10px',
    textTransform: 'uppercase',
    width: '100%',
    '&:hover': {
      bg: 'green-3',
    },
  };

  const checkboxStyles = {
    'input:checked ~ &': {
      color: 'accent',
    },

    'input:focus ~ &': {
      color: 'accent',
    },
  }

  const onChange = (_, phoneNumber, country) => {
    const dialCode = country.dialCode;
    const withoutDial = phoneNumber.replace(new RegExp(`\\+${dialCode}`), '');
    setPhone(`+${dialCode}${withoutDial}`);
  };

  useEffect(() => {
    const fetchAvailableApartments = async () => {
      try {
        const response = await axios.get('https://filavirtual.coninsa.co/api/fila/available-apartments');
        setAvailableApartments(response.data);
      } catch (error) {
        console.log('Error al cargar los apartamentos disponibles:', error);
      }
    };

    fetchAvailableApartments();
  }, []);

  const onSubmit = async data => {
    const { name, last_name, email, cedula, apartamento, amount } = data;

    try {
      const inmuebleID = `NAU_HOUSE_${apartamento}`;
      const paymentData = {
        quantity: 1,
        onePayment: true,
        amount,
        currency: "COP",
        id: 0,
        description: `Cobro reserva Apartamento ${apartamento} ${name} ${last_name}, CC ${cedula}, Tel: ${phone}, ${email}`,
        title: `Reserva NAU HOUSE APARTAMENTOS ${apartamento}`,
        typeSell: "2",
        tax: "0",
        email
      };

      const loginResponse = await axios.post(
        'https://apify.epayco.co/login/mail',
        {},
        {
          headers: {
            Authorization: 'Basic cHJvY2Vzb3NAY3NvZnQuY286Q3NvZnQyMDI0Kg==',
          },
        }
      );

      const token = loginResponse.data.token;

      const paymentResponse = await axios.post(
        'https://apify.epayco.co/collection/link/create',
        paymentData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (paymentResponse.data.success) {
        const link = paymentResponse.data.data.routeLink;
        setPaymentLink(link);

        axios.post('https://filavirtual.coninsa.co/api/fila/enviar-sms', {
          numero: phone,
          mensaje: `Hola ${name}, ya puedes finalizar el pago de tu reserva en el siguiente link: ${link} `,
        });

        await axios.post('https://filavirtual.coninsa.co/api/fila/save-transaction', {
          inmuebleID,
          details: { paymentLink: link }
        });
      } else {
        console.log('Error al generar el enlace de pago.');
      }
    } catch (error) {
      console.log('Error en el proceso de autenticación o generación de pago:', error);
    }
  };

  return (
    <Box as='form' onSubmit={handleSubmit(onSubmit)}>
      <Box sx={phoneStyles}>
        <Label htmlFor='phone'>Celular</Label>
        <IntlTelInput
          id='phone'
          onPhoneNumberChange={onChange}
          defaultCountry={'co'}
          telInputProps={{ required: true }}
        />
      </Box>
      <Box>
        <Label htmlFor='name'>Nombres</Label>
        <Input {...register('name')} id='name' required />
      </Box>
      <Box>
        <Label htmlFor='last_name'>Apellidos</Label>
        <Input {...register('last_name')} id='last_name' required />
      </Box>
      <Box>
        <Label htmlFor='email'>Correo Electrónico</Label>
        <Input {...register('email')} id='email' required />
      </Box>
      <Box>
        <Label htmlFor='cedula'>Cédula</Label>
        <Input {...register('cedula')} id='cedula' required />
      </Box>
      <Box>
        <Label htmlFor='apartamento'>Apartamento</Label>
        <Select {...register('apartamento')} id='apartamento' required defaultValue="">
          <option value="" disabled>Selecciona un apartamento</option>
          {availableApartments.map((apartment) => (
            <option key={apartment.inmuebleID} value={apartment.numero}>
              {`Apartamento ${apartment.numero} - ${apartment.areaTotal} m² - ${apartment.precio} COP`}
            </option>
          ))}
        </Select>
      </Box>

      <Box mt={4}>
        <Label htmlFor='amount'>Monto de la Transacción</Label>
        <Select {...register('amount')} id='amount' required defaultValue="1000000">
          <option value="1000000">1.000.000 COP</option>
          <option value="2000000">2.000.000 COP</option>
        </Select>
      </Box>

      <Label sx={{ alignItems: 'center', mt: 'small' }}>
            <Checkbox sx={checkboxStyles} required />
            <Box
              sx={{
                fontSize: [0, 1],
              }}
            >
              Autorizo el uso y tratamiento de datos personales y acepto los{' '}
              <Box
                as='a'
                href='https://d2xt7650da4t25.cloudfront.net/s3fs-public/nau-house/terminos-y-condiciones-reserva.pdf'
                sx={{ color: 'accent', ml: '3px', textDecoration: 'underline' }}
                target='_blank'
              >
                términos y condiciones
              </Box>{' '}
              de la reserva de inmueble.
            </Box>
      </Label>


      <Box>
        <Button sx={CallStyles} type='submit'>Generar Link de Pago</Button>
      </Box>
      
      {paymentLink && (
        <Box mt={4}>
          <p>Enlace de pago generado:</p>
          <a href={paymentLink} target='_blank' rel='noopener noreferrer'>{paymentLink}</a>
        </Box>
      )}
    </Box>
  );
}

export default ContactForm;
