import React from "react";
import GoogleAnalytics from "../analytics";
import Hotjar from "../hotjar";

import Banner from "../sections/banner";
import Services from "../sections/services";
import ProjectCards from "../sections/project-cards";
import PropertyCards from "../sections/property-cards";
import QuickAccess from "../sections/quick-access";
import LatestBlogs from "../sections/latest-blog";
import FromAbroad from "../sections/from-abroad";
import { CurrencyProvider } from "@coninsa-s2/currency";

function Beta({ projects = [], properties = [] }) {
  return (
    <>
      <GoogleAnalytics trackingId="G-FDEY39BZL3" />
      <Hotjar trackingId="5040306" />
      <Banner />
      <Services />
      <CurrencyProvider>
        <ProjectCards projects={projects} />
      </CurrencyProvider>
      <PropertyCards properties={properties} />
      <LatestBlogs />
      <FromAbroad />
      <QuickAccess />
    </>
  );
}

export default Beta;
