import {
  ALL_OPTION_VALUE,
  EMPTY_STRING_VALUE,
} from "@coninsa-site/project/src/constants";
import {
  buildPrettyFacetPaths,
  getActiveFilters,
} from "@coninsa-site/project/src/utils";

import { SEARCH_QUERY } from "./queries";
import { getProjectCommerceHousingLabel, getProjectStateLabel } from "../facets";

const baseUrl = "/proyectos/nuevo";
const breadcrumbItems = [
  {
    label: "Inicio",
    url: "/",
  },
  {
    label: "Proyectos",
    url: "/proyectos",
  },
  {
    label: "Nuevo",
  },
];
const title = "Conoce los proyectos que tenemos para ti";
const loadMoreLabel = "Cargar más proyectos";
const searchQuery = SEARCH_QUERY;

const configs = {
  text: {
    pretty: "text",
    facetName: "text",
    fieldName: "text",
    defaultValue: EMPTY_STRING_VALUE,
    useLabel: false,
    translate: (t) => t,
    type: "query",
  },
  property_type: {
    pretty: "tipo-de-inmueble",
    facetName: "property_type",
    fieldName: "property_type",
    defaultValue: ALL_OPTION_VALUE,
    useLabel: false,
    translate: (t) => t,
    type: "pathname",
  },

  department_project: {
    pretty: "region",
    facetName: "department_project",
    fieldName: "department_project",
    defaultValue: ALL_OPTION_VALUE,
    useLabel: false,
    translate: (t) => t,
    type: "pathname",
  },

  field_state: {
    pretty: "estado",
    facetName: "field_state",
    fieldName: "field_state",
    defaultValue: ALL_OPTION_VALUE,
    useLabel: true,
    translate: getProjectStateLabel,
    type: "pathname",
  },

  commerce_housing: {
    pretty: "tipo-de-servicio",
    facetName: "commerce_housing",
    fieldName: "commerce_housing",
    defaultValue: ALL_OPTION_VALUE,
    useLabel: true,
    translate: getProjectCommerceHousingLabel,
    type: "pathname",
  },
};

const initialValues = {
  text: "",

  field_sale_price_from: "",
  field_sale_price_to: "",

  department_project: ALL_OPTION_VALUE,
  property_type: ALL_OPTION_VALUE,
  field_state: ALL_OPTION_VALUE,
  field_immediate_delivery: ALL_OPTION_VALUE,
  commerce_housing: ALL_OPTION_VALUE,
};

const getSearchQuery = (data) => {
  let andConditions = [];
  let fullSearch = "";

  if (data.text?.length >= 3) {
    fullSearch = data.text.replace(/[.,]/g, "").split(" ");
  }

  if (data.department_project !== ALL_OPTION_VALUE) {
    andConditions = andConditions.concat({
      name: "department_project",
      value: data.department_project,
    });
  }

  if (data.property_type !== ALL_OPTION_VALUE) {
    andConditions = andConditions.concat({
      name: "property_type",
      value: data.property_type,
    });
  }

  if (data.field_state !== ALL_OPTION_VALUE) {
    andConditions = andConditions.concat({
      name: "field_state",
      value: data.field_state,
    });
  }

  if (data.field_immediate_delivery !== ALL_OPTION_VALUE) {
    andConditions = andConditions.concat({
      name: "field_immediate_delivery",
      value: data.field_immediate_delivery,
    });
  }

  if (data.commerce_housing !== ALL_OPTION_VALUE) {
    andConditions = andConditions.concat({
      name: "commerce_housing",
      value: data.commerce_housing,
    });
  }

  if (data.field_sale_price_from !== "") {
    andConditions = andConditions.concat({
      name: "field_sale_price_from",
      value: data.field_sale_price_from.replaceAll(".", ""),
      operator: ">",
    });
  }

  if (data.field_sale_price_to !== "") {
    andConditions = andConditions.concat({
      name: "field_sale_price_to",
      value: data.field_sale_price_to.replaceAll(".", ""),
      operator: "<",
    });
  }

  return {
    text: fullSearch,
    and_conditions: andConditions,
  };
};

const getPrettyPaths = (data) => {
  return buildPrettyFacetPaths(baseUrl, configs, data);
};

const getActiveFacets = (data) => {
  return getActiveFilters(baseUrl, configs, data);
};

const defaultSortFields = [
  {
    field: "sold_project",
    value: "ASC"
  },
  {
    field: "field_sticky",
    value: "DESC"
  },
];

const sortFields = {
  lower_price: {
    field: "field_sale_price_from",
    value: "ASC",
  },
  higher_price: {
    field: "field_sale_price_from",
    value: "DESC",
  },
  smaller_area: {
    field: "field_area_from",
    value: "ASC",
  },
  larger_area: {
    field: "field_area_from",
    value: "DESC",
  },
  property_type: {
    field: "property_type",
    value: "ASC",
  },
};

const sortOptions = [
  { value: "relevance", label: "Relevancia" },
  { value: "property_type", label: "Tipo de inmueble" },
  { value: "lower_price", label: "Menor precio" },
  { value: "higher_price", label: "Mayor precio" },
  { value: "smaller_area", label: "Menor área" },
  { value: "larger_area", label: "Mayor área" },
];

export default {
  breadcrumbItems,
  title,
  loadMoreLabel,

  baseUrl,
  searchQuery,
  configs,
  initialValues,
  getSearchQuery,
  getPrettyPaths,
  getActiveFacets,

  sort: {
    defaultFields: defaultSortFields,
    fields: sortFields,
    options: sortOptions,
  }
};
