import React from 'react'
import PropTypes from 'prop-types'
import { Box, Container, Image, Grid } from 'theme-ui'

import Banner from 'ui/Banner'
import Stores from './components/Stores'

import bannerImage from '../../images/portalalianzas-banner.png'
import pinImage from '../../images/pin.png'
import terminosImage from '../../images/terminos.svg'

function AlianzasPage(upper) {
  const BannerStyles = {
    backgroundImage: `url(${bannerImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    px: 'medium',
    position: 'relative',
  }

  const titleStyles = {
    fontFamily: 'Oswald',
    fontSize: ['26px', '36px'],
    fontWeight: '500',
    mb: ['small', 'medium'],
    textTransform: upper ? 'uppercase' : 'inherit',
    color: 'neutral',
  }

  const headlineStyles = {
    fontSize: ['22px', '30px'],
    fontWeight: '300',
    color: 'neutral',
  }

  const contentStyles = {
    fontSize: ['14px', '20px'],
    color: 'black',
  }

  const contentBannerStyles = {
    bg: 'white',
    color: 'black',
    fontSize: ['14px', '16px'],
    p: 'medium',
    boxShadow: '0 3px 6px #00000029',
    borderRadius: '10px',
    width: '100%',
    bottom: 0,
  }
  const imagePin = {
    p: 'medium',
    right: 0,
    '@media screen and (max-width: 576px)': {
      display: 'none',
    },
  }
  const LinkStyle = {
    a: {
      color: '#00993B',
    },
  }
  const gridStyles = {
    '@media screen and (max-width: 576px)': {
      display: 'block',
    },
  }

  return (
    <Box>
      <Banner sx={BannerStyles}>
        <Banner.Header>
          <Box sx={headlineStyles}>Bienvenido a nuestro</Box>
          <Box as='h2' sx={titleStyles}>
            PORTAL DE CONVENIOS
          </Box>
        </Banner.Header>
        <Banner.Content pr={[null, '65%']} sx={contentStyles}>
          <p>
            Creamos este espacio para brindarte más oportunidades al momento de
            comprar tu hogar ideal.
          </p>
        </Banner.Content>
        <Box sx={contentBannerStyles}>
          <Grid sx={gridStyles} gap={2} columns={[2, '0.5fr 2fr']}>
            <Box sx={imagePin}>
              <Image src={pinImage} />
            </Box>
            <Box sx={contentStyles}>
              <p>
                Sabemos lo importante que eres para nosotros, por eso hemos creado un{' '}
                <strong>descuento exclusivo</strong> para ti a la hora de comprar una
                vivienda nueva. Si haces parte de alguno de nuestros aliados,
                solicita tu descuento a través de este sitio y un asesor se pondrá en
                contacto contigo para brindarte toda la información sobre nuestro
                programa.
              </p>
            </Box>
            <Box></Box>
            <Box sx={LinkStyle}>
              <a href='https://www.coninsa.co/terminos-y-condiciones'>
                <Image src={terminosImage} /> Conoce los términos y condiciones del
                programa.
              </a>
            </Box>
          </Grid>
        </Box>
      </Banner>

      <Stores />
    </Box>
  )
}

export default AlianzasPage
