import React from "react";

import PropertyPopup from "@coninsa-s2/property-popup";
import Tag from "@coninsa-s2/tag";

import {
  getPropertyStateData,
  getServiceType,
} from "@coninsa-site/home/src/utils";

const SearchPropertyPopup: React.FC = (props) => {
  const {
    code,
    price,
    bedrooms,
    bathrooms,
    url,
    area,
    imageUrl,
    serviceType,
    location,
    propertyType,
    state,
  } = props;

  const stateData = getPropertyStateData(state);
  const title = [propertyType, getServiceType(serviceType), location]
    .filter((str) => Boolean(str))
    .join(" en ");

  const tagsElement = () => (
    <>
      <Tag tint="gray">{propertyType}</Tag>
      {stateData && <Tag tint={stateData.tint}>{stateData.name}</Tag>}
    </>
  );

  const formattedPrice = `$${(price ?? 0).toLocaleString("es-CO")}`;

  return (
    <PropertyPopup
      title={title}
      code={code}
      price={formattedPrice}
      area={area}
      bedrooms={bedrooms}
      bathrooms={bathrooms}
      url={url}
      imageUrl={imageUrl}
      tags={tagsElement}
    />
  );
};

export default SearchPropertyPopup;
