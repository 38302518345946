import {PropuestaConstructor} from './propuestaConstructor';
import {PropuestaCliente} from './propuestaCliente';

export class Cotizacion {
  constructor(
    public propuestaConstructor: PropuestaConstructor,
    public propuestaCliente: PropuestaCliente,
    public valorDescuentoCliente: number,
    public valorInteresesCliente: number,
    public valorTotalPagar: number,
    public fechaAplicacionDiferencia: any,
    public diasAplicacionDiferencia: number,
    public valorAplicacionDiferencia: number,
    public tasaDescuento: number,
    public tasaFinanciera: number,
    public sumaAplicacionDiferenciaInteresesDifCuotas: number,
    public cobroInteres: boolean,
    public showIntDesc: boolean
  ) {
  }
}
