import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";

import { UPDATE_BUILDING_PROPERTY } from "../graphql/mutations";
import { UploadFiles } from "@coninsa-ui/upload-file";

import Alert from "../components/Alert";
import * as Modal from "../components/Modal";

import cleaningIconUrl from "../../assets/icons/cleaning.svg"
import lightingIconUrl from "../../assets/icons/lighting.svg"
import broadnessIconUrl from "../../assets/icons/broadness.svg"

export default function Step6({ onSuccess, onBack, id }) {
  const [showModal, setShowModal] = useState(false);
  const { register, handleSubmit, control, setError } = useForm();
  const [mutation] = useMutation(UPDATE_BUILDING_PROPERTY);

  const onSubmit = async (data) => {
    const response = await mutation({
      variables: {
        id,
        input: {
          fieldImages: data?.images.map((image) => ({
            targetId: image?.entity?.fid.toString(),
          })),
        },
      },

    });

    const violations = response?.data?.updateBuildingProperty?.violations;
    const errors = response?.data?.updateBuildingProperty?.errors;

    if (violations?.length === 0 && errors?.length === 0) {
      return onSuccess({ id });
    } else {
      setError("Algo ocurrió mal. Vuelva a intentarlo más tarde.");
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div>
      <div className="co-card max-w-4xl mx-auto">
        <h3 className="font-bold mb-4">Sube tus fotografías</h3>
        <Alert className="is-orange">
          <span className="is-highlight">
            ¡No olvides subir las fotos de tu inmueble!
          </span>
          <p>
            Así tendrás el 98% más de probabilidad de vender o arrrendar tu
            inmueble.¡Anímate a subirlas para continuar con el proceso!
          </p>
        </Alert>
        <Alert className="is-primary">
          <span className="is-highlight">
            ¿No sabes cómo tomar las fotografías?
          </span>
          <p>
            Reviza nuestros{" "}
            <a
              className="co-link is-secondary"
              href="#"
              onClick={() => setShowModal(true)}
            >
              consejos
            </a>{" "}
            para que tus fotografías sean geniales y logres tener una mejor
            impresión.
          </p>
        </Alert>

        <form className="co-form gap-y-8" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-control is-dropzone">
            <UploadFiles name="images" control={control} />
          </div>

          <div className="form-control is-checkbox bg-gray-100 p-3">
            <input
              type="checkbox"
              id="without_pictures"
              {...register("without_pictures")}
            />
            <label
              htmlFor="without_pictures"
              className="data-policy"
            >
              No tengo fotos para subir en este momento. Requiero del acompañamiento de un asesor.
            </label>
          </div>

          <div className="flex justify-between gap-2 md:flex-row">
            <button type="button" onClick={onBack} className="co-button is-secondary is-large has-fixed-icon">
              <i className="co-icon icon-arrow"></i>
              Regresar
            </button>

            <button className="co-button is-progress-default is-large w-full md:w-auto">
              Continuar
            </button>
          </div>
        </form>
      </div>

      <Modal.Root isOpen={showModal}>
        <Modal.Body>
          <h3 className="co-heading-3 is-primary mb-8">
            Consejos para tomar mejores fotografías
          </h3>
          <ul>
            <li className="featured-list">
              <img
                src={cleaningIconUrl}
                className="shadow-lg rounded-full"
              />
              <div className="content">
                <h4>Limpieza y orden</h4>
                <p>
                  Antes de capturar las imágenes, asegúrate de que tu
                  propiedad esté impecable, libre de objetos que distraigan al
                  usuario como ropa, cajas o alimentos.
                </p>
              </div>
            </li>

            <li className="featured-list">
              <img
                src={lightingIconUrl}
                className="shadow-lg rounded-full"
              />
              <div className="content">
                <h4>Mientras más luz, mejor</h4>
                <p>
                  Procura tomar fotografías de tus espacios durante la luz del
                  día, preferiblemente en una jornada soleada. Esto
                  garantizará que tus publicaciones luzcan más atractivas y
                  nítidas para los usuarios.
                </p>
              </div>
            </li>

            <li className="featured-list">
              <img
                src={broadnessIconUrl}
                className="shadow-lg rounded-full"
              />
              <div className="content">
                <h4>Incluye más espacio</h4>
                <p>
                  Con el fin de obtener una toma de calidad, coloca la cámara
                  en la intersección de las esquinas de las paredes, orientada
                  hacia el fondo del espacio.
                </p>
              </div>
            </li>
          </ul>
          <div className="flex flex-wrap justify-end">
            <button
              onClick={handleClose}
              className="co-button is-secondary flex items-center gap-4 text-base"
            >
              CERRAR VENTANA
            </button>
          </div>
        </Modal.Body>
      </Modal.Root>
    </div>
  );
}
