import React from "react";

import PropertyCard from "@coninsa-s2/property-card";
import Tag from "@coninsa-s2/tag";

import {
  getPropertyStateData,
  getServiceType,
} from "@coninsa-site/home/src/utils";

const SearchPropertyCard: React.FC = ({
  code,
  price,
  bedrooms,
  bathrooms,
  url,
  area,
  images = [],
  serviceType,
  location,
  propertyType,
  state,
}) => {
  const stateData = getPropertyStateData(state);
  const title = [propertyType, getServiceType(serviceType), location]
    .filter((str) => Boolean(str))
    .join(" en ");

  const tagsElement = () => (
    <>
      <Tag tint="gray">{propertyType}</Tag>
      {stateData && <Tag tint={stateData.tint}>{stateData.name}</Tag>}
    </>
  );

  const formattedPrice = `$${parseInt(price).toLocaleString("es-CO")}`;

  return (
    <PropertyCard
      title={title}
      code={code}
      price={formattedPrice}
      area={area}
      bedrooms={bedrooms}
      bathrooms={bathrooms}
      url={url}
      images={images}
      tags={tagsElement}
    />
  );
};

export default SearchPropertyCard;
