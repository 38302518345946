import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";

import DollarSignIcon from "@coninsa-s2/icon/assets/dollar-sign.inline.svg";
import HomeSignIcon from "@coninsa-s2/icon/assets/home.inline.svg";
import LoupeSignIcon from "@coninsa-s2/icon/assets/loupe.inline.svg";
import PinSignIcon from "@coninsa-s2/icon/assets/pin.inline.svg";
import SparkleSignIcon from "@coninsa-s2/icon/assets/sparkle.inline.svg";
import TagSignIcon from "@coninsa-s2/icon/assets/tag.inline.svg";

import "./styles.css";

function Facet({
  label,
  current,
  icon = "dollar-sign",
  size = "standard",
  showValue = false,
  children,
  className,
}) {
  const facetRef = useRef();

  const dynamicClasses = {
    [`s2-facet--${size}`]: size,
    "s2-facet--icon": icon,
    "s2-facet--show-value": showValue,
  };

  const icons = {
    "dollar-sign": DollarSignIcon,
    home: HomeSignIcon,
    loupe: LoupeSignIcon,
    pin: PinSignIcon,
    sparkle: SparkleSignIcon,
    tag: TagSignIcon,
  };

  const Icon = icons[icon] ?? icons["dollar-sign"];

  useEffect(() => {
    const handleOutboundClick = (event) => {
      if (
        facetRef.current.open &&
        !event.composedPath().includes(facetRef.current)
      ) {
        facetRef.current.open = false;
      }
    };

    document.addEventListener("click", handleOutboundClick);

    return () => document.removeEventListener("click", handleOutboundClick);
  }, []);

  useEffect(() => {
    const handleFaceItemClick = (event) => {
      const facetItem = event.target.closest(".s2-facet-item");

      if (facetRef.current.open && facetItem) {
        facetRef.current.open = false;
      }
    };

    const container = facetRef.current.querySelector(".s2-facet__container");

    container.addEventListener("click", handleFaceItemClick);

    return () => container.removeEventListener("click", handleFaceItemClick);
  }, []);

  return (
    <details
      ref={facetRef}
      className={clsx("s2-facet", dynamicClasses, className)}
    >
      <summary className="s2-facet__trigger">
        {icon && <Icon className="s2-facet__icon" />}
        <span className="s2-facet__trigger__label">{label}</span>
        <span className="s2-facet__trigger__value">{current}</span>
      </summary>

      <div className="s2-facet__container">
        <h4>{label}</h4>
        <div>{children}</div>
      </div>
    </details>
  );
}

export default Facet;
