import buildingIcon from "./assets/icons/building.svg";
import invoiceIcon from "./assets/icons/invoice.svg";
import documentIcon from "./assets/icons/document.svg";

export default [
  {
    id: "terms-item-1",
    icon: buildingIcon,
    label: "Proyectos",
    url: "/terminos-y-condiciones/proyectos",
  },
  {
    id: "terms-item-2",
    icon: invoiceIcon,
    label: "Arrendamientos",
    url: "/terminos-y-condiciones/arrendamientos",
  },
  // {
  //   id: "terms-item-3",
  //   icon: documentIcon,
  //   label: "Generales",
  //   url: "/terminos-y-condiciones/generales",
  // },
];
