import React from 'react'
import { navigate } from 'gatsby'
import { Box } from 'theme-ui'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { Button } from 'ui'

import { UPDATE_NODE_NATURAL_PERSON_COTENANT } from '../../mutations'
import { NATURAL_COTENANT_APPLICATION } from '../../queries'

import HeaderForm from '../../components/HeaderForm'
import BasicDocumentation from '../generic/BasicDocumentation'
import { getEntity, cleanData } from '../utils'

import { getBasePersonPath } from '../co-legal/4-documentation'

function Documentation(props) {
  const loadedCotenant = props?.loadedCotenant || {}
  const [mutation] = useMutation(UPDATE_NODE_NATURAL_PERSON_COTENANT, {
    refetchQueries: [
      { query: NATURAL_COTENANT_APPLICATION, variables: { id: props.cid } },
    ],
  })
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm({ defaultValues: loadedCotenant })

  const onSubmit = async data => {
    const variables = cleanData(data)

    return mutation({ variables })
      .then(response => {
        const entity = getEntity(response)

        if (entity) {
          const basePath = getBasePersonPath(props.bundle, props.id)
          navigate(`${basePath}?path=/coarrendatarios`)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <section>
      <HeaderForm step='5/5' title='Documentación' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <BasicDocumentation
          control={control}
          extraFields={['labor-certification']}
        />

        <Box mt='medium'>
          <Button type='submit' disabled={isSubmitting} loading={isSubmitting}>
            Guardar
          </Button>

          <Button
            ml='small'
            type='button'
            variant='default'
            onClick={() => navigate(-1)}
          >
            Cancelar
          </Button>
        </Box>
      </form>
    </section>
  )
}

export default Documentation
