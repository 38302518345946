import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, Image } from 'theme-ui'
import Carousel from 'nuka-carousel'

import { Header, Modal, Section } from 'ui'
import BaseImage from 'images/base-image.jpg'
import {
  ButtonOverlay,
  renderLeftControl,
  renderRightControl,
  renderTopControls,
} from './PropertyBanner'

export function SimpleOverlay({ children, handler, label }) {
  return (
    <Box sx={{ position: 'relative' }}>
      {children}
      <Box
        sx={{
          backgroundColor: 'black',
          borderRadius: '8px',
          opacity: '0.6',
          display: 'flex',
          justifyContent: 'center',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          margin: 'auto',
          alignItems: 'center',
        }}
      >
        <Box
          as='button'
          sx={{
            bg: 'white',
            borderRadius: '21px',
            border: '1px solid neutral',
            color: 'neutral',
            cursor: 'pointer',
            fontSize: '14px',
            fontWeight: 'bold',
            px: 'small',
            py: 'xsmall',
            ":hover": {
              bg: 'neutral',
              color: 'white',
            }
          }}
          onClick={handler}
        >
          {label}
        </Box>
      </Box>
    </Box>
  )
}

function ProjectGallery({ images }) {
  const [state, setState] = useState(false)
  const handler = () => setState(true)
  const closeHandler = () => {
    setState(false)
  }

  const totalImages = `${images?.length}+`

  const firstImages = images?.slice(0, 3)
  const secondImages = images?.slice(3, 4)

  return (
    <div>
      <Box
        sx={{ display: ['block', 'none'] }}
      >
        {images?.length > 0 && (
          <ButtonOverlay handler={handler} label={totalImages}>
            <Box>
              <Box
                sx={{
                  height: ['227px', '396px'],
                  overflow: 'hidden',
                  borderRadius: '8px',
                  border: 'none',
                  width: '100%',
                }}
              >
                <Image
                  sx={{ borderRadius: '8px', width: '100%', height: '100%' }}
                  src={images[0]?.derivative?.url}
                />
              </Box>
            </Box>
          </ButtonOverlay>
        )}

        {images?.length === 0 && <Image src={BaseImage} />}
      </Box>

      <Box sx={{ display: ['none', 'block'] }}>
        <Grid columns={3} mb={3}>
          {firstImages?.map(image => (
            <Box sx={{ cursor: 'pointer' }} onClick={handler}>
              <Image sx={{ borderRadius: '8px' }} src={image?.derivative?.url} />
            </Box>
          ))}
        </Grid>

        <Grid columns={2} mb={1}>
          {secondImages?.map(image => (
            <Box sx={{ cursor: 'pointer' }} onClick={handler}>
              <Image sx={{ borderRadius: '8px' }} src={image?.derivative?.url} />
            </Box>
          ))}

          {images?.length === 5 && (
            <Box sx={{ cursor: 'pointer' }} onClick={handler}>
              <Image sx={{ borderRadius: '8px' }} src={images[5]?.derivative?.url} />
            </Box>
          )}

          {images?.length > 5 && (
            <SimpleOverlay handler={handler} label='Ver más fotos'>
              <Image sx={{ borderRadius: '8px' }} src={images[5]?.derivative?.url} />
            </SimpleOverlay>
          )}
        </Grid>
      </Box>

      <Modal
        sx={{ width: ['100%', '640px'] }}
        open={state}
        onClose={closeHandler}
        variant='fullDark'
        showCloseControl={true}
      >
        <Modal.Content variant='fullDark' sx={{ px: 0 }}>
          <Box
            sx={{
              color: 'white',
              fontSize: 3,
              fontWeight: 'bold',
              mb: 'small',
              textAlign: 'center',
              textTransform: 'uppercase',
              top: '20px',
            }}
          >
            Galería del proyecto
          </Box>
          {images?.length > 0 && (
            <Box
              sx={{
                width: ['90vw', 'auto'],
                height: [280, 'auto'],
              }}
            >
              <Carousel
                renderAnnounceSlideMessage={({ currentSlide, slideCount }) =>
                  `Slide ${currentSlide + 1} of ${slideCount}`
                }
                renderBottomCenterControls={({ currentSlide }) =>
                  renderTopControls(currentSlide, images.length)
                }
                renderCenterLeftControls={({ previousSlide }) =>
                  renderLeftControl(previousSlide)
                }
                renderCenterRightControls={({ nextSlide }) =>
                  renderRightControl(nextSlide)
                }
              >
                {images.map(image => (
                  <Box>
                    <Box
                      sx={{
                        height: ['228px', '459px'],
                        overflow: 'hidden',
                        borderRadius: '8px',
                        border: 'none',
                        m: '0 auto',
                        width: ['310px', '612px'],
                      }}
                    >
                      <Image
                        sx={{ width: '100%', height: '100%' }}
                        src={image?.derivative?.url}
                      />
                    </Box>
                  </Box>
                ))}
              </Carousel>
            </Box>
          )}

          {images?.length === 0 && <Image src={BaseImage} />}
        </Modal.Content>
      </Modal>
    </div>
  )
}

ProjectGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
}

SimpleOverlay.propTypes = {
  children: PropTypes.node.isRequired,
  handler: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
}

export default ProjectGallery
