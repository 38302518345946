import React from 'react'
import PropTypes from 'prop-types'

import BaseWrapper from './BaseWrapper'
import SubMenuLink from './SubMenuLink'

function MenuWrapper({ wrapper, columns, links }) {
  return (
    <BaseWrapper columns={columns} {...wrapper}>
      {links.map(link => (
        <SubMenuLink key={link.label} {...link} />
      ))}
    </BaseWrapper>
  )
}

MenuWrapper.defaultProps = {
  columns: {},
  links: [],
  url: {
    path: '#'
  },
  wrapper: {},
}

MenuWrapper.propTypes = {
  columns: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.object),
  url: PropTypes.shape({
    path: PropTypes.string
  }),
  wrapper: PropTypes.shape({}),
}

export default MenuWrapper
