import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from 'ui'

function FloatingWhatsappBtn({ path }) {
  return (
    <div className='fixed right-3 bottom-3 z-50'>
      {/* <a
        className='flex text-white no-underline bg-coninsa-green-900
         md:w-[254px] rounded-[42px] pl-3 md:pl-6 gap-1 pr-3 md:pr-4 items-center py-3 hover:bg-coninsa-green-300'
        href={path}
        target='_blank'
      >
        <div className='hidden md:block'>
          <h4 className='text-sm font-bold mb-1.5'>¿Necesitas ayuda?</h4>
          <p className='text-xs leading-tight'>
            Habla con la{' '}
            <span className='font-semibold'>asistente virtual Coninsa</span> a
            través de WhatsApp
          </p>
        </div>

        <Icon
          name='fa-whatsapp'
          sx={{
            fontSize: '40px',
            verticalAlign: 'middle',
            flexShrink: 0
          }}
        />
      </a> */}
      <a
        className='flex text-white no-underline bg-coninsa-green-900
          rounded-[42px] gap-1 p-3 items-center hover:bg-coninsa-green-500'
        href={path}
        target='_blank'
        rel="noreferrer"
      >
        <Icon
          name='fa-whatsapp'
          sx={{
            fontSize: ['40px', '50px'],
            verticalAlign: 'middle',
            flexShrink: 0
          }}
        />
      </a>
    </div>
  )
}

FloatingWhatsappBtn.propTypes = {
  path: PropTypes.string.isRequired,
}

export default FloatingWhatsappBtn
