import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { Box } from 'theme-ui'
import Icon from 'ui/Icon'

function CollapseItem({
  children,
  title,
  active,
  onActive,
  index,
  variant,
  autoFocus,
  lazyLoading,
  icons,
  ...props
}) {
  const headerStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  }

  const contentStyles = {
    overflow: 'hidden',
    height: 0,
    visibility: 'hidden',
    transition: 'height 200ms ease 0s',

    '&.expanded': {
      height: 'auto',
      visibility: 'visible',
      overflow: 'visible',
    },
  }

  const itemRef = useRef(null)
  const contentClassName = active ? 'expanded' : ''
  const [showMoreIcon, showLessIcon] = icons

  const handleClick = () => {
    onActive()

    if (!autoFocus) {
      return
    }

    if ('parentIFrame' in window) {
      const iframeOffesetTop = 415
      const parentOffsetTop = iframeOffesetTop + index * 96

      !active && window.parentIFrame.scrollTo(0, parentOffsetTop)
    } else {
      const offsetTop = itemRef.current.offsetTop - 100
      !active && window.scrollTo(0, offsetTop)
    }
  }

  return (
    <Box variant={`${variant}.item`} {...props} __themeKey='collapses'>
      <Box
        ref={itemRef}
        onClick={handleClick}
        variant={`${variant}.item.header`}
        __themeKey='collapses'
        __css={headerStyles}
      >
        {title}
        <Icon
          name={active ? showLessIcon : showMoreIcon}
          variant={`${variant}.item.icon`}
          __css={{ flexShrink: 0, ml: 'small' }}
          __themeKey='collapses'
        />
      </Box>

      {(lazyLoading || active) && (
        <Box
          className={contentClassName}
          variant={`${variant}.item.content`}
          __themeKey='collapses'
          __css={contentStyles}
        >
          {children}
        </Box>
      )}
    </Box>
  )
}

CollapseItem.defaultProps = {
  active: false,
  autoFocus: false,
  children: undefined,
  onActive: () => {},
  variant: 'default',
  icons: ['chevron-down', 'chevron-up'],
  lazyLoading: false,
}

CollapseItem.propTypes = {
  active: PropTypes.bool,
  autoFocus: PropTypes.bool,
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  variant: PropTypes.string,
  title: PropTypes.string.isRequired,
  onActive: PropTypes.func,
  icons: PropTypes.arrayOf(PropTypes.string),
  lazyLoading: PropTypes.bool,
}

export default CollapseItem
