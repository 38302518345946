import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, Input, Label, Radio, Select } from 'theme-ui'

import Location from '../../Location'
import Control from '../../components/Control'

import {
  getOptions,

  // Options
  genders,
  idTypes,
  maritalStatus
} from '../options'

function BasicPersonalInformation({ register, control, setValue, extraFields }) {
  return (
    <Grid sx={{ columnGap: 'medium' }} columns={[1, '1fr 1fr']}>
      <Control label='Nombres' required>
        <Input {...register('fieldFirstName')} type='text' required />
      </Control>

      <Control label='Apellidos' required>
        <Input {...register('fieldLastName')} type='text' required />
      </Control>

      <Control label='Tipo de identificación' required>
        <Box sx={{ display: 'flex' }}>
          {getOptions(idTypes).map(option => (
            <Label>
              <Radio {...register('fieldIdType')} value={option.value} required />
              {option.label}
            </Label>
          ))}
        </Box>
      </Control>

      <Control label='Número de identificación' required>
        <Input {...register('fieldIdNumber')} type='text' required />
      </Control>

      {/* --- Fields temporarily hidden. ---
        <Control label='Lugar de expedición'>
          <Location
            name='fieldExpeditionPlace'
            setValue={setValue}
            control={control}
          />
        </Control>

        <Control label='Fecha de expedición'>
          <Input {...register('fieldExpeditionDate.value')} type='date' />
        </Control>

        <Control label='Ciudad de nacimiento'>
          <Location name='fieldCityBirth' setValue={setValue} control={control} />
        </Control>

        <Control label='Fecha de nacimiento'>
          <Input {...register('fieldDateBirth.value')} type='date' />
        </Control>
      */}

      <Control label='Género' required>
        <Box sx={{ display: 'flex' }}>
          {getOptions(genders).map(option => (
            <Label>
              <Radio {...register('fieldGender')} value={option.value} required />
              {option.label}
            </Label>
          ))}
        </Box>
      </Control>

      <Control label='Estado civil'>
        <Select {...register('fieldMaritalStatus')}>
          {getOptions(maritalStatus).map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </Control>

      {extraFields.includes('cell') && (
        <Control label='Número celular' required>
          <Input {...register('fieldCellPhoneNumber')} type='text' required />
        </Control>
      )}

      {extraFields.includes('phone') && (
        <Control
          label='Teléfono fiscal'
          helpText='(Este valor corresponde al teléfono registrado en el RUT)'
        >
          <Input {...register('fieldFiscalPhone')} type='text' />
        </Control>
      )}
    </Grid>
  )
}
BasicPersonalInformation.defaultProps = {
  extraFields: []
}

BasicPersonalInformation.propTypes = {
  extraFields: PropTypes.shape([])
}

export default BasicPersonalInformation
