import React, { useContext } from 'react'
import { Box, Button } from 'theme-ui'
import { useQuery } from '@apollo/client'
import { navigate } from '@reach/router'

import { USER_APPLICATIONS_QUERY } from '../queries'
import { AuthContext } from '../../auth/AuthDrupalContext'

function RequestedList() {
  const auth = useContext(AuthContext)
  const uid = auth?.user?.uid
  const { data, loading, error } = useQuery(USER_APPLICATIONS_QUERY, {
    variables: { uid: uid.toString() },
    skip: !uid,
  })

  const entities = data?.nodeQuery?.entities || []

  const onClick = entity => () => {
    const bundleMap = {
      natural_person_application: 'natural',
      legal_person_application: 'juridica',
    }

    navigate(
      `/estudio-digital/solicitud/persona/${bundleMap[entity.bundle]}/${entity.id}`
    )
  }

  const transMap = {
    natural_person_application: 'Persona Natural',
    legal_person_application: 'Persona Juridica',
  }

  return (
    <Box>
      <Box mb='4'>Solicitudes pendientes</Box>

      <Box>
        {entities.map(entity => (
          <Box
            key={entity.id}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mb: '3',
              alignItems: 'center',
            }}
          >
            <Box>
              <strong>#{entity.id}</strong> Solicitud {transMap[entity.bundle]}{' '}
              <i>{entity.created}</i>
            </Box>

            <Button onClick={onClick(entity)}>Ver</Button>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default RequestedList
