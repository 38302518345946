import React from 'react'
import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { Spinner, Container, Message } from 'theme-ui'

import { prepareDrupal2Forms } from '../forms/utils'
import LegalCotenant from '../pages/CoLegalPerson'
import NaturalCotenant from '../pages/CoNaturalPerson'
import { NATURAL_COTENANT_APPLICATION, LEGAL_COTENANT_APPLICATION } from '../queries'
import { getPersonType } from '../settings'

const cotenantConfigs = {
  natural: {
    query: NATURAL_COTENANT_APPLICATION,
    component: NaturalCotenant,
  },
  legal: {
    query: LEGAL_COTENANT_APPLICATION,
    component: LegalCotenant,
  },
}

function EditCotenant(props) {
  const { cid, cotenant } = props
  const cotenantType = getPersonType(cotenant)

  const cotenantConfig = cotenantConfigs[cotenantType]
  const bundleQuery = cotenantConfig.query
  const Component = cotenantConfig.component

  const { data, loading, error } = useQuery(bundleQuery, {
    variables: { id: cid },
  })

  if (loading) {
    return (
      <Container my='6'>
        <Spinner />
      </Container>
    )
  }

  if (error) {
    return <Message>{error}</Message>
  }

  return <Component {...props} loadedCotenant={prepareDrupal2Forms(data?.node)} />
}

EditCotenant.propTypes = {
  id: PropTypes.string.isRequired,
  bundle: PropTypes.string.isRequired,
}

export default EditCotenant
