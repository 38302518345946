import accordionMapper from './accordionMapper'
import accordionItemMapper from './accordionItemMapper'
import cardMapper from './cardMapper'
import ctaMapper from './ctaMapper'
import cardIconMapper from './cardIconMapper'
import featuredItemMapper from './featuredItemMapper'
import basicHeroMapper from './basicHeroMapper'
import heroMapper from './heroMapper'
import heroSliderMapper from './heroSliderMapper'
import horizontalSectionMapper from './horizontalSectionMapper'
import sectionMapper from './sectionMapper'
import slideMapper from './slideMapper'
import sliderMapper from './sliderMapper'
import ourProjectsMapper from './ourProjectsMapper'
import videoMapper from './videoMapper'

import { mapper as dynamicMapper } from '../paragraphs/PDynamic'
import { mapper as richTextMapper } from '../paragraphs/PRichText'
import {
  mapper as threeItemDiagramMapper,
  THREE_ITEM_DIAGRAM_PARAGRAPH,
} from '../paragraphs/PThreeItemDiagram'

import {
  mapper as iconListMapper,
  ICON_LIST_PARAGRAPH,
} from '../paragraphs/PIconList'

const defaultMapper = entity => ({ type: 'no_defined_paragraph', ...entity })
const simpleMapper = type => entity => ({ type, ...entity })

export const mapResolver = paragraph => {
  const mappers = {
    Drupal_ParagraphAccordion: accordionMapper,
    Drupal_ParagraphAccordionItem: accordionItemMapper,
    Drupal_ParagraphCard: cardMapper,
    Drupal_ParagraphCardIcon: cardIconMapper,
    Drupal_ParagraphCta: ctaMapper,
    Drupal_ParagraphDynamicComponent: dynamicMapper,
    Drupal_ParagraphFeaturedItem: featuredItemMapper,
    Drupal_ParagraphBasicHero: basicHeroMapper,
    Drupal_ParagraphHero: heroMapper,
    Drupal_ParagraphHeroSlider: heroSliderMapper,
    Drupal_ParagraphHorizontalSection: horizontalSectionMapper,
    Drupal_ParagraphSection: sectionMapper,
    Drupal_ParagraphSlide: slideMapper,
    Drupal_ParagraphSlider: sliderMapper,
    Drupal_ParagraphRichText: richTextMapper,
    Drupal_ParagraphVideo: videoMapper,
    Drupal_ParagraphWebform: simpleMapper('webform'),
    Drupal_ParagraphOurProjects: ourProjectsMapper,
    Drupal_ParagraphArchitectureTeam: simpleMapper('architecture_team'),

    [THREE_ITEM_DIAGRAM_PARAGRAPH]: threeItemDiagramMapper,
    [ICON_LIST_PARAGRAPH]: iconListMapper,
  }

  const typeName = paragraph?.entity?.__typename
  const mapper = mappers[typeName] || defaultMapper

  const entity = paragraph?.entity
  const id = paragraph?.id

  return mapper({ id, ...entity })
}

export default {}
