import handBalanceIcon from './../../../icons/hand-balance.svg'
import earphonesIcon from './../../../icons/earphones.svg'
import pinIcon from './../../../icons/pin.svg'
import handMoneyIcon from './../../../icons/hand-money.svg'
import keyIcon from './../../../icons/key.svg'
import laptopIcon from './../../../icons/laptop.svg'
import listIcon from './../../../icons/list.svg'
import signIcon from './../../../icons/sign.svg'
import toolsIcon from './../../../icons/tools.svg'

export default [
  {
    id: "diffetential-card-1",
    icon: handBalanceIcon,
    description: `<strong>Asesoría comercial y jurídica</strong> en todo el proceso de ejecución, de principio a fin.`,
  },
  {
    id: "diffetential-card-2",
    icon: earphonesIcon,
    description: `<strong>Contamos con diferentes canales digitales de atención</strong> Portal web, WhatsApp, Asistente virtual 24/7, Facebook e Instagram.`,
  },
  {
    id: "diffetential-card-3",
    icon: pinIcon,
    description: `Encuéntranos en <strong>Bogotá, Antioquia y Costa Atlántica</strong>.`,
  },
  {
    id: "diffetential-card-4",
    icon: handMoneyIcon,
    description: `<strong>Contamos con múltiples alternativas de pago</strong> como Mi Coninsa Virtual. Sin filas, ni complicaciones.`,
  },
  {
    id: "diffetential-card-5",
    icon: keyIcon,
    description: `Arriendas los inmuebles <strong>sin coarrendatario*</strong>.`,
  },
  {
    id: "diffetential-card-6",
    icon: laptopIcon,
    description: `<strong>Estudio de arrendamiento gratis</strong> con respuesta a tu solicitud en menos de 2 horas.`,
  },
  {
    id: "diffetential-card-7",
    icon: listIcon,
    description: `Entregamos el inmueble <strong>listo para el disfrute</strong>.`,
  },
  {
    id: "diffetential-card-8",
    icon: signIcon,
    description: `Gestionamos tu contrato <strong>con firma digital</strong>.`,
  },
  {
    id: "diffetential-card-9",
    icon: toolsIcon,
    description: `<strong>Área de reparaciones</strong> para darte soluciones oportunas cuando lo necesites.`,
  },
]
