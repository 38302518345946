import string from "string";

import type { FacetConfig } from "./constants";

function facetResultToOption(result) {
  return {
    value: result.filter,
    label: result.filter,
  };
}

export function getFacetIndexOptions(index, facets = []) {
  return (
    facets?.[index]?.values
      .map(facetResultToOption)
      .filter(({ label }) => label != "!") || []
  );
}

export function getFacetBuildingTypes(facets = []) {
  const propertyTypeFacet = facets.find(
    (facet) => facet.name === "property_type"
  );

  const values = propertyTypeFacet?.values ?? [];

  const options = values?.map((item) => ({
    value: item.filter,
    label: item.filter,
  }));

  const defaultOption = {
    value: 0,
    label: "Todos los inmuebles",
  };

  return [defaultOption, ...options];
}

export function buildSearchQuery(type, search, propertyTypeId) {
  const typeMap = {
    rent_properties: `/arrendamientos/vivienda`,
    rent_properties_commercial: `/arrendamientos/comercio`,
    buy_properties: `/venta-de-usados/inmuebles`,
  };

  let url = typeMap[type];

  if (propertyTypeId) {
    url += `/tipo-de-inmueble/${cleanName(propertyTypeId)}`;
  }

  if (search) {
    const params = new URLSearchParams({ text: search });
    url += `?${params.toString()}`;
  }

  return url;
}

export function getActiveFilters(searchBaseUrl, facetConfigs, initialFacets) {
  const { pathname, search } = window.location;

  // Get active pathname filters.
  const activePathnameFilters = getFiltersBasedOnPathnames(
    pathname,
    searchBaseUrl,
    facetConfigs,
    initialFacets
  );

  // Get active search query filters.
  const activeSearchQueryFilters = getFiltersBasedOnSearchString(
    search,
    facetConfigs
  );

  return {
    ...activePathnameFilters,
    ...activeSearchQueryFilters,
  };
}

/**
 * Generates a set of filters based on the given pathnames.
 *
 * @param {string} fullPathname - The full pathname.
 * @param {string} baseSearchPathname - The base search pathname.
 * @param {Object} pathnamesToFiltersMap - The mapping of pathnames to filters.
 * @return {Object} The generated filters.
 *
 * If the pathname is not in the map, it will be ignored.
 *
 * @example
 * // Given:
 * const fullPathname = "/properties/rent/commercial/pathaname1/value1/pathaname2/value2";
 * const baseSearchPathname = "/properties/rent/commercial";
 * const pathnamesToFiltersMap = {
 *   "pathaname1": "filter1",
 *   "pathaname2": "filter2",
 * };
 *
 * // Returns:
 * {
 *   "filter1": "value1",
 *   "filter2": "value2",
 * }
 */
export function getFiltersBasedOnPathnames(
  fullPathname = "",
  baseSearchPathname = "",
  facetConfigs,
  solrFacets = []
) {
  const filterPathname = fullPathname.replace(baseSearchPathname, "");
  const paths = filterPathname.slice(1).split("/");
  const pairs = [];
  const activeFilters = {};

  for (let i = 0; i < paths.length; i += 2) {
    const key = paths[i];
    const value = paths[i + 1] ?? "";

    pairs.push([key, value]);
  }

  for (const [pretty, value] of pairs) {
    const config = Object.values(facetConfigs).find(
      (facet) => facet.pretty === pretty
    ) as FacetConfig | null;

    if (!config) {
      continue;
    }

    const facetName = config.facetName;
    const solrFacet = solrFacets.find((facet) => facet.name === facetName);

    if (!solrFacet) {
      continue;
    }

    const solrValue = solrFacet.values.find((item) => {
      const filterValue = !config.useLabel
        ? item.filter
        : config.translate(item.filter);

      return cleanName(filterValue) === value;
    });

    if (!solrValue) {
      continue;
    }

    activeFilters[config.fieldName] = solrValue.filter;
  }

  return activeFilters;
}

/**
 * Generates a set of filters based on the given search string.
 *
 * @param {string} searchString - The search string to parse.
 * @param {Object} searchStringsToFilterMap - An optional mapping of search parameters to filter names.
 * @return {Object} The generated filters.
 *
 * If the search parameter is not in the map, it will be ignored.
 *
 * @example
 * // Given:
 * const searchString = '?search=Sue&flag=Colombia';
 * const searchStringsToFilterMap = { search: 'name' };
 *
 * // Returns:
 * { name: 'Sue' }
 */
export function getFiltersBasedOnSearchString(searchString, facetConfigs) {
  const stringFacetConfigs = Object.values(facetConfigs).filter(
    (config) => config.type === "query"
  );

  const searchParams = new URLSearchParams(searchString);
  const filters = {};

  for (const config of stringFacetConfigs) {
    const value = searchParams.get(config.pretty);

    if (!value) {
      continue;
    }

    filters[config.pretty] = value;
  }

  return filters;
}

const cleanName = (name) => {
  const rawName = name.toLowerCase().replace(":", "");
  const cleanedName = string(rawName).slugify().dasherize().s;

  return cleanedName;
};

export function buildPrettyFacetPaths(
  basePathname = "",
  facetConfigs,
  formValues
) {
  let prettyPathname = basePathname;
  let pretyQueryString = {};

  for (let [facetName, value] of Object.entries(formValues)) {
    const config: FacetConfig | null = facetConfigs[facetName] ?? null;

    if (!config || !value) {
      continue;
    }

    if (value == config.defaultValue) {
      continue;
    }

    if (config.useLabel) {
      value = config.translate(value);
    }

    if (config.type === "pathname") {
      prettyPathname += `/${config.pretty}/${cleanName(value)}`;
    } else if (config.type === "query") {
      pretyQueryString[config.facetName] = value;
    }
  }

  // Check if the pretyQueryString object is empty
  // if not create a query string
  if (Object.keys(pretyQueryString).length > 0) {
    const params = new URLSearchParams(pretyQueryString);
    prettyPathname += `?${params.toString()}`;
  }

  return prettyPathname;
}
