import React from 'react'
import { Box } from 'theme-ui'

function HabeasData() {
  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: 'gray',
        borderRadius: '4px',
        height: '70px',
        overflow: 'auto',
        scrollBehavior: 'auto',
        textAlign: 'justify',
        fontSize: '12px',
        mb: '16px',
        px: '10px',
        py: '5px',
      }}
    >
      <p>
        En cumplimiento de la Ley 1581 de 2012 por la cual se dictan las
        disposiciones generales para la protección de datos personales y las normas
        que la modifiquen, sustituyan, adicionen o complementen; y en mi calidad de
        titular de los datos personales; emito mi consentimiento previo, expreso e
        informado con una X en el recuadro inferior, para que la sociedad CONINSA y/o
        terceros con los cuales ésta acuerde en todo o en parte la realización de
        cualquier actividad relativa o relacionada con el tratamiento de datos
        personales en su calidad de RESPONSABLE y/o ENCARGADA DEL TRATAMIENTO, decida
        sobre los datos personales aquí contenidos; específicamente para que realice
        el contacto telefónico y/o el envío de información de interés y de
        invitaciones a eventos programados por la Compañía por cualquier medio,
        correos electrónicos, y redes sociales y alguna otra derivada de este
        contacto inicial que sea de su interés.
      </p>
      <Box as='p' sx={{ my: '8px' }}>
        Así mismo, con el consentimiento, acepto que he sido informado de los
        derechos que me asisten como titular de mis datos personales, los cuales se
        encuentran consagrados en el artículo 8° de la Ley 1581 de 2012 y demás
        normatividad aplicable, entre otros, a dar respuesta o no a las preguntas que
        traten sobre datos sensibles o sobre los datos de las niñas, niños y
        adolescente.
      </Box>
      <p>
        CONINSA me ha informado que en el link:
        <Box
          as='a'
          sx={{ color: 'accent', fontWeight: '600', mx: '3px' }}
          href='https://www.coninsa.co/politica-de-tratamiento-de-datos-personales-de-coninsa-ramon-h-sa'
          target='_blank'
        >
          https://www.coninsa.co/politica-de-tratamiento-de-datos-personales-de-coninsa-ramon-h-sa,
        </Box>
        puedo leer su Política en Materia de Protección de Datos Personales y
        solicitar la actualización o remoción de mis datos personales, escribiendo al
        correo{' '}
        <Box as='span' sx={{ color: 'accent', fontWeight: 600 }}>
          servicioalcliente@coninsa.co
        </Box>
        , dirección Calle 55 # 45-55 o telefónicamente el PBX de cada regional.
      </p>
    </Box>
  )
}

export default HabeasData
