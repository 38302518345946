import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import PropTypes from 'prop-types'
import { Box, Input, Button, Checkbox, Label, Textarea } from 'theme-ui'
import IntlTelInput from 'react-intl-tel-input'
import 'react-intl-tel-input/dist/main.css'

import { Icon } from 'ui'
import HabeasData from 'component-v2/HabeasData'

function ProjectContactForm({ name }) {
  const [lat, setLat] = useState()
  const [lon, setLon] = useState()
  const { register, handleSubmit, reset } = useForm({})
  const [phone, setPhone] = useState()

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(position => {
      setLat(position.coords.latitude)
      setLon(position.coords.longitude)
    })
  }, [])

  const onSubmit = async data => {
    const info = {
      properties: [
        {
          property: 'email',
          value: data.email,
        },
        {
          property: 'firstname',
          value: data.name,
        },
        {
          property: 'lastname',
          value: data.last_name,
        },
        {
          property: 'website',
          value: 'https://www.coninsa.co',
        },
        {
          property: 'company',
          value: 'company',
        },
        {
          property: 'phone',
          value: phone,
        },
        {
          property: 'fuente_offline',
          value: 'website',
        },
        {
          property: 'proyecto_de_interes',
          value: name,
        },
        {
          property: 'coninsaco',
          value: name,
        },
        {
          property: 'latitud',
          value: lat,
        },
        {
          property: 'longitud',
          value: lon,
        },
      ],
    }

    const email = data?.email
    const url = `https://api.coninsa.co/api/v2/hubspot-create-contact/${email}`

    return new Promise((resolve, reject) => {
      axios
        .post(url, info)
        .then(response => {
          reset()
          resolve(response)
          window.location.replace(`/gracias?destination=${window.location.pathname}`)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const onChange = (_, phoneNumber, country) => {
    const dialCode = country.dialCode
    const expression = `\\+${dialCode}`
    const regex = new RegExp(expression)
    const withoutDial = phoneNumber.replace(regex, '')
    const internationalNumber = `+${country.dialCode}` + `${withoutDial}`
    setPhone(internationalNumber)
  }

  const baseStyles = {
    input: {
      fontSize: [1, 0],
      mb: 'xsmall',
      py: [2, 1],
    },
  }

  const phoneStyles = {
    mb: 'xsmall',
    '.allow-dropdown.intl-tel-input': { display: 'block' },
    input: {
      width: '100%',
      border: '1px solid #d0d1d1',
      borderRadius: '4px',
      lineHeight: 'inherit',
    },
  }

  return (
    <Box as='form' onSubmit={handleSubmit(onSubmit)} mt={4}>
      <Box
        sx={{
          color: 'neutral',
          fontFamily: 'heading',
          fontWeight: 'heading',
          mb: 'small',
          textTransform: 'uppercase',
        }}
      >
        Contáctame
      </Box>

      <Box sx={baseStyles} mb='xsmall' register={register}>
        <Input {...register('name')} id='name' placeholder='Nombres' required />
        <Input
          {...register('last_name')}
          id='last_name'
          placeholder='Apellidos'
          required
        />

        <Box sx={phoneStyles}>
          <IntlTelInput
            {...register('phone')}
            id='phone'
            placeholder='Número de contacto'
            defaultCountry={'co'}
            onPhoneNumberChange={onChange}
            telInputProps={{ required: true }}
          />
        </Box>

        <Input
          {...register('email')}
          id='email'
          placeholder='Correo electrónico'
          required
        />

        <HabeasData />

        <Label>
          <Checkbox {...register('accept_conditions')} required />
          <Box sx={{ fontSize: [1, 0], textAlign: 'left' }}>
            <span>Autorizo el tratamiento y la </span>
            <Box
              as='a'
              href='https://www.coninsa.co/politica-de-tratamiento-de-datos-personales-de-coninsa-ramon-h-sa'
              sx={{ color: 'primary', textDecoration: 'none' }}
              target='_blank'
            >
              política de datos personales
            </Box>
          </Box>
        </Label>
      </Box>

      <Button
        sx={{
          bg: 'accent',
          color: 'white',
          mt: 'xsmall',
          py: ['small', 'xsmall'],
          width: '100%',
        }}
        type='submit'
        variant='sharing'
      >
        <Icon name='fi-phone' sx={{ mr: '10px', verticalAlign: 'middle' }} />
        Recibir una llamada
      </Button>
    </Box>
  )
}

ProjectContactForm.propTypes = {
  buildingPath: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default ProjectContactForm
