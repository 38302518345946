import React from "react";
import clsx from "clsx";

import "./styles.css";

function ContactCard({ medium = false, label, highlighted, icon, ...props }) {
  const dynamicClasses = {
    "s2-contact-card--medium": medium,
  };

  return (
    <div className={clsx("s2-contact-card", dynamicClasses)} {...props}>
      <img src={icon} alt="Icon" />
      <div>
        <h4 className="s2-contact-card__text-light">{label}</h4>
        <p className="s2-contact-card__text-strong">{highlighted}</p>
      </div>
    </div>
  );
}

export default ContactCard;
