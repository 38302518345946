import React from "react";
import clsx from "clsx";

import "./styles.css";

interface AdCardProps {
  tint?: "blue" | "lime" | "teal";
  className?: string;
  children?: React.ReactNode;
}

const AdCard: React.FC<AdCardProps> = ({
  tint = "blue",
  className,
  ...props
}) => {
  const dynamicClasses = {
    [`s2-ad-card--${tint}`]: tint,
  };

  return (
    <div className={clsx("s2-ad-card", dynamicClasses, className)} {...props}>
      {props.children}
    </div>
  );
};

export default AdCard;
