import React from 'react'
import { navigate } from 'gatsby'
import { useForm } from 'react-hook-form'
import { Box, Grid, Select } from 'theme-ui'
import { useMutation } from '@apollo/client'

import { Button } from 'ui'
import Control from '../../components/Control'

import { UPDATE_NODE_NATURAL_PERSON_APPLICATION } from '../../mutations'
import { NATURAL_PERSON_APPLICATION } from '../../queries'

import HeaderForm from '../../components/HeaderForm'
import Cotenant from '../../components/Cotenant'
import { getEntity, cleanData } from '../utils'

export const cotenantMap = [
  {
    path: 'juridica',
    value: 'cotenant_legal',
    label: 'Coarrendatario persona jurídica',
    bundle: 'legal_person_co_tenant',
  },
  {
    path: 'natural',
    value: 'cotenant_natural',
    label: 'Coarrendatario persona natural',
    bundle: 'natural_person_co_tenant',
  },
]

function getPathByCotenantType(cotenantType) {
  const contant = cotenantMap.find(type => type.value === cotenantType)

  return contant.path
}

function getPathByCotenantBundle(cotenantBundle) {
  const contant = cotenantMap.find(type => type.bundle === cotenantBundle)

  return contant.path
}

export function getCotenantAddUrl(basePath, cotenantType) {
  const contentTypePath = getPathByCotenantType(cotenantType)

  return `${basePath}/coarrendatario/${contentTypePath}/add`
}

export function getCotenantEditUrl(basePath, cotenantBundle, cid) {
  const contentTypePath = getPathByCotenantBundle(cotenantBundle)

  return `${basePath}/coarrendatario/${contentTypePath}/${cid}`
}

function CoTenant(props) {
  const loadedPerson = props?.loadedPerson || {}
  const basePersonPath = props.location.pathname
  const [mutation] = useMutation(UPDATE_NODE_NATURAL_PERSON_APPLICATION, {
    refetchQueries: [
      { query: NATURAL_PERSON_APPLICATION, variables: { id: props.id } },
    ],
  })

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    errors,
    watch,
  } = useForm({ defaultValues: loadedPerson })

  const tempCotenantType = watch('temporary__cotenant_type', 'cotenant_legal')

  const addCotenant = () => {
    const redirectTo = getCotenantAddUrl(basePersonPath, tempCotenantType)
    navigate(redirectTo)
  }

  const onSubmit = async data => {
    const variables = cleanData(data)

    return mutation({ variables })
      .then(response => {
        const entity = getEntity(response)
        fetch(
          `https://admindrupal.coninsa.co/digital-studio/notification/${data.id}`,
          {
            credentials: 'include',
          }
        )

        if (entity) {
          navigate('/estudio-digital/solicitud/gracias')
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const allCotenants = loadedPerson?.fieldCotenants ?? []

  return (
    <section>
      <HeaderForm step='4/4' title='Coarrendatarios' />
      <h4 className='text-lg font-medium'>¡No falta mucho para terminar!</h4>
      <p className='mb-4 font-medium'>
        Para continuar con tu solicitud es necesario ingresar un coarrendatario con
        el fin de agilizar tu estudio, será la persona de apoyo en tu proceso de
        arrendamiento.
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid mb='medium' sx={{ alignItems: 'end' }} columns='4fr 1fr'>
          <Control
            label='Identifica el tipo de coarrendatario, selecciona:'
            required
          >
            <Select {...register('temporary__cotenant_type')} required>
              {cotenantMap.map(cotenant => (
                <option value={cotenant.value}>{cotenant.label}</option>
              ))}
            </Select>
          </Control>

          <Button
            sx={{ mb: 'small', py: 16 }}
            type='button'
            variant='secondary'
            onClick={() => addCotenant()}
          >
            Agregar
          </Button>
        </Grid>

        <Box>
          {allCotenants?.map(cotenant => (
            <Cotenant
              key={cotenant.targetId}
              {...cotenant}
              editUrl={getCotenantEditUrl(
                basePersonPath,
                cotenant.entity.bundle,
                cotenant.targetId
              )}
            />
          ))}
        </Box>

        <Box pt='medium'>
          <Button
            type='submit'
            disabled={isSubmitting || allCotenants.length === 0}
            loading={isSubmitting}
          >
            Continuar
          </Button>

          <Button
            ml='small'
            type='button'
            variant='default'
            onClick={() => navigate('?path=/documentacion')}
          >
            Atras
          </Button>
        </Box>
      </form>
    </section>
  )
}

export default CoTenant
