import React from 'react'
import PropTypes from 'prop-types'

import { Box } from 'theme-ui'

const Card = ({ children, ...props }) => {
  const baseStyles = {
    bg: 'light100',
    borderRadius: '5px',
    boxShadow: '3px 3px 4px #00000015',
  }

  return (
    <Box __css={baseStyles} __themeKey='cards' {...props}>
      {children}
    </Box>
  )
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Card
