import React from "react"
import { Helmet } from "react-helmet"
import ThankYouPage from "@coninsa-site/thank-you"

import Layout from "../layout/default/Layout.js"

export default function ThankYou() {
  return (
    <Layout>
      <Helmet>
        <title>{`Gracias por tu interés - Coninsa`}</title>
        <meta name="description" content="Pagina de gracias Coninsa.co" />
      </Helmet>
      <ThankYouPage />
    </Layout>
  )
}



