import React from 'react'
import PropTypes from 'prop-types'

import { Box, Container } from 'theme-ui'

const SealCard = ({ children, ...props }) => {
  const baseStyles = {
    bg: 'white',
    borderRadius: '5px',
    boxShadow: '3px 3px 4px #00000015',
    mb: 'small',
    p: 'medium',
    textAlign: 'center',
    width: ['auto', '32%'],
  }
  return (
    <Box __css={baseStyles} {...props}>
      <Container>{children}</Container>
    </Box>
  )
}

SealCard.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SealCard
