export function getProjectStateData(code) {
  const states = [
    {
      tid: 4683,
      name: "100% vendido",
      tint: "quaternary",
      machineName: "sold_out",
    },
    {
      tid: 4684,
      name: "En lanzamiento",
      tint: "quinary",
      machineName: "in_release",
    },
    {
      tid: 4685,
      name: "Entrega inmediata",
      tint: "tertiary",
      machineName: "immediate_delivery",
    },
    {
      tid: 4686,
      name: "Últimas unidades",
      tint: "secondary",
      machineName: "last_units",
    },
    {
      tid: 4687,
      name: "Nuevo",
      tint: null,
      machineName: "new",
    },
    {
      tid: 4947,
      name: "Nueva etapa",
      tint: "primary",
      machineName: "new_stage",
    },
  ];

  return states.find((item) => item.tid === code);
}

export function getPropertyStateData(code) {
  const states = [
    {
      tid: 5927,
      name: "15% Dto 3 primeros meses",
      tint: "quinary",
      machineName: "15% Dto 3 primeros meses",
    },
    {
      tid: 5926,
      name: "20% Dto 3 primeros meses",
      tint: "quaternary",
      machineName: "20% Dto 3 primeros meses",
    },
    {
      tid: 5925,
      name: "50% Dto en el canon",
      tint: "primary",
      machineName: "50% Dto en el canon",
    },
    {
      tid: 5921,
      name: "Bono regalo",
      tint: "senary",
      machineName: "Bono regalo",
    },
    {
      tid: 5884,
      name: "Descuento especial",
      tint: "primary",
      machineName: "special_discount",
    },
    {
      tid: 5917,
      name: "Encantador apartaestudio en ciudad del rio. Aplica Bono regalo",
      tint: "quinary",
      machineName:
        "Encantador apartaestudio en ciudad del rio. Aplica Bono regalo",
    },
    {
      tid: 3716,
      name: "Entrega inmediata",
      tint: "tertiary",
      machineName: "for_immediate_delivery",
    },
    {
      tid: 5920,
      name: "Estrena y vive feliz. Aplica un bono regalo",
      tint: "quinary",
      machineName: "Estrena y vive feliz. Aplica un bono regalo",
    },
    {
      tid: 5918,
      name: "Moderno y elegante apartamento. Aplica Bono regalo",
      tint: "quinary",
      machineName: "Moderno y elegante apartamento. Aplica Bono regalo",
    },
    {
      tid: 3715,
      name: "Nuevo",
      tint: "senary",
      machineName: "new",
    },
    {
      tid: 3717,
      name: "Para estrenar",
      tint: "primary",
      machineName: "brand_new",
    },
    {
      tid: 5894,
      name: "Renta garantizada",
      tint: "secondary",
      machineName: "renta_garantizada",
    },
    {
      tid: 5919,
      name: "Único y exclusivo solo para ti. Aplica Bono regalo",
      tint: "quinary",
      machineName: "Único y exclusivo solo para ti. Aplica Bono regalo",
    },
    {
      tid: 3718,
      name: "Usado",
      tint: "secondary",
      machineName: "used",
    },
  ];

  return states.find((item) => item.tid === code);
}


export function getServiceType(type) {
  const types = {
    AR: "arriendo",
  };

  return types[type] ?? null;
}