import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

const FeaturedListSubTitle = props => {
  const baseStyles = {
    color: '#80C342',
    fontSize: ['16px', '24px'],
    fontWeight: '600',
    lineHeight: ['18px', '24px'],
    mb: ['xsmall', 'small'],
  }

  return <Box __css={baseStyles} {...props} />
}

FeaturedListSubTitle.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FeaturedListSubTitle
