import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

import curveSvg from './curves/curve-001.svg'
import curveSvgMobile from './curves/curve-002.svg'

const SquareCard = ({ ...props }) => {
  const baseStyles = {
    border: ['8px solid #00993B', '10px solid #00993B'],
    bg: 'light-2',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    p: 'small',
    lineHeight: 1.15,
  }

  return <Box __css={baseStyles} {...props} />
}

const TitleCard = ({ ...props }) => {
  const baseStyles = {
    color: 'neutral',
    fontWeight: '700',
    fontSize: [0, 2],
  }

  return <Box __css={baseStyles} {...props} />
}

const List = ({ children, ...props }) => {
  const baseStyles = {
    display: 'inline-block',
    fontSize: 0,
    ul: {
      pl: 0,
      listStyle: 'none',
    },
  }

  return (
    <Box {...props}>
      <Box __css={baseStyles}>{children}</Box>
    </Box>
  )
}

const CircleCard = ({ ...props }) => {
  const baseStyles = {
    textAlign: 'center',
    p: 'medium',
    borderRadius: '50%',
    bg: 'white',
    boxShadow: '0px 0px 29px #00000017',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  }

  return <Box __css={baseStyles} {...props} />
}

function OrganizationalStructure() {
  const baseStyles = {
    justifySelf: 'end',
    position: 'relative',

    '&:before': {
      content: '""',
      display: 'block',
      width: [75, 400],
      height: [125, 130],
      position: 'absolute',

      top: [-32, 'calc(50% + 65px)'],
      left: [20, -400],
      backgroundImage: [`url(${curveSvgMobile})`, `url(${curveSvg})`],
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      zIndex: 0,
    },
  }

  const listStyles = {
    borderBottom: '1px solid',
    borderColor: 'gray',
    mb: 'xsmall',
    pb: 'xsmall',
  }

  return (
    <Box sx={baseStyles}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(8, 1fr)',
          gridTemplateRows: ['repeat(12, 1fr)', 'repeat(8, 1fr)'],
          gap: 'small',
          width: ['100%', '600px'],
          height: ['auto', '550px'],
        }}
      >
        <SquareCard sx={{ gridArea: ['1 / 3 / 5 / 9', '1 / 1 / 5 / 5'] }}>
          <TitleCard>
            Diseño, Gerencia, Construcción de Proyectos, Avalúos y Corretajes
            Corporativos UEN Desarrollos Inmobiliarios (DEI)
          </TitleCard>

          <List sx={{ textAlign: ['right', 'left'] }}>
            <Box sx={listStyles}>Vivienda</Box>
            <Box as='ul'>
              <Box as='li'>Comercio</Box>
              <Box as='li'>Industria</Box>
              <Box as='li'>Servicios</Box>
            </Box>
          </List>
        </SquareCard>

        <SquareCard
          sx={{
            gridArea: ['3 / 1 / 7 / 7', '1 / 5 / 5 / 9'],
            textAlign: 'right',
            // zIndex: 1,
          }}
        >
          <TitleCard sx={{ textAlign: 'right' }}>
            Arrendamiento y Administración de Bienes Raíces, Patrimonios y Fondos
            Inmobiliarios, Ventas Corporativas UEN Administración y Bienes Raíces
            (ABR)
          </TitleCard>

          <List sx={{ textAlign: ['left', 'right'] }}>
            <Box sx={listStyles}>Vivienda</Box>
            <Box as='ul'>
              <Box as='li'>Comercio</Box>
              <Box as='li'>Industria</Box>
              <Box as='li'>Servicios</Box>
            </Box>
          </List>
        </SquareCard>

        <SquareCard
          sx={{ gridArea: ['7 / 1 / 11 / 7', '5 / 1 / 9 / 5'], zIndex: 1 }}
        >
          <List>
            <Box sx={listStyles}>Vivienda</Box>
            <Box as='ul'>
              <Box as='li'>Comercio</Box>
              <Box as='li'>Industria</Box>
              <Box as='li'>Servicios</Box>
            </Box>
          </List>

          <TitleCard>
            Diseño, Gerencia y Construcción de Proyectos, APP UEN de Contratación
            Pública y Privada (CPP)
          </TitleCard>
        </SquareCard>

        <SquareCard
          sx={{
            gridArea: ['9 / 3 / 13 / 9', '5 / 5 / 9 / 9'],
            textAlign: 'right',
            zIndex: 0,
          }}
        >
          <List>
            <Box sx={listStyles}>Vivienda</Box>
            <Box as='ul'>
              <Box as='li'>Comercio</Box>
              <Box as='li'>Industria</Box>
              <Box as='li'>Servicios</Box>
            </Box>
          </List>

          <TitleCard>
            Diseños Urbanísticos, Arquitectónicos, Interiorismo, Coordinación Técnica
            UEN Arquitectura (ARCO)
          </TitleCard>
        </SquareCard>

        <CircleCard
          sx={{
            gridArea: ['5 / 4 / 9 / 9', '3 / 3 / 7 / 7'],
            zIndex: '1',
            mx: [0, 'small'],
          }}
        >
          <Box
            sx={{ fontSize: ['35px', '50px'], fontWeight: 'bold', color: 'neutral' }}
          >
            USC’S
          </Box>
          <Box
            as='ul'
            sx={{
              fontSize: ['11px', 1],
              pl: 'medium',
              listStyle: 'none',
              textAlign: 'left',
              '& li:before': {
                color: '#80C342',
                display: 'inline-block',
                content: '"•"',
                width: '.75rem',
                ml: '-1rem',
                fontWeight: '600',
              },
            }}
          >
            <li>Operaciones</li>
            <li>Financiera y Administrativa</li>
            <li>Gestión Humana</li>
            <li>Mercadeo y Comunicaciones</li>
          </Box>
        </CircleCard>
      </Box>
    </Box>
  )
}

List.defaultProps = {
  children: undefined,
}

List.propTypes = {
  children: PropTypes.node,
}

SquareCard.defaultProps = {
  leftConnector: false,
  rightConnector: false,
}

SquareCard.propTypes = {
  leftConnector: PropTypes.bool,
  rightConnector: PropTypes.bool,
}

export default OrganizationalStructure
