import React from 'react'
import { navigate } from 'gatsby'
import { Box } from 'theme-ui'
import { useForm } from 'react-hook-form'
import { useStateMachine } from 'little-state-machine'
import { useMutation } from '@apollo/client'

import {
  CREATE_NODE_LEGAL_PERSON_APPLICATION,
  UPDATE_NODE_LEGAL_PERSON_APPLICATION
} from '../../mutations'
import { LEGAL_PERSON_APPLICATION } from '../../queries'

import BasicGeneralInformation from '../generic/BasicGeneralInformation'
import BasicTaxInformation from '../generic/BasicTaxInformation'
import BasicLegalRepresentative from '../generic/BasicLegalRepresentative'

import FooterForm from '../../components/FooterForm'
import HeaderForm from '../../components/HeaderForm'
import { getEntity, cleanData } from '../utils'

const initialtValues = {
  title: 'temporary',
  fieldApplicationStatus: 'en-proceso',
  fieldCotenants: [],

  fieldIdType: 'cc',
}

function GeneralInformation(props) {
  const { state } = useStateMachine()
  const loadedPerson = props?.loadedPerson || {
    fieldPropertyCode: state.initialSettings?.propertyCode
  }

  const [createMutation] = useMutation(CREATE_NODE_LEGAL_PERSON_APPLICATION)
  const [updateMutation] = useMutation(UPDATE_NODE_LEGAL_PERSON_APPLICATION, {
    refetchQueries: [
      { query: LEGAL_PERSON_APPLICATION, variables: { id: props.id } }
    ]
  })
  const { register, handleSubmit, control, setValue } = useForm({
    defaultValues: {
      ...initialtValues,
      ...loadedPerson
    }
  })

  const onSubmit = async data => {
    const mutation = data?.id ? updateMutation : createMutation
    const variables = cleanData(data)

    return mutation({ variables })
      .then(response => {
        const entity = getEntity(response)

        if (entity) {
          const redirectTo = `/estudio-digital/solicitud/persona/juridica/${entity.id}`
          navigate(`${redirectTo}?path=/informacion-accionistas`)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }
  return (
    <section>
      <HeaderForm step='1/4' title='Información General' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <BasicGeneralInformation register={register} />

        <Box mb='medium'>
          <h3 className='bg-[#ededed] font-display font-medium mb-6 px-4 py-[14px] rounded-md text-coninsa-blue-900 text-base md:text-lg uppercase'>
            Información fiscal
          </h3>

          <BasicTaxInformation
            register={register}
            control={control}
            setValue={setValue}
          />
        </Box>

        <Box mb='medium'>
          <h3 className='bg-[#ededed] font-display font-medium mb-6 px-4 py-[14px] rounded-md text-coninsa-blue-900 text-base md:text-lg uppercase'>
            Información representante legal
          </h3>
          <BasicLegalRepresentative
            register={register}
            control={control}
            setValue={setValue}
          />
        </Box>
        <FooterForm />
      </form>
    </section>
  )
}

export default GeneralInformation
