import React, { Children, cloneElement, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

import { getTabsHeaderStyles } from './styles'

// @TODO: Add refs.
const Tabs = ({
  activeIndex: propsActiveIndex,
  children,
  hideDivider,
  variant,
  ...props
}) => {
  const lengthChildren = children.filter(child => child).length
  const defaultActiveIndex = lengthChildren > propsActiveIndex ? propsActiveIndex : 0
  const [activeIndex, setActiveIndex] = useState(defaultActiveIndex)
  const tabsHeaderStyles = getTabsHeaderStyles(hideDivider)
  const activateTab = index => setActiveIndex(index)

  let activeContent

  const tabs = Children.map(children, (tab, index) => {
    if (!tab) return undefined

    const tabProps = tab.props || {}
    const isTabActive = index === activeIndex

    if (isTabActive) {
      activeContent = tabProps.children
    }

    return cloneElement(tab, {
      active: isTabActive,
      onActivate: () => activateTab(index),
      variant,
    })
  })

  return (
    <Box variant={variant} {...props} __themeKey='tabs'>
      <Box variant={`${variant}.header`} __themeKey='tabs' __css={tabsHeaderStyles}>
        {tabs}
      </Box>

      <Box variant={`${variant}.content`} __themeKey='tabs'>
        {activeContent}
      </Box>
    </Box>
  )
}

Tabs.defaultProps = {
  hideDivider: false,
  activeIndex: 0,
  variant: 'default',
}

Tabs.propTypes = {
  hideDivider: PropTypes.bool,
  activeIndex: PropTypes.number,
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
}

export default Tabs
