import React from 'react'
import { Box, Grid, Input } from 'theme-ui'

import { Heading } from 'ui'
import Control from '../../components/Control'

function BasicShareholdersInformation({ name, register }) {
  return (
    <Box>
      <Box mb='medium'>
        <Heading level='3' variant='form'>
          Accionista (I)
        </Heading>
        <Grid sx={{ columnGap: 'medium' }} columns={[2, '1fr 1fr']}>
          <Control label='Nombre del accionista'>
            <Input {...register(`${name}.0.name`)} type='text' />
          </Control>
          <Control label='Documento'>
            <Input {...register(`${name}.0.document`)} type='text' />
          </Control>
          <Control label='Participación %'>
            <Input {...register(`${name}.0.share`)} type='text' />
          </Control>
        </Grid>
      </Box>

      <Box mb='medium'>
        <Heading level='3' variant='form'>
          Accionista (II)
        </Heading>
        <Grid sx={{ columnGap: 'medium' }} columns={[2, '1fr 1fr']}>
          <Control label='Nombre del accionista'>
            <Input {...register(`${name}.1.name`)} type='text' />
          </Control>
          <Control label='Documento'>
            <Input {...register(`${name}.1.document`)} type='text' />
          </Control>
          <Control label='Participación %'>
            <Input {...register(`${name}.1.share`)} type='text' />
          </Control>
        </Grid>
      </Box>

      <Box mb='medium'>
        <Heading level='3' variant='form'>
          Accionista (III)
        </Heading>
        <Grid sx={{ columnGap: 'medium' }} columns={[2, '1fr 1fr']}>
          <Control label='Nombre del accionista'>
            <Input {...register(`${name}.2.name`)} type='text' />
          </Control>
          <Control label='Documento'>
            <Input {...register(`${name}.2.document`)} type='text' />
          </Control>
          <Control label='Participación %'>
            <Input {...register(`${name}.2.share`)} type='text' />
          </Control>
        </Grid>
      </Box>

      <Box mb='medium'>
        <Heading level='3' variant='form'>
          Accionista (IV)
        </Heading>
        <Grid sx={{ columnGap: 'medium' }} columns={[2, '1fr 1fr']}>
          <Control label='Nombre del accionista'>
            <Input {...register(`${name}.3.name`)} type='text' />
          </Control>
          <Control label='Documento'>
            <Input {...register(`${name}.3.document`)} type='text' />
          </Control>
          <Control label='Participación %'>
            <Input {...register(`${name}.3.share`)} type='text' />
          </Control>
        </Grid>
      </Box>
    </Box>
  )
}

export default BasicShareholdersInformation
