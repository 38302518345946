import React from 'react'
import PropTypes from 'prop-types'
import { Box, Text } from 'theme-ui'
import ReactPlayer from 'react-player'

// @TODO: Move to an external component on UI directory. .
function ProportionalScaling({ scale, ...props }) {
  const baseStyles = {
    position: 'relative',
    width: '100%',
    minHeight: ['210px', 'inherit'],

    '&:before': {
      content: '""',
      display: 'block',
      paddingTop: scale,
      width: 0,
    },

    '& > div': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  }

  return <Box __css={baseStyles} {...props} />
}

ProportionalScaling.defaultProps = {
  scale: '56.25%',
}

ProportionalScaling.propTypes = {
  scale: PropTypes.string,
}

function PVideo({ description, video, variant, ...props }) {
  return (
    video?.url && (
      <Box variant={variant} mx={2} {...props}>
        <ProportionalScaling sx={{ minHeight: ['210px', 'inherit'] }}>
          <ReactPlayer url={video.url} controls width='100%' height='100%' />
        </ProportionalScaling>

        <Text sx={{ fontSize: 0, fontWeight: '500', mt: 'xsmall' }}>
          {description}
        </Text>
      </Box>
    )
  )
}

PVideo.defaultProps = {
  description: undefined,
  variant: 'default',
  video: {},
}

PVideo.propTypes = {
  variant: PropTypes.string,
  description: PropTypes.string,
  video: PropTypes.oneOf({}),
}

export default PVideo
