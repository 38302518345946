import imageSrc from '../images/call-to-action.png'
import sealFirstImage from '../images/iso-9001.png'
import sealSecondImage from '../images/iso-14001.png'
import sealThirdImage from '../images/iso-450001.png'
import LonjaImage from '../images/lonja.png'
import CompanyImage from '../images/company.png'
import ComacolImage from '../images/comacol.png'
import InfraestructureImage from '../images/infraestructure.png'

import { DYNAMIC_PARAGRAPH } from '../paragraphs/PDynamic'

export const certifications = [
  {
    type: 'seal_card',
    image: {
      alt: 'Seal 9001',
      url: sealFirstImage,
    },
    tag: 'ISO 9001:2015 <br /> # de certificado:SC088-1',
    body: 'Sistema de Gestión de la Calidad',
    variant: 'seal',
    link: 'https://d2xt7650da4t25.cloudfront.net/s3fs-public/2022-12/Coninsa-SISTEMA-DE-GESTION-DE-CALIDAD-ISO9001.pdf',
  },
  {
    type: 'seal_card',
    image: {
      alt: 'Seal 450001',
      url: sealThirdImage,
    },
    tag: 'ISO 45001:2018 <br /> # de certificado CER107622',
    body: 'Sistema de Gestión de Seguridad y Salud en el Trabajo',
    variant: 'seal',
    link: 'https://d2xt7650da4t25.cloudfront.net/s3fs-public/2022-12/Coninsa-SISTEMA-DE-GESTION-DE-SEGURIDAD-Y-SEGURIDAD-EN-EL-TRABAJO-ISO45001.pdf',
  },
  {
    type: 'seal_card',
    image: {
      alt: 'Seal 14001',
      url: sealSecondImage,
    },
    tag: 'ISO 14001:2015 <br /> # de certificado CER92575',
    body: 'Sistema de Gestión Ambiental',
    variant: 'seal',
    link: 'https://d2xt7650da4t25.cloudfront.net/s3fs-public/2022-12/Coninsa-SISTEMA-DE-GESTION-AMBIENTAL-ISO14001.pdf',
  },
]

export const members = [
  {
    type: 'member_card',
    image: {
      alt: '',
      url: LonjaImage,
    },
    title: 'Lonja de Propiedad Raíz de Barranquilla',
    body: 'Contribuye al perfeccionamiento el conjunto de actividades relacionadas con el mercado de la Propiedad Raíz, promover directa e indirectamente el entendimiento eficaz entre los diversos grupos sociales y económicos que participan en el mercado inmobiliario, en demanda o en oferta de bienes y servicios en sus diferentes modalidades.',
    variant: 'member',
  },
  {
    type: 'member_card',
    image: {
      alt: '',
      url: CompanyImage,
    },
    title: 'Lonja de Propiedad Raíz de Antioquia',
    body: 'Agrupa a personas y empresas vinculadas al sector inmobiliario en las áreas de promoción, gerencia y venta de proyectos, corretaje y propiedad raíz, administración y arrendamiento de bienes inmuebles, avalúos, administración de propiedad horizontal y consultoría inmobiliaria.',
    variant: 'member',
  },
  {
    type: 'member_card',
    image: {
      alt: '',
      url: ComacolImage,
    },
    title: 'CAMACOL',
    body: 'Es un gremio sectorial que contribuye significativamente a la consolidación de la actividad de la construcción, en un entorno globalizado.”',
    variant: 'member',
  },
  {
    type: 'member_card',
    image: {
      alt: '',
      url: InfraestructureImage,
    },
    title: 'Cámara Colombiana de la Infraestructura',
    body: 'Promueve el desarrollo socioeconómico a través de una infraestructura moderna y eficiente, defiende la institucionalidad, los principios éticos y la transparencia, busca el equilibrio en las relaciones contractuales y propende por el fortalecimiento de las empresas que intervienen en la cadena de valor y su recurso humano.',
    variant: 'member',
  },
]

const page = {
  header: {
    type: 'hero',
    title: 'SOMOS CONINSA',
    bgImage: '',
    body: `
        <p><strong>Somos el resultado de un equipo de Colaboradores, que inspirados en las necesidades de nuestros Clientes, hacemos posible el sueño de miles de familias y empresarios, porque sabemos que el bienestar es verdadero solo cuando la funcionalidad y el diseño van de la mano.</strong></p>
        <p>¡Nuestra experiencia es total! Recoge la trayectoria de Coninsa S.A. y de Ramón H. Londoño S.A., fundadas en 1972 y 1975 respectivamente, y fusionadas desde julio de 1999, con un portafolio de servicios en Diseño, Construcción y Bienes Raíces a nivel nacional e internacional.</p>
        <p>Diseñamos, construimos y comercializamos proyectos de calidad, que contribuyen al desarrollo del país, y con las que nuestros Clientes se sienten tranquilos y satisfechos al saber que aquí encuentran su lugar ideal: viviendas, oficinas, bodegas, locales, entre otros.</p>
      `,

    video: {
      url: 'https://www.youtube.com/watch?v=NXoy2XTNlac',
    },

    styles: {
      container: {
        bg: 'secondary',
      },
      content: {
        bg: 'secondary',
        color: 'white',
      },
    },
  },
  sections: [
    {
      type: 'section',
      direction: 'horizontal',
      variant: 'grayly',

      header: {
        title: 'CERTIFICACIONES DE NUESTRA GESTIÓN',
        headline: 'Contamos con',
      },
      content: [
        {
          type: 'slider',
          variant: 'colored',
          slidesToShow: [2, 3],
          items: certifications,
        },
      ],
    },

    {
      type: 'call',
      title: 'Nuestra misión',
      subtitle: 'Somos una Compañía enfocada al Cliente.',
      description: `
        <p>Nos especializamos en la Arquitectura, la Ingeniería, la Construcción, los Bienes Raíces y la prestación de Servicios Inmobiliarios. Ofrecemos soluciones integrales, competitivas y confiables en los sectores de Vivienda, Comercio, Industria, Institucional e Infraestructura.</p>
        <p>Trabajamos con el compromiso de satisfacer a nuestros Clientes, crecer mutuamente con nuestros Colaboradores, crear valor percibible para los Accionistas y transformar el hábitat en pro de una mejor calidad de vida.</p>
      `,
      image: {
        alt: 'Image 01',
        src: imageSrc,
      },
      link: {
        label: 'Vamos',
        href: '#',
      },

      variant: 'lightly',
    },

    {
      type: 'featured_list',
      title: 'NUESTROS VALORES',
      items: [
        {
          icon: 'col-heart',
          title: 'Defendemos el respeto',
          description:
            'Valoramos a las personas, aceptando que hay diferencias. Entendemos que debemos aportar a la sociedad que nos acoge y tratamos a los demás con el respeto que se merecen.',
        },
        {
          icon: 'col-people',
          title: 'Nos entusiasma trabajar en equipo',
          description:
            'Entendemos que los buenos resultados se consiguen haciendo equipo. Valoramos los aportes en la búsqueda de los objetivos. Nos estimula llegar a acuerdos y cumplirlos.',
        },
        {
          icon: 'col-medal',
          title: 'Brindamos una experiencia diferenciadora',
          description:
            'Sabemos que cada Cliente es importante, entendemos sus necesidades para entregarles productos con valor. Nos apasiona participar en el logro de sus sueños.',
        },
        {
          icon: 'col-like',
          title: 'Somos confiables',
          description:
            'Nos satisface generar confianza. Por eso somos coherentes con nuestros principios, cumplimos los compromisos y actuamos transparentemente. Defendemos la honestidad.',
        },
        {
          icon: 'col-check-circle',
          title: 'Buscamos la excelencia',
          description:
            'Nos exigimos para mejorar constantemente, optimizar los procesos y generar innovación. Nos motiva elevar la productividad de los recursos para proporcionar productos y servicios de excelente calidad.',
        },
      ],
    },

    {
      type: 'section',
      variant: 'bluey',
      header: {
        title: 'CONINSA A TRAVÉS DEL TIEMPO',
        sx: { textAlign: 'left' },
      },

      content: [
        {
          type: DYNAMIC_PARAGRAPH,
          component: 'timeline',
        },
      ],
    },

    {
      type: 'section',
      header: {
        title: 'VENTAJAS COMPETITIVAS',
      },
      variant: 'greenly',
      content: [
        {
          type: 'slider',
          variant: 'default',
          slidesToShow: [1, 1],
          items: [
            {
              type: 'slide',
              icon: 'co-bars',
              title: 'Nuestra Calidad se basa en la Gestión por Resultados',
              description:
                'Trabajamos de permanentemente en el mejoramiento de los procesos, implementando planes de control y ejecutando oportunamente acciones que permitan obtener los resultados deseados por el Cliente.',
            },
            {
              type: 'slide',
              icon: 'co-alliances',
              title: 'Alianzas estratégicas que suman conocimiento',
              description:
                'Tenemos múltiples alianzas con diferentes especialistas y empresas del sector, que por su experiencia en áreas complementarias, se suman al conocimiento que el Cliente necesita para que sus proyectos sean competitivos. Esto le genera valor e integralidad a nuestro portafolio de servicios.',
            },
            {
              type: 'slide',
              icon: 'co-leaf',
              title: 'Amigables con el Medio Ambiente y la Vida',
              description:
                'El respeto y cuidado de los recursos naturales, la reforestación y el reciclaje son actividades que llevamos a cabo en nuestras sedes, obras y proyectos. De esta forma contribuimos para que las futuras generaciones puedan disfrutar de un ambiente sano y con calidad de vida.',
            },
            {
              type: 'slide',
              icon: 'co-team',
              title: 'Un equipo humano que trabaja con orientación al Cliente',
              description:
                'Cada año generamos más 1.800 empleos para profesionales en todas las ramas del sector Inmobiliario, del Diseño y la Construcción, conformando un equipo calificado de alto desempeño.',
            },
            {
              type: 'slide',
              icon: 'col-check-circle',
              title: 'Nuestra solidez financiera es sinónimo de respaldo',
              description:
                'La viabilidad de nuestros productos, el acertado manejo de créditos, el pago cumplido de obligaciones y el respaldo patrimonial de la Compañía, nos permiten obtener mejores precios con proveedores y aprovechar diversas oportunidades de negocios en las que el respaldo financiero es muy importante.',
            },
            {
              type: 'slide',
              icon: 'col-check-circle',
              title: 'Cumplimos lo que prometemos',
              description:
                'Ejecutamos nuestras obras basados en el control y el seguimiento de las metas, a través de sistemas que nos permiten planificar, de manera ágil y oportuna, las actividades y recursos requeridos. Manejamos el presupuesto eficientemente logrando una relación de gana - gana con nuestros Clientes.',
            },
          ],
        },
      ],
    },

    {
      type: 'section',
      direction: 'horizontal',
      variant: 'grayly',
      header: {
        title: 'PREMIOS Y RECONOCIMIENTOS',
        headline: 'Conoce nuestros',
      },

      content: [
        {
          type: DYNAMIC_PARAGRAPH,
          component: 'our_awards',
        },
      ],
    },

    {
      type: 'member_section',
      header: {
        title: 'SOMOS MIEMBROS DE',
      },

      cards: members,
    },
  ],
}

export default page
