import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import IntlTelInput from "react-intl-tel-input-18";
import "react-intl-tel-input-18/dist/main.css";
import parsePhoneNumber from "libphonenumber-js";

import HabeasData from "../../../../react/quotation/src/components/HabeasData";

function ReferrerForm() {
  const { register, handleSubmit, reset } = useForm();
  const [submitted, setSubmitted] = useState(false);
  const [phone, setPhone] = useState();
  const [refPhone, setRefPhone] = useState();

  const onChange = (_, number, country) => {
    const phoneNumber = parsePhoneNumber(number, country.iso2);

    if (phoneNumber && phoneNumber?.isValid()) {
      setPhone(phoneNumber.number);
    }
  };

  const onSubmit = (data) => {
    const hubspotInfo = {
      properties: [
        {
          property: "nombre_referidor",
          value: data.referrer_name,
        },
        {
          property: "apellido_referidor",
          value: data.referrer_last_name,
        },
        {
          property: "correo_referidor",
          value: data.referrer_email,
        },
        {
          property: "telefono_referidor",
          value: phone,
        },
        {
          property: "pais_referidor",
          value: data.referrer_country,
        },
        {
          property: "tipo_referidor",
          value: data.referrer_type,
        },
        {
          property: "firstname",
          value: data.referred_name,
        },
        {
          property: "lastname",
          value: data.referred_last_name,
        },
        {
          property: "email",
          value: data.referred_email,
        },
        {
          property: "phone",
          value: refPhone,
        },
        {
          property: "proyecto_de_interes",
          value: data.referred_project,
        },
        {
          property: "pais",
          value: data.referred_country,
        },
      ],
    };

    const saleUrl = `https://api.coninsa.co/api/v2/hubspot-create-contact/${data.referred_email}`;

    return new Promise((resolve, reject) => {
      axios
        .post(saleUrl, hubspotInfo)
        .then((response) => {
          reset();
          setSubmitted(true);
          resolve(response);
          window.open("https://www.coninsa.co/gracias");
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  return (
    <div>
      {!submitted && (
        <div className="form pt-5" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-container container">
            <div className="form-header">
              <i className="co-icon icon-team"></i>
              <h2 className="form-title">
                Diligencia el formulario{" "}
                <span className="is-not-bold">
                  refiere un amigo o familiar y gana beneficios
                </span>
              </h2>
            </div>
            <form className="form-subject-matter md:w-3/4">
              <div className="form-content">
                <div className="title">
                  <div className="circle">1</div>
                  <h3 className="information">Tus datos</h3>
                </div>

                <div className="co-form">
                  <div className="form-control">
                    <input
                      {...register("referrer_name")}
                      type="text"
                      id="referrer_name"
                      placeholder="Nombres"
                      required
                    />
                  </div>
                  <div className="form-control">
                    <input
                      {...register("referrer_last_name")}
                      type="text"
                      id="referrer_last_name"
                      placeholder="Apellidos"
                      required
                    />
                  </div>
                  <div className="form-control is-intl-tel">
                    <IntlTelInput
                      {...register("phone")}
                      id="referrer_phone"
                      placeholder="Número de contacto"
                      defaultCountry={"co"}
                      onPhoneNumberChange={onChange}
                      telInputProps={{ required: true }}
                    />
                  </div>
                  <div className="form-control">
                    <input
                      {...register("referrer_email")}
                      type="email"
                      id="referrer_email"
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div className="form-control">
                    <select
                      {...register("referrer_country")}
                      className="text-area"
                      id="referrer_country"
                      required
                    >
                      <option value="">País de residencia</option>
                      <option value="Aruba">Aruba</option>
                      <option value="Australia">Australia</option>
                      <option value="Colombia">Colombia</option>
                      <option value="Espana">España</option>
                      <option value="Estados Unidos">Estados Unidos</option>
                      <option value="Mexico">Mexico</option>
                      <option value="Republica Dominicana">
                        Republica Dominicana
                      </option>
                      <option value="Otro">Otro</option>
                    </select>
                  </div>
                  <div className="form-control">
                    <select
                      {...register("referrer_type")}
                      className="text-area"
                      id="referrer_type"
                      required
                    >
                      <option value="">¿Eres comprador?</option>
                      <option value="Referido Cliente digital">
                        Soy comprador
                      </option>
                      <option value="Referido Amigo - pariente digital">
                        No soy comprador
                      </option>
                      <option value="Referido empleado digital">
                        Soy empleado de Coninsa
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="form-content">
                <div className="title">
                  <div className="circle">2</div>
                  <h3 className="information">Datos de tu referido</h3>
                </div>

                <div className="co-form">
                  <div className="form-control">
                    <input
                      {...register("referred_name")}
                      type="text"
                      id="referred_name"
                      placeholder="Nombres"
                      required
                    />
                  </div>
                  <div className="form-control">
                    <input
                      {...register("referred_last_name")}
                      type="text"
                      id="referred_last_name"
                      placeholder="Apellidos"
                      required
                    />
                  </div>
                  <div className="form-control is-intl-tel">
                    <IntlTelInput
                      {...register("referred_phone")}
                      id="referred_phone"
                      placeholder="Número de contacto"
                      defaultCountry={"co"}
                      onPhoneNumberChange={onChange}
                      telInputProps={{ required: true }}
                      required
                    />
                  </div>
                  <div className="form-control">
                    <input
                      {...register("referred_email")}
                      type="email"
                      id="referred_email"
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div className="form-control">
                    <select
                      className="text-area"
                      {...register("referred_project")}
                      id="referred_project"
                      required
                    >
                      <option value="">Proyecto a referir</option>
                      <option value="Loira">Loira</option>
                      <option value="Sendero Silvestre">
                        Sendero Silvestre
                      </option>
                      <option value="La Riviere">La Riviere</option>
                      <option value="Terravita">Terravita</option>
                      <option value="Civita">Civita</option>
                      <option value="Vida Park">Vida Park</option>
                      <option value="Reserva 67">Reserva 67</option>
                      <option value="Torem">Torem</option>
                      <option value="Natal">Natal</option>
                      <option value="Bosques De Monticello">
                        Bosques De Monticello
                      </option>
                      <option value="Poblado Niquía">Poblado Niquía</option>
                      <option value="Ciudadela Monteazul">
                        Ciudadela Monteazul
                      </option>
                      <option value="Trend">Trend</option>
                      <option value="Madeira">Madeira</option>
                      <option value="Torres de las Palmas">
                        Torres de las Palmas
                      </option>
                      <option value="Ferrara">Ferrara</option>
                      <option value="Álamos del Caribe">
                        Álamos del Caribe
                      </option>
                      <option value="Montiara">Montiara</option>
                      <option value="Altos de Monticello">
                        Altos de Monticello
                      </option>
                      <option value="Nuvoli 44">Nuvoli 44</option>
                      <option value="Leven">Leven</option>
                      <option value="Mocca">Mocca</option>
                      <option value="Sue Natura">Sue Natura</option>
                      <option value="Nuvoli 53">Nuvoli 53</option>
                      <option value="Centrik Town">Centrik Town</option>
                      <option value="Amantina">Amantina</option>
                      <option value="Kaizen">Kaizen</option>
                      <option value="Quality Center">Quality Center</option>
                      <option value="Soho Bavaria">Soho Bavaria</option>
                      <option value="Altos de Monticello">
                        Altos de Monticello
                      </option>
                      <option value="Castilla Urbana">Castilla Urbana</option>
                      <option value="Castelli">Castelli</option>
                      <option value="Felisa Condominium">
                        Felisa Condominium
                      </option>
                      <option value="Ponteverdi">Ponteverdi</option>
                      <option value="Miravento">Miravento</option>
                      <option value="Soho Bavaria">Soho Bavaria</option>
                      <option value="Sue">Sue</option>
                      <option value="Piemonti">Piemonti</option>
                    </select>
                  </div>

                  <div className="form-control">
                    <select
                      {...register("referred_country")}
                      className="text-area"
                      id="referred_country"
                      required
                    >
                      <option value="">País de residencia</option>
                      <option value="Aruba">Aruba</option>
                      <option value="Australia">Australia</option>
                      <option value="Colombia">Colombia</option>
                      <option value="Espana">España</option>
                      <option value="Estados Unidos">Estados Unidos</option>
                      <option value="Mexico">Mexico</option>
                      <option value="Republica Dominicana">
                        Republica Dominicana
                      </option>
                      <option value="Otro">Otro</option>
                    </select>
                  </div>

                  <HabeasData />

                  <div className="form-control is-checkbox">
                    <input type="checkbox" id="data-policy" required />
                    <label for="data-policy" className="data-policy">
                      Autorizo el tratamiento y la{" "}
                      <a
                        className="co-link is-primary"
                        href="https://www.coninsa.co/politica-de-tratamiento-de-datos-personales-de-coninsa-ramon-h-sa"
                        target="_blank"
                      >
                        Política de datos personales
                      </a>
                    </label>
                  </div>

                  <div className="form-actions">
                    <button className="co-button is-progress-default w-full">
                      Enviar mi referido
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      {submitted && (
        <p className="mb-8 text-center font-bold">
          Gracias por confiar en nosotros, pronto un asesor se comunicará con
          usted.
        </p>
      )}
    </div>
  );
}

export default ReferrerForm;
