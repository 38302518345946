import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { Box, Checkbox, Input, Label, Select } from 'theme-ui'
import IntlTelInput from 'react-intl-tel-input'

import CallButton from './CallButton'
import HabeasData from './HabeasData'

function ContactForm() {
  const { register, handleSubmit, reset } = useForm({})
  const [phone, setPhone] = useState()

  const onChange = (_, phoneNumber, country) => {
    const dialCode = country.dialCode
    const expression = `\\+${dialCode}`
    const regex = new RegExp(expression)
    const withoutDial = phoneNumber.replace(regex, '')
    const internationalNumber = `+${country.dialCode}` + `${withoutDial}`
    setPhone(internationalNumber)
  }

  const regions = [
    { value: 'barranquilla', name: 'Barranquilla' },
    { value: 'bogota', name: 'Bogotá' },
    { value: 'medellin', name: 'Medellín' },
  ]

  const countries = [
    { value: 'aw', name: 'Aruba' },
    { value: 'au', name: 'Australia' },
    { value: 'co', name: 'Colombia' },
    { value: 'es', name: 'España' },
    { value: 'us', name: 'Estados Unidos' },
    { value: 'mx', name: 'México' },
    { value: 'do', name: 'República Dominicana' },
    { value: 'other', name: 'Otro' },
  ]

  const schedules = [
    { value: 'de 8:00AM a 10:30AM', label: 'De 8:00AM a 10:30AM' },
    { value: 'de 10:30AM a 1:00PM', label: 'De 10:30AM a 1:00PM' },
    { value: 'de 2:00PM a 4:00PM', label: 'De 2:00PM a 4:00PM' },
    { value: 'de 4:00PM a 5:30PM', label: 'De 4:00PM a 5:30PM' },
  ]

  const contacts = [
    { value: 'whatsapp', label: 'WhatsApp' },
    { value: 'calling', label: 'Llamada' },
    { value: 'email', label: 'Correo electrónico' },
    { value: 'video_call', label: 'Videollamada' },
  ]

  const onSubmit = async data => {
    const zohoInfo = {
      data: [
        {
          First_Name: data.name,
          Last_Name: data.last_name,
          Owner: { id: '2528071000370702608' },
          Email: data.email,
          Mobile: phone,
          Regional: data.regional,
          Pais_procedencia: data.country,
          Horario_contacto: data.schedule,
          Canal_contacto: data.contact_channel,
          Origen_de_Informaci_n: 'Digital',
          Medio_Publicitario: 'www.coninsa.co',
          Creador_API: 'www.coninsa.co',
          Estrategia_de_Campa_a: 'Colombianos en el Exterior',
          Tipo_de_Canal: 'Digital',
          Tipo_de_Contacto: 'Otros',
          Layout: {
            id: '2528071000000091055',
          },
          Proyecto_de_interes: {
            id: '2528071000024260071',
          },
          Destino_del_Inmueble: 'Para Habitar',
        },
      ],
    }

    const url = 'https://api.coninsa.co/api/v2/zohocrm-create-lead'

    return new Promise((resolve, reject) => {
      axios
        .post(url, zohoInfo)
        .then(response => {
          reset()
          resolve(response)
          window.location.replace(`/gracias?destination=${window.location.pathname}`)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const baseStyles = {
    input: {
      fontSize: [1],
      mb: 'xsmall',
      py: [2],
    },
  }

  const checkboxStyles = {
    'input:checked ~ &': {
      color: 'accent',
    },

    'input:focus ~ &': {
      color: 'accent',
    },
  }

  const phoneStyles = {
    mb: ['xsmall', 'none'],
    '.allow-dropdown.intl-tel-input': { display: 'block' },
    input: {
      width: '100%',
      border: '1px solid #d0d1d1',
      borderRadius: '4px',
      lineHeight: 'inherit',
    },
  }

  return (
    <Box>
      <Box as='form' onSubmit={handleSubmit(onSubmit)}>
        <Box sx={baseStyles} mb='xsmall' register={register}>
          <Box sx={{ display: ['block', 'flex'], gap: 3 }}>
            <Input
              {...register('name')}
              id='name'
              placeholder='Nombres'
              required
            />
            <Input
              {...register('last_name')}
              id='last_name'
              placeholder='Apellidos'
              required
            />
          </Box>
          <Box
            sx={{
              display: ['block', 'grid'],
              gridTemplateColumns: 'repeat(2, 1fr)',
              gap: 3,
            }}
          >
            <Box sx={phoneStyles}>
              <IntlTelInput
                {...register('phone')}
                id='phone'
                placeholder='Número de contacto'
                defaultCountry={'co'}
                onPhoneNumberChange={onChange}
                telInputProps={{ required: true }}
              />
            </Box>

            <Box>
              <Input
                {...register('email')}
                id='email'
                placeholder='Correo electrónico'
                required
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: ['block', 'grid'],
              gridTemplateColumns: 'repeat(2, 1fr)',
              gap: 3,
              mb: '10px',
            }}
          >
            <Box sx={phoneStyles}>
              <Label sx={{ fontSize: '14px' }}>
                ¿En qué ciudad deseas invertir?*
              </Label>
              <Select sx={{ py: '6px' }} {...register('regional')}>
                {regions.map(({ value, name }) => (
                  <option value={value}>{name}</option>
                ))}
              </Select>
            </Box>

            <Box>
              <Label sx={{ fontSize: '14px' }}>País de Residencia*</Label>
              <Select sx={{ py: '6px' }} {...register('country')}>
                {countries.map(({ value, name }) => (
                  <option value={value}>{name}</option>
                ))}
              </Select>
            </Box>
          </Box>

          <Box
            sx={{
              display: ['block', 'grid'],
              gridTemplateColumns: 'repeat(2, 1fr)',
              gap: 3,
              mb: '12px',
            }}
          >
            <Box sx={phoneStyles}>
              <Label sx={{ fontSize: '14px' }}>
                ¿En qué horario desea ser contactado? (hora Colombia)*
              </Label>
              <Select sx={{ py: '6px' }} {...register('schedule')}>
                {schedules.map(({ value, label }) => (
                  <option value={value}>{label}</option>
                ))}
              </Select>
            </Box>

            <Box>
              <Label sx={{ fontSize: '14px' }}>
                ¿Por qué medio desea ser contactado?*
              </Label>
              <Select sx={{ py: '6px' }} {...register('contact_channel')}>
                {contacts.map(({ value, label }) => (
                  <option value={value}>{label}</option>
                ))}
              </Select>
            </Box>
          </Box>

          <HabeasData />

          <div className='block md:grid grid-cols-[60%_40%] gap-4 flex-initial'>
            <Label sx={{ alignItems: 'center', mt: 'small' }}>
              <Checkbox
                sx={checkboxStyles}
                {...register('accept_conditions')}
                required
              />
              <Box
                sx={{
                  fontSize: [0, 1],
                }}
              >
                Autorizo el tratamiento y la
                <Box
                  as='a'
                  href='https://www.coninsa.co/politica-de-tratamiento-de-datos-personales-de-coninsa-ramon-h-sa'
                  sx={{ color: 'accent', ml: '3px', textDecoration: 'underline' }}
                  target='_blank'
                >
                  política de datos personales
                </Box>
              </Box>
            </Label>
            <CallButton />
          </div>
        </Box>
      </Box>
    </Box>
  )
}

export default ContactForm
