import React from "react";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Breadcrumb, { BreadcrumbItem } from "@coninsa-s2/breadcrumb";
import Heading from "@coninsa-s2/heading";

export default function RealEstateChrReferralPlan() {
  const breadcrumbItems = [
    {
      label: "Inicio",
      url: "/terminos-y-condiciones",
    },
    {
      label: "Términos y condiciones generales",
      url: "/terminos-y-condiciones/generales",
    },
    {
      label: "Plan referidos Coninsa Ramón H. inmobiliaria",
    },
  ];

  return (
    <Section>
      <Container>
        <div className="mb-8">
          <Breadcrumb>
            {breadcrumbItems.map((item) => (
              <BreadcrumbItem>
                {item.url && <a href={item.url}>{item.label}</a>}
                {!item.url && item.label}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        </div>
        <div className="mb-7">
          <span className="text-lg text-s2-dark-blue lg:text-[22px]">
            Términos y condiciones
          </span>
          <Heading size="2xl">
            Plan Referidos Coninsa Ramón H. Inmobiliaria
          </Heading>
        </div>

        <div className="prose">
          <ol>
            <li>
              <h4>Vigencia:</h4>
              <p>
                La actividad referidos Coninsa Ramón H. 2018 Inmobiliaria se
                realizará del 1 de junio de 2018 al 01 de junio de 2019, en las
                ciudades: Bogotá, Medellín, Barranquilla y Cartagena.
              </p>
            </li>
            <li>
              <h4>El organizador:</h4>
              <p>
                La presente actividad está organizada por Coninsa Ramón H S.A.
                con Nit 890911431-1 con domicilio principal en la Carrera 45 #55
                – 45 en Medellín teléfonos en Medellín: (57+4) 448 88 28, Región
                Bogotá: (57+1) 6014800 y Región Caribe: (57+5) 319 96 10.
              </p>
            </li>
            <li>
              <h4>Objeto y mecánica de la actividad:</h4>
              <p>
                Campaña referidos Coninsa Ramón H. Inmobiliaria es una actividad
                que premia durante la vigencia descrita, a aquellas personas que
                nos refieran un Cliente (propietario o arrendatario) que cierre
                negocio con la inmobiliaria (cuando segenere firma de contrato
                del inmueble que se refirió) recibirán 1 bono regalo Éxito por
                valor de $100.000, aplica para las ciudades de Bogotá, Medellín,
                Barranquilla y Cartagena durante la vigencia de la actividad.
              </p>
              <ol>
                <li>
                  Los ganadores del bono regalo éxito autorizan a Coninsa Ramón
                  H. S.A a difundir y/o publicar cualquier dato que haya sido
                  suministrado en su inscripción (en el desprendible del volante
                  de la campaña), en caso que decida identificarlo como ganador
                  de la promoción o para cualquier otro fin comercial que la
                  empresa organizadora considere necesario, sin obligación de
                  compensación alguna.
                </li>
                <li>
                  Los derechos que le asisten como Titular de los datos
                  personales objeto de Tratamiento son los señalados en el
                  artículo 8 de la Ley 1581 de 2012.
                </li>
              </ol>
            </li>
            <li>
              <h4>Disposiciones legales:</h4>
              <ol>
                <li>
                  Una vez los ganadores hayan recibido el bono éxito no se
                  admitirán cambios por dinero, valores o cualquier otro bien.
                  El derecho al canje no es transferible, negociable, ni puede
                  ser comercializado o canjeado por dinero en efectivo, ni por
                  ningún otro premio.
                </li>
                <li>
                  El solo hecho de participar en esta actividad implica la total
                  aceptación de los presentes términos y condiciones.
                </li>
                <li>
                  Los participantes se comprometen a eximir de responsabilidad a
                  Coninsa Ramón H. S.A., sus accionistas, empleados o
                  colaboradores y frente a cualquier acción, daño, costes, y
                  otras responsabilidades en las que pudiera incurrir Coninsa
                  Ramón H. S.A. como resultado de cualquier tipo de
                  incumplimiento por parte suya de alguno de alguno de los
                  términos y condiciones de un uso o manipulación indebido del
                  premio (bono) que incluye la presente actividad.
                </li>
                <li>
                  Al aceptar estos términos y condiciones los participantes
                  reconocen que de manera libre, voluntaria y espontánea han
                  decidido participar en esta actividad, por lo que dentro de
                  los límites permitidos por la ley Colombiana, renuncian a
                  cualquier reclamación o acción de cualquier naturaleza en
                  contra de Coninsa Ramón H. S.A., sus accionistas, empleados o
                  colaboradores, que tengan por objeto reclamar indemnización
                  por daños o perjuicios que les haya causado su participación
                  en la actividad, o de cualquier manera relacionados con esta,
                  o por las actividades que realizare como consecuencia de dicha
                  participación. Si el premio (bono) no es aceptado o sus
                  condiciones, se considerará que ha renunciado al mismo y no
                  tendrá derecho a reclamo o indemnización alguna, ni siquiera
                  parcialmente.
                </li>
                <li>
                  La responsabilidad inherente a la actividad por parte de
                  Coninsa Ramón H. S.A. culmina con la entrega del bono regalo
                  éxito.
                </li>
              </ol>
            </li>
            <li>
              <h4>Condiciones y restricciones:</h4>
              <ol>
                <li>
                  Válido un (1) bono regalo Éxito por valor de $100.000 se
                  entregará a la persona que refiera un negocio con un contrato
                  generado de mínimo seis meses y para cánones superiores a
                  $1.000.000
                </li>
                <li>
                  Para acceder al bono, se debe hacer efectivo el negocio
                  (inmueble que se refirió), con un contrato con Coninsa Ramón
                  H. mínimo por seis meses, los inmuebles que aplican para la
                  actividad son aquellos cuyos cánones sean superiores o iguales
                  a $1.000.000 (un millón de pesos m/cte.)
                </li>
                <li>Promoción no válida para empleados de la compañía.</li>
                <li>
                  Válido únicamente (1) un bono por negocio referido efectivo.
                </li>
                <li>
                  Para reclamar dicho bono es indispensable presentar el
                  desprendible del volante de la promoción vigente.
                </li>
                <li>El bono no es canjeable en dinero en efectivo</li>
              </ol>
            </li>
          </ol>
        </div>
      </Container>
    </Section>
  );
}
