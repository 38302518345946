import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import Layout from 'layout/default/Layout'
import IntlTelInput from 'react-intl-tel-input'

import consultants from 'modules/used-property/data/consultants'
import promotedItems from 'modules/used-property/data/promoted-items'
import UsedProperties from '../modules/used-property/components/UsedProperties'

function UsedSale() {
  const { register, handleSubmit, reset } = useForm({})
  const [submitted, setSubmitted] = useState(false)
  const [phone, setPhone] = useState()

  const onChangePhone = (_, phoneNumber, country) => {
    const { dialCode } = country
    const expression = `\\+${dialCode}`
    const regex = new RegExp(expression)
    const withoutDial = phoneNumber.replace(regex, '')
    const internationalNumber = `+${country.dialCode}` + `${withoutDial}`

    setPhone(internationalNumber)
  }

  const onSubmit = async data => {
    const zohoInfo = {
      data: [
        {
          First_Name: data.first_name,
          Last_Name: data.last_name,
          Owner: { id: '2528071000370702608' },
          Email: data.email,
          Mobile: phone,
          Regional: 'Bogotá',
          Origen_de_Informaci_n: 'Digital',
          Medio_Publicitario: 'www.coninsa.co',
          Creador_API: 'www.coninsa.co',
          Estrategia_de_Campa_a: 'Venta de usados',
          Tipo_de_Canal: 'Digital',
          Tipo_de_Contacto: 'Otros',
          Layout: {
            id: '2528071000000091055'
          },
          Proyecto_de_interes: {
            id: '2528071000024260071'
          },
          Destino_del_Inmueble: 'Para Habitar'
        }
      ]
    }

    const url = 'https://api.coninsa.co/api/v2/zohocrm-create-lead'

    return new Promise((resolve, reject) => {
      axios
        .post(url, zohoInfo)
        .then(response => {
          reset()
          setSubmitted(true)
          resolve(response)
          window.open('https://www.coninsa.co/gracias')
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  return (
    <Layout>
      <Helmet>
        <title>¿Buscas una propiedad usada? | Coninsa</title>
        <meta name="description" content="Cuando buscas una propiedad usada somos la mejor opción, Déjanos ayudarte a vender tu inmueble o a buscar el que sueñas. Conoce nuestro portafolio en inmuebles usados de vivienda, comercio, industria y servicios." />
        <link rel="canonical" href="https://www.coninsa.co/venta-de-usados/" />
      </Helmet>
      <div className='co-poligon-banner' style={{ '--co-poligon-banner--background': 'url(/system-ui/images/alternative-banner.png)' }}>
        <img
          src='/system-ui/images/alternative-banner.png'
          className='banner-image'
        />
        <div className='container banner-container'>
          <div className='banner banner-content'>
            <h1 className='text-3xl md:text-4xl text-center md:text-left'>
              Cuando buscas una <br />
              <span className='font-handwritting text-[48px] md:text-5xl text-co-green'>
                propiedad usada
              </span>
              <div className='text-center'>
                <div className='dialog is-compact is-teal'>
                  <span className='font-bold text-2xl md:text-[26px]'>
                    Somos la mejor opción
                  </span>
                </div>
              </div>
            </h1>
            <div className='banner-text text-center max-w-md mx-auto'>
              <p className='font-bold leading-6 text-coninsa-green-300 text-lg md:text-xl'>
                Déjanos ayudarte a vender tu inmueble o a buscar el que sueñas.
              </p>
              <p className='font-normal leading-5 md:leading-6 text-base md:text-lg'>
                Conoce nuestro portafolio en inmuebles usados de vivienda, comercio,
                industria y servicios.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='container relative max-w-2xl'>
        <div className='dialog arrow-left is-secondary flex justify-center p-2 md:p-6 -translate-y-6 mb-4'>
          <h2 className='font-extralight font-display text-xl md:text-4xl'>
            ¡TENEMOS LAS MEJORES{' '}
            <span className='font-medium md:font-semibold'>OPCIONES PARA TI!</span>
          </h2>
        </div>
      </div>

      <UsedProperties />

      <div className='headline'>
        <h1 className='headline-title'>
          En un solo lugar <br />
          <span className='is-highlighted font-handwritting'>vendes o compras</span>
          <br />
          <p className='headline-subtitle'>tu propiedad usada</p>
        </h1>
      </div>

      <div className='bg-teal-700 pt-10 md:pt-20 pb-28'>
        <div className='container flex gap-4 flex-wrap justify-center md:[&>div]:w-[calc((100%-var(--spacing-4))/2)] xl:[&>div]:w-[calc((100%-var(--spacing-8))/3)]'>
          {promotedItems.map(item => (
            <div className='co-card is-promoted'>
              <img src={item?.icon} />
              <div>
                <h4 className='co-heading-5 is-secondary'>{item?.title}</h4>
                <p className='text-sm leading-5 md:text-base'>{item?.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className='container relative md:max-w-3xl md:mx-auto text-center'>
        <div className='dialog arrow is-primary -translate-y-12'>
          <h1 className='font-bold text-3xl'>Comunícate con nuestros consultores</h1>
          <p className='font-light leading-5 text-lg'>
            para conocer en detalle el proceso de venta de tu inmueble, tipos de
            contratos{' '}
            <span className='font-medium'>y beneficios extras que te otorgamos</span>
            .
          </p>
        </div>
      </div>

      <div className='container flex gap-2 justify-center flex-wrap pb-12'>
        {consultants.map(consultant => (
          <div className='advisor-card'>
            <div className='advisor-card-image'>
              <img src={consultant?.photo} />
            </div>
            <div className='advisor-content'>
              <h2>{consultant?.names}</h2>
              <h4>{consultant?.position}</h4>
              <a
                href={`https://api.whatsapp.com/send?phone=57${consultant?.contact}&text=Hola`}
                target='_blank'
                className='co-button is-secondary'
                rel='noreferrer'
              >
                <i className='co-icon icon-whatsapp' />
                Contactar
              </a>
            </div>
          </div>
        ))}
      </div>

      <div className='bg-co-dark-cyan py-10 md:py-20'>
        <div className='container grid md:grid-cols-2 gap-6'>
          <div className='text-white'>
            <h2 className='co-heading-1 mb-6 font-display'>CONTÁCTANOS</h2>
            <p className='text-2xl font-thin max-w-sm'>
              Puedes comunicarte con nosotros y así recibirás un acompañamiento más
              personalizado.
            </p>
          </div>
          <div className='co-card'>
            {!submitted && (
              <div>
                <h3 className='co-heading-5 text-center mb-4'>
                  Comunícate con nosotros
                </h3>

                <form className='co-form' onSubmit={handleSubmit(onSubmit)}>
                  <div className='form-control'>
                    <input
                      {...register('first_name')}
                      type='text'
                      id='first_name'
                      placeholder='Nombres'
                      required
                    />
                  </div>
                  <div className='form-control'>
                    <input
                      {...register('last_name')}
                      type='text'
                      id='last_name'
                      placeholder='Apellidos'
                      required
                    />
                  </div>
                  <div className='form-control is-intl-tel'>
                    <IntlTelInput
                      {...register('phone')}
                      id='phone'
                      placeholder='Número de contacto'
                      defaultCountry='co'
                      onPhoneNumberChange={onChangePhone}
                      telInputProps={{ required: true }}
                      required
                    />
                  </div>
                  <div className='form-control'>
                    <input
                      {...register('email')}
                      id='email'
                      type='email'
                      placeholder='Email'
                      required
                    />
                  </div>
                  <div className='form-control'>
                    <input
                      {...register('city')}
                      id='city'
                      type='text'
                      placeholder='Ciudad de residencia'
                      required
                    />
                  </div>
                  <div className='form-control flex flex-col md:flex-row gap-4'>
                    <select
                      {...register('property_type')}
                      className='inmueble'
                      id='property_type'
                      required
                    >
                      <option value=''>Tipo de inmueble</option>
                      <option value='Apartamento'>Apartamento</option>
                      <option value='Bodega'>Bodega</option>
                      <option value='Casa'>Casa</option>
                      <option value='Consultorio'>Consultorio</option>
                      <option value='Edificio'>Edificio</option>
                      <option value='Local'>Local</option>
                      <option value='Lote'>Lote</option>
                      <option value='Oficina'>Oficina</option>
                    </select>
                    <select
                      {...register('service_type')}
                      className='servicio'
                      id='service_type'
                      placeholder='Tipo de servicio'
                      required
                    >
                      <option value=''>Tipo de servicio</option>
                      <option value='Vivienda'>Vivienda</option>
                      <option value='Comercio'>Comercio</option>
                    </select>
                  </div>
                  <div className='form-control'>
                    <textarea
                      {...register('description')}
                      id='description'
                      placeholder='Escríbenos los detalles de tu solicitud'
                      required
                    />
                  </div>

                  <div className='form-control is-checkbox'>
                    <input
                      type='checkbox'
                      id='data-policy'
                      name='data-policy'
                      required
                    />
                    <label htmlFor='data-policy' className='!text-sm'>
                      Autorizo el tratamiento y la{' '}
                      <a className='co-link is-secondary'>
                        Política de datospersonales
                      </a>
                    </label>
                  </div>

                  <div className='form-actions'>
                    <button className='co-button is-progress-default w-full'>
                      Contactarme
                    </button>
                  </div>
                </form>
              </div>
            )}

            {submitted && (
              <p className='font-bold text-center'>
                Gracias por confiar en nosotros, pronto un asesor se comunicará con
                usted.
              </p>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default UsedSale
