export class ValorPresente {
  constructor(
    public fecha: string,
    public dias: number,
    public valorDesc: number,
    public valorIntereses: number,
    public conceptoCuotaId: number,
    public conceptoCuota: string,
    public conceptoCuotaCat: number
  ) {
  }
}
