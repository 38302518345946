import React from 'react'
import PropTypes from 'prop-types'

import { Section, Header, List } from 'components'

function BuildingFeatures({ features, mb, title, upper, ...props }) {
  const listStyles = {
    columnCount: [2, 3],
    verticalAlign: 'top',
    li: { fontSize: [1, 2], mb: 'xsmall' },
  }

  if (!features.length) {
    return null
  }

  return (
    <Section
      title={
        <Header sx={{ mb: ['medium', 'large'] }} upper={upper} {...props}>
          {title}
        </Header>
      }
      variant='grayly'
      mb={mb}
    >
      <List color='#80c343' sx={listStyles}>
        {features.map(feature => (
          <li>{feature}</li>
        ))}
      </List>
    </Section>
  )
}

BuildingFeatures.defaultProps = {
  features: [],
  mb: '',
  upper: true,
}

BuildingFeatures.propTypes = {
  features: PropTypes.arrayOf([PropTypes.string]),
  mb: PropTypes.string,
  title: PropTypes.string.isRequired,
  upper: PropTypes.bool,
}

export default BuildingFeatures
