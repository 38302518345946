import { graphql } from 'gatsby'
import React from 'react'

import PropTypes from 'prop-types'
import { Box } from 'theme-ui'
import { Helmet } from 'react-helmet'
import FloatingWhatsappBtn from 'modules/home/components/FloatingWhatsappBtn'
import ContactForm from '../component-v2/ContactForm'
import { mapResolver } from '../mappers'
import { paragraphResolver } from '../paragraphs'

import Layout from '../layout/default/Layout'
import Timeline from '../dynamic/components/Timeline'
import steps from '../modules/from-abroad/data/steps.js'
import contactInfo from '../modules/from-abroad/data/contact-us.js'

import mBannerImage from '../images/m-colombians-abroad.png'
import cBannerImage from '../images/colombians-abroad.png'

function FromAbroadTemplate({ data, ...props }) {
  const info = data?.drupal?.node
  const tabs = info?.tabs
  const members = info?.team

  const normalizedParagraphs = tabs['0']?.entity?.content?.map(mapResolver) || []

  const memberSlideOptions = {
    arrows: false,
    rewind: true,
    perPage: 4,
    gap: '1rem',

    breakpoints: {
      768: {
        gap: 0,
        perPage: 2,
        padding: { left: 0, right: '4rem' },
        pagination: false,
      },
      640: {
        gap: 0,
        perPage: 1,
        padding: { left: 0, right: '4rem' },
        pagination: false,
      },
    },
  }

  return (
    <Layout>
      <Helmet>
        <title>Compras desde el exterior | Coninsa</title>
        <meta name="description" content="Déjanos ayudarte a alcanzar tus metas y ser partícipes de la alegría de comprar un inmueble en Colombia para vivir o invertir" />
        <link rel="canonical" href="https://www.coninsa.co/desde-el-exterior/" />
      </Helmet>
      <h1 hidden>¿Estas en el exterior?</h1>
      <Box sx={{ display: ['none', 'block'] }}>
        <FloatingWhatsappBtn path='https://api.whatsapp.com/send?phone=573123636333&text=Hola!' />
      </Box>

      <div {...props}>
        <div>
          <img className='hidden md:block' src={cBannerImage} />
          <img className='min-w-full block md:hidden' src={mBannerImage} />
        </div>

        <div className='container relative md:flex md:justify-center'>
          <div className='absolute mx-5 md:mx-0 -top-8 z-10'>
            <h2
              className='bg-coninsa-blue-900 font-display font-extralight leading-8 md:leading-9 px-10 md:px-8 py-5 md:py-6
                rounded-lg shadow-[0px_3px_6px_#00000029] text-[28px] md:text-4xl text-white uppercase text-center relative'
            >
              <span className='font-semibold'>¡Estás a pocos pasos</span> de lograr
              tu sueño!
            </h2>
            <span
              className='absolute w-0 h-0 border-t-[25px] border-t-transparent border-r-[22px] md:border-r-[32px]
                border-r-transparent border-l-transparent border-b-[22px] md:border-b-[32px]
                border-b-coninsa-blue-900 border translate-x-52 md:translate-x-44 rotate-180'
            ></span>
          </div>
        </div>

        <div className='bg-coninsa-gray-300 pb-10 pt-40 md:pb-10 md:pt-48'>
          <div className='container mb-8 md:mb-14'>
            <div className='block px-5 md:px-0 text-sm md:grid grid-cols-3 gap-x-4 gap-y-24 md:text-base'>
              {steps?.map(step => (
                <div
                  className='rounded-lg bg-coninsa-white-300 border border-solid border-transparentpx-4 mb-[70px]
                    last:mb-0 md:mb-0 px-4 md:px-5 pb-4 md:pb-5 shadow-[0px_3px_6px_#00000029] opacity-100'
                >
                  <div className='mb-4 md:mb-7 md:ml-4 border relative -translate-y-5'>
                    <img
                      src={step?.icon}
                      className='absolute w-[85px] h-[85px] md:w-[110px] md:h-[110px] block -left-1 md:-left-4 -top-8 md:-top-10 z-10'
                    />
                    <h4 className='bg-coninsa-green-400 text-white px-5 py-[6px] font-bold rounded-[4px] uppercase text-end'>
                      {step?.step}
                    </h4>
                    <span
                      className='absolute w-0 h-0 border-t-[20px] border-t-transparent border-r-[22px]
                      border-r-transparent border-l-transparent border-b-[20px]border-b-cobg-coninsa-green-400
                      border rotate-180 right-8 md:right-9 top-8 md:top-9'
                    ></span>
                  </div>

                  <h5 className='font-bold text-coninsa-blue-900 text-base md:text-[17px]'>
                    {step?.title}
                  </h5>
                  <p className='text-coninsa-gray-900 leading-5'>{step?.body}</p>
                </div>
              ))}
            </div>
          </div>

          <div className='container px-5 md:px-0'>
            <div className='flex gap-4 overflow-x-auto md:overflow-x-hidden pb-4'>
              <div
                className='w-[87%] md:w-[calc(50%-.5rem)] flex-shrink-0 bg-coninsa-blue-900 grid grid-cols-1
                lg:grid-cols-[51%_49%] gap-2 items-center p-4 md:px-6 md:py-9 rounded-lg opacity-100'
              >
                <p className='text-white'>
                  Si compraste un inmueble de comercio o vivienda por inversión,
                  <span className='font-bold'> en Coninsa te lo rentabilizamos</span>
                  .
                </p>
                <div>
                  <a
                    href='https://contenidos.coninsa.co/preconsignar-inmueble'
                    target='_blank'
                    className='text-white border border-white inline-block rounded-md text-sm font-bold px-5 py-[10px]
                    cursor-pointer uppercase hover:bg-white hover:text-coninsa-green-300'
                  >
                    Preconsigna tu inmueble
                  </a>
                </div>
              </div>

              <div
                className='w-[87%] md:w-[calc(50%-.5rem)] flex-shrink-0 bg-coninsa-blue-900 grid grid-cols-1
                lg:grid-cols-[52%_48%] gap-4 items-center p-5 md:px-6 md:py-9 rounded-lg opacity-100'
              >
                <p className='text-white mb-2 md:mb-0'>
                  Si deseas arrendar o comprar apartamento usado, locales, bodegas u
                  oficinas,{' '}
                  <span className='font-bold'>
                    tenemos variedad de opciones para ti
                  </span>
                  .
                </p>

                <div>
                  <a
                    href='https://busquedas.coninsa.co/inmuebles/arriendo'
                    target='_blank'
                    className='text-white border border-white inline-block rounded-md text-sm font-bold px-5 py-[10px]
                  cursor-pointer uppercase hover:bg-white hover:text-coninsa-green-300'
                  >
                    Conoce nuestra oferta
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='container px-5 py-8 md:pt-16 pb-12 md:px-0'>
          <h3 className='text-2xl md:text-[33px] text-coninsa-blue-900 font-display font-normal mb-2'>
            {tabs['0']?.entity?.title}
          </h3>
          <div>{normalizedParagraphs.map(paragraphResolver)}</div>
        </div>

        <div className='container'>
          {tabs?.map(item => (
            <div>
              <h3>{item?.entity['0']?.title}</h3>
            </div>
          ))}
        </div>

        <div className='bg-coninsa-gray-300 pb-5 pt-7 md:pb-14 md:pt-16 overflow-x-auto px-5 md:px-0'>
          <div className='container'>
            <div className='mb-5 md:mb-12'>
              <Box
                sx={{
                  color: 'neutral',
                  fontFamily: 'heading',
                  fontSize: ['26px', '33px'],
                  mb: 'small',
                  opacity: 1,
                  textAlign: 'center',
                }}
              >
                Tenemos un equipo dispuesto{' '}
                <Box
                  as='span'
                  sx={{ fontFamily: ['28px', '36px'], fontWeight: 'semibold' }}
                >
                  PARA RESOLVER TUS DUDAS
                </Box>
              </Box>
              <Box
                sx={{
                  borderBottom: '2px solid',
                  borderColor: 'secondary',
                  width: '303px',
                  m: '0 auto',
                }}
              />
            </div>

            <div className='flex gap-4 md:gap-8 md:justify-center pb-4 md:pb-0'>
              {members?.map(member => (
                <div className='flex-shrink-0 md:flex-shrink'>
                  <img
                    className='h-56 w-52 md:h-auto md:w-auto'
                    src={member?.entity?.photo?.url}
                  />

                  <div class='text-center text-coninsa-gray-900'>
                    <div className='font-display text-xl font-medium opacity-100 md:text-2xl'>
                      {member?.entity?.name}
                    </div>
                    <div className='text-xs md:text-sm'>
                      {member?.entity?.position}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className='bg-coninsa-green-300 pb-10 pt-10 md:pt-16 md:pb-16'>
          <div className='container px-5 md:px-0 text-center mb-8'>
            <h3 className='font-display text-white text-[28px] md:text-[33px]'>
              Puedes <span className='font-medium'>comunicarte</span> con nosotros
            </h3>
            <p className='text-white text-base md:text-lg mb-4'>
              y así recibirás un acompañamiento más personalizado
            </p>
            <div className='border border-solid border-white w-[302px] my-0 mx-auto'></div>
          </div>

          <div className='container'>
            <div className='flex flex-col px-14 md:px-0 md:flex-row gap-4 justify-center mb-4 md:mb-0 '>
              {contactInfo?.map(item => (
                <a
                  href={item?.path}
                  target='_blank'
                  className='bg-coninsa-green-500 flex rounded-lg px-5 py-[14px] min-w-[258px]
                    text-white hover:bg-white hover:text-coninsa-green-500 cursor-pointer'
                >
                  <outline-icon class='w-[30px] h-[32px] block' src={item?.icon} />
                  <p className='ml-6 leading-4'>
                    {item?.tag} <br />
                    <span className='font-extrabold uppercase'>{item?.label}</span>
                  </p>
                </a>
              ))}
            </div>
          </div>
        </div>

        <div className='bg-white relative pb-10 md:pb-16'>
          <div className='absolute bg-coninsa-green-300 h-48 left-0 right-0 top-0'></div>
          <div className='container relative'>
            <div className='px-5 md:px-40'>
              <div className=' bg-white rounded-lg opacity-100 p-6 md:p-10 shadow-[0px_3px_6px_#00000029]'>
                <p className='text-base md:text-lg text-center mb-4 font-medium'>
                  O déjanos tus datos y te devolvemos una llamada
                </p>
                <ContactForm />
              </div>
            </div>
          </div>
        </div>

        <div className='bg-coninsa-blue-900 py-10 md:py-16'>
          <div className='container px-5 md:px-0 text-white'>
            <h3 className='font-display font-extralight mb-0 text-[28px] md:text-[33px]'>
              ¿Por qué confiar en <span className='font-medium'>Coninsa</span>?
            </h3>
            <Timeline />
          </div>
        </div>
      </div>
    </Layout>
  )
}

FromAbroadTemplate.propTypes = {
  data: PropTypes.oneOf({}).isRequired,
}

export default FromAbroadTemplate

export const pageQuery = graphql`
  query customPage($id: String!) {
    drupal {
      node: nodeById(id: $id) {
        id: nid
        ... on Drupal_NodeCustomPage {
          __typename
          image: fieldImage {
            alt
            url
          }
          title
          subtitle: fieldSubtitle {
            value
          }
          body {
            value
          }
          tabs: fieldTabs {
            entity {
              ... on Drupal_ParagraphTab {
                label: fieldLabel
                title: fieldTitle
                content: fieldContent {
                  id: targetId
                  entity {
                    __typename

                    ... on Drupal_ParagraphRichText {
                      body: fieldBody {
                        value
                      }
                    }

                    ... on Drupal_ParagraphDynamicComponent {
                      component: fieldComponent
                    }

                    ... on Drupal_ParagraphThreeItemDiagram {
                      title: fieldTitle
                      items: fieldThreeItems {
                        entity {
                          ... on Drupal_ParagraphItemDiagram {
                            icon: fieldImageIcon {
                              alt
                              url
                            }
                            title: fieldTitle
                            description: fieldDescription
                          }
                        }
                      }
                    }

                    ... on Drupal_ParagraphIconList {
                      items: fieldItems {
                        entity {
                          ... on Drupal_ParagraphIconItem {
                            icon: fieldImageIcon {
                              alt
                              url
                            }
                            label: fieldLabel
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          team: fieldTeamMembers {
            entity {
              ... on Drupal_ParagraphTeamMember {
                photo: fieldPhoto {
                  url
                  alt
                }
                name: fieldName
                position: fieldPosition
              }
            }
          }
        }
      }
    }
  }
`
