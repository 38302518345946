import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

function BaseWrapper({ columns, rowGap, ...props }) {
  const baseStyles = {
    display: 'grid',
    columnGap: 'medium',
    gridTemplateColumns: ['1fr', columns],
    bg: 'white',
    rowGap,
  }

  return <Box __css={baseStyles} {...props} />
}

BaseWrapper.defaultProps = {
  columns: undefined,
  rowGap: undefined,
}

BaseWrapper.propTypes = {
  columns: PropTypes.string,
  rowGap: PropTypes.string,
}

export default BaseWrapper
