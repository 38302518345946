import { gql } from '@apollo/client'

export const SEARCH_PROJECTS_QUERY = gql`
  query SearchProjects($searchText: String) {
    searchAPISearch(
      index_id: "search_projects"
      conditions: [
        { name: "status", value: "1", operator: "=" }
        { name: "field_final_publication", value: "1", operator: "=" }
        { name: "field_for_sale", value: "0", operator: "<>" }
      ]
      fulltext: { keys: [$searchText] }
      sort: [{ field: "field_ordering", value: "DESC" }]
      range: { offset: 0, limit: 10 }
    ) {
      result_count
      documents {
        ... on SearchProjectsDoc {
          price: field_sale_price_from
          name: main_name
          images: project_images
          description
          url: project_url
          location: project_location
        }
      }
      facets {
        name
        values {
          count
          filter
        }
      }
    }
  }
`
