import React from 'react';
import clsx from 'clsx'

import "./styles.css"

function BlogCard({ imageUrl, className, ...props }) {
  return (
    <div className={clsx('s2-blog-card', className)} >
      <img className='s2-blog-card__image' src={imageUrl} />
      <div className='s2-blog-card__content'>
        {props.children}
      </div>
    </div>
  )
}

export default BlogCard
