import React from 'react'
import PropTypes from 'prop-types'
import { Box, Link } from 'theme-ui'

import { Divider, Icon } from 'ui'

function ProjectTerms({ link, ...props }) {
  return (
    <Box sx={{ fontSize: 1 }} {...props}>
      <Divider sx={{ width: ['100%', '330px'] }} />
      <Icon name='fa-reg-file-alt' color='#00993B' mr={2} />
      <Link color='#00993B' href={link} target='_blank'>
        Conoce los términos y condiciones del proyecto
      </Link>
    </Box>
  )
}

ProjectTerms.defaultProps = {
  link: '#',
}

ProjectTerms.propTypes = {
  link: PropTypes.string,
}

export default ProjectTerms
