import React from "react";

import ContactForm from "./ContactForm";

import linkedinIcon from "./../../assets/icons/alternative-linkedin.svg";

function ContactSection() {
  return (
    <div className="bg-coninsa-green-500 py-14" id="contacto">
      <div className="px-5 md:px-0 md:container block md:grid grid-cols-2 gap-4 items-center">
        <div className="mb-6 md:mb-0">
          <h2 className="font-display font-medium text-2xl md:text-[44px] text-white uppercase">
            Contáctanos
          </h2>
          <div className="border border-solid border-gray-400 my-4 md:my-5 w-32" />
          <p className="text-white text-xl md:text-3xl leading-8 mb-6 md:mb-10 md:leading-[44px] max-w-sm">
            ¿Has imaginado el espacio de tus sueños? nuestro equipo espera por
            ti.
          </p>

          <p className="text-white mb-12">
            Si estás interesado en trabajar con nosotros <br />
            <span className="font-semibold">
              ingresa a nuestra sección
            </span>{" "}
            <a
              href="https://www.coninsa.co/trabaje-con-nosotros/"
              target="_blank"
              className="cursor-pointer font-semibold text-coninsa-green-300 underline"
              rel="noreferrer"
            >
              Trabaja con Nosotros
            </a>
          </p>

          <a
            href="https://www.linkedin.com/company/coninsa/"
            target="_blank"
            className="cursor-pointer"
            rel="noreferrer"
          >
            <img src={linkedinIcon} className="w-14 h-14 mb-3" />
            <p className="text-white hover:text-coninsa-green-300">
              <span className="block font-medium">Síguenos en LinkedIn</span>y
              entérate de las publicaciones más recientes.
            </p>
          </a>
        </div>

        <div className="bg-white px-4 py-6 md:p-10">
          <ContactForm />
        </div>
      </div>
    </div>
  );
}

export default ContactSection;
