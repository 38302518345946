import PropTypes from 'prop-types'
import React from 'react'

import CoLegalPerson from '../pages/CoLegalPerson'
import CoNaturalPerson from '../pages/CoNaturalPerson'

function CoLegalPersonForm(props) {
  const { cotenant } = props
  const settingsMap = {
    juridica: CoLegalPerson,
    natural: CoNaturalPerson,
  }

  const Component = settingsMap[cotenant]

  return <Component {...props} />
}

CoLegalPersonForm.propTypes = {
  cotenant: PropTypes.string.isRequired,
}

export default CoLegalPersonForm
