import React from "react";
import { useForm } from "react-hook-form";

export default function Step8({ onSuccess, id }) {
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    onSuccess()
  };

  return (
    <div className="co-card max-w-4xl mx-auto text-center md:px-24">
      <div className="flex flex-wrap justify-center py-6">
        <div className="check">
          <i className="co-icon icon-check"></i>
        </div>
      </div>

      <h3 className="co-heading-4 mb-8" style={{ color: "#80C342" }}>
        ¡Has promocionado tu inmueble correctamente!
      </h3>

      <p className="co-heading-6 mb-8">
        Hemos recibido toda la información de tu inmueble y la estamos
        revisando para ser promocionado en nuestro portal. Un asesor se
        contactará contigo en el menor tiempo posible para afinar algunos
        detalles.
      </p>
      <span className="co-heading-5">¡Gracias por confiar en nosotros!</span>
      <div className="flex flex-wrap justify-center py-8">
        <a href="/consigna-tu-inmueble" class="co-button is-progress-default w-80">
          VOLVER AL INICIO
        </a>
      </div>
    </div>
  );
}
