import React from 'react'
import PropTypes from 'prop-types'

import { Image, Box } from 'theme-ui'

const CallToActionImage = ({ variant, ...props }) => {
  const baseStyles = {
    border: '6px solid',
    display: 'inline-block',
    borderRadius: '50%',
    width: [null, '400px'],
    height: [null, '400px'],
    overflow: 'hidden',
  }

  const imageStyles = {
    height: ['auto', '100%'],
    minHeight: '327px',
    objectFit: 'cover',
  }

  return (
    <Box __css={baseStyles} variant={`ctas.${variant}.image`}>
      <Image __css={imageStyles} {...props} />
    </Box>
  )
}

CallToActionImage.defaultProps = {
  variant: 'default',
}

CallToActionImage.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
}

export default CallToActionImage
