import {
  ALL_OPTION_VALUE,
  EMPTY_STRING_VALUE,
} from "@coninsa-site/project/src/constants";
import {
  buildPrettyFacetPaths,
  getActiveFilters,
} from "@coninsa-site/project/src/utils";

import { SEARCH_PROGRESS_QUERY } from "./queries";

const baseUrl = "/proyectos/avance-de-obra";
const breadcrumbItems = [
  {
    label: "Inicio",
    url: "/",
  },
  {
    label: "Proyectos",
    url: "/proyectos",
  },
  {
    label: "Avance de obras",
  },
];
const title = "Conoce los proyectos que tenemos en construcción y cómo avanza su obra";
const loadMoreLabel = "Cargar más proyectos";
const searchQuery = SEARCH_PROGRESS_QUERY;

const configs = {
  text: {
    pretty: "text",
    facetName: "text",
    fieldName: "text",
    defaultValue: EMPTY_STRING_VALUE,
    useLabel: false,
    translate: (t) => t,
    type: "query",
  },
  department_project: {
    pretty: "region",
    facetName: "department_project",
    fieldName: "department_project",
    defaultValue: ALL_OPTION_VALUE,
    useLabel: false,
    translate: (t) => t,
    type: "pathname",
  },
};

const initialValues = {
  text: "",
  department_project: ALL_OPTION_VALUE,
};

const getSearchQuery = (data) => {
  let andConditions = [];
  let fullSearch = "";

  if (data.text?.length >= 3) {
    fullSearch = data.text.replace(/[.,]/g, "").split(" ");
  }

  if (data.department_project !== ALL_OPTION_VALUE) {
    andConditions = andConditions.concat({
      name: "department_project",
      value: data.department_project,
    });
  }

  return {
    text: fullSearch,
    and_conditions: andConditions,
  };
};

const getPrettyPaths = (data) => {
  return buildPrettyFacetPaths(baseUrl, configs, data);
};

const getActiveFacets = (data) => {
  return getActiveFilters(baseUrl, configs, data);
};

const defaultSortFields = [
  {
    field: "field_sticky",
    value: "DESC"
  },
];

const sortFields = {};

const sortOptions = [];

export default {
  breadcrumbItems,
  title,
  loadMoreLabel,

  baseUrl,
  searchQuery,
  configs,
  initialValues,
  getSearchQuery,
  getPrettyPaths,
  getActiveFacets,

  sort: {
    defaultFields: defaultSortFields,
    fields: sortFields,
    options: sortOptions,
  }
};
