import React, { useEffect, useState } from "react";
import Footsteps from "@coninsa-ui/react-footsteps"

import Auth0 from "./slices/Auth0";
import Auth1 from "./slices/Auth1";

import Step1 from "./slices/Step1";
import Step2 from "./slices/Step2";
import Step3 from "./slices/Step3";
import Step4 from "./slices/Step4";
import Step5 from "./slices/Step5";
import Step6 from "./slices/Step6";
import Step7 from "./slices/Step7";
import Step8 from "./slices/Step8";

const AUTH_0_STEP = -1;
const AUTH_1_STEP = 0;
const FORM_1_STEP = 1;
const FORM_2_STEP = 2;
const FORM_3_STEP = 3;
const FORM_4_STEP = 4;
const FORM_5_STEP = 5;
const FORM_6_STEP = 6;
const FORM_7_STEP = 7;
const FORM_8_STEP = 8;

export function getQueryString(name) {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(name);
}

export function setQueryString(name, value) {
  let searchParams = new URLSearchParams(window.location.search);
  searchParams.set(name, value)

  const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
  window.history.pushState(null, '', newUrl)
}

export default function AdvertiseProperty() {
  const [step, setStep] = useState(-1);
  const [id, setId] = useState();

  const handleAuthSuccess = () => {
    setStep(step + 1);
  }

  const handleStepOneSuccess = ({ id }) => {
    setStep(step + 1);
    setId(id);
  }

  const handleStepSuccess = () => {
    setStep(step + 1);
  }

  const handleBack = () => {
    setStep(step - 1);
  }

  useEffect(() => {
    const step = getQueryString("step");
    const id = getQueryString("id");

    if (step) {
      setStep(Number.parseInt(step));
    }

    if (id) {
      setId(Number.parseInt(id));
    }
  }, []);

  useEffect(() => {
    setQueryString("step", step);
  }, [step]);

  useEffect(() => {
    if (id) {
      setQueryString("id", id);
    }
  }, [id]);

  const steps = [
    { label: 1, desc: "Responde estas preguntas" },
    { label: 2, desc: "Ubicación del inmueble" },
    { label: 3, desc: "Información general" },
    { label: 4, desc: "Información adicional" },
    { label: 5, desc: "Detalles de la publicación" },
    { label: 6, desc: "Fotos del inmueble" },
    { label: 7, desc: "Tarifas y amparos" },
    { label: 8, desc: "Confirmación" },
  ]

  return (
    <div className="bg-zinc-50">
      <header className="headline is-small">
        <h1>Promocionar un inmueble</h1>
      </header>

      <div className="container py-16">
        {step === AUTH_0_STEP && <Auth0 onSuccess={handleAuthSuccess} />}
        {step === AUTH_1_STEP && <Auth1 onSuccess={handleAuthSuccess} />}

        {step > AUTH_1_STEP && <Footsteps steps={steps} currentStep={step} />}

        {step === FORM_1_STEP && <Step1 onSuccess={handleStepOneSuccess} />}
        {step === FORM_2_STEP && id && <Step2 onSuccess={handleStepSuccess} onBack={handleBack} id={id.toString()} />}
        {step === FORM_3_STEP && id && <Step3 onSuccess={handleStepSuccess} onBack={handleBack} id={id.toString()} />}
        {step === FORM_4_STEP && id && <Step4 onSuccess={handleStepSuccess} onBack={handleBack} id={id.toString()} />}
        {step === FORM_5_STEP && id && <Step5 onSuccess={handleStepSuccess} onBack={handleBack} id={id.toString()} />}
        {step === FORM_6_STEP && id && <Step6 onSuccess={handleStepSuccess} onBack={handleBack} id={id.toString()} />}
        {step === FORM_7_STEP && id && <Step7 onSuccess={handleStepSuccess} onBack={handleBack} id={id.toString()} />}
        {step === FORM_8_STEP && id && <Step8 onSuccess={handleStepSuccess} id={id.toString()} />}
      </div>
    </div>
  )
}

