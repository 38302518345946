import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

import { ButtonOverlay } from 'modules/search-building/slices/PropertyBanner'

function SelfOverlay({
  children,
  isFull = false,
  closeHandler,
  ...props
}) {

  useEffect(() => {
    if (isFull) {
      document.querySelector('html').classList.add('no-scroll')
    }
  }, [isFull])


  const selfCloseHandler = () => {
    document.querySelector('html').classList.remove('no-scroll')

    if (closeHandler) {
      closeHandler()
    }
  }

  const baseStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    bg: 'rgba(0, 0, 0, 0.75)',
    bottom: 0,
    right: 0,
    zIndex: 99999,
  };

  const styles = isFull ? baseStyles : {};

  const contentStyles = isFull
    ? { width: '95vw' }
    : { width: '100%' };

  return (
    <Box sx={styles} {...props}>
      <Box sx={{ position: 'relative' }}>
        <Box sx={contentStyles}>{children}</Box>

        { isFull && (
          <ButtonOverlay
            handler={selfCloseHandler}
            label="Cerrar"
            icon='close'
          />
        )}
      </Box>
    </Box>
  )
}

SelfOverlay.propTypes = {
  children: PropTypes.element.isRequired,
}

export default SelfOverlay
