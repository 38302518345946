import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

import BaseLink from './BaseLink'
import MenuWrapper from './MenuWrapper'
import SubMenuLink from './SubMenuLink'

function SubSubMenuLink({
  icon,
  label,
  links,
  nolink,
  url,
  variant,
  wrapper,
  ...props
}) {
  return (
    <Box {...props}>
      <BaseLink url={url} icon={icon} nolink={nolink} variant={variant}>
        {label}
      </BaseLink>

      {links.length > 0 &&
        links.map((link, index) =>
          link.wrapper.enabled ? (
            <MenuWrapper key={`l-${index}`} {...link} />
          ) : (
            <SubMenuLink key={`l-${index}`} {...link} />
          )
        )}
    </Box>
  )
}

SubSubMenuLink.defaultProps = {
  icon: {},
  links: [],
  nolink: false,
  url: {
    path: '#',
  },
  variant: '',
  wrapper: {},
}

SubSubMenuLink.propTypes = {
  icon: PropTypes.shape({}),
  label: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.object),
  nolink: PropTypes.bool,
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  variant: PropTypes.string,
  wrapper: PropTypes.shape({}),
}

export default SubSubMenuLink
