import React from 'react'
import PropTypes from 'prop-types'
import { Box, Container } from 'theme-ui'

import topLinks from './top-links.json'
import Menu from '../Menu'

function TopMenu({ variant, links, visible, display, ...props }) {
  const baseStyles = {
    bg: 'neutral',
  }

  const menuStyles = { display: ['block', 'flex'], justifyContent: `${display}` }

  return (
    <Box __css={baseStyles} variant={variant} {...props} __themeKey='topMenus'>
      <Container px='small'>
        <Menu sx={menuStyles}>
          {visible && (
            <Box sx={{ display: ['block', 'flex'] }}>
              {links.leftSide.map((link, index) => (
                <Menu.BaseLink key={`tml-${index}`} {...link} variant='top'>
                  {link.label}
                </Menu.BaseLink>
              ))}
            </Box>
          )}

          <Box sx={{ display: ['block', 'flex'], justifyContent: 'flex-end' }}>
            {links.rightSide.map((link, index) => (
              <Menu.BaseLink key={`tml-${index}`} {...link} variant='top'>
                {link.label}
              </Menu.BaseLink>
            ))}
          </Box>
        </Menu>
      </Container>
    </Box>
  )
}

TopMenu.defaultProps = {
  display: 'space-between',
  variant: 'default',
  links: topLinks,
  visible: true,
}

TopMenu.propTypes = {
  display: PropTypes.string,
  variant: PropTypes.string,
  visible: PropTypes.bool,
  links: PropTypes.shape({}),
}

export default TopMenu
