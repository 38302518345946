import React from "react";

import Layout from "../../layout/construction/Layout";
import IndexPage from "../../modules/microsites/construction";

function ConstructionPage() {
  return (
    <Layout>
      <IndexPage />
    </Layout>
  )
}

export default ConstructionPage;
