import { mapResolver } from '.'

function accordionMapper(entity) {
  return {
    type: 'accordion',
    items: entity.items.map(paragraph => mapResolver(paragraph)),
  }
}

export default accordionMapper
