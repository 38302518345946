import React from "react";
import { clsx } from "clsx";
import "./styles.css";

import DialogBox from "@coninsa-s2/dialog-box";

function Heading({
  topTitle,
  title,
  bottomTitle,
  className,
}) {
  return (
    <h1 className={clsx("s2-featured-heading", className)}>
      <span className="s2-featured-heading__top">{topTitle}</span>
      <DialogBox tint='teal' className="s2-featured-heading__title">{title}</DialogBox>
      <span className="s2-featured-heading__bottom">{bottomTitle}</span>
    </h1>
  );
}

export default Heading;
