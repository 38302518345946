import DefaultLayout from './default/Layout'
import ConstructionLayout from './construction/Layout'
import ArchitectureLayout from './architecture/Layout'

export const layoutResolver = type => {
  const layoutMap = {
    default: DefaultLayout,
    architecture: ArchitectureLayout,
    construction: ConstructionLayout,
  }

  return layoutMap[type] || DefaultLayout
}

export default {}
