import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../../layout/default/Layout";
import ReferProperties from "@coninsa-site/refer-properties"

function ReferrerProperties() {
  return (
    <Layout>
      <Helmet>
        <title>Referidos Arrendamientos</title>
        <link rel="canonical" href="https://www.coninsa.co/referidos/arrendamientos" />
      </Helmet>

      <ReferProperties />
    </Layout>
  );
}

export default ReferrerProperties;