import React from "react";
import { SplideSlide } from "@splidejs/react-splide";
import Splider from "component-v2/Splider";

import Layout from "../../layout/construction/Layout";
import ConstructionCards from "modules/microsites/construction/components/ConstructionCards";
import IconItem from "modules/microsites/architecture/components/IconItem";
import StaffCard from "modules/microsites/architecture/components/StaffCard";
import ContactSection from "modules/microsites/architecture/components/ContactSection";

import heroBannerImageUrl from "./../../modules/microsites/assets/images/architecture-mobile-image.jpg";
import heroBannerUrl from "../../modules/microsites/assets/images/construction-banner.jpg";
import bgWhite from "../../modules/microsites/assets/images/bg-white.jpg";
import screenImage from "../../modules/microsites/assets/images/screen.png";
import transparentBg from "../../modules/microsites/assets/images/transparent-bg.png";

import standardIcon from "../../modules/microsites/assets/icons/standard.svg";
import designIcon from "../../modules/microsites/assets/icons/design-integration.svg";
import efficientIcon from "../../modules/microsites/assets/icons/efficient-coordination.svg";
import optimizationIcon from "../../modules/microsites/assets/icons/optimization.svg";
import craneIcon from "../../modules/microsites/assets/icons/crane.svg";

import constructorsInfo from "../../modules/microsites/data/constructor-staff";

function AboutUsPage() {
  const slideSettings = {
    autoplay: true,
    perMove: 1,
    perPage: 2,
    rewind: true,
    padding: { left: 0, right: "12rem" },
    breakpoints: {
      1024: {
        perPage: 2,
        padding: { left: 0, right: "8rem" },
      },
      768: {
        perPage: 2,
        padding: { left: 0, right: "4rem" },
      },
      640: {
        perPage: 1,
        padding: { left: 0, right: "4rem" },
      },
    },
  };

  return (
    <Layout>
      <div>
        <div
          className="hero hero--right-content bg-[#223983]"
          style={{ backgroundImage: `url(${heroBannerUrl})` }}
        >
          <div className="hero__container container">
            <img className="hero__image" src={heroBannerImageUrl} />

            <div className="hero__content md:!w-1/2">
              <h1 className="text-white font-heading leading-none text-4xl">
                <span className="text-4xl leading-5 md:text-5xl">Soluciones</span>
                <span className="block font-handwritting text-coninsa-green-500 md:text-coninsa-green-300 text-5xl md:text-7xl text-center mb-3">
                  integrales
                </span>
                <span className="text-[20px] md:text-4xl">En estructuración, diseño</span>
                <span className="text-[20px] md:text-4xl block text-end">y construcción</span>
              </h1>
            </div>
          </div>
        </div>

        <div
          className="bg-coninsa-blue-900 py-10 md:py-24"
          style={{ backgroundImage: `url(${bgWhite})` }}
        >
          {/* <div className='border-b-[16px] border-solid border-coninsa-green-400 rounded-r-md w-1/2 transform -translate-y-[104px]' /> */}

          <div className="container px-5 text-base md:text-2xl md:px-0 text-center">
            <span className="font-semibold bg-coninsa-blue-900 inline-block md:leading-9 p-1 mb-2 md:px-4 md:py-2 rounded-md text-white">
              ¡Construimos el futuro de todos los sectores productivos en
              Colombia!
            </span>
            <p className="max-w-2xl my-0 mx-auto">
              Desde infraestructuras sostenibles hasta soluciones tecnológicas
              avanzadas e innovación que impulsa el progreso en obras del sector
              público y privado.
            </p>
          </div>

          {/* <div className='border-b-[16px] border-solid border-coninsa-green-400 rounded-l-md w-1/2 transform translate-y-[104px] translate-x-[850px]' /> */}
        </div>

        <div
          className="py-10 md:py-16 relative
          before:absolute before:bottom-0 before:w-full before:z-0"
        >
          <div className="container px-5 z-10 md:px-0">
            <div className="mb-12">
              <h2 className="font-display leading-8 mb-4 text-3xl md:leading-[44px] md:mb-6 md:text-[44px] text-coninsa-green-500 uppercase">
                Nuestra oferta en
                <span className="block font-bold text-coninsa-blue-900">
                  construcción
                </span>
              </h2>

              <p className="text-sm md:text-base">
                Conoce la oferta de servicios que tenemos disponible para
                realizar obras en el sector público y privado.
              </p>
            </div>
            <ConstructionCards />
          </div>
        </div>

        <div className="relative bg-coninsa-green-300">
          <div className="container pt-12 px-5 md:px-0 z-10 relative">
            <div className="text-center mb-8">
              <h2 className="font-heading text-coninsa-blue-900 mb-8">
                <span className="text-white text-4xl md:text-6xl">
                  Gestionamos
                </span>
                <br />
                <span className="text-3xl">proyectos y obras </span>
                <span className="font-handwritting text-5xl md:text-6xl">
                  exitosas
                </span>
              </h2>

              <p className="font-normal text-white">
                Agregamos valor a{" "}
                <span className="font-bold bg-coninsa-green-500 px-2">
                  nuestros
                </span>
                clientes y aliados a través de la{" "}
                <span className="font-bold bg-coninsa-green-500 px-2">
                  innovación
                </span>{" "}
                y la{" "}
                <span className="font-bold bg-coninsa-green-500 px-2">
                  tecnología
                </span>
              </p>
            </div>

            <div className="flex flex-wrap justify-center gap-4 text-center">
              <IconItem icon={standardIcon} label="Estandarización" />
              <IconItem icon={designIcon} label="Integración de diseños" />
              <IconItem icon={efficientIcon} label="Coordinación eficiente" />
              <IconItem icon={optimizationIcon} label="Optimización" />
              <IconItem icon={craneIcon} label="Constructibilidad" />
            </div>
          </div>
        </div>

        <div
          className="pt-10 md:pt-16 relative before:content before:block before:h-24 md:before:h-48 before:bg-coninsa-green-300
          before:absolute before:top-0 before:w-full before:z-0"
        >
          <div className="container inline-block md:flex gap-4 px-5 md:px-0">
            <div>
              <h2 className="font-heading text-3xl md:text-4xl text-coninsa-blue-900 mb-0 md:mb-3 relative z-10 text-center md:text-left">
                <span className="leading-5 md:leading-[110px]">
                  Metodología
                </span>
                <span className="block text-5xl md:text-9xl text-coninsa-green-500">
                  BIM
                </span>
              </h2>
              <p className="font-bold text-coninsa-green-500 text-lg mb-6 text-center md:text-left">
                Entorno Común de Datos (CDE)
              </p>

              <ul className="px-5 list-disc list-outside marker:text-coninsa-green-300 text-sm md:text-base">
                <li>
                  <span className="font-bold">Trazabilidad</span> de cambios y
                  versión.
                </li>
                <li>
                  <span className="font-bold">
                    Manejo de información digital
                  </span>
                  del proyecto que incluye a las partes interesadas.
                </li>
                <li>
                  <span className="font-bold">Revisiones</span> gerenciales de
                  plantas y modelos.
                </li>
                <li>
                  <span className="font-bold">Centralización</span> y
                  almacenamiento de la información en la nube.
                </li>
                <li>
                  <span className="font-bold">Visualización</span> de modelos
                  3D.
                </li>
              </ul>
            </div>

            <div className="z-10">
              <img src={screenImage} />
            </div>
          </div>
        </div>

        <div
          className="bg-coninsa-blue-900 py-10 md:py-16"
          style={{ backgroundImage: `url(${transparentBg})` }}
        >
          <div className="container inline-block md:grid grid-cols-[40%_60%] gap-4 px-5 md:px-0">
            <div className="mb-8 md:mb-0">
              <h2 className="font-display leading-8 mb-4 text-3xl md:leading-[44px] md:mb-6 md:text-[44px] text-coninsa-blue-900 uppercase">
                Conoce a<span className="block font-bold">nuestro equipo</span>
              </h2>
              <p className="text-sm md:text-base">
                Para lograr el cumplimiento de los objetivos de nuestros
                Clientes,{" "}
                <span className="font-bold">
                  {" "}
                  contamos con un excelente equipo de profesionales y
                  especialistas{" "}
                </span>{" "}
                que le imprime todo su humanismo, integralidad, liderazgo,
                actitud de servicio y pasión a la realización de los proyectos
                compuesto por Arquitectos, Diseñadores Estructurales, Gestores
                de Sistemas, Gerentes de Proyecto, Administradores, Financieros,
                y por supuesto Ingenieros Civiles, hidráulicos, Ambientales,
                Mecánicos, Eléctricos y Estructurales.
              </p>
            </div>

            <Splider slot="content" options={slideSettings}>
              {constructorsInfo.map((item) => (
                <SplideSlide>
                  <div className="flex gap-4 mb-8">
                    <StaffCard
                      key={item.id}
                      photo={item?.photo}
                      firstName={item?.firstName}
                      lastName={item?.lastName}
                      position={item?.position}
                      linkedin={item?.linkedin}
                    />
                  </div>
                </SplideSlide>
              ))}
            </Splider>
          </div>
        </div>

        <ContactSection />
      </div>
    </Layout>
  );
}

export default AboutUsPage;
