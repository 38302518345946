import React from "react";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Breadcrumb, { BreadcrumbItem } from "@coninsa-s2/breadcrumb";
import Heading from "@coninsa-s2/heading";

export default function GoalkeeperReferralPlan() {
  const breadcrumbItems = [
    {
      label: "Inicio",
      url: "/terminos-y-condiciones",
    },
    {
      label: "Términos y condiciones generales",
      url: "/terminos-y-condiciones/generales",
    },
    {
      label: "Plan referidos porteros 2019 - 2020",
    },
  ];

  return (
    <Section>
      <Container>
        <div className="mb-8">
          <Breadcrumb>
            {breadcrumbItems.map((item) => (
              <BreadcrumbItem>
                {item.url && <a href={item.url}>{item.label}</a>}
                {!item.url && item.label}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        </div>
        <div className="mb-7">
          <span className="text-lg text-s2-dark-blue lg:text-[22px]">
            Términos y condiciones
          </span>
          <Heading size="2xl">Plan referidos porteros 2019 - 2020</Heading>
        </div>

        <div className="prose">
          <p>
            Las siguientes son las condiciones del plan referidos porteros, el
            cual rige en las ciudades de Medellín, Barranquilla, Cartagena y
            Bogotá, solo para mayores de edad.
          </p>
          <ol>
            <li>
              <h4>Vigencia:</h4>
              <p>
                El plan referidos porteros Coninsa Ramón H. Inmobiliaria se
                realizará 01 de enero de 2019 al 31 de diciembre de 2020 (Aplica
                únicamente para inmuebles que se arrienden antes del 31 de
                diciembre de 2020), en las ciudades: Bogotá, Medellín,
                Barranquilla y Cartagena.
              </p>
            </li>
            <li>
              <h4>El organizador:</h4>
              <p>
                La presente actividad está organizada por Coninsa Ramón H S.A.
                con Nit 890911431-1 con domicilio principal en la Carrera 45 #55
                - 45 en Medellín teléfonos en Medellín: (57+4) 448 88 28, Región
                Bogotá: (57+1) 6014800 y Región Caribe: (57+5) 319 96 10.
              </p>
            </li>
            <li>
              <h4>Objeto y mecánica de la actividad:</h4>
              <p>
                El plan referidos porteros Coninsa Ramón H. Inmobiliaria es una
                campaña de referidos que premia a los porteros o vigilantes de
                conjuntos residenciales o comerciales a través de los referidos
                efectivos (negocios que se cierren con contrato generado de
                mínimo seis meses y para cánones superiores a $800.000). Por
                cada negocio efectivo (con firma de contrato que se genere para
                Coninsa Ramón H.), se ganará 1 bono de Sodexo por un valor único
                de $100.000. Adicional por acumular valores de negocios cerrados
                se puede llegar a acceder a los siguientes premios:
              </p>
              <ol>
                <li>
                  Los ganadores de los premios autorizan a Coninsa Ramón H. S.A
                  a difundir y/o publicar cualquier dato que haya sido
                  suministrado en su inscripción, en caso que decida
                  identificarlo como ganador de la promoción o para cualquier
                  otro fin comercial que la empresa organizadora considere
                  necesario, sin obligación de compensación alguna.
                </li>
              </ol>
            </li>

            <li>
              <h4>Disposiciones legales</h4>
              <ol>
                <li>
                  Una vez los porteros ganadores hayan recibido el bono y/o
                  cualquiera de los premios mencionados, no se admitirán cambios
                  por dinero, valores o cualquier otro bien. El derecho al canje
                  no es transferible, negociable, ni puede ser comercializado o
                  canjeado por dinero en efectivo, ni por ningún otro premio.
                </li>
                <li>
                  El solo hecho de participar en esta actividad implica la total
                  aceptación de los presentes términos y condiciones.
                </li>
                <li>
                  Los participantes se comprometen a eximir de responsabilidad a
                  Coninsa Ramón H. S.A., sus accionistas, empleados o
                  colaboradores y frente a cualquier acción, daño, costes, y
                  otras responsabilidades en las que pudiera incurrir Coninsa
                  Ramón H. S.A. como resultado de cualquier tipo de
                  incumplimiento por parte suya de alguno de alguno de los
                  términos y condiciones de un uso o manipulación indebido de
                  los premios que incluye la presente actividad.
                </li>
                <li>
                  Al aceptar estos términos y condiciones los participantes
                  reconocen que de manera libre, voluntaria y espontánea han
                  decidido participar en esta actividad, por lo que dentro de
                  los límites permitidos por la ley Colombiana, renuncian a
                  cualquier reclamación o acción de cualquier naturaleza en
                  contra de Coninsa Ramón H. S.A., sus accionistas, empleados o
                  colaboradores, que tengan por objeto reclamar indemnización
                  por daños o perjuicios que les haya causado su participación
                  en la actividad, o de cualquier manera relacionados con esta,
                  o por las actividades que realizare como consecuencia de dicha
                  participación. Si los premios no son aceptados o sus
                  condiciones, se considerará que ha renunciado al mismo y no
                  tendrá derecho a reclamo o indemnización alguna, ni siquiera
                  parcialmente.
                </li>
                <li>
                  La responsabilidad inherente a la actividad por parte de
                  Coninsa Ramón H. S.A. culmina con la entrega de los premios
                  especificados a la persona que haya cumplido
                  satisfactoriamente con las condiciones exigidas.
                </li>
              </ol>
            </li>

            <li>
              <h4>Condiciones y restricciones</h4>
              <ol>
                <li>
                  Promoción válida del 01 de enero de 2019 al 31 de diciembre de
                  2020 (Aplica únicamente para inmuebles que se arrienden antes
                  del 31 de diciembre de 2020)
                </li>
                <li>
                  No aplica para arrendatarios y/o propietarios con contrato
                  vigente en la compañía al 31 de diciembre de 2018.
                </li>
                <li>Promoción no válida para empleados de la compañía.</li>
                <li>Aplican condiciones y restricciones.</li>
                <li>
                  Condiciones para la entrega del bono:
                  <ul>
                    <li>
                      El bono Sodexo por valor de $100.000 se entregará al
                      portero que refiera un negocio con un contrato generado de
                      mínimo seis meses y para cánones superiores a $800.000
                    </li>
                    <li>Válido un bono por negocio.</li>
                    <li>
                      Para reclamar dicho bono es indispensable presentar el
                      desprendible del volante de la promoción vigente.
                    </li>
                    <li>El bono no es canjeable en dinero en efectivo.</li>
                    <li>
                      Los negocios deben ser referidos durante la vigencia de la
                      presente promoción ((01 de enero de 2019 al 31 de
                      diciembre de 2020)), deben ser con un contrato generado de
                      mínimo seis meses y para cánones superiores a $800.000,
                      (el valor se puede completar ya sea acumulándolo o
                      realizando directamente el total en un solo negocio)
                    </li>
                  </ul>
                </li>
                <li>
                  Condiciones generales de los premios:
                  <ul>
                    <li>
                      Las especificaciones de los premios están claramente
                      especificados en la tabla del punto número 3, en caso de
                      no estar disponible alguna de las referencias mencionadas,
                      Coninsa Ramón H. acudirá a la referencia más cercana en
                      especificaciones.
                    </li>
                    <li>
                      La moto se entregará sin incluir el costo de los trámites
                      de tránsito (matrícula, SOAT y placa), ni mantenimiento
                      alguno.
                    </li>
                    <li>
                      Los premios se entregan de acuerdo a la descripción hecha
                      (no vienen con dispositivos adicionales).
                    </li>
                    <li>
                      La garantía de los premios mencionados la dan directamente
                      los comercializadores o fabricantes (según sea el caso),
                      Coninsa Ramón H. no será responsable por dicha garantía si
                      se llegare a presentar.
                    </li>
                    <li>
                      Ninguno de los premios es canjeable en dinero en efectivo.
                    </li>
                    <li>
                      El viaje a San Andrés es para dos personas, 3 noches y 4
                      días a San Andrés en un Hotel Decameron o similar, se
                      Incluye: tiquete aéreo, hotel, alimentación: desayuno,
                      almuerzo, cena y snacks, traslado del aeropuerto de San
                      Andrés al hotel y del hotel de San Andrés al aeropuerto.
                      El ganador deberá asumir los impuestos de entrada a la
                      isla, el transporte de la ciudad de origen al aeropuerto
                      más cercano y el transporte del aeropuerto más cercano al
                      lugar de residencia, los valores de transporte en la isla,
                      toures, propinas, llamadas telefónicas, servicio de
                      lavandería, cajilla de seguridad, asistencia médica,
                      medicinas y los cargos que no se incluyan en el plan.
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </Container>
    </Section>
  );
}
