import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

import {
  BiAward,
  BiBath,
  BiBed,
  BiHome,
  BiMap,
  BiSearch,
  BiScreenshot,
  BiWallet,
  BiVideo,
  BiFullscreen,
} from 'react-icons/bi'

import {
  FiArrowRight,
  FiCamera,
  FiPercent,
  FiPlusCircle,
  FiMinusCircle,
  FiExternalLink,
  FiPhone,
  FiSettings,
  FiShare,
  FiShare2,
  FiGlobe,
  FiDownload,
} from 'react-icons/fi'

import { RiArrowLeftLine, RiArrowRightLine } from 'react-icons/ri'

import { GrLocation, GrTag } from 'react-icons/gr'

import {
  FaArrowLeft,
  FaArrowRight,
  FaAngleLeft,
  FaAngleRight,
  FaBars,
  FaCalendarAlt,
  FaCalendarCheck,
  FaCheck,
  FaChevronDown,
  FaChevronUp,
  FaChevronRight,
  FaChevronLeft,
  FaDownload,
  FaEnvelope,
  FaFacebookF,
  FaHome,
  FaInstagram,
  FaMapMarkerAlt,
  FaLinkedinIn,
  FaTimes,
  FaPlus,
  FaPhoneAlt,
  FaPinterestP,
  FaMinus,
  FaRegCheckCircle,
  FaRegCommentDots,
  FaRegFile,
  FaRegFileAlt,
  FaRegFilePdf,
  FaRegHeart,
  FaRegQuestionCircle,
  FaWaze,
  FaWhatsapp,
  FaYoutube,
} from 'react-icons/fa'

import { TiPrinter, TiDownload } from 'react-icons/ti'

import PhoneIcon from 'assets/icons/co-phone.inline.svg'
import MobileIcon from 'assets/icons/co-mobile.inline.svg'
import CoFacebook from 'assets/icons/co-facebook.inline.svg'

import CoInstagram from 'assets/icons/co-instagram.inline.svg'
import CoLinkedin from 'assets/icons/co-linkedin.inline.svg'
import CoPintrest from 'assets/icons/co-pinterest.inline.svg'
import CoTwitter from 'assets/icons/co-twitter.inline.svg'
import CoYoutube from 'assets/icons/co-youtube.inline.svg'

import CoBuilding from 'assets/icons/co-building.inline.svg'
import CoComment from 'assets/icons/co-comment.inline.svg'
import CoContact from 'assets/icons/co-contact.inline.svg'
import CoCorporate from 'assets/icons/co-corporate.inline.svg'
import CoHome from 'assets/icons/co-home.inline.svg'
import CoDocuments from 'assets/icons/co-documents.inline.svg'
import CoKey from 'assets/icons/co-key.inline.svg'
import CoShopping from 'assets/icons/co-shopping.inline.svg'
import CoStar from 'assets/icons/co-star.inline.svg'
import CoTrade from 'assets/icons/co-trade.inline.svg'
import CoUser from 'assets/icons/co-user.inline.svg'
import CoUserWhite from 'assets/icons/co-user-white.inline.svg'
import CoWallet from 'assets/icons/co-wallet.inline.svg'
import CoWalletWhite from 'assets/icons/co-wallet-white.inline.svg'

import CoBuildingGreen from 'assets/icons/co-building-green.inline.svg'
import CoArea from 'assets/icons/co-area.inline.svg'
import CoStayHome from 'assets/icons/co-stay-home.inline.svg'
import CoUserGreen from 'assets/icons/co-user-green.inline.svg'
import CoLeaf from 'assets/icons/co-leaf.inline.svg'
import CoEmpleo from 'assets/icons/icon-empleos.inline.svg'
import CoTeam from 'assets/icons/co-team.inline.svg'
import CoAlliances from 'assets/icons/co-alliances.inline.svg'
import CoFriends from 'assets/icons/friends.inline.svg'

import CoBrandArchitecture from 'assets/icons/co-brand-architecture.inline.svg'
import CoBrandConinsa from 'assets/icons/co-brand-coninsa.inline.svg'
import CoBrandConstruction from 'assets/icons/co-brand-construction.inline.svg'

import CoArrowLeft from 'assets/icons/co-arrow-left.inline.svg'
import CoArrowRight from 'assets/icons/co-arrow-right.inline.svg'
import CoArrowBlueLeft from 'assets/icons/co-arrow-blue-left.inline.svg'
import CoArrowBlueRight from 'assets/icons/co-arrow-blue-right.inline.svg'

import WhatsappIcon from 'assets/icons/whatsapp.inline.svg'
import BarsIcon from 'assets/icons/bars.inline.svg'
import ChatbotIcon from 'assets/icons/chatbot.inline.svg'
import CheckCircleIcon from 'assets/icons/check-circle.inline.svg'
import colHeart from 'assets/icons/col-heart.inline.svg'
import LikeIcon from 'assets/icons/like.inline.svg'
import MedalIcon from 'assets/icons/medal.inline.svg'
import PeopleIcon from 'assets/icons/people.inline.svg'
import ConinsaIcon from 'assets/icons/coninsa.inline.svg'
import SymantecIcon from 'assets/icons/symantec.inline.svg'
// import ServchatIcon from 'assets/icons/serv-chat.inline.svg'

const iconMaps = {
  'bi-area': BiScreenshot,
  'bi-award': BiAward,
  'bi-bath': BiBath,
  'bi-bed': BiBed,
  'bi-home': BiHome,
  'bi-map': BiMap,
  'bi-search': BiSearch,
  'bi-wallet': BiWallet,
  'bi-video': BiVideo,
  'bi-full-screen': BiFullscreen,
  'ri-arrow-left-line': RiArrowLeftLine,
  'ri-arrow-right-line': RiArrowRightLine,

  // Font awesome icons
  'fa-plus': FaPlus,
  'f-arrow-left': FaArrowLeft,
  'f-arrow-right': FaArrowRight,
  'fa-minus': FaMinus,
  'fa-bars': FaBars,
  'fa-calendar': FaCalendarAlt,
  'fa-calendar-check': FaCalendarCheck,
  'fa-home': FaHome,
  'fa-location': FaMapMarkerAlt,
  'fa-times': FaTimes,
  'fa-download': FaDownload,
  'fa-chevron-left': FaChevronLeft,
  'fa-chevron-right': FaChevronRight,
  'fa-chevron-down': FaChevronDown,
  'fa-chevron-up': FaChevronUp,
  'fa-envelope': FaEnvelope,
  'fa-facebookf': FaFacebookF,
  'fa-instagram': FaInstagram,
  'fa-linkedinin': FaLinkedinIn,
  'fa-reg-file': FaRegFile,
  'fa-reg-file-alt': FaRegFileAlt,
  'fa-reg-file-pdf': FaRegFilePdf,
  'fa-phone': FaPhoneAlt,
  'fa-pinterestp': FaPinterestP,
  'fa-reg-check-circle': FaRegCheckCircle,
  'fa-reg-comment-dots': FaRegCommentDots,
  'fa-reg-question-circle': FaRegQuestionCircle,
  'fa-reg-heart': FaRegHeart,
  'fa-waze': FaWaze,
  'fa-whatsapp': FaWhatsapp,
  'fa-youtube': FaYoutube,

  'fi-camera': FiCamera,
  'fi-percent': FiPercent,
  'fi-phone': FiPhone,
  'fi-arrow-right': FiArrowRight,
  'fi-settings': FiSettings,
  'fi-share1': FiShare,
  'fi-share': FiShare2,
  'fi-globe': FiGlobe,
  'fi-download': FiDownload,

  'gr-tag': GrTag,
  'gr-location': GrLocation,

  'ti-printer': TiPrinter,
  'ti-download': TiDownload,

  'chevron-down': FiPlusCircle,
  'chevron-up': FiMinusCircle,
  'arrow-down': FaChevronDown,
  close: FaTimes,
  success: FaCheck,

  phone: PhoneIcon,
  whatsapp: WhatsappIcon,
  cellphone: MobileIcon,
  chatbot: ChatbotIcon,
  coninsa: ConinsaIcon,
  symantec: SymantecIcon,

  'col-check-circle': CheckCircleIcon,
  'col-heart': colHeart,
  'col-like': LikeIcon,
  'col-medal': MedalIcon,
  'col-people': PeopleIcon,

  // Coninsa Icons.
  'co-facebook': CoFacebook,
  'co-instagram': CoInstagram,
  'co-linkedin': CoLinkedin,
  'co-twitter': CoTwitter,
  'co-youtube': CoYoutube,
  'co-pinterest': CoPintrest,

  // Coninsa menu icons.
  'co-building': CoBuilding,
  'co-comment': CoComment,
  'co-contact': CoContact,
  'co-corporate': CoCorporate,
  'co-documents': CoDocuments,
  'co-home': CoHome,
  'co-key': CoKey,
  'co-shopping': CoShopping,
  'co-star': CoStar,
  'co-trade': CoTrade,
  'co-user': CoUser,
  'co-user-white': CoUserWhite,
  'co-wallet': CoWallet,
  'co-wallet-white': CoWalletWhite,

  'co-building-green': CoBuildingGreen,
  'co-area': CoArea,
  'co-stay-home': CoStayHome,
  'co-user-green': CoUserGreen,
  'co-leaf': CoLeaf,
  'co-empleo': CoEmpleo,

  'co-team': CoTeam,
  'co-alliances': CoAlliances,
  'co-bars': BarsIcon,
  'co-friends': CoFriends,

  // Brand icons
  'co-brand-architecture': CoBrandArchitecture,
  'co-brand-coninsa': CoBrandConinsa,
  'co-brand-construction': CoBrandConstruction,

  // Slide icons
  'co-arrow-left': CoArrowLeft,
  'co-arrow-right': CoArrowRight,
  'co-arrow-blue-left': CoArrowBlueLeft,
  'co-arrow-blue-right': CoArrowBlueRight,
  'fa-arrow-left': FaAngleLeft,
  'fa-arrow-right': FaAngleRight,

  'fi-external-link': FiExternalLink,
}

function Icon({ name, ...props }) {
  return <Box __css={{ display: 'inline-block' }} as={iconMaps[name]} {...props} />
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
}

export default Icon
