import React from 'react'
import PropTypes from 'prop-types'
import { Box, Text } from 'theme-ui'

import { Icon } from 'components'

function HorizontalTab({ icon, label, children }) {
  const tabStyles = {
    fontSize: 3,
    pb: 1,
    pt: 3
  }

  return (
    <Box sx={tabStyles}>
      <Box>
        <Icon name={icon} sx={{ mr: 2 }} />
        <Text sx={{ fontSize: 1 }}>{label}</Text>
      </Box>
      <Box
        sx={{
          color: 'dark-0',
          fontFamily: 'heading',
          fontSize: [3, 2],
          fontWeight: 'heading'
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

HorizontalTab.defaultProps = {
  icon: undefined
}

HorizontalTab.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
  label: PropTypes.string.isRequired
}

export default HorizontalTab
