import { gql } from '@apollo/client'

export const CREATE_NODE_NATURAL_PERSON_APPLICATION = gql`
  mutation CreateNodeNaturalPersonApplication(
    $title: String!
    $fieldAccountNumber: String
    $fieldApplicationStatus: String!
    $fieldAssets: String
    $fieldBrand: String
    $fieldCellPhoneNumber: String!
    $fieldContractType: String
    $fieldCrmConsultant: String
    $fieldCommercialReference: NodeFieldCommercialReferenceFieldInput
    $fieldEmail: String!
    $fieldEnablePdfApplication: String
    $fieldExpenses: String
    $fieldFirstName: String!
    $fieldFiscalPhone: String
    $fieldHandlesForeignCurrency: String
    $fieldHasGarment: String
    $fieldHasInternationalAccount: String
    $fieldHasPropertyMortgage: String
    $fieldIdNumber: String!
    $fieldIdType: String!
    $fieldIncome: String
    $fieldIndependentActivity: String
    $fieldInternationalOperations: String
    $fieldLastName: String!
    $fieldLiabilities: String
    $fieldLicensePlate: String
    $fieldMaritalStatus: String
    $fieldModel: String
    $fieldOccupation: String
    $fieldOfficeAddress: String
    $fieldOfficeCity: String
    $fieldPersonalAddress: String
    $fieldPersonalName: String
    $fieldPersonalPhone: String
    $fieldPersonalRelationship: String
    $fieldPosition: String
    $fieldProfession: String
    $fieldPropertyAddress: String
    $fieldPropertyCode: String
    $fieldPropertyRegistration: String
    $fieldFamilyReference: NodeFieldFamilyReferenceFieldInput
    $fieldBankReference: NodeFieldBankReferenceFieldInput
    $fieldRefFamiliarMunicipality: NodeFieldRefFamiliarMunicipalityFieldInput
    $fieldRefPersonalMunicipality: NodeFieldRefPersonalMunicipalityFieldInput
    $fieldSalary: String
    $fieldWorkCompany: String
    $fieldAdmissionDate: NodeFieldAdmissionDateFieldInput
    $fieldCityBirth: NodeFieldCityBirthFieldInput
    $fieldAdditionalDocuments: [NodeFieldAdditionalDocumentsFieldInput]
    $fieldBankStatements: [NodeFieldBankStatementsFieldInput]
    $fieldCreditAnalyst: NodeFieldCreditAnalystFieldInput
    $fieldDateBirth: NodeFieldDateBirthFieldInput
    $fieldExpeditionDate: NodeFieldExpeditionDateFieldInput
    $fieldExpeditionPlace: NodeFieldExpeditionPlaceFieldInput
    $fieldFinancialState: NodeFieldFinancialStateFieldInput
    $fieldIdentificationDocument: NodeFieldIdentificationDocumentFieldInput
    $fieldLaborCertification: NodeFieldLaborCertificationFieldInput
    $fieldRut: NodeFieldRutFieldInput
    $fieldProofPayroll: [NodeFieldProofPayrollFieldInput]
    $fieldCotenants: [NodeFieldCotenantsFieldInput]
  ) {
    node: createNodeNaturalPersonApplication(
      input: {
        title: $title
        fieldAccountNumber: $fieldAccountNumber
        fieldApplicationStatus: $fieldApplicationStatus
        fieldAssets: $fieldAssets
        fieldBrand: $fieldBrand
        fieldCellPhoneNumber: $fieldCellPhoneNumber
        fieldCrmConsultant: $fieldCrmConsultant
        fieldCommercialReference: $fieldCommercialReference
        fieldEmail: $fieldEmail
        fieldEnablePdfApplication: $fieldEnablePdfApplication
        fieldExpenses: $fieldExpenses
        fieldFirstName: $fieldFirstName
        fieldFiscalPhone: $fieldFiscalPhone
        fieldHasPropertyMortgage: $fieldHasPropertyMortgage
        fieldHandlesForeignCurrency: $fieldHandlesForeignCurrency
        fieldHasInternationalAccount: $fieldHasInternationalAccount
        fieldIdNumber: $fieldIdNumber
        fieldIdType: $fieldIdType
        fieldIncome: $fieldIncome
        fieldIndependentActivity: $fieldIndependentActivity
        fieldInternationalOperations: $fieldInternationalOperations
        fieldLastName: $fieldLastName
        fieldLiabilities: $fieldLiabilities
        fieldLicensePlate: $fieldLicensePlate
        fieldMaritalStatus: $fieldMaritalStatus
        fieldModel: $fieldModel
        fieldOccupation: $fieldOccupation
        fieldOfficeAddress: $fieldOfficeAddress
        fieldOfficeCity: $fieldOfficeCity
        fieldPersonalAddress: $fieldPersonalAddress
        fieldPersonalName: $fieldPersonalName
        fieldPersonalPhone: $fieldPersonalPhone
        fieldPersonalRelationship: $fieldPersonalRelationship
        fieldPosition: $fieldPosition
        fieldProfession: $fieldProfession
        fieldPropertyAddress: $fieldPropertyAddress
        fieldPropertyCode: $fieldPropertyCode
        fieldPropertyRegistration: $fieldPropertyRegistration
        fieldFamilyReference: $fieldFamilyReference
        fieldBankReference: $fieldBankReference
        fieldRefFamiliarMunicipality: $fieldRefFamiliarMunicipality
        fieldRefPersonalMunicipality: $fieldRefPersonalMunicipality
        fieldSalary: $fieldSalary
        fieldWorkCompany: $fieldWorkCompany
        fieldHasGarment: $fieldHasGarment
        fieldContractType: $fieldContractType
        fieldAdmissionDate: $fieldAdmissionDate
        fieldAdditionalDocuments: $fieldAdditionalDocuments
        fieldBankStatements: $fieldBankStatements
        fieldCityBirth: $fieldCityBirth
        fieldCreditAnalyst: $fieldCreditAnalyst
        fieldDateBirth: $fieldDateBirth
        fieldExpeditionDate: $fieldExpeditionDate
        fieldExpeditionPlace: $fieldExpeditionPlace
        fieldFinancialState: $fieldFinancialState
        fieldIdentificationDocument: $fieldIdentificationDocument
        fieldRut: $fieldRut
        fieldLaborCertification: $fieldLaborCertification
        fieldProofPayroll: $fieldProofPayroll
        fieldCotenants: $fieldCotenants
      }
    ) {
      violations {
        message
        path
        code
      }
      errors
      entity {
        bundle: entityBundle
        label: entityLabel
        id: entityId
      }
    }
  }
`

export const UPDATE_NODE_NATURAL_PERSON_APPLICATION = gql`
  mutation UpdateNodeNaturalPersonApplication(
    $id: String!
    $title: String!
    $fieldAccountNumber: String
    $fieldApplicationStatus: String!
    $fieldAssets: String
    $fieldBrand: String
    $fieldCellPhoneNumber: String!
    $fieldContractType: String
    $fieldCrmConsultant: String
    $fieldCommercialReference: NodeFieldCommercialReferenceFieldInput
    $fieldEmail: String!
    $fieldEnablePdfApplication: String
    $fieldExpenses: String
    $fieldFirstName: String!
    $fieldFiscalPhone: String
    $fieldHandlesForeignCurrency: String
    $fieldHasGarment: String
    $fieldHasInternationalAccount: String
    $fieldHasPropertyMortgage: String
    $fieldIdNumber: String!
    $fieldIdType: String!
    $fieldIncome: String
    $fieldIndependentActivity: String
    $fieldInternationalOperations: String
    $fieldLastName: String!
    $fieldLiabilities: String
    $fieldLicensePlate: String
    $fieldMaritalStatus: String
    $fieldModel: String
    $fieldOccupation: String
    $fieldOfficeAddress: String
    $fieldOfficeCity: String
    $fieldPersonalAddress: String
    $fieldPersonalName: String
    $fieldPersonalPhone: String
    $fieldPersonalRelationship: String
    $fieldPosition: String
    $fieldProfession: String
    $fieldPropertyAddress: String
    $fieldPropertyCode: String
    $fieldPropertyRegistration: String
    $fieldRefFamiliarMunicipality: NodeFieldRefFamiliarMunicipalityFieldInput
    $fieldRefPersonalMunicipality: NodeFieldRefPersonalMunicipalityFieldInput
    $fieldFamilyReference: NodeFieldFamilyReferenceFieldInput
    $fieldBankReference: NodeFieldBankReferenceFieldInput
    $fieldSalary: String
    $fieldWorkCompany: String
    $fieldAdmissionDate: NodeFieldAdmissionDateFieldInput
    $fieldCityBirth: NodeFieldCityBirthFieldInput
    $fieldAdditionalDocuments: [NodeFieldAdditionalDocumentsFieldInput]
    $fieldBankStatements: [NodeFieldBankStatementsFieldInput]
    $fieldCreditAnalyst: NodeFieldCreditAnalystFieldInput
    $fieldDateBirth: NodeFieldDateBirthFieldInput
    $fieldExpeditionDate: NodeFieldExpeditionDateFieldInput
    $fieldExpeditionPlace: NodeFieldExpeditionPlaceFieldInput
    $fieldFinancialState: NodeFieldFinancialStateFieldInput
    $fieldIdentificationDocument: NodeFieldIdentificationDocumentFieldInput
    $fieldLaborCertification: NodeFieldLaborCertificationFieldInput
    $fieldRut: NodeFieldRutFieldInput
    $fieldProofPayroll: [NodeFieldProofPayrollFieldInput]
    $fieldCotenants: [NodeFieldCotenantsFieldInput]
  ) {
    node: updateNodeNaturalPersonApplication(
      id: $id
      input: {
        title: $title
        fieldAccountNumber: $fieldAccountNumber
        fieldApplicationStatus: $fieldApplicationStatus
        fieldAssets: $fieldAssets
        fieldBrand: $fieldBrand
        fieldCellPhoneNumber: $fieldCellPhoneNumber
        fieldCrmConsultant: $fieldCrmConsultant
        fieldCommercialReference: $fieldCommercialReference
        fieldEmail: $fieldEmail
        fieldEnablePdfApplication: $fieldEnablePdfApplication
        fieldExpenses: $fieldExpenses
        fieldFirstName: $fieldFirstName
        fieldFiscalPhone: $fieldFiscalPhone
        fieldHasPropertyMortgage: $fieldHasPropertyMortgage
        fieldHandlesForeignCurrency: $fieldHandlesForeignCurrency
        fieldHasInternationalAccount: $fieldHasInternationalAccount
        fieldIdNumber: $fieldIdNumber
        fieldIdType: $fieldIdType
        fieldIncome: $fieldIncome
        fieldIndependentActivity: $fieldIndependentActivity
        fieldInternationalOperations: $fieldInternationalOperations
        fieldLastName: $fieldLastName
        fieldLiabilities: $fieldLiabilities
        fieldLicensePlate: $fieldLicensePlate
        fieldMaritalStatus: $fieldMaritalStatus
        fieldModel: $fieldModel
        fieldOccupation: $fieldOccupation
        fieldOfficeAddress: $fieldOfficeAddress
        fieldOfficeCity: $fieldOfficeCity
        fieldPersonalAddress: $fieldPersonalAddress
        fieldPersonalName: $fieldPersonalName
        fieldPersonalPhone: $fieldPersonalPhone
        fieldPersonalRelationship: $fieldPersonalRelationship
        fieldPosition: $fieldPosition
        fieldProfession: $fieldProfession
        fieldPropertyAddress: $fieldPropertyAddress
        fieldPropertyCode: $fieldPropertyCode
        fieldPropertyRegistration: $fieldPropertyRegistration
        fieldFamilyReference: $fieldFamilyReference
        fieldBankReference: $fieldBankReference
        fieldRefFamiliarMunicipality: $fieldRefFamiliarMunicipality
        fieldRefPersonalMunicipality: $fieldRefPersonalMunicipality
        fieldSalary: $fieldSalary
        fieldWorkCompany: $fieldWorkCompany
        fieldHasGarment: $fieldHasGarment
        fieldContractType: $fieldContractType
        fieldAdmissionDate: $fieldAdmissionDate
        fieldAdditionalDocuments: $fieldAdditionalDocuments
        fieldBankStatements: $fieldBankStatements
        fieldCityBirth: $fieldCityBirth
        fieldCreditAnalyst: $fieldCreditAnalyst
        fieldDateBirth: $fieldDateBirth
        fieldExpeditionDate: $fieldExpeditionDate
        fieldExpeditionPlace: $fieldExpeditionPlace
        fieldFinancialState: $fieldFinancialState
        fieldIdentificationDocument: $fieldIdentificationDocument
        fieldRut: $fieldRut
        fieldLaborCertification: $fieldLaborCertification
        fieldProofPayroll: $fieldProofPayroll
        fieldCotenants: $fieldCotenants
      }
    ) {
      violations {
        message
        path
        code
      }
      errors
      entity {
        bundle: entityBundle
        label: entityLabel
        id: entityId
      }
    }
  }
`
