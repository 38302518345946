import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button } from 'theme-ui'

import { Divider, Icon } from 'ui'
import ProjectContactForm from './ProjectContactForm'

function ProjectInfo({ name, number, whatsappChatMsg }) {
  const baseStyles = {
    alignSelf: 'start',
    borderRadius: '8px',
    boxShadow: '0px 0px 29px #00000017',
    textAlign: 'center',
    mx: ['medium', 'none'],
    px: 4,
    py: 5
  }

  const infoStyles = {
    color: 'neutral',
    fontFamily: 'heading',
    fontSize: 3,
    fontWeight: 'heading',
    lineHeight: 1,
    textTransform: 'uppercase',
    height: '20px'
  }

  const buttonStyles = {
    bg: '#00993B',
    color: 'white',
    cursor: 'pointer',
    fontWeight: 'body',
    textTransform: 'inherit',
    px: 3,
    py: 1
  }

  return (
    <Box sx={baseStyles}>
      <Box>
        <Box sx={infoStyles}>Quiero más información</Box>
        <Box mb={4}>Sobre este proyecto</Box>

        <Box mb={5}>
          <Button
            as='a'
            sx={buttonStyles}
            href={`https://api.whatsapp.com/send?phone=${number}&text=${whatsappChatMsg}`}
            target='_blank'
          >
            <Icon name='fa-whatsapp' mr={2} />A través de WhatsApp
          </Button>
        </Box>
        <Divider />

        <Box
          as='button'
          sx={{
            border: '1, solid',
            borderColor: 'transparent',
            bg: 'transparent',
            color: 'green-1',
            cursor: 'pointer',
            display: 'flex',
            fontWeight: 'heading',
            justifyContent: 'center',
            m: '0 auto'
          }}
        >
        </Box>
        <ProjectContactForm name={name} />
      </Box>
    </Box>
  )
}

ProjectInfo.propTypes = {
  whatsappChatMsg: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}

export default ProjectInfo
