import React from 'react'
import PropTypes from 'prop-types'

function IconItem({ icon, label }) {
  return (
    <div className=''>
      <img src={icon} className='w-20 md:w-auto my-0 mx-auto' />
      <p className='font-semibold text-white leading-4 text-sm md:text-base'>
        {label}
      </p>
    </div>
  )
}

export default IconItem

IconItem.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}
