import React from 'react'
import { Box, Grid, Input, Label, Radio } from 'theme-ui'

import Control from '../../components/Control'

function BasicAdditionalInformation({ register }) {
  return (
    <Grid gap={4} columns={[2, '1fr 1fr']}>
      <Control label='Servidor público' required>
        <Box sx={{ display: 'flex' }}>
          {['No', 'Si'].map((label, index) => (
            <Label>
              <Radio {...register('fieldPublicServer')} required value={index} />
              {label}
            </Label>
          ))}
        </Box>
      </Control>
      <Control label='Maneja recursos públicos' required>
        <Box sx={{ display: 'flex' }}>
          {['No', 'Si'].map((label, index) => (
            <Label>
              <Radio
                {...register('fieldManagePublicResources')}
                required
                value={index}
              />
              {label}
            </Label>
          ))}
        </Box>
      </Control>
      <Control label='Públicamente reconocido' required>
        <Box sx={{ display: 'flex' }}>
          {['No', 'Si'].map((label, index) => (
            <Label>
              <Radio
                {...register('fieldPubliclyRecognized')}
                required
                value={index}
              />
              {label}
            </Label>
          ))}
        </Box>
      </Control>
      <Control label='Familiar públicamente reconocido' required>
        <Box sx={{ display: 'flex' }}>
          {['No', 'Si'].map((label, index) => (
            <Label>
              <Radio
                {...register('fieldFamilyPubliclyRecognized')}
                required
                value={index}
              />
              {label}
            </Label>
          ))}
        </Box>
      </Control>
      <Control label='Personas a cargo'>
        <Input {...register('fieldDependents')} type='text' />
      </Control>
      <Control label='Empleado interno' required>
        <Box sx={{ display: 'flex' }}>
          {['No', 'Si'].map((label, index) => (
            <Label>
              <Radio {...register('fieldInternalEmployee')} required value={index} />
              {label}
            </Label>
          ))}
        </Box>
      </Control>
    </Grid>
  )
}

export default BasicAdditionalInformation
