import React from "react";

import IconCard from "./IconCard";

import constructionOffer from "modules/microsites/data/construction-offer";

function ConstructionCards() {
  const spans = [
    "md:col-span-3",
    "md:col-span-3",
    "md:col-span-2",
    "md:col-span-2",
    "md:col-span-2",
  ];
  return (
    <div className="grid md:grid-cols-6 gap-y-8 gap-x-1">
      {constructionOffer.map((item, index) => (
        <IconCard
          className={spans[index]}
          key={item.id}
          icon={item.icon}
          title={item.title}
          description={item.description}
        />
      ))}
    </div>
  );
}

export default ConstructionCards;
