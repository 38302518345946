import React from 'react'
import { Box, Input, Grid, Label, Radio } from 'theme-ui'

import Control from '../../components/Control'

function BasicFinancialInformation({ register }) {
  return (
    <Grid gap={4} columns={[2, '1fr 1fr']}>
      <Control label='Egresos'>
        <Input {...register('fieldExpenses')} type='text' />
      </Control>
      <Control label='Ingresos'>
        <Input {...register('fieldIncome')} type='text' />
      </Control>
      <Control label='Pasivos'>
        <Input {...register('fieldLiabilities')} type='text' />
      </Control>
      <Control label='Activos'>
        <Input {...register('fieldAssets')} type='text' />
      </Control>
      <Control label='Maneja moneda extranjera' required>
        <Box sx={{ display: 'flex' }}>
          {['No', 'Si'].map((label, index) => (
            <Label key={`foreignCurrency${index}`}>
              <Radio
                {...register('fieldHandlesForeignCurrency')}
                value={index}
                required
              />
              {label}
            </Label>
          ))}
        </Box>
      </Control>
      <Control label='Realiza operaciones internacionales' required>
        <Box sx={{ display: 'flex' }}>
          {['No', 'Si'].map((label, index) => (
            <Label key={`internationalOperations${index}`}>
              <Radio
                {...register('fieldInternationalOperations')}
                required
                value={index}
              />
              {label}
            </Label>
          ))}
        </Box>
      </Control>
      <Control label='Cuenta bancaria fuera del país' required>
        <Box sx={{ display: 'flex' }}>
          {['No', 'Si'].map((label, index) => (
            <Label key={`InternationalAccount${index}`}>
              <Radio
                {...register('fieldHasInternationalAccount')}
                required
                value={index}
              />
              {label}
            </Label>
          ))}
        </Box>
      </Control>
      <Control label='Número de cuenta'>
        <Input {...register('fieldAccountNumber')} type='text' />
      </Control>
    </Grid>
  )
}

export default BasicFinancialInformation
