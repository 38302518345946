import React from 'react'
import { Box } from 'theme-ui'

import ProjectIfoForm from '../../modules/search-building/components/ProjectInfo'

function InfoProjectForm(params) {
  const name = 'Coninsa'
  const number = '573123636333'
  const whatsappChatMsg =
    'Hola! Los vi en coninsa.co, me gustaría obtener más información.'

  return (
    <Box sx={{ width: '300px', m: '0 auto' }}>
      <ProjectIfoForm
        name={name}
        number={number}
        whatsappChatMsg={whatsappChatMsg}
      />
    </Box>
  )
}

export default InfoProjectForm
