import PropTypes from 'prop-types'
import React from 'react'

import { Header } from 'ui'
import PersonalInformation from '../forms/natural/1-personal-infomation'
import FinancialInformation from '../forms/natural/2-informacion-financiera'
import Documentation from '../forms/natural/3-documentation'
import CoTenant from '../forms/natural/4-co-tenant'

function NaturalPersonForm(props) {
  const { search } = props.location
  const params = new URLSearchParams(search)
  const path = params.get('path') || '/'

  const mapForms = {
    '/informacion-financiera': FinancialInformation,
    '/documentacion': Documentation,
    '/coarrendatarios': CoTenant,
  }

  const FormComponent = mapForms[path] || PersonalInformation

  return (
    <div className='container py-10 md:py-14 '>
      <Header
        sx={{ lineHeight: 1.15, mb: ['32px', '6'], px: ['20px', '0'] }}
        headline='Solicitud de Arrendamiento'
      >
        Persona Natural
      </Header>

      <FormComponent {...props} />
    </div>
  )
}

NaturalPersonForm.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.any,
  }),
}

export default NaturalPersonForm
