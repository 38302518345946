import React from "react";

import Banner from "../sections/banner";
import Buildings from "../sections/buildings";
import ProjectCards from "../sections/project-cards";
import Services from "../sections/services";
import OurExperience from "@coninsa-site/home/src/sections/our-experience";
import { Helmet } from 'react-helmet'

function ProjectPage({ projects = [] }) {
  return (
    <div>
      <Helmet>
        <title>Venta de Proyectos Inmobiliarios en Colombia | Coninsa</title>
        <meta name="description" content="Explora los mejores proyectos inmobiliarios en venta en Colombia con Coninsa. Ofrecemos una amplia gama de desarrollos, desde modernos apartamentos y casas hasta oficinas y locales comerciales, en las ubicaciones más privilegiadas. Invierte en calidad y confianza con Coninsa y encuentra el proyecto perfecto que se adapta a tus necesidades y expectativas." />
        <link rel="canonical" href="https://www.coninsa.co/proyectos/" />
      </Helmet>
      <Banner />
      <Buildings />
      <ProjectCards projects={projects} />
      <OurExperience />
      <Services />
    </div>
  );
}

export default ProjectPage;
