import React from "react";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Breadcrumb, { BreadcrumbItem } from "@coninsa-s2/breadcrumb";
import Heading from "@coninsa-s2/heading";

export default function TellEveryoneAndWinReferralPlan() {
  const breadcrumbItems = [
    {
      label: "Inicio",
      url: "/terminos-y-condiciones",
    },
    {
      label: "Términos y condiciones generales",
      url: "/terminos-y-condiciones/generales",
    },
    {
      label: "Plan de referidos cuéntale a todos y gana - Barranquilla",
    },
  ];

  return (
    <Section>
      <Container>
        <div className="mb-8">
          <Breadcrumb>
            {breadcrumbItems.map((item) => (
              <BreadcrumbItem>
                {item.url && <a href={item.url}>{item.label}</a>}
                {!item.url && item.label}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        </div>
        <div className="mb-7">
          <span className="text-lg text-s2-dark-blue lg:text-[22px]">
            Términos y condiciones
          </span>
          <Heading size="2xl">
            Plan de referidos cuéntale a todos y gana - Barranquilla
          </Heading>
        </div>

        <div className="prose">
          <ol>
            <li>
              <h4>Vigencia:</h4>
              <p>
                Agosto de 2018 a Diciembre de 2019, o hasta agotar existencia.
              </p>
            </li>
            <li>
              <h4>El organizador:</h4>
              <p>
                La presente actividad está organizada por Coninsa Ramón H S.A.
                con Nit 890.911.431-1 con domicilio en la sede regional Caribe:
                Calle 93 # 47 – 53 (Barranquilla), (57+5) 319 96 10.
              </p>
            </li>
            <li>
              <h4>Objeto y mecánica de la actividad:</h4>
              <p>
                El Plan de referidos “Cuéntale a todos & Gana” de Coninsa Ramón
                H. Barranquilla, es una actividad que premia durante la vigencia
                descrita, a aquellas personas que nos refieran un Cliente que
                compre un inmueble (cierre negocio) en los proyectos de vivienda
                de la ciudad de Barranquilla (Álamos del Caribe, Torres de las
                Palmas, Madeira, Sky 96, Murano y Monticello), con un suma de
                dinero que podrá ser redimida en bonos o en efectivo.
              </p>
              <ol>
                <li>
                  Los participantes (referidor y referido) autorizan a Coninsa
                  Ramón H. S.A. a difundir y/o publicar cualquier dato que haya
                  sido suministrado en su inscripción (realizada en la sala de
                  ventas de los proyectos o en los formularios físicos que se
                  entregan de la campaña “Cuéntale a todos & Gana”), en caso que
                  decida identificarlo como ganador de la actividad o para
                  cualquier otro fin comercial que la empresa organizadora
                  considere necesario, sin obligación de compensación alguna.
                </li>
                <li>
                  Los derechos que le asisten como Titular de los datos
                  personales objeto de Tratamiento son los señalados en el
                  artículo 8 de la Ley 1581 de 2012.
                </li>
              </ol>
            </li>
            <li>
              <h4>Disposiciones legales</h4>
              <ol>
                <li>
                  El solo hecho de participar en esta actividad implica la total
                  aceptación de los presentes términos y condiciones.
                </li>
                <li>
                  Los participantes se comprometen a eximir de responsabilidad a
                  Coninsa Ramón H. S.A., sus accionistas, empleados o
                  colaboradores y frente a cualquier acción, daño, costes, y
                  otras responsabilidades en las que pudiera incurrir Coninsa
                  Ramón H. S.A. como resultado de cualquier tipo de
                  incumplimiento por parte suya de alguno de los términos y
                  condiciones.
                </li>
                <li>
                  Al aceptar estos términos y condiciones los participantes
                  reconocen que de manera libre, voluntaria y espontánea han
                  decidido participar en esta actividad, por lo que dentro de
                  los límites permitidos por la ley Colombiana, renuncian a
                  cualquier reclamación o acción de cualquier naturaleza en
                  contra de Coninsa Ramón H. S.A., sus accionistas, empleados o
                  colaboradores, que tengan por objeto reclamar indemnización
                  por daños o perjuicios que les haya causado su participación
                  en la actividad, o de cualquier manera relacionados con esta,
                  o por las actividades que realizare como consecuencia de dicha
                  participación. Si el premio no es aceptado o sus condiciones,
                  se considerará que ha renunciado al mismo y no tendrá derecho
                  a reclamo o indemnización alguna, ni siquiera parcialmente.
                </li>
                <li>
                  La responsabilidad inherente a la actividad por parte de
                  Coninsa Ramón H. S.A. culmina con la entrega del premio (sea
                  en bonos o en efectivo).
                </li>
              </ol>
            </li>

            <li>
              <h4>Condiciones y restricciones</h4>
              <ol>
                <li>
                  Se considera cierre de negocio (compra del inmueble),
                  únicamente cuando se haya hecho la legalización del inmueble
                  comprado ante la fiducia.
                </li>
                <li>Promoción no válida para empleados de la compañía.</li>
                <li>
                  Para reclamar el premio se deberán dar las condiciones
                  descritas en el presente documento y se realizará en la sala
                  de negocios del proyecto en el cual se haya hecho la compra
                  del inmueble.
                </li>
                <li>
                  Bono de referido no acumulable con otras promociones, se hace
                  efectivo cuando el negocio se encuentre legalizado y el
                  proyecto alcance el punto de equilibrio. El referido no puede
                  ser un cliente que esté activo, es decir, que se encuentre en
                  seguimiento por parte de los asesores de Coninsa Ramón H.,
                  durante los últimos 30 días.
                </li>
                <li>
                  Para el pago del bono, se debe anexar la siguiente
                  documentación:
                  <ul>
                    <li>Cuenta de cobro.</li>
                    <li>Copia del Rut con fecha máxima de 2013.</li>
                    <li>Copia de la cédula.</li>
                    <li>
                      Certificación Bancaria de quien pasa la cuenta de cobro
                    </li>
                  </ul>
                  <p>Se puede escoger la forma de pago del referido:</p>
                  <ul>
                    <li>
                      Valor en dinero: el pago se realizará aproximadamente 30
                      días calendario después de entregar los documentos en Sala
                      de Negocios, a la cuenta bancaria que se informó en la
                      certificación.
                    </li>
                    <li>
                      Bonos Sodexo: se entregarán aproximadamente 15 días
                      calendario después de radicar los documentos en Sala de
                      Negocios. El plan de referido puede ser modificado a
                      discreción de la compañía y su pago es sujeto a retención
                      en la fuente.
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </Container>
    </Section>
  );
}
