import CallToAction from './CallToAction'
import CallToActionSubTitle from './CallToActionSubTitle'
import CallToActionBody from './CallToActionBody'
import CallToActionImage from './CallToActionImage'

CallToAction.SubTitle = CallToActionSubTitle
CallToAction.Body = CallToActionBody
CallToAction.Image = CallToActionImage

export default CallToAction
