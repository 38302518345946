import invoiceIcon from "./../../../icons/invoice.svg";
import contractIcon from "./../../../icons/contract.svg";
import certificateIcon from "./../../../icons/certificate.svg";

export default [
  {
    id: 'mcv-item-1',
    icon: invoiceIcon,
    subtitle: 'Consulta y',
    title: 'pago de facturas',
  },
  {
    id: 'mcv-item-2',
    icon: contractIcon,
    subtitle: 'Tus contratos',
    title: 'asociados',
  },
  {
    id: 'mcv-item-3',
    icon: certificateIcon,
    subtitle: 'Certificados y',
    title: 'constancias',
  },
]