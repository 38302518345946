import React from 'react'
import { Container } from 'theme-ui'

import { Header } from 'ui'
import Icon from 'ui/Icon'
import GeneralInformation from '../forms/legal/1-general-information'
import ShareholdersInformation from '../forms/legal/2-shareholders-information'
import Documentation from '../forms/legal/3-documentation'
import CoTenant from '../forms/legal/4-co-tenant'

function LegalPersonForm(props) {
  const { search } = props.location
  const params = new URLSearchParams(search)
  const path = params.get('path') || '/'

  const mapForms = {
    '/informacion-accionistas': ShareholdersInformation,
    '/documentacion': Documentation,
    '/coarrendatarios': CoTenant,
  }

  const FormComponent = mapForms[path] || GeneralInformation

  return (
    <Container sx={{ my: 'large' }}>
      <Header sx={{ lineHeight: 1.15, mb: '6' }} headline='Solicitud de Arrendamiento'>
        <Icon name='co-documents' width='25px' mr='small' />
        Persona Jurídica
      </Header>

      <FormComponent {...props} />
    </Container>
  )
}

export default LegalPersonForm
