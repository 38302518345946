import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

function Heading({ level, ...props }) {
  const baseLevelStyles = {
    3: {
      fontSize: 3,
      fontWeight: 'bold',
      mb: 'small',
    },
  }

  const levelStyles = baseLevelStyles[level] || {}

  const baseStyles = {
    mb: ['small', 'medium'],
    ...levelStyles,
  }

  return <Box as={`h${level}`} __css={baseStyles} {...props} __themeKey='heading' />
}

Heading.defaultProps = {
  level: 2,
}

Heading.propTypes = {
  level: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default Heading
