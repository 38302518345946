export const setPageScrolling = (enabled: boolean): void => {
  const bodyElement = window.document.body;
  const htmlElement = window.document.documentElement;

  if (enabled) {
    htmlElement.style.overflow = "hidden";
    bodyElement.style.overflow = "hidden";
  } else {
    bodyElement.style.removeProperty("overflow");
    htmlElement.style.removeProperty("overflow");
  }
};
