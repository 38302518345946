import React from 'react'

import JobOffersPage from 'modules/work-with-us/jobOffers'
import Layout from '../../layout/default/Layout'

function JobOffers() {
  return (
    <Layout>
      <JobOffersPage />
    </Layout>
  )
}

export default JobOffers
