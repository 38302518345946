import AchitectOne from '../assets/images/arch-one.jpg'
import AchitectTwo from '../assets/images/arch-two.jpg'
import AchitectThree from '../assets/images/arch-three.jpg'
import AchitectFour from '../assets/images/arch-four.jpg'
import AchitectFive from '../assets/images/arch-five.jpg'
import AchitectSix from '../assets/images/arch-six.jpg'
import AchitectSeven from '../assets/images/arch-seven.jpg'

export default [
  {
    photo: AchitectOne,
    firstName: 'Luis Fernando',
    lastName: 'Gaviria Muñoz',
    position: 'Gerente Nacional de Arquitectura',
    linkedin: '#'
  },
  {
    photo: AchitectTwo,
    firstName: 'Juan Manuel',
    lastName: 'Berrio Velásquez',
    position: 'Arquitecto Director Comercio Industria y Servicios',
    linkedin: '#'
  },
  {
    photo: AchitectFour,
    firstName: 'Juan Fernando',
    lastName: 'Montoya Sierra',
    position: 'Arquitecto Director Vivienda',
    linkedin: '#'
  },
  {
    photo: AchitectFive,
    firstName: 'Juan Carlos',
    lastName: 'Uribe Valencia',
    position: 'Arquitecto Director Región Caribe',
    linkedin: '#'
  },
  {
    photo: AchitectSix,
    firstName: 'Rogelio',
    lastName: 'Nieto Puentes',
    position: 'Arquitecto Director',
    linkedin: '#'
  },
  {
    photo: AchitectSeven,
    firstName: 'Viviana',
    lastName: 'Escobar',
    position: 'Directora Comercial',
    linkedin: '#'
  }
]