/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';
import { Helmet } from 'react-helmet';

import { CurrencyProvider } from 'modules/currency/CurrencyContext';

import SiteHeader from '../SiteHeader';
import TopMenu from '../TopMenu';

import links from './links.json';
import topLinks from './top-links.json';


const Layout = ({ children }) =>(
  <>
    <Box sx={{ display: ['none', 'block'] }}>
      <TopMenu visible={false} links={topLinks} display='end' />
    </Box>
    <SiteHeader links={links} brandUrl='/construccion' />
    <Helmet>
      <script src='/dist/outline.js' type='module' weight='100' />
      <link rel='stylesheet' media='all' href='/dist/fouc.css' />
      <link rel='stylesheet' media='all' href='/outline.theme.css' />
      <link rel='stylesheet' media='all' href='/dist/shared.css' />
    </Helmet>

    <CurrencyProvider>{children}</CurrencyProvider>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
