import React from 'react'

import Button from "@coninsa-s2/button"
import Section from "@coninsa-s2/section"
import Container from "@coninsa-s2/container"
import Heading from "@coninsa-s2/heading"

function FromAbroad() {
  return (
    <Section tint='teal'>
      <Container className='max-w-3xl'>
        <div className='mb-6'>
          <Heading tint='white' size='3xl' className="!font-bold">¿Estás en el exterior?</Heading>
          <p className='text-xl leading-5 mt-2 md:mt-0 md:text-2xl text-white'>
            Comprar tu inmueble en <strong>Colombia</strong> nunca fue tan fácil.
          </p>
        </div>

        <div className='grid gap-4 md:grid-cols-4 items-center'>
          <p className='col-span-3 text-white'>
            Asegura tu patrimonio del que podrás recibir ingresos de manera
            rentable y con la tranquilidad de tener tu dinero seguro y respaldado
            por una <strong>Compañía que desde 1972 Construye Bienestar</strong>.
          </p>

          <Button tint='teal' outline href='/desde-el-exterior' target='_blank' className='justify-self-end'>
            Más información
          </Button>
        </div>
      </Container>
    </Section>
  )
}

export default FromAbroad
