import React from 'react'
import { Box, Button, Container, Image, Link } from 'theme-ui'
import { Helmet } from 'react-helmet'
import Banner from 'ui/Banner'
import Collapse from 'ui/Collapse'
import { Header } from 'ui'
import BannerImage from '../../images/banner-trabaja-nosotros-2022.jpeg'
import TeamImage from '../../images/team_new.jpg'
import BuildersImage from '../../images/coninsa-builders-new.jpg'
import { StaticImage } from "gatsby-plugin-image"

function WorkWithUsPage() {
  const baseStyles = {
    height: '350px',
  }

  const questionStyles = {
    color: 'neutral',
    fontSize: ['22px', '32px'],
    fontWeight: 'bold',
    mb: ['small', 'medium'],
  }

  const body = `Somos un equipo conformado por 2.000 Colaboradores comprometidos con la construcción de bienestar
  para nuestros Clientes. Ubicados a lo largo y ancho del territorio Colombiano con la ejecución de obras de Comercio, 
  Industria, Servicios, de Infraestructura, del sector Público y de Vivienda.`

  return (
    <Box>
      <Helmet>
        <title>Trabaje con nosotros | Coninsa</title>
        <meta name="description" content={body} />
        <link rel="canonical" href="https://www.coninsa.co/trabaje-con-nosotros/" />
      </Helmet>
      <Banner mb='medium' bannerImageUrl={BannerImage} sx={baseStyles} />

      <Box sx={{ fontSize: [1, 2], mx: ['medium', 0] }}>
        <Container>
          <Header as="h1" sx={{ my: 'medium' }}>Trabaje con nosotros</Header>

          <Box sx={{ display: 'flex', flexDirection: ['column', 'row'] }} mb='medium'>
            <Link sx={{ color: 'accent', fontWeight: 'bold', mb: ['small', 0], mr: ['small', 'medium'] }} href='#'>Haz parte de nuestro equipo</Link>
            <Link sx={{ color: 'accent', ':hover': { textDecoration: 'underline' } }} href='/trabaje-con-nosotros/ofertas-laborales'>Oportunidades laborales</Link>
          </Box>

          <Box sx={{ mb: ['medium', 'large'] }}>
            {body} 
          </Box>

          <Box sx={{ display: ['block', 'grid'], gap: '5', mb: 'large', gridTemplateColumns: '1fr 1fr' }}>
            <Box sx={{ mb: ['medium', 0] }}>
              <Box>
                Esta ha sido nuestra misión desde 1972  y continuará siendo fuente de inspiración para la ejecución de proyectos y soluciones creativas,
                competitivas e integrales en materia de Diseño, Construcción y Bienes Raíces
              </Box>

              <Box>
                Tenemos sedes en Medellín, Bogotá, Barranquilla, ciudades donde gracias al equipo humano, profesional, comprometido,
                íntegro y altamente competente que somos, hemos crecido y posicionado una marca confiable, sólida, cálida y humana.
              </Box>
            </Box>

            <Box>
              <StaticImage src="../../images/team_new.jpg" alt="Oficinistas" />
            </Box>
          </Box>

          <Box sx={{ display: ['block', 'grid'], gap: '5', mb: 'large', gridTemplateColumns: '1fr 1fr' }}>
            <Box sx={{ mb: ['medium', 0] }}>
              <StaticImage src="../../images/coninsa-builders-new.jpg" alt="Obreros trabajando"/>
            </Box>
            <Box>
              <Box>
                Nos caracterizamos por el respeto hacia los demás y a la diferencia; por ser confiables y actuar de manera coherente con lo que proponemos y hacemos.
                Somos comprometidos y vivimos cada sueño como propio, por eso damos lo mejor para hacerlo realidad. Somos honestos y transparentes al mostrarnos tal y como somos;
                tratamos a los demás como nos gusta ser tratados y por esto brindamos y fomentamos una excelente actitud de servicio. Aquí crecemos de la mano de nuestros Colaboradores, valoramos el conocimiento y el trabajo realizado con pasión.
              </Box>
              <Box>
                Nuestra Cultura da cuenta de nuestros valores, por esto apreciamos a las personas íntegras, con metas que los inspiren a ser mejor cada día y que se vean identificados con la esencia de nuestra marca.
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: ['block', 'grid'], gap: '5', mb: 'large', gridTemplateColumns: '1fr 1fr' }}>
            <Box sx={{ mb: ['medium', 0] }}>
              <Collapse variant='mini'>
                <Collapse.Item title='Nuestro Proceso de Selección'>
                  <Box mb='medium'>
                    Buscamos enriquecer nuestro equipo con el mejor Talento Humano y brindar una experiencia memorable para aquellos profesionales que deseen hacer parte de nuestra compañía, brindando ideas innovadoras, siendo líderes inspiradores
                    con ADN comercial y así contribuir a la construcción de País.
                  </Box>
                </Collapse.Item>

                <Collapse.Item title='Etapas'>
                  <Box as='ol' mb='medium'>
                    <Box as='li'>¡Se parte de nuestro talento!</Box>
                    <Box as='li'>Nuestro primer acercamiento</Box>
                    <Box as='li'>¡Conociéndonos!</Box>
                    <Box as='li'>Poniendo a prueba tus habilidades y conocimientos</Box>
                    <Box as='li'>Interactúa con tu futuro líder</Box>
                    <Box as='li'>Seleccionando al mejor talento</Box>
                    <Box as='li'>Construyendo sueños</Box>
                    <Box as='li'>Preparándonos para tu llegada</Box>
                    <Box as='li'>Creciendo en Coninsa Ramón H.</Box>
                  </Box>
                </Collapse.Item>

                <Collapse.Item title='Políticas de selección'>
                  <Box as='ol'>
                    <Box as='li' mb='small'>
                    Se deben presentar los respectivos certificados que acrediten la experiencia requerida en el perfil y que validen lo descrito en la hoja de vida.
                    </Box>
                    <Box as='li'>
                    En Coninsa Ramón H. somos una Familia, donde Permitimos la vinculación de personas que comparten algún parentesco o grado de consanguinidad como: hijos, padres,
                    hermanos, abuelos, nietos, sobrinos y tíos, sin embargo, éstos no podrán compartir una misma área o proceso, es decir, no podrá existir codependencia o relación directa
                    en cuanto a las responsabilidades a desempeñar o el área a la cual pertenecerán, de esta manera no se presenta conflicto de intereses.
                    </Box>
                  </Box>
                </Collapse.Item>
              </Collapse>
            </Box>
              
            <Box sx={{ textAlign: 'center' }}>
              <Box sx={questionStyles}>¿Quieres hacer parte de nuestro equipo?</Box>
              <Button as='a' href='https://www.coninsa.co/trabaje-con-nosotros/ofertas-laborales/' sx={{ borderRadius: '25px', color: 'white', cursor: 'pointer', fontSize: [0, 2], px: ['15px', '22px'] }} target='_blank'>
                Conoce nuestras oportunidades laborales
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

export default WorkWithUsPage
