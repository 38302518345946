import React from "react";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Breadcrumb, { BreadcrumbItem } from "@coninsa-s2/breadcrumb";
import * as Accordion from "@coninsa-s2/accordion";
import Heading from "@coninsa-s2/heading";

import documentIcon from "../../../assets/icons/document.svg";
import pageIcon from "../../../assets/icons/window.svg";

import termDocuments2020 from "../../../assets/documents/general-2020.js";
import termDocuments2018 from "../../../assets/documents/general-2018.js";

export default function Property() {
  const breadcrumbItems = [
    {
      label: "Inicio",
      url: "/terminos-y-condiciones",
    },
    {
      label: "Términos y condiciones generales",
    },
  ];

  return (
    <Section>
      <Container>
        <div className="mb-8">
          <Breadcrumb>
            {breadcrumbItems.map((item) => (
              <BreadcrumbItem>
                {item.url && <a href={item.url}>{item.label}</a>}
                {!item.url && item.label}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        </div>

        <div className="flex items-center gap-4">
          <img src={documentIcon} alt="building icon" />
          <div>
            <span className="text-lg text-s2-dark-blue lg:text-[22px]">
              Términos y condiciones
            </span>
            <Heading size="2xl" tint="teal" weight="bold">
              Generales
            </Heading>
          </div>
        </div>

        <div>
          <Accordion.Root type="single" defaultValue="item-1" collapsible>
            <Accordion.Item value="item-1">
              <Accordion.Trigger>
                <h4 className="font-display text-2xl font-medium">Año 2020</h4>
              </Accordion.Trigger>
              <Accordion.Content>
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
                  {termDocuments2020.map((item) => (
                    <a
                      key={item?.id}
                      href={item?.url}
                      target="_blank"
                      className="flex items-center gap-2 rounded-lg bg-white px-2 py-3 shadow-lg hover:shadow-xl lg:gap-4 lg:px-5 lg:py-4"
                    >
                      <img
                        src={item?.icon}
                        className="h-16 w-14 lg:h-auto lg:w-auto"
                      />
                      <div>
                        <p className="text-sm font-bold text-s2-dark-blue lg:text-base">
                          {item?.label}
                        </p>
                      </div>
                    </a>
                  ))}
                </div>
              </Accordion.Content>
            </Accordion.Item>

            <Accordion.Item value="item-2">
              <Accordion.Trigger>
                <h4 className="font-display text-2xl font-medium">Año 2019</h4>
              </Accordion.Trigger>
              <Accordion.Content>
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
                  <a
                    href="/terminos-y-condiciones/generales/colombianos-en-el-exterior-2019"
                    target="_blank"
                    className="flex items-center gap-2 rounded-lg bg-white px-2 py-3 shadow-lg hover:shadow-xl lg:gap-4 lg:px-5 lg:py-4"
                  >
                    <img
                      src={pageIcon}
                      className="h-16 w-14 lg:h-auto lg:w-auto"
                    />
                    <div>
                      <p className="text-sm font-bold text-s2-dark-blue lg:text-base">
                        Colombianos en el Exterior - 2019
                      </p>
                    </div>
                  </a>
                </div>
              </Accordion.Content>
            </Accordion.Item>

            <Accordion.Item value="item-3">
              <Accordion.Trigger>
                <h4 className="font-display text-2xl font-medium">Año 2018</h4>
              </Accordion.Trigger>
              <Accordion.Content>
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
                  {termDocuments2018.map((item) => (
                    <a
                      key={item?.id}
                      href={item?.url}
                      target="_blank"
                      className="flex items-center gap-2 rounded-lg bg-white px-2 py-3 shadow-lg hover:shadow-xl lg:gap-4 lg:px-5 lg:py-4"
                    >
                      <img
                        src={item?.icon}
                        className="h-16 w-14 lg:h-auto lg:w-auto"
                      />
                      <div>
                        <p className="text-sm font-bold text-s2-dark-blue lg:text-base">
                          {item?.label}
                        </p>
                      </div>
                    </a>
                  ))}
                </div>
              </Accordion.Content>
            </Accordion.Item>
          </Accordion.Root>
        </div>
      </Container>
    </Section>
  );
}
