import React from "react";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { arrowPath } from "@coninsa-s2/slider";

import Heading from "@coninsa-s2/heading";
import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import ProjectCard from "@coninsa-s2/project-card";
import Tag from "@coninsa-s2/tag";

import { getProjectStateData } from "../../../../home/src/utils";

export default function ProjectCards({ projects = [] }) {
  const sliderOptions = {
    arrowPath: arrowPath,
    arrows: true,
    gap: 0,
    padding: { left: 0, right: "160px" },
    perPage: 3,
    perMove: 1,
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: 0,
        perPage: 1,
        padding: { left: 0, right: "1.5rem" },
        pagination: true,
      },
    },
  };

  return (
    <Section tint="white">
      <Container>
        <Heading tint="teal" size="4xl" className="mb-4" condensed>
          Conoce nuestros proyectos{" "}
          <span className="is-highlighted"> para entrega inmediata</span>
        </Heading>

        <Splide options={sliderOptions} className="s2-slider s2-slider--lg">
          {projects.map((item) => {
            const stateCode = item?.state;
            const state = getProjectStateData(stateCode);
            const isVis = item?.isVis;

            const areaElement = () => (
              <>
                <strong>
                  Desde {item?.areaFrom} m<sup>2</sup> hasta {item?.areaTo} m
                  <sup>2</sup>
                </strong>
                <br />
                <span className="text-sm">Áreas totales construidas</span>
              </>
            );

            const tagsElement = () => (
              <>
                {state && <Tag tint={state.tint}>{state.name}</Tag>}
                {isVis && <Tag tint="senary">VIS</Tag>}
              </>
            );

            const formattedPrice = `$${item?.price?.toLocaleString("es-CO")}`;

            return (
              <SplideSlide key={item.id}>
                <div key={item.id} className="px-2 pt-2 pb-4 h-full">
                  <ProjectCard
                    title={item.name}
                    code={item.code}
                    price={formattedPrice}
                    location={item.location}
                    url={item.url}
                    imageUrl={item.imageUrl}
                    logoUrl={item?.brandUrl}
                    description={item?.description}
                    area={areaElement}
                    tags={tagsElement}
                  />
                </div>
              </SplideSlide>
            );
          })}
        </Splide>
      </Container>
    </Section>
  );
}
