import Card from './Card'
import CardContent from './CardContent'
import CardImage from './CardImage'
import CardPicture from './CardPicture'
import CardInfo from './CardInfo'
import CardTitle from './CardTitle'
import CardTag from './CardTag'

Card.Content = CardContent
Card.Image = CardImage
Card.Picture = CardPicture
Card.Info = CardInfo
Card.Title = CardTitle
Card.Tag = CardTag

export default Card
