import React from "react";
import clsx from "clsx";

import "./styles.css";

interface BreadcrumbProps {
  children: React.ReactNode;

  className?: string;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ className, ...props }) => {
  return (
    <nav
      aria-label="Breadcrumb"
      className={clsx("s2-breadcrumb", className)}
      {...props}
    >
      <ol>{props.children}</ol>
    </nav>
  );
};

interface BreadcrumbItemProps {
  children: React.ReactNode;
  className?: string;
}
export const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({
  className,
  ...props
}) => {
  return (
    <li className={clsx("s2-breadcrumb__item", className)} {...props}>
      {props.children}
    </li>
  );
};

export default Breadcrumb;
