import { compose, filter, map, pathOr } from 'ramda'

function getTaxonomyTermUrl(path = '') {
  return path.alias.split('/').slice(0, -1).join('/')
}

const mediaImageMapper = ({ entity }) => ({
  meta: entity?.fieldMediaImage || {},
  source: entity?.gatsbyImageFile?.childImageSharp?.gatsbyImageData,
})

function getFistValidImage(gallery) {
  if (gallery.length > 0) {
    return mediaImageMapper(gallery[0])
  } else {
    return {};
  }
}

export function ourProjectTeaserMapper(data) {
  const typeName = pathOr('', ['entity', 'type', 0, 'entity', 'name'], data);

  return {
    title: data?.entity?.title,
    type: {
      name: typeName,
      url: getTaxonomyTermUrl(data?.entity?.path),
    },
    image: getFistValidImage(data?.entity?.gallery),
    url: data?.entity?.path.alias,
    location: data?.entity?.location,
  }
}

function useTypeInsteadOfTitle({ type, ...project }) {
  return {
    ...project,
    title: type?.name,
    url: type?.url,
  }
}

export function ourProjectsMapper({ projects = [], ...data }) {
  return {
    type: 'our_projects',
    projects: compose(
      map(useTypeInsteadOfTitle),
      map(ourProjectTeaserMapper),
      filter(project => project.entity)
    )(projects),
    ...data,
  }
}

export default ourProjectsMapper
