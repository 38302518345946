import { graphql } from 'gatsby'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'
import Carousel from 'nuka-carousel'
import { Helmet } from 'react-helmet'

import { layoutResolver } from '../layout'
import { Modal } from 'ui'
import SummaryCard from '../modules/microsites/architecture/components/SummaryCard.jsx'
import { SimpleOverlay } from '../modules/search-building/slices/ProjectGallery'

import {
  renderLeftControl,
  renderRightControl,
  renderTopControls,
} from '../modules/search-building/slices/PropertyBanner.js'

import bgGreen from '../modules/microsites/assets/images/bg-green.jpg'
import BaseImage from '../images/base-image.jpg'

import calendarIcon from '../modules/microsites/assets/icons/calendar.svg'
import mapIcon from '../modules/microsites/assets/icons/map.svg'

function imageMapper(data) {
  return {
    src: data?.entity?.image?.src,
    alt: data?.entity?.image?.alt,
    ...data?.entity,
  }
}

function ourProjectMapper(data) {
  const info = data?.drupal?.node

  return {
    title: info?.title,
    description: info?.body?.value,
    images: info?.images.map(imageMapper),
    location: info?.location,
    status: info?.state,
    year: info?.year,
    designYear: info?.designYear,
  }
}

function OurProjectsTemplate({ data, ...props }) {
  const [state, setState] = useState(false)
  const handler = () => setState(true)
  const closeHandler = () => {
    setState(false)
  }

  const { layout } = props?.pageContext
  const Layout = layoutResolver(layout)
  const info = data?.drupal?.node

  const { title, description, images, location, status, year } =
    ourProjectMapper(data)

  return (
    <Layout
      footerAds={info?.footerAds}
      headerAds={info?.headerAds}
      popupAds={info?.popUpAds}
    >
      <Helmet>
        <title>{info?.title} | Coninsa</title>
        <meta name="description" content={description} />
      </Helmet>

      <div
        className='bg-coninsa-green-900 py-10 md:py-16'
        style={{ backgroundImage: `url(${bgGreen})` }}
      />

      <div className='pb-16'>
        <div className='container px-5 md:px-0'>
          <h1
            className='bg-coninsa-green-500 font-display inline-block leading-8 text-3xl md:leading-[44px] md:text-[44px]
          text-white uppercase px-6 py-3 md:py-5 rounded-lg transform -translate-y-11  md:-translate-y-20'
          >
            {title}
          </h1>

          <div>
            {images.length >= 1 && (
              <div>
                <div className='block md:hidden'>
                  {images.length === 1 && (
                    <div className='flex justify-center'>
                      <img src={images[0]?.src} className='rounded-lg' />
                    </div>
                  )}
                  {images.length > 1 && (
                    <SimpleOverlay handler={handler} label='Ver más fotos'>
                      <div className='flex justify-center'>
                        <img src={images[0]?.src} className='rounded-lg' />
                      </div>
                    </SimpleOverlay>
                  )}
                </div>

                <div className='hidden md:block'>
                  {images.length > 4 && (
                    <div className='grid grid-cols-2 gap-4'>
                      <div className='mr-0'>
                        <img
                          src={images[0]?.src}
                          className='rounded-lg h-[432px] w-[560px] mr-0'
                        />
                      </div>

                      <div className='grid grid-cols-2 gap-4 h-60'>
                        <img
                          src={images[1]?.src}
                          className='rounded-lg h-[208px] w-[280px]'
                        />
                        <img
                          src={images[2]?.src}
                          className='rounded-lg h-[208px] w-[280px]'
                        />
                        <img
                          src={images[3]?.src}
                          className='rounded-lg h-[208px] w-[280px]'
                        />
                        <SimpleOverlay handler={handler} label='Ver más fotos'>
                          <img
                            src={images[4]?.src}
                            className='rounded-lg h-[208px] w-[280px]'
                          />
                        </SimpleOverlay>
                      </div>
                    </div>
                  )}

                  {images.length === 4 && (
                    <div className='grid grid-cols-2 gap-4'>
                      <div className='mr-0'>
                        <img
                          src={images[0]?.src}
                          className='rounded-lg h-[432px] w-[560px] mr-0'
                        />
                      </div>

                      <div className='grid grid-cols-2 gap-4 h-60'>
                        <img
                          src={images[1]?.src}
                          className='rounded-lg h-[208px] w-[280px]'
                        />
                        <img
                          src={images[2]?.src}
                          className='rounded-lg h-[208px] w-[280px]'
                        />
                        <img
                          src={images[3]?.src}
                          className='rounded-lg h-[208px] w-[280px]'
                        />
                      </div>
                    </div>
                  )}

                  {images.length === 3 && (
                    <div className='grid grid-cols-2 gap-4'>
                      <div className='mr-0'>
                        <img
                          src={images[0]?.src}
                          className='rounded-lg h-[432px] w-[560px] mr-0'
                        />
                      </div>

                      <div className='grid grid-cols-2 gap-4 h-60'>
                        <img
                          src={images[1]?.src}
                          className='rounded-lg h-[208px] w-[280px]'
                        />
                        <img
                          src={images[2]?.src}
                          className='rounded-lg h-[208px] w-[280px]'
                        />
                      </div>
                    </div>
                  )}

                  {images.length === 2 && (
                    <div className='grid grid-cols-2 gap-4'>
                      <img
                        src={images[0]?.src}
                        className='rounded-lg h-[432px] w-[560px] mr-0'
                      />
                      <img
                        src={images[1]?.src}
                        className='h-[432px] w-[560px] rounded-lg'
                      />
                    </div>
                  )}

                  {images.length === 1 && (
                    <div className='flex justify-center'>
                      <img
                        src={images[0]?.src}
                        className='rounded-lg h-[432px] w-[560px]'
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        sx={{ width: ['100%', '70vw'] }}
        open={state}
        onClose={closeHandler}
        variant='fullDark'
        showCloseControl={true}
      >
        <Modal.Content variant='fullDark' sx={{ px: 0 }}>
          <Box
            sx={{
              color: 'white',
              fontSize: 3,
              fontWeight: 'bold',
              mb: 'small',
              textAlign: 'center',
              textTransform: 'uppercase',
              top: '20px',
            }}
          >
            Galería del proyecto
          </Box>
          {images?.length > 0 && (
            <Box
              sx={{
                width: ['90vw', 'auto'],
                height: [280, 'auto'],
              }}
            >
              <Carousel
                renderAnnounceSlideMessage={({ currentSlide, slideCount }) =>
                  `Slide ${currentSlide + 1} of ${slideCount}`
                }
                renderBottomCenterControls={({ currentSlide }) =>
                  renderTopControls(currentSlide, images.length)
                }
                renderCenterLeftControls={({ previousSlide }) =>
                  renderLeftControl(previousSlide)
                }
                renderCenterRightControls={({ nextSlide }) =>
                  renderRightControl(nextSlide)
                }
              >
                {images.map(image => (
                  <Box>
                    <Box
                      sx={{
                        height: ['228px', '459px'],
                        overflow: 'hidden',
                        borderRadius: '8px',
                        border: 'none',
                        m: '0 auto',
                        width: ['310px', '612px'],
                      }}
                    >
                      <img className='w-full h-full' src={image?.src} />
                    </Box>
                  </Box>
                ))}
              </Carousel>
            </Box>
          )}

          {images.length === 0 && <img src={BaseImage} />}
        </Modal.Content>
      </Modal>

      <div className='py-10 md:py-14'>
        <div className='container px-5 md:px-0'>
          <div className='mb-10'>
            <h2 className='font-display leading-7 mb-4 text-3xl md:leading-10 md:mb-8 md:text-[44px] text-coninsa-green-500 uppercase'>
              Ficha técnica
              <span className='block font-bold text-coninsa-blue-900'>
                del proyecto
              </span>
            </h2>
            <h3 className='font-semibold mb-4 text-lg'>Descripción</h3>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </div>

          <div className='block md:flex gap-4 justify-center'>
            {year && (
              <SummaryCard
                icon={calendarIcon}
                title='Año de entrega'
                summary={year}
              />
            )}

            {location && (
              <SummaryCard icon={mapIcon} title='Ubicación' summary={location} />
            )}

            {status && (
              <SummaryCard icon={calendarIcon} title='Avance' summary={status} />
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

OurProjectsTemplate.propTypes = {
  data: PropTypes.oneOf({}).isRequired,
  pageContext: PropTypes.shape({
    layout: PropTypes.shape({}),
  }).isRequired,
}

export const pageQuery = graphql`
  query ourProject($id: String!) {
    drupal {
      node: nodeById(id: $id) {
        id: nid

        headerAds: ads(type: "header") {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        popUpAds: ads(type: "pop_up") {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        footerAds: ads(type: "footer") {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }

        ...NodeOurProject
      }
    }
  }
`

export default OurProjectsTemplate
