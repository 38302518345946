import headers from './headers'
import cards from './cards'
import colors from './colors'
import collapses from './collapses'
import links from './links'
import sections from './sections'
import styles from './styles'
import sliders from './sliders'

import './global.css'

const baseSpacing = 24

const borders = {
  none: 0,
  xsmall: 1,
  small: 2,
  medium: baseSpacing / 6, // 4
  large: baseSpacing / 4, // 6
  xlarge: baseSpacing / 2, // 12
}

const space = {
  0: 0,
  1: 4,
  2: 8,
  3: 12,
  4: 16,
  5: 32,
  6: 64,
  7: 128,
  8: 256,
  9: 512,

  none: 0,
  xxsmall: baseSpacing / 8, // 3
  xsmall: baseSpacing / 4, // 6
  small: baseSpacing / 2, // 12
  medium: baseSpacing, // 24
  large: baseSpacing * 2, // 48
  xlarge: baseSpacing * 4, // 96
  xxlarge: baseSpacing * 8, // 192
}

const sizes = {
  xxsmall: '48px',
  xsmall: '96px',
  small: '192px',
  medium: '384px',
  large: '768px',
  xlarge: '1152px',
  xxlarge: '1536px',
  container: '1152px',
  full: '100%',
}

export default {
  initialColorModeName: 'light',

  colors,
  borders,
  fonts: {
    body: 'Open Sans',
    heading: 'Oswald, sans-serif',
    monospace: 'Menlo, monospace',
  },

  fontSizes: [12, 14, 16, 18, 20, 24, 32, 48, 64, 96],

  fontWeights: {
    body: 400,
    heading: 400,
    semibold: 600,
    bold: 700,
  },

  lineHeights: {
    input: 1,
    body: 1.5,
    heading: 1.125,
  },

  sizes,
  space,

  dividers: {
    horizontal: {
      borderBottom: '1px solid',
      height: 1,
      my: 'small',
    },
    vertical: {
      borderRight: '1px solid',
      height: '5rem',
      mx: 'small',
    },
  },

  // Components

  headerBars: {
    default: {
      bg: 'white',
    },
    darkly: {
      bg: 'dark-2',
      color: 'white',
    },
  },

  heros: {
    default: {
      '& h2': {
        color: 'neutral',
      },
    },
    bluey: {
      bg: 'neutral',
      '& h2': {
        color: 'white',
      },
    },
  },

  buttons: {
    primary: {
      fontWeight: 'bold',
      color: 'neutral',
      textTransform: 'uppercase',
      py: 3,

      bg: 'primary',
      '&:hover': {
        color: 'primary',
        bg: 'neutral',
      },
    },

    secondary: {
      fontWeight: 'bold',
      color: 'background',
      bg: 'secondary',
      textTransform: 'uppercase',
      py: 3,

      '&:hover': {
        cursor: 'pointer',
        color: 'neutral',
        bg: 'primary',
      },
    },

    tertiary: {
      fontWeight: 'bold',
      color: 'neutral',
      bg: 'primary',
      textTransform: 'uppercase',
      py: 3,

      '&:hover': {
        cursor: 'pointer',
        color: 'neutral',
        bg: 'secondary',
      },
    },

    sharing: {
      bg: 'white',
      boxShadow: '0px 0px 29px #00000017',
      color: 'dark-4',
      fontSize: 1,
      py: 2,

      '&:hover': {
        cursor: 'pointer',
        color: 'white',
        bg: 'primary',
      },
    },

    searchProjects: {
      fontWeight: 'bold',
      fontSize: 1,
      borderRadius: '0 0 4px 4px',
      color: 'white',
      bg: 'primary',
      textTransform: 'uppercase',
      py: 3,

      '&:hover': {
        cursor: 'pointer',
        color: 'white',
        bg: 'secondary',
      },
    },

    more: {
      bg: 'transparent',
      border: '1px solid',
      borderColor: 'accent',
      boxShadow: '0px 0px 29px #00000017',
      color: 'accent',
      fontSize: 1,
      pb: 2,
      pt: 3,
      textTransform: 'capitalize',

      '&:hover': {
        cursor: 'pointer',
        color: 'white',
        bg: 'accent',
      },
    },
  },

  cards,
  collapses,

  ctas: {
    default: {
      image: {
        color: 'secondary',
        boxShadow: '3px 3px 4px #00000015',
      },
    },

    inverse: {
      content: {},
    },

    darkly: {
      bg: 'dark-1',
      content: {
        color: 'white',
      },
    },
  },

  featuredItems: {
    default: {
      icon: {
        color: 'accent',
      },
    },

    darkly: {
      color: 'white',
      icon: {
        bg: 'dark-3',
        color: 'primary',
      },
    },
  },

  forms: {
    label: {
      mb: 'xsmall',
    },

    input: {
      borderColor: 'gray',
      bg: 'background',
      p: 'small',

      darkly: {
        p: 'small',
        borderColor: 'white',
      },

      lightly: {
        bg: 'transparent',
      },
    },

    select: {
      borderColor: 'gray',
      p: 'small',

      darkly: {
        p: 'small',
        borderColor: 'white',
        bg: 'transparent',

        '& option': {
          color: 'text',
        },
      },
    },

    textarea: {
      borderColor: 'gray',
      bg: 'background',
      p: 'small',
    },
  },

  hamburgerMenus: {
    default: {
      content: {
        bg: ['white', 'inherit'],
      },
      icon: {
        color: 'neutral',
      },
    },
    darkly: {
      content: {
        bg: ['dark-2', 'inherit'],
      },
      icon: {
        color: 'white',
      },
    },
  },

  images: {
    avatar: {
      width: '250px',
      height: '250px',
      borderRadius: '50%',
    },
    mini_avatar: {
      width: '70px',
      height: '70px',
      borderRadius: '50%',
    },
  },

  headers,

  heading: {
    form: {
      borderBottom: '1px solid',
      borderColor: 'light-5',
      color: 'neutral',
      fontFamily: 'heading',
      textTransform: 'uppercase',
      fontWeight: '400',
      pb: 'xsmall',
      mb: 'medium',
    },

    label: {
      color: 'neutral',
      fontFamily: 'heading',
      fontSize: 3,
      fontWeight: 'heading',
    },
  },

  links,

  lists: {
    bullet: {
      li: {
        pl: '18px',
        position: 'relative',
        mb: 'xsmall',
      },

      'li::before': {
        content: '" "',
        bg: 'primary',
        borderRadius: '50%',
        height: '6px',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        position: 'absolute',
        width: '6px',
      },
    },
  },

  modals: {
    default: {
      width: '416px',
      bg: 'background',
      header: {},
    },
    fullScreen: {
      width: '416px',
      bg: 'background',
    },
    fullDark: {
      header: {
        bg: 'transparent',
        display: 'flex',
        justifyContent: 'flex-end',
      },
      content: {
        bg: 'transparent',
        pb: '20px',
        px: 'large',
      },
    },
  },

  sections,
  sliders,

  slides: {
    default: {},
    colored: {},
  },

  tabs: {
    default: {
      header: {},
      content: {},
      item: {
        fontWeight: 'heading',
        fontSize: [0, 1],
        '&::after': {
          height: 2,
          left: 0,
        },
      },
    },

    stepped: {
      header: { borderBottom: '1px solid', borderColor: '#ddd', pb: 0 },
      content: {},
      item: {
        display: 'flex',
        flexDirection: 'column',
        fontWeight: 'heading',
        fontSize: [0, 1],
        mx: 0,
        pb: 0,
        px: 0,

        '.step': {
          color: 'accent',
          fontSize: 1,
          fontWeight: 'bold',
          borderRadius: '5px',
          boxShadow: '0px 1px 1px #00000029',
          bg: 'white',
          p: '1px 8px',
          zIndex: 1,
          visibility: 'hidden',
        },

        '&:hover .step': {
          visibility: 'visible',
        },

        '&.active .step': {
          visibility: 'visible',
        },

        '.label': {
          mt: '-4px',
          fontWeight: 'semibold',
          p: '8px 12px',
        },

        '&:hover .label': {
          color: 'white',
          bg: '#007ae2',
          borderRadius: '6px 6px 0 0',
        },

        '&.active .label': {
          color: 'white',
          bg: 'accent',
          borderRadius: '6px 6px 0 0',
        },
      },
    },

    searching: {
      header: {
        mb: 0,
        px: 'medium',
        borderBottom: '6px solid',
        borderBottomColor: 'primary',
      },
      content: {},
      item: {
        textTransform: 'uppercase',
        fontSize: 1,
        flex: 1,
        justifyContent: 'center',
        fontWeight: 600,
        bg: 'gray',
        mx: ['1px', 1],
        '&.active': {
          bg: 'primary',
          color: 'white',
        },
      },
    },

    rounded: {
      header: {
        justifyContent: 'center',
      },
      item: {
        border: '1px solid',
        borderColor: 'text',
        borderRadius: '4px',
        mx: 0,
        px: 'small',

        '&.active': {
          bg: 'accent',
          borderColor: 'transparent',
          color: 'background',
          fontWeight: 'heading',
        },
      },
    },

    horizontal: {
      header: {
        // display: {},
        minWidth: 300,
        borderColor: 'transparent',
      },
      content: {
        py: 'xsmall',
        px: 'medium',
        flex: 1,
      },
      item: {
        mx: 0,
        pr: 'medium',

        '&::after': {
          bg: 'gray',
          bottom: 0,
          top: 0,
          width: 2,
        },
      },
    },
    vertical: {
      display: 'flex',
      header: {
        display: 'block',
        minWidth: 200,
        borderColor: 'transparent',
      },
      content: {
        py: 'xsmall',
        px: 'medium',
        flex: 1,
      },
      item: {
        mx: 0,
        pr: 'medium',

        '&::after': {
          bg: 'gray',
          bottom: 0,
          top: 0,
          width: 2,
        },
      },
    },
  },

  text: {
    heading: {
      textTransform: 'uppercase',
    },
  },

  topMenus: {
    darkly: {
      bg: 'dark-3',
    },
  },

  videos: {
    default: {},
    featured: {
      background: '#3E3E3E 0% 0% no-repeat padding-box',
      p: ['15px', '20px'],
      borderRadius: [null, '10px'],
    },
  },

  // Root styles
  styles,
}
