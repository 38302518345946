import React from "react";
import Layout from "../../../layout/default/Layout";
import { Router, navigate } from "@reach/router";

import { GoalkeeperReferralPlanPage } from "@coninsa-site/terms-and-conditions/pages/general";


function GoalkeeperReferralPlan() {
  return (
    <Layout>
      <Router basepath="/terminos-y-condiciones/generales/plan-referidos-porteros-2019-2020">
        <GoalkeeperReferralPlanPage path="*" navigate={navigate} />
      </Router>
    </Layout>
  );
}

export default GoalkeeperReferralPlan;
