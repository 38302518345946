import { gql } from '@apollo/client'

export const CREATE_NODE_NATURAL_PERSON_COTENANT = gql`
  mutation createNodeNaturalPersonCoTenant(
    $title: String!
    $fieldIndependentActivity: String
    $fieldAssets: String
    $fieldLastName: String!
    $fieldSelfRetainer: String
    $fieldPosition: String
    $fieldSpousePosition: String
    $fieldSpouseCellPhone: String
    $fieldLaborCertification: NodeFieldLaborCertificationFieldInput
    $fieldSpouseCity: NodeFieldSpouseCityFieldInput
    $fieldCityBirth: NodeFieldCityBirthFieldInput
    $fieldOfficeCity: String
    $fieldIsCustomer: String!
    $fieldHasInternationalAccount: String
    $fieldMegaDiscountLocation: NodeFieldMegaDiscountLocationFieldInput
    $fieldUbication: NodeFieldUbicationFieldInput
    $fieldPersonalAddress: String
    $fieldPropertyAddress: String
    $fieldOfficeAddress: String
    $fieldAddress: String
    $fieldFiscalAddress: String
    $fieldSpouseOfficeAddress: String
    $fieldIdentificationDocument: NodeFieldIdentificationDocumentFieldInput
    $fieldExpenses: String
    $fieldEmail: String
    $fieldInternalEmployee: String
    $fieldWorkCompany: String
    $fieldSpouseWorkCompany: String
    $fieldMaritalStatus: String
    $fieldFinancialState: NodeFieldFinancialStateFieldInput
    $fieldBankStatements: [NodeFieldBankStatementsFieldInput]
    $fieldFamilyPubliclyRecognized: String
    $fieldExpeditionDate: NodeFieldExpeditionDateFieldInput
    $fieldAdmissionDate: NodeFieldAdmissionDateFieldInput
    $fieldDateBirth: NodeFieldDateBirthFieldInput
    $fieldGreatTaxpayer: String
    $fieldGender: String!
    $fieldIncome: String
    $fieldExpeditionPlace: NodeFieldExpeditionPlaceFieldInput
    $fieldHandlesForeignCurrency: String
    $fieldManagePublicResources: String
    $fieldBrand: String
    $fieldPropertyRegistration: String
    $fieldModel: String
    $fieldPersonalName: String
    $fieldSpouseFullName: String
    $fieldFirstName: String!
    $fieldCellPhoneNumber: String
    $fieldAccountNumber: String
    $fieldSpouseIdNumber: String
    $fieldIdNumber: String!
    $fieldOccupation: String
    $fieldPersonalRelationship: String
    $fieldLiabilities: String
    $fieldDependents: String
    $fieldLicensePlate: String
    $fieldProfession: String
    $fieldPubliclyRecognized: String
    $fieldInternationalOperations: String
    $fieldBankReferences: [NodeFieldBankReferencesFieldInput]
    $fieldCommercialReferences: [NodeFieldCommercialReferencesFieldInput]
    $fieldFamilyReferences: [NodeFieldFamilyReferencesFieldInput]
    $fieldIcaResponsible: String
    $fieldIncomeResponsible: String
    $fieldSalesResponsible: String
    $fieldSalary: String
    $fieldSpouseSalary: String
    $fieldPublicServer: String
    $fieldPersonalPhone: String
    $fieldFiscalPhone: String
    $fieldSpouseOfficePhone: String
    $fieldHasPropertyMortgage: String
    $fieldHasGarment: String
    $fieldContractType: String
    $fieldIdType: String!
    $fieldSpouseId: String
    $fieldBusiness: String
  ) {
    node: createNodeNaturalPersonCoTenant(
      input: {
        title: $title
        fieldIndependentActivity: $fieldIndependentActivity
        fieldAssets: $fieldAssets
        fieldLastName: $fieldLastName
        fieldSelfRetainer: $fieldSelfRetainer
        fieldPosition: $fieldPosition
        fieldSpousePosition: $fieldSpousePosition
        fieldSpouseCellPhone: $fieldSpouseCellPhone
        fieldLaborCertification: $fieldLaborCertification
        fieldSpouseCity: $fieldSpouseCity
        fieldCityBirth: $fieldCityBirth
        fieldOfficeCity: $fieldOfficeCity
        fieldIsCustomer: $fieldIsCustomer
        fieldHasInternationalAccount: $fieldHasInternationalAccount
        fieldMegaDiscountLocation: $fieldMegaDiscountLocation
        fieldUbication: $fieldUbication
        fieldPersonalAddress: $fieldPersonalAddress
        fieldPropertyAddress: $fieldPropertyAddress
        fieldOfficeAddress: $fieldOfficeAddress
        fieldAddress: $fieldAddress
        fieldFiscalAddress: $fieldFiscalAddress
        fieldSpouseOfficeAddress: $fieldSpouseOfficeAddress
        fieldIdentificationDocument: $fieldIdentificationDocument
        fieldExpenses: $fieldExpenses
        fieldEmail: $fieldEmail
        fieldInternalEmployee: $fieldInternalEmployee
        fieldWorkCompany: $fieldWorkCompany
        fieldSpouseWorkCompany: $fieldSpouseWorkCompany
        fieldMaritalStatus: $fieldMaritalStatus
        fieldFinancialState: $fieldFinancialState
        fieldBankStatements: $fieldBankStatements
        fieldFamilyPubliclyRecognized: $fieldFamilyPubliclyRecognized
        fieldExpeditionDate: $fieldExpeditionDate
        fieldAdmissionDate: $fieldAdmissionDate
        fieldDateBirth: $fieldDateBirth
        fieldGreatTaxpayer: $fieldGreatTaxpayer
        fieldGender: $fieldGender
        fieldIncome: $fieldIncome
        fieldExpeditionPlace: $fieldExpeditionPlace
        fieldHandlesForeignCurrency: $fieldHandlesForeignCurrency
        fieldManagePublicResources: $fieldManagePublicResources
        fieldBrand: $fieldBrand
        fieldPropertyRegistration: $fieldPropertyRegistration
        fieldModel: $fieldModel
        fieldPersonalName: $fieldPersonalName
        fieldSpouseFullName: $fieldSpouseFullName
        fieldFirstName: $fieldFirstName
        fieldCellPhoneNumber: $fieldCellPhoneNumber
        fieldAccountNumber: $fieldAccountNumber
        fieldSpouseIdNumber: $fieldSpouseIdNumber
        fieldIdNumber: $fieldIdNumber
        fieldOccupation: $fieldOccupation
        fieldPersonalRelationship: $fieldPersonalRelationship
        fieldLiabilities: $fieldLiabilities
        fieldDependents: $fieldDependents
        fieldLicensePlate: $fieldLicensePlate
        fieldProfession: $fieldProfession
        fieldPubliclyRecognized: $fieldPubliclyRecognized
        fieldInternationalOperations: $fieldInternationalOperations
        fieldBankReferences: $fieldBankReferences
        fieldCommercialReferences: $fieldCommercialReferences
        fieldFamilyReferences: $fieldFamilyReferences
        fieldIcaResponsible: $fieldIcaResponsible
        fieldIncomeResponsible: $fieldIncomeResponsible
        fieldSalesResponsible: $fieldSalesResponsible
        fieldSalary: $fieldSalary
        fieldSpouseSalary: $fieldSpouseSalary
        fieldPublicServer: $fieldPublicServer
        fieldPersonalPhone: $fieldPersonalPhone
        fieldFiscalPhone: $fieldFiscalPhone
        fieldSpouseOfficePhone: $fieldSpouseOfficePhone
        fieldHasPropertyMortgage: $fieldHasPropertyMortgage
        fieldHasGarment: $fieldHasGarment
        fieldContractType: $fieldContractType
        fieldIdType: $fieldIdType
        fieldSpouseId: $fieldSpouseId
        fieldBusiness: $fieldBusiness
      }
    ) {
      violations {
        message
        path
        code
      }
      errors
      entity {
        bundle: entityBundle
        label: entityLabel
        id: entityId
        ... on NodeNaturalPersonCoTenant {
          status
          title
          created
          changed
        }
      }
    }
  }
`

export const UPDATE_NODE_NATURAL_PERSON_COTENANT = gql`
  mutation UpdateNodeNaturalPersonCoTenant(
    $id: String!
    $title: String!
    $fieldIndependentActivity: String
    $fieldAssets: String
    $fieldLastName: String!
    $fieldSelfRetainer: String
    $fieldPosition: String
    $fieldSpousePosition: String
    $fieldSpouseCellPhone: String
    $fieldLaborCertification: NodeFieldLaborCertificationFieldInput
    $fieldSpouseCity: NodeFieldSpouseCityFieldInput
    $fieldCityBirth: NodeFieldCityBirthFieldInput
    $fieldOfficeCity: String
    $fieldIsCustomer: String!
    $fieldHasInternationalAccount: String
    $fieldMegaDiscountLocation: NodeFieldMegaDiscountLocationFieldInput
    $fieldUbication: NodeFieldUbicationFieldInput
    $fieldPersonalAddress: String
    $fieldPropertyAddress: String
    $fieldOfficeAddress: String
    $fieldAddress: String
    $fieldFiscalAddress: String
    $fieldSpouseOfficeAddress: String
    $fieldIdentificationDocument: NodeFieldIdentificationDocumentFieldInput
    $fieldExpenses: String
    $fieldEmail: String
    $fieldInternalEmployee: String
    $fieldWorkCompany: String
    $fieldSpouseWorkCompany: String
    $fieldMaritalStatus: String
    $fieldFinancialState: NodeFieldFinancialStateFieldInput
    $fieldBankStatements: [NodeFieldBankStatementsFieldInput]
    $fieldFamilyPubliclyRecognized: String
    $fieldExpeditionDate: NodeFieldExpeditionDateFieldInput
    $fieldAdmissionDate: NodeFieldAdmissionDateFieldInput
    $fieldDateBirth: NodeFieldDateBirthFieldInput
    $fieldGreatTaxpayer: String
    $fieldGender: String!
    $fieldIncome: String
    $fieldExpeditionPlace: NodeFieldExpeditionPlaceFieldInput
    $fieldHandlesForeignCurrency: String
    $fieldManagePublicResources: String
    $fieldBrand: String
    $fieldPropertyRegistration: String
    $fieldModel: String
    $fieldPersonalName: String
    $fieldSpouseFullName: String
    $fieldFirstName: String!
    $fieldCellPhoneNumber: String
    $fieldAccountNumber: String
    $fieldSpouseIdNumber: String
    $fieldIdNumber: String!
    $fieldOccupation: String
    $fieldPersonalRelationship: String
    $fieldLiabilities: String
    $fieldDependents: String
    $fieldLicensePlate: String
    $fieldProfession: String
    $fieldPubliclyRecognized: String
    $fieldInternationalOperations: String
    $fieldBankReferences: [NodeFieldBankReferencesFieldInput]
    $fieldCommercialReferences: [NodeFieldCommercialReferencesFieldInput]
    $fieldFamilyReferences: [NodeFieldFamilyReferencesFieldInput]
    $fieldIcaResponsible: String
    $fieldIncomeResponsible: String
    $fieldSalesResponsible: String
    $fieldSalary: String
    $fieldSpouseSalary: String
    $fieldPublicServer: String
    $fieldPersonalPhone: String
    $fieldFiscalPhone: String
    $fieldSpouseOfficePhone: String
    $fieldHasPropertyMortgage: String
    $fieldHasGarment: String
    $fieldContractType: String
    $fieldIdType: String!
    $fieldSpouseId: String
    $fieldBusiness: String
  ) {
    node: updateNodeNaturalPersonCoTenant(
      id: $id
      input: {
        title: $title
        fieldIndependentActivity: $fieldIndependentActivity
        fieldAssets: $fieldAssets
        fieldLastName: $fieldLastName
        fieldSelfRetainer: $fieldSelfRetainer
        fieldPosition: $fieldPosition
        fieldSpousePosition: $fieldSpousePosition
        fieldSpouseCellPhone: $fieldSpouseCellPhone
        fieldLaborCertification: $fieldLaborCertification
        fieldSpouseCity: $fieldSpouseCity
        fieldCityBirth: $fieldCityBirth
        fieldOfficeCity: $fieldOfficeCity
        fieldIsCustomer: $fieldIsCustomer
        fieldHasInternationalAccount: $fieldHasInternationalAccount
        fieldMegaDiscountLocation: $fieldMegaDiscountLocation
        fieldUbication: $fieldUbication
        fieldPersonalAddress: $fieldPersonalAddress
        fieldPropertyAddress: $fieldPropertyAddress
        fieldOfficeAddress: $fieldOfficeAddress
        fieldAddress: $fieldAddress
        fieldFiscalAddress: $fieldFiscalAddress
        fieldSpouseOfficeAddress: $fieldSpouseOfficeAddress
        fieldIdentificationDocument: $fieldIdentificationDocument
        fieldExpenses: $fieldExpenses
        fieldEmail: $fieldEmail
        fieldInternalEmployee: $fieldInternalEmployee
        fieldWorkCompany: $fieldWorkCompany
        fieldSpouseWorkCompany: $fieldSpouseWorkCompany
        fieldMaritalStatus: $fieldMaritalStatus
        fieldFinancialState: $fieldFinancialState
        fieldBankStatements: $fieldBankStatements
        fieldFamilyPubliclyRecognized: $fieldFamilyPubliclyRecognized
        fieldExpeditionDate: $fieldExpeditionDate
        fieldAdmissionDate: $fieldAdmissionDate
        fieldDateBirth: $fieldDateBirth
        fieldGreatTaxpayer: $fieldGreatTaxpayer
        fieldGender: $fieldGender
        fieldIncome: $fieldIncome
        fieldExpeditionPlace: $fieldExpeditionPlace
        fieldHandlesForeignCurrency: $fieldHandlesForeignCurrency
        fieldManagePublicResources: $fieldManagePublicResources
        fieldBrand: $fieldBrand
        fieldPropertyRegistration: $fieldPropertyRegistration
        fieldModel: $fieldModel
        fieldPersonalName: $fieldPersonalName
        fieldSpouseFullName: $fieldSpouseFullName
        fieldFirstName: $fieldFirstName
        fieldCellPhoneNumber: $fieldCellPhoneNumber
        fieldAccountNumber: $fieldAccountNumber
        fieldSpouseIdNumber: $fieldSpouseIdNumber
        fieldIdNumber: $fieldIdNumber
        fieldOccupation: $fieldOccupation
        fieldPersonalRelationship: $fieldPersonalRelationship
        fieldLiabilities: $fieldLiabilities
        fieldDependents: $fieldDependents
        fieldLicensePlate: $fieldLicensePlate
        fieldProfession: $fieldProfession
        fieldPubliclyRecognized: $fieldPubliclyRecognized
        fieldInternationalOperations: $fieldInternationalOperations
        fieldBankReferences: $fieldBankReferences
        fieldCommercialReferences: $fieldCommercialReferences
        fieldFamilyReferences: $fieldFamilyReferences
        fieldIcaResponsible: $fieldIcaResponsible
        fieldIncomeResponsible: $fieldIncomeResponsible
        fieldSalesResponsible: $fieldSalesResponsible
        fieldSalary: $fieldSalary
        fieldSpouseSalary: $fieldSpouseSalary
        fieldPublicServer: $fieldPublicServer
        fieldPersonalPhone: $fieldPersonalPhone
        fieldFiscalPhone: $fieldFiscalPhone
        fieldSpouseOfficePhone: $fieldSpouseOfficePhone
        fieldHasPropertyMortgage: $fieldHasPropertyMortgage
        fieldHasGarment: $fieldHasGarment
        fieldContractType: $fieldContractType
        fieldIdType: $fieldIdType
        fieldSpouseId: $fieldSpouseId
        fieldBusiness: $fieldBusiness
      }
    ) {
      violations {
        message
        path
        code
      }
      errors
      entity {
        bundle: entityBundle
        label: entityLabel
        id: entityId
      }
    }
  }
`