import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Image } from 'theme-ui'
import { Icon } from 'ui'

function PArchitectureTeam({ members, teamPhoto, ...props }) {
  const [open, setOpen] = useState(false)

  const toggleOpenState = () => {
    setOpen(!open)
  }

  return (
    <Box {...props}>
      <Box
        sx={{
          columnGap: [0, '15%'],
          display: 'grid',
          gridTemplateColumns: ['repeat(1, 1fr)', 'repeat(2, 1fr)'],
          gridAutoRows: 'minmax(100px, auto)',
          mb: 'small',
          mx: [0, '7%'],
          mt: ['small', 'medium'],
        }}
      >
        {members
          .map(({ entity }) => entity)
          .map(member => (
            <Box sx={{ display: 'flex', mb: 'medium' }}>
              <Box sx={{ mr: ['small', 'medium'] }}>
                <Image
                  src={member.photo.entity.image.src}
                  sx={{ objectFit: 'cover' }}
                  variant='mini_avatar'
                />
              </Box>
              <Box>
                <Box sx={{ fontSize: [1, 2], fontWeight: 'bold' }}>
                  {member.title}
                </Box>
                <Box sx={{ fontSize: [0, 1], fontWeight: 'heading', mb: 'xsmall' }}>
                  {member.position}
                </Box>

                <Box
                  as='a'
                  target='_blank'
                  sx={{
                    color: 'secondary',
                    fontSize: 1,
                    textTransform: 'uppercase',
                  }}
                  href={member.path.alias}
                >
                  Conócelo
                  <Icon
                    name='fi-external-link'
                    sx={{ color: 'secondary', ml: 'xsmall', fontSize: 1 }}
                  />
                </Box>
              </Box>
            </Box>
          ))}
      </Box>

      <Box sx={{ display: ['none', 'block'], textAlign: 'center' }}>
        <Box
          as='a'
          onClick={toggleOpenState}
          sx={{
            cursor: 'pointer',
            color: 'secondary',
            display: 'block',
            fontWeight: 'bold',
            mb: 'small',
          }}
        >
          Conoce todo nuestro equipo
        </Box>
        {open && (
          <Box sx={{ textAlign: 'center' }}>
            <Image src={teamPhoto.entity.image.src} />
          </Box>
        )}
      </Box>
    </Box>
  )
}

PArchitectureTeam.defaultProps = {
  members: [],
  teamPhoto: {},
}

PArchitectureTeam.propTypes = {
  members: PropTypes.arrayOf(),
  teamPhoto: PropTypes.oneOf({}),
}

export default PArchitectureTeam
