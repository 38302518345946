import pageIcon from "../icons/window.svg";

export default [
  {
    id: "terms-2020-1",
    icon: pageIcon,
    label: "Plan Referidos Porteros 2019 - 2020",
    url: "/terminos-y-condiciones/generales/plan-referidos-porteros-2019-2020",
  },
  {
    id: "terms-2020-2",
    icon: pageIcon,
    label: "Arrendamientos sin Coarrendatario",
    url: "/terminos-y-condiciones/generales/arrendamientos-sin-coarrendatario",
  },
  {
    id: "terms-2020-3",
    icon: pageIcon,
    label: "Propietarios Feria Arrendamientos 2020",
    url: "/terminos-y-condiciones/generales/propietarios-feria-arrendamientos-2020",
  },
];
