import React from 'react'
import { Grid, Input, Select } from 'theme-ui'

import Control from '../../components/Control'

import { getOptions, companyTypes } from '../options'

function BasicGeneralInformation({ register }) {
  return (
    <Grid gap={4} columns={[2, '1fr 1fr']} mb='medium'>
      <Control label='Razón social' required>
        <Input {...register('fieldSocialReason')} type='text' required />
      </Control>
      <Control label='NIT' required>
        <Input {...register('fieldNit')} type='text' required />
      </Control>
      <Control label='Tipo de empresa'>
        <Select {...register('fieldCompanyType')}>
          {getOptions(companyTypes).map(option => (
            <option value={option.value}>{option.label}</option>
          ))}
        </Select>
      </Control>
      <Control label='Nombre de actividad económica'>
        <Input {...register('fieldEconomicActivity')} type='text' />
      </Control>
      <Control label='Número de empleados'>
        <Input {...register('fieldEmployeesNumber')} type='number' />
      </Control>
    </Grid>
  )
}

export default BasicGeneralInformation
