/** @jsx jsx */
import PropTypes from 'prop-types'
import { Box, Container, jsx } from 'theme-ui'

import defaultBannerImage from '../images/enmascarar.png'

const Banner = ({ children, bannerImageUrl, ...props }) => {

  const bannerImage = bannerImageUrl || defaultBannerImage
  const baseStyles = {
    backgroundImage: `url(${bannerImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    py: 'large',
    px: 'medium',
  }

  return (
    <Box {...props} __css={baseStyles}>
      <Container>{children}</Container>
    </Box>
  )
}

const BannerHeader = props => {
  const headerStyles = {
    mb: 'medium',
    textTransform: 'uppercase',
    fontFamily: 'heading',
    fontSize: '32px',
    fontWeight: 'heading',
    color: 'white',
  }

  return <Box __css={headerStyles} as='h2' {...props} />
}

const BannerContent = props => {
  const contentStyles = {
    color: 'white',
    fontSize: '20px',
  }

  return <Box __css={contentStyles} {...props} />
}

Banner.Header = BannerHeader
Banner.Content = BannerContent

Banner.propTypes = {
  children: PropTypes.node.isRequired,
}
export default Banner
