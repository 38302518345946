import React from "react";
import Layout from "../../../layout/default/Layout";
import { Router, navigate } from "@reach/router";

import { TellEveryoneAndWinReferralPlanPage } from "@coninsa-site/terms-and-conditions/pages/general";


function TellEveryoneAndWinReferralPlan() {
  return (
    <Layout>
      <Router basepath="/terminos-y-condiciones/generales/plan-de-referidos-cuentale-a-todos-y-gana-barranquilla">
        <TellEveryoneAndWinReferralPlanPage path="*" navigate={navigate} />
      </Router>
    </Layout>
  );
}

export default TellEveryoneAndWinReferralPlan;
