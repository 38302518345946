import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

const PropertyMap = ({ latLon }) => {
  if (!latLon?.lat || !latLon?.lon) {
    return null
  }

  // Prevent render on SSR.
  const { lat, lon } = latLon

  return (
    <Box>
      <Box
        as='iframe'
        width='100%'
        frameBorder='0'
        src={`https://maps.google.com/maps?q=${lat},${lon}&hl=es&z=14&output=embed&lvl=17&typ=d&sty=r&src=SHELL&FORM=MBEDV8`}
        scrolling='no'
        sx={{
          borderRadius: '8px',
          height: ['400px', '325px'],
          mb: '0',
          mt: ['small', 'medium'],
        }}
      />
    </Box>
  )
}

PropertyMap.propTypes = {
  latLon: PropTypes.shape({
    lat: PropTypes.string,
    lon: PropTypes.string,
  }).isRequired,
}

export default PropertyMap
