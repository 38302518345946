const config = {
  cognito: {
    REGION: process.env.REACT_APP_COGNITO_REGION,
    USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,

    // DOMAIN: process.env.REACT_APP_COGNITO_DOMAIN,
    // REDIRECT_SIGN_IN: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN,
    // REDIRECT_SIGN_OUT: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT,

    // IDENTITY_POOL_ID: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  },
}

export default config
