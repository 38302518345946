import React from "react";
import { useForm } from "react-hook-form";

import formStorage from "./storage/form";
import quotationStorage from "./storage/quotation";
import { bootCalculator } from "./proxy/calculator";
import ZohoApi, { T_zoho_detail } from "./api/zoho-api";



interface T_props {
  backCallback: () => void

  zohoDetail: T_zoho_detail
}

export default function StepFive({ backCallback, zohoDetail }: T_props) {
  const form = formStorage.get();
  const quotation = quotationStorage.get();
  const calculator = bootCalculator({ form, quotation, applyVariables: true });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({});

  const onSubmit = async () => {
    const data = await calculator.generarPdf();
    const url = data["data"]["url_last"];

    // Call zoho API client
    ZohoApi(formStorage.get(), url, zohoDetail);

    window.open(url, "_blank");
  };

  const projectName = calculator?.selectProject[0]["nombre"];

  const units = calculator?.selectedUnits ?? [];

  const onReset = () => {
    formStorage.clean();

    if (backCallback) {
      backCallback();
    }
  };

  return (
    <div className="co-card">
      <form className="co-form" onSubmit={handleSubmit(onSubmit)}>
        <h3 className="co-heading-4 text-center">
          Inmueble simulado | {projectName}
        </h3>

        <table className="max-w-3xl w-full mx-auto">
          <tbody>
            {units.map((unit) => (
              <tr key={unit.idUnidad}>
                <td>
                  {unit.descTipoUnidad} | {unit.descipcionTipoUnidad}
                </td>
                <td className="text-end">
                  ${Number.parseInt(unit.valor).toLocaleString("es-CO")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <table className="mb-4 max-w-3xl w-full mx-auto">
          <tbody>
            <tr>
              <td className="font-semibold">
                Separación ({calculator.porcentajeSeparacion.toFixed(2)} %)
              </td>
              <td className="text-end">
                ${calculator.valorSeparacion.toLocaleString("es-CO")}
              </td>
            </tr>
            <tr>
              <td className="font-semibold">
                Cuota inicial ({calculator.porcentajeCuotaInicial.toFixed(2)} %)
              </td>
              <td className="text-end">
                ${calculator.cuotainicial.toLocaleString("es-CO")}
              </td>
            </tr>
            <tr>
              <td className="font-semibold">
                Crédito hipotecario ({calculator.porcentajeCredito.toFixed(2)}{" "}
                %)
              </td>
              <td className="text-end">
                ${calculator.credito.toLocaleString("es-CO")}
              </td>
            </tr>
            <tr>
              <td className="font-semibold">Total:</td>
              <td className="text-end">
                $
                {Number.parseInt(calculator.totalCalculado).toLocaleString(
                  "es-CO"
                )}
              </td>
            </tr>
          </tbody>
        </table>

        <div className="form-actions flex gap-2 justify-center">
          <button
            className="co-button is-progress-default is-large"
            disabled={isSubmitting}
          >
            {!isSubmitting ? "Ver simulación" : "Generando..."}
          </button>

          <button onClick={onReset} className="co-button is-secondary is-large">
            Nueva simulación
          </button>
        </div>

        <p className="co-summary__note text-center">
          “El valor de la separación es de $
          {calculator.valorSeparacion.toLocaleString("es-CO")} y está incluido
          en el precio del inmueble”
        </p>
      </form>
    </div>
  );
}
