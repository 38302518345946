import React from 'react';
import { clsx } from "clsx";
import "./styles.css";

function Text({ className, ...props}) {
  const classes = {
    "s2-text": true,
    [className]: className,
  }
  return <div className={clsx(classes)}>{props.children}</div>;
}

export default Text;
