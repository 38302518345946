import { gql } from '@apollo/client'

export const CURRENT_USER_CONTEXT_QUERY = gql`
  query {
    user: currentUserContext {
      uid
      roles {
        id: targetId
      }
      name
      mail
      pass {
        value
        existing
        preHashed
      }
      document: fieldDocument
      phone: fieldPhone
      fullName: fieldFullName
      documentType: fieldDocumentType
    } 
  }
`

export default {}