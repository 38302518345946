import React from "react";
import clsx from "clsx";
import "./styles.css";

interface DialogBoxProps {
  tint?: string;
  width?: string;
  inverted?: boolean;

  className?: string;
  children?: React.ReactNode;
}

const DialogBox: React.FC<DialogBoxProps> = ({
  tint,
  width,
  inverted = false,
  className,
  ...props
}) => {
  const dynamicClasses = {
    [`s2-dialog-box--${tint}`]: tint,
    [`s2-dialog-box--${width}`]: width,
    "s2-dialog-box--inverted": inverted,
  };

  return (
    <div
      className={clsx("s2-dialog-box", dynamicClasses, className)}
      {...props}
    >
      {props.children}
      <span className="s2-dialog-box__shape"></span>
    </div>
  );
};

export default DialogBox;
