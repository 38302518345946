import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import IntlTelInput from "react-intl-tel-input-18";
import "react-intl-tel-input-18/dist/main.css";
import parsePhoneNumber from "libphonenumber-js";
import { Helmet } from 'react-helmet'

import Slider from "@coninsa-ui/react-slider";
import Splide from "@coninsa-ui/react-splide";

import "./style.css"

import marketingIcon from "../assets/icons/marketing.svg";
import calendarIcon from "../assets/icons/calendar.svg";
import signIcon from "../assets/icons/sign.svg";
import rentIcon from "../assets/icons/rent.svg";
import toolsIcon from "../assets/icons/tools.svg";
import reviewIcon from "../assets/icons/review.svg";
import computerIcon from "../assets/icons/computer.svg";
import protectorIcon from "../assets/icons/protector.svg";
import settingsIcon from "../assets/icons/settings.svg";
import presentIcon from "../assets/icons/present.svg";

const ourDifferentials = [
  {
    icon: marketingIcon,
    label: "Promocionamos tu inmueble gratis",
    body: "en los principales portales web.",
  },
  {
    icon: calendarIcon,
    label: "Te giramos la renta",
    body: "así no pague tu inquilino.",
  },
  {
    icon: signIcon,
    label: "Podrás firmar tu contrato desde cualquier lugar.",
    body: "Nuestros procesos son digitales con firma electrónica",
  },
  {
    icon: rentIcon,
    label: "Te anticipamos la renta",
    body: "sin afectar su capacidad de endeudamiento.",
  },
  {
    icon: toolsIcon,
    label: "Tenemos un equipo de mantenimiento",
    body: "para atender los requerimientos de tu inmueble arrendado o en el que vives.",
  },
  {
    icon: reviewIcon,
    label: "Arrendamos tu inmueble",
    body: "más rápido que el mercado una vez se desocupe.",
  },
  {
    icon: computerIcon,
    label: "Analizamos tu arrendatario",
    body: "en todas las bases de datos de riesgo para darte tranquilidad.",
  },
  {
    icon: protectorIcon,
    label: "Amparos adicionales",
    body: "que protegerán tu inmueble",
  },
  {
    icon: settingsIcon,
    label: "Administras tus contratos",
    body: "a través de Mi Coninsa virtual.",
  },
];

export default function Landing() {
  const { register, handleSubmit, reset } = useForm({});
  const [submitted, setSubmitted] = useState(false);
  const [phone, setPhone] = useState();

  const onChange = (_, number, country) => {
    const phoneNumber = parsePhoneNumber(number, country.iso2);

    if (phoneNumber && phoneNumber?.isValid()) {
      setPhone(phoneNumber.number);
    }
  };

  const onSubmit = async (data) => {
    const zohoInfo = {
      data: [
        {
          First_Name: data.first_name,
          Last_Name: data.last_name,
          Owner: { id: "2528071000370702608" },
          Email: data.email,
          Mobile: phone,
          Regional: "Bogotá",
          Origen_de_Informaci_n: "Digital",
          Medio_Publicitario: "www.coninsa.co",
          Creador_API: "www.coninsa.co",
          Estrategia_de_Campa_a: "Consigna tu inmueble",
          Tipo_de_Canal: "Digital",
          Tipo_de_Contacto: "Otros",
          Layout: {
            id: "2528071000000091055",
          },
          Proyecto_de_interes: {
            id: "2528071000024260071",
          },
          Destino_del_Inmueble: "Para Habitar",
        },
      ],
    };

    const url = "https://api.coninsa.co/api/v2/zohocrm-create-lead";

    return new Promise((resolve, reject) => {
      axios
        .post(url, zohoInfo)
        .then((response) => {
          reset();
          setSubmitted(true);
          window.open('https://www.coninsa.co/gracias')
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  return (
    <div>
      <Helmet>
        <title>Consigna tu inmueble | Coninsa</title>
        <meta name="description" content="Con nosotros estarás tranquilo, tienes respaldo, soluciones integrales y un verdadero aliado inmobiliario para tus propiedades de vivienda o comercio." />
        <link rel="canonical" href="https://www.coninsa.co/consigna-tu-inmueble/" />
      </Helmet>
      <div className="linear-bg" data-cid="wxz4562">
        <div className="hero h-[576px] md:h-[450px]">
          <div className="hero-container md:container">
            <div className="hero-content sm:w-2/3 md:w-1/2 px-6 md:px-0 py-6 md:py-0">
              <h1 className="font-heading text-white text-xl leading-5 md:text-[40px] md:leading-10 mb-3 md:mb-6">
                ¿Quieres hacer tú <span className="block text-center">el proceso de</span>
                <br />
                <span className="block font-handwritting font-light text-center text-[var(--color-secondary)] text-5xl leading-5 mb-4 md:text-7xl md:leading-9">
                  Publicar
                </span>
                el inmueble con nosotros?
              </h1>
              <p className="font-light leading-5 text-white md:text-lg md:leading-6 mb-4">
                Con nosotros estarás{" "}
                <span className="font-bold">
                  tranquilo, tienes respaldo, soluciones integrales y un
                  verdadero aliado inmobiliario
                </span>{" "}
                para tus propiedades de vivienda o comercio.
              </p>
              <a href="/consigna-tu-inmueble/registro" className="co-button is-tertiary--alternative">
                Comenzar con mi publicación
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-8 md:py-12">
        <h2 className=" md:w-[750px] mb-6 md:mb-12 mx-auto font-medium text-2xl md:text-[40px] text-[var(--color-primary)] text-center leading-5 md:leading-10">
          <span className="block font-heading text-[34px] md:text-5xl text-[var(--color-secondary)] leading-8 md:leading-10 text-center">
            Recuerda tener a la mano
          </span>
          esta información
        </h2>

        <Splide
          options={{
            paddingRight: "60px",
            gap: "16px",
            itemsToShow: 3,
          }}
        >
          <div className="co-numeric-card">
            <div className="co-numeric-card__number">1</div>
            <div className="co-numeric-card__content">
              <h4 className="co-heading-4 is-secondary">
                Información de inmueble
              </h4>
              <p className="text-sm md:text-base">
                Descripción, ubicación, detalles y características, entre otros.
              </p>
            </div>
          </div>
          <div className="co-numeric-card">
            <div className="co-numeric-card__number">2</div>
            <div className="co-numeric-card__content">
              <h4 className="co-heading-4 is-secondary">
                Datos del propietario
              </h4>
              <p className="text-sm md:text-base">
                Los datos básicos del propietario del inmueble.
              </p>
            </div>
          </div>
          <div className="co-numeric-card">
            <div className="co-numeric-card__number">3</div>
            <div className="co-numeric-card__content">
              <h4 className="co-heading-4 is-secondary">Fotografías</h4>
              <p className="text-sm md:text-base">
                Recuerda tener las fotografías del inmueble y aumentar la
                visibilidad.
              </p>
            </div>
          </div>
        </Splide>
      </div>

      <div className="bg-[var(--color-primary)] py-8 md:py-20">
        <div className="container">
          <h2 className="font-heading text-[34px] md:text-6xl text-white md:text-center mb-3 md:mb-5">
            Nuestros{" "}
            <span className="dialog is-compact is-teal">diferenciales</span>
          </h2>

          <Slider>
            {ourDifferentials.map((differential) => (
              <div className="co-card is-centered">
                <img src={differential?.icon} />
                <h4 className="font-medium md:font-bold text-sm md:text-base">
                  {differential?.label}
                  <span className="font-light">{` ${differential?.body}`}</span>
                </h4>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <div className="container pt-12 pb-8 md:pb-16 max-w-5xl">
        <h2 className="font-heading text-[34px] md:text-5xl text-[var(--color-secondary)] leading-5 md:leading-6 text-center mb-8 md:mb-10">
          Conoce nuestras {""}
          <span className="font-handwritting font-light text-[var(--color-primary)] text-5xl md:text-7xl">
            tarifas
          </span>
        </h2>

        <Splide options={{
          paddingRight: "120px",
          gap: "16px",
          itemsToShow: 4,
        }}>
          <div className="">
            <div className="is-rate shadow-[0px_3px_6px_#00000029]">
              <h4 className="co-heading-4 is-primary mb-4">Súper Premium</h4>
              <span className="text-sm font-bold">Tarifa del</span>
              <h3>11%</h3>
              <p className="text-sm">
                Fecha de pago el primer día hábil del mes.
              </p>
            </div>
            <div className="highlighted-text">
              <p>Garantía de canon</p>
            </div>
          </div>

          <div className="">
            <div className="is-rate shadow-[0px_3px_6px_#00000029]">
              <h4 className="co-heading-4 is-primary mb-4">Premium</h4>
              <span className="text-sm font-bold">Tarifa del</span>
              <h3>10.8%</h3>
              <p className="text-sm">
                Fecha de pago el primer día hábil del mes.
              </p>
            </div>
            <div className="highlighted-text">
              <p>Garantía de canon</p>
            </div>
          </div>

          <div className="">
            <div className="is-rate shadow-[0px_3px_6px_#00000029]">
              <h4 className="co-heading-4 is-primary mb-4">Estándar</h4>
              <span className="text-sm font-bold">Tarifa del</span>
              <h3>10.5%</h3>
              <p className="text-sm">
                Fecha de pago el primer día hábil del mes.
              </p>
            </div>
            <div className="highlighted-text">
              <p>Garantía de canon</p>
            </div>
          </div>

          <div className="">
            <div className="is-rate shadow-[0px_3px_6px_#00000029]">
              <h4 className="co-heading-4 is-primary mb-4">Básica</h4>
              <span className="text-sm font-bold">Tarifa del</span>
              <h3>10%</h3>
              <p className="text-sm">
                Fecha de pago el primer día hábil del mes.
              </p>
            </div>
            <div className="highlighted-text">
              <p>Garantía de canon</p>
            </div>
          </div>
        </Splide>

        <div className="bg-[var(--color-base-2)] p-5 rounded-lg mt-4 mb-5 md:mb-10">
          <p className="text-sm">
            <span className="block font-bold">
              Todas las tarifas se facturarán más IVA.
            </span>
            El tiempo de la garantía del canon se define en el momento del
            estudio del cliente arrendatario por parte de la aseguradora, puede
            cubrir por mora de 6 a 36 meses.
          </p>
        </div>

        <div className="flex justify-center">
          <div className="flex items-center ml-9 md:ml-24 relative rounded-lg shadow-[0px_3px_6px_#00000029] pl-8 md:pl-12 pr-4 py-4">
            <img
              src={presentIcon}
              className="absolute -left-12 md:-left-24 z-10 w-20 h-20 md:w-auto md:h-auto"
            />
            <h3 className="text-xs md:text-base text-[var(--color-primary)] leading-5 md:leading-6 md:px-5">
              Complementa tus servicios con nuestros
              <span className="block text-co-dark-cyan text-[20px] font-bold md:text-[28px]">
                Amparos adicionales
              </span>
              <span className="font-bold text-sm md:text-lg">
                Para que tengas tranquilidad total
              </span>
            </h3>
          </div>
        </div>
      </div>

      <div className="from-white-to-teal md:pb-10">
        <div className="md:container">
          <div className="co-card !rounded-none md:!rounded-lg is-green is-horizontal py-8">
            <div className="text-center md:text-left">
              <h3 className="font-bold text-xl leading-6 md:leading-8 md:text-3xl mb-2 md:mb-3">
                ¡Comienza el proceso de publicación de tu propiedad en línea!
              </h3>
              <p className="font-normal leading-5 md:text-xl md:leading-6">
                Encontramos el inquilino ideal para que comiences a recibir la
                renta de tu propiedad.
              </p>
            </div>

            <a href="/consigna-tu-inmueble/registro" className="co-button is-tertiary--alternative">
              Quiero publicar ahora
            </a>
          </div>
        </div>
      </div>

      <div className="bg-co-dark-cyan py-10 md:py-16">
        <div className="container grid md:grid-cols-2 gap-6">
          <div className="text-white">
            <h2 className="text-3xl text-center md:text-left md:text-[40px] mb-3 md:mb-6 font-display uppercase">
              ¿Necesitas ayuda?
            </h2>
            <p className="text-xl leading-7 md:leading-10 md:text-3xl font-thin max-w-sm text-center md:text-left">
              Si quieres compañía en tu proceso, déjanos tus datos para que se
              gestione con un asesor, se contacte contigo y te ayude con el
              proceso.
            </p>
          </div>
          <div className="co-card">
            {!submitted && (
              <div>
                <h3 className="co-heading-5 text-center mb-4">
                  Comunícate con un asesor
                </h3>

                <form className="co-form" onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-control">
                    <input
                      {...register("first_name")}
                      type="text"
                      id="first_name"
                      placeholder="Nombres"
                      required
                    />
                  </div>
                  <div className="form-control">
                    <input
                      {...register("last_name")}
                      type="text"
                      id="last_name"
                      placeholder="Apellidos"
                      required
                    />
                  </div>
                  <div className="form-control is-intl-tel">
                    <IntlTelInput
                      {...register("phone")}
                      id="phone"
                      placeholder="Número de contacto"
                      defaultCountry={"co"}
                      onPhoneNumberChange={onChange}
                      telInputProps={{ required: true }}
                    />
                  </div>
                  <div className="form-control">
                    <input
                      {...register("email")}
                      type="email"
                      id="email"
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div className="form-control">
                    <input
                      {...register("city")}
                      type="text"
                      id="city"
                      placeholder="Ciudad de residencia"
                      required
                    />
                  </div>
                  <div className="form-control flex flex-col md:flex-row gap-4">
                    <select
                      className="inmueble"
                      {...register("property_type")}
                      id="property_type"
                      required
                    >
                      <option value="">Tipo de inmueble</option>
                      <option value="Apartamento">Apartamento</option>
                      <option value="Bodega">Bodega</option>
                      <option value="Casa">Casa</option>
                      <option value="Consultorio">Consultorio</option>
                      <option value="Edificio">Edificio</option>
                      <option value="Local">Local</option>
                      <option value="Lote">Lote</option>
                      <option value="Oficina">Oficina</option>
                    </select>
                    <select
                      {...register("service_type")}
                      className="servicio"
                      id="pais"
                      required
                    >
                      <option value="">Tipo de servicio</option>
                      <option value="Vivienda">Vivienda</option>
                      <option value="Comercio">Comercio</option>
                    </select>
                  </div>
                  <div className="form-control">
                    <textarea
                      {...register("description")}
                      id="description"
                      placeholder="Escríbenos los detalles de tu solicitud"
                      required
                    ></textarea>
                  </div>

                  <div className="form-control is-checkbox">
                    <input
                      type="checkbox"
                      id="data-policy"
                      name="data-policy"
                      required
                    />
                    <label htmlFor="data-policy" className="!text-sm">
                      Autorizo el tratamiento y la{" "}
                      <a className="co-link is-secondary">
                        Política de datospersonales
                      </a>
                    </label>
                  </div>

                  <div className="form-actions">
                    <button className="co-button is-progress-default w-full">
                      Contactar a un asesor
                    </button>
                  </div>
                </form>
              </div>
            )}
            {submitted && (
              <p className='font-bold text-center'>
                Gracias por confiar en nosotros, pronto un asesor se comunicará con
                usted.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
