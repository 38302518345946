import React from "react";

import Banner from "./src/banner";
import Intro from "./src/intro";
import Info from "./src/info";
import LearnMore from "./src/learn-more";

function McvGuide() {
  return (
    <>
      <Banner />
      <Intro />
      <Info />
      <LearnMore />
    </>
  );
}

export default McvGuide;
