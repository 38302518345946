import React from "react";
import clsx from "clsx";

import Tag from "@coninsa-s2/tag";
import FeatureItem from "@coninsa-s2/feature-item";
import Heading from "@coninsa-s2/heading";

import "./styles.css";

function PropertyPopup({
  title,
  price,
  code,
  area,
  bedrooms,
  bathrooms,

  url,
  imageUrl,

  tags: TagsElement,

  className,
}) {
  const classes = {
    "s2-property-popup": true,
  };

  return (
    <a href={url} target="_blank" className={clsx("s2-property-popup", className)}>
      <div className="s2-property-popup__media">
        <img src={imageUrl} alt="Picture" />
      </div>

      <div className="s2-property-popup__content">
        <div className="s2-property-popup__tags">
          <TagsElement />
        </div>

        <Heading as="h3" tint="black" size="md" className="s2-property-popup__title">
          {title}
        </Heading>

        <p className="s2-property-popup__price">{price}</p>

        <div className="s2-property-popup__details">
          <ul className="s2-property-popup__features">
            <li>
              <FeatureItem icon="area">
                <strong>
                  {area} m<sup>2</sup>
                </strong>
              </FeatureItem>
            </li>
            {parseInt(bedrooms) > 0 && (
              <li>
                <FeatureItem icon="bed" className="leading-5">
                  <strong>{bedrooms}</strong>
                </FeatureItem>
              </li>
            )}

            {parseInt(bathrooms) > 0 && (
              <li>
                <FeatureItem icon="bath" className="leading-5">
                  <strong>{bathrooms}</strong>
                </FeatureItem>
              </li>
            )}
          </ul>
          <Tag variant="rounded">Código: {code}</Tag>
        </div>
      </div>
    </a>
  );
}

export default PropertyPopup;
