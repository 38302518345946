import React from 'react'
import PropTypes from 'prop-types'

import PAccordion from './PAccordion'
import PAccordionItem from './PAccordionItem'
import PCard from './PCard'
import PCardIcon from './PCardIcon'
import PCallToAction from './PCallToAction'
import PHero from './PHero'
import PBasicHero from './PBasicHero'
import PHeroSlider from './PHeroSlider'
import PHorizontalSection from './PHorizontalSection'
import PFeaturedList from './PFeaturedList'
import PFeaturedItem from './PFeaturedItem'
import PAwardCard from './PAwardCard'
import PSealCard from './PSealCard'
import PMemberCard from './PMemberCard'
import PSlide from './PSlide'
import PSlider from './PSlider'
import PSection from './PSection'
import PMemberSection from './PMemberSection'
import PVideo from './PVideo'
import PWebform from './PWebform'
import POurProjects from './POurProjects'
import PArchitectureTeam from './PArchitectureTeam'

import PRichText, { RICH_TEXT_PARAGRAPH } from './PRichText'
import PDynamic, { DYNAMIC_PARAGRAPH } from './PDynamic'
import PThreeItemDiagram, { THREE_ITEM_DIAGRAM_PARAGRAPH } from './PThreeItemDiagram'
import PIconList, { ICON_LIST_PARAGRAPH } from './PIconList'

function DefaultParagraph(props) {
  return (
    <pre>
      <code>{JSON.stringify(props, null, 2)}</code>
    </pre>
  )
}

export const paragraphResolver = (props, key) => {
  const paragraphs = {
    accordion: PAccordion,
    accordion_item: PAccordionItem,
    call: PCallToAction,
    card: PCard,
    card_icon: PCardIcon,
    featured_list: PFeaturedList,
    featured_item: PFeaturedItem,
    basic_hero: PBasicHero,
    hero: PHero,
    hero_slider: PHeroSlider,
    horizontal_section: PHorizontalSection,
    section: PSection,
    slide: PSlide,
    slider: PSlider,
    member_section: PMemberSection,
    award_card: PAwardCard,
    seal_card: PSealCard,
    member_card: PMemberCard,
    video: PVideo,
    webform: PWebform,
    our_projects: POurProjects,
    architecture_team: PArchitectureTeam,

    [DYNAMIC_PARAGRAPH]: PDynamic,
    [RICH_TEXT_PARAGRAPH]: PRichText,
    [THREE_ITEM_DIAGRAM_PARAGRAPH]: PThreeItemDiagram,
    [ICON_LIST_PARAGRAPH]: PIconList,
  }

  const bundleType = props?.type || props?.__typename
  const Paragraph = paragraphs[bundleType] || DefaultParagraph

  return <Paragraph key={key} {...props} />
}

paragraphResolver.propTypes = {
  type: PropTypes.string.isRequired,
}

export default {}
