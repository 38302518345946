import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

import ContactServicesForm from '../dynamic/forms/ContactServicesForm'
import ConstructionMicrositeForm from '../dynamic/forms/ConstructionMicrositeForm'

const DefaultForm = props => (
    <Box>
      <pre>
        <code> {JSON.stringify(props, null, 2)}</code>
      </pre>
    </Box>
  )

function PWebform({ id, form, variant, ...props }) {
  const mapForm = {
    construction_microsite: ConstructionMicrositeForm,
    services_contact: ContactServicesForm
  }
  const Form = mapForm[form.id] || DefaultForm

  return <Form key={id} form={form} {...props} />
}

PWebform.defaultProps = {
  variant: 'default',
}

PWebform.propTypes = {
  form: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.string.isRequired,
  variant: PropTypes.string,
}

export default PWebform
