import React from "react";

import SlideBanner from "@coninsa-s2/slide-banner";
import Container from "@coninsa-s2/container";
import FeaturedHeading from "@coninsa-s2/featured-heading";

import firstBannerImage from "../../../images/banner-one.jpg";
import secondBannerImage from "../../../images/banner-two.jpg";
import thirdBannerImage from "../../../images/banner-three.jpg";
import firstBannerMobileImage from "../../../images/banner-mobile-one.jpg";
import secondBannerMobileImage from "../../../images/banner-mobile-two.jpg";
import thirdBannerMobileImage from "../../../images/banner-mobile-three.jpg";

const images = [firstBannerImage, secondBannerImage, thirdBannerImage];

const mobileImages = [
  firstBannerMobileImage,
  secondBannerMobileImage,
  thirdBannerMobileImage,
];

function Banner() {
  return (
    <SlideBanner
      imageUrl={firstBannerImage}
      imageUrlMobile={firstBannerMobileImage}
      slideImages={images}
      mobileSlideImages={mobileImages}
    >
      <Container>
        <FeaturedHeading
          topTitle="Construimos Bienestar,"
          title="transformándonos en un ecosistema"
          bottomTitle="que brinda soluciones integrales para el hábitat."
          className="max-w-2xl"
        ></FeaturedHeading>
      </Container>
    </SlideBanner>
  );
}

export default Banner;
