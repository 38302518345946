import React from 'react'
import PropTypes from 'prop-types'

import { Box } from 'theme-ui'

const SliderTitle = props => {
  const baseStyles = {
    color: 'white',
    fontSize: ['18px', '24px'],
    fontWeight: '500',
    mb: 'small',
  }

  return <Box as='h2' __css={baseStyles} {...props} />
}

SliderTitle.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SliderTitle
