import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

export const THREE_ITEM_DIAGRAM_PARAGRAPH = 'Drupal_ParagraphThreeItemDiagram'

export function mapper({ items, ...data }) {
  const normalizedItems = items.map(item => item?.entity)

  return {
    ...data,

    items: normalizedItems,
  }
}

function PThreeItemDiagram({ title, items, ...props }) {
  return (
    <Box mt='large' {...props}>
      <co-three-item-diagram heading={title}>
        {items.map(item => (
          <co-item-diagram image-url={item.icon.url} heading={item.title}>
            {item.description}
          </co-item-diagram>
        ))}
      </co-three-item-diagram>
    </Box>
  )
}

PThreeItemDiagram.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf().isRequired,
}

export default PThreeItemDiagram
