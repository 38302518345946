import { useState } from 'react'

import locations from './data/locations.json'

const getKeysAsSelectOptions = object =>
  Object.keys(object).map(name => ({
    value: name,
    label: name,
  }))

const getStrAsSelectOptions = arr =>
  arr.map(name => ({
    value: name,
    label: name,
  }))

const departments = getKeysAsSelectOptions(locations)

function LocationField({ children }) {
  const [department, setDepartment] = useState([])
  const [cities, setCities] = useState([])
  const [neighborhoods, setNeighborhoods] = useState([])

  const updateCities = ({ value }) => {
    const filterCities = locations[value]

    setDepartment(value)
    setCities(getKeysAsSelectOptions(filterCities))
    setNeighborhoods([])
  }

  const updateNeighborhoods = ({ value }) => {
    const filterNeighborhoods = locations[department][value]

    setNeighborhoods(getStrAsSelectOptions(filterNeighborhoods))
  }

  return children({
    departments,
    cities,
    neighborhoods,
    updateCities,
    updateNeighborhoods,
  })
}

export default LocationField
