import React from 'react';
import clsx from "clsx";

import "./styles.css";

function Hero({ ...props }) {
  const classes = {
    "s2-hero": true,
  }

  return (
    <div className={clsx(classes)}>
      {props.children}
    </div>
  );
}

function HeroMedia(props) {
  return (
    <div className="s2-hero__media">
      {props.children}
    </div>
  );
}

function HeroContent(props) {
  return (
    <div className="s2-hero__content">
      {props.children}
    </div>
  );
}

Hero.Media = HeroMedia;
Hero.Content = HeroContent;

export default Hero;
