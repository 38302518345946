import React from 'react'
import { Box, Grid, Input } from 'theme-ui'

import { Heading } from 'ui'
import Location from '../../Location'
import Control from '../../components/Control'

function NaturalReferences({ register, control, setValue }) {
  return (
    <Box>
      <Box mb='medium'>
        <Heading level='3' variant='form'>
          Bancaria
        </Heading>
        <Grid gap={4} columns={[2, '1fr, 1fr']}>
          <Control label='Entidad'>
            <Input {...register('fieldBankReference.entity')} type='text' />
          </Control>
          <Control label='Sucursal'>
            <Input {...register('fieldBankReference.branchOffice')} type='text' />
          </Control>
          <Control label='Número de cuenta'>
            <Input {...register('fieldBankReference.accountNumber')} type='text' />
          </Control>
        </Grid>
      </Box>

      <Box
        as='fieldset'
        sx={{ border: '1px solid', borderColor: 'transparent', mb: 'medium' }}
      >
        <Heading level='3' variant='form'>
          Comercial
        </Heading>
        <Grid gap={4} columns={[2, '1fr, 1fr']}>
       
          <Control label='Almacén'>
            <Input {...register('fieldCommercialReference.store')} type='text' />
          </Control>
          <Control label='Teléfono'>
            <Input {...register('fieldCommercialReference.phone')} type='text' />
          </Control>
        </Grid>
      </Box>

      <Box
        as='fieldset'
        sx={{ border: '1px solid', borderColor: 'transparent', mb: 'medium' }}
      >
        <Heading level='3' variant='form'>
          Personal
        </Heading>
        <Grid gap={4} columns={[2, '1fr, 1fr']}>
          <Control label='Nombre'>
            <Input {...register('fieldPersonalName')} type='text' />
          </Control>
          <Control label='Dirección'>
            <Input {...register('fieldPersonalAddress')} type='text' />
          </Control>
          <Control label='Teléfono'>
            <Input {...register('fieldPersonalPhone')} type='text' />
          </Control>
          <Control label='Parentesco'>
            <Input {...register('fieldPersonalRelationship')} type='text' />
          </Control>
          <Control label='Ubicación'>
            <Location
              name='fieldRefPersonalMunicipality'
              setValue={setValue}
              control={control}
            />
          </Control>
        </Grid>
      </Box>

      <Box
        as='fieldset'
        sx={{ border: '1px solid', borderColor: 'transparent', mb: 'medium' }}
      >
        <Heading level='3' variant='form'>
          Familiar
        </Heading>
        <Grid gap={4} columns={[2, '1fr, 1fr']}>
          <Control label='Nombre'>
            <Input {...register('fieldFamilyReference.name')} type='text' />
          </Control>
          <Control label='Dirección'>
            <Input {...register('fieldFamilyReference.address')} type='text' />
          </Control>
          <Control label='Telefono'>
            <Input {...register('fieldFamilyReference.phone')} type='text' />
          </Control>
          <Control label='Parentesco'>
            <Input {...register('fieldFamilyReference.relationship')} type='text' />
          </Control>
          <Control label='Ubicación'>
            <Location
              name='fieldRefFamiliarMunicipality'
              setValue={setValue}
              control={control}
            />
          </Control>
        </Grid>
      </Box>
    </Box>
  )
}
export default NaturalReferences
