export default {
  // Apartamento
  2433: {
    group: "dwelling",
    features: [
      { value: "2516", label: "Amparo de renta" },
      { value: "2478", label: "Entrega Inmediata" },
      { value: "2478", label: "Penthouse" },
      { value: "2552", label: "Reformado(a)" },
      { value: "2506", label: "Ocupado" },
      { value: "2467", label: "Seguridad Privada" },
      { value: "2455", label: "Para Estrenar" },
      { value: "2505", label: "Portería 7 x 12" },
      { value: "2457", label: "Solo Vivienda" },
      { value: "2492", label: "Vista Panorámica" },
      { value: "2452", label: "Portería 7 X 24 H" },
      { value: "2486", label: "Tipo Loft" },
      { value: "2564", label: "Dúplex" },
      { value: "2462", label: "Edificio" },
    ],
  },

  // Casa
  2434: {
    group: "dwelling",
    features: [
      { value: "2467", label: "Seguridad Privada" },
      { value: "2455", label: "Para Estrenar" },
      { value: "2505", label: "Portería 7 x 12" },
      { value: "2457", label: "Solo Vivienda" },
      { value: "2492", label: "Vista Panorámica" },
      { value: "2452", label: "Portería 7 X 24 H" },
      { value: "2486", label: "Tipo Loft" },
      { value: "2564", label: "Dúplex" },
      { value: "2462", label: "Edificio" },
    ],
  },

  // Bodega
  2435: {
    group: "commerce",
    features: [
      { value: "2445", label: "Conjunto cerrado" },
      { value: "2559", label: "Cortina metalica" },
      { value: "2588", label: "Energia Trifásica" },
      { value: "2478", label: "Entrega Inmediata" },
      { value: "2589", label: "Patio de Maniobras" },
      { value: "2452", label: "Portería 7 X 24 horas" },
      { value: "2505", label: "Portería 7 x 12 horas" },
      { value: "2503", label: "Obra Gris" },
      { value: "2506", label: "Actualmente Ocupado" },
      { value: "2455", label: "Para Estrenar" },
    ],
  },

  // Local
  2436: {
    group: "commerce",
    features: [
      { value: "2445", label: "Conjunto cerrado" },
      { value: "2559", label: "Cortina metalica" },
      { value: "2588", label: "Energia Trifásica" },
      { value: "2478", label: "Entrega Inmediata" },
      { value: "2469", label: "Esquinero" },
      { value: "2455", label: "Para Estrenar" },
      { value: "2452", label: "Portería 7 x 24 horas" },
      { value: "2505", label: "Portería 7 x 12 horas" },
    ],
  },

  // Oficina
  2440: {
    group: "commerce",
    features: [
      { value: "2462", label: "Edificio" },
      { value: "2445", label: "Conjunto cerrado" },
      { value: "2492", label: "Vista Panorámica" },
      { value: "2478", label: "Entrega Inmediata" },
      { value: "2469", label: "Esquinero" },
      { value: "2503", label: "Obra Gris" },
      { value: "2455", label: "Para Estrenar" },
      { value: "2452", label: "Portería 7 x 24 horas" },
      { value: "2505", label: "Portería 7 x 12 horas" },
    ],
  },

  // Consultorio
  2437: {
    group: "commerce",
    features: [
      { value: "2503", label: "Obra Gris" },
      { value: "2455", label: "Para Estrenar" },
      { value: "2452", label: "Portería 7 x 24 horas" },
      { value: "2492", label: "Vista Panorámica" },
      { value: "2552", label: "Reformado(a)" },
      { value: "2478", label: "Entrega Inmediata" },
    ],
  }
};
