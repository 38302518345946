import mediaImageMapper from './mediaImageMapper'

function heroSliderMapper({ background, items, ...entity }) {
  return {
    type: 'hero_slider',
    items: items.map(mediaImageMapper),
    background: background?.entity?.image || {},

    ...entity,
  }
}

export default heroSliderMapper
