import React, { Children, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import SliderSlick from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Box } from 'theme-ui'

import PrevArrow from './PrevArrow'
import { NextArrow } from './NextArrow'
import { Arrow } from './Arrow'

function Slider({
  sx,
  customArrows,
  children,
  variant,
  arrowsPosition,
  autoHeight,
  ...props
}) {
  const ref = useRef()
  const slider = useRef()
  const [height, setHeight] = useState()
  const { dots, arrows } = props

  useEffect(() => {
    if (autoHeight) {
      setTimeout(() => {
        const calcHeight = ref.current.offsetHeight - 24

        setHeight(calcHeight)
      }, 1000)
    }
  }, [])

  const arrowsPositionStylesMap = {
    bottom: {
      mb: ['xsmall', '35px'],
      '.slick-next': {
        right: 'small',
        top: 'auto',
        bottom: '-40px',
      },

      '.slick-prev': {
        left: 'small',
        top: 'auto',
        bottom: '-40px',
      },

      '.slick-dots': {
        bottom: '-30px',
      },
    },

    center: {
      mx: [null, 'large'],

      '.slick-next': {
        right: '-45px',
      },
      '.slick-prev': {
        left: '-45px',
      },
    },
  }

  const arrowsPositionStyles = arrows ? arrowsPositionStylesMap[arrowsPosition] : {}

  const baseStyles = {
    '.slick-list': {
      pb: dots ? 'medium' : null,
    },

    '.slick-dots li.slick-active button:before': {
      color: 'accent',
      opacity: 1,
    },

    '.slick-dots li button:before': {
      fontSize: 'medium',
      opacity: 1,
    },

    '.slick-slide > div > div:focus': {
      outline: 'none',
    },

    '.slick-prev, .slick-next': {
      width: '45px',
      height: '25px',
      zIndex: 1,
    },

    '.slick-disabled': {
      visibility: 'hidden',
    },

    ...arrowsPositionStyles,

    ...sx,
  }

  const innerItemStyles = {
    height,
    '& > div': {
      height: height ? '100%' : null,
      mx: 2,
    },
  }

  const items = Children.map(children, item => (
    <Box sx={innerItemStyles} min={height}>
      {item}
    </Box>
  ))

  const next = () => slider.current.slickNext()
  const previous = () => slider.current.slickPrev()

  return (
    <Box __css={baseStyles} variant={variant} __themeKey='sliders'>
      <Box ref={ref} sx={{ position: 'relative' }}>
        <SliderSlick ref={slider} calcHeight={height} {...props}>
          {items}
        </SliderSlick>

        {customArrows && (
          <Box sx={{ position: 'absolute', right: '8px', bottom: [100, 80] }}>
            <Arrow icon='fa-arrow-left' onClick={previous} />
            <Arrow icon='fa-arrow-right' onClick={next} />
          </Box>
        )}
      </Box>
    </Box>
  )
}

Slider.defaultProps = {
  arrows: true,
  arrowsPosition: 'center',
  autoHeight: true,
  customArrows: false,
  dots: false,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        slidesToShow: 1,
      },
    },
  ],
  variant: 'default',
  sx: {},
}

Slider.propTypes = {
  arrows: PropTypes.bool,
  arrowsPosition: PropTypes.string,
  autoHeight: PropTypes.bool,
  children: PropTypes.node.isRequired,
  customArrows: PropTypes.bool,
  dots: PropTypes.bool,
  nextArrow: PropTypes.node,
  prevArrow: PropTypes.node,
  responsive: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
}

export default Slider
