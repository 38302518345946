import React from 'react'

import AlianzasPage from 'modules/alianzas/page'
import Layout from '../layout/default/Layout'

const Page = () => (
  <Layout>
    <AlianzasPage />
  </Layout>
)

export default Page
