import React from "react";
import GoogleAnalytics from "../analytics";
import Hotjar from "../hotjar";

import Banner from "../sections/banner";
import Services from "../sections/services";
import WeAre from "../sections/we-are";
import OurExperience from "../sections/our-experience";
import QuickAccess from "../sections/quick-access";
import LatestBlogs from "../sections/latest-blog";

function Alpha() {
  return (
    <>
      <GoogleAnalytics trackingId="G-NZLTLY9W18" />
      <Hotjar trackingId="5040308" />
      <Banner />
      <Services />
      <WeAre />
      <OurExperience />
      <LatestBlogs />
      <QuickAccess />
    </>
  );
}

export default Alpha;
