import projectIcon from "./icons/home.svg";
import propertyIcon from "./icons/home-file.svg";

export default [
  {
    id: "referral-item-1",
    icon: projectIcon,
    text: "Cuéntales a tus amigos o familiares",
    highlighted: "acerca de nuestros proyectos de vivienda nueva",
    url: "/referidos/proyectos",
  },
  {
    id: "referral-item-2",
    icon: propertyIcon,
    text: "Cuéntales a tus amigos o familiares",
    highlighted: "acerca de nuestros inmuebles en arriendo o venta",
    url: "/referidos/arrendamientos",
  },
];
