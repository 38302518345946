/** @jsx jsx */
import { jsx } from 'theme-ui'
import DefaultSelect from 'react-select'

const Select = ({ variant, sx, ...props }) => {
  const variantStyles = {
    default: {
      '& .select__control': {
        minHeight: '50px',
      },

      '& .select__control--is-disabled': {
        opacity: '0.85',
      },

      '& .select__control--is-focused': {
        borderColor: 'border',
        boxSizing: 'border-box',
        boxShadow: '0 0 0 1px rgb(72, 185, 199)',
      },
    },

    large: {
      '& .select__control': {
        minHeight: ['60px', '70px'],
        borderRadius: ['0 0 7px 7px', '0'],
      },

      '& .select__control--is-disabled': {
        opacity: '0.85',
      },

      '& .select__control--is-focused': {
        borderColor: 'border',
        boxSizing: 'border-box',
        boxShadow: '0 0 0 1px rgb(72, 185, 199)',
      },

      '& .select__indicator-separator': {
        display: 'none',
      },
    },

    'search-left': {
      '& .select__control': {
        minHeight: ['60px', '70px'],
        borderRadius: ['7px 7px 0 0', '7px 0 0 7px'],
      },

      '& .select__indicator-separator': {
        display: 'none',
      },
    },
  }

  const styles = variantStyles[variant]

  return <DefaultSelect {...props} sx={{ ...styles, ...sx }} />
}

Select.defaultProps = {
  classNamePrefix: 'select',
  variant: 'default',
  sx: {},
}

export default Select
