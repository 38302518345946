import { gql } from '@apollo/client'

export const WEBFORM_SUBMIT = gql`
  mutation webformSubmit($values: String!) {
    submitForm(values: $values) {
      errors
      submission {
        id
      }
    }
  }
`

export const UPDATE_WEBFORM_SUBMIT = gql`
  mutation upadteWebformSubmit($id: String!, $values: String!) {
    updateForm(id: $id, values: $values) {
      errors
      submission {
        id
      }
    }
  }
`
