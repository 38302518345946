import { CalculatorComponent } from "../../ng-app/components/calculator.component";
import { CotizadorService } from "../../ng-app/shared/services/cotizador.service";
import { QuotationCurrencyConvert } from "../../ng-app/shared/services/quotationCurrencyConvert.service";
import { CurrencyConvertService } from "../../ng-app/shared/services/currencyConvert.service";
import { ClienteService } from "../../ng-app/cliente/services/cliente.service";

function normalizeUser(data) {
  const user = {
    id: '',
    primerNombre: data.names,
    segundoNombre: '',
    primerApellido: data.last_name,
    segundoApellido: '',
    cedula: data.document,
    telefono: data.phone,
    email: data.email,
    type_document: data.document_type,
    module: '',
  };

  return user;
}

/**
 * Boot calculator setup.
 *
 * @returns calculator object.
 */
export function bootCalculator({ quotation, form, applyVariables = false }) {
  const calculator = new CalculatorComponent(
    new CotizadorService(
      new QuotationCurrencyConvert(new CurrencyConvertService())
    ),
    new ClienteService(),
    {
      unit: quotation.unit,
      project: quotation.project,
      credit_parameters: quotation.credit_parameters,
      user: normalizeUser(form.user),
      quote_concepts: quotation.quote_concepts,
    }
  );

  calculator.modificarAdicionales();
  calculator.calcularCotizacion(quotation.unit.valor);

  if (form.additional_units?.length > 0) {
    const selectedUnits = quotation.other_units.filter(
      (item) => item.idUnidad && form.additional_units.includes(item.idUnidad)
    );
    calculator.selectedUnits = calculator.selectedUnits.concat(selectedUnits);
    calculator.modificarAdicionales();
  }

  if (applyVariables && form.separation_value > 0) {
    calculator.valorSeparacionVariable = form.separation_value;
    calculator.recalcularCotizacion();
  }

  return calculator;
}
