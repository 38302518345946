export const baseTabsStyles = {}

// Tabs styles.
export const getTabsHeaderStyles = hideDivider => {
  const dividerStyles = !hideDivider
    ? { borderBottom: '1px solid', borderColor: 'border' }
    : {}

  return {
    display: 'flex',
    mb: 'small',
    overflowY: 'hidden',
    overflowX: 'auto',
    pb: 'xsmall',
    ...dividerStyles,
  }
}

// Tabs Item styles.
export const getBaseTabsItemStyles = disabled => {
  const disabledStyles = disabled ? { opacity: 0.5, cursor: 'not-allowed' } : {}

  return {
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',
    fontSize: 'medium',
    mx: 'xsmall',
    position: 'relative',
    px: 'xsmall',
    py: 'xsmall',
    flexShrink: 0,

    ...disabledStyles,

    '&:first-of-type': {
      ml: 0,
    },

    '&::after': {
      content: '""',
      bg: 'transparent',
      display: 'block',
      position: 'absolute',
      right: 0,
      bottom: -1,
    },

    '&.active::after': {
      bg: 'accent',
    },
  }
}
