import React from 'react'
import { Box, Grid, Input, Label, Radio } from 'theme-ui'

import Control from '../../components/Control'

import {
  getOptions,

  // Options
  salesResponsibles
} from '../options'

function BasicTributaryInformation({ register }) {
  return (
    <Grid gap={4} columns={[2, '1fr 1fr']}>
      <Control label='Responsable de ventas'>
        <Box sx={{ display: 'flex' }}>
          {getOptions(salesResponsibles).map(option => (
            <Label>
              <Radio {...register('fieldSalesResponsible')} value={option.value} />
              {option.label}
            </Label>
          ))}
        </Box>
      </Control>
      <Control label='Responsable de rentas' required>
        <Box sx={{ display: 'flex' }}>
          {['No', 'Si'].map((label, index) => (
            <Label>
              <Radio
                {...register('fieldIncomeResponsible')}
                value={index}
                required
              />
              {label}
            </Label>
          ))}
        </Box>
      </Control>
      <Control label='Autoretenedor' required>
        <Box sx={{ display: 'flex' }}>
          {['No', 'Si'].map((label, index) => (
            <Label>
              <Radio
                {...register('fieldSelfRetainer')}
                key={`fieldSelfRetainer:${index}`}
                required
                value={index}
              />
              {label}
            </Label>
          ))}
        </Box>
      </Control>

      <Control label='Gran contribuyente' required>
        <Box sx={{ display: 'flex' }}>
          {['No', 'Si'].map((label, index) => (
            <Label>
              <Radio {...register('fieldGreatTaxpayer')} required value={index} />
              {label}
            </Label>
          ))}
        </Box>
      </Control>
      <Control label='Responsable de ICA (%)'>
        <Input
          {...register('fieldIcaResponsible')}
          type='number'
          min='10'
          max='100'
        />
      </Control>
    </Grid>
  )
}

export default BasicTributaryInformation
