import React from 'react'
import PropTypes from 'prop-types'

import { Box, Container } from 'theme-ui'

const FeaturedList = ({ children, ...props }) => {
  const baseStyles = {
    bg: '#FAFAFA',
    px: 'medium',
    py: ['large', 'large'],
  }
  return (
    <Box __css={baseStyles} {...props}>
      <Container sx={{ maxWidth: '800px' }}>{children}</Container>
    </Box>
  )
}

FeaturedList.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FeaturedList
