import { useStaticQuery, graphql } from 'gatsby'
import { paragraphResolver } from '../../paragraphs'

export const awardMapper = data => {
  const { body, date, title, gatsbyPictureFile, fieldPicture, ...others } = data
  const temporalDate = new Date(date?.value)
  const year = temporalDate.getFullYear()

  return {
    body: body?.value,
    title,
    type: 'award_card',
    year,
    ...others,
  }
}

function OurAwards() {
  const data = useStaticQuery(graphql`
    query OurAwards {
      drupal {
        query: nodeQuery(
          filter: {
            conditions: [
              { field: "type", value: ["award"] }
              { field: "field_microsite", operator: IS_NULL }
              { field: "status", value: ["1"] }
            ]
          }
          sort: [{ field: "field_date", direction: DESC }]
          limit: 10000
        ) {
          awards: entities {
            id: entityId
            ... on Drupal_NodeAward {
              title
              body {
                value
              }
              image: fieldPicture {
                alt
                derivative(style: IMAGECARD) {
                  url
                }
              }
              date: fieldDate {
                value
              }
              created
            }
          }
        }
      }
    }
  `)

  const rawAwards = data?.drupal?.query?.awards || []
  const awards = rawAwards.map(awardMapper)

  const sliderStructure = {
    arrowsPosition: 'bottom',
    autoplay: true,
    autoplaySpeed: 10000,
    items: awards,
    slidesToScroll: 3,
    slidesToShow: [1, 3],
    type: 'slider',
    variant: 'colored',
    responsive: [
      {
        breakpoint: 480,
        settings: {
          dots: false,
          slidesToScroll: 1,
          slidesToShow: 1,
        },
      },
    ],
  }

  return paragraphResolver(sliderStructure)
}

export default OurAwards
