import React from "react";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Heading from "@coninsa-s2/heading";
import NumericCard from "@coninsa-s2/numeric-card";

import data from "./data";

export default function MoreInfo() {
  return (
    <Section ptop="small">
      <Container>
        <div className="text-center">
          <Heading size="4xl" tint="dark-blue" className="!mb-6" condensed>
            <span className="text-s2-teal font-extrabold block">
              El proceso lo puedes hacer totalmente fácil y rápido
            </span>
            <span className="text-4xl">Solo necesitas tener listo:</span>
          </Heading>
        </div>
        <div className="grid gap-2 grid-cols-1 lg:grid-cols-3">
          {data.map((item) => (
            <div className="px-2 pt-2 pb-4">
              <NumericCard class="s2-numeric-card">
                <div class="s2-numeric-card__number">{item.label}</div>
                <div class="s2-numeric-card__content">
                  <h4 class="s2-numeric-card__title">{item.title}</h4>
                </div>
              </NumericCard>
            </div>
          ))}
        </div>
      </Container>
    </Section>
  );
}
