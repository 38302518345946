import React from 'react'
import { navigate } from 'gatsby'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { Box } from 'theme-ui'

import { Collapse, Heading } from 'ui'
import { UPDATE_NODE_NATURAL_PERSON_COTENANT } from '../../mutations'
import { NATURAL_COTENANT_APPLICATION } from '../../queries'

import BasicOccupation from '../generic/BasicOccupation'
import BasicAdditionalInformation from '../generic/BasicAdditionalInformation'
import BasicSpouseInformation from '../generic/BasicSpouseInformation'
import FooterForm from '../../components/FooterForm'
import HeaderForm from '../../components/HeaderForm'
import { getEntity, cleanData } from '../utils'

function AdditionalInformation(props) {
  const loadedCotenant = props?.loadedCotenant || {}
  const [mutation] = useMutation(UPDATE_NODE_NATURAL_PERSON_COTENANT, {
    refetchQueries: [
      { query: NATURAL_COTENANT_APPLICATION, variables: { id: props.cid } }
    ]
  })
  const { register, handleSubmit, control, setValue } = useForm({
    defaultValues: loadedCotenant
  })

  const onSubmit = async data => {
    const variables = cleanData(data)

    return mutation({ variables })
      .then(response => {
        const entity = getEntity(response)

        if (entity) {
          navigate('?path=/informacion-financiera')
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <section>
      <HeaderForm step='3/5' title='Información Adicional' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Collapse variant='grayly'>
          <Collapse.Item title='Ocupación' index={1}>
            <BasicOccupation register={register} />
          </Collapse.Item>
        </Collapse>

        {/* --- Temporarily hidden the additional information section. ---
          <Box mb='medium'>
            <Heading level='3' variant='form'>
              Información Adicional
            </Heading>
            <BasicAdditionalInformation register={register} />
          </Box>
        */}

        <Collapse variant='grayly'>
          <Collapse.Item title='Información Cónyuge' index={1}>
            <BasicSpouseInformation
              register={register}
              control={control}
              setValue={setValue}
            />
          </Collapse.Item>
        </Collapse>
        <FooterForm back />
      </form>
    </section>
  )
}

export default AdditionalInformation
