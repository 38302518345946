import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

import { Section, Header } from 'ui'
import { paragraphResolver } from '.'

function PHorizontalSection({ header, content, variant, ...props }) {
  const headerComponent = (
    <Header
      headline={header.headline}
      sx={{ alignSelf: 'center' }}
      variant={variant}
    >
      {header.title}
    </Header>
  )

  return (
    <Section
      title={headerComponent}
      direction='horizontal'
      variant={variant}
      {...props}
    >
      <Box>{content.map(paragraph => paragraphResolver(paragraph))}</Box>
    </Section>
  )
}

PHorizontalSection.defaultProps = {
  content: [],
  variant: undefined,
}

PHorizontalSection.propTypes = {
  content: PropTypes.arrayOf(),
  header: PropTypes.shape({
    headline: PropTypes.string,
    title: PropTypes.string.isRequired,
  }).isRequired,
  variant: PropTypes.string,
}
export default PHorizontalSection
