import rawDepartments from '../data/departments.json'

function DepartmentsField({ children }) {
  const departments = rawDepartments.map(item => item.name)

  const getCities = department => {
    if (!department) return []

    const deparment = rawDepartments.find(item => item.name === department)
    return deparment.cities
  }

  return children(departments, getCities)
}

export default DepartmentsField
