import React, { useContext, useState } from "react";
import PropTypes, { bool } from "prop-types";
import { Box, Grid, Image, Paragraph, Text } from "theme-ui";

import { Divider, Header, Icon, Section, Tabs, Button } from "ui";
import { CurrencyContext } from "modules/currency/CurrencyContext";
import CurrencyPrice from "modules/currency/CurrencyPrice";
import HorizontalTab from "../components/HorizontalTab";
import { formatPrice } from "../utils";
import MoreInfo from "./ProjectMoreInfo";
import SimulatorForm from "./SimulatorForm";
import Quotation from "@coninsa-ui/quotation";
import PaymentPlan from "@coninsa-ui/payment-plan";
import Cloud from "@coninsa-s2/cloud";

import defaultPlanImg from "../../../images/default-plan-image.jpg";

function IdealPlace({
  buildingLocation,
  method,
  projectPath,
  codeProject,
  pdfLink,
  tabs,
  terms,
  number,
  code,
  id,
  simulatorScript,
  isSoldOut,
  state,
  zohoDetail,
  ...props
}) {
  const whatsapp = "573123636333";
  const whatsappMsg = `Hola! los vi en coninsa.co, me gustaría obtener más información. Proyecto: ${code}`;
  const itemStyles = {
    display: "flex",
    flexDirection: ["column", "row"],
    gap: ".8rem",
    justifyContent: "space-around",
    mb: "small",
    "co-tab-item": {
      borderBottom: ["1px solid #ededed", "none"],
    },
    "co-tab-item:last-child": {
      borderBottom: "none",
    },
  };

  const currencySettings = useContext(CurrencyContext);

  const [open, setOpen] = useState(false);
  const showForm = () => setOpen(true);

  return (
    <Section title={<Header>Escoge tu lugar ideal</Header>} {...props}>
      <Tabs variant="rounded">
        {tabs.map((tab) => (
          <Tabs.Item label={tab.name}>
            <Tabs as="nav" sx={{ justifyContent: "center", color: "dark-4" }}>
              {tab?.areas.map((area) => {
                const hasQuotaInformation =
                  area?.property?.separationQuota ||
                  (area?.property?.quotasNumber &&
                    area?.property?.totalAmountOfQuotas) ||
                  area?.property?.remainingValue;

                const price =
                  (area?.property?.saleValue || 0) / currencySettings.divider;

                return (
                  <Tabs.Item label={`${area?.title} m²`}>
                    <Grid
                      sx={{ textAlign: "center" }}
                      columns={["1fr", "2fr 1fr"]}
                    >
                      {area?.property?.plans?.length === 0 && (
                        <Image src={defaultPlanImg} />
                      )}

                      {area.property?.plans?.length > 0 && (
                        <Image src={area?.property?.plans?.[0]?.url} />
                      )}

                      <Box>
                        <Box
                          sx={{
                            color: "neutral",
                            fontSize: 4,
                            fontWeight: "semibold",
                            my: 2,
                          }}
                        >
                          {area?.property?.type?.entity?.name} de{" "}
                          {area?.property?.area}
                          m²
                        </Box>

                        <Grid columns={[2, 4]} mb={4}>
                          {area?.property?.code && (
                            <HorizontalTab icon="gr-tag" label="Código:">
                              {area?.property?.code}
                            </HorizontalTab>
                          )}
                          {area?.property?.area && (
                            <HorizontalTab icon="bi-area" label="Área:">
                              {area?.property?.area} m²
                            </HorizontalTab>
                          )}
                          {area?.property?.bedrooms && (
                            <HorizontalTab icon="bi-bed" label="Alcobas:">
                              {area?.property?.bedrooms}
                            </HorizontalTab>
                          )}
                          {area?.property?.bathrooms > 0 && (
                            <HorizontalTab icon="bi-bath" label="Baños:">
                              {area?.property?.bathrooms}
                            </HorizontalTab>
                          )}
                        </Grid>

                        <Box slot="description" mb="1rem">
                          <CurrencyPrice isCenter />
                        </Box>

                        <Box
                          sx={{
                            bg: "light-0",
                            display: "flex",
                            justifyContent: "space-around",
                            mb: "1.5rem",
                            py: "xsmall",
                            verticalAlign: "middle",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Icon name="bi-wallet" mr={2} />
                            <Text sx={{ fontSize: 2 }}>Valor desde:</Text>
                          </Box>
                          <Box
                            sx={{
                              color: "dark-0",
                              fontFamily: "heading",
                              fontSize: 5,
                              fontWeight: "heading",
                            }}
                          >
                            {formatPrice(price, currencySettings.symbol)}
                          </Box>
                        </Box>

                        <Paragraph sx={{ fontSize: 0 }} mb={3}>
                          {area?.property?.smmlvNote}
                        </Paragraph>

                        {/* {hasQuotaInformation && (
                          <Box mb="small">
                            <PaymentPlan
                              projectCode={code}
                              propertyCode={area?.property?.code}
                            />
                          </Box>
                        )} */}

                        {area?.property?.separationQuota !== null && (
                          <Box mb="small">
                            <Box
                              sx={{
                                color: "neutral",
                                fontSize: 0,
                                fontWeight: "semibold",
                                mb: "1.2rem",
                                textAlign: "center",
                              }}
                            >
                              Forma de Pago para la torre o etapa señalada
                            </Box>

                            <Box sx={itemStyles}>
                              {area?.property?.separationQuota !== null && (
                                <co-tab-item
                                  label="Cuota de separación"
                                  value={`$ ${area?.property?.separationQuota}`}
                                />
                              )}
                              {area?.property?.totalAmountOfQuotas !== null && (
                                <co-tab-item
                                  label={`${area?.property?.quotasNumber} de cuota(s) mensuales`}
                                  value={`$ ${area?.property?.totalAmountOfQuotas}`}
                                />
                              )}
                              {area?.property?.remainingValue !== null && (
                                <co-tab-item
                                  label="Valor restante"
                                  value={`$ ${area?.property?.remainingValue}`}
                                />
                              )}
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                    <Box sx={{ fontSize: 0, mb: 4, textAlign: "justify" }}>
                      <Paragraph mb={3}>
                        Esta información corresponde al plan de pagos sugerido
                        para esta torre, que toma siempre el precio desde del
                        inmueble de menor valor y que podría modificarse de
                        acuerdo a sus necesidades.{" "}
                        <strong>¡Cuéntenos su forma de pago predilecta!</strong>
                        .
                      </Paragraph>

                      <Paragraph mb={3}>
                        {area?.property?.explanatoryNote}
                      </Paragraph>
                      <Paragraph>
                        Los precios y condiciones de venta pueden ser
                        modificados sin previo aviso.{" "}
                        <Box
                          as="a"
                          sx={{ color: "green-1", fontWeight: "heading" }}
                          href={terms}
                          target="_blank"
                        >
                          Ver términos y condiciones
                        </Box>
                      </Paragraph>
                    </Box>

                    <div>
                      <Cloud
                        iconDirection="left"
                        iconPosition="left-bottom"
                        tint="teal"
                        style={{ "--cloud--height": "auto" }}
                      >
                        <div className="py-2 lg:py-3 text-center text-lg lg:text-xl font-bold text-white">Simula tu forma de pago</div>
                      </Cloud>
                      <Quotation
                        projectCode={tab?.code}
                        propertyCode={area?.property?.code}
                        zohoDetail={zohoDetail}
                      />
                    </div>

                    <Divider />

                    {simulatorScript && (
                      <div
                        dangerouslySetInnerHTML={{ __html: simulatorScript }}
                      />
                    )}

                    {state?.machineName !== "sold_out" && (
                      <MoreInfo
                        code={code}
                        isSoldOut={isSoldOut}
                        adviserUrl={`https://api.whatsapp.com/send?phone=${whatsapp}&text=${whatsappMsg}`}
                        pdfUrl={pdfLink}
                        creditUrl={`https://simulador.coninsa.co?idProject=${tab?.code}&idUnit=${area?.property?.code}&campaign=Ficha de proyectos`}
                      />
                    )}
                  </Tabs.Item>
                );
              })}
            </Tabs>
          </Tabs.Item>
        ))}
      </Tabs>
    </Section>
  );
}

IdealPlace.defaultProps = {
  method: "",
  note: "",
  pdfLink: "#",
  simulatorScript: "",
  terms: "",
};

IdealPlace.propTypes = {
  buildingLocation: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  method: PropTypes.string,
  note: PropTypes.string,
  number: PropTypes.string.isRequired,
  projectPath: PropTypes.string.isRequired,
  codeProject: PropTypes.string.isRequired,
  pdfLink: PropTypes.string,
  simulatorScript: PropTypes.string,
  isSoldOut: PropTypes.bool.isRequired,
  state: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.array).isRequired,
  terms: PropTypes.string,
};

export default IdealPlace;
