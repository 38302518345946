import React, { useState, useRef } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Box, Input, Textarea } from 'theme-ui'
import { useMutation } from '@apollo/client'

import { compose, filter, join, values } from 'ramda'
import { nFile } from 'modules/digital-studio/forms/utils'
import { Button } from 'ui'
import { Field, Select, InputMask } from 'ui/form'
import Toast from 'ui/Toast'
import Control from '../../digital-studio/components/Control'
import FilesInput from '../../file/FilesInput'

import TermsAndConditions from '../components/TermsAndConditions'
import Location from '../../digital-studio/Location'
import { WEBFORM_SUBMIT } from '../mutations'

const nLocation = compose(
  join(', '),
  filter(i => i),
  values
)

const options = [
  { value: 'Felicitación', label: 'Felicitación' },
  { value: 'Comentario', label: 'Comentario' },
  { value: 'Queja', label: 'Queja' },
  { value: 'Sugerencia', label: 'Sugerencia' },
  { value: 'Asesoría comercial', label: 'Asesoría comercial' },
  { value: 'Deseo contactarme con', label: 'Deseo contactarme con' },
  { value: 'Solicitud de certificados', label: 'Solicitud de certificados' },
  { value: 'Solicitud de planos', label: 'Solicitud de planos' },
  { value: 'Blog - sala de prensa', label: 'Blog - sala de prensa' },
  { value: 'Otros', label: 'Otros' },
]

const defaultValues = {
  contactType: '',
  phone: '',
}

const SuggestionsForm = () => {
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm({
    defaultValues,
  })

  const [showingToast, showToast] = useState(false)
  const [toastSettings, setToastSettings] = useState({ position: 'bottom-right' })
  const [webformSubmit] = useMutation(WEBFORM_SUBMIT)

  const list = [
    {
      id: 's1',
      title: 'Gracias!',
      description: 'Su información fue enviada con éxito.',
      backgroundColor: '#85bb25',
      icon: 'success',
    },
  ]

  const formRef = useRef(null)

  const calcToastSettings = () => {
    if ('parentIFrame' in window) {
      const offsetTop = formRef.current.offsetHeight + 360
      setToastSettings({ position: 'top-right', offsetTop })
    }
  }

  const onSubmit = async data => {
    const { department } = data.location
    const { fullName, email, phone, project, apartment, contactType, comments } =
      data

    const normalizedData = {
      full_name: fullName,
      attach_support: data.attachSupport.map(nFile).map(item => item.targetId),
      email,
      phone,
      location: nLocation(data.location),
      department,
      project,
      apartment,
      contact_type: contactType,
      comments,
      webform_id: 'suggestions',
    }

    const webformData = JSON.stringify(normalizedData)

    return new Promise((resolve, reject) => {
      webformSubmit({
        variables: {
          values: webformData,
        },
      })
        .then(response => {
          const { errors: errorsForm } = response.data.submitForm

          if (errorsForm.length) {
            // @TODO Render errors
            console.error(errorsForm)
            return
          }

          reset()
          calcToastSettings()
          showToast(true)
          resolve(response)
        })
        .catch(error => {
          console.error(error.message)
          reject(error)
        })
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
      {showingToast && (
        <Toast
          toastList={list}
          autoDelete
          autoDeleteTime='3000'
          {...toastSettings}
        />
      )}

      <p>
        Diligencia el formulario completamente para asegurarnos de brindarte el mejor
        servicio y comunicarnos contigo.
      </p>

      <Field>
        <Input
          placeholder='Tu nombre completo'
          type='text'
          {...register('fullName')}
          required
        />
      </Field>

      <Box sx={{ display: [null, 'flex'] }}>
        <Field sx={{ width: [null, '50%'], mr: [null, 2] }}>
          <Controller
            control={control}
            name='phone'
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <InputMask
                placeholder='Teléfono de contacto'
                type='tel'
                format='+57 (###) ###-####'
                mask='_'
                onBlur={onBlur}
                onChange={onChange}
                checked={value}
                inputRef={ref}
                required
              />
            )}
          />
        </Field>

        <Field sx={{ width: [null, '50%'], ml: [null, 2] }}>
          <Input
            placeholder='Tu correo electrónico'
            type='email'
            {...register('email')}
            required
          />
        </Field>
      </Box>

      <Location
        placeholders={{
          department: '¿En qué departamento estás ubicado?',
          city: '¿En qué ciudad estás ubicado?',
          neighborhood: '¿En qué barrio estás ubicado?',
        }}
        name='location'
        setValue={setValue}
        control={control}
      />

      <Box sx={{ display: [null, 'flex'] }}>
        <Field sx={{ width: [null, '75%'], mr: [null, 2] }}>
          <Input
            placeholder='¿Cuál es tu proyecto?'
            type='text'
            {...register('project')}
          />
        </Field>

        <Field sx={{ width: [null, '35%'], ml: [null, 2] }}>
          <Input placeholder='Apartamento' type='text' {...register('apartment')} />
        </Field>
      </Box>

      <Field>
        <Controller
          control={control}
          name='contactType'
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Select
              onBlur={onBlur}
              checked={value}
              inputRef={ref}
              options={options}
              control={control}
              placeholder='Selecciona un tipo de contacto'
              onChange={selected => {
                if (selected) {
                  onChange(selected.value)
                }
              }}
            />
          )}
        />
      </Field>

      <Control label='Adjuntar soporte'>
        <FilesInput
          name='attachSupport'
          control={control}
          accept='.txt,.pdf,.doc,.docx,.jpg,.png'
        />
      </Control>

      <Field>
        <Textarea
          rows={5}
          placeholder='Escribe tu comentario, dudas o preguntas.'
          {...register('comments')}
        />
      </Field>

      <TermsAndConditions />

      <Button
        sx={{ width: ['100%', 'auto'] }}
        disabled={isSubmitting}
        loading={isSubmitting}
      >
        ENVIAR FORMULARIO
      </Button>
    </form>
  )
}

export default SuggestionsForm
