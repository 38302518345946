import React from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import { Box } from 'theme-ui'

function Video({ url, variant, ...props }) {
  const { controls } = props
  return (
    <Box __themeKey='videos' variant={variant} {...props}>
      <ReactPlayer url={url} controls={controls} width='100%' height='100%' />
    </Box>
  )
}

Video.defaultProps = {
  controls: 'default',
  variant: 'default',
  url: '',
}

Video.propTypes = {
  controls: PropTypes.string,
  variant: PropTypes.string,
  url: PropTypes.string,
}

export default Video
