import React from 'react'
import IframeResizer from 'iframe-resizer-react'
import { Helmet } from 'react-helmet'
import Layout from '../layout/default/Layout'

import banner from '../modules/home/images/develop-your-town.jpg'
const mBanner =
  'https://d2xt7650da4t25.cloudfront.net/s3fs-public/2023-08/desarrolamos-tu-tierra-mobile.jpg?VersionId=qHy5MZqMgWb36w9yMlU8dgWGgX64tQri'

const SITE_URL = 'https://busquedas.coninsa.co'

function soilDevelopment() {
  return (
    <Layout>
      <Helmet>
        <title>Desarrollamos tu tierra | Coninsa</title>
        <meta name="description" content="Actualmente estamos interesados en terrenos ubicados en Cundinamarca, Antioquia y Costa Atlántica, buscamos predios preferiblemente urbanos para el desarrollo de vivienda en altura, con áreas mínimas de 3.000 m2 para sectores consolidados y áreas superiores a 5.000 m2 para lotes en tratamiento de desarrollo." />
        <link rel="canonical" href="https://www.coninsa.co/desarrollamos-tu-tierra/" />
      </Helmet>
      <div>
        <img className='block md:hidden w-full' src={mBanner} />
        <img className='hidden md:block' src={banner} />
      </div>
      <div className='container px-5 md:px-0 py-10 md:py-16'>
        <div className='mb-10'>
          <h2 className='font-bold text-2xl text-coninsa-green-900 mb-5'>
            ¿Tienes un lote que desees negociar?
          </h2>
          <p className='mb-4'>
            Actualmente estamos interesados en terrenos ubicados en Cundinamarca,
            Antioquia y Costa Atlántica, buscamos predios preferiblemente urbanos
            para el desarrollo de vivienda en altura, con áreas mínimas de 3.000 m2
            para sectores consolidados y áreas superiores a 5.000 m2 para lotes en
            tratamiento de desarrollo.
          </p>

          <p className='font-medium mb-4'>
            Si tu terreno cumple estas condiciones, diligencia el formulario. Al
            recibir la información, procederemos con los estudios preliminares y si
            cumple con las características que estamos buscando, te contactaremos.
          </p>

          <p className='italic mb-4'>
            *Este formulario es únicamente para ofrecer lotes. Si tienes otro tipo de
            solicitud, te invitamos a realizarla a través de{' '}
            <a
              href='https://www.coninsa.co/servicio-cliente/'
              className='uppercase text-coninsa-blue-300 font-semibold'
            >
              servicio al cliente
            </a>
          </p>

          <p className='font-medium'>
            Recorramos este camino juntos, usa el formulario para dejarnos tus datos
            y pronto nos estaremos comunicando.
          </p>
        </div>

        <IframeResizer
          className='w-full mx-0'
          src={`${SITE_URL}/form/soil-development`}
        ></IframeResizer>
      </div>
    </Layout>
  )
}

export default soilDevelopment
