import documentIcon from "../icons/pdf.svg";

export default [
  {
    id: "terms-2023-1",
    icon: documentIcon,
    label: "Derechos de contrato",
    url: "https://d2xt7650da4t25.cloudfront.net/s3fs-public/2023-05/campana-derechos-de-contrato-2023.pdf",
  },
  {
    id: "terms-2023-2",
    icon: documentIcon,
    label: "Inversionista Coninsa Propietarios",
    url: "https://d2xt7650da4t25.cloudfront.net/s3fs-public/2023-04/promocionesinversionistaconinsa_0.pdf",
  },
  {
    id: "terms-2023-3",
    icon: documentIcon,
    label: "Plan Referidos Porteros",
    url: "https://d2xt7650da4t25.cloudfront.net/s3fs-public/2023-04/terminosycondicionesporteros.pdf",
  },
  {
    id: "terms-2023-4",
    icon: documentIcon,
    label: "Campaña Propietarios",
    url: "https://d2xt7650da4t25.cloudfront.net/s3fs-public/2023-04/promocionespropietarios.pdf",
  },
];
