import React, { useState, useRef } from 'react'
import { useForm, Controller } from 'react-hook-form'
import axios from 'axios'
import { Box, Input, Textarea } from 'theme-ui'

import { Button } from 'ui'
import { Field, Select, InputMask } from 'ui/form'
import Toast from 'ui/Toast'
import Control from 'modules/digital-studio/components/Control'
import FilesInput from 'modules/file/FilesInput'

import TermsAndConditions from '../../components/TermsAndConditions'
import PropertyLocations from '../../components/PropertyLocations'
import categories from '../../data/rentCategories.json'

const roles = [
  { value: 'Arrendatario', label: 'Arrendatario' },
  { value: 'Coarrendatario', label: 'Coarrendatario' },
  { value: 'Propietario', label: 'Propietario' },
  { value: 'Copropietario', label: 'Copropietario' },
  { value: 'Apoderado', label: 'Apoderado' },
  { value: 'Edificio/proyecto', label: 'Edificio/proyecto' },
  { value: 'Vecino', label: 'Vecino' },
]

const documentTypes = [
  { value: 'CC', label: 'CC' },
  { value: 'NIT', label: 'NIT' },
  { value: 'CE', label: 'CE' },
  { value: 'PA', label: 'PA' },
]

const ProcessRequireForm = () => {
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm({ defaultValues: { role: '', idType: '', phone: '', location: '', category: '' } })

  const [showingToast, showToast] = useState(false)
  const [toastSettings, setToastSettings] = useState({ position: 'bottom-right' })

  const list = [
    {
      id: 'pr1',
      title: 'Gracias!',
      description: 'La solicitud ha sido creada con éxito.',
      backgroundColor: '#85bb25',
      icon: 'success',
    },
  ]

  const formRef = useRef(null)

  const calcToastSettings = () => {
    if ('parentIFrame' in window) {
      const offsetTop = formRef.current.offsetHeight + 510
      setToastSettings({ position: 'top-right', offsetTop })
    }
  }

  const onSubmit = async ({ category, idType, role, ...data }) => {
    const url = 'https://busquedas.coninsa.co/zoho/contacts/abr';

    return new Promise((resolve, reject) => {
      axios
        .post(url, { category: category.value, idType: idType.value, role: role.value, ...data })
        .then(response => {
          reset()
          calcToastSettings()
          showToast(true)
          resolve(response)
        })
        .catch(error => {
          console.error(error.message)
          reject(error)
        })
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
      {showingToast && (
        <Toast
          toastList={list}
          autoDelete
          autoDeleteTime='3000'
          {...toastSettings}
        />
      )}

      <p className='mb-5'>
        Diligencia el formulario completamente para asegurarnos de brindarte el mejor
        servicio y comunicarnos contigo.
      </p>

      <div className='mb-5'>
        <h3 className='text-coninsa-blue-900 font-semibold text-lg mb-2'>
          Tus datos de contacto
        </h3>

        <Field>
          <Controller
            control={control}
            name='role'
            required
            render={({ field }) => (
              <Select
                {...field}
                placeholder='Rol'
                options={roles}
                isClearable
                required
              />
            )}
          />
        </Field>

        <Box sx={{ display: [null, 'flex'], gap: '16px' }}>
          <Field sx={{ width: [null, '50%'] }}>
            <Input
              placeholder='Nombres'
              type='text'
              {...register('firstName')}
              required
            />
          </Field>

          <Field sx={{ width: [null, '50%'] }}>
            <Input
              placeholder='Apellidos'
              type='text'
              {...register('lastName')}
              required
            />
          </Field>
        </Box>

        <Box sx={{ display: [null, 'flex'], gap: '16px' }}>
          <Field sx={{ width: [null, '50%'] }}>
            <Controller
              control={control}
              name='phone'
              render={({ field }) => (
                <InputMask
                  {...field}
                  placeholder='Teléfono de contacto'
                  type='tel'
                  format='+57 (###) ###-####'
                  mask='_'
                  required
                />
              )}
            />
          </Field>

          <Field sx={{ width: [null, '50%'] }}>
            <Input
              placeholder='Tu correo electrónico'
              type='email'
              {...register('email')}
              required
            />
          </Field>
        </Box>

        <Box sx={{ display: [null, 'flex'], gap: '16px' }}>
          <Field sx={{ width: [null, '50%'] }}>
            <Controller
              control={control}
              name='idType'
              required
              render={({ field }) => (
                <Select
                  {...field}
                  options={documentTypes}
                  placeholder='Tipo de identificación'
                  required
                />
              )}
            />
          </Field>
          <Field sx={{ width: [null, '50%'] }}>
            <Input
              placeholder='Número de Identificación '
              type='text'
              {...register('id')}
              required
            />
          </Field>
        </Box>
      </div>

      <div className='mb-5'>
        <h3 className='text-coninsa-blue-900 font-semibold text-lg mb-2'>
          Tu inmueble
        </h3>

        <Field>
          <PropertyLocations
            placehollders={{
              department: '¿En qué departamento estás ubicado?',
              city: '¿En qué ciudad estás ubicado?',
            }}
            name='location'
            setValue={setValue}
            control={control}
          />
        </Field>

        <Field>
          <Input
            placeholder='Dirección del inmueble'
            type='text'
            {...register('address')}
            required
          />
        </Field>
      </div>

      <div className='mb-5'>
        <h3 className='text-coninsa-blue-900 font-semibold text-lg mb-2'>
          Tu solicitud
        </h3>

        <Box sx={{ display: [null, 'flex'], gap: '16px' }}>
          <Field sx={{ width: [null, '50%'] }}>
            <Controller
              control={control}
              name='category'
              required
              render={({ field }) => (
                <Select
                  {...field}
                  options={categories}
                  placeholder='Categoría'
                  required
                />
              )}
            />
          </Field>

          <Field sx={{ width: [null, '50%'] }}>
            <Input
              placeholder='Asunto'
              type='text'
              {...register('matter')}
              required
            />
          </Field>
        </Box>

        <Field>
          <Textarea
            rows={5}
            placeholder='Escribe los detalles de tu solicitud'
            {...register('description')}
            required
          />
        </Field>

        <Control label='Agregar archivos adjuntos'>
          <FilesInput
            name='attachSupport'
            control={control}
            accept='.txt,.pdf,.doc,.docx,.jpg,.png,.mp4,.mov'
            required
          />
        </Control>
      </div>

      <TermsAndConditions />

      <Button
        sx={{ width: ['100%', 'auto'] }}
        disabled={isSubmitting}
        loading={isSubmitting}
      >
        Solicitar servicio
      </Button>
    </form>
  )
}

export default ProcessRequireForm
