import React from 'react'
import PropTypes from 'prop-types'

import { Image } from 'theme-ui'

const SealCardImage = ({ children, ...props }) => {
  const baseStyles = {
    width: ['85px', '138px'],
    height: ['119px', '195px'],
    textAlign: 'center',
  }
  return (
    <Image __css={baseStyles} {...props}>
      {children}
    </Image>
  )
}

SealCardImage.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SealCardImage
