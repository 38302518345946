import React from "react";
import Layout from "../../../layout/default/Layout";
import { Router, navigate } from "@reach/router";

import { GeneralTermsAndConditionsPage } from "@coninsa-site/terms-and-conditions";


function GeneralLanding() {
  return (
    <Layout>
      <Router basepath="/terminos-y-condiciones/generales">
        <GeneralTermsAndConditionsPage path="*" navigate={navigate} />
      </Router>
    </Layout>
  );
}

export default GeneralLanding;
