import React from 'react'
import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { Spinner, Container, Message } from 'theme-ui'

import { prepareDrupal2Forms } from '../forms/utils'
import LegalPerson from '../pages/LegalPerson'
import NaturalPerson from '../pages/NaturalPerson'
import { NATURAL_PERSON_APPLICATION, LEGAL_PERSON_APPLICATION } from '../queries'
import { getPersonType } from '../settings'

const applicantConfigs = {
  natural: {
    id: 'natural',
    query: NATURAL_PERSON_APPLICATION,
    component: NaturalPerson,
  },
  legal: {
    id: 'legal',
    query: LEGAL_PERSON_APPLICATION,
    component: LegalPerson,
  },
}

function LoadPerson(props) {
  const { bundle, id } = props
  const personType = getPersonType(bundle)

  const applicantConfig = applicantConfigs[personType]
  const bundleQuery = applicantConfig.query

  const { data, loading, error } = useQuery(bundleQuery, {
    variables: { id },
  })

  if (loading) {
    return (
      <Container my='6'>
        <Spinner />
      </Container>
    )
  }

  if (error) {
    return <Message>{error}</Message>
  }

  return <div {...props} loadedPerson={prepareDrupal2Forms(data?.node)} />
}

LoadPerson.propTypes = {
  id: PropTypes.string.isRequired,
  bundle: PropTypes.string.isRequired,
}

export default LoadPerson
