import React from "react";

import SearchPropertyCard from "../../enhancers/SearchPropertyCard";
import SearchPropertyPopup from "../../enhancers/SearchPropertyPopup";

import SearchBar from "../search-rental-housing/sections/search-bar";
import SearchView from "@coninsa-site/project/src/pages/search-view/search-view";

import searchFeaturedPropertiesConfigs from "../../facets/search-featured-properties";

export const SearchFeaturedPage: React.FC = ({
  navigate = (url) => console.log(url),
}) => {
  return (
    <SearchView
      navigate={navigate}
      searchBar={SearchBar}
      itemCard={SearchPropertyCard}
      popupCard={SearchPropertyPopup}
      configs={searchFeaturedPropertiesConfigs}
    />
  );
};

export default SearchFeaturedPage;
