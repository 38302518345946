import React from 'react'
import PropTypes from 'prop-types'

import FeaturedList from 'ui/FeaturedList'

function PFeaturedItem({ title, description, icon, ...props }) {
  return (
    <FeaturedList.Item icon={icon} {...props}>
      <FeaturedList.SubTitle>{title}</FeaturedList.SubTitle>
      <FeaturedList.Body>{description}</FeaturedList.Body>
    </FeaturedList.Item>
  )
}

PFeaturedItem.defaultProps = {
  icon: undefined,
}

PFeaturedItem.propTypes = {
  icon: PropTypes.string,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default PFeaturedItem
