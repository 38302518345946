import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

import { Card } from 'ui'

function PSealCard({ body, image, variant, tag, link = '#', ...props }) {
  const bodyStyles = {
    color: 'neutral',
    display: 'block',
    pt: 'small',
    fontSize: 1,
    fontWeight: '600',
    lineHeight: 1.2,

    '&:hover': {
      color: 'accent',
    },
  }

  return (
    <Card variant={variant} mx={2} {...props}>
      <Card.Image mb='medium' alt={image.alt} src={image.url} variant={variant} />
      <Box
        sx={{
          textAlign: 'center',
          color: 'secondary',
          fontSize: 1,
          fontWeight: 'normal',
        }}
        dangerouslySetInnerHTML={{
          __html: tag,
        }}
      />
      <Card.Content
        as='a'
        href={link}
        target='_blank'
        sx={bodyStyles}
        variant={variant}
      >
        {body}
      </Card.Content>
    </Card>
  )
}

PSealCard.defaultProps = {
  tag: '',
  variant: 'default',
}

PSealCard.propTypes = {
  body: PropTypes.string.isRequired,
  image: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  link: PropTypes.string,
  tag: PropTypes.string,
  variant: PropTypes.string,
}
export default PSealCard
