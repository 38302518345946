import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

import { Icon } from 'ui'
import SelfOverlay from '../../../components/SelfOverlay/SelfOverlay'
import {
  ButtonOverlay,
} from '../slices/PropertyBanner'

export function VideoIframe({ src, ...props }) {
  return (
    <Box
      as='iframe'
      __css={{ width: '100%', height: '100%' }}
      src={src}
      frameborder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowfullscreen
      {...props}
    />
  )
}

VideoIframe.propTypes = {
  src: PropTypes.string.isRequired,
}

function ProjectVideos({ videos, ...props }) {
  const [isFullScreen, setFullScreen] = useState(false)
  const [activeIndexTab, setActiveIndexTab] = useState(0);


  const clickHandler = () => setFullScreen(true)
  const closeHandler = () => setFullScreen(false)

  // Get the firts two videos.
  const mainVideos = videos.slice(0, 2)

  const showBodyCard = (indexTab) => event => {
    console.log(indexTab)
    setActiveIndexTab(indexTab);
  }

  return (
    <Box>
      <style>{`
          .co-video-tab {
            align-items: center;
            background: white;
            display: flex;
            gap: 0.5rem;
            border: 1px solid;
            border-color: var(--theme-ui-colors-gray);
            border-radius: 6px;
            padding: 8px 6px;
            cursor: pointer;
            box-shadow: 8px;
            color: var(--theme-ui-colors-accent);
            font-size: 14px;
            line-height: 1rem;
            flex-shrink: 0;
          }

          .co-video-tab:hover {
            opacity: 0.95;
          }

          .co-video-tab:hover,
          .co-video-tab[is-active='true'] {
            background: var(--theme-ui-colors-accent);
            border-color: var(--theme-ui-colors-accent);
            color: white;
        }
      `}</style>
      <div className='flex gap-1 mb-4 lg:justify-end overflow-x-auto'>
        {videos.map((video, index) => {
          return (
            <button
              onClick={showBodyCard(index)}
              is-active={index === activeIndexTab ? 'true' : 'false'}
              className='co-video-tab'
              title={video?.title}
            >
              <Icon name={'bi-video'} sx={{ fontSize: '18px', flexShrink: 0 }} />

              <span className='max-w-[150px] text-left line-clamp-2 gray' style={{ textWrap: 'balance' }}>
                {video?.title}
              </span>
            </button>
          )
        })}
      </div>

      {videos.map((video, index) => (
        <div id={`video-${video?.id}`} className={`body-video ${index !== activeIndexTab ? 'hidden' : ''}`}>
          <SelfOverlay isFull={isFullScreen} closeHandler={closeHandler}>
            <VideoIframe src={video?.url} sx={{
              borderRadius: '8px',
              width: '100%',
              height: isFullScreen ? '95vh' : [255, 500]
            }} />
          </SelfOverlay>
        </div>
      ))}

      <ButtonOverlay handler={clickHandler} label='Full screen' icon = 'bi-full-screen'></ButtonOverlay>
    </Box>
  )
}

ProjectVideos.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default ProjectVideos
