import Landing from "./pages/landing";

import ProjectTermsAndConditions from "./pages/project";
import PropertyTermsAndConditions from "./pages/property/pages";
import GeneralTermsAndConditions from "./pages/general/pages";

export const LandingPage = Landing;

export const ProjectTermsAndConditionsPage = ProjectTermsAndConditions;
export const PropertyTermsAndConditionsPage = PropertyTermsAndConditions;
export const GeneralTermsAndConditionsPage = GeneralTermsAndConditions;
