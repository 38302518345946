import { NATURAL_PERSON_APPLICATION, LEGAL_PERSON_APPLICATION } from './queries'

export const DIGITAL_STUDIO_BASE_PATH = '/estudio-digital/solicitud'

export const applicantConfigs = {
  natural: {
    id: 'natural',
    label: 'Persona natural',
    basePath: '/estudio-digital/solicitud/persona/natural/add',
    lastStep: 'path=/coarrendatarios',
    query: NATURAL_PERSON_APPLICATION,
  },
  juridica: {
    id: 'legal',
    label: 'Persona jurídica',
    basePath: '/estudio-digital/solicitud/persona/juridica/add',
    lastStep: 'path=/coarrendatarios',
    query: LEGAL_PERSON_APPLICATION,
  },
}

export const getPersonType = bundle => {
  const types = {
    juridica: 'legal',
    natural: 'natural',
  }

  return types[bundle]
}

export const getPersonBasePath = (bundle, id) =>
  `${DIGITAL_STUDIO_BASE_PATH}/persona/${bundle}/${id}`

export const getCotenantBasePath = (bundle, id, cotenant, cid) =>
  `${DIGITAL_STUDIO_BASE_PATH}/persona/${bundle}/${id}/coarrendatario/${cotenant}/${cid}`

export const updatePersonCotenants = (person, entity) => {
  const cotenants = person?.node?.fieldCotenants || []
  const cotenant = cotenants.find(({ targetId }) => targetId == entity.id) || {}
  const mergedCotenant = { ...cotenant?.entity, ...entity }

  const udpatedCotenants = cotenants
    .filter(({ targetId }) => targetId != entity.id)
    .concat({ targetId: entity.id, entity: mergedCotenant })

  return udpatedCotenants
}
