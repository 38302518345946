import React from 'react'
import PropTypes from 'prop-types'

import linkedinIcon from '../../assets/icons/alternative-linkedin.svg'

function StaffCard({ photo, firstName, lastName, position, linkedin }) {
  return (
    <div>
      <img className='rounded-t-xl w-full' src={photo} />
      <div className='bg-white px-4 py-5 rounded-b-xl text-center h-52'>
        <h3 className='text-coninsa-blue-900 leading-5 mb-5 uppercase'>
          <span className='block font-semibold'>{firstName}</span>
          {lastName} <br />
        </h3>

        <p className='mb-5 text-sm'>{position}</p>
        <a href='' target='_blank' className='cursor-pointer'>
          <img className='my-0 mx-auto' src={linkedinIcon} />
        </a>
      </div>
    </div>
  )
}

export default StaffCard

StaffCard.propTypes = {
  photo: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  linkedin: PropTypes.string.isRequired,
}
