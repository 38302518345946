import React from 'react'
import { Box, Container, Grid } from 'theme-ui'

import { Header } from 'ui'
import LogInForm from '../../auth/forms/LogIn'

function IntroPage(props) {
  return (
    <Container pt='large'>
      <Grid gap={2} columns={[2, '1fr 1fr']} mb='medium'>
        <Box>
          <Header sx={{ lineHeight: 1.15, mb: '3' }}>Estudio Digital</Header>
          <Box
            sx={{
              mb: 'medium',
              fontSize: '18px',
              fontWeight: '300',
            }}
          >
            <Box sx={{ fontWeight: 'heading', mb: 'small' }}>¡Estás más cerca de arrendar el inmueble de tus sueños!</Box>
            <p>

              Con nuestro estudio de arrendamiento digital cuentas con la mejor
              seguridad en protección de datos, además tendrás tu resultado en
              menos de 2 horas.
            </p>
            <p className='mb-5'>Te aseguramos que es fácil, rápido y confiable.</p>
            <p>Diligencia tus datos personales y dale en continuar para iniciar con tu
              proceso de estudio de arrendamiento en tan solo minutos.</p>
          </Box>
        </Box>

        <Box sx={{ width: '350px', mx: 'auto' }}>
          <h3 className='text-xl md:text-3xl font-body font-light mb-3 md:mb-6'>Ingresa tus datos</h3>
          <Box sx={{ mx: 'auto' }}>
            <LogInForm {...props} />
          </Box>
        </Box>
      </Grid>
    </Container>
  )
}

export default IntroPage
