import React from 'react'
import { Box, Grid, Input, Label, Radio } from 'theme-ui'

import Control from '../../components/Control'

function BasicCustomer({ register }) {
  return (
    <Grid gap={4} columns={[2, '1fr 1fr']}>
      <Control label='¿Eres cliente de Coninsa Ramón H' required>
        <Box sx={{ display: 'flex' }}>
          {['No', 'Si'].map((label, index) => (
            <Label>
              <Radio {...register('fieldIsCustomer')} value={index} required />
              {label}
            </Label>
          ))}
        </Box>
      </Control>
      <Control label='¿En qué negocio?'>
        <Input {...register('fieldBusiness')} type='text' />
      </Control>
    </Grid>
  )
}

export default BasicCustomer
