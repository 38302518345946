import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

import { Card } from 'ui'

function PAwardCard({ body, image, title, variant, year, ...props }) {
  return (
    <Card variant={variant} {...props}>
      <Card.Picture
        sx={{ height: '200px' }}
        variant={variant}
        image={image}
      />
      <Card.Content variant={variant}>
        {year && <Card.Tag variant={variant}>{year}</Card.Tag>}
        <Card.Title>{title}</Card.Title>
        <Box sx={{ fontSize: 1 }} dangerouslySetInnerHTML={{ __html: body }} />
      </Card.Content>
    </Card>
  )
}

PAwardCard.defaultProps = {
  year: null,
  variant: 'default',
}

PAwardCard.propTypes = {
  body: PropTypes.string.isRequired,
  image: PropTypes.shape({
    source: PropTypes.shape({}),
    meta: PropTypes.shape({}),
  }).isRequired,

  year: PropTypes.string,
  title: PropTypes.string.isRequired,
  variant: PropTypes.string,
}
export default PAwardCard
