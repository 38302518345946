/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Box } from "theme-ui";
import { Helmet } from "react-helmet";

// import { useMainMenuStaticQuery } from './staticQueries';
import { CurrencyProvider } from "../../modules/currency/CurrencyContext";

import Footer from "@coninsa-s2/footer";
import Header from "@coninsa-s2/header";
import Ads from "@coninsa-s2/ads";
import mockedMenuLinks from "@coninsa-s2/header/mock/menu-links";
import "@coninsa-s2/base/styles.css";

function Layout({ children, footerAds, headerAds, popupAds }) {
  // const data = useMainMenuStaticQuery();
  const links = mockedMenuLinks || [];

  return (
    <Box>
      <Ads variant="push-down" items={headerAds} />

      <Header menuLinks={links} brandUrl="https://www.coninsa.co" />

      <Helmet>
        <meta
          name="google-site-verification"
          content="M-4dimcuzUKwcHUitxbfDtfIxNJTzmrJsagAk9ILFqc"
        />
        <script src="/dist/outline.js" type="module" weight="100" />
        <link rel="stylesheet" media="all" href="/dist/fouc.css" />
        <link rel="stylesheet" media="all" href="/outline.theme.css" />
        <link rel="stylesheet" media="all" href="/dist/shared.css" />
        <link rel="stylesheet" media="all" href="/system-ui/style.css" />
      </Helmet>

      <Ads variant="pop-up" items={popupAds} />

      <CurrencyProvider>{children}</CurrencyProvider>
      <Footer />

      <Ads variant="push-down" items={footerAds} />
    </Box>
  );
}

Layout.defaultProps = {
  footerAds: [],
  headerAds: [],
  popupAds: [],
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  footerAds: PropTypes.arrayOf(PropTypes.shape()),
  headerAds: PropTypes.arrayOf(PropTypes.shape()),
  popupAds: PropTypes.arrayOf(PropTypes.shape()),
};

export default Layout;
