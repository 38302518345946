import React from 'react'
import PropTypes from 'prop-types'

import { Box } from 'theme-ui'

const SealCardItem = ({ children, ...props }) => {
  const baseStyles = {
    fontSize: ['14px', '16px'],
  }
  return (
    <Box __css={baseStyles} {...props}>
      {children}
    </Box>
  )
}

SealCardItem.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SealCardItem
