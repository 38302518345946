import React, { useState, useRef, useEffect } from "react";

function pxToNumber(value) {
  return Number.parseInt(value.replace("px"));
}

export default function Slider({
  children,
  options = {
    paddingRight: "100px",
    gap: "16px",
    itemToShow: 4,
  },
  className = "",
}) {
  const [slideWidth, setSlideWidth] = useState("100%");
  const [maxIndex, setMaxIndex] = useState(0);
  const [index, setIndex] = useState(0);

  const elementRef = useRef(null);

  const getChildWidth = () => {
    return elementRef.current?.firstChild?.getBoundingClientRect()?.width;
  };

  useEffect(() => {
    if (elementRef.current) {
      const gapSpaces = options.paddingRight
        ? options.itemToShow
        : options.itemToShow - 1;

      setSlideWidth(
        `calc((100% - ${gapSpaces} * ${options.gap} - ${options.paddingRight}) / ${options.itemToShow} )`
      );
      setMaxIndex(elementRef.current?.children.length - options.itemToShow);

      elementRef.current.addEventListener("scrollend", (event) => {
        const calcIndex =
          event.target.scrollLeft / (getChildWidth() + pxToNumber(options.gap));
        const index = calcIndex % 1 === 0 ? calcIndex : Math.round(calcIndex);
        setIndex(index);
      });
    }
  }, []);

  const handlePrevClick = () => {
    if (index > 0) {
      const scrollTo =
        (index - 1) * (getChildWidth() + pxToNumber(options.gap));
      elementRef.current.scrollTo(scrollTo, 0);
      setIndex(index - 1);
    }
  };

  const handleNextClick = () => {
    if (index < maxIndex) {
      const scrollTo = Math.floor(
        (index + 1) * (getChildWidth() + pxToNumber(options.gap))
      );
      elementRef.current.scrollTo(scrollTo, 0);
      setIndex(index + 1);
    }
  };

  const gapSpaces = options.paddingRight
    ? options.itemToShow
    : options.itemToShow - 1;

  const style = {
    "--slider-items-to-show": options.itemToShow,
    "--slider-gap-spaces": gapSpaces,

    "--slider-gap": options.gap,
    "--slider-padding-right": options.paddingRight,
    "--slider-slide-width": slideWidth,
  };

  return (
    <div className={`co-slider`} style={style}>
      <div ref={elementRef} className={`co-slider__content ${className}`}>
        {children}
      </div>

      <div className="co-slider__navigation">
        <button
          className="co-slider__navigation__prev"
          onClick={handlePrevClick}
          disabled={index === 0}
        >
          <i className="co-icon icon-arrow"></i>
        </button>
        <button
          className="co-slider__navigation__next"
          onClick={handleNextClick}
          disabled={index === maxIndex}
        >
          <i className="co-icon icon-arrow"></i>
        </button>
      </div>
    </div>
  );
}
