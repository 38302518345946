import React from 'react'
import { Box } from 'theme-ui'

import { Field } from 'ui/form'
import LocationField from '../LocationField'
import { Input } from '../../../modules/digital-studio/Location'

function PropertyLocations({ name, setValue, control, placeholders, ...props }) {
  return (
    <Box  {...props}>
      <LocationField>
        {({ departments, cities, updateCities }) => (
          <Box sx={{ display: [null, 'flex'], gap: '16px' }}>
            <Field sx={{ width: [null, '50%'] }}>
              <Input
                name={`${name}.department`}
                control={control}
                options={departments}
                placeholder={placeholders.department}
                handleChange={selected => {
                  setValue(`${name}.city`, null)

                  if (selected) {
                    updateCities(selected)
                  }
                }}
                required
              />
            </Field>

            <Field sx={{ width: [null, '50%'] }}>
              <Input
                name={`${name}.city`}
                control={control}
                options={cities}
                placeholder='Ciudad'
                disabled={!cities?.length}
                required
              />
            </Field>
          </Box>
        )}
      </LocationField>
    </Box>
  )
}

PropertyLocations.defaultProps = {
  placeholders: {
    department: 'Departamento',
    city: 'Ciudad',
    neighborhood: 'Barrio',
  },
}

export default PropertyLocations
