import React from 'react'
import PropTypes from 'prop-types'
import { Box, Text } from 'theme-ui'

import { Icon } from 'components'

function Tab({ icon, label, children }) {
  const tabStyles = {
    fontSize: 3,
    borderBottomStyle: 'solid',
    borderBottomWidth: 0.1,
    borderColor: 'light-4',
    pb: 1,
    pt: 2,
    px: 2,
  }

  return (
    <Box sx={tabStyles}>
      <Box>
        <Icon name={icon} sx={{ verticalAlign: 'middle', mr: 2 }} />
        <Text sx={{ fontSize: ['11px', 'small'] }}>{label}</Text>
      </Box>
      <Box
        sx={{
          fontFamily: 'heading',
          fontSize: [2, 3],
          fontWeight: 'heading',
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

Tab.defaultProps = {
  icon: undefined,
}

Tab.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
}

export default Tab
