import React from "react";
import Layout from "../../../layout/default/Layout";
import { Router, navigate } from "@reach/router";

import { VirtualFairHousingProjectsPage } from "@coninsa-site/terms-and-conditions/pages/general";


function VirtualFairHousingProjects() {
  return (
    <Layout>
      <Router basepath="/terminos-y-condiciones/generales/feria-virtual-proyectos-vivienda-bogota-2018">
        <VirtualFairHousingProjectsPage path="*" navigate={navigate} />
      </Router>
    </Layout>
  );
}

export default VirtualFairHousingProjects;
