import React from 'react'
import PropTypes from 'prop-types'

import { Grid } from 'theme-ui'

import Control from '../../components/Control'
import FileInput from '../../../file/FileInput'
import FilesInput from '../../../file/FilesInput'

function BasicDocumentation({ control, extraFields }) {
  return (
    <Grid gap={4} columns={[2, '1fr 1fr']}>
      <Control label='Documento identidad' required>
        <FileInput
          name='fieldIdentificationDocument'
          control={control}
          required
          accept='.txt,.pdf,.doc,.docx,.jpg,.png,.xls'
        />
      </Control>

      {extraFields.includes('rut') && (
        <Control label='RUT'>
          <FileInput
            name='fieldRut'
            control={control}
            accept='.txt,.pdf,.doc,.docx,.jpg,.png,.xls'
          />
        </Control>
      )}

      {extraFields.includes('income-statement') && (
        <Control label='Declaración de renta'>
          <FileInput
            name='fieldIncomeStatement'
            control={control}
            accept='.txt,.pdf,.doc,.docx,.jpg,.png,.xls'
          />
        </Control>
      )}

      {/* <Control label='Extractos bancarios'>
        <FilesInput
          name='fieldBankStatements'
          control={control}
          accept='.txt,.pdf,.doc,.docx,.jpg,.png,.xls'
        />
      </Control> */}

      <Control label='Estado financiero' helpText='(aplica sólo para independientes o personas jurídicas.)'>
        <FileInput
          name='fieldFinancialState'
          control={control}
          accept='.txt,.pdf,.doc,.docx,.jpg,.png,.xls'
        />
      </Control>

      {extraFields.includes('existence-representation') && (
        <Control label='Existencia y representación'>
          <FileInput
            name='fieldExistenceRepresentation'
            control={control}
            accept='.txt,.pdf,.doc,.docx,.jpg,.png,.xls'
          />
        </Control>
      )}

      {extraFields.includes('proof-payroll') && (
        <Control label='Comprobantes de nómina'>
          <FilesInput
            name='fieldProofPayroll'
            control={control}
            accept='.txt,.pdf,.doc,.docx,.jpg,.png,.xls'
          />
        </Control>
      )}

      {extraFields.includes('property-tax') && (
        <Control label='Impuesto predial'>
          <FileInput
            name='fieldPropertyTax'
            control={control}
            accept='.txt,.pdf,.doc,.docx,.jpg,.png,.xls'
          />
        </Control>
      )}

      {extraFields.includes('labor-certification') && (
        <Control label='Certificación laboral'>
          <FileInput
            name='fieldLaborCertification'
            control={control}
            accept='.txt,.pdf,.doc,.docx,.jpg,.png,.xls'
          />
        </Control>
      )}

      {extraFields.includes('additionals') && (
        <Control
          label='Documentos adicionales'
          help='Certificado laboral indicando cargo, tiempo de servicio y salario mensual.'
        >
          <FilesInput
            name='fieldAdditionalDocuments'
            control={control}
            accept='.txt,.pdf,.doc,.docx,.jpg,.png,.xls'
          />
        </Control>
      )}
    </Grid>
  )
}

BasicDocumentation.defaultProps = {
  extraFields: [],
}

BasicDocumentation.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  extraFields: PropTypes.array,
}

export default BasicDocumentation
