import React from 'react'
import { navigate } from 'gatsby'
import { Box, Grid, Input } from 'theme-ui'
import { useForm } from 'react-hook-form'
import { useStateMachine } from 'little-state-machine'
import { useMutation } from '@apollo/client'

import { Collapse, Heading } from 'ui'
import { UPDATE_NODE_NATURAL_PERSON_COTENANT } from '../../mutations'
import { NATURAL_COTENANT_APPLICATION } from '../../queries'

import Location from '../../Location'
import Control from '../../components/Control'
import FooterForm from '../../components/FooterForm'
import HeaderForm from '../../components/HeaderForm'
import BasicResidenceInformation from '../generic/BasicResidenceInformation'
import BasicTributaryInformation from '../generic/BasicTributaryInformation'
import { getEntity, cleanData } from '../utils'

function TaxInformation(props) {
  const loadedCotenant = props?.loadedCotenant || {}
  const [mutation] = useMutation(UPDATE_NODE_NATURAL_PERSON_COTENANT, {
    refetchQueries: [
      { query: NATURAL_COTENANT_APPLICATION, variables: { id: props.cid } }
    ]
  })
  const { register, handleSubmit, control, setValue } = useForm({
    defaultValues: { ...loadedCotenant }
  })

  const onSubmit = async data => {
    const variables = cleanData(data)
    return mutation({ variables })
      .then(response => {
        const entity = getEntity(response)

        if (entity) {
          navigate('?path=/informacion-adicional')
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <section>
      <HeaderForm step='2/5' title='Información General' />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb='medium'>
          <Heading level='3' variant='form'>
            Información Fiscal
          </Heading>
          <Control label='Dirección fiscal'>
            <Input {...register('fieldFiscalAddress')} type='text' />
          </Control>
          <Control label='Departamento/Ciudad/Barrio'>
            <Location name='fieldUbication' setValue={setValue} control={control} />
          </Control>
          <Grid sx={{ columnGap: 'medium', rowGap: 0 }} columns={[1, '1fr 1fr']}>
            <Control label='Email para envio de facturación electrónica' required>
              <Input {...register('fieldEmail')} type='email' required />
            </Control>
            <Control
              label='Teléfono fiscal'
              helpText='(Este valor corresponde al teléfono registrado en el RUT)'
            >
              <Input {...register('fieldFiscalPhone')} type='text' />
            </Control>
          </Grid>
        </Box>

        <Collapse variant='grayly'>
          <Collapse.Item title='Información Residencia' index={1}>
            <BasicResidenceInformation
              register={register}
              control={control}
              setValue={setValue}
            />

            <Control label='Número de celular'>
              <Input {...register('fieldCellPhoneNumber')} type='text' />
            </Control>
          </Collapse.Item>
        </Collapse>

        {/* --- Temporarily hidden the tributary information section. ---
          <Box mb='medium'>
            <Heading level='3' variant='form'>
              Información Tributaria
            </Heading>
            <BasicTributaryInformation register={register} />
          </Box>
        */}

        <FooterForm back />
      </form>
    </section>
  )
}

export default TaxInformation
