import React from 'react'
import { Box, Label } from 'theme-ui'
import PropTypes from 'prop-types'
import Asterisk from './Asterisk'
import HelpText from './HelpText'

function Control({ children, label, required, helpText, ...props }) {
  return (
    <Box mb='small' {...props}>
      <Label>
        {label}
        {required && <Asterisk />}
        {<HelpText text={helpText} />}
      </Label>
      {children}
    </Box>
  )
}

Control.defaultProps = {
  children: null,
  helpText: '',
  label: '',
  required: false,
}

Control.propTypes = {
  children: PropTypes.node,
  helpText: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
}

export default Control
