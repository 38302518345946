import React from 'react'
import { Box, Input, Grid, Label, Radio } from 'theme-ui'

import { Heading } from 'ui'
import Control from '../../components/Control'

function BasicProperties({ register }) {
  return (
    <Box>
      <Box mb='medium'>
        <Heading level='3' variant='form'>
          Inmuebles
        </Heading>

        <Grid gap={4} columns={[2, '1fr 1fr']}>
          <Control label='Dirección del inmueble'>
            <Input {...register('fieldPropertyAddress')} type='text' />
          </Control>
          <Control label='Matrícula'>
            <Input {...register('fieldPropertyRegistration')} type='text' />
          </Control>
          <Control label='Tiene hipoteca'>
            <Box sx={{ display: 'flex' }}>
              {['No', 'Si'].map((label, index) => (
                <Label>
                  <Radio {...register('fieldHasPropertyMortgage')} value={index} />
                  {label}
                </Label>
              ))}
            </Box>
          </Control>
        </Grid>
      </Box>

      <Box mb='medium'>
        <Heading level='3' variant='form'>
          Vehículos
        </Heading>
        <Grid gap={4} columns={[2, '1fr 1fr']}>
          <Control label='Marca'>
            <Input {...register('fieldBrand')} type='text' />
          </Control>
          <Control label='Modelo'>
            <Input {...register('fieldModel')} type='text' />
          </Control>
          <Control label='Placa'>
            <Input {...register('fieldLicensePlate')} type='text' />
          </Control>
          <Control label='Tiene prenda'>
            <Box sx={{ display: 'flex' }}>
              {['No', 'Si'].map((label, index) => (
                <Label>
                  <Radio {...register('fieldHasGarment')} value={index} />
                  {label}
                </Label>
              ))}
            </Box>
          </Control>
        </Grid>
      </Box>
    </Box>
  )
}

export default BasicProperties
