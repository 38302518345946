import React from 'react'
import { Box, Text } from 'theme-ui'
import PropTypes from 'prop-types'

export const InlineError = props => (
  <Text sx={{ fontSize: '0', mt: '5px', color: 'error' }} {...props} />
)

export const Field = ({ error, children, ...props }) => (
    <Box mb='3' {...props}>
      {children}
      {error && <InlineError>{error.message}</InlineError>}
    </Box>
  )

Field.defaultProps = {
  error: {},
}

Field.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
}

export default Field
