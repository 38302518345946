const links = {
  default: {
    mb: 'xxsmall',
    display: 'inline-block',
    icon: {},
    label: {
      fontSize: 1,
    },
  },

  button: {
    bg: 'secondary',
    borderRadius: '3px',
    display: 'block',
    fontSize: 0,
    fontWeight: 'bold',
    mx: [null, null, 'small'],
    my: ['small', null, 0],
    py: 'small',
    px: 'medium',
    textAlign: 'center',
    textTransform: 'uppercase',

    width: ['100%', 'auto'],

    '&:hover': {
      bg: 'green-3',
    },

    label: {
      color: 'white',
    },

    parent: {
      display: 'flex',
      alignItems: 'center',
    },
  },

  header: {
    display: 'flex',
    mb: 'small',

    icon: {
      mr: 'xsmall',
    },

    label: {
      fontFamily: 'heading',
      textTransform: 'uppercase',
      fontSize: '15px',
      fontWeight: '500',
    },
  },

  highlighted: {
    display: 'flex',
    alignItems: 'center',

    icon: {
      mr: 'small',
    },

    label: {
      color: 'neutral',
      fontFamily: 'heading',
      fontWeight: '500',
      textTransform: 'uppercase',
    },
  },

  stacked: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    icon: {
      mb: ['xsmall', 'medium'],
    },

    label: {
      fontFamily: 'heading',
      fontSize: 2,
      fontWeight: '500',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
  },

  main: {
    display: 'block',
    mx: [null, null, 'small'],
    py: [4, null, null, 5],
    textTransform: 'uppercase',
    position: [null, null, 'relative'],
    '&:hover::after': {
      content: [null, null, '""'],
      display: 'block',
      background: 'secondary',
      height: '3px',
      position: 'absolute',
      left: '.5rem',
      right: '.5rem',
      bottom: '1.5rem',
    },

    icon: {},
    label: {
      fontSize: [2, null, 0],
      fontWeight: 'bold',
    },
  },

  square: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
  },

  subheader: {
    label: {
      color: 'neutral',
      fontSize: '15px',
      fontWeight: '600',
      textTransform: 'uppercase',
    },
  },

  top: {
    display: 'flex',
    px: 'small',
    py: 'small',

    icon: {
      mr: 'xsmall',
    },

    label: {
      alignItems: 'center',
      color: 'rgba(255, 255, 255, 0.65)',
      fontFamily: 'body',
      fontSize: '13px',
      fontWeight: 'bold',
      textTransform: 'uppercase',

      '&:hover': {
        color: 'white',
      },
    },
  },
}

export default links
