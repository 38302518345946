import React from "react";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Breadcrumb, { BreadcrumbItem } from "@coninsa-s2/breadcrumb";
import Heading from "@coninsa-s2/heading";

export default function VirtualFairHousingProjects() {
  const breadcrumbItems = [
    {
      label: "Inicio",
      url: "/terminos-y-condiciones",
    },
    {
      label: "Términos y condiciones generales",
      url: "/terminos-y-condiciones/generales",
    },
    {
      label: "Feria virtual proyectos vivienda Bogotá 2018",
    },
  ];

  return (
    <Section>
      <Container>
        <div className="mb-8">
          <Breadcrumb>
            {breadcrumbItems.map((item) => (
              <BreadcrumbItem>
                {item.url && <a href={item.url}>{item.label}</a>}
                {!item.url && item.label}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        </div>
        <div className="mb-7">
          <span className="text-lg text-s2-dark-blue lg:text-[22px]">
            Términos y condiciones
          </span>
          <Heading size="2xl">
            Feria virtual proyectos vivienda Bogotá 2018
          </Heading>
        </div>

        <div className="prose">
          <ol>
            <li>
              <h4>Vigencia:</h4>
              <p>
                La actividad feria virtual Coninsa Ramón H. 2018 proyectos
                vivienda Bogotá se realizará del 03 de septiembre de 2018 al 31
                de octubre de 2018, en la ciudad de Bogotá.
              </p>
            </li>
            <li>
              <h4>El organizador:</h4>
              <p>
                La presente actividad está organizada por Coninsa Ramón H S.A.
                con Nit 890911431-1 con domicilio principal en la Región Bogotá
                Av. Cra. 19 No. 114 – 65 piso 6 (57+1) 6014800, Región Antioquia
                (57+4) 448 88 28, y Región Caribe: (57+5) 319 96 10
              </p>
            </li>
            <li>
              <h4>Objeto y mecánica de la actividad:</h4>
              <p>
                Feria virtual Coninsa Ramón H. 2018 proyectos vivienda Bogotá,
                es una actividad que se realizara en la vigencia descrita en la
                que se entregara a los compradores de inmuebles durante dicha
                vigencia unos descuentos que aplican dependiendo del proyecto,
                la forma de pago y fechas estipuladas.
              </p>
              <ol>
                <li>
                  Los clientes que obtengan estos descuentos autorizan a Coninsa
                  Ramón H. S.A. a difundir y/o publicar cualquier dato que haya
                  sido suministrado en su inscripción, en caso que decida
                  identificarlo como ganador de la actividad o para cualquier
                  otro fin comercial que la empresa organizadora considere
                  necesario, sin obligación de compensación alguna.
                </li>
                <li>
                  Los derechos que le asisten como Titular de los datos
                  personales objeto de Tratamiento son los señalados en el
                  artículo 8 de la Ley 1581 de 2012.
                </li>
              </ol>
            </li>
            <li>
              <h4>Disposiciones legales:</h4>
              <ol>
                <li>
                  Los descuentos que aplican en la presente actividad no se
                  cambiaran por dinero, valores o cualquier otro bien. El
                  derecho al canje no es transferible, negociable, ni puede ser
                  comercializado o canjeado por dinero en efectivo, ni por
                  ningún otro premio.
                </li>
                <li>
                  El solo hecho de participar en esta actividad implica la total
                  aceptación de los presentes términos y condiciones.
                </li>
                <li>
                  Los participantes se comprometen a eximir de responsabilidad a
                  Coninsa Ramón H. S.A., sus accionistas, empleados o
                  colaboradores y frente a cualquier acción, daño, costes, y
                  otras responsabilidades en las que pudiera incurrir Coninsa
                  Ramón H. S.A. como resultado de cualquier tipo de
                  incumplimiento por parte suya de alguno de alguno de los
                  términos y condiciones.
                </li>
                <li>
                  Al aceptar estos términos y condiciones los participantes
                  reconocen que de manera libre, voluntaria y espontánea han
                  decidido participar en esta actividad, por lo que dentro de
                  los límites permitidos por la ley Colombiana, renuncian a
                  cualquier reclamación o acción de cualquier naturaleza en
                  contra de Coninsa Ramón H. S.A., sus accionistas, empleados o
                  colaboradores, que tengan por objeto reclamar indemnización
                  por daños o perjuicios que les haya causado su participación
                  en la actividad, o de cualquier manera relacionados con esta,
                  o por las actividades que realizare como consecuencia de dicha
                  participación. Si el descuento no es aceptado o sus
                  condiciones, se considerará que ha renunciado al mismo y no
                  tendrá derecho a reclamo o indemnización alguna, ni siquiera
                  parcialmente.
                </li>
                <li>
                  La responsabilidad inherente a la actividad por parte de
                  Coninsa Ramón H. S.A. culmina con la entrega del descuento
                  sobre el inmueble comprado.
                </li>
              </ol>
            </li>
            <li>
              <h4>Tabla de condiciones para descuentos:</h4>
              <table>
                <tbody>
                  <tr>
                    <th>Proyecto castilla la nueva:</th>
                    <td>
                      <div>
                        <p>
                          Los siguientes descuentos solo aplican una vez se
                          realice la separación del inmueble únicamente del 03
                          al 30 de Septiembre de 2018:
                        </p>
                        <ul>
                          <li>
                            Descuento de $ 5, 000,000 aplica para los
                            apartamentos de 68m2 y 86m2 área construida.
                          </li>
                          <li>
                            Descuento de $ 7, 000,000 aplica para los
                            apartamentos de 76 m2 área construida.
                          </li>
                        </ul>
                      </div>
                      <div>
                        <p>
                          Los siguientes descuentos aplican una vez se realice
                          la separación del inmueble únicamente del 03 de
                          Septiembre al 31 de Octubre de 2018:
                        </p>
                        <ul>
                          <li>
                            Descuento de $ 5, 000,000 aplica para cualquier
                            área, únicamente cuando se realice el total del pago
                            de la cuota inicial (30%).
                          </li>
                          <li>
                            Descuento de $ 10, 000,000 aplica para el área
                            construida de 68 m2, por pagar la totalidad de la
                            cuota inicial en 17 meses (última cuota enero del
                            2020)
                          </li>
                          <li>
                            Descuento de $ 11, 000,000 aplica para el área
                            construida de 76 m2, por pagar la totalidad de la
                            cuota inicial en 17 meses (última cuota enero del
                            2020)
                          </li>
                          <li>
                            Descuento de $ 12, 000,000 aplica para el área
                            construida de 86 m2, por pagar la totalidad de la
                            cuota inicial en 17 meses (última cuota enero del
                            2020)
                          </li>
                          <li>
                            Descuento de $ 18, 000,000 aplica para el área
                            construida de 68 m2, por pagar la totalidad de la
                            cuota inicial en 13 meses (última cuota Septiembre
                            del 2019)
                          </li>
                          <li>
                            Descuento de $ 20, 000,000 aplica para el área
                            construida de 76 m2, por pagar la totalidad de la
                            cuota inicial en 13 meses (última cuota Septiembre
                            del 2019)
                          </li>
                          <li>
                            Descuento de $ 22, 000,000 aplica para el área
                            construida de 86 m2, por pagar la totalidad de la
                            cuota inicial en 13 meses (última cuota Septiembre
                            del 2019)
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Reserva 67:</th>
                    <td>
                      <p>
                        Los siguientes descuentos aplican una vez se realice la
                        separación del inmueble únicamente del 03 de Septiembre
                        al 31 de Octubre de 2018:
                      </p>
                      <ul>
                        <li>
                          El descuento depende del área y aplica desde que la
                          cuota inicial sea pagada en 15 meses.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>Reserva de Mallorca:</th>
                    <td>
                      Los descuentos para este proyecto aplican para todas las
                      áreas de este proyecto. El descuento está sujeto al área
                      total construida del inmueble que se separe. Aplica del 03
                      de Septiembre al 31 de Octubre de 2018.
                    </td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
              <h4>Condiciones y restricciones:</h4>
              <ol>
                <li>
                  Se considera cierre de negocio (compra del inmueble),
                  únicamente cuando se haya hecho la legalización del inmueble
                  comprado ante la fiducia.
                </li>
                <li>Promoción no válida para empleados de la compañía.</li>
                <li>
                  Para acceder al descuento se deberán dar las condiciones
                  descritas en el presente documento y se realizará en la sala
                  de negocios del proyecto en el cual se haya hecho la compra
                  del inmueble.
                </li>
                <li>
                  Los descuentos no son acumulables entre si y no son canjeables
                  en dinero en efectivo.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </Container>
    </Section>
  );
}
