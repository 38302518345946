export default [
  {
    "title": "Nuestra presidenta Coninsa en un episodio especial del P\u00f3dcast #Metr\u00f3poli ",
    "url": "http://blog.coninsa.co/somos-coninsa/nuestra-presidenta-coninsa-en-un-episodio-especial-del-podcast-metropoli",
    "author": "CONINSA RAM\u00d3N H",
    "created": "2024-05-17",
    "image": "https://via.placeholder.com/300x200"
  },
  {
    "title": " \u00bfTrasteo en un edificio? Estas son las restricciones de mudanzas ",
    "url": "http://blog.coninsa.co/bienestar/trasteo-en-un-edificio-estas-son-las-restricciones-de-mudanzas",
    "author": "CONINSA RAM\u00d3N H",
    "created": "2024-05-14",
    "image": "https://via.placeholder.com/300x200"
  },
  {
    "title": "\u00bfVolver a vivir con mam\u00e1? Consejos para encontrar la vivienda ideal",
    "url": "http://blog.coninsa.co/bienestar/volver-vivir-con-mama-consejos-para-encontrar-la-vivienda-ideal",
    "author": "CONINSA RAM\u00d3N H",
    "created": "2024-05-06",
    "image": "https://via.placeholder.com/300x200"
  },
  {
    "title": "Nuestros reconocimientos en gesti\u00f3n sostenible 2023 - 2024",
    "url": "http://blog.coninsa.co/somos-coninsa/nuestros-reconocimientos-en-gestion-sostenible-2023-2024",
    "author": "CONINSA RAM\u00d3N H",
    "created": "2024-05-06",
    "image": "https://via.placeholder.com/300x200"
  }
]
