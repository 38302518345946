export default {
  seal: {
    // height: '303px',

    content: {
      fontSize: [1, 2],
      textAlign: 'center',
    },

    image: {
      height: ['119px', '195px'],
      width: ['85px', '120px'],
      mb: 'small',
      mx: 'auto',
      pt: 'medium',
    },
  },

  award: {
    // height: '530px',
    content: {
      fontSize: [1, 2],
    },

    image: {
      width: '100%',
      mb: 'small',
    },
  },

  member: {
    // height: '500px',

    content: {
      fontSize: 1,
    },

    image: {
      height: '131px',
      width: 'auto',
      mb: 'small',
      mx: 'auto',
    },
  },
}
