import documentIcon from "../../../icons/document.svg";
import homeIcon from "../../../icons/home.svg";
import bullhornIcon from "../../../icons/bullhorn.svg";
import profileIcon from "../../../icons/profile.svg";


export default [
  {
    id: 'building-item-1',
    subtitle: 'de inmuebles',
    title: 'Arrendamiento',
    icon: documentIcon,
    url: '/arrendamientos/vivienda',
    tint: 'lime',
  },
  {
    id: 'building-item-2',
    subtitle: 'de usados',
    title: 'Compra y venta',
    icon: homeIcon,
    url: '/venta-de-usados',
    tint: 'green',
  },
  {
    id: 'building-item-3',
    subtitle: 'tus inmuebles',
    title: 'Publica',
    icon: bullhornIcon,
    url: 'https://contenidos.coninsa.co/preconsignar-inmueble/',
    tint: 'green',
  },
  {
    id: 'building-item-4',
    subtitle: 'Mi Coninsa Virtual',
    title: 'Portal de gestión',
    icon: profileIcon,
    url: 'https://miconinsavirtual.coninsa.co/',
    tint: 'green',
  },
]
