import React from "react";
import { useMediaQuery } from "react-responsive";
import clsx from "clsx";

function FootstepsDesktop({ steps, currentStep, className }) {
  return (
    <div className={`footsteps ${className} max-w-4xl mx-auto`}>
      {steps.map((step) => (
        <div key={step.label} className="rectangle">
          <div
            className={clsx({
              circle: true,
              "is-green": step.label <= currentStep,
            })}
          >
            <i
              className={clsx({
                "co-icon": true,
                "icon-check": true,
                "is-hidden": step.label >= currentStep,
              })}
            ></i>
            <span className={clsx({ "is-hidden": step.label < currentStep })}>
              {step.label}
            </span>
          </div>
          <p className={clsx({ "is-hidden": step.label !== currentStep })}>
            {step.desc}
          </p>
        </div>
      ))}
    </div>
  );
}

function FootstepsMobile({ steps, currentStep, className }) {
  const current = steps
    .filter((step) => step.label === currentStep)
    .map((x) => x.desc);
  const next = steps
    .filter((step) => step.label === currentStep + 1)
    .map((y) => y.desc);
  const isLast = next.length === 0;

  return (
    <div className={`footsteps-is-mobile ${className} max-w-4xl mx-auto`}>
      <div className="progress">
        <svg viewBox="0 0 10 10">
          <circle cx="50%" cy="50%" r="4" />
          <circle className={`step-${currentStep}`} cx="50%" cy="50%" r="4" />
        </svg>
        <h2> {currentStep}/8 </h2>
      </div>
      <div>
        <p>{current}</p>
        <p className={clsx({ "is-hidden": isLast })}>Siguiente: {next}</p>
      </div>
    </div>
  );
}

export default function Footsteps({
  steps = [],
  currentStep = 1,
  className = "",
}) {
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 641px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 640px)" });

  return (
    <div>
      {isDesktopOrLaptop && (
        <FootstepsDesktop
          steps={steps}
          currentStep={currentStep}
          className={className}
        />
      )}
      {isTabletOrMobile && (
        <FootstepsMobile
          steps={steps}
          currentStep={currentStep}
          className={className}
        />
      )}
    </div>
  );
}
