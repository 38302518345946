import React from "react";

export default function Alert(props) {
  return (
    <div className={`alert-container mb-7 ${props.className}`}>
      <i className="co-icon icon-alert"></i>
      <div className="alert-message">{props.children}</div>
    </div>
  );
}
