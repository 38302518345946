import { compose, filter, join, values } from 'ramda'

export const nFile = value => {
  if (value?.targetId) {
    return {
      targetId: value?.targetId,
    }
  }

  return null
}

export const nFiles = (arr = []) => arr.map(nFile)

export const nLocation = compose(
  join(', '),
  filter(i => i),
  values
)

export default {}
