import React from "react";

import Banner from "@coninsa-s2/banner";
import Container from "@coninsa-s2/container";
import DialogBox from "@coninsa-s2/dialog-box";
import Section from "@coninsa-s2/section";

import bannerImage from "./assets/images/banner.png";
import bannerMobileImage from "./assets/images/mobile-banner.png";
import pdfIcon from "./assets/icons/pdf.svg";

function DataProcessingPolicy() {
  return (
    <div>
      <Banner
        imageUrl={bannerImage}
        imageUrlMobile={bannerMobileImage}
        className="flex items-start lg:items-center lg:pl-16"
      >
        <Container>
          <div className="text-white lg:w-[600px]">
            <h2 className="pb-3 text-2xl font-semibold leading-5 lg:text-5xl">
              Conoce nuestra <br /> política de
            </h2>
            <DialogBox tint="dark-blue" inverted>
              <span className="text-3xl font-extrabold leading-7 lg:text-6xl">
                Tratamiento de datos personales
              </span>
            </DialogBox>
          </div>
        </Container>
      </Banner>
      <Section tint="ligther-gray">
        <Container>
          <div className="mx-auto lg:w-[602px]">
            <p className="mb-8 lg:text-lg">
              Atendiendo a lo consagrado en la Ley 1581 de 2012, Coninsa
              establece su{" "}
              <span className="font-bold">
                Política de Tratamiento de Datos Personales
              </span>
              , la cual te invitamos a leer a en el siguiente archivo en PDF.
            </p>

            <a
              href="https://d2xt7650da4t25.cloudfront.net/s3fs-public/2023-03/Politica-de-Habeas-Data-Coninsa-08032023.pdf"
              target="_blank"
              className="flex items-center gap-5 rounded-lg bg-white px-5 py-5 shadow-lg hover:shadow-xl lg:px-12"
            >
              <img src={pdfIcon} className="h-16 w-14 lg:h-auto lg:w-auto" />
              <div>
                <h4 className="font-bold text-s2-dark-blue lg:text-lg">
                  Política de Tratamiento de Datos Personales
                </h4>
                <p className="text-xs text-s2-gray-600 lg:text-sm">
                  Archivo PDF - 289 kb
                </p>
              </div>
            </a>
          </div>
        </Container>
      </Section>
    </div>
  );
}

export default DataProcessingPolicy;
