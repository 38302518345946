import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import debounce from "lodash.debounce";
import NumberFormat from "react-number-format";

import Section from "@coninsa-s2/section";
import Heading from "@coninsa-s2/heading";
import Container from "@coninsa-s2/container";
import Facet from "@coninsa-s2/facet";
import FacetItem from "@coninsa-s2/facet-item";
import Button from "@coninsa-s2/button";
import Breadcrumb, { BreadcrumbItem } from "@coninsa-s2/breadcrumb";
import { dom } from "@coninsa-s2/utils";
import FilterIcon from "@coninsa-s2/icon/assets/filter.inline.svg";

import ExposedForm from "./exposed-form";

import {
  getProjectStateLabel,
  getProjectImmediateDeliveryLabel,
} from "../../../facets";

const ALL_OPTION_VALUE = "Todos";

interface FacetType {
  name: string;
  values: Array<{ filter: string; count: number }>;
}

interface SearcBarProps {
  initialFacets: Array<FacetType>;
  resultCount: number;
  loading: boolean;

  refetch: () => void;
  navigate: (string) => void;
}

const SearcBar: React.FC<SearcBarProps> = ({
  initialFacets,
  resultCount,
  loading,

  refetch,
  navigate,

  configs,
}) => {
  const [facets, setFacets] = useState<Array<FacetType>>([]);
  const [showModal, setShowModal] = useState(false);
  const [initFacets, setInitFacets] = useState(false);

  const { register, handleSubmit, watch, control, setValue } = useForm({
    defaultValues: configs.initialValues,
  });

  const form = watch();

  // Toggle window page scrolling.
  useEffect(() => {
    dom.setPageScrolling(showModal);
  }, [showModal]);

  useEffect(() => {
    const debounceCb = debounce((data) => {
      const defaultQuery = configs.getSearchQuery(data);
      const newPathname = configs.getPrettyPaths(data);

      navigate(newPathname);
      refetch(defaultQuery);
    }, 750);

    const subscription = watch(debounceCb);

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (initialFacets && !initFacets) {
      // Set facets.
      setFacets(initialFacets);
      setInitFacets(true);

      // Get active filters.
      const activeFilters = configs.getActiveFacets(initialFacets);

      // Set active filters.
      setTimeout(() => {
        for (const [filter, value] of Object.entries(activeFilters)) {
          setValue(filter, value);
        }
      });
    }
  }, [initialFacets]);

  const onsubmit = () => { };

  function getFacetValues(facetKey) {
    const facet = facets.find(({ name }) => name === facetKey);

    return facet?.values ? [{ filter: ALL_OPTION_VALUE }, ...facet.values] : [];
  }

  return (
    <div>
      <Section tint="light-gray" className="py-4">
        <Container>
          <Breadcrumb>
            {configs.breadcrumbItems.map((item) => (
              <BreadcrumbItem>
                {item.url && <a href={item.url}>{item.label}</a>}
                {!item.url && item.label}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>

          <Heading as="h1" size="xl" weight="bold" className="mb-6">
            {configs.title}
          </Heading>

          <form onSubmit={handleSubmit(onsubmit)}>
            <div className="grid grid-cols-2 lg:grid-cols-12 xl:flex gap-2 md:gap-4 flex-wrap">
              <div className="flex gap-2 md:gap-4 col-span-2 lg:col-span-4 xl:w-64">
                <input
                  placeholder="Ciudad, zona, localidad o barrio"
                  className="s2-input flex-1"
                  type="search"
                  {...register("text")}
                />

                <Button
                  shape="square"
                  type="button"
                  className="s2-filters-trigger lg:!hidden"
                  onClick={() => setShowModal(true)}
                >
                  <FilterIcon />
                </Button>
              </div>

              <Facet
                label="Ubicación"
                icon="pin"
                current={form.department_project}
                showValue={form.department_project !== ALL_OPTION_VALUE}
                className="lg:col-span-3"
              >
                <div className="flex gap-2 flex-wrap max-w-sm">
                  {getFacetValues("department_project").map((item, index) => {
                    const filterId = `facet-item--department_project-${index}`;

                    return (
                      <FacetItem key={filterId}>
                        <input
                          type="radio"
                          {...register("department_project")}
                          value={item.filter}
                          id={filterId}
                        />
                        <label htmlFor={filterId}>
                          {item.filter}
                          <span>({item.count})</span>
                        </label>
                      </FacetItem>
                    );
                  })}
                </div>
              </Facet>

              <Facet
                label="Tipo de inmueble"
                icon="home"
                current={form.property_type}
                showValue={form.property_type !== ALL_OPTION_VALUE}
                className={"hidden xl:block"}
              >
                <div className="flex gap-2 flex-wrap max-w-sm">
                  {getFacetValues("property_type").map((item, index) => {
                    const filterId = `facet-item-property_type-${index}`;

                    return (
                      <FacetItem key={filterId}>
                        <input
                          type="radio"
                          {...register("property_type")}
                          value={item.filter}
                          id={filterId}
                        />
                        <label htmlFor={filterId}>
                          {item.filter}
                          <span>({item.count})</span>
                        </label>
                      </FacetItem>
                    );
                  })}
                </div>
              </Facet>

              <Facet
                label="Precio desde"
                icon="dollar"
                current={[form.field_sale_price_from, form.field_sale_price_to]
                  .filter((value) => Boolean(value))
                  .join(" - ")}
                showValue={form.field_sale_price_from !== ""}
                className="lg:col-span-3"
              >
                <div className="md:max-w-sm">
                  <div className="grid grid-cols-2 gap-4">
                    <Controller
                      control={control}
                      name="field_sale_price_from"
                      render={({ field }) => (
                        <NumberFormat
                          className="s2-input s2-input--small"
                          placeholder="Min"
                          thousandSeparator="."
                          decimalSeparator=","
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="field_sale_price_to"
                      render={({ field }) => (
                        <NumberFormat
                          className="s2-input s2-input--small"
                          placeholder="Max"
                          thousandSeparator="."
                          decimalSeparator=","
                          {...field}
                        />
                      )}
                    />
                  </div>

                  <div className="flex gap-2 justify-end mt-2">
                    <Button
                      variant="ghost"
                      size="compact"
                      shape="rounded"
                      type="button"
                      onClick={() => {
                        setValue("field_sale_price_from", ""),
                          setValue("field_sale_price_to", "");
                      }}
                    >
                      Limpiar
                    </Button>
                    {/* <button type="button">Aceptar</button> */}
                  </div>
                </div>
              </Facet>

              <Facet
                label="Estado"
                icon="tag"
                current={getProjectStateLabel(form.field_state)}
                showValue={form.field_state !== ALL_OPTION_VALUE}
                className={"hidden xl:block"}
              >
                <div className="flex gap-2 flex-wrap max-w-sm">
                  {getFacetValues("field_state").map((item, index) => {
                    const filterId = `facet-item-field_state-${index}`;

                    return (
                      <FacetItem key={filterId}>
                        <input
                          type="radio"
                          {...register("field_state")}
                          value={item.filter}
                          id={filterId}
                        />
                        <label htmlFor={filterId}>
                          {getProjectStateLabel(item.filter)}
                          <span>({item.count})</span>
                        </label>
                      </FacetItem>
                    );
                  })}
                </div>
              </Facet>

              <div className="hidden lg:col-span-2 lg:block">
                <Button
                  shape="square"
                  variant="facet"
                  type="button"
                  onClick={() => setShowModal(true)}
                >
                  <FilterIcon /> Más filtros
                </Button>
              </div>
            </div>
          </form>
        </Container>
      </Section>

      {showModal && (
        <ExposedForm
          facets={facets}
          parentFormValues={form}
          parentDefaultValues={configs.initialValues}
          resultCount={resultCount}
          loading={loading}
          setParentValue={setValue}
          closeModal={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default SearcBar;
