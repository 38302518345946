import React from 'react'
import PropTypes from 'prop-types'

import ConstructionAwards from '../dynamic/components/ConstructionAwards'
import ArchitectureAwards from '../dynamic/components/ArchitectureAwards'
import OurAwards from '../dynamic/components/OurAwards'
import Timeline from '../dynamic/components/Timeline'
import OrganizationalStructure from '../dynamic/components/OrganizationalStructure'
import NewProjectsSlider from '../dynamic/components/NewProjectsSlider'
import InfoProjectForm from '../dynamic/components/InfoProjectForm'

export const DYNAMIC_PARAGRAPH = 'Drupal_ParagraphDynamicComponent'

export function mapper({ id, ...entity }) {
  return {
    id,
    ...entity,
  }
}

function DefaultComponent(props) {
  return <pre>{JSON.stringify(props, null, 2)}</pre>
}

function PDynamic({ component, ...props }) {
  const map = {
    construction_awards: ConstructionAwards,
    architecture_awards: ArchitectureAwards,
    our_awards: OurAwards,
    timeline: Timeline,
    organizational_structure: OrganizationalStructure,
    new_projects_slider: NewProjectsSlider,
    info_project_form: InfoProjectForm,
  }

  const DynamicComponent = map[component] || DefaultComponent

  return <DynamicComponent {...props} />
}

PDynamic.propTypes = {
  component: PropTypes.string.isRequired,
}

export default PDynamic
