import React from 'react'
import PropTypes from 'prop-types'
import { Image, Link } from 'theme-ui'
import { Icon } from 'ui'

import brandImg from './icons/coninsa-brand.svg'

function Brand({ brand, href, ...props }) {
  const baseStyles = {
    display: 'flex',
    alignItems: 'center',
  }

  const iconStyles = {
    width: [150, null, 160, null, 200],
  }

  if (brand === 'default') {
    return (
      <Link href={href} sx={baseStyles} {...props}>
        <Image sx={{ width: ['180px', 'auto'] }} src={brandImg} />
      </Link>
    )
  }

  const mapIcons = {
    default: 'co-brand-coninsa',
    architecture: 'co-brand-architecture',
    construction: 'co-brand-construction',
  }

  const icon = mapIcons[brand]

  return (
    <Link href={href} sx={baseStyles} {...props}>
      <Icon name={icon} sx={iconStyles} />
    </Link>
  )
}

Brand.defaultProps = {
  brand: 'default',
  href: '#',
}

Brand.propTypes = {
  brand: PropTypes.string,
  href: PropTypes.string,
}

export default Brand
