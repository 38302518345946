import React from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { Box, Button, Checkbox, Input, Label, Select, Textarea } from 'theme-ui'

import { Heading } from 'ui'
import Control from '../digital-studio/components/Control'
import {getOptions, academicBackground} from '../digital-studio/forms/options'
import { nFile, nLocation } from '../digital-studio/utils'
import Location from '../digital-studio/Location'
import FileInput from '../file/FileInput'

import { WEBFORM_SUBMIT } from '../services/mutations'

function WorkWithUsForm () {
  const { control, handleSubmit, register, reset, setValue } = useForm()

  const [mutation] = useMutation(WEBFORM_SUBMIT)

  const onSubmit = data => {
    const normalizedData = {
      ...data,
      cv: nFile(data.cv),
      location: nLocation(data.location),
      webform_id: 'work_with_us',
    }


    const webformData = JSON.stringify(normalizedData)

    return mutation({ 
      variables: {
        values: webformData,
      }, 
    })
      .then(response => {
        console.log(response)
        // const entity = getEntity(response)

        reset()
      })
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <section>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Heading sx={{ color:'neutral', fontSize: ['25px', '30px'] }}>¿Quieres postularte?</Heading>

        <Control label='¿A qué oferta deseas aplicar?'>
          <Input
            placeholder='Escribe aquí el nombre de la vacante'
            type='text'
            {...register('want_to_apply_for')}
            required
          />
        </Control>

        <Control label='Nombre'>
          <Input
            type='text'
            {...register('name')}
            required
          />
        </Control>

        <Control label='Apellidos'>
          <Input
            type='text'
            {...register('last_name')}
            required
          />
        </Control>

        <Location
          placeholders={{
            department: '¿En qué departamento estás ubicado?',
            city: '¿En qué ciudad estás ubicado?',
            neighborhood: '¿En qué barrio estás ubicado?',
          }}
          name='location'
          setValue={setValue}
          control={control}
        />

        <Control label='Correo electrónico'>
          <Input
            type='email'
            {...register('email')}
            required
          />
        </Control>

        <Control label='Teléfono'>
          <Input
            type='text'
            {...register('phone')}
            required
          />
        </Control>

        <Control label='Cédula'>
          <Input
            type='text'
            {...register('document')}
            required
          />
        </Control>

        <Control label='Formación académica'>
          <Select {...register('academic_background')}>
            {getOptions(academicBackground).map(option => (
              <option value={option.value}>{option.label}</option>
            ))}
          </Select>
        </Control>

        <Control label='Empresa'>
          <Input
            type='text'
            {...register('company')}
            required
          />
        </Control>

        <Control label='Responsabilidades'>
          <Textarea
            rows={5}
            {...register('responsibilities')}
          />
        </Control>

        <Control label='Años'>
          <Input
            type='text'
            {...register('years')}
            required
          />
        </Control>

        <Control label='Hoja de vida' >
          <FileInput
            name='cv'
            control={control}
            accept='.txt,.pdf,.doc,.docx,.jpg,.png,.xls'
          />
        </Control>

        <Control label='Comentario'>
          <Textarea
            rows={5}
            {...register('comments')}
          />
        </Control>

        <Label mb='medium'>
          <Checkbox {...register('accept_conditions')} required />
            <Box sx={{ fontSize: 2, textAlign: 'left' }}>
              <span>Acepto las </span>
              <Box as='a' href='https://www.coninsa.co/politica-de-tratamiento-de-datos-personales-de-coninsa-ramon-h-sa' 
                sx={{ color : 'accent', textDecoration: 'none' }} target='_blank'
              >
                políticas de tratamiento de datos
              </Box>
              <span> de Coninsa Ramón H. S.A.</span>
            </Box>
        </Label>

        <Button
          sx={{ color:'white', cursor: 'pointer', mb: 'medium', width: ['100%', 'auto'] }}
          // disabled={isSubmitting}
          // loading={isSubmitting}
        >
          Postularme
        </Button>
      </form>
    </section>  
  )
}

export default WorkWithUsForm
