import React from 'react'
import PropTypes from 'prop-types'

import LegalPerson from '../pages/LegalPerson'
import NaturalPerson from '../pages/NaturalPerson'

function AddPerson(props) {
  const { bundle } = props

  const settingsMap = {
    juridica: LegalPerson,
    natural: NaturalPerson,
  }

  const Component = settingsMap[bundle]

  return <Component {...props} />
}

AddPerson.propTypes = {
  id: PropTypes.string.isRequired,
  bundle: PropTypes.string.isRequired,
}

export default AddPerson
