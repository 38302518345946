import React from "react";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Breadcrumb, { BreadcrumbItem } from "@coninsa-s2/breadcrumb";
import Heading from "@coninsa-s2/heading";

export default function OwnersLeaseFair() {
  const breadcrumbItems = [
    {
      label: "Inicio",
      url: "/terminos-y-condiciones",
    },
    {
      label: "Términos y condiciones generales",
      url: "/terminos-y-condiciones/generales",
    },
    {
      label: "Propietarios feria arrendamientos 2020",
    },
  ];

  return (
    <Section>
      <Container>
        <div className="mb-8">
          <Breadcrumb>
            {breadcrumbItems.map((item) => (
              <BreadcrumbItem>
                {item.url && <a href={item.url}>{item.label}</a>}
                {!item.url && item.label}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        </div>
        <div className="mb-7">
          <span className="text-lg text-s2-dark-blue lg:text-[22px]">
            Términos y condiciones
          </span>
          <Heading size="2xl">Propietarios feria arrendamientos 2020</Heading>
        </div>

        <div className="prose">
          <p>
            Las siguientes son las condiciones de la feria de arrendamientos
            Coninsa, la cual rige en las ciudades de Bogotá, Medellín y
            Barranquilla, para los clientes propietarios de inmuebles de
            vivienda o comercio.
          </p>
          <div>
            <ol>
              <li>
                <h4>Vigencia:</h4>
                <p>
                  La actividad feria de arrendamientos Coninsa se realizará
                  entre los días 01 de junio de 2020 y 31 de julio de 2020, en
                  las ciudades de Bogotá, Medellín y Barranquilla.
                </p>
              </li>
              <li>
                <h4>El organizador:</h4>
                <p>
                  La presente actividad está organizada por Coninsa Ramón H.
                  S.A. sociedad comercial con Nit 890.911.431-1 con domicilio
                  principal en la ciudad de Bogotá Av. Cra. 19 N° 114 – 65 piso
                  6 (57+1) 601 4800, regional Antioquia (57+4) 448 8828 y
                  regional Costa (57+5) 319 9610.
                </p>
              </li>
              <li>
                <h4>Objeto y mecánica de la actividad:</h4>
                <p>
                  Feria de arrendamientos Coninsa 2020, es una actividad en la
                  cual se otorgarán a los propietarios de los inmuebles uno (1),
                  dos (2) o tres (3) beneficios, según el monto del descuento
                  otorgado al contrato de arrendamiento durante la vigencia de
                  la feria, que podrán utilizar en el tiempo determinado del
                  contrato de arrendamiento. Dichos beneficios aplicarán
                  dependiendo del valor del descuento otorgado, que se definen a
                  partir de estos rangos:
                </p>

                <table>
                  <thead>
                    <tr>
                      <th>Rango descuentos otorgado por el propietario</th>
                      <th>Beneficio otorgado por Coninsa</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Por descuento otorgado del propietario desde $500.000
                        hasta $1.000.000
                      </td>
                      <td>
                        Garantía de Servicios públicos hasta por $1.000.000.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Por descuento otorgado del propietario desde $1.000.001
                        hasta $1.500.000
                      </td>
                      <td>
                        Garantía de Servicios públicos hasta por $1.000.000 +
                        Garantía de daños y faltantes hasta por $1.000.000.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Por descuento otorgado del propietario igual o superior
                        a $1.500.001
                      </td>
                      <td>
                        Garantía de Servicios públicos hasta por $1.000.000 +
                        Garantía de daños y faltantes hasta por $1.000.000 +
                        Aseo y desinfección del inmueble al momento de la
                        entrega al inquilino.
                      </td>
                    </tr>
                  </tbody>
                </table>

                <ol>
                  <li>
                    El total del descuento otorgado por el propietario, podrá
                    ser distribuido máximo hasta el canon correspondiente al mes
                    de diciembre de 2020.
                  </li>
                  <li>Solo aplica para contratos de doce (12) meses o más.</li>
                </ol>
              </li>

              <li>
                <h4>Disposiciones legales:</h4>
                <p>
                  Una vez al propietario se le haya otorgado el o los
                  beneficios, no se admitirán cambios por dinero, valores o
                  cualquier otro bien. El derecho al beneficio no es
                  transferible, negociable, ni puede ser comercializado o
                  canjeado por dinero en efectivo, ni por ningún otro premio.
                </p>
                <ol>
                  <li>
                    Participar en esta actividad implica para los clientes, la
                    total aceptación de los presentes términos y condiciones.
                  </li>
                  <li>
                    Los participantes se comprometen a eximir de responsabilidad
                    a Coninsa Ramón H. S.A., sus accionistas, empleados o
                    colaboradores y frente a cualquier acción, daño, costes, y
                    otras responsabilidades en las que pudiera incurrir Coninsa
                    Ramón H. S.A. como resultado de cualquier tipo de
                    incumplimiento por parte suya de alguno de los términos y
                    condiciones.
                  </li>
                  <li>
                    Al aceptar estos términos y condiciones los participantes
                    reconocen que de manera libre, voluntaria y espontánea han
                    decidido participar en esta actividad, por lo que dentro de
                    los límites permitidos por la ley colombiana, renuncian a
                    cualquier reclamación o acción de cualquier naturaleza en
                    contra de Coninsa Ramón H. S.A., sus accionistas, empleados
                    o colaboradores, que tengan por objeto reclamar
                    indemnización por daños o perjuicios que les haya causado su
                    participación en la actividad, o de cualquier manera
                    relacionados con esta, o por las actividades que realizare
                    como consecuencia de dicha participación. Si el premio
                    (descuento) no es aceptado o sus condiciones, se considerará
                    que ha renunciado al mismo y no tendrá derecho a reclamo o
                    indemnización alguna, ni siquiera parcialmente.
                  </li>
                </ol>
              </li>

              <li>
                <h4>Condiciones y restricciones:</h4>
                <p>
                  El cliente propietario podrá acceder a uno (1), dos (2) o tres
                  (3) beneficios, según el descuento otorgado al contrato de
                  arrendamiento.
                </p>
                <ol>
                  <li>
                    Para acceder a los beneficios la firma del contrato de
                    arrendamiento deberá ser antes del 31 de julio, es decir,
                    luego de que el cliente arrendatario visite el inmueble,
                    presente la solicitud de arrendamiento, sea aprobada y firme
                    el contrato.
                  </li>
                  <li>
                    Para acceder a los beneficios se deberán dar las condiciones
                    descritas en el presente documento.
                  </li>
                  <li>
                    El total del descuento otorgado por el propietario, podrá
                    ser distribuido máximo hasta el canon correspondiente al mes
                    de diciembre de 2020.
                  </li>
                  <li>Beneficios no acumulables con otras promociones.</li>
                  <li>
                    Los beneficios se pueden implementar durante la vigencia del
                    contrato o al finalizarlo.
                  </li>
                  <li>El descuento no es canjeable por dinero en efectivo.</li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </Container>
    </Section>
  );
}
