import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";

import FacetItem from "@coninsa-s2/facet-item";
import Button from "@coninsa-s2/button";

import ModalExposedForm from "@coninsa-site/project/src/pages/search-view/sections/modal-exposed-form";

const ALL_OPTION_VALUE = "Todos";

interface FacetType {
  name: string;
  values: Array<{ filter: string; count: number }>;
}

interface ExposedFormProps {
  facets: FacetType;
  setValue: (string, any) => void;
}

function getCustomFacetValues(facetKey) {
  const facets = {
    field_bedrooms: [
      { filter: ALL_OPTION_VALUE, label: ALL_OPTION_VALUE },
      { filter: 1, label: "1+" },
      { filter: 2, label: "2+" },
      { filter: 3, label: "3+" },
      { filter: 4, label: "4+" },
      { filter: 5, label: "5+" },
    ],
    field_bathrooms: [
      { filter: ALL_OPTION_VALUE, label: ALL_OPTION_VALUE },
      { filter: 1, label: "1+" },
      { filter: 2, label: "2+" },
      { filter: 3, label: "3+" },
      { filter: 4, label: "4+" },
    ],
    field_garages: [
      { filter: ALL_OPTION_VALUE, label: ALL_OPTION_VALUE },
      { filter: 1, label: "1+" },
      { filter: 2, label: "2+" },
      { filter: 3, label: "3+" },
      { filter: 4, label: "4+" },
      { filter: 5, label: "5+" },
    ],
    field_stratum: [
      { filter: ALL_OPTION_VALUE, label: ALL_OPTION_VALUE },
      { filter: 1, label: "1" },
      { filter: 2, label: "2" },
      { filter: 3, label: "3" },
      { filter: 4, label: "4" },
      { filter: 5, label: "5" },
      { filter: 5, label: "6" },
    ],
  };

  return facets[facetKey] ?? [];
}

const ExposedForm: React.FC<ExposedFormProps> = ({
  facets,
  parentDefaultValues,
  parentFormValues,

  resultCount,
  loading,

  setParentValue,
  closeModal,
}) => {
  const { register, handleSubmit, watch, control, setValue } = useForm({
    defaultValues: {
      exposed: parentFormValues,
    },
  });

  useEffect(() => {
    const subscription = watch((data, { name }) => {
      const parentName = name.replace("exposed.", "");
      setParentValue(parentName, data.exposed[parentName]);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const onsubmit = () => {};

  function getFacetValues(facetKey) {
    const facet = facets.find(({ name }) => name === facetKey);
    return facet?.values ? [{ filter: ALL_OPTION_VALUE }, ...facet.values] : [];
  }

  function resetFormValues() {
    for (const [filter, value] of Object.entries(parentDefaultValues)) {
      if (filter !== "text") {
        setValue(`exposed.${filter}`, value);
      }
    }
  }

  return (
    <ModalExposedForm
      resultCount={resultCount}
      loading={loading}
      closeModal={closeModal}
      resetValues={resetFormValues}
    >
      <form onSubmit={handleSubmit(onsubmit)} className="grid gap-8">
        <fieldset className="s2-fieldset s2-fieldset--tags">
          <legend className="s2-fieldset__legend">
            Precio desde
            <Button
              type="button"
              variant="ghost"
              shape="rounded"
              size="compact"
              onClick={() => {
                setValue("exposed.field_lease_value_from", "");
                setValue("exposed.field_lease_value_to", "");
              }}
            >
              Limpiar
            </Button>
          </legend>
          <div className="s2-fieldset__content">
            <div className="flex gap-4">
              <Controller
                control={control}
                name="exposed.field_lease_value_from"
                render={({ field }) => (
                  <NumberFormat
                    className="s2-input w-1/2"
                    placeholder="Min"
                    thousandSeparator="."
                    decimalSeparator=","
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name="exposed.field_lease_value_to"
                render={({ field }) => (
                  <NumberFormat
                    className="s2-input w-1/2"
                    placeholder="Max"
                    thousandSeparator="."
                    decimalSeparator=","
                    {...field}
                  />
                )}
              />
            </div>
          </div>
        </fieldset>

        <fieldset className="s2-fieldset s2-fieldset--tags">
          <legend className="s2-fieldset__legend">Ciudad</legend>
          <div className="s2-fieldset__content">
            {getFacetValues("city_string").map((item, index) => {
              const filterId = `exposed-city_string-${index}`;

              return (
                <FacetItem key={filterId}>
                  <input
                    type="radio"
                    {...register("exposed.city_string")}
                    value={item.filter}
                    id={filterId}
                  />
                  <label htmlFor={filterId} tabIndex={0}>
                    {item.filter}
                    <span>({item.count})</span>
                  </label>
                </FacetItem>
              );
            })}
          </div>
        </fieldset>

        <fieldset className="s2-fieldset s2-fieldset--tags">
          <legend className="s2-fieldset__legend">Tipo de inmueble</legend>
          <div className="s2-fieldset__content">
            {getFacetValues("property_type").map((item, index) => {
              const filterId = `exposed-property_type-${index}`;

              return (
                <FacetItem key={filterId}>
                  <input
                    type="radio"
                    {...register("exposed.property_type")}
                    value={item.filter}
                    id={filterId}
                  />
                  <label htmlFor={filterId} tabIndex={0}>
                    {item.filter}
                    <span>({item.count})</span>
                  </label>
                </FacetItem>
              );
            })}
          </div>
        </fieldset>

        <fieldset className="s2-fieldset s2-fieldset--tags">
          <legend className="s2-fieldset__legend">Alcobas</legend>
          <div className="s2-fieldset__content">
            {getCustomFacetValues("field_bedrooms").map((item, index) => {
              const filterId = `exposed-field_bedrooms-${index}`;

              return (
                <FacetItem key={filterId}>
                  <input
                    type="radio"
                    {...register("exposed.field_bedrooms")}
                    value={item.filter}
                    id={filterId}
                  />
                  <label htmlFor={filterId} tabIndex={0}>
                    {item.label}
                  </label>
                </FacetItem>
              );
            })}
          </div>
        </fieldset>

        <fieldset className="s2-fieldset s2-fieldset--tags">
          <legend className="s2-fieldset__legend">Baños</legend>
          <div className="s2-fieldset__content">
            {getCustomFacetValues("field_bathrooms").map((item, index) => {
              const filterId = `exposed-field_bathrooms-${index}`;

              return (
                <FacetItem key={filterId}>
                  <input
                    type="radio"
                    {...register("exposed.field_bathrooms")}
                    value={item.filter}
                    id={filterId}
                  />
                  <label htmlFor={filterId} tabIndex={0}>
                    {item.label}
                  </label>
                </FacetItem>
              );
            })}
          </div>
        </fieldset>

        <fieldset className="s2-fieldset s2-fieldset--tags">
          <legend className="s2-fieldset__legend">Garajes</legend>
          <div className="s2-fieldset__content">
            {getCustomFacetValues("field_garages").map((item, index) => {
              const filterId = `exposed-field_garages-${index}`;

              return (
                <FacetItem key={filterId}>
                  <input
                    type="radio"
                    {...register("exposed.field_garages")}
                    value={item.filter}
                    id={filterId}
                  />
                  <label htmlFor={filterId} tabIndex={0}>
                    {item.label}
                  </label>
                </FacetItem>
              );
            })}
          </div>
        </fieldset>

        <fieldset className="s2-fieldset s2-fieldset--tags">
          <legend className="s2-fieldset__legend">Estrato</legend>
          <div className="s2-fieldset__content">
            {getCustomFacetValues("field_stratum").map((item, index) => {
              const filterId = `exposed-field_stratum-${index}`;

              return (
                <FacetItem key={filterId}>
                  <input
                    type="radio"
                    {...register("exposed.field_stratum")}
                    value={item.filter}
                    id={filterId}
                  />
                  <label htmlFor={filterId} tabIndex={0}>
                    {item.label}
                  </label>
                </FacetItem>
              );
            })}
          </div>
        </fieldset>

        <fieldset className="s2-fieldset s2-fieldset--tags">
          <legend className="s2-fieldset__legend">
            Área
            <Button
              type="button"
              variant="ghost"
              shape="rounded"
              size="compact"
              onClick={() => {
                setValue("exposed.field_area_from", "");
                setValue("exposed.field_area_to", "");
              }}
            >
              Limpiar
            </Button>
          </legend>
          <div className="s2-fieldset__content">
            <div className="flex gap-4">
              <Controller
                control={control}
                name="exposed.field_area_from"
                render={({ field }) => (
                  <NumberFormat
                    className="s2-input w-1/2"
                    placeholder="Min"
                    thousandSeparator="."
                    decimalSeparator=","
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name="exposed.field_area_to"
                render={({ field }) => (
                  <NumberFormat
                    className="s2-input w-1/2"
                    placeholder="Max"
                    thousandSeparator="."
                    decimalSeparator=","
                    {...field}
                  />
                )}
              />
            </div>
          </div>
        </fieldset>
      </form>
    </ModalExposedForm>
  );
};

export default ExposedForm;
