import React, { useRef, useState } from 'react'
import { Box, Input } from 'theme-ui'
import { useMutation } from '@apollo/client'
import { useController } from 'react-hook-form'
import PropTypes from 'prop-types'

import FileList from './components/FileList'

import { FILES_UPLOAD } from './mutations'

function FilesInput({ accept, name, control, max = 3 }) {
  const [mutation, { loading }] = useMutation(FILES_UPLOAD)
  const {
    field: { ref, value = [], onChange, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    // rules: { required: true },
    defaultValue: [],
  })

  const inputRef = useRef(null)

  const handleChange = e => {
    const { files } = e.target

    if (files.length) {
      mutation({ variables: { files } })
        .then(response => {
          const { data } = response
          const newValues = data.entities.map(entity => ({
            targetId: entity.fid,
            entity,
          }))

          onChange([...value, ...newValues])

          inputRef.current.value = ''
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  const onRemove = excludeIndex => () => {
    const filteredValues = value.filter((_, index) => index !== excludeIndex)
    onChange(filteredValues)
  }

  return (
    <Box>
      {value?.length > 0 && (
        <FileList items={value.map(item => item.entity)} onRemove={onRemove} />
      )}

      {value?.length < max && (
        <Input
          accept={accept}
          type='file'
          onChange={handleChange}
          disabled={loading}
          multiple
          ref={inputRef}
        />
      )}
    </Box>
  )
}

FilesInput.defaultProps = {
  max: 10,
}

FilesInput.propTypes = {
  max: PropTypes.number,
}

export default FilesInput
