import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid } from 'theme-ui'
import Carousel from 'nuka-carousel'

import { Header, Section } from 'ui'
import BuildingFaq from '../components/BuildingFaq'

function MoreInfo({ adviserUrl, code, pdfUrl, creditUrl, isSoldOut }) {
  const titleStyles = {
    m: 'auto 0',
  }

  return (
    <Section>
      <Box sx={{ display: ['block', 'none'] }}>
        <Box sx={titleStyles}>
          <Header>¿Necesitas más información?</Header>
          <Box mb='medium'>
            Agenda una visita, descarga la ficha del inmueble y simula tu crédito.
          </Box>
        </Box>

        <Box sx={{ height: '310px' }}>
          <Carousel withoutControls slidesToShow='1.30'>
            {!isSoldOut && (
              <BuildingFaq
                bg='green-1'
                cardBg='green-1'
                icon='fa-whatsapp'
                label='Agenda una visita'
                description='Uno de nuestros asesores te está esperando para conocer el inmueble.'
                url={adviserUrl}
              />
            )}

            <BuildingFaq
              bg='neutral'
              cardBg='neutral'
              icon='fa-reg-file-pdf'
              label='Descargar PDF'
              description='Ten a la mano la información de tu inmueble y compártela con los que más quieres.'
              url={pdfUrl}
            />
          </Carousel>
        </Box>
      </Box>

      <Grid columns={3} sx={{ display: ['none', 'grid'] }}>
        <Box sx={titleStyles}>
          <Header>¿Necesitas más información?</Header>
          <Box>
            Agenda una visita, descarga la ficha del inmueble y simula tu crédito.
          </Box>
        </Box>

        {!isSoldOut && (
          <BuildingFaq
            bg='green-1'
            cardBg='green-1'
            icon='fa-whatsapp'
            label='Agenda una visita'
            description='Uno de nuestros asesores te está esperando para conocer el inmueble.'
            url={adviserUrl}
          />
        )}

        <BuildingFaq
          bg='neutral'
          cardBg='neutral'
          icon='fa-reg-file-pdf'
          label='Descargar PDF'
          description='Ten a la mano la información de tu inmueble y compártela con los que más quieres.'
          url={pdfUrl}
        />
      </Grid>
    </Section>
  )
}

MoreInfo.propTypes = {
  adviserUrl: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  pdfUrl: PropTypes.string.isRequired,
  creditUrl: PropTypes.string.isRequired,
  isSoldOut: PropTypes.bool.isRequired,
}

export default MoreInfo
