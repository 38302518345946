import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

import IconCap from 'ui/IconCap'

const FeaturedListItem = ({ children, icon, variant, ...props }) => {
  const baseStyles = {
    display: 'flex',
    mb: ['medium', 'large'],
  }

  return (
    <Box __css={baseStyles} variant={variant} {...props} __themeKey='featuredItems'>
      <IconCap
        sx={{ mr: ['small', 'medium'], variant: `featuredItems.${variant}.icon` }}
        icon={icon}
      />
      <div>{children}</div>
    </Box>
  )
}

FeaturedListItem.defaultProps = {
  children: undefined,
  variant: 'default',
}

FeaturedListItem.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.string.isRequired,
  variant: PropTypes.string,
}

export default FeaturedListItem
