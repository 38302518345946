import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import debounce from "lodash.debounce";
import Section from "@coninsa-s2/section";
import Heading from "@coninsa-s2/heading";
import Container from "@coninsa-s2/container";
import Facet from "@coninsa-s2/facet";
import FacetItem from "@coninsa-s2/facet-item";
import Button from "@coninsa-s2/button";
import Breadcrumb, { BreadcrumbItem } from "@coninsa-s2/breadcrumb";
import FilterIcon from "@coninsa-s2/icon/assets/filter.inline.svg";

const ALL_OPTION_VALUE = "Todos";

interface FacetType {
  name: string;
  values: Array<{ filter: string; count: number }>;
}

interface SearcBarProps {
  initialFacets: Array<FacetType>;
  refetch: () => void;
  navigate: (string) => void;
}

const SearcBar: React.FC<SearcBarProps> = ({
  initialFacets,

  refetch,
  navigate,

  configs,
}) => {
  const [facets, setFacets] = useState<Array<FacetType>>([]);
  const [initFacets, setInitFacets] = useState(false);

  const { register, handleSubmit, watch, control, setValue } = useForm({
    defaultValues: configs.initialValues,
  });

  const form = watch();

  useEffect(() => {
    const debounceCb = debounce((data) => {
      const defaultQuery = configs.getSearchQuery(data);
      const newPathname = configs.getPrettyPaths(data);

      navigate(newPathname);
      refetch(defaultQuery);
    }, 750);

    const subscription = watch(debounceCb);

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (initialFacets && !initFacets) {
      // Set facets.
      setFacets(initialFacets);
      setInitFacets(true);

      // Get active filters.
      const activeFilters = configs.getActiveFacets(initialFacets);

      // Set active filters.
      setTimeout(() => {
        for (const [filter, value] of Object.entries(activeFilters)) {
          setValue(filter, value);
        }
      });
    }
  }, [initialFacets]);

  const onsubmit = () => {};

  function getFacetValues(facetKey) {
    const facet = facets.find(({ name }) => name === facetKey);

    return facet?.values ? [{ filter: ALL_OPTION_VALUE }, ...facet.values] : [];
  }

  return (
    <div>
      <Section tint="light-gray" className="py-4">
        <Container>
          <Breadcrumb>
            {configs.breadcrumbItems.map((item) => (
              <BreadcrumbItem>
                {item.url && <a href={item.url}>{item.label}</a>}
                {!item.url && item.label}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>

          <Heading as="h1" size="xl" weight="bold" className="mb-6">
            {configs.title}
          </Heading>

          <form onSubmit={handleSubmit(onsubmit)}>
            <div className="grid grid-cols-2 flex-wrap gap-2 md:gap-4 lg:grid-cols-12 xl:flex">
              <div className="col-span-2 flex gap-2 md:gap-4 lg:col-span-4 xl:w-2/5">
                <input
                  placeholder="Ciudad, zona, localidad o barrio"
                  className="s2-input flex-1"
                  type="search"
                  {...register("text")}
                />
              </div>

              <Facet
                label="Ubicación"
                icon="pin"
                current={form.department_project}
                showValue={form.department_project !== ALL_OPTION_VALUE}
                className="lg:col-span-3"
              >
                <div className="flex max-w-sm flex-wrap gap-2">
                  {getFacetValues("department_project").map((item, index) => {
                    const filterId = `facet-item--department_project-${index}`;

                    return (
                      <FacetItem key={filterId}>
                        {item.filter !== "Magdalena" && (
                          <div>
                            <input
                              type="radio"
                              {...register("department_project")}
                              value={item.filter}
                              id={filterId}
                            />
                            <label htmlFor={filterId}>
                              {item.filter}
                              <span>({item.count})</span>
                            </label>
                          </div>
                        )}
                      </FacetItem>
                    );
                  })}
                </div>
              </Facet>
            </div>
          </form>
        </Container>
      </Section>
    </div>
  );
};

export default SearcBar;
