import React from 'react'
import { Box, Grid } from 'theme-ui'

import { Header, List, Section } from 'ui'

function InterestPoints() {
	return (
		<Section title={<Header>Puntos de interés</Header>}>
			<Box sx={{ fontSize: [1, 2] }}>
				<Grid columns={[2, '1fr 1fr 1fr 1fr']}>
					<Box>
						<Box sx={{ color: 'neutral', fontWeight: 'bold', mb: 3 }}>
							Centros comerciales
            </Box>
						<List color='#71A82A'>
							<li>Premium Plaza</li>
							<li>Punto Clave</li>
							<li>Monterrey</li>
						</List>
					</Box>
					<Box>
						<Box sx={{ color: 'neutral', fontWeight: 'bold', mb: 3 }}>
							Supermercados
            </Box>
						<List color='#71A82A'>
							<li>Jumbo</li>
							<li>Justo y Bueno</li>
							<li>Dollar City</li>
							<li>Éxito Express</li>
							<li>Éxito El Poblado</li>
						</List>
					</Box>
					<Box>
						<Box sx={{ color: 'neutral', fontWeight: 'bold', mb: 3 }}>
							Centros Bancarios
            </Box>
						<List color='#71A82A'>
							<li>Bancolombia</li>
							<li>Banco de Occidente</li>
							<li>Colpatria</li>
							<li>BBVA</li>
						</List>
					</Box>
					<Box>
						<Box sx={{ color: 'neutral', fontWeight: 'bold', mb: 3 }}>
							Centros Centros de Salud
            </Box>
						<List color='#71A82A'>
							<li>Clínica del Prado</li>
							<li>Torre Médica Ciudad del Río</li>
							<li>SURA Salud</li>
							<li>EMI</li>
							<li>Clínica Oftalmológica San Diego</li>
						</List>
					</Box>
					<Box>
						<Box sx={{ color: 'neutral', fontWeight: 'bold', mb: 3 }}>
							Centros Culturales
            </Box>
						<List color='#71A82A'>
							<li>Museo Arte Moderno</li>
							<li>Parque Lineal</li>
						</List>
					</Box>
					<Box>
						<Box sx={{ color: 'neutral', fontWeight: 'bold', mb: 3 }}>
							Estaciones de Servicio
            </Box>
						<List color='#71A82A'>
							<li>Esso (2)</li>
							<li>Terpel (2)</li>
						</List>
					</Box>
					<Box>
						<Box sx={{ color: 'neutral', fontWeight: 'bold', mb: 3 }}>
							Institucionales
            </Box>
						<List color='#71A82A'>
							<li>Cámara Colombiana de Infraestructura</li>
							<li>Telemedellín</li>
							<li>Comfama</li>
							<li>CDA El poblado</li>
						</List>
					</Box>
					<Box>
						<Box sx={{ color: 'neutral', fontWeight: 'bold', mb: 3 }}>
							Notarias
            </Box>
						<List color='#71A82A'>
							<li>Notaría 15</li>
							<li>Notaría 22</li>
						</List>
					</Box>
					<Box>
						<Box sx={{ color: 'neutral', fontWeight: 'bold', mb: 3 }}>
							Servicios
            </Box>
						<List color='#71A82A'>
							<li>U-storage</li>
							<li>Homecenter</li>
							<li>Pintuco</li>
							<li>Astor</li>
							<li>Alpina</li>
							<li>SURA Seguros</li>
						</List>
					</Box>
					<Box>
						<Box sx={{ color: 'neutral', fontWeight: 'bold', mb: 3 }}>
							Vías principales
            </Box>
						<List color='#71A82A'>
							<li>Calle 30 Av. El Poblado</li>
							<li>Av. Las Vegas</li>
							<li>Autopista Regional</li>
							<li>Autopista Sur</li>
						</List>
					</Box>
					<Box>
						<Box sx={{ color: 'neutral', fontWeight: 'bold', mb: 3 }}>
							Transporte
            </Box>
						<List color='#71A82A'>
							<li>Metro Industriales</li>
							<li>Metroplus Buses</li>
							<li>Encycla Cicluruta</li>
						</List>
					</Box>
					<Box>
						<Box sx={{ color: 'neutral', fontWeight: 'bold', mb: 3 }}>
							Centros Automotriz
            </Box>
						<List color='#71A82A'>
							<li>Centro Automotriz Mazda</li>
							<li>Renault Toyota</li>
							<li>Andar SURA motos</li>
						</List>
					</Box>
				</Grid>
			</Box>
		</Section>
	)
}

export default InterestPoints