export class CreditoHipotecario {
  constructor(
    public index: number,
    public valorCredito: number,
    public tasaPeriodo: number,
    public valorCuota: number,
    public ingresosReq: number,
    public valorCuotaUVR: number,
    public ingresosReqUVR: number
  ) {
  }
}
