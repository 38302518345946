export const getOptions = options =>
  options.map((option = '') => {
    const arr = option.split('|')
    return {
      value: arr[0],
      label: arr[1],
    }
  })

export const activities = [
  'agricola-ganadera-minera|Agricola-Ganadera-Minera',
  'rentista-capital-inmueble|Rentista capital inmueble',
  'transportador|Transportador',
  'rentista-capital-portafolio|Rentista capital portafolio',
  'Comerciante|comerciante',
  'religioso|Religioso',
]

export const contracts = [
  'parcial|Parcial',
  'definido|Definido',
  'indefinido|Indefinido',
  'obra-labor|Obra labor',
  'relevo|Relevo',
  'formativo|Formativo',
]

export const genders = ['M|Masculino', 'F|Femenino', 'O|Otro']

export const idTypes = ['cc|C.C', 'ce|C.E', 'pas|PAS']

export const maritalStatus = [
  'soltero|Soltero',
  'union-libre|Unión libre',
  'casado|Casado',
  'divorciado|Divorciado',
  'viudo|Viudo',
]

export const occupations = [
  'pensionado|Pensionado',
  'empleado-pensionado|Empleado-pensionado',
  'empleado|Empleado',
  'empleado-socio|Empleado-socio',
  'socio|Socio',
  'estudiante|Estudiante',
  'independiente-profesional|Independiente profesional',
  'independiente-no-profesional|Independiente no profesional',
]

export const salesResponsibles = ['comun|Común', 'simplificado|Simplificado']

export const companyTypes = ['privada|Privada', 'publica|Pública', 'mixta|Mixta']

export const academicBackground = [
  'Bachiller|Bachiller',
  'Tecnico|Técnico',
  'Tecnologo|Tecnólogo',
  'Pregrado|Pregrado',
  'Postgrado|Postgrado',
  'Diplomados|Diplomados',
  'Especializaciones|Especializaciones',
]
