import React from "react";
import Layout from "../../../layout/default/Layout";
import { Router, navigate } from "@reach/router";

import { RentAdvancePage } from "@coninsa-site/terms-and-conditions/pages/property";


function RentAdvance() {
  return (
    <Layout>
      <Router basepath="/terminos-y-condiciones/arrendamientos/anticipo-de-renta">
        <RentAdvancePage path="*" navigate={navigate} />
      </Router>
    </Layout>
  );
}

export default RentAdvance;
