import React from "react";

import ReferrerForm from "./referrer-form";

import AdCard from "@coninsa-s2/ad-card";
import Button from "@coninsa-s2/button";

function ProjectBody() {
  return (
    <div>
      <ReferrerForm />
      <div className="bg-gradient-to-b from-s2-gray-50 from-50% to-s2-teal to-50% pb-8 pt-10">
        <div className="md:container md:max-w-5xl">
          <AdCard tint="blue">
            <div className="s2-ad-card__content">
              <div>
                <h4 className="s2-ad-card__title">Conoce nuestros proyectos</h4>
                <p className="s2-ad-card__description">
                  Encuentra la oferta de proyectos nuevos de vivienda que
                  tenemos en{" "}
                  <strong>Antioquia, Bogotá y Costa Atlántica</strong>.
                </p>
              </div>
              <Button
                href="/proyectos/nuevo/tipo-de-servicio/vivienda"
                shape="square"
                tint="white"
              >
                VER PROYECTOS DE VIVIENDA
              </Button>
            </div>
          </AdCard>
        </div>
      </div>

      <div className="co-section bg-s2-teal py-8">
        <div className="container flex max-w-4xl flex-col items-center text-center">
          <h2 className="co-heading-2 is-teal mb-2">
            <span className="is-normal">
              Si conoces a alguien que quiera comprar o invertir
            </span>{" "}
            desde el Exterior,
            <span className="is-normal"> también puedes referirlo.</span>
          </h2>

          <p className="max-w-xl text-center text-lg text-white md:text-xl">
            Contamos con{" "}
            <span className="font-bold"> un equipo exclusivo </span>
            para brindar asesoría personalizada logrando un
            <span className="font-bold"> proceso fácil y ágil.</span>
          </p>
        </div>
      </div>

      <div className="co-section bg-s2-mint py-6 text-white">
        <div className="container text-center">
          <a
            className="co-link text-sm font-bold md:text-xl"
            href="https://www.coninsa.co/legal-referidos-proyectos"
          >
            * Conoce los términos y condiciones de esta promoción
          </a>
        </div>
      </div>
    </div>
  );
}

export default ProjectBody;
