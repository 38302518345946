import Landing from "./src/pages/landing";

import SearchRentalHousing from "./src/pages/search-rental-housing";
import SearchRentalBusiness from "./src/pages/search-rental-business";
import SearchBuyProperty from "./src/pages/search-buy-properties";
import SearchFeaturedProperty from "./src/pages/search-featured-properties";

export const LandingPage = Landing;

export const SearchRentalHousingPage = SearchRentalHousing;
export const SearchRentalBusinessPage = SearchRentalBusiness;
export const SearchBuyPropertyPage = SearchBuyProperty;
export const SearchFeaturedPropertyPage = SearchFeaturedProperty;
