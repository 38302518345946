import React from 'react';
import { Page as AdvertisePropertyPage } from '@coninsa-site/advertise-property';

import Layout from '../../layout/default/Layout';

export default function advertiseProperty() {
  return (
    <Layout>
      <AdvertisePropertyPage />
    </Layout>
  );
}
