/** @jsx jsx */
import { jsx } from 'theme-ui'
import DefaultCreatableSelect from 'react-select/creatable'

const CreatableSelect = ({ variant, sx, ...props }) => {
  const variantStyles = {
    search: {
      '& .select__control': {
        minHeight: ['60px', '70px'],
        borderRadius: '0',
      },

      '& .select__indicator-separator': {
        display: 'none',
      },
    },
  }

  const styles = variantStyles[variant]

  return <DefaultCreatableSelect {...props} sx={{ ...styles, ...sx }} />
}

CreatableSelect.defaultProps = {
  classNamePrefix: 'select',
  variant: 'default',
  sx: {},
  createOptionPosition: 'first',
}

export default CreatableSelect
