import { gql } from "@apollo/client";

export const CREATE_ADVERTISER_USER = gql`
  mutation CreateAdvertiserUser($phone: String!) {
    createAdvertiserUser(phone: $phone) {
      status
      type
      message
    }
  }
`;

export const CHECK_ADVERTISER_USER_CODE = gql`
  mutation CheckAdvertiserUserCode($phone: String!, $code: String!) {
    checkAdvertiserUserCode(phone: $phone, code: $code) {
      status
      type
      message
    }
  }
`;

export const CREATE_BUILDING_PROPERTY = gql`
  mutation CreateBuildingProperty($input: BuildingPropertyCreateInput!) {
    createBuildingProperty(input: $input) {
      violations {
        code
        message
      }
      errors
      entity {
        entityId
      }
    }
  }
`;

export const UPDATE_BUILDING_PROPERTY = gql`
  mutation UpdateBuildingProperty(
    $id: String!
    $input: BuildingPropertyUpdateInput!
  ) {
    updateBuildingProperty(id: $id, input: $input) {
      violations {
        code
        message
      }
      errors
      entity {
        entityId

        ... on BuildingProperty {
          fieldBedrooms
        }
      }
    }
  }
`;
