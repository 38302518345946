import React from "react";
import clsx from "clsx";
import rightArrowUrl from "./images/right-arrow.png";

interface NumberCardProps {
  tint?: "dark-blue" | "lime";
  number: number;
  children: React.ReactNode;
  className?: string;
  withArrow?: boolean;
}

export const NumberCard: React.FC<NumberCardProps> = ({
  number,
  className,
  children,
  tint = "dark-blue",
  withArrow = false,
}) => {
  return (
    <div className={clsx(className)}>
      <div className="mb-4 flex items-center justify-between">
        <div
          className={clsx(
            "flex h-10 w-10 shrink-0 items-center justify-center rounded-full font-bold text-white",
            tint === "dark-blue" && "bg-s2-dark-blue",
            tint === "lime" && "bg-s2-lime"
          )}
        >
          {number}
        </div>
        {withArrow && <img className="w-3/4" src={rightArrowUrl} />}
      </div>
      <div className="text-balance">{children}</div>
    </div>
  );
};

export default NumberCard;
