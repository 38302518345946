export default {
  default: {
    item: {
      mb: 'small',
      header: {
        color: 'text',
      },
      content: {
        bg: 'background',
      },
    },
  },
  leasing: {
    item: {
      mb: 'small',
      header: {
        color: 'neutral',
        fontSize: ['16px', '18px'],
        lineHeight: ['20px', 'auto'],
        fontWeight: 'semibold',
      },
      icon: {
        color: '#00706A',
      },
      content: {
        bg: 'background',
      },
    },
  },
  mini: {
    item: {
      header: {
        color: 'neutral',
        fontWeight: 'bold',
        mb: 'small',
      },

      content: {
        bg: 'background',
        borderTop: '1px solid',
        borderColor: 'gray',

        '&.expanded': {
          pt: 'small',
        },
      },
    },
  },
  accordion: {
    item: {
      mb: 'medium',
      header: {
        bg: 'primary',
        borderRadius: '6px',
        color: 'neutral',
        py: '14px',
        px: '16px',
        textTransform: 'uppercase',
        fontWeight: '400',
        fontFamily: 'heading',
      },
      icon: {
        fontSize: '24px',
      },
      content: {
        bg: 'light-1',

        '&.expanded': {
          p: 'medium',
        },
      },
    },
  },
  grayly: {
    item: {
      mb: 'medium',
      header: {
        bg: 'light-3',
        borderRadius: '6px',
        color: 'neutral',
        py: '14px',
        px: '16px',
        textTransform: 'uppercase',
        fontWeight: '400',
        fontFamily: 'heading',
      },
      icon: {
        fontSize: '24px',
      },
      content: {
        bg: 'light-0',

        '&.expanded': {
          p: 'medium',
        },
      },
    },
  },
  plain: {
    item: {
      mb: 'medium',
      header: {
        fontWeight: 'bold',
        color: 'white',
        mb: 'medium',
      },
      content: {
        mb: 'large',
      },
    },
  },
}
