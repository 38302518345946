import { gql } from '@apollo/client'

export const SEARCH_RENT_PROPERTIES_QUERY = gql`
  query SearchRentProperties($text: String!) {
    search: searchAPISearch(
      index_id: "search_properties"
      facets: [
        {
          field: "string_location"
          min_count: 1
          missing: true
          operator: "="
          limit: 1000
        }
        {
          field: "property_type"
          min_count: 1
          missing: false
          operator: "="
          limit: 100
        }
        {
          field: "field_property_type"
          min_count: 1
          missing: false
          operator: "="
          limit: 10
        }
      ]
      conditions: [
        { name: "field_service_type", value: "AR", operator: "=" }
        { name: "status", value: "1", operator: "=" }
        { name: "field_publish", value: "1", operator: "=" }
        { name: "string_location", value: "!", operator: "<>" }
        { name: "field_lease_value", value: "0", operator: "<>" }
        { name: "field_commerce_housing", value: "1", operator: "=" }
      ]
      fulltext: { keys: [$text] }
    ) {
      facets {
        name
        values {
          count
          filter
        }
      }
    }
  }
`

export const SEARCH_RENT_PROPERTIES_COMMERCIAL_QUERY = gql`
  query SearchRentPropertiesCommercial($text: String!) {
    search: searchAPISearch(
      index_id: "search_properties"
      facets: [
        {
          field: "string_location"
          min_count: 1
          missing: true
          operator: "="
          limit: 1000
        }
        {
          field: "property_type"
          min_count: 1
          missing: false
          operator: "="
          limit: 100
        }
        {
          field: "field_property_type"
          min_count: 1
          missing: false
          operator: "="
          limit: 10
        }
      ]
      conditions: [
        { name: "field_service_type", value: "AR", operator: "=" }
        { name: "status", value: "1", operator: "=" }
        { name: "field_publish", value: "1", operator: "=" }
        { name: "string_location", value: "!", operator: "<>" }
        { name: "field_lease_value", value: "0", operator: "<>" }
        { name: "field_commerce_housing", value: "0", operator: "=" }
      ]
      fulltext: { keys: [$text] }
    ) {
      facets {
        name
        values {
          count
          filter
        }
      }
    }
  }
`

export const SEARCH_BUY_PROPERTIES_QUERY = gql`
  query SearchBuyProperties($text: String!) {
    search: searchAPISearch(
      index_id: "search_properties"
      facets: [
        {
          field: "string_location"
          min_count: 1
          missing: true
          operator: "="
          limit: 1000
        }
        {
          field: "property_type"
          min_count: 1
          missing: false
          operator: "="
          limit: 100
        }
        {
          field: "field_property_type"
          min_count: 1
          missing: false
          operator: "="
          limit: 10
        }
      ]
      conditions: [
        { name: "field_service_type", value: "CO", operator: "=" }
        { name: "status", value: "1", operator: "=" }
        { name: "field_publish", value: "1", operator: "=" }
        { name: "string_location", value: "!", operator: "<>" }
        { name: "sale_value", value: "0", operator: "<>" }
      ]
      fulltext: { keys: [$text] }
    ) {
      facets {
        name
        values {
          count
          filter
        }
      }
    }
  }
`

export const SEARCH_PROPERTY_BY_CODE = gql`
  query BuildingByCode($code: String!) {
    building: buildingByCode(code: $code) {
      id: entityId
      code
      url: entityUrl {
        path
      }
      propertyUrl: entityUrl {
        path
      }
    }
  }
`
