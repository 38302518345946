import React from 'react'
import PropTypes from 'prop-types'

import { Section, Header, Slider } from 'ui'
import { paragraphResolver } from '.'
import bg from '../images/card-banner.png'

function PMemberSection(props) {
  const { cards, header } = props

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  }

  return (
    <Section background={{ src: bg }} >
      <Header sx={{ color: 'white', textAlign: 'center' }}>{header.title}</Header>

      <Slider {...settings}>
        {cards.map((card, index) => paragraphResolver(card, index))}
      </Slider>
    </Section>
  )
}

PMemberSection.defaultProps = {
  cards: [],
}

PMemberSection.propTypes = {
  cards: PropTypes.arrayOf(),
  header: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
}

export default PMemberSection
