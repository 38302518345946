import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

function MemberCardPhoto({ children, ...props }) {
  return (
    <Box __css={{ mb: ['medium', 'small'] }} {...props}>
      {children}
    </Box>
  )
}

MemberCardPhoto.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MemberCardPhoto
