import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

const CardTitle = ({ ...props }) => {
  const baseStyles = {
    color: 'secondary',
    fontSize: ['16px', '18px'],
    lineHeight: '1.25',
    mb: 'small',
  }

  return <Box as='h3' __css={baseStyles} {...props} />
}

CardTitle.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CardTitle
