import React from "react";

import Banner from "./src/sections/banner";
import ListCard from "./src/sections/list-card";
import ProjectBody from "./src/sections/project-body";

function ReferProjects() {
  return (
    <>
      <Banner />
      <div className="bg-gradient-to-b from-s2-teal from-50% to-white to-50%">
        <div className="lg:-translate-y-12">
          <ListCard />
        </div>
      </div>
      <ProjectBody />
    </>
  );
}

export default ReferProjects;
