import React from 'react'
import { navigate } from 'gatsby'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { Box } from 'theme-ui'

import { Collapse, Heading } from 'ui'

import { UPDATE_NODE_NATURAL_PERSON_APPLICATION } from '../../mutations'
import { NATURAL_PERSON_APPLICATION } from '../../queries'

import HeaderForm from '../../components/HeaderForm'
import FooterForm from '../../components/FooterForm'
import NaturalProperties from '../generic/NaturalProperties'
import BasicFinancialInformation from '../generic/BasicFinancialInformation'
import NaturalReferences from '../generic/NaturalReferences'
import { getEntity, cleanData } from '../utils'

function FinancialInformation(props) {
  const loadedPerson = props?.loadedPerson || {}
  const [mutation] = useMutation(UPDATE_NODE_NATURAL_PERSON_APPLICATION, {
    refetchQueries: [
      { query: NATURAL_PERSON_APPLICATION, variables: { id: props.id } },
    ],
  })
  const { control, register, handleSubmit, setValue } = useForm({
    defaultValues: loadedPerson,
  })

  const onSubmit = async data => {
    const variables = cleanData(data)

    return mutation({ variables })
      .then(response => {
        const entity = getEntity(response)

        if (entity) {
          navigate('?path=/documentacion')
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <section>
      <HeaderForm step='2/4' title='Información Financiera' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Collapse variant='grayly'>
          <Collapse.Item title='Propiedades' index={1}>
            <NaturalProperties register={register} />
          </Collapse.Item>
        </Collapse>

        <Box mb='medium'>
          <Heading level='3' variant='form'>
            Información Financiera
          </Heading>
          <BasicFinancialInformation register={register} />
        </Box>

        <Collapse variant='grayly'>
          <Collapse.Item title='Referencias' index={1}>
            <NaturalReferences
              control={control}
              register={register}
              setValue={setValue}
            />
          </Collapse.Item>
        </Collapse>

        <FooterForm back />
      </form>
    </section>
  )
}

export default FinancialInformation
