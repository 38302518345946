export default [
  {
    columns: null,
    label: "Nuestro portafolio",
    nolink: false,
    url: {
      path: "",
    },
    links: [
      {
        columns: null,
        label: "Arrendamientos y venta de usados",
        nolink: false,
        url: {
          path: "/arrendamientos/",
        },
        links: [],
      },
      {
        columns: null,
        label: "Proyectos nuevos",
        nolink: false,
        url: {
          path: "/proyectos/",
        },
        links: [],
      },
      {
        columns: null,
        label: "Diseño arquitectónico y técnico",
        nolink: false,
        url: {
          path: "/arquitectura/",
        },
        links: [],
      },
      {
        columns: null,
        label: "Construcción de edificaciones e infraestructura",
        nolink: false,
        url: {
          path: "/construccion/",
        },
        links: [],
      },
    ],
  },
  {
    columns: "repeat(3, 1fr)",
    label: "Servicio al cliente",
    nolink: false,
    url: {
      path: "/servicio-cliente/",
    },
    links: [],
  },
  {
    columns: null,
    label: "Invierte desde el exterior",
    nolink: false,
    url: {
      path: "/desde-el-exterior",
    },
    links: [],
  },
  {
    columns: null,
    label: "Referidos",
    nolink: false,
    url: {
      path: "/referidos",
    },
    links: [
      {
        columns: null,
        label: "Referir para proyectos nuevos",
        nolink: false,
        url: {
          path: "/referidos/proyectos",
        },
        links: [],
      },
      {
        columns: null,
        label: "Referir para arriendos",
        nolink: false,
        url: {
          path: "/referidos/arrendamientos",
        },
        links: [],
      },
    ],
  },
  {
    columns: null,
    label: "Blog",
    nolink: false,
    url: {
      path: "https://blog.coninsa.co/",
    },
    links: [],
  },
  {
    columns: "repeat(2, 1fr)",
    label: "Nosotros",
    nolink: false,
    url: {
      path: "/somos-coninsa",
    },
    links: [
      {
        columns: null,
        icon: {
          name: "co-building",
        },
        label: "Servicios para empresas",
        nolink: true,
        url: {
          path: "",
        },
        links: [
          {
            columns: null,
            icon: {
              name: null,
            },
            label: "Consultoría inmobiliaria",
            nolink: false,
            url: {
              path: "/empresas/consultoria-inmobiliaria",
            },
            links: [],
          },
          {
            columns: null,
            icon: {
              name: null,
            },
            label: "Desarrollamos tu tierra",
            nolink: false,
            url: {
              path: "/desarrollamos-tu-tierra",
            },
            links: [],
          },
          {
            columns: null,
            icon: {
              name: null,
            },
            label: "Soluciones inmobiliarias empresariales",
            nolink: false,
            url: {
              path: "/empresas/soluciones-inmobiliarias-empresariales",
            },
            links: [],
          },
          {
            columns: null,
            icon: {
              name: null,
            },
            label: "Promoción gerencia y venta de proyectos inmobiliarios",
            nolink: false,
            url: {
              path: "/empresas/promocion-gerencia-y-venta-de-proyectos-inmobiliarios",
            },
            links: [],
          },
          {
            columns: null,
            icon: {
              name: null,
            },
            label: "Administración de Patrimonios Autónomos",
            nolink: false,
            url: {
              path: "/empresas/administracion-de-patrimonios-autonomos",
            },
            links: [],
          },
          {
            columns: null,
            icon: {
              name: null,
            },
            label: "Arrendamiento de maquinaria equipos y formaleta",
            nolink: false,
            url: {
              path: "/empresas/arrendamiento-de-maquinaria-equipos-y-formaleta",
            },
            links: [],
          },
        ],
      },
      {
        columns: null,
        icon: {
          name: "co-user",
        },
        label: "Corporativo",
        nolink: true,
        url: {
          path: "",
        },
        links: [
          {
            columns: null,
            icon: {
              name: null,
            },
            label: "Quiénes somos",
            nolink: false,
            url: {
              path: "/somos-coninsa/",
            },
            links: [],
          },
          {
            columns: null,
            icon: {
              name: null,
            },
            label: "Página Aniversario 50 años",
            nolink: false,
            url: {
              path: "https://aniversario.coninsa.co",
            },
            links: [],
          },
          {
            columns: null,
            icon: {
              name: null,
            },
            label: "Sala de prensa",
            nolink: false,
            url: {
              path: "https://blog.coninsa.co/sala-de-prensa",
            },
            links: [],
          },
          {
            columns: null,
            icon: {
              name: null,
            },
            label: "Gobierno corporativo",
            nolink: false,
            url: {
              path: "/corporativo/gobierno-corporativo",
            },
            links: [],
          },
          {
            columns: null,
            icon: {
              name: null,
            },
            label: "Sostenibilidad",
            nolink: false,
            url: {
              path: "/corporativo/sostenibilidad",
            },
            links: [],
          },
          {
            columns: null,
            icon: {
              name: null,
            },
            label: "Trabaja con nosotros",
            nolink: false,
            url: {
              path: "/trabaje-con-nosotros",
            },
            links: [],
          },
          {
            columns: null,
            icon: {
              name: null,
            },
            label: "Portal de proveedores y contratistas",
            nolink: false,
            url: {
              path: "https://proveedores.coninsa.co/index",
            },
            links: [],
          },
        ],
      },
    ],
  },
];
