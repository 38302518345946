import React from "react";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";

export default function Content() {
  return (
    <Section>
      <Container>
        <div className="flex flex-col gap-5 text-sm text-s2-gray-600 lg:text-base">
          <p>
            Cookie es un fichero que se descarga en su computador al acceder a
            determinadas páginas web. Las cookies permiten almacenar y recuperar
            información sobre los hábitos de navegación de un usuario o de su
            equipo, dependiendo de la información que contengan y como se
            utilicen permiten reconocer un visitante web.
          </p>
          <p>
            El navegador del usuario memoriza cookies en el disco duro solamente
            durante la sesión actual ocupando un espacio de memoria mínimo y no
            perjudica el computador. Las cookies no contiene ninguna clase de
            información personal específica y la mayoría de las mismas se borran
            del disco duro al finalizar la sesión de navegador.
          </p>

          <p>
            La mayoría de los navegadores aceptan como estándar a las cookies y,
            con independencia de las mismas, permiten o impiden en los ajustes
            de seguridad las cookies temporales o memorizadas.
          </p>

          <p>
            Sin su expreso consentimiento-mediante la activación de las cookies
            en su navegador -{" "}
            <a href="https://www.coninsa.co/" target="_blank" className="font-bold text-s2-teal hover:underline">
              www.coninsa.co
            </a>{" "}
            no enlazará en las cookies los datos memorizados con sus datos
            personales proporcionados en el momento del registro o la compra.
          </p>

          <p>
            La web Coninsa Ramón H. puede utilizar servicios de terceros que,
            por cuenta de{" "}
            <a href="https://www.coninsa.co/" target="_blank" className="font-bold text-s2-teal hover:underline">
              www.coninsa.co
            </a>
            , recopilaran información con fines estadísticos, de uso del site
            por parte del usuario y para la prestación de otros servicios
            relacionados con la actividad del website y otros servicios de
            internet.
          </p>
          <p>
            En particular,{" "}
            <a href="https://www.coninsa.co/" target="_blank" className="font-bold text-s2-teal hover:underline">
              www.coninsa.co
            </a>{" "}
            utiliza Google Analytics, un servicio analítico de web prestado por
            Google, Inc. con domicilio en los Estados Unidos con sede central en
            1600 Amphitheatre Parkway, Mountain View, California 94043. Para la
            prestación de estos servicios, estos utilizan cookies que recopilan
            la información, incluida la dirección IP del usuario, que será
            transmitida, tratada y almacenada por Google en los términos fijados
            en la Web Google.com. Incluyendo la posible transmisión de dicha
            información a terceros por razones de exigencia legal o cuando
            dichos terceros procesen la información por cuenta de Google.
          </p>

          <p>
            El usuario acepta expresamente, por la utilización{" "}
            <a href="https://www.coninsa.co/" target="_blank" className="font-bold text-s2-teal hover:underline">
              www.coninsa.co
            </a>
            , el tratamiento de la información expresada en la forma y los fines
            anteriormente mencionados. Asimismo reconoce la posibilidad de
            rechazar el tratamiento de tales datos o información rechazando el
            uso de cookies mediante la selección de la configuración apropiada a
            tal fin en su navegador. Si bien esta opción de bloqueo de cookies
            en su navegador puede permitirle el uso pleno de todas las
            funcionalidades del sitio web de Coninsa Ramón H.
          </p>

          <p>
            Usted puede permitir, bloquear o eliminar las cookies instaladas en
            su equipo mediante la configuración de las opciones del navegador
            instalado en su computador:
          </p>

          <ul className="list-inside list-disc marker:text-xl marker:text-s2-lime">
            <li>Chrome</li>
            <li>Explorer</li>
            <li>Firefox</li>
            <li>Safari</li>
          </ul>
        </div>
      </Container>
    </Section>
  );
}
