import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";

import { UPDATE_BUILDING_PROPERTY } from "../graphql/mutations";

import LeafletMap from "../components/LeafletMap";
import { getDepartments, getCities, getNeighborhoods } from "../components/location/location";

function Step2({ onSuccess, onBack, id }) {
  const { register, handleSubmit, setValue, watch, setError } = useForm();
  const [cityOptions, setCityOptions] = useState([]);
  const [neighborhoodOptions, setNeighborhoodOptions] = useState([]);
  const department = watch("department");
  const city = watch("city");

  const [mutation] = useMutation(UPDATE_BUILDING_PROPERTY);

  useEffect(() => {
    if (department) {
      setValue("city", "");
      setCityOptions(getCities(department));
    }
  }, [department]);

  useEffect(() => {
    if (city) {
      setValue("neighborhood", "");
      setNeighborhoodOptions(getNeighborhoods(department, city));
    }
  }, [city]);

  const departmentOptions = getDepartments();

  const propertyAddressOptions = [
    { value: "", label: "Tipo de vía" },
    { value: "Avenida", label: "Avenida" },
    { value: "Calle", label: "Calle" },
    { value: "Carrera", label: "Carrera" },
    { value: "Circunvalar", label: "Circunvalar" },
  ];

  const locationOptions = [
    { value: "", label: "Ubicación" },
    { value: "Sur", label: "Sur" },
    { value: "Norte", label: "Norte" },
    { value: "Este", label: "Este" },
    { value: "Oeste", label: "Oeste" },
  ];

  const onSubmit = async (data) => {
    let location = data["department"] + ", " + data["city"];

    if(data["neighborhood"]) {
      location = location + ", " + data["neighborhood"];
    }

    const address =
      data["property_address"] + " " +
      data["property_address_number"] + " " +
      data["property_address_letter"] + " " +
      data["location"] + " " +
      data["location_number"] + " " +
      data["location_letter"] + " " +
      data["number"];
    const point = `POINT (${data?.coordinate_lon} ${data?.coordinate_lat})`

    const response = await mutation({
      variables: {
        id,
        input: {
          fieldLocationAutocomplete: location,
          fieldAddress: address,
          fieldLatLong: {
            value: point,
          }
        },
      },
    });

    const violations = response?.data?.updateBuildingProperty?.violations;
    const errors = response?.data?.updateBuildingProperty?.errors;

    if (violations?.length === 0 && errors?.length === 0) {
      return onSuccess({ id });
    } else {
      setError("Algo ocurrió mal. Vuelva a intentarlo más tarde.");
    }
  };

  const handleChangeMarker = (markerPos) => {
    setValue('coordinate_lat', markerPos[0]);
    setValue('coordinate_lon', markerPos[1]);
  }

  return (
    <div className="co-card max-w-4xl mx-auto">
      <form className="co-form gap-y-7" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between gap-4 flex-col md:flex-row">
          <div className="form-control grow">
            <label htmlFor="apartment">Departamento</label>
            <select {...register("department")} id="department" required>
              <option value="">Selecciona una opción</option>

              {departmentOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div className="form-control grow">
            <label htmlFor="city">Ciudad</label>
            <select {...register("city")} id="city" required disabled={!department}>
              <option value="">Selecciona una opción</option>

              {cityOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          {neighborhoodOptions.length > 0 && (
            <div className="form-control grow">
              <label htmlFor="neighborhood">Barrio</label>
              <select {...register("neighborhood")} id="neighborhood" disabled={!city || neighborhoodOptions.length == 0 }>
                <option value="">Selecciona una opción</option>

                {neighborhoodOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>

        <fieldset className="form-group">
          <legend>Dirección del inmueble</legend>
          <div className="form-items flex gap-2 flex-col md:flex-row">
            <div className="form-control shrink-0">
              <select
                {...register("property_address")}
                id="property_address"
                required
              >
                {propertyAddressOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-control">
              <input
                {...register("property_address_number")}
                type="text"
                id="property_address_number"
                placeholder="Número"
                required
              />
            </div>
            <div className="form-control">
              <input
                {...register("property_address_letter")}
                type="text"
                id="property_address_letter"
                placeholder="Letra"
              />
            </div>
            <div className="form-control shrink-0">
              <select {...register("location")} id="location" required>
                {locationOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div >
            <div className="form-control">
              <input
                {...register("location_number")}
                type="text"
                id="location_number"
                placeholder="Número"
                required
              />
            </div>
            <div className="form-control">
              <input
                {...register("location_letter")}
                type="text"
                id="location_letter"
                placeholder="Letra"
              />
            </div>
            <div className="form-control">
              <input
                {...register("number")}
                type="text"
                id="number"
                placeholder="Número"
                required
              />
            </div>
          </div >
        </fieldset >

        <div className="form-control flex-col md:flex-row">
          <label>Complemento de la dirección</label>
          <input
            {...register("building_name")}
            type="text"
            id="building_name"
            placeholder="Ingresa el nombre de la unidad o del edificio"
            required
          />
        </div>

        <div className="flex gap-4 flex-col md:flex-row">
          <div className="form-control grow">
            <label>Torre número</label>
            <input
              {...register("tower_number")}
              type="text"
              id="tower_number"
              placeholder="Ingresa el número de torre"
              required
            />
          </div>
          <div className="form-control grow">
            <label>Apartamento</label>
            <input
              {...register("apartment_number")}
              type="text"
              id="apartment_number"
              placeholder="Ingresa el número de apartamento"
              required
            />
          </div>
        </div>

        <div className="form-control is-map">
          <label>Ubicación</label>
          <input
            {...register('coordinate_lat')}
            type="hidden"
            id="coordinate_lat"
            required
          />
          <input
            {...register('coordinate_lon')}
            type="hidden"
            id="coordinate_lon"
            required
          />
          <LeafletMap onChange={handleChangeMarker} />
          <p className="help-text">Mueve el pin para determinar la ubicación exacta de tu inmueble</p>
        </div>

        <div className="flex justify-between gap-2 md:flex-row">
          <button type="button" onClick={onBack} className="co-button is-secondary is-large has-fixed-icon">
            <i className="co-icon icon-arrow"></i>
            Regresar
          </button>

          <button className="co-button is-progress-default is-large w-full md:w-auto">
            Continuar
          </button>
        </div>
      </form >
    </div>
  );
}

export default Step2;
