import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css"
import "./LeafletMapStyles.css";

function LocationMarker({ pos, onMove }) {
  return (
    <Marker
      position={pos}
      draggable
      autoPan
      eventHandlers={{
        moveend: (event) => {
          onMove([event.target.getLatLng().lat, event.target.getLatLng().lng]);
        }
      }}
    />
  );
}

export default function LeafletMap(props) {
  const [markerPos, setMarkerPos] = useState([6.243, -75.575]);
  useEffect(() => {
    props?.onChange(markerPos);
  }, [markerPos])

  return (
    <>
      <MapContainer
        style={{height: '252px'}}
        center={[6.243, -75.575]}
        zoom={13}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <LocationMarker onMove={setMarkerPos} pos={markerPos} />
      </MapContainer>
    </>
  );
}