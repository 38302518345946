import { mapResolver } from '.'

function SectionMapper({
  background,
  content,
  columns,
  headline,
  title,
  ...entity
}) {
  return {
    type: 'section',
    background: background?.entity?.image || {},

    header: {
      title,
      headline,
    },
    content: content.map(mapResolver),
    grid: {
      columns,
    },

    ...entity,
  }
}

export default SectionMapper
