import FeaturedList from './FeaturedList'
import FeaturedListItem from './FeaturedListItem'
import FeaturedListSubTitle from './FeaturedListSubTitle'
import FeaturedListBody from './FeaturedListBody'

FeaturedList.SubTitle = FeaturedListSubTitle
FeaturedList.Item = FeaturedListItem
FeaturedList.Body = FeaturedListBody

export default FeaturedList
