import React from 'react'
import { Box, Input } from 'theme-ui'

import Location from '../../Location'
import Control from '../../components/Control'

function BasicResidenceInformation({ register, control, setValue }) {
  return (
    <Box>
      <Control label='Dirección de residencia'>
        <Input {...register('fieldAddress')} type='text' />
      </Control>
      <Control label='Departamento/Ciudad/Barrio'>
        <Location name='fieldUbication' setValue={setValue} control={control} />
      </Control>
    </Box>
  )
}

export default BasicResidenceInformation
