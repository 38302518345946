export default [
  {
    icon: '/system-ui/images/group.svg',
    title: 'Recibes acompañamiento',
    description: 'durante todo el proceso de venta o compra.',
  },

  {
    icon: '/system-ui/images/marketing.svg',
    title: 'Marketing, Investigación y promoción',
    description: 'de tu inmueble gratis en los diferentes portales web.',
  },

  {
    icon: '/system-ui/images/legal.svg',
    title: 'Atención legal y jurídica',
    description: 'en la negociación, para que tengas un cierre tranquilo y sin complicaciones.',
  },

  {
    icon: '/system-ui/images/look-for.svg',
    title: 'Más de 50 años de experiencia en el sector inmobiliario',
    description: 'Te ayudamos a encontrar tu mejor opción.',
  },

  {
    icon: '/system-ui/images/trade.svg',
    title: 'Comercialización inmediata para inversión',
    description: '¡Está listo para que lo pongas en arriendo con nosotros o lo ocupes para uso propio!',
  },

  {
    icon: '/system-ui/images/review.svg',
    title: 'Estudio SAGRILAF',
    description: 'Realizamos una revisión exhaustiva de las propiedades y compradores de los inmuebles.',
  },

  {
    icon: '/system-ui/images/signature.svg',
    title: 'Firma 100% digital',
    description: 'Te ahorramos tiempo y dinero a la hora de firmar la promesa.',
  },
]
