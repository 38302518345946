import React from 'react'
import { Box } from 'theme-ui'

const cardStyles = {
  alignSelf: 'start',
  boxShadow: '0px 3px 6px #00000029',
  opacity: 1,
  borderRadius: '7px',
  py: 3,
  px: 4
}

function Card(props) {
  return (
    <Box __css={cardStyles} {...props} />
  )
}

export default Card
