import React from 'react'
import { navigate } from 'gatsby'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'

import { UPDATE_NODE_LEGAL_PERSON_APPLICATION } from '../../mutations'
import { LEGAL_PERSON_APPLICATION } from '../../queries'

import BasicReferences from '../generic/BasicReferences'
import FooterForm from '../../components/FooterForm'
import HeaderForm from '../../components/HeaderForm'
import { getEntity, cleanData } from '../utils'

const defaultValues = {
  test: [
    {
      name: 'useFieldArray1',
      nestedArray: [{ field1: 'field1', field2: 'field2' }],
    },
  ],
}

function ShareholdersInformation(props) {
  const loadedPerson = props?.loadedPerson || {}
  const [mutation] = useMutation(UPDATE_NODE_LEGAL_PERSON_APPLICATION, {
    refetchQueries: [
      { query: LEGAL_PERSON_APPLICATION, variables: { id: props.id } },
    ],
  })
  const { register, handleSubmit, control, errors } = useForm({
    defaultValues: loadedPerson,
  })

  const onSubmit = async data => {
    const variables = cleanData(data)

    return mutation({ variables })
      .then(response => {
        const entity = getEntity(response)

        if (entity) {
          navigate('?path=/documentacion')
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <section>
      <HeaderForm step='2/4' title='Información Accionistas' />
      <form as='form' onSubmit={handleSubmit(onSubmit)}>
        <h3 className='bg-[#ededed] font-display font-medium mb-6 px-4 py-[14px] rounded-md text-coninsa-blue-900  uppercase'>
          Referencias
        </h3>
        <div className='px-6'>
          <BasicReferences register={register} />
        </div>
        <FooterForm back />
      </form>
    </section>
  )
}

export default ShareholdersInformation
