import React from 'react'
import PropTypes from 'prop-types'

import { Box, Container } from 'theme-ui'

const Paragraph = ({ children, direction, variant, ...props }) => {
  const baseStyles = {
    px: ['medium', null],
    py: ['large', 'large'],
  }

  const contentStyles = {
    display: ['block', 'flex'],
    flexDirection: direction === 'inverse' && 'row-reverse',

    '& > div': {
      width: [null, null, '50%'],
      textAlign: direction === 'inverse' && 'left',
    },
  }

  return (
    <Box __css={baseStyles} variant={variant} __themeKey='ctas' {...props}>
      <Container>
        <Box __css={contentStyles} __themeKey='ctas' variant={`${variant}.content`}>
          {children}
        </Box>
      </Container>
    </Box>
  )
}

Paragraph.defaultProps = {
  direction: 'default',
  variant: undefined,
}

Paragraph.propTypes = {
  children: PropTypes.node.isRequired,
  direction: PropTypes.bool,
  variant: PropTypes.string,
}

export default Paragraph
