import React, { useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import MarkerCluster from "react-leaflet-markercluster";
import Leaflet from "leaflet";
import clsx from "clsx";

import type { ProjectSearchType } from "../index";

import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";

interface SearchMapProps {
  items: Array<ProjectSearchType>;
  className: string;
  withoutPriceFormatting: boolean;
}

function fitBounds(map, coordinates = []) {
  if (coordinates.length > 0) {
    map.fitBounds(coordinates);
  }
}

const SearchMap: React.FC<SearchMapProps> = ({
  items = [],
  className,
  popupComponent: PopupComponent,
  expanded = false,
  withoutPriceFormatting = false,
}) => {
  const mapRef = useRef(null);
  const coordinates = items.map((item) => item.latLong.split(","));

  useEffect(() => {
    if (mapRef.current) {
      fitBounds(mapRef.current, coordinates);
    }
  }, [items, expanded]);

  useEffect(() => {
    if (mapRef.current) {
      return () => mapRef.current.remove();
    }
  }, []);

  const customMarkerIcon = (price) =>
    new Leaflet.DivIcon({
      html: `<div class="s2-marker">${
        !withoutPriceFormatting ? `$${price.toLocaleString("es-CO")}` : price
      }</div>`,
      iconSize: [120, 30],
      iconAnchor: [60, 36],
      popupAnchor: [0, -34],
    });

  return (
    <MapContainer
      ref={mapRef}
      center={[4.1156735, -72.9301367]}
      className={clsx("s2-map", className)}
      zoom={6}
      scrollWheelZoom={false}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      <MarkerCluster>
        {items.map((item) => {
          return (
            <Marker
              key={item.id}
              position={item.latLong.split(",")}
              icon={customMarkerIcon(item.price)}
            >
              <Popup>
                <PopupComponent {...item} />
              </Popup>
            </Marker>
          );
        })}
      </MarkerCluster>
    </MapContainer>
  );
};

export default SearchMap;
