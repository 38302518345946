import PropTypes from 'prop-types'
import React from 'react'
import { Box } from 'theme-ui'

function List({ color, ...props }) {
  const baseStyles = {
    listStyle: 'none',
    listStylePosition: 'inside',
    pl: 0,
  }

  const coloredStyles = color
    ? {
        'li::before': {
          content: '"•"',
          display: 'inline-block',
          width: '1em',
          color,
        },
      }
    : {}

  const classes = {
    ...baseStyles,
    ...coloredStyles,
  }

  return <Box as='ul' __css={classes} {...props} __themeKey='lists' />
}

List.propTypes = {
  color: PropTypes.string,
}

export default List
