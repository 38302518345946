import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";

import Alert from "../components/Alert";

import { CREATE_BUILDING_PROPERTY } from "../graphql/mutations";

import { setQueryString } from "../page";

function Step1({ onSuccess }) {
  const { register, handleSubmit, setError, watch } = useForm();
  const [mutation] = useMutation(CREATE_BUILDING_PROPERTY);

  const propertyType = watch('property_type');

  useEffect(() => {
    if (propertyType)  {
      setQueryString('property_type', propertyType);
    }
  }, [propertyType])

  const propertyTypeOptions = [
    { value: "", label: "Selecciona una opción" },
    { value: "2434", label: "Apartamento" },
    { value: "2433", label: "Casa" },
    { value: "2435", label: "Bodega" },
    { value: "2436", label: "Local" },
    { value: "2440", label: "Oficina" },
    { value: "2437", label: "Consultorio" },
  ];

  const stateOptions = [
    { value: "", label: "Selecciona una opción" },
    { value: "1", label: "Se desconoce estado civil" },
    { value: "2", label: "Soltero(a)" },
    { value: "3", label: "Casado(a)" },
    { value: "4", label: "Unión Libre" },
    { value: "5", label: "Separado(a)" },
    { value: "6", label: "Divorciado(a)" },
    { value: "8", label: "Viudo(a)" },
  ];

  const genderOptions = [
    { value: "", label: "Selecciona una opción" },
    { value: "1", label: "Mujer" },
    { value: "2", label: "Hombre" },
    { value: "3", label: "Otro" },
    { value: "4", label: "Prefiero no decirlo" },
  ];

  const onSubmit = async (data) => {
    const { property_type, owner, owner_email, owner_phone, owner_gender, owner_document } = data;
    const response = await mutation({
      variables: {
        input: {
          name: "Advertiser property",
          fieldServiceType: "AR",
          fieldSourceProvider: "customer",
          fieldPropertyType: { targetId: property_type },
          fieldOwner: owner,
          fieldOwnerEmail: owner_email,
          fieldOwnerPhone: owner_phone,
          fieldOwnerDocument: owner_document,
          fieldOwnerGender: owner_gender,
        },
      },
    });

    const violations = response?.data?.createBuildingProperty?.violations;
    const errors = response?.data?.createBuildingProperty?.errors;
    const id = response?.data?.createBuildingProperty?.entity?.entityId;

    if (violations?.length === 0 && errors?.length === 0) {
      onSuccess({ id })
    } else {
      setError("Algo ocurrió mal. Vuelva a intentarlo más tarde.");
    }
  };

  return (
    <div className="co-card max-w-4xl mx-auto">
      <Alert className="is-primary">
        <span className="is-highlight">
          ¡Que bueno poder promocionar tu inmueble!
        </span>
        <p>
          Recuerda diligenciar la información completa y de forma adecuada,
          así tu proceso de promoción del inmueble será más ágil y rápido.
        </p>
      </Alert>

      <form
        className="co-form flex gap-y-7"
        onSubmit={handleSubmit(onSubmit)}
      >
        <fieldset className="form-group are-radios">
          <legend>¿Quién eres?</legend>
          <div className="form-items flex-col md:flex-row">
            <div className="form-control is-radio">
              <input
                {...register("is_owner")}
                type="radio"
                id="person_owner"
                value="1"
              />
              <label htmlFor="person_owner">Soy el propietario</label>
            </div>
            <div className="form-control is-radio">
              <input
                {...register("is_owner")}
                type="radio"
                id="person_proxy"
                value="0"
              />
              <label htmlFor="person_proxy">
                Soy un conocido / apoderado
              </label>
            </div>
          </div>
        </fieldset>

        <div className='form-control flex-1'>
            <label for="owner">Nombre completo</label>
            <input
              {...register("owner")}
              type="text"
              id="owner"
              required
              />
          </div>

        <div className='flex gap-4 flex-col md:flex-row'>
          <div className='form-control flex-1'>
            <label for="owner_document">Número de documento</label>
            <input
              {...register("owner_document")}
              type="text"
              id="owner_document"
              required
              />
          </div>

          <div className="form-control flex-1">
            <label htmlFor="owner_gender" className="!font-bold mb-4">
              Género
            </label>
            <select
              {...register("owner_gender")}
              id="owner_gender"
              required
            >
              {genderOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className='flex gap-4 flex-col md:flex-row'>
          <div className='form-control flex-1'>
            <label for='owner_email'>Correo electrónico</label>
            <input
              {...register('owner_email')}
              type='email'
              id='owner_email'
              required
            />
          </div>
          <div className='form-control flex-1'>
            <label for='owner_phone'>Número de celular</label>
            <input
              {...register('owner_phone')}
              type='text'
              id='owner_phone'
              required
            />
          </div>
        </div>

        <div className="form-control">
          <label htmlFor="property_type" className="!font-bold mb-4">
            Tipo de inmueble a consignar
          </label>
          <select
            {...register("property_type")}
            id="property_type"
            required
          >
            {propertyTypeOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <div className="flex md:justify-end gap-2 md:flex-row">
          <button className="co-button is-progress-default is-large w-full md:w-auto">
            Continuar
          </button>
        </div>
      </form>
    </div>
  );
}

export default Step1;
