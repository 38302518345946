import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

import { Slider } from 'ui'
import InterestCard from '../slices/InterestCard'
import imageCard_1 from '../../../images/c-exterior.jpg'
import imageCard_2 from '../../../images/c-news.jpg'

function InterestCards({ image, location, desc, ...props }) {
  const data = [
    {
      id: 1,
      image,
      title: location,
      summary: desc,
      btn: {
        text: 'Continuar leyendo',
        url: '#',
      },
      openModal: true,
    },
    {
      id: 2,
      image: imageCard_1,
      title: 'Compra desde el exterior',
      summary:
        '<p>Invertir en Colombia es una gran oportunidad, es asegurar un patrimonio del que podrás recibir ingresos de manera rentable y con la tranquilidad de tener tu dinero seguro y respaldado por una Compañía que desde 1972 Construye Bienestar.</p>',
      btn: {
        text: 'Conoce más',
        url: 'https://www.coninsa.co/desde-el-exterior',
      },
      openModal: false,
    },
    {
      id: 3,
      image: imageCard_2,
      title: '¿Cómo comprar inmuebles nuevos?',
      summary:
        '<p>Aquí encontrarás toda la información que necesitas como comprador de un inmueble en el que harás realidad todos tus sueños de vivienda e inversión.</p>',
      btn: {
        text: 'Continuar leyendo',
        url: 'https://www.coninsa.co/personas/como-comprar-inmuebles-nuevos',
      },
      openModal: false,
    },
  ]

  const settings = {
    arrows: false,
    customArrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    autoHeight: true,
    dots: true,
    slidesToScroll: 1,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          dots: false,
          slidesToScroll: 1,
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <Box>
      <Slider {...settings} {...props}>
        {data.map(item => (
          <InterestCard
            key={item.id}
            image={item.image}
            title={item.title}
            summary={item.summary}
            btn={item.btn}
            openModal={item.openModal}
          />
        ))}
      </Slider>
    </Box>
  )
}

InterestCards.propTypes = {
  image: PropTypes.string.isRequired,
  location: PropTypes.arrayOf(PropTypes.string).isRequired,
  desc: PropTypes.string.isRequired,
}

export default InterestCards
