import React from "react";

import Banner from "@coninsa-s2/banner";
import Container from "@coninsa-s2/container";
import DialogBox from "@coninsa-s2/dialog-box";

import McvGuideImage from "../images/mcv-guide-banner.png";
import McvGuideImageMobile from "../images/mcv-guide-mobile.png";

export default function McvGuideBanner() {
  return (
    <div>
      <Banner
        imageUrl={McvGuideImage}
        imageUrlMobile={McvGuideImageMobile}
        className="flex items-end lg:items-center lg:pl-16"
      >
        <Container>
          <div className="text-center">
            <h2 className="text-2xl font-extrabold text-white lg:mb-3 lg:text-5xl">
              Guía para usuarios de
            </h2>
            <DialogBox tint="dark-blue">
              <span className="text-4xl font-extrabold text-s2-lime lg:text-6xl">
                Mi Coninsa Virtual
              </span>
            </DialogBox>
          </div>
        </Container>
      </Banner>
    </div>
  );
}
