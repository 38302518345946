export default [
  {
    id: "step-1",
    title: "Paso 1",
    description: `Recuerda que el usuario es el <strong>correo electrónico o cédula
    ingresado en el contrato</strong>. Estos se deben digitar sin espacios,
    puntos o guiones.`,
  },
  {
    id: "step-2",
    title: "Paso 2",
    description: `En caso de que aún no hayas hecho previamente un registro de
    usuario con Coninsa, solo necesitas <strong>digitar tu número de
    identificación y correo electrónico</strong>.`,
  },
  {
    id: "step-3",
    title: "Paso 3",
    description: `Luego de ingresar debes ir a la <strong>pestaña Mi Perfil</strong>, donde
    aparecerá el nombre con el que te registraste y hacer clic en
    “Mi Coninsa Virtual”.`,
  },
  {
    id: "step-4",
    title: "Paso 4",
    description: `En caso de que olvides tu usuario y/o contraseña, ten en cuenta
    que el usuario siempre será tu número de documento de
    identificación y la contraseña podrá ser recuperada dando clic
    en la opción <strong>Restablecer Contraseña</strong>.`,
  },
];
