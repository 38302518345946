import React from 'react'

function SummaryCard({ icon, title, summary }) {
  return (
    <div className='relative rounded-b-lg shadow-[0px_3px_6px_#00000029] min-w-[258px] mb-5 md:mb-0'>
      <h4 className='bg-coninsa-blue-900 font-semibold pl-20 pr-5 py-3 rounded-t-lg text-lg text-white mb-5'>
        {title}
      </h4>
      <img src={icon} className='absolute pl-3 top-4 left-0' />
      <p className='p-5'>{summary}</p>
    </div>
  )
}

export default SummaryCard
