import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

function MemberCardName({ children, ...props }) {
  const baseStyles = {
    color: 'white',
    background: '#273475',
    fontSize: [4, 5],
    opacity: '0.8',
    textTransform: 'uppercase',
    textAlign: 'center',
    py: 'medium',
    px: ['small', 'large'],
    mb: ['medium', 0],
    mr: [0, 'large'],
  }
  return (
    <Box as='h2' __css={baseStyles} {...props}>
      {children}
    </Box>
  )
}

MemberCardName.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MemberCardName
