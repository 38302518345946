import { mapResolver } from '.'

function sliderMapper(entity) {
  return {
    type: 'slider',
    items: entity.items.map(paragraph => mapResolver(paragraph)),
    slidesToShow: [entity.slidesToShowMobile, entity.slidesToShowDesktop],
  }
}

export default sliderMapper
