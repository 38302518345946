export const QUOTATION_STORAGE = "quotation.storage";

function setQuotationStorage(data = {}) {
  sessionStorage.setItem(QUOTATION_STORAGE, JSON.stringify(data));
}

function getQuotationStorage() {
  const data = sessionStorage.getItem(QUOTATION_STORAGE) ?? "{}";

  return JSON.parse(data);
}

export default {
  set: setQuotationStorage,
  get: getQuotationStorage,
};
