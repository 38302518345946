import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

import Icon from 'ui/Icon'

const IconCap = ({ icon, size, ...props }) => {
  const baseStyles = {
    alignItems: 'center',
    alignSelf: 'center',
    bg: 'white',
    borderRadius: '50%',
    boxShadow: '0 6px 10px 0 rgba(0,0,0,0.24)',
    display: 'flex',
    height: size,
    width: size,
    flexShrink: 0,
    justifyContent: 'center',
  }

  return (
    <Box __css={baseStyles} {...props}>
      <Icon name={icon} width={30} />
    </Box>
  )
}

IconCap.defaultProps = {
  size: 82,
}

IconCap.propTypes = {
  icon: PropTypes.node.isRequired,
  size: PropTypes.number,
}

export default IconCap
