import React from "react";
import PropTypes from "prop-types";

function TabCard({
  image,
  projectName,
  location,
  area,
  designYear,
  year,
  url,
  displayUrl,
}) {
  console.log(year, "YEAR");

  return (
    <div className="block md:grid overflow-hidden md:grid-cols-[30%_70%] md:border-2 md:border-coninsa-gray-400 rounded-b-xl md:rounded-xl md:w-full h-[540px]">
      <div className="px-5 py-6 bg-white shadow-[0px_3px_6px_#00000029] w-full">
        <h3 className="font-bold text-xl leading-5 mb-4 md:mb-28 md:leading-normal md:text-2xl text-coninsa-blue-900">
          {projectName}
        </h3>

        {location && (
          <p>
            Ubicación
            <span className="block font-bold">{location}</span>
          </p>
        )}

        {area > 0 && (
          <>
            <div className="border-b border-coninsa-gray-400 my-3" />
            <p>
              Área construida
              <span className="block font-bold">{area} m²</span>
            </p>
          </>
        )}

        <div className="border-b border-coninsa-gray-400 my-3" />

        {year > 0 && (
          <p className="mb-8">
            Año
            <span className="block font-bold">{year}</span>
          </p>
        )}

        {displayUrl && (
          <a
            href={url}
            target="_blank"
            className="inline-block border border-coninsa-blue-900 cursor-pointer font-bold text-coninsa-blue-900 text-center
              text-sm px-5 py-[10px] rounded-[21px] md:block hover:text-white hover:bg-coninsa-blue-900"
          >
            Todos los proyectos
          </a>
        )}
      </div>

      <div>
        <img className="w-full md:h-full object-cover" src={image} />
      </div>
    </div>
  );
}

export default TabCard;
