import React from "react";

import Banner from "@coninsa-s2/banner";
import Container from "@coninsa-s2/container";
import DialogBox from "@coninsa-s2/dialog-box";

import Section from "@coninsa-s2/section";
import IconCard from "@coninsa-s2/icon-card";
import Shape from "@coninsa-s2/shape";
import Button from "@coninsa-s2/button";

import bannerImage from "../assets/images/banner.png";
import bannerMobileImage from "../assets/images/mobile-banner.png";

import data from "../data.js";

function TcContestsAndPromotions() {
  return (
    <div>
      <Banner
        imageUrl={bannerImage}
        imageUrlMobile={bannerMobileImage}
        className="flex items-start lg:items-center lg:pl-16"
      >
        <Container>
          <div className="text-white lg:w-[380px]">
            <DialogBox tint="dark-blue">
              <span className="text-3xl font-extrabold lg:text-6xl">
                Términos y condiciones
              </span>
            </DialogBox>
            <h2 className="pt-6 font-semibold lg:text-3xl">
              Concursos y promociones Coninsa S.A.S
            </h2>
          </div>
        </Container>
      </Banner>

      <Section tint="ligther-gray">
        <Container>
          <div>
            <p className="mb-4 text-sm lg:w-[700px] lg:text-lg">
              Te explicamos en detalle los términos, condiciones y restricciones
              de los concursos y promociones de Arrendamientos Coninsa durante
              su vigencia.
            </p>

            <div className="flex flex-col justify-center gap-4 lg:flex-row">
              {data?.map((item) => (
                <div key={item?.id} className="lg:w-[396px] lg:px-0">
                  <IconCard>
                    <Shape>
                      <img src={item?.icon} alt="Icon" />
                    </Shape>
                    <div className="pt-3 lg:pt-5">
                      <div className="mb-4">
                        <h4 className="text-lg text-s2-dark-blue lg:text-[22px]">
                          Términos y condiciones
                        </h4>
                        <h3 className="text-2xl font-extrabold leading-5 text-s2-teal lg:text-3xl">
                          {item?.label}
                        </h3>
                      </div>
                      <Button as="a" href={item?.url}>
                        Conoce más
                      </Button>
                    </div>
                  </IconCard>
                </div>
              ))}
            </div>
          </div>
        </Container>
      </Section>
    </div>
  );
}

export default TcContestsAndPromotions;
