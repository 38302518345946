import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getQueryString } from "../page";
import { useMutation } from "@apollo/client";

import { serialize } from "../utils/php-serialize";

import prots from "../data/protections";

import { UPDATE_BUILDING_PROPERTY } from "../graphql/mutations";

function fromArrayToObject(items = []) {
  const obj = {};
  items.forEach(element => {
    obj[element] = element
  });

  return obj;
}

export default function Step7({ onSuccess, onBack, id }) {
  const { register, handleSubmit, watch, setError } = useForm();
  const [mutation] = useMutation(UPDATE_BUILDING_PROPERTY);
  const [protections, setProtections] = useState([]);
  const [propertyType, setPropertyType] = useState();
  const [rates, setRates] = useState([]);

  const rateType = watch("rate_type");

  useEffect(() => {
    const propertyType = getQueryString("property_type");

    if (propertyType) {
      setPropertyType(propertyType);
      const rates = Object.values(prots[propertyType]);
      setRates(rates);
    }
  }, []);

  useEffect(() => {
    if (rateType) {
      setProtections(prots[propertyType][rateType].protections);
    }
  }, [propertyType, rateType]);

  const onSubmit = async (data) => {
    const response = await mutation({
      variables: {
        id,
        input: {
          fieldRate: {
            rate: data.rate_type,
            protection: serialize(fromArrayToObject(data.protection_type))
          }
        },
      },
    });

    const violations = response?.data?.updateBuildingProperty?.violations;
    const errors = response?.data?.updateBuildingProperty?.errors;

    if (violations?.length === 0 && errors?.length === 0) {
      return onSuccess({ id });
    } else {
      setError("Algo ocurrió mal. Vuelva a intentarlo más tarde.");
    }
  };

  return (
    <div className="co-card max-w-4xl mx-auto">
      <h3 className="font-bold">¡Conoce nuestras tarifas y amparos!</h3>
      <p className="mb-4">
        Te presentamos las opciones que tienes de tarifas y amparos para la
        publicación de tu inmueble.
      </p>
      <form className="co-form gap-y-8" onSubmit={handleSubmit(onSubmit)}>
        <fieldset className="form-group are-featured-radio-checkbox">
          <legend>
            Selecciona la tarifa que más se acomode a tu presupuesto.
          </legend>
          <div className="form-items">
            {rates.map((rate) => (
              <div className="form-control featured-radio">
                <input
                  className="radio-sign"
                  type="radio"
                  {...register("rate_type")}
                  id={`selected_price_${rate.id}`}
                  value={rate.id}
                  required
                />
                <label
                  htmlFor={`selected_price_${rate.id}`}
                  className="card is-rate"
                >
                  <div className="title">{rate?.label}</div>
                  <div className="price">{rate?.percentage}</div>
                  <div className="description">{rate?.description}</div>
                  <div className="highlighted-text mt-2">
                    <p>{rate?.note}</p>
                  </div>
                </label>
              </div>
            ))}
          </div>

          <div className="bg-[var(--color-base-2)] p-4 rounded-lg mt-4 mb-5 md:mb-10">
            <p className="text-sm">
              <span className="font-bold mr-1">
                Todas las tarifas se facturarán más IVA.
              </span>
              El tiempo de la garantía del canon se define en el momento del
              estudio del cliente arrendatario por parte de la aseguradora,
              puede cubrir por mora de 6 a 36 meses.
            </p>
          </div>
        </fieldset>

        <fieldset className="form-group are-featured-radio-checkbox">
          <legend>
            Tenemos disponibles los amparos para que te sientas tranquilo en
            todo momento:
          </legend>
          <div className="form-items">
            {protections.map((protection) => (
              <div className="form-control featured-checkbox">
                <input
                  type="checkbox"
                  className="checkbox-sign"
                  {...register("protection_type")}
                  id={`selected_protection_${protection.id}`}
                  value={protection.id}
                />
                <label
                  htmlFor={`selected_protection_${protection.id}`}
                  className="card"
                >
                  <div className="title">{protection?.label}</div>
                  <div className="price">{protection?.amount}</div>
                  <div className="description">
                    <span className="font-semibold">Tarifa: </span>
                    {protection?.rate}
                  </div>
                  <p className="font-thin text-[11px] text-gray-700 leading-[14px] mt-2">{protection?.description}</p>
                </label>
              </div>
            ))}
          </div>
        </fieldset>

        <div className="flex justify-between gap-2 md:flex-row">
          <button
            type="button"
            onClick={onBack}
            className="co-button is-secondary is-large has-fixed-icon"
          >
            <i className="co-icon icon-arrow"></i>
            Regresar
          </button>

          <button className="co-button is-progress-default is-large w-full md:w-auto">
            Continuar
          </button>
        </div>
      </form>
    </div>
  );
}
