import React from "react";
import clsx from "clsx";
import "./styles.css";

import loupeIcon from "./loupe.svg";

interface SearchBarProps {
  text: string;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}

const SearchBar: React.FC<SearchBarProps> = ({
  className,
  text = "something",
  onChange,
  value,

  ...props
}) => {
  return (
    <div className={clsx("s2-search-bar", className)}>
      <input className="s2-search-bar__input" {...props} placeholder={text} onChange={onChange} value={value}/>
      <button className="s2-search-bar__btn" type="submit">
        <img src={loupeIcon} alt="loupe-icon" />
      </button>
    </div>
  );
};

export default SearchBar;
