import commercialLegal from '../assets/icons/commercial-legal.svg'
import digitalChannels from '../assets/icons/digital-channels.svg'
import findUs from '../assets/icons/find-us.svg'
import paymentMethods from '../assets/icons/payment-methods.svg'
import withoutCotenats from '../assets/icons/without-cotenants.svg'
import freeLeasing from '../assets/icons/free-leasing.svg'
import toEnjoy from '../assets/icons/to-enjoy.svg'
import digitalSign from '../assets/icons/digital-sign.svg'
import operationsArea from '../assets/icons/settings.svg'

export default [
  {
    icon: commercialLegal,
    text: `<span class='font-bold'>Asesoría comercial y jurídica</span> <br /> en todo el proceso de ejecución, de principio a fin.`,
  },

  {
    icon: digitalChannels,
    text: `<span class='font-bold'>Contamos con diferentes canales digitales de atención</span> <br /> Portal web, WhatsApp, Asistente virtual 24/7, Facebook e Instagram.`,
  },

  {
    icon: findUs,
    text: `Encuéntranos en <br /> <span class='font-bold'>Bogotá, Antioquia y Costa Atlántica</span>.`
  },

  {
    icon: paymentMethods,
    text: `<span class='font-bold'>Contamos con múltiples alternativas de pago</span> <br /> como Mi Coninsa Virtual. Sin filas, ni complicaciones.`,
  },

  {
    icon: withoutCotenats,
    text: `Arriendas los inmuebles <br /> <span class='font-bold'>sin coarrendatario*</span>`,
  },

  {
    icon: freeLeasing,
    text: `<span class='font-bold'>Estudio de arrendamiento gratis</span> <br /> con respuesta a tu solicitud en menos de 2 horas.`,
  },

  {
    icon: toEnjoy,
    text: `Entregamos el inmueble <br /> <span class='font-bold'> listo para el disfrute.</span>`,
  },

  {
    icon: digitalSign,
    text: `Gestionamos tu contrato <br /> <span class='font-bold'>con firma digital.</span>`,
  },

  {
    icon: operationsArea,
    text: `<span class='font-bold'>Área de reparaciones</span> <br /> para darte soluciones oportunas cuando lo necesites.`,
  }
]
