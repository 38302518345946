import { gql } from '@apollo/client'

export const ADVISORS_QUERY = gql`
  query advisors {
    advisorList {
      id: entityId
      name
      email: fieldCorreoElectronico
      region: fieldRegional
      __typename
    }
  }
`

export default {}
