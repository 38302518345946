import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

import { Cap } from 'ui'

import rightCurveSvg from './curves/curve-005.svg'
import leftCurveSvg from './curves/curve-006.svg'

import leftCurveSvgMobile from './curves/curve-003.svg'
import rightCurveSvgMobile from './curves/curve-004.svg'

const TimeCard = ({ leftConnector, rightConnector, ...props }) => {
  const rightConnectorStyles = rightConnector
    ? {
        '&:after': {
          content: '""',
          display: 'block',
          width: ['230px', '138px'],
          height: ['58px', '200px'],
          position: 'absolute',

          top: ['110px', '65px'],
          left: ['50px', 'calc(50% + 50px)'],
          zIndex: 0,
          backgroundImage: [`url(${leftCurveSvgMobile})`, `url(${rightCurveSvg})`],
          backgroundRepeat: 'no-repeat',
        },
      }
    : {}

  const leftConnectorStyles = leftConnector
    ? {
        '&:before': {
          content: '""',
          display: 'block',
          width: ['215px', '138px'],
          height: ['30px', '200px'],
          position: 'absolute',

          top: ['-25px', '65px'],
          right: ['50px', 'calc(50% + 50px)'],
          backgroundImage: [`url(${rightCurveSvgMobile})`, `url(${leftCurveSvg})`],
          backgroundRepeat: 'no-repeat',
          zIndex: 0,
        },
      }
    : {}

  const baseStyles = {
    textAlign: [null, 'center'],
    px: [null, 'medium'],
    py: 'medium',
    position: 'relative',

    ...leftConnectorStyles,
    ...rightConnectorStyles,
  }

  return <Box __css={baseStyles} {...props} />
}

function Timeline() {
  const capStyles = {
    mb: 'small',
    position: 'relative',
    color: 'secondary',
    zIndex: 1,
  }

  const titleStyles = {
    color: 'secondary',
    fontSize: [2, 3],
    fontWeight: 'bold',
  }

  const numberStyles = {
    color: 'secondary',
  }

  const summaryStyles = {
    fontSize: [1, 2],
  }

  return (
    <Box>
      <div className='text-white text-base mt-3'>
        Desde hace <strong className='font-bold'>50 años</strong> Construimos
        Bienestar...
      </div>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', 'repeat(12, 1fr)'],
          gridTemplateRows: [null, 'repeat(2, 1fr)'],
        }}
      >
        <TimeCard
          sx={{
            gridArea: [0, '1 / 1 / 2 / 5'],
            display: ['flex', 'block'],
          }}
          rightConnector
        >
          <Cap
            icon='co-area'
            sx={capStyles}
            mr={['small', 'auto']}
            ml={[null, 'auto']}
          />
          <Box>
            <Box as='h3' sx={titleStyles}>
              + 13 millones de m2
            </Box>
            <Box sx={summaryStyles} px={[null, 'small']}>
              Construidos y diseñado más de <span sx={numberStyles}>490</span>{' '}
              proyectos en diferentes sectores productivos del país.
            </Box>
          </Box>
        </TimeCard>

        <TimeCard
          sx={{
            gridArea: [0, '2 / 3 / 3 / 7'],
            display: ['flex', 'block'],
            flexDirection: ['row-reverse', null],
          }}
        >
          <Cap
            icon='co-building-green'
            sx={capStyles}
            mr={[null, 'auto']}
            ml={['small', 'auto']}
          />
          <Box sx={{ textAlign: ['right', 'center'] }}>
            <Box as='h3' sx={titleStyles}>
              Sedes
            </Box>
            <Box sx={summaryStyles}>
              ubicadas en Bogotá, Medellín y Barranquilla.
            </Box>
          </Box>
        </TimeCard>

        <TimeCard
          sx={{ gridArea: [0, '1 / 5 / 2 / 9'], display: ['flex', 'block'] }}
          leftConnector
          rightConnector
        >
          <Cap
            icon='co-stay-home'
            sx={capStyles}
            mr={['small', 'auto']}
            ml={[null, 'auto']}
          />
          <Box>
            <Box as='h3' sx={titleStyles}>
              + 26.000 viviendas
            </Box>
            <Box sx={summaryStyles}>
              vendidas y entregadas en el país y actualmente comercializamos más de 45 proyectos.
            </Box>
          </Box>
        </TimeCard>

        <TimeCard
          sx={{
            gridArea: [0, '2 / 7 / 3 / 11'],
            display: ['flex', 'block'],
            flexDirection: ['row-reverse', null],
          }}
        >
          <Cap
            icon='co-empleo'
            sx={capStyles}
            mr={[null, 'auto']}
            ml={['small', 'auto']}
          />
          <Box sx={{ textAlign: ['right', 'center'] }}>
            <Box as='h3' sx={titleStyles}>
              + 5.800
            </Box>
            <Box sx={summaryStyles}>
              Empleados entre directos e indirectos.
            </Box>
          </Box>
        </TimeCard>

        <TimeCard
          sx={{ gridArea: [null, '1 / 9 / 2 / 13'], display: ['flex', 'block'] }}
          leftConnector
        >
          <Cap
            icon='co-user-green'
            sx={capStyles}
            mr={['small', 'auto']}
            ml={[null, 'auto']}
          />
          <Box>
            <Box as='h3' sx={titleStyles}>
              + 23.000 clientes
            </Box>
            <Box sx={summaryStyles}>
              <p>en arrendamiento</p>
              <p>Top 5 en Antioquia</p>
              <p> Top 10 en Colombia</p>
              <p sx={titleStyles}>+ de 6.000 activos en renta</p>
            </Box>
          </Box>
        </TimeCard>
      </Box>
    </Box>
  )
}

TimeCard.defaultProps = {
  leftConnector: false,
  rightConnector: false,
}

TimeCard.propTypes = {
  leftConnector: PropTypes.bool,
  rightConnector: PropTypes.bool,
}

export default Timeline
