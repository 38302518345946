import { gql } from '@apollo/client'

export const WEBFORM_SUBMIT = gql`
  mutation webformSubmit($values: String!) {
    submitForm(values: $values) {
      errors
      submission {
        id
      }
    }
  }
`

export default {}
