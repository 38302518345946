// import {Component, OnInit} from '@angular/core';
// import {ApiConinsaService} from '../shared/services/apiConinsa.service';
// import {ApiService} from '../shared/services/api.service';
// import {NotificacionesService} from '../shared/services/notificaciones.service';
import { CuotasDiferidas } from '../shared/models/cuotasDiferidas';
import { User } from '../shared/models/user';
import { PropuestaConstructor } from '../shared/models/propuestaConstructor';
import { Projects } from '../shared/models/projects';
import { Units } from '../shared/models/units';
import { CreditoHipotecario } from '../shared/models/creditoHipotecario';
import { Parametros } from '../shared/models/parametros';
import { Filtros } from '../shared/models/filtros';
// import {constantes} from '../shared/constants/app.constant';
import { CotizadorService } from '../shared/services/cotizador.service';
import { Cotizacion } from '../shared/models/cotizacion';
import { ClienteService } from '../cliente/services/cliente.service';
// import {IMyDateModel, INgxMyDpOptions} from 'ngx-mydatepicker';

import moment from "moment";

// declare var $: any;
// declare var moment: any;

// @Component({
//   selector: 'app-calculator',
//   templateUrl: '../views/calculator.component.html',
//   providers: [ApiConinsaService, ApiService, NotificacionesService, CotizadorService, ClienteService]
// })

// export class CalculatorComponent implements OnInit {
export class CalculatorComponent {
  public porcentajeTotal: number;
  public cuotaInicialTotal: number;
  public msjErrorporcentajeCuotaInicialVariable: string;
  // Se comentarea de acuerdo a solicitud del cliente tarjeta trello "Cambios Cotizador"
  // public msjErrorSubsidioVariable: string;
  public msjErrorAhorroProgramadoVariable: string;
  public msjErrorCesantiasVariable: string;
  // public msjErrorCuotaAdicionalMensualVariable: string;
  public msjErrornCuotasVariables: string;
  public msjErrorvalorSeparacionVariable: string;
  public msjErrorDescuentoComercialVariable: string;
  public msjErrorCuotaExtInicial: string;
  public msjErrorCuotaExtCredito: string;
  public msjErrorFormaPagoGastosEscri: string;
  public flag: boolean;
  public parametros: Parametros[] = [];
  public observacion: string;
  public units: Units[] = [];
  public selectedUnits: Units[] = [];
  public idsUnits: number[] = [];
  public cuotasDiferidas: CuotasDiferidas;
  public cuotas: Array<CuotasDiferidas>;
  public valorCuota: number;
  public fechaCotizacion: string;
  public expirationDate: string;
  public fechaSeparacion: string;
  public fechaCredito: string;
  public credito: number;
  public numPeriodoCredito: number;
  public cuotainicial: number;
  public porcentajeCredito: number;
  public porcentajeSeparacion: number;
  public porcentajeCuotaInicial: number;
  public user: User;
  public cotizacionPdf: PropuestaConstructor;
  public selectProject: Projects;
  public selectUnit: Units;
  public totalCalculado: number;
  public totalCalculadoMenosDescuento: number;
  public valorSeparacion: number;
  public creditoHipoticario: CreditoHipotecario[] = [];
  public nCuotasVariables: number;
  public porcentajeCuotaInicialVariable: number;
  public porcentajeCuotaInicialCalculada: number;
  public valorSeparacionVariable: string;
  public subsidio: number;
  public ahorroProgramado: number;
  public cesantias: number;
  public descuentoComercial: number;
  public gastosEscrituracion: number;
  public cuotaAdicionalMensual: number;
  public descuentoComercialVariable: string;
  // Se comentarea de acuerdo a solicitud del cliente tarjeta trello "Cambios Cotizador"
  // public cuotaAdicionalMensualVariable: string;
  // public subsidioVariable: string;
  public ahorroProgramadoVariable: string;
  public cesantiasVariable: string;
  public filtros: Filtros;
  public nombreCompleto: string;
  public tipoPersonaJuridica: number;
  public opcionado: number;
  public constantes: any;
  public cotizacion: Cotizacion;
  public diaPago: number;
  // public myOptions: INgxMyDpOptions = {
  //   dateFormat: 'dd-mm-yyyy',
  // };
  public conceptosCuotas: any[];
  public subsidioInicialVariable = '';
  public subsidioCreditoVariable = '';
  public subsidioInicial = 0;
  public subsidioCredito = 0;
  public formaPagoGastsEscrVar = [{
    gastosEscrituracion: '',
    fechaGastosEscrituracion: ''
  }];
  public formaPagoGastsEscr = [];
  public currency: string = 'cop';
  public exchange: number = 1;
  public yearData: any;
  public note: string;

  // New fields
  public tower: number;
  public floor: number;
  public features: string;
  public advisor: string;

  constructor(
    // private _acs: ApiConinsaService,
    // private _as: ApiService,
    // private _ns: NotificacionesService,
    public _cs: CotizadorService,
    private _cl: ClienteService,
    {
      unit,
      credit_parameters,
      project,
      user,
      quote_concepts
    }
  ) {
    this.selectedUnits = [unit];
    this.conceptosCuotas = quote_concepts;

    const year = credit_parameters;
    this.yearData = year[0];
    this.cuotasDiferidas = new CuotasDiferidas('', 0, 'Separación');
    this.cuotas = new Array();
    this.fechaCotizacion = moment().format('DD-MM-YYYY');
    this.expirationDate = moment().add(3, 'days').format('DD-MM-YYYY');
    this.fechaSeparacion = moment().format('DD-MM-YYYY');
    this.selectProject = [project];

    this.selectProject[0].maxCuotas = this.selectProject[0].maxCuotas
      ? this.selectProject[0].maxCuotas
      : this.selectProject[0].minCuotas;

    this.selectUnit = [unit];
    this.valorSeparacion = this.selectProject[0].valorSeparacion ? this.selectProject[0].valorSeparacion : this.selectUnit[0].valor * this.selectProject[0].separacion;
    this.valorSeparacionVariable = this.formatoMiles(String(Math.trunc(this.valorSeparacion)), 1);
    this.nCuotasVariables = this.selectProject[0].maxCuotas ?? 0;
    this.porcentajeCuotaInicialVariable = this.selectProject[0].cuotaInicial;
    this.porcentajeCuotaInicialCalculada = this.selectProject[0].cuotaInicial;
    this.subsidio = 0;
    this.ahorroProgramado = 0;
    this.cesantias = 0;
    this.descuentoComercial = 0;
    this.cuotaAdicionalMensual = 0;
    this.gastosEscrituracion = 0;
    this.descuentoComercialVariable = '';
    // Se comentarea de acuerdo a solicitud del cliente tarjeta trello "Cambios Cotizador"
    // this.cuotaAdicionalMensualVariable = '';
    // this.subsidioVariable = '';
    this.ahorroProgramadoVariable = '';
    this.cesantiasVariable = '';
    this.parametros[0] = new Parametros(0, '', '', '');
    // this.filtros = JSON.parse(sessionStorage.getItem('filtros'));

    // this.selectedUnits[0] = new Units(null, null, '', '', null, null, null, null, '', '', null, null, '', '', '');
    // this.constantes = constantes;
    // this.note = sessionStorage.getItem('note');

    // Get user data
    this.user = user;
    this.nombreCompleto = this.user.primerNombre + ' ' + this.user.segundoNombre + ' ' + this.user.primerApellido + ' ' + this.user.segundoApellido;
    this.tipoPersonaJuridica = 0;
    this.user.type_document = 'CC';

    if (this.tipoPersonaJuridica) {
      this.user.type_document = 'NIT';
    }
  }

  // public ngOnInit() {
  //   this.parametros = this._cs.cargarParametros();
  //   this.obtenerUnidades();
  //   this.obtenerConceptos();
  // }

  public generarPdf(skipClient = false)  {
  //   this.cotizacionPdf.observation = this.observacion;
  //   this.cotizacionPdf.tower = this.tower;
  //   this.cotizacionPdf.floor = this.floor;
  //   this.cotizacionPdf.features = this.features;
  //   this.cotizacionPdf.advisor = this.advisor;

    this.cotizacion = this._cl.inicializarCotizacion(
      this.cotizacionPdf
    );

  //   if (this.user.id || skipClient) {
    return this._cs.generarPdf(this.cotizacion,
        this.tipoPersonaJuridica,
        this.user,
        false,
        this.yearData.year,
        this.currency,
        this.exchange,
        this.note
      );
  //   } else {
  //     $('#leadModal').modal('show');
  //   }
  }

  // public agregarAdicionalesModal(): void {
  //   $('#modalAdicionales').modal('show');
  // }

  // public recalcularCotizacionModal(): void {
  //   $('#modalCotizacionPersonalizada').modal('show');
  // }

  public modificarAdicionales(): void {
    let total: number = 0;
    // let unit: Units[] = [];

    // this.idsUnits.forEach(x => {
    //   unit = this.units.filter(item => { return item.idUnidad === x; });
    //   this.selectedUnits.push(unit[0]);
    // });

    this.selectedUnits.forEach(x => {
      total = Number(total) + Number(x.valor);
    });

    // $('#modalAdicionales').modal('hide');

    this.totalCalculado = total;
    this.totalCalculadoMenosDescuento = this.totalCalculado - Number(this.limpiarNumeros(this.descuentoComercialVariable));
    this.calcularCotizacion(Number(this.limpiarNumeros(this.descuentoComercialVariable)) <= 0 ? this.totalCalculado : this.totalCalculadoMenosDescuento);
  }

  // public obtenerUnidades(): any {
  public obtenerUnidades(unit): any {
    // this.selectedUnits = [];
    // this.selectedUnits[0] = new Units(null, null, '', '', null, null, null, null, '', '', null, null, '', '', '');
    // this._ns.downdloadAlert('Cargando Unidades');
    // Se consultan las unidades
    // this._acs.getUnitsService(this.filtros).subscribe(
    //   response => {
        // this.selectedUnits = units;
        // // Se filtra la unidad principal
        // this.selectedUnits = this.selectedUnits.filter(
        //   unit => unit.idUnidad === this.filtros.inmueble
        // );

        // this.selectedUnits = [unit];

        // Se refresca el sessionstorage
        // sessionStorage.setItem('selectUnit', JSON.stringify(this.selectedUnits[0]));
        // sessionStorage.setItem('selectUnit', JSON.stringify(unit));

        // Se acutualiza los adicionales
        // this.modificarAdicionales();
        // this._ns.closeAlert();
        // Se notifica si el inmuble ya se encuentra opcionado
        // this.notificacionesOpcionado();
      // },
      // error => {
      //   this._ns.errorAlert('No se pueden cargar las unidades, por favor comunicarse con el administrador del sistema');
      // },
    // );
  }

  public calcularCotizacion(valorTotal: number, proceso: number = 2): any {
    this.cuotas = [];
    this.valorSeparacion = this.selectProject[0].valorSeparacion > 0
      ? Number(this.selectProject[0].valorSeparacion)
      : Number(valorTotal * this.selectProject[0].separacion);

    if (proceso === 1) {
      this.selectProject[0].cuotaInicial = this.porcentajeCuotaInicialVariable;
      this.selectProject[0].maxCuotas = this.nCuotasVariables;
      this.valorSeparacion = Number(this.limpiarNumeros(this.valorSeparacionVariable));
      // Se comentarea de acuerdo a solicitud del cliente tarjeta trello "Cambios Cotizador"
      // this.subsidio = Number(this.limpiarNumeros(this.subsidioVariable));
      this.ahorroProgramado = Number(this.limpiarNumeros(this.ahorroProgramadoVariable));
      this.cesantias = Number(this.limpiarNumeros(this.cesantiasVariable));
      // this.cuotaAdicionalMensual = Number(this.limpiarNumeros(this.cuotaAdicionalMensualVariable));
      this.descuentoComercial = Number(this.limpiarNumeros(this.descuentoComercialVariable));
      this.setGastsEscTotal();
      this.subsidioInicial = Number(this.limpiarNumeros(this.subsidioInicialVariable));
      this.subsidioCredito = Number(this.limpiarNumeros(this.subsidioCreditoVariable));
    }

    this.porcentajeSeparacion = (this.valorSeparacion / valorTotal) * 100;
    this.cuotainicial = (valorTotal * this.selectProject[0].cuotaInicial) / 100;
    this.cuotaInicialTotal = this.cuotainicial;
    const abonos = this.valorSeparacion + this.subsidio + this.ahorroProgramado + this.cesantias;
    this.cuotainicial = this.cuotainicial - (abonos) + (this.cuotaAdicionalMensual * this.selectProject[0].maxCuotas);
    this.porcentajeCuotaInicialCalculada = ((abonos + this.cuotainicial) / this.totalCalculado) * 100;
    this.credito = Math.round(valorTotal - (abonos + this.cuotainicial));
    this.porcentajeCredito = 100 - this.porcentajeCuotaInicialCalculada;
    this.porcentajeCuotaInicial = this.selectProject[0].cuotaInicial - this.porcentajeSeparacion;
    this.porcentajeTotal = this.porcentajeCredito + this.porcentajeCuotaInicial + this.porcentajeSeparacion;
    this.valorCuota = this.selectProject[0].maxCuotas
      ? (!this.subsidioInicial ? this.cuotainicial : this.cuotainicial - this.subsidioInicial) / this.selectProject[0].maxCuotas
      :  0;
    const decimal = Math.round(this.valorCuota % 1 * this.selectProject[0].maxCuotas);
    this.valorCuota = Math.floor(this.valorCuota);
    this.numPeriodoCredito = Number(this.selectProject[0].maxCuotas) + (!this.subsidioInicial ? 1 : 2);
    const fecSepForm = moment(this.fechaSeparacion, 'DD-MM-YYYY');
    this.fechaCredito = moment(fecSepForm).date(!this.diaPago ? fecSepForm.date() : this.diaPago).add(Number(this.selectProject[0].maxCuotas) + 1, 'months').format('DD-MM-YYYY');

    this.creditoHipoticario = this._cs.calcularCreditoHipotecario(
      this.subsidioCredito ? (this.credito - this.subsidioCredito) : this.credito,
      valorTotal,
      this.yearData.periods,
      this.selectProject[0].proyectoVIS
    )
    for (let i = 1; i <= this.selectProject[0].maxCuotas; i++) {
      if (i == this.selectProject[0].maxCuotas) {
        this.valorCuota = this.valorCuota + decimal;
      }
      this.cuotasDiferidas = new CuotasDiferidas(
        moment(fecSepForm).date(
          !this.diaPago ? fecSepForm.date() : this.diaPago
        ).add(i, 'months').format('DD-MM-YYYY'), this.valorCuota, this.conceptosCuotas.find(item => item.id === 3).name);
      this.cuotas.push(this.cuotasDiferidas);
    }
    // Se añade subsidio inicial a cuota inicial
    if (this.subsidioInicial) {
      this.cuotas.push(
        new CuotasDiferidas(
          moment(fecSepForm).date(
            !this.diaPago ? fecSepForm.date() : this.diaPago
          ).add(Number(this.selectProject[0].maxCuotas) + 1, 'months')
            .format('DD-MM-YYYY'), this.subsidioInicial, this.conceptosCuotas.find(item => item.id === 11).name)
      );
    }
    if (this.subsidioCredito) {
      this.cuotas.push(
        new CuotasDiferidas(
          moment(fecSepForm).date(
            !this.diaPago ? fecSepForm.date() : this.diaPago
          ).add(Number(this.selectProject[0].maxCuotas) + 1, 'months')
            .format('DD-MM-YYYY'), this.subsidioCredito, this.conceptosCuotas.find(item => item.id === 10).name)
      );
    }

    this.cotizacionPdf = new PropuestaConstructor(
      this.user.cedula,
      this.nombreCompleto,
      this.user.telefono,
      this.user.email,
      this.selectProject[0].nombre,
      this.fechaCotizacion,
      this.expirationDate,
      this.fechaSeparacion,
      this.fechaCredito,
      this.totalCalculado,
      this.cuotainicial,
      this.valorSeparacion,
      this.credito,
      this.selectProject[0].maxCuotas,
      this.porcentajeSeparacion,
      this.porcentajeCuotaInicial,
      100 - this.selectProject[0].cuotaInicial,
      this.cuotas,
      this.selectedUnits,
      this.user.type_document,
      this.user.id,
      this.observacion,
      this.creditoHipoticario,
      this.subsidio,
      this.ahorroProgramado,
      this.cesantias,
      this.cuotaInicialTotal,
      this.descuentoComercial,
      this.totalCalculadoMenosDescuento,
      this.cuotaAdicionalMensual,
      this.porcentajeCuotaInicialCalculada,
      this.gastosEscrituracion,
      this.formaPagoGastsEscr,
      this.diaPago,
      this.subsidioInicial,
      this.subsidioCredito,

      this.tower,
      this.floor,
      this.features,
      this.advisor
    );

    return this;
  }

  public recalcularCotizacion(): any {
    // this.validar();
    // if (this.flag) {
    //   return true;
    // }
    this.totalCalculadoMenosDescuento = this.totalCalculado - Number(this.limpiarNumeros(this.descuentoComercialVariable));
    this.calcularCotizacion(Number(this.limpiarNumeros(this.descuentoComercialVariable)) <= 0 ? this.totalCalculado : this.totalCalculadoMenosDescuento, 1);
    // $('#modalCotizacionPersonalizada').modal('hide');
  }

  // private traerParametro(parametro: string): any {
  //   var valor = '';
  //   this.parametros.forEach(
  //     x => {
  //       if (x.code === parametro) {
  //         valor = x.value;
  //       }
  //     }
  //   );
  //   return valor;
  // }

  // private validar(): any {
  //   const reg = /^\d*$/;
  //   this.msjErrornCuotasVariables = '';
  //   this.msjErrorporcentajeCuotaInicialVariable = '';
  //   this.msjErrorCuotaExtCredito = '';
  //   this.msjErrorFormaPagoGastosEscri = '';
  //   this.flag = false;
  //   if (this.nCuotasVariables == null) {
  //     this.msjErrornCuotasVariables = 'El campo no puede estar en blanco';
  //     this.flag = true;
  //   }
  //   if (this.porcentajeCuotaInicialVariable == null) {
  //     this.msjErrorporcentajeCuotaInicialVariable = 'El campo no puede estar en blanco';
  //     this.flag = true;
  //   }
  //   if (!reg.test(String(this.nCuotasVariables))) {
  //     this.msjErrornCuotasVariables = 'El valor debe ser numerico';
  //     this.flag = true;
  //   }
  //   if (!reg.test(String(this.porcentajeCuotaInicialVariable))) {
  //     this.msjErrorporcentajeCuotaInicialVariable = 'El valor debe ser numerico';
  //     this.flag = true;
  //   }
  //   const minCuotas = this.traerParametro('min_cuotas');
  //   if (this.nCuotasVariables < Number(minCuotas)) {
  //     this.msjErrornCuotasVariables = 'El número de cuotas no puede ser menor a ' + minCuotas;
  //     this.flag = true;
  //   }

  //   const maxCuotas = this.traerParametro('max_cuotas');
  //   if (this.nCuotasVariables > Number(maxCuotas)) {
  //     this.msjErrornCuotasVariables = 'El número de cuotas no puede ser mayor a ' + maxCuotas;
  //     this.flag = true;
  //   }
  //   const minPorcCuotaInicial = this.traerParametro('min_porc_cuota_inicial');
  //   if (this.porcentajeCuotaInicialVariable < Number(minPorcCuotaInicial)) {
  //     this.msjErrorporcentajeCuotaInicialVariable = 'El porcentaje de cuota inicial no puede ser menor a ' + minPorcCuotaInicial;
  //     this.flag = true;
  //   }

  //   const maxPorcCuotaInicial = this.traerParametro('max_porc_cuota_inicial');
  //   if (this.porcentajeCuotaInicialVariable > Number(maxPorcCuotaInicial)) {
  //     this.msjErrorporcentajeCuotaInicialVariable = 'El porcentaje de cuota inicial no puede ser mayor a ' + maxPorcCuotaInicial;
  //     this.flag = true;
  //   }
  //   this.formaPagoGastsEscrVar.forEach(item => {
  //     if (item.gastosEscrituracion !== '' && item.fechaGastosEscrituracion === '') {
  //       this.msjErrorFormaPagoGastosEscri = 'El pago de los gastos de escrituración debe contener una fecha';
  //       this.flag = true;
  //     }
  //   });
  // }

  public formatoMiles(event, proceso: number = 2): any {
    if (proceso === 2) {
      var valorConvertir = event.target.value;
    } else {
      var valorConvertir = event;
    }

    valorConvertir = valorConvertir.replace(/\./g, '');
    if (!isNaN(Number(valorConvertir))) {
      valorConvertir = valorConvertir.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.');
      valorConvertir = valorConvertir.split('').reverse().join('').replace(/^[\.]/, '');
    } else {
      valorConvertir = String(valorConvertir).replace(/[^\d\.]*/g, '');
    }
    if (proceso === 2) {
      event.target.value = valorConvertir;
      return event;
    } else {
      return valorConvertir;
    }
  }

  public limpiarNumeros(numero: string | number): any {
    if (typeof numero === 'number') {
      return numero;
    }

    return numero.replace(/\./g, '');
  }

  // private notificacionesOpcionado(): any {
  //   if (this.selectedUnits[0].fechaFinalOpcion != '') {
  //     this._ns.warningAlert('El inmueble ' + this.selectedUnits[0].nomenclatura + ' se encuentra opcionado hasta el ' + this.selectedUnits[0].fechaFinalOpcion);
  //   }
  // }

  // /**
  //  * @desc redirigir a pantalla propuesta cliente
  //  * @desc redirigir a pantalla propuesta cliente
  //  */
  // public redirigirPropuestaCliente(): void {
  //   sessionStorage.setItem('propuestaConstructor', JSON.stringify(this.cotizacionPdf));
  // }

  // /**
  //  * Función llamar servicio para obtener los conceptos de las cuotas
  //  */
  // private obtenerConceptos(): void {
  //   this._as.obtenerConceptos().subscribe(
  //     response => {
  //       this.conceptosCuotas = response.data;
  //       sessionStorage.setItem('conceptosCuotas', JSON.stringify(response.data));
  //     },
  //     error => {
  //       console.log(error);
  //     }
  //   );
  // }

  // /**
  //  * Recibe los valores enviados del el componente de opcionar
  //  * @param $event
  //  */
  // public obtenerValoresOpcion($event): void {
  //   this.cotizacionPdf = $event.cotizacionPdf;
  //   this.tipoPersonaJuridica = $event.tipoPersonaJuridica;
  //   this.user = $event.user;
  //   this.nombreCompleto = $event.nombreCompleto;
  //   this.opcionar();
  // }

  // public validarUsuario(): boolean {
  //   return !this.user.cedula || !this.nombreCompleto || !this.user.type_document;
  // }

  // public opcionar(): any {
  //   // Valida datos del usuario
  //   if (this.validarUsuario()) {
  //     $('#datosUsuarioForm').modal({backdrop: 'static', keyboard: false});
  //     return false;
  //   } else {
  //     $('#datosUsuarioForm').modal('hide');
  //   }
  //   this.cotizacion = this._cl.inicializarCotizacion(this.cotizacionPdf);
  //   // Opciona al usuario
  //   this._cs.procesoOpcionarGenerarPdf(this.cotizacion, this.user, this.selectProject, this.tipoPersonaJuridica, this.filtros, false, this.yearData.year, this.currency, this.exchange, this.note);
  // }

  // /**
  //  * Recibe los valores enviados del el componente de adicionales
  //  * @param $event
  //  */
  // public obtenerValoresAdicionales($event): void {
  //   this.idsUnits = $event.idsUnits;
  //   this.units = $event.units;
  //   this.obtenerUnidades();
  // }

  // /**
  //  * Optional date changed callback
  //  * @param event
  //  */
  // public onDateChanged(event: IMyDateModel): void {
  //   this.fechaSeparacion = event.formatted;
  // }

  // public anadirGastoEscriturarion() {
  //   if (this.formaPagoGastsEscrVar && this.formaPagoGastsEscrVar.length <= 1) {
  //     this.formaPagoGastsEscrVar.push({
  //       gastosEscrituracion: '',
  //       fechaGastosEscrituracion: ''
  //     });
  //   }
  // }

  // public eliminarGastoEscriturarion() {
  //   if (this.formaPagoGastsEscrVar && this.formaPagoGastsEscrVar.length > 1) {
  //     this.formaPagoGastsEscrVar.splice(-1, 1);
  //   }
  // }

  // public editarGastosEscrituracion(value, element, elementName) {
  //   element[elementName] = value;
  // }

  public setGastsEscTotal() {
    this.formaPagoGastsEscr = [];
    this.formaPagoGastsEscrVar.map(item => {
      const gasto = item;
      gasto.gastosEscrituracion = String(this.limpiarNumeros(item.gastosEscrituracion));
      if (item.gastosEscrituracion !== '' && item.fechaGastosEscrituracion !== '') {
        this.formaPagoGastsEscr.push(item);
        this.gastosEscrituracion += Number(gasto.gastosEscrituracion);
      }
    });
  }

  // /**
  //  * Dispatch leadEvent emitter
  //  */
  // public leadEvent() {
  //   this.user = JSON.parse(sessionStorage.getItem('user'));
  //   this.nombreCompleto = this.user.primerNombre + ' ' + this.user.segundoNombre + ' ' + this.user.primerApellido + ' ' + this.user.segundoApellido;
  //   this.cotizacion.propuestaConstructor.id_zoho = this.user.id;
  //   this.cotizacionPdf.name = this.nombreCompleto;
  //   this.cotizacionPdf.type_document = this.user.type_document;
  //   this.cotizacionPdf.document = this.user.cedula;
  //   this.cotizacionPdf.email = this.user.email;
  //   this.cotizacionPdf.phone = this.user.telefono;
  //   $('#leadModal').modal('hide');
  //   this.generarPdf();
  // }

  // /**
  //  * Dispatch onSkipClientEvent emitter
  //  */
  // public onSkipClientEvent() {
  //   const skipClient = true;
  //   $('#leadModal').modal('hide');
  //   this.generarPdf(skipClient);
  // }

  // /**
  //  * Dispatch getCurrentCurrency emitter
  //  */
  // public setCurrentCurrency(currency) {
  //   this.currency = currency.code;
  //   this.exchange = currency.exchange;
  // }

  // /**
  //  * Dispatch getCreditDataYear emitter
  //  */
  // public setCreditDataYear(data) {
  //   this.yearData = data;
  //   this.recalcularCotizacion();
  // }

}
