import React, { useRef, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import axios from 'axios'
import { Box, Input, Textarea } from 'theme-ui'

import { Button } from 'ui'
import { Field, InputMask, Select } from 'ui/form'
import Toast from 'ui/Toast'
import Control from '../../digital-studio/components/Control'
import FilesInput from '../../file/FilesInput'

import TermsAndConditions from '../components/TermsAndConditions'
import projects from '../data/projects.json'

const roles = [
  { value: 'Propietario', label: 'Propietario' },
  { value: 'Otro solicitante', label: 'Otro solicitante' },
]

const idTypes = [
  { value: 'CC', label: 'CC' },
  { value: 'NIT', label: 'NIT' },
  { value: 'CE', label: 'CE' },
  { value: 'PA', label: 'PA' },
]

const regions = [
  { value: 'Antioquia', label: 'Antioquia' },
  { value: 'Atlántico', label: 'Atlántico' },
  { value: 'Cundinamarca', label: 'Cundinamarca' },
]

const categories = [
  'Certificados',
  'Entrega del inmueble',
  'Pagos y estados de cuenta de mi inmueble',
  'Proceso comercial del inmueble',
  'Reformas',
  'Servicio Postventa',
  'Trámites de mi inmueble',
  'Otro',
]

const PostSalesServiceForm = () => {
  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    register,
    reset,
  } = useForm({ defaultValues: { role: '', idType: '', phone: '', location: '', project: '', category: '' } })

  const [showingToast, showToast] = useState(false)
  const [toastSettings, setToastSettings] = useState({ position: 'bottom-right' })

  const list = [
    {
      id: 'pss1',
      title: 'Gracias!',
      description: 'La solicitud ha sido creada con éxito.',
      backgroundColor: '#85bb25',
      icon: 'success',
    },
  ]

  const formRef = useRef(null)

  const calcToastSettings = () => {
    if ('parentIFrame' in window) {
      const offsetTop = formRef.current.offsetHeight + 435
      setToastSettings({ position: 'top-right', offsetTop })
    }
  }

  const onSubmit = async ({ category, role, idType, location, project, ...data }) => {
    const url = 'https://busquedas.coninsa.co/zoho/contacts/dei'

    return new Promise((resolve, reject) => {
      axios
        .post(url, { category: category.value, role: role.value, idType: idType.value, location: location.value, project: project.value, ...data })
        .then(response => {
          reset()
          calcToastSettings()
          showToast(true)
          resolve(response)
        })
        .catch(error => {
          console.error(error.message)
          reject(error)
        })
    })
  }


  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
      {showingToast && (
        <Toast
          toastList={list}
          {...toastSettings}
          autoDelete
          autoDeleteTime='3000'
        />
      )}

      <p className='mb-5'>
        Diligencia el formulario completamente para asegurarnos de brindarte el mejor
        servicio y comunicarnos contigo.
      </p>

      <div className='mb-5'>
        <h3 className='text-coninsa-blue-900 font-semibold text-lg mb-2'>
          Tus datos de contacto
        </h3>

        <Field>
          <Controller
            control={control}
            name='role'
            render={({ field }) => (
              <Select
                {...field}
                placeholder="Rol"
                options={roles}
                isClearable
                required
              />
            )}
          />
        </Field>

        <Box sx={{ display: [null, 'flex'], gap: '16px' }}>
          <Field sx={{ width: [null, '50%'] }}>
            <Input
              placeholder='Nombres'
              type='text'
              {...register('firstName')}
              required
            />
          </Field>

          <Field sx={{ width: [null, '50%'] }}>
            <Input
              placeholder='Apellidos'
              type='text'
              {...register('lastName')}
              required
            />
          </Field>
        </Box>

        <Box sx={{ display: [null, 'flex'], gap: '16px' }}>
          <Field sx={{ width: [null, '50%'] }}>
            <Controller
              control={control}
              name='phone'
              render={({ field }) => (
                <InputMask
                  {...field}
                  placeholder='Teléfono de contacto'
                  type='tel'
                  format='+57 (###) ###-####'
                  mask='_'
                  required
                />
              )}
            />
          </Field>

          <Field sx={{ width: [null, '50%'] }}>
            <Input
              placeholder='Tu correo electrónico'
              type='email'
              {...register('email')}
              required
            />
          </Field>
        </Box>

        <Box sx={{ display: [null, 'flex'], gap: '16px' }}>
          <Field sx={{ width: [null, '50%'] }}>
            <Controller
              control={control}
              name='idType'
              render={({ field }) => (
                <Select
                  {...field}
                  options={idTypes}
                  placeholder='Tipo de identificación'
                  required
                />
              )}
            />
          </Field>

          <Field sx={{ width: [null, '50%'] }}>
            <Input
              placeholder='Número de Identificación'
              type='text'
              {...register('id')}
              required
            />
          </Field>
        </Box>
      </div>

      <div className='mb-5'>
        <h3 className='text-coninsa-blue-900 font-semibold text-lg mb-2'>
          Tu proyecto
        </h3>

        <Field>
          <Controller
            control={control}
            name='location'
            render={({ field }) => (
              <Select
                {...field}
                options={regions}
                placeholder='¿En qué departamento estás ubicado?'
                required
              />
            )}
          />
        </Field>

        <Box sx={{ display: [null, 'flex'], gap: '16px' }}>
          <Field sx={{ width: [null, '50%'] }}>
            <Controller
              control={control}
              name='project'
              rules={{ required: "Este campo es obligatorio" }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={projects.map(project => ({
                    value: project,
                    label: project,
                  }))}
                  placeholder='Proyecto/etapa'
                  required
                />
              )}
            />
          </Field>

          <Field sx={{ width: [null, '50%'] }}>
            <Input
              placeholder='Número de Inmueble (apto o casa)'
              type='text'
              {...register('apartment')}
              required
            />
          </Field>
        </Box>
      </div>

      <div className='mb-5'>
        <h3 className='text-coninsa-blue-900 font-semibold text-lg mb-2'>
          Tu solicitud
        </h3>

        <Box sx={{ display: [null, 'flex'], gap: '16px' }}>
          <Field sx={{ width: [null, '50%'] }}>
            <Controller
              control={control}
              name='category'
              rules={{ required: "Este campo es obligatorio" }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={categories.map(category => ({
                    value: category,
                    label: category,
                  }))}
                  placeholder='Categoría'
                  required
                />
              )}
            />
          </Field>

          <Field sx={{ width: [null, '50%'] }}>
            <Input
              placeholder='Asunto'
              type='text'
              {...register('matter')}
              required
            />
          </Field>
        </Box>

        <Field>
          <Textarea
            rows={5}
            placeholder='Escribe los detalles de tu solicitud'
            {...register('description')}
            required
          />
        </Field>

        <Control label='Agregar archivos adjuntos'>
          <FilesInput
            name='attachSupport'
            control={control}
            accept='.txt,.pdf,.doc,.docx,.jpg,.png,.mp4,.mov'
          />
        </Control>
      </div>

      <TermsAndConditions />

      <Button
        sx={{ width: ['100%', 'auto'] }}
        disabled={isSubmitting}
        loading={isSubmitting}
      >
        Solicitar servicio
      </Button>
    </form>
  )
}

export default PostSalesServiceForm
