import React from 'react'
import PropTypes from 'prop-types'
import { Box, Link } from 'theme-ui'

import { Icon } from 'ui'

const getIconSize = size => {
  const defaultSize = '20px'

  const sizesMap = {
    small: '18px',
    medium: '30px',
    large: '40px',
  }

  return sizesMap[size] || defaultSize
}

function BaseLink({ children, url, icon, nolink, variant, target, ...props }) {
  const linkVariant = variant !== null ? variant : 'default'

  const linkContent = (
    <>
      {icon?.name && (
        <Icon
          sx={{
            width: getIconSize(icon.size),
            height: getIconSize(icon.size),
            variant: `links.${linkVariant}.icon`,
          }}
          name={icon.name}
        />
      )}

      <Box as='span' variant={`${linkVariant}.label`} __themeKey='links'>
        {children}
      </Box>
    </>
  )

  if (nolink) {
    return (
      <Link as='div' variant={linkVariant} {...props}>
        {linkContent}
      </Link>
    )
  }

  const linkStyles = {
    color: 'inherit',
    '&:hover': {
      color: 'secondary',
    },
  }

  return (
    <Link
      sx={linkStyles}
      href={url.path}
      variant={linkVariant}
      target={target}
      {...props}
    >
      {linkContent}
    </Link>
  )
}

BaseLink.defaultProps = {
  children: undefined,
  icon: {},
  nolink: false,
  target: null,
  url: {
    path: '/',
  },
  variant: 'default',
}

BaseLink.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.shape({
    name: PropTypes.string,
    size: PropTypes.string,
  }),
  nolink: PropTypes.bool,
  target: PropTypes.string,
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  variant: PropTypes.string,
}

export default BaseLink
