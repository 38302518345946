import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box } from 'theme-ui'

const CardImage = ({ image, variant, isDynamic, ...props }) => {
  const baseStyles = {
    width: '100%',

    '& > div': {
      width: '100%',
    },
  }

  if (isDynamic) {
    return (
      <Box
        __css={baseStyles}
        __themeKey='cards'
        variant={`${variant}.image`}
        {...props}
      >
        {image?.source && <GatsbyImage image={image?.source} />}
      </Box>
    )
  }

  return (
    <Box
      as="img"
      __css={baseStyles}
      __themeKey='cards'
      variant={`${variant}.image`}
      {...props}
    >
      {image?.source && <GatsbyImage image={image?.source} />}
    </Box>
  )
}

CardImage.defaultProps = {
  isDynamic: false,
  variant: 'default',
}

CardImage.propTypes = {
  children: PropTypes.node.isRequired,
  image: PropTypes.shape({
    source: PropTypes.shape({}),
  }).isRequired,
  isDynamic: PropTypes.bool,
  variant: PropTypes.string,
}

export default CardImage
