import React from 'react'
import { navigate } from 'gatsby'
import { useForm } from 'react-hook-form'
import { useMutation, useApolloClient } from '@apollo/client'
import { Box } from 'theme-ui'
import { Button, Heading } from 'ui'

import {
  CREATE_NODE_NATURAL_PERSON_COTENANT,
  UPDATE_NODE_NATURAL_PERSON_COTENANT,
} from '../../mutations'
import {
  LEGAL_PERSON_APPLICATION,
  NATURAL_PERSON_APPLICATION,
  NATURAL_COTENANT_APPLICATION,
} from '../../queries'

import {
  getCotenantBasePath,
  updatePersonCotenants,
} from 'modules/digital-studio/settings'
import BasicPersonalInformation from '../generic/BasicPersonalInformation'
import BasicCustomer from '../generic/BasicCustomer'
import HeaderForm from '../../components/HeaderForm'
import { getEntity, cleanData } from '../utils'

const initialValues = {
  title: 'temporary',
  fieldIdType: 'cc',
  fieldIsCustomer: '0',
  fieldIncomeResponsible: '0',
  fieldSelfRetainer: '0',
  fieldGreatTaxpayer: '0',
  fieldPublicServer: '0',
  fieldManagePublicResources: '0',
  fieldPubliclyRecognized: '0',
  fieldFamilyPubliclyRecognized: '0',
  fieldInternalEmployee: '0',
  fieldHandlesForeignCurrency: '0',
  fieldInternationalOperations: '0',
  fieldHasInternationalAccount: '0',
}

export const getQueryByBundleType = bundle => {
  const queries = {
    natural: NATURAL_PERSON_APPLICATION,
    juridica: LEGAL_PERSON_APPLICATION,
  }

  return queries[bundle]
}

function PersonalInformation(props) {
  const client = useApolloClient()

  const loadedCotenant = props?.loadedCotenant || {}
  const [createMutation] = useMutation(CREATE_NODE_NATURAL_PERSON_COTENANT)
  const [updateMutation] = useMutation(UPDATE_NODE_NATURAL_PERSON_COTENANT, {
    refetchQueries: [
      { query: NATURAL_COTENANT_APPLICATION, variables: { id: props.cid } },
    ],
  })

  const { register, handleSubmit, control, setValue } = useForm({
    defaultValues: {
      ...initialValues,
      ...loadedCotenant,
    },
  })

  const onSubmit = async data => {
    const mutation = data?.id ? updateMutation : createMutation
    const variables = cleanData(data)

    return mutation({ variables })
      .then(response => {
        const entity = getEntity(response)

        if (entity) {
          const cacheQuery = getQueryByBundleType(props.bundle)
          const person = client.readQuery({
            query: cacheQuery,
            variables: { id: props.id },
          })

          const updatedCotenants = updatePersonCotenants(person, entity)

          client.cache.updateQuery(
            { query: cacheQuery, variables: { id: props.id } },
            data => ({ node: { ...data.node, fieldCotenants: updatedCotenants } })
          )

          const redirectTo = getCotenantBasePath(
            props.bundle,
            props.id,
            props.cotenant,
            entity.id
          )

          navigate(`${redirectTo}?path=/informacion-fiscal`)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <section>
      <HeaderForm step='1/5' title='Información personal del coarrendatario' />

      <form onSubmit={handleSubmit(onSubmit)}>
        <BasicPersonalInformation
          register={register}
          control={control}
          setValue={setValue}
        />

        <Box mb='medium'>
          <Heading level='3' variant='form'>
            Cliente Coninsa
          </Heading>
          <BasicCustomer register={register} />
        </Box>

        <Box mt='medium'>
          <Button type='submit'>Siguiente</Button>
          <Button
            ml='small'
            type='button'
            variant='default'
            onClick={() => navigate(-1)}
          >
            Cancelar
          </Button>
        </Box>
      </form>
    </section>
  )
}

export default PersonalInformation
