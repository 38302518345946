import React from "react";

import SearchPropertyCard from "../../enhancers/SearchPropertyCard";
import SearchPropertyPopup from "../../enhancers/SearchPropertyPopup";

// import SearchBar from "./sections/search-bar";
import SearchBar from "../search-rental-housing/sections/search-bar";
import SearchView from "@coninsa-site/project/src/pages/search-view/search-view";

import searchBuyPropertiesConfigs from "../../facets/search-buy-properties";

export const SearcRentalPage: React.FC = ({
  navigate = (url) => console.log(url),
}) => {
  return (
    <SearchView
      navigate={navigate}
      searchBar={SearchBar}
      itemCard={SearchPropertyCard}
      popupCard={SearchPropertyPopup}

      configs={searchBuyPropertiesConfigs}
    />
  );
};

export default SearcRentalPage;
