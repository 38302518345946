import React from 'react'
import { navigate } from 'gatsby'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'

import { UPDATE_NODE_LEGAL_PERSON_COTENANT } from '../../mutations'
import { LEGAL_COTENANT_APPLICATION } from '../../queries'

import BasicFinancialInformation from '../generic/BasicFinancialInformation'
import HeaderForm from '../../components/HeaderForm'
import FooterForm from '../../components/FooterForm'
import { getEntity, cleanData } from '../utils'

function FinancialInformation(props) {
  const loadedCotenant = props?.loadedCotenant || {}
  const [mutation] = useMutation(UPDATE_NODE_LEGAL_PERSON_COTENANT, {
    refetchQueries: [
      { query: LEGAL_COTENANT_APPLICATION, variables: { id: props.cid } },
    ],
  })
  const { register, handleSubmit } = useForm({
    defaultValues: loadedCotenant,
  })

  const onSubmit = async data => {
    const variables = cleanData(data)

    return mutation({ variables })
      .then(response => {
        const entity = getEntity(response)

        if (entity) {
          navigate('?path=/documentacion')
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <section>
      <HeaderForm step='3/4' title='Información Financiera' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <BasicFinancialInformation register={register} />
        <FooterForm back />
      </form>
    </section>
  )
}

export default FinancialInformation
