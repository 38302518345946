import { gql } from "@apollo/client";

export const SEARCH_RENT_HOUSING_QUERY = gql`
  query SearchRentHousingProperties(
    $text: [String]!
    $and_conditions: [ConditionInput]
    $offset: Int!
    $sort: [SortInput]
  ) {
    search: searchAPISearch(
      index_id: "search_properties"
      facets: [
        {
          field: "string_location"
          min_count: 1
          missing: false
          operator: "="
          limit: 1000
        }
        {
          field: "city_string"
          min_count: 1
          missing: false
          operator: "="
          limit: 100
        }
          {
          field: "property_type"
          min_count: 1
          missing: false
          operator: "="
          limit: 100
        }
        {
          field: "field_property_type"
          min_count: 1
          missing: false
          operator: "="
          limit: 10
        }
      ]
      condition_group: {
        conjunction: AND
        groups: [
          {
            conjunction: AND
            conditions: [
              { name: "field_service_type", value: "AR", operator: "=" }
              { name: "status", value: "1", operator: "=" }
              { name: "field_publish", value: "1", operator: "=" }
              { name: "string_location", value: "!", operator: "<>" }
              { name: "field_lease_value", value: "0", operator: "<>" }
              { name: "field_commerce_housing", value: "1", operator: "=" }
            ]
          }
        ]
      }
      conditions: $and_conditions
      fulltext: { keys: $text, conjunction: "AND" }
      range: { offset: $offset, limit: 12 }
      sort: $sort
    ) {
      result_count
      facets {
        name
        values {
          count
          filter
        }
      }
      documents {
        ... on SearchPropertiesDoc {
          id: property_id
          url
          imageUrl: building_image
          images: slider_images
          propertyType: property_type
          serviceType: field_service_type
          code
          area: field_area
          location: ngram_location
          price: field_lease_value
          state
          bedrooms: field_bedrooms
          bathrooms: field_bathrooms
          latLong: latlon
        }
      }
    }
  }
`;

export const SEARCH_RENT_BUSINESS_QUERY = gql`
  query SearchRentBusinessProperties(
    $text: [String]!
    $and_conditions: [ConditionInput]
    $offset: Int!
    $sort: [SortInput]
  ) {
    search: searchAPISearch(
      index_id: "search_properties"
      facets: [
        {
          field: "string_location"
          min_count: 1
          missing: false
          operator: "="
          limit: 1000
        }
        {
          field: "city_string"
          min_count: 1
          missing: false
          operator: "="
          limit: 100
        }
        {
          field: "property_type"
          min_count: 1
          missing: false
          operator: "="
          limit: 100
        }
        {
          field: "field_property_type"
          min_count: 1
          missing: false
          operator: "="
          limit: 10
        }
      ]
      condition_group: {
        conjunction: AND
        groups: [
          {
            conjunction: AND
            conditions: [
              { name: "field_service_type", value: "AR", operator: "=" }
              { name: "status", value: "1", operator: "=" }
              { name: "field_publish", value: "1", operator: "=" }
              { name: "string_location", value: "!", operator: "<>" }
              { name: "field_lease_value", value: "0", operator: "<>" }
              { name: "field_commerce_housing", value: "0", operator: "=" }
            ]
          }
        ]
      }
      conditions: $and_conditions
      fulltext: { keys: $text, conjunction: "AND" }
      range: { offset: $offset, limit: 12 }
      sort: $sort
    ) {
      result_count
      facets {
        name
        values {
          count
          filter
        }
      }
      documents {
        ... on SearchPropertiesDoc {
          id: property_id
          url
          imageUrl: building_image
          images: slider_images
          propertyType: property_type
          serviceType: field_service_type
          code
          area: field_area
          location: ngram_location
          price: field_lease_value
          state
          bedrooms: field_bedrooms
          bathrooms: field_bathrooms
          latLong: latlon
        }
      }
    }
  }
`;

export const SEARCH_BUY_PROPERTIES_QUERY = gql`
  query SearchRentHousingProperties(
    $text: [String]!
    $and_conditions: [ConditionInput]
    $offset: Int!
    $sort: [SortInput]
  ) {
    search: searchAPISearch(
      index_id: "search_properties"
      facets: [
        {
          field: "string_location"
          min_count: 1
          missing: false
          operator: "="
          limit: 1000
        }
        {
          field: "city_string"
          min_count: 1
          missing: false
          operator: "="
          limit: 100
        }
        {
          field: "property_type"
          min_count: 1
          missing: false
          operator: "="
          limit: 100
        }
        {
          field: "field_property_type"
          min_count: 1
          missing: false
          operator: "="
          limit: 10
        }
      ]
      condition_group: {
        conjunction: AND
        groups: [
          {
            conjunction: AND
            conditions: [
              { name: "field_service_type", value: "CO", operator: "=" }
              { name: "status", value: "1", operator: "=" }
              { name: "field_publish", value: "1", operator: "=" }
              { name: "string_location", value: "!", operator: "<>" }
              { name: "sale_value", value: "0", operator: "<>" }
            ]
          }
        ]
      }
      conditions: $and_conditions
      fulltext: { keys: $text, conjunction: "AND" }
      range: { offset: $offset, limit: 12 }
      sort: $sort
    ) {
      result_count
      facets {
        name
        values {
          count
          filter
        }
      }
      documents {
        ... on SearchPropertiesDoc {
          id: property_id
          url
          imageUrl: building_image
          images: slider_images
          propertyType: property_type
          serviceType: field_service_type
          code
          area: field_area
          location: ngram_location
          price: sale_value
          state
          bedrooms: field_bedrooms
          bathrooms: field_bathrooms
          latLong: latlon
        }
      }
    }
  }
`;

export const SEARCH_FEATURED_PROPERTIES_QUERY = gql`
  query SearchFeaturedProperties(
    $text: [String]!
    $and_conditions: [ConditionInput]
    $offset: Int!
    $sort: [SortInput]
  ) {
    search: searchAPISearch(
      index_id: "search_properties"
      facets: [
        {
          field: "string_location"
          min_count: 1
          missing: false
          operator: "="
          limit: 1000
        }
        {
          field: "city_string"
          min_count: 1
          missing: false
          operator: "="
          limit: 100
        }
        {
          field: "property_type"
          min_count: 1
          missing: false
          operator: "="
          limit: 100
        }
        {
          field: "field_property_type"
          min_count: 1
          missing: false
          operator: "="
          limit: 10
        }
      ]
      condition_group: {
        conjunction: AND
        groups: [
          {
            conjunction: AND
            conditions: [
              { name: "field_service_type", value: "AR", operator: "=" }
              { name: "status", value: "1", operator: "=" }
              { name: "field_publish", value: "1", operator: "=" }
              { name: "string_location", value: "!", operator: "<>" }
              { name: "field_lease_value", value: "0", operator: "<>" }
              { name: "mega_discount", value: "1", operator: "=" }
            ]
          }
        ]
      }
      conditions: $and_conditions
      fulltext: { keys: $text, conjunction: "AND" }
      range: { offset: $offset, limit: 12 }
      sort: $sort
    ) {
      result_count
      facets {
        name
        values {
          count
          filter
        }
      }
      documents {
        ... on SearchPropertiesDoc {
          id: property_id
          url
          imageUrl: building_image
          images: slider_images
          propertyType: property_type
          serviceType: field_service_type
          code
          area: field_area
          location: ngram_location
          price: field_lease_value
          state
          bedrooms: field_bedrooms
          bathrooms: field_bathrooms
          latLong: latlon
        }
      }
    }
  }
`;
