import React from 'react'
import PropTypes from 'prop-types'
import { Text } from 'theme-ui'

const FeaturedListBody = ({ children, ...props }) => {
  const baseStyles = {
    fontSize: ['14px', '16px'],
  }
  return (
    <Text __css={baseStyles} {...props}>
      {children}
    </Text>
  )
}

FeaturedListBody.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FeaturedListBody
