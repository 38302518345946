import budgetIcon from '../../../assets/icons/rounded/budget.svg'
import adviceIcon from '../../../assets/icons/rounded/advice.svg'
import documentsIcon from '../../../assets/icons/rounded/documents.svg'
import initialFeeIcon from '../../../assets/icons/rounded/initial-fee.svg'
import mortgageCreditIcon from '../../../assets/icons/rounded/mortgage-credit.svg'
import registerPropertyIcon from '../../../assets/icons/rounded/register-property.svg'

export default [
  {
    icon: budgetIcon,
    step: 'Paso 1',
    title: 'Define el presupuesto',
    body: 'estimado de tu inversión, la ciudad y el proyecto donde deseas cumplir tu sueño.',
  },

  {
    icon: adviceIcon,
    step: 'Paso 2',
    title: 'Solicita una asesoría',
    body: 'virtual o telefónica con nuestro equipo especializado, para revisar el plan de pagos, conocer más y enamorarte de tu nuevo apartamento.',
  },

  {
    icon: documentsIcon,
    step: 'Paso 3',
    title: 'Reúne los documentos',
    body: 'requeridos para dar paso a la vinculación.',
  },

  {
    icon: initialFeeIcon,
    step: 'Paso 4',
    title: 'Realiza el pago de la cuota inicial',
    body: 'desde donde te encuentres de manera fácil y rápida.',
  },

  {
    icon: mortgageCreditIcon,
    step: 'Paso 5',
    title: 'Para el crédito hipotecario',
    body: 'te ayudamos a gestionarlo con entidades financieras aliadas (Bancolombia, Banco de Occidente, Davivienda, Banco Unión).',
  },

  {
    icon: registerPropertyIcon,
    step: 'Paso 6',
    title: 'Al momento de escriturar tu inmueble',
    body: 'si no puedes asistir, deberás tener una persona apoderada en Colombia para que realice la firma y reciba tu apartamento.',
  },
]
