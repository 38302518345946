import React from "react";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Breadcrumb, { BreadcrumbItem } from "@coninsa-s2/breadcrumb";
import * as Accordion from "@coninsa-s2/accordion";
import Heading from "@coninsa-s2/heading";

import invoiceIcon from "../../../assets/icons/invoice.svg";

import termDocuments2024 from "../../../assets/documents/property-2024.js";
import termDocuments2023 from "../../../assets/documents/property-2023.js";
import termDocuments2021 from "../../../assets/documents/property-2021.js";

export default function Property() {
  const breadcrumbItems = [
    {
      label: "Inicio",
      url: "/terminos-y-condiciones",
    },
    {
      label: "Términos y condiciones arrendamientos",
    },
  ];

  return (
    <Section>
      <Container>
        <div className="mb-8">
          <Breadcrumb>
            {breadcrumbItems.map((item) => (
              <BreadcrumbItem>
                {item.url && <a href={item.url}>{item.label}</a>}
                {!item.url && item.label}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        </div>

        <div className="flex items-center gap-4">
          <img src={invoiceIcon} alt="building icon" />
          <div>
            <span className="text-lg text-s2-dark-blue lg:text-[22px]">
              Términos y condiciones
            </span>
            <Heading size="2xl" tint="teal" weight="bold">
              Arrendamientos
            </Heading>
          </div>
        </div>

        <div>
          <Accordion.Root type="single" defaultValue="item-1" collapsible>
            <Accordion.Item value="item-1">
              <Accordion.Trigger>
                <h4 className="font-display text-2xl font-medium">Año 2024</h4>
              </Accordion.Trigger>
              <Accordion.Content>
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
                  {termDocuments2024.map((item) => (
                    <a
                      key={item?.id}
                      href={item?.url}
                      target="_blank"
                      className="flex items-center gap-2 rounded-lg bg-white px-2 py-3 shadow hover:shadow-md lg:gap-4 lg:px-5 lg:py-4"
                    >
                      <img
                        src={item?.icon}
                        className="h-16 w-14 lg:h-auto lg:w-auto"
                      />
                      <div>
                        <p className="text-sm font-bold text-s2-dark-blue lg:text-base">
                          {item?.label}
                        </p>
                      </div>
                    </a>
                  ))}
                </div>
              </Accordion.Content>
            </Accordion.Item>
            <Accordion.Item value="item-2">
              <Accordion.Trigger>
                <h4 className="font-display text-2xl font-medium">Año 2023</h4>
              </Accordion.Trigger>
              <Accordion.Content>
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
                  {termDocuments2023.map((item) => (
                    <a
                      key={item?.id}
                      href={item?.url}
                      target="_blank"
                      className="flex items-center gap-2 rounded-lg bg-white px-2 py-3 shadow hover:shadow-md lg:gap-4 lg:px-5 lg:py-4"
                    >
                      <img
                        src={item?.icon}
                        className="h-16 w-14 lg:h-auto lg:w-auto"
                      />
                      <div>
                        <p className="text-sm font-bold text-s2-dark-blue lg:text-base">
                          {item?.label}
                        </p>
                      </div>
                    </a>
                  ))}
                </div>
              </Accordion.Content>
            </Accordion.Item>

            <Accordion.Item value="item-3">
              <Accordion.Trigger>
                <h4 className="font-display text-2xl font-medium">Año 2021</h4>
              </Accordion.Trigger>
              <Accordion.Content>
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
                  {termDocuments2021.map((item) => (
                    <a
                      key={item?.id}
                      href={item?.url}
                      target="_blank"
                      className="flex items-center gap-2 rounded-lg bg-white px-2 py-3 shadow-lg hover:shadow-xl lg:gap-4 lg:px-5 lg:py-4"
                    >
                      <img
                        src={item?.icon}
                        className="h-16 w-14 lg:h-auto lg:w-auto"
                      />
                      <div>
                        <p className="text-sm font-bold text-s2-dark-blue lg:text-base">
                          {item?.label}
                        </p>
                      </div>
                    </a>
                  ))}
                </div>
              </Accordion.Content>
            </Accordion.Item>
          </Accordion.Root>
        </div>
      </Container>
    </Section>
  );
}
