import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../layout/default/Layout'
import { paragraphResolver } from '../paragraphs'

import page from '../static-pages/somos'

function Page({ data, ...props }) {
  return (
    <Layout
    
      headerAds={data?.drupal?.ads?.header}
      popupAds={data?.drupal?.ads?.popUp}
      footerAds={data?.drupal?.ads?.footer}
    >
      <Helmet>
        <title>Somos Coninsa | Coninsa</title>
        <meta name="description" content="Somos el resultado de un equipo de Colaboradores, que inspirados en las necesidades de nuestros Clientes, hacemos posible el sueño de miles de familias y empresarios, porque sabemos que el bienestar es verdadero solo cuando la funcionalidad y el diseño van de la mano." />
        <link rel="canonical" href="https://www.coninsa.co/somos-coninsa/"/>
      </Helmet>
      {paragraphResolver(page.header)}
      <h1 hidden>Somos Coninsa</h1>
      {page.sections.map(section => paragraphResolver(section))}
    </Layout>
  )
}

export const query = graphql`
  query SomosConinsaPageQuery {
    drupal {
      ads: getAdsByUrl(path: "/somos-coninsa") {
        header {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        popUp {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        footer {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
      }
    }
  }
`

export default Page
