import React, { useEffect, useState } from "react";
import { getQueryString } from "../page";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";

import { UPDATE_BUILDING_PROPERTY } from "../graphql/mutations";
import generalFeatures from "../data/features.js";

export default function Step4({ onSuccess, onBack, id }) {
  const { register, handleSubmit, setError } = useForm();
  const [mutation] = useMutation(UPDATE_BUILDING_PROPERTY);
  const [propertyType, setPropertyType] = useState();
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    const propertyType = getQueryString("property_type");

    if (propertyType) {
      setPropertyType(propertyType);
      setFeatures(generalFeatures[propertyType]["features"])
    }
  }, []);

  const onSubmit = async (data) => {
    const response = await mutation({
      variables: {
        id,
        input: {
          fieldFeatures: data.features.map((feature) => ({
            targetId: feature,
          })),
        },
      },
    });

    const violations = response?.data?.updateBuildingProperty?.violations;
    const errors = response?.data?.updateBuildingProperty?.errors;

    if (violations?.length === 0 && errors?.length === 0) {
      return onSuccess({ id });
    } else {
      setError("Algo ocurrió mal. Vuelva a intentarlo más tarde.");
    }
  };

  return (
    <div className="co-card max-w-4xl mx-auto">
      <form className="co-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex gap-x-36 flex-col md:flex-row">
          <div className="flex flex-col gap-y-5">
            <fieldset className="form-group are-compact-checkboxes">
              <legend>Características del inmueble</legend>
              {features.map((zone) => (
                <div key={zone?.value} className="form-control">
                  <input
                    {...register("features[]")}
                    className="sign"
                    type="checkbox"
                    value={zone?.value}
                    id={`option-${zone?.value}`}
                  />
                  <label htmlFor={`option-${zone?.value}`}>{zone?.label}</label>
                </div>
              ))}
            </fieldset>
          </div>
        </div>

        <div className="flex justify-between gap-2 md:flex-row">
          <button
            type="button"
            onClick={onBack}
            className="co-button is-secondary is-large has-fixed-icon"
          >
            <i className="co-icon icon-arrow"></i>
            Regresar
          </button>

          <button className="co-button is-progress-default is-large w-full md:w-auto">
            Continuar
          </button>
        </div>
      </form>
    </div>
  );
}

