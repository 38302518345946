import React from "react";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Breadcrumb, { BreadcrumbItem } from "@coninsa-s2/breadcrumb";
import Heading from "@coninsa-s2/heading";

export default function ConsignathonPromotion() {
  const breadcrumbItems = [
    {
      label: "Inicio",
      url: "/terminos-y-condiciones",
    },
    {
      label: "Términos y condiciones generales",
      url: "/terminos-y-condiciones/generales",
    },
    {
      label: "Promoción consignatón 2018",
    },
  ];

  return (
    <Section>
      <Container>
        <div className="mb-8">
          <Breadcrumb>
            {breadcrumbItems.map((item) => (
              <BreadcrumbItem>
                {item.url && <a href={item.url}>{item.label}</a>}
                {!item.url && item.label}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        </div>
        <div className="mb-7">
          <span className="text-lg text-s2-dark-blue lg:text-[22px]">
            Términos y condiciones
          </span>
          <Heading size="2xl">Promoción Consignatón 2018</Heading>
        </div>

        <div className="prose">
          <p>
            Las siguientes son las condiciones de la promoción Consignatón, la
            cual rige en las ciudades de Medellín, Barranquilla, Cartagena y
            Bogotá, solo para mayores de edad.
          </p>
          <ol>
            <li>
              Condiciones promoción Consignatón Coninsa Ramón H." Vigencia de la
              promoción Consignatón Coninsa Ramón H. del 20 de julio de 2017
              hasta el 30 de junio de 2018.
            </li>
            <li>
              Válido un bono de Homecenter por valor de $250.000 por cada
              inmueble consignado y arrendado durante la vigencia de la
              promoción mencionada en el punto anterior.
            </li>
            <li>
              Para acceder al bono se debe hacer un contrato con Coninsa Ramón
              H. mínimo por seis meses, los inmuebles que aplican para la
              promoción son aquellos cuyos cánones sean superiores o iguales a
              $1.000.000 (un millón de pesos m/cte)
            </li>
            <li>
              Una vez firmado el contrato del inmueble consignado la vigencia
              para reclamar el bono será únicamente de 30 días calendario que
              cuentan a partir de la fecha de firma del contrato.
            </li>
            <li>
              No aplica para arrendatarios y/o propietarios con contrato vigente
              en la compañía al 20 de julio de 2017
            </li>
            <li>
              Coninsa Ramón H. se reservará el derecho de consignar el inmueble,
              de acuerdo a su ubicación y estado.
            </li>
            <li>Bono no redimible en dinero.</li>
            <li>Aplican condiciones y restricciones</li>
          </ol>
        </div>
      </Container>
    </Section>
  );
}
