import React from 'react'
import PropTypes from 'prop-types'

import { Collapse } from 'ui'
import { paragraphResolver } from '.'

function PAccordion({ items, ...props }) {
  return <Collapse {...props}>{items.map(paragraphResolver)}</Collapse>
}

PAccordion.defaultProps = {
  items: [],
  variant: 'accordion',
}

PAccordion.propTypes = {
  items: PropTypes.arrayOf(),
  variant: PropTypes.string,
}
export default PAccordion
