import React from 'react'
import PropTypes from 'prop-types'

import { Box, Container } from 'theme-ui'
import bannerImage from '../../images/banner2x.png'
import bannerDesktopImage from '../../images/banner-desktop2x.png'

const Hero = ({ bgImages, children, hasContent, variant, ...props }) => {
  const [bgMobile, bgDesktop] = bgImages

  const baseStyles = {
    backgroundImage: [`url(${bgMobile})`, `url(${bgDesktop})`],
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    backgroundSize: ['contain', 'cover'],
    pt: ['4rem', '18rem'],
    px: 'medium',
    position: 'relative',

    '&:after': {
      content: '""',
      bottom: 0,
      display: 'block',
      height: hasContent ? 100 : 0,
      width: '100%',
      bg: 'light-0',
      position: 'absolute',
      left: 0,
      right: 0,
    },
  }

  return (
    <Box __css={baseStyles} {...props} variant={variant} __themeKey='heros'>
      <Container>{children}</Container>
    </Box>
  )
}

Hero.defaultProps = {
  hasContent: false,
  bgImages: [bannerImage, bannerDesktopImage],
  variant: 'default',
}

Hero.propTypes = {
  children: PropTypes.node.isRequired,
  hasContent: PropTypes.bool,
  bgImages: PropTypes.arrayOf([PropTypes.string]),
  variant: PropTypes.string,
}

export default Hero
