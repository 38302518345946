import React from 'react'
import PropTypes from 'prop-types'
import S from 'string'

import { Grid } from 'theme-ui'
import { Header, Section } from 'ui'
import { paragraphResolver } from '.'

function PSection({ content, grid, header, id, variant, ...props }) {
  const headerComponent = header && (
    <Header
      headline={header?.headline}
      sx={{ alignSelf: 'center', ...header.sx }}
      variant={variant}
    >
      {header.title}
    </Header>
  )

  const gridSettings = {
    columns: ['1fr', grid.columns],
    gap: 'large',
  }

  const titleDashed = header && S(header.title.toLowerCase()).slugify().dasherize().s

  return (
    <Section
      id={titleDashed}
      title={headerComponent}
      variant={variant}
      key={id}
      {...props}
    >
      <Grid {...gridSettings}>
        {content.map(paragraph =>
          paragraphResolver({ parentVariant: variant, ...paragraph })
        )}
      </Grid>
    </Section>
  )
}

PSection.defaultProps = {
  content: undefined,
  grid: {
    columns: '1fr',
  },
  variant: 'default',
  header: {
    headline: null,
    sx: {},
    title: '',
  },
}

PSection.propTypes = {
  content: PropTypes.node,
  header: PropTypes.shape({
    title: PropTypes.string.isRequired,
    headline: PropTypes.string,
    sx: PropTypes.oneOf({}),
  }),
  id: PropTypes.string.isRequired,
  grid: PropTypes.oneOf({
    columns: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  variant: PropTypes.string,
}

export default PSection
