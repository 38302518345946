import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from 'theme-ui'

export const ICON_LIST_PARAGRAPH = 'Drupal_ParagraphIconList'

export function mapper({ items, ...data }) {
  const normalizedItems = items?.map(item => item?.entity)

  return {
    ...data,
    items: normalizedItems,
  }
}

function PIconList({ title, items, ...props }) {
  return (
    <Grid columns={[2, 6]} sx={{ display: 'grid' }} mt='medium' {...props}>
      {items?.map(item => (
        <co-image-cap image-url={item.icon.url} label={item.label} />
      ))}
    </Grid>
  )
}

PIconList.defaultProps = {
  items: [],
}

PIconList.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(),
}

export default PIconList
