import React from 'react'
import { Box, Grid } from 'theme-ui'
import Carousel from 'nuka-carousel'

import { Header, Section } from 'ui'
import BuildingFaq from '../components/BuildingFaq'

function PropertyFaqs() {
  const titleStyles = {
    m: 'auto 0',
  }

  return (
    <Section sx={{ mb: ['small', 'medium'] }}>
      <Box sx={{ display: ['block', 'none'] }}>
        <Box sx={titleStyles}>
          <Header>¿Tienes dudas?</Header>
          <Box mb='medium'>
            Conoce más información sobre el proceso de arrendamiento
          </Box>
        </Box>

        <Box sx={{ height: '310px' }}>
          <Carousel withoutControls slidesToShow='1.30'>
            <BuildingFaq
              bg='secondary'
              cardBg='secondary'
              icon='fa-reg-comment-dots'
              label='Preguntas frecuentes'
              description='¿Necesitas ayuda?, encuentra las respuestas que necesitas.'
              url='https://www.coninsa.co/preguntas-frecuentes'
            />

            <BuildingFaq
              bg='neutral'
              cardBg='neutral'
              icon='fa-reg-file-alt'
              label='Sobre Arrendamientos'
              description='Conoce los beneficios del servicio de arrendamiento y sus términos y
              condiciones.'
              url='https://www.coninsa.co/personas/arrendar-un-inmueble'
            />

            <BuildingFaq
              bg='primary'
              cardBg='primary'
              icon='fi-settings'
              label='¿Tienes un requerimiento?'
              description='Conoce nuestros medios habilitados para generar un requerimiento.'
              url='https://www.coninsa.co/servicio-cliente'
            />
          </Carousel>
        </Box>
      </Box>

      <Grid
        columns={4}
        sx={{ display: ['none', 'grid'] }}
      >
        <Box sx={titleStyles}>
          <Header>¿Tienes dudas?</Header>
          <Box>Conoce más información sobre el proceso de arrendamiento</Box>
        </Box>

        <BuildingFaq
          bg='secondary'
          cardBg='secondary'
          icon='fa-reg-comment-dots'
          label='Preguntas frecuentes'
          description='¿Necesitas ayuda?, encuentra las respuestas que necesitas.'
          url='https://www.coninsa.co/preguntas-frecuentes'
        />

        <BuildingFaq
          bg='neutral'
          cardBg='neutral'
          icon='fa-reg-file-alt'
          label='Sobre Arrendamientos'
          description='Conoce los beneficios del servicio de arrendamiento y sus términos y
              condiciones.'
          url='https://www.coninsa.co/personas/arrendar-un-inmueble'
        />

        <BuildingFaq
          bg='primary'
          cardBg='primary'
          icon='fi-settings'
          label='¿Tienes un requerimiento?'
          description='Conoce nuestros medios habilitados para generar un requerimiento.'
          url='https://www.coninsa.co/servicio-cliente'
        />
      </Grid>
    </Section>
  )
}

export default PropertyFaqs
