import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

import { Collapse } from 'ui'

function PAccordion({ title, body, ...props }) {
  return (
    <Collapse.Item title={title} autoFocus index={0} {...props}>
      <Box dangerouslySetInnerHTML={{ __html: body?.value }} />
    </Collapse.Item>
  )
}

PAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
}
export default PAccordion
