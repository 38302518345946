import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, Image, Paragraph } from 'theme-ui'

import ProjectShare from '../components/ProjectShare'
import ProjectVideos from '../components/ProjectVideos'

function ProjectBanner({
  brand,
  name,
  subtitle,
  state,
  isVis,
  banner,
  videos,
  whatsappMsg,
  ...props
}) {
  const titleStyles = {
    color: 'neutral',
    fontFamily: 'Oswald',
    fontSize: ['22px', '36px'],
    fontWeight: '500',
    mb: ['8px', 'medium'],
    textTransform: 'uppercase',
    lineHeight: '20px',
  }

  return (
    <Box sx={{ pt: ['medium', 'large'], pb: ['medium', 'large'], px: [4, 0] }}>
      <Grid columns={['1fr', '2fr 1fr']} {...props}>
        <Box sx={{ display: ['block', 'none'] }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ProjectShare whatsappMsg={whatsappMsg} />
          </Box>
        </Box>

        <Box sx={{ display: 'flex', mb: ['16px', 'medium'] }}>
          <Box
            sx={{
              borderRadius: '8px',
              boxShadow: '0px 0px 29px #00000017',
              height: '100px',
              mr: ['small', 'medium'],
              p: 'small',
              width: '100px',
            }}
          >
            <Image src={brand} />
          </Box>

          <Box>
            <Box sx={{ display: 'flex', gap: '.3rem' }}>
              {state?.name && (
                <co-outstanding class='mb-3' variant={state?.variant}>
                  {state?.name}
                </co-outstanding>
              )}

              {isVis && (
                <co-outstanding class='mb-3' variant='senary'>
                  VIS
                </co-outstanding>
              )}
            </Box>

            <Box sx={titleStyles}>{name}</Box>
            <Paragraph sx={{ fontSize: [1, 2], lineHeight: ['15px', '17px'] }}>
              {subtitle}
            </Paragraph>
          </Box>
        </Box>

        <Box sx={{ display: ['none', ' block'] }}>
          <ProjectShare whatsappMsg={whatsappMsg} />
        </Box>
      </Grid>

      {banner && (
        <Image
          sx={{ borderRadius: '8px', height: ['230px', 'auto'], objectFit: 'cover' }}
          src={banner}
        />
      )}

      {!banner && <ProjectVideos videos={videos} />}
    </Box>
  )
}

ProjectBanner.defaultProps = {
  brand: '',
  subtitle: '',
  videos: [],
}

ProjectBanner.propTypes = {
  brand: PropTypes.string,
  name: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  state: PropTypes.string.isRequired,
  isVis: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  videos: PropTypes.array,
  whatsappMsg: PropTypes.string.isRequired,
}

export default ProjectBanner
