import {
  CREATE_NODE_NATURAL_PERSON_APPLICATION,
  UPDATE_NODE_NATURAL_PERSON_APPLICATION,
} from './natural-person'

import {
  CREATE_NODE_LEGAL_PERSON_APPLICATION,
  UPDATE_NODE_LEGAL_PERSON_APPLICATION,
} from './legal-person'

import {
  CREATE_NODE_NATURAL_PERSON_COTENANT,
  UPDATE_NODE_NATURAL_PERSON_COTENANT,
} from './co-natural-person'

import {
  CREATE_NODE_LEGAL_PERSON_COTENANT,
  UPDATE_NODE_LEGAL_PERSON_COTENANT,
} from './co-legal-person'

export {
  CREATE_NODE_NATURAL_PERSON_APPLICATION,
  UPDATE_NODE_NATURAL_PERSON_APPLICATION,

  CREATE_NODE_LEGAL_PERSON_APPLICATION,
  UPDATE_NODE_LEGAL_PERSON_APPLICATION,

  CREATE_NODE_NATURAL_PERSON_COTENANT,
  UPDATE_NODE_NATURAL_PERSON_COTENANT,

  CREATE_NODE_LEGAL_PERSON_COTENANT,
  UPDATE_NODE_LEGAL_PERSON_COTENANT,
}
