import React from "react";

import ProjectThumbnailCard from "@coninsa-s2/project-thumbnail-card";

interface T_props {
  name: string;
  location: string;
  url: string;
  imageUrl: string;
  brandUrl: string;
}

const ProjectThumbnailSearchCard: React.FC = ({
  name,
  location,
  url,
  imageUrl,
  brandUrl,
}: T_props) => {
  return (
    <ProjectThumbnailCard
      title={name}
      location={location}
      url={`${url}/avance-de-obra`}
      imageUrl={imageUrl}
      logoUrl={brandUrl}
    />
  );
};

export default ProjectThumbnailSearchCard;
