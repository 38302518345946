import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { Box, Checkbox, Input, Label, Button, Select } from 'theme-ui'
import IntlTelInput from 'react-intl-tel-input'

import HabeasData from './HabeasData'

function ContactForm() {
  const { register, handleSubmit, reset } = useForm({})
  const [phone, setPhone] = useState()

  const onChange = (_, phoneNumber, country) => {
    const dialCode = country.dialCode
    const expression = `\\+${dialCode}`
    const regex = new RegExp(expression)
    const withoutDial = phoneNumber.replace(regex, '')
    const internationalNumber = `+${country.dialCode}` + `${withoutDial}`
    setPhone(internationalNumber)
  }

  const onSubmit = async data => {
    let zohoInfo;

    if (data.tipo_negocio === 'Proyectos') {
      zohoInfo = {
        data: [
          {
            First_Name: data.name,
            Last_Name: data.last_name,
            Owner: { id: '2528071000000120011' },
            Email: data.email,
            Mobile: phone,
            Regional: "Medellin",
            Origen_de_Informaci_n: 'Visita Sala',
            Medio_Publicitario: 'www.coninsa.co',
            Creador_API: 'www.coninsa.co',
            Estrategia_de_Campa_a: 'Habeas Data',
            Tipo_de_Canal: 'Digital',
            Tipo_de_Contacto: data.tipo_negocio,
            Layout: {
              id: '2528071000000091055',
            },
            Proyecto_de_interes: {
              id: '2528071000024260071',
            },
          },
        ],
      };
    } else if (data.tipo_negocio === 'Arrendamientos') {
      zohoInfo = {
        data: [
          {
            First_Name: data.name,
            Last_Name: data.last_name,
            Owner: { id: '2528071000008997151' },
            Email: data.email,
            Mobile: phone,
            Regional: "Medellin",
            Sucursal: "ABR MEDELLIN CENTRO",
            Origen_de_Informaci_n: 'Visita Sala',
            Medio_Publicitario: 'www.coninsa.co',
            Creador_API: 'www.coninsa.co',
            Estrategia_de_Campa_a: 'Habeas Data',
            Tipo_de_Canal: 'Digital',
            Tipo_de_Contacto: 'Otros',
            Layout: {
              id: '2528071000003925032',
            }
          },
        ],
      };
    }

    const url = 'https://api.coninsa.co/api/v2/zohocrm-create-lead'

    return new Promise((resolve, reject) => {
      axios
        .post(url, zohoInfo)
        .then(response => {
          reset()
          resolve(response)
          window.location.replace(`/gracias?destination=${window.location.pathname}`)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const CallStyles = {
    bg: ['secondary', 'secondary'],
    borderRadius: '7px',
    color: 'white',
    fontSize: 1,
    fontWeight: 'bold',
    mt: 'xsmall',
    py: '10px',
    textTransform: 'uppercase',
    width: '100%',
    '&:hover': {
      bg: 'green-3',
    },
  }

  const baseStyles = {
    input: {
      fontSize: [1],
      mb: 'xsmall',
      py: [2],
    },
  }

  const checkboxStyles = {
    'input:checked ~ &': {
      color: 'accent',
    },
    'input:focus ~ &': {
      color: 'accent',
    },
  }

  const phoneStyles = {
    mb: ['xsmall', 'none'],
    '.allow-dropdown.intl-tel-input': { display: 'block' },
    input: {
      width: '100%',
      border: '1px solid #d0d1d1',
      borderRadius: '4px',
      lineHeight: 'inherit',
    },
  }

  return (
    <Box>
      <Box as='form' onSubmit={handleSubmit(onSubmit)}>
        <Box sx={baseStyles} mb='xsmall' register={register}>
          {/* Campo adicional para Tipo de Negocio */}
          <Box>
            <Label htmlFor="tipo_negocio">Tipo de Negocio</Label>
            <Select
              {...register('tipo_negocio')}
              id="tipo_negocio"
              required
              defaultValue=""
            >
              <option value="" disabled>Seleccione una opción</option>
              <option value="Arrendamientos">Arrendamientos</option>
              <option value="Proyectos">Proyectos</option>
            </Select>
          </Box>
          
          <Box sx={{ display: ['block', 'flex'], gap: 3 }}>
            <Input
              {...register('name')}
              id='name'
              placeholder='Nombres'
              required
            />
            <Input
              {...register('last_name')}
              id='last_name'
              placeholder='Apellidos'
              required
            />
          </Box>
          <Box
            sx={{
              display: ['block', 'grid'],
              gridTemplateColumns: 'repeat(2, 1fr)',
              gap: 3,
            }}
          >
            <Box sx={phoneStyles}>
              <IntlTelInput
                {...register('phone')}
                id='phone'
                placeholder='Número de contacto'
                defaultCountry={'co'}
                onPhoneNumberChange={onChange}
                telInputProps={{ required: true }}
              />
            </Box>

            <Box>
              <Input
                {...register('email')}
                id='email'
                placeholder='Correo electrónico'
                required
              />
            </Box>
          </Box>

          <HabeasData />

          <div className='block md:grid grid-cols-[60%_40%] gap-4 flex-initial'>
            <Label sx={{ alignItems: 'center', mt: 'small' }}>
              <Checkbox
                sx={checkboxStyles}
                {...register('accept_conditions')}
                required
              />
              <Box
                sx={{
                  fontSize: [0, 1],
                }}
              >
                Autorizo el tratamiento y la
                <Box
                  as='a'
                  href='https://www.coninsa.co/politica-de-tratamiento-de-datos-personales-de-coninsa-ramon-h-sa'
                  sx={{ color: 'accent', ml: '3px', textDecoration: 'underline' }}
                  target='_blank'
                >
                  política de datos personales,
                </Box>
                 este consentimiento se toma por medio del canal presencial y sus datos serán almacenados en nuestra base de datos.
              </Box>
            </Label>
            <Button sx={CallStyles} type='submit' variant='sharing'>
              Aceptar
            </Button>
          </div>
        </Box>
      </Box>
    </Box>
  )
}

export default ContactForm