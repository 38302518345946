import React from "react";
import Layout from "../../../layout/default/Layout";
import { Router, navigate } from "@reach/router";

import { NeighborsAndFriendsReferralPlanPage } from "@coninsa-site/terms-and-conditions/pages/general";


function NeighborsAndFriendsReferralPlan() {
  return (
    <Layout>
      <Router basepath="/terminos-y-condiciones/generales/plan-referidos-vecinos-y-amigos-2018">
        <NeighborsAndFriendsReferralPlanPage path="*" navigate={navigate} />
      </Router>
    </Layout>
  );
}

export default NeighborsAndFriendsReferralPlan;
