import pdfIcon from "../icons/pdf.svg";

export default [
  {
    id: "terms-2024-1",
    icon: pdfIcon,
    label: "Referidos Coninsa",
    url: "https://d2xt7650da4t25.cloudfront.net/s3fs-public/2024-06/T%C3%A9rminos%20y%20condiciones%20AMIGOS%20CONINSA%20Jun%202024.pdf?VersionId=sWKWNfNL3Mu4uTKik2Yhl6ZJ5VUSt4zl",
  },
  {
    id: "terms-2024-2",
    icon: pdfIcon,
    label: "Feria inmobiliaria",
    url: "https://d2xt7650da4t25.cloudfront.net/s3fs-public/2024-07/Feria%20Inmobiliaria%20Coninsa%202024%20-%20T%C3%A9rminos%20y%20condiciones%202024.pdf?VersionId=l9t3pXdMhZb3OeoFPXUjRjWkjuNeN8NS",
  },
];
