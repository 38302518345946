import { from, ApolloClient, InMemoryCache } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import fetch from 'isomorphic-fetch'

const clientFactory = new ApolloClient({
  fetch,
  link: from([
    createUploadLink({
      uri: `${process.env.GATSBY_DRUPAL_URL}/graphql`,
      credentials: 'include',
    }),
  ]),
  cache: new InMemoryCache(),
})

export default clientFactory
