import React from 'react'
import { Box, Grid, Input, Label, Radio } from 'theme-ui'

import Location from '../../Location'
import Control from '../../components/Control'

import {
  getOptions,

  // Options
  idTypes,
} from '../options'

function BasicSpouseInformation({ register, control, setValue }) {
  return (
    <Grid gap={4} columns={[2, '1fr 1fr']}>
      <Control label='Nombre completo'>
        <Input {...register('fieldSpouseFullName')} type='text' />
      </Control>
      <Box>
        <Label>Tipo de indetificación</Label>
        <Box sx={{ display: 'flex' }}>
          {getOptions(idTypes).map(option => (
            <Label>
              <Radio {...register('fieldSpouseId')} value={option.value} />
              {option.label}
            </Label>
          ))}
        </Box>
      </Box>
      <Control label='Número de identificación'>
        <Input {...register('fieldSpouseIdNumber')} type='text' />
      </Control>
      <Control label='Celular'>
        <Input {...register('fieldSpouseCellPhone')} type='text' />
      </Control>
      <Control label='Empresa donde labora'>
        <Input {...register('fieldSpouseWorkCompany')} type='text' />
      </Control>
      <Control label='Teléfono Oficina'>
        <Input {...register('fieldSpouseOfficePhone')} type='text' />
      </Control>
      <Control label='Dirección oficina'>
        <Input {...register('fieldSpouseOfficeAddress')} type='text' />
      </Control>
      <Control label='Ciudad'>
        <Location name='fieldSpouseCity' setValue={setValue} control={control} />
      </Control>
      <Control label='Cargo'>
        <Input {...register('fieldSpousePosition')} type='text' />
      </Control>
      <Control label='Salario $'>
        <Input {...register('fieldSpouseSalary')} type='text' />
      </Control>
    </Grid>
  )
}

export default BasicSpouseInformation
