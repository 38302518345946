/* eslint-disable react/require-default-props */
import React from 'react'
import PropTypes from 'prop-types'

export default function IconCard({ icon, children }) {
  return (
    <div className='flex flex-col items-center w-full md:max-w-[294px]'>
      <img
        className='w-16 h-16 md:w-[88px] md:h-[88px]'
        alt='commercial-legal'
        src={icon}
      />
      <div className='text-white text-center'>{children}</div>
    </div>
  )
}

IconCard.propTypes = {
  icon: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
}
