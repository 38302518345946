import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

import BaseLink from './BaseLink'
import BaseWrapper from './BaseWrapper'
import SubSubMenuLink from './SubSubMenuLink'
import MenuWrapper from './MenuWrapper'

function SubMenuLink({
  columns,
  icon,
  label,
  links,
  url,
  variant,
  wrapper,
  ...props
}) {
  const baseStyles = {}

  return (
    <Box sx={baseStyles} {...props}>
      <BaseLink url={url} icon={icon} variant={variant}>
        {label}
      </BaseLink>

      {links.length > 0 && (
        <BaseWrapper columns={columns} {...wrapper}>
          {links.map((link, index) =>
            link.wrapper.enabled ? (
              <MenuWrapper key={`sml-${index}`} {...link} px={wrapper.px} />
            ) : (
              <SubSubMenuLink key={`sml-${index}`} {...link} />
            )
          )}
        </BaseWrapper>
      )}
    </Box>
  )
}

SubMenuLink.defaultProps = {
  icon: {},
  columns: '',
  links: [],
  url: {
    path: '#',
  },
  variant: '',
  wrapper: {},
}

SubMenuLink.propTypes = {
  columns: PropTypes.string,
  icon: PropTypes.shape({}),
  label: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.object),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  variant: PropTypes.string,
  wrapper: PropTypes.shape({
    px: PropTypes.string,
  }),
}

export default SubMenuLink
