import React from 'react'
import PropTypes from 'prop-types'
import { Box, Text } from 'theme-ui'

import { Card } from 'ui'

function PMemberCard({ body, image, title, variant, ...props }) {
  return (
    <Card variant={variant} {...props}>
      <Box __css={{ textAlign: 'center' }}>
        <Card.Image
          as='img'
          alt={image.alt}
          src={image.url}
          variant={variant}
          sx={{ maxWidth: '100%' }}
        />
        <Box
          __css={{
            borderBottom: '2px solid',
            color: 'accent',
            mx: '70px',
          }}
        />
      </Box>

      <Card.Content variant={variant}>
        <Card.Title>{title}</Card.Title>
        <Text>{body}</Text>
      </Card.Content>
    </Card>
  )
}

PMemberCard.defaultProps = {
  variant: 'default',
}

PMemberCard.propTypes = {
  body: PropTypes.string.isRequired,
  image: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
  variant: PropTypes.string,
}
export default PMemberCard
