import React from 'react'
import { navigate } from 'gatsby'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { Box, Grid, Input } from 'theme-ui'

import { Collapse, Heading } from 'ui'

import { UPDATE_NODE_LEGAL_PERSON_COTENANT } from '../../mutations'
import { LEGAL_COTENANT_APPLICATION } from '../../queries'

import Control from '../../components/Control'
import BasicShareholdersInformation from '../generic/BasicShareholdersInformation'
import BasicProperties from '../generic/BasicProperties'
import HeaderForm from '../../components/HeaderForm'
import FooterForm from '../../components/FooterForm'
import { getEntity, cleanData } from '../utils'

function ShareholdersInformation(props) {
  const loadedCotenant = props?.loadedCotenant || {}
  const [mutation] = useMutation(UPDATE_NODE_LEGAL_PERSON_COTENANT, {
    refetchQueries: [
      { query: LEGAL_COTENANT_APPLICATION, variables: { id: props.cid } },
    ],
  })
  const { register, handleSubmit, control, errors } = useForm({
    defaultValues: loadedCotenant,
  })

  const onSubmit = async data => {
    const variables = cleanData(data)

    return mutation({ variables })
      .then(response => {
        const entity = getEntity(response)

        if (entity) {
          navigate('?path=/informacion-financiera')
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <section>
      <HeaderForm step='2/4' title='Información Accionistas' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Collapse variant='grayly'>
          <Collapse.Item title='Información Accionistas' index={1}>
            <BasicShareholdersInformation
              name='fieldShareholders'
              {...{ control, register, errors }}
              register={register}
            />
          </Collapse.Item>
        </Collapse>

        <Collapse variant='grayly'>
          <Collapse.Item title='Propiedades' index={1}>
            <BasicProperties register={register} />
          </Collapse.Item>
        </Collapse>

        <Collapse variant='grayly'>
          <Collapse.Item title='Referencias' index={1}>
            <Box mb='medium'>
              <Heading level='3' variant='form'>
                Bancaria (I)
              </Heading>
              <Grid gap={4} columns={[2, '1fr, 1fr']}>
                <Control label='Entidad'>
                  <Input {...register('fieldBankReferences.0.entity')} type='text' />
                </Control>
                <Control label='Sucursal'>
                  <Input
                    {...register('fieldBankReferences.0.branchOffice')}
                    type='text'
                  />
                </Control>
                <Control label='Número de cuenta'>
                  <Input
                    {...register('fieldBankReferences.0.accountNumber')}
                    type='text'
                  />
                </Control>
                <Control label='Tipo de cuenta'>
                  <Input
                    {...register('fieldBankReferences.0.accountType')}
                    type='text'
                  />
                </Control>
              </Grid>
            </Box>

            <Box mb='medium'>
              <Heading level='3' variant='form'>
                Bancaria (II)
              </Heading>
              <Grid gap={4} columns={[2, '1fr, 1fr']}>
                <Control label='Entidad'>
                  <Input {...register('fieldBankReferences.1.entity')} type='text' />
                </Control>
                <Control label='Sucursal'>
                  <Input
                    {...register('fieldBankReferences.1.branchOffice')}
                    type='text'
                  />
                </Control>
                <Control label='Número de cuenta'>
                  <Input
                    {...register('fieldBankReferences.1.accountNumber')}
                    type='text'
                  />
                </Control>
                <Control label='Tipo de cuenta'>
                  <Input
                    {...register('fieldBankReferences.1.accountType')}
                    type='text'
                  />
                </Control>
              </Grid>
            </Box>

            <Box
              sx={{ border: '1px solid', borderColor: 'transparent', mb: 'medium' }}
            >
              <Heading level='3' variant='form'>
                Comercial (I)
              </Heading>
              <Grid gap={4} columns={[2, '1fr, 1fr']}>
                <Control label='Almacén'>
                  <Input {...register('fieldCommercialReferences.0.store')} type='text' />
                </Control>
                <Control label='Teléfono'>
                  <Input {...register('fieldCommercialReferences.0.phone')} type='text' />
                </Control>
              </Grid>
            </Box>

            <Box
              sx={{ border: '1px solid', borderColor: 'transparent', mb: 'medium' }}
            >
              <Heading level='3' variant='form'>
                Comercial (II)
              </Heading>
              <Grid gap={4} columns={[2, '1fr, 1fr']}>
                <Control label='Almacén'>
                  <Input {...register('fieldCommercialReferences.1.store')} type='text' />
                </Control>
                <Control label='Teléfono'>
                  <Input {...register('fieldCommercialReferences.1.phone')} type='text' />
                </Control>
              </Grid>
            </Box>

            <Box
              sx={{ border: '1px solid', borderColor: 'transparent', mb: 'medium' }}
            >
              <Heading level='3' variant='form'>
                Proveedor (I)
              </Heading>
              <Grid gap={4} columns={[2, '1fr, 1fr']}>
                <Control label='Razón social y contacto'>
                  <Input
                    {...register('fieldProviderReferences.0.socialReason')}
                    type='text'
                  />
                </Control>
                <Control label='Teléfono'>
                  <Input {...register('fieldProviderReferences.0.phone')} type='text' />
                </Control>
              </Grid>
            </Box>

            <Box
              sx={{ border: '1px solid', borderColor: 'transparent', mb: 'medium' }}
            >
              <Heading level='3' variant='form'>
                Proveedor (II)
              </Heading>
              <Grid gap={4} columns={[2, '1fr, 1fr']}>
                <Control label='Razón social y contacto'>
                  <Input
                    {...register('fieldProviderReferences.1.socialReason')}
                    type='text'
                  />
                </Control>
                <Control label='Teléfono'>
                  <Input {...register('fieldProviderReferences.1.phone')} type='text' />
                </Control>
              </Grid>
            </Box>

          </Collapse.Item>
        </Collapse>

        <FooterForm back backRedirect={props.uri} />
      </form>
    </section>
  )
}

export default ShareholdersInformation
