import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button } from 'theme-ui'

import { Icon } from 'ui'

function ProjectShare({ whatsappMsg }) {
  const baseStyles = {
    mt: ['xsmall', 'medium'],

    '.container': {
      display: 'none',
    },

    '&:hover .container': {
      display: 'block',
    },
  }

  const shareStyles = {
    display: 'flex',
    px: 'xsmall',

    '&:hover': {
      bg: 'primary',
    },
  }

  const whatStyles = {
    color: 'accent',
    fontSize: 1,

    '&:hover': {
      color: 'white',
    },
  }
  return (
    <Box sx={baseStyles}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Box>
          <Button fluid variant='sharing' mr={3}>
            <Box>
              <Icon name='fi-share' color='accent' mr='xsmall' />
              Compartir
            </Box>
          </Button>
          <Box className='container'>
            <Box
              as='a'
              sx={shareStyles}
              href={`https://api.whatsapp.com/send?text=${whatsappMsg}`}
              target='_blank'
            >
              <Box>
                <Icon sx={{ color: '#5db85c', mr: '8px' }} name='fa-whatsapp' />
              </Box>
              <Box sx={whatStyles}>Whatsapp</Box>
            </Box>
          </Box>
          {/* <Button fluid variant='sharing'>
                  <Icon name='fa-reg-heart' color='error' mr='xsmall' />
                  Guardar
                </Button> */}
        </Box>
      </Box>
    </Box>
  )
}

ProjectShare.propTypes = {
  whatsappMsg: PropTypes.string.isRequired,
}

export default ProjectShare
