import React from 'react'
import PropTypes from 'prop-types'

import { Box } from 'theme-ui'

const CallToActionBody = props => {
  const baseStyles = {
    fontSize: ['14px', '16px'],
    mb: ['medium', null],
  }

  return <Box __css={baseStyles} {...props} />
}

CallToActionBody.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CallToActionBody
