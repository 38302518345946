import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'

import { Icon } from 'ui'

export function Arrow({ icon, ...props }) {
  const baseStyle = {
    bg: 'neutral',
    color: 'white',
    cursor: 'pointer',
    fontSize: '20px',
    height: 50,
    lineHeight: '50px',
    mb: '1px',
    textAlign: 'center',
    transition: 'all .3s linear',
    width: 50,

    '&:hover': {
      bg: 'secondary',
    },
  }

  return (
    <Box sx={baseStyle} {...props}>
      <Icon name={icon} />
    </Box>
  )
}

Arrow.defaultProps = {
  icon: '',
}

Arrow.propTypes = {
  icon: PropTypes.string,
}

export default Arrow
