import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'theme-ui'
import { GatsbyImage } from 'gatsby-plugin-image'

import { Header, Hero, Slider } from 'ui'

const PHeroSlider = ({
  background,
  items,
  title,
  variant,
  slidesToShow,
  showTitles,
  ...props
}) => {
  const [slidesToShowMobile, slidesToShowDesktop] = slidesToShow
  const calcSlidesToShowDesktop =
    items.length < slidesToShowDesktop ? items.length : slidesToShowDesktop

  const settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    customArrows: true,
    infinite: true,
    fade: true,
    speed: 500,
    slidesToShow: calcSlidesToShowDesktop,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          dots: false,
          slidesToShow: slidesToShowMobile,
        },
      },
    ],

    autoHeight: false,
  }

  const baseStyles = {
    backgroundSize: 'cover',
    bg: 'light-2',
    pt: ['3rem', '4rem'],
    pb: 'large',
  }

  return (
    <Hero
      bgImages={[background.src, background.src]}
      sx={baseStyles}
      variant={variant}
      {...props}
    >
      <Header sx={{ color: 'white' }}>{title}</Header>
      <Slider {...settings}>
        {items.map(item => (
          <Box>
            <Box sx={{ height: ['250px', '465px'], overflow: 'hidden' }}>
              <GatsbyImage image={item.gImage} />
            </Box>
            {item?.showTitles && (
              <Hero.Content
                sx={{
                  fontWeight: '600',
                  fontSize: [0, 2],
                  p: ['small', 'medium'],
                }}
              >
                {item.title}
              </Hero.Content>
            )}
          </Box>
        ))}
      </Slider>
    </Hero>
  )
}

PHeroSlider.defaultProps = {
  background: {},
  variant: 'default',
  slidesToShow: [1, 1],
  showTitles: false,
  items: [],
}

PHeroSlider.propTypes = {
  background: PropTypes.oneOf({}),
  items: PropTypes.arrayOf([]),
  slidesToShow: PropTypes.arrayOf([]),
  showTitles: PropTypes.bool,
  title: PropTypes.string.isRequired,
  variant: PropTypes.string,
}

export default PHeroSlider
