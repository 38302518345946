import React from "react";
import clsx from "clsx";
import "./styles.css";

interface ChipProps {
  active?: boolean;

  href?: string;
  type?: "button" | "submit";
  onClick: () => void;
  className?: string;
  children?: React.ReactNode;
}

const Chip: React.FC<ChipProps> = ({
  active = false,

  className,
  ...props
}) => {
  const dynamicClasses = {
    "s2-chip--active": active,
  };

  const ElementTag = props?.href ? "a" : "button";

  return (
    <ElementTag
      className={clsx("s2-chip", dynamicClasses, className)}
      {...props}
    >
      {props.children}
    </ElementTag>
  )
}

export default Chip;