import React from "react";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Breadcrumb, { BreadcrumbItem } from "@coninsa-s2/breadcrumb";
import Heading from "@coninsa-s2/heading";

export default function LeasesWithoutCoTenant() {
  const breadcrumbItems = [
    {
      label: "Inicio",
      url: "/terminos-y-condiciones",
    },
    {
      label: "Términos y condiciones generales",
      url: "/terminos-y-condiciones/generales",
    },
    {
      label: "Arrendamientos sin coarrendatario",
    },
  ];

  return (
    <Section>
      <Container>
        <div className="mb-8">
          <Breadcrumb>
            {breadcrumbItems.map((item) => (
              <BreadcrumbItem>
                {item.url && <a href={item.url}>{item.label}</a>}
                {!item.url && item.label}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        </div>

        <div className="mb-7">
          <span className="text-lg text-s2-dark-blue lg:text-[22px]">
            Términos y condiciones
          </span>
          <Heading size="2xl">Arrendamientos sin coarrendatario</Heading>
        </div>

        <div className="prose">
          <p>
            Estos términos y condiciones están destinados a regular el servicio
            de arrendamiento sin coarrendatario ofrecido por Coninsa Ramón H, a
            clientes potenciales interesados en arrendar inmuebles disponibles
            en el Kardex publicado en las diferentes plataformas que usa la
            compañía para promocionarlos bajo las siguientes condiciones:
          </p>

          <div>
            <ul>
              <li>Aplica para contratos de arriendo de vivienda.</li>
              <li>Los contratos celebrados deben ser mínimo de 12 meses.</li>
              <li>
                Aplica para cánones de vivienda hasta $3.000.000 (este valor
                máximo debe incluir el valor de la cuota de administración de la
                copropiedad).
              </li>
              <li>
                El servicio solo aplica para los negocios realizados en las
                regionales de Bogotá y Medellín (incluye área metropolitana).
              </li>
            </ul>

            <div>
              <h4>Ingresos para tomar el producto:</h4>
              <ul>
                <li>
                  El valor del canon no puede ser mayor al 35% de los ingresos,
                  ejemplo: para arrendar una vivienda cuyo canon sea de
                  $2.000.000 los ingresos familiares deben ser mínimo de
                  $5.700.000 ($2 MM / $5.7 MM = 35%) estos ingresos pueden ser
                  de una o dos personas de la familia.
                </li>
                <li>No debe tener reportes en centrales de riesgo</li>
                <li>
                  No aplica para personas que devenguen sus ingresos como
                  independientes, debe existir un vínculo laboral.
                </li>
                <li>
                  Si el arrendatario se presenta solo y cumple con el requisito
                  de los ingresos debe presentar un delegado que diligencie el
                  formulario solicitud de arriendo y al contrato de arriendo se
                  le agrega la siguiente cláusula:
                </li>
              </ul>
            </div>

            <div>
              <h4>
                CLÁUSULA: DELEGACIÓN PARA RESTITUIR O DEVOLVER EL INMUEBLE
              </h4>
              <p>
                Las partes que suscriben el contrato de arrendamiento, aceptan
                expresamente que ______________________, identificado (a) con
                Cédula de Ciudadanía No. __________, en adelante “EL DELEGADO”,
                es quien representa a EL ARRENDATARIO para todos los efectos de
                la restitución o devolución del inmueble objeto del presente
                contrato. Igualmente, las partes acuerdan que EL DELEGADO está
                facultado para entregar el inmueble a EL ARRENDADOR, su
                cesionario o su subrogatorio, o a quien ellos designen, conforme
                a los parámetros establecidos en el presente contrato. La
                devolución del inmueble se efectuará a la finalización del
                contrato por terminación de la vigencia, por mutuo acuerdo o en
                caso de incumplimiento en el pago del canon de arrendamiento,
                cuotas de administración e IVA comercial, si aplicaren.
                _______________ suscribe el presente contrato en señal de
                aceptación de su delegación y manifiesta que, por el hecho de
                aceptar el encargo descrito, dicha aceptación no le hace deudor
                (a) solidario(a) del negocio jurídico. Igualmente, manifiesta
                que podrá ser localizado en la ________, (Dirección) __________
                (Ciudad) Celular ________ y correo electrónico _________.
              </p>
            </div>

            <div>
              <h4>Documentos para presentar la solicitud de arrendamiento:</h4>
              <div>
                <h4>Para persona natural:</h4>
                <ul>
                  <li>
                    Formulario debidamente diligenciado (puede hacerlo a través
                    de nuestro servicio de estudio digital
                    https://www.coninsa.co/solicitud-de-arrendamiento)
                  </li>
                  <li>Certificación laboral no mayor a 30 días</li>
                  <li>Documento de identidad</li>
                  <li>Extractos bancarios últimos 3 meses</li>
                  <li>RUT o carta de no RUT</li>
                </ul>
              </div>
              <div>
                <h4>Para persona Jurídica:</h4>
                <ul>
                  <li>
                    Formulario debidamente diligenciado (puede hacerlo a través
                    de nuestro servicio de estudio digital
                    https://www.coninsa.co/solicitud-de-arrendamiento)
                  </li>
                  <li>
                    Certificado de existencia y representación no mayor a 30
                    días
                  </li>
                  <li>Documento de identidad del representante legal</li>
                  <li>Extractos bancarios últimos 3 meses</li>
                  <li>Estados financieros de los últimos 2 años contables</li>
                  <li>RUT</li>
                  <li>Fotocopia última declaración de renta</li>
                </ul>
              </div>
              <div>
                <h4>Resultado del estudio de solicitud:</h4>
                <ul>
                  <li>
                    Si el estudio de la solicitud cumple con los términos y
                    condiciones establecidos se procede a elaborar los contratos
                    para firma.
                  </li>
                  <li>
                    Si el resultado del estudio no cumple con los términos y
                    condiciones establecidos se solicitarán los documentos y
                    requisitos que exijan las aseguradoras y/o afianzadoras con
                    la cual se respalde el contrato.
                  </li>
                  <li>
                    El valor del estudio no es reembolsable en ningún caso.
                  </li>
                </ul>
              </div>
              <div>
                <h4>Vigencia y cambios:</h4>
                <ul>
                  <li>
                    Aplican las condiciones de estudio en Coninsa Ramón H.
                  </li>
                  <li>
                    Vigencia: aplica a partir del 18 de noviembre de 2020.
                  </li>
                  <li>
                    Coninsa Ramón H. en cualquier momento puede cambiar los
                    términos y condiciones del producto sin previo aviso.
                  </li>
                  <li>
                    Coninsa Ramón H. puede suspender temporal o definitivamente
                    el producto sin previo aviso.
                  </li>
                  <li>
                    Coninsa Ramón H. no se hace responsable si las afianzadoras
                    y/o aseguradoras al momento del estudio solicitan al cliente
                    arrendatario presentar requisitos adicionales incluido uno o
                    más coarrendatarios al no cumplir con los términos y
                    condiciones establecidas para este servicio.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
}
