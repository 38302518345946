import houseImage from '../assets/images/award-2023.jpg'
import buildingImage from '../assets/images/award-2021.jpg'

export default [
  {
    image: buildingImage,
    tag: 'Arquitectura y grandes obras',
    title: 'Construyendo conocimiento a través de espacios educativos innovadores',
    date: 'Jun 05, 2023',
    summary:
      'La arquitectura en el sector de la educación, busca crear espacios que sean flexibles y adaptados.',
  },

  {
    image: houseImage,
    tag: 'Arquitectura y grandes obras',
    title: 'Diseñamos el Nuevo Palacio Municipal de Envigado',
    date: 'Aug 12, 2022',
    summary:
      'Conoce más detalles de este diseño del Taller de Arquitectura Coninsa.',
  },

  {
    image: buildingImage,
    tag: 'Arquitectura y grandes obras',
    title: 'Nueva sede del Colegio Palermo ¡Conócelo!',
    date: 'Feb 08, 2022',
    summary:
      'Conoce más detalles de esta obra de 6 plantas que fortalece el ecosistema educativo.',
  },
]
