import React from "react";

import Banner from "./src/sections/banner";
import ListCard from "./src/sections/list-card";
import PropertyBody from "./src/sections/property-body";

function ReferProperties() {
  return (
    <>
      <Banner />
      <div className="bg-gradient-to-b from-s2-dark-blue from-50% to-white to-50%">
        <div className="lg:-translate-y-12">
          <ListCard />
        </div>
      </div>
      <PropertyBody />
    </>
  );
}

export default ReferProperties;
