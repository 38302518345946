import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { Field, Select } from 'ui/form'
import { useForm, Controller } from 'react-hook-form'

import { Box, Checkbox, Input, Label, Textarea } from 'theme-ui'
import IntlTelInput from 'react-intl-tel-input'

import { WEBFORM_SUBMIT } from '../../../../dynamic/forms/mutations'
import handIcon from '../../assets/icons/hand.svg'

const services = [
  { value: 'diseño arquitectonico', label: 'Diseño arquitectónico' },
  { value: 'diseños integrados', label: 'Diseños integrados' },
  { value: 'construccion', label: 'Construcción' },
  {
    value: 'diseños integrados y construccion',
    label: 'Diseños integrados y construcción',
  },
  {
    value: "estructuracion de proyectos",
    label: "Estructuración de proyectos",
  },
]

function ContactForm() {
  const {
    control,
    formState: {},
    register,
    handleSubmit,
    reset,
    errors,
  } = useForm({})

  const [submitted, setSubmitted] = useState(false)
  const [phone, setPhone] = useState()
  const [webformSubmit] = useMutation(WEBFORM_SUBMIT)

  const onChange = (_, phoneNumber, country) => {
    const { dialCode } = country
    const expression = `\\+${dialCode}`
    const regex = new RegExp(expression)
    const withoutDial = phoneNumber.replace(regex, '')
    const internationalNumber = `+${country.dialCode}` + `${withoutDial}`
    setPhone(internationalNumber)
  }

  const onSubmit = async data => {
    const { fullName, country, email, interestService, comments } = data

    const normalizedData = {
      full_name: fullName,
      country,
      email,
      interest_service: interestService,
      comments,
      phone,
      webform_id: 'construction_microsite',
    }

    const webformData = JSON.stringify(normalizedData)

    return new Promise((resolve, reject) => {
      webformSubmit({
        variables: {
          values: webformData,
        },
      })
        .then(response => {
          const { errors: errorsForm } = response.data.submitForm

          if (errorsForm.length) {
            console.error(errorsForm)
            return
          }

          reset()
          setSubmitted(true)
          resolve(response)
        })
        .catch(error => {
          console.error(error.message)
          reject(error)
        })
    })
  }

  const phoneStyles = {
    mb: ['xsmall', 'none'],
    '.allow-dropdown.intl-tel-input': { display: 'block' },
    input: {
      width: '100%',
      border: '1px solid #d0d1d1',
      borderRadius: '4px',
      lineHeight: 'inherit',
      py: '12px',
    },
  }

  const checkboxStyles = {
    'input:checked ~ &': {
      color: 'accent',
    },

    'input:focus ~ &': {
      color: 'accent',
    },
  }

  return (
    <div>
      {!submitted && (
        <form className='flex flex-col gap-4' onSubmit={handleSubmit(onSubmit)}>
          <p className='font-bold mb-5 text-coninsa-gray-900 text-lg text-center'>
            Comunícate con nosotros para cotizar tu proyecto
          </p>
          <Field error={errors?.fullName} sx={{ mb: 0 }}>
            <Input
              placeholder='Tu nombre completo'
              type='text'
              {...register('fullName')}
              required
            />
          </Field>

          <Input
            {...register('country')}
            type='text'
            placeholder='País de interés'
            required
          />

          <Input
            {...register('email')}
            type='email'
            placeholder='Correo electrónico'
            required
          />

          <Box sx={phoneStyles}>
            <IntlTelInput
              {...register('phone')}
              type='tel'
              placeholder='Número de contacto'
              defaultCountry='co'
              onPhoneNumberChange={onChange}
              telInputProps={{ required: true }}
            />
          </Box>

          <Field error={errors?.interestService} sx={{ mb: 0 }}>
            <Controller
              control={control}
              name='interestService'
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select
                  onBlur={onBlur}
                  checked={value}
                  inputRef={ref}
                  options={services}
                  control={control}
                  placeholder='Servicio de interés'
                  onChange={selected => {
                    if (selected) {
                      onChange(selected.value)
                    }
                  }}
                />
              )}
            />
          </Field>

          <Field>
            <Textarea
              rows={2}
              placeholder='Motivo de contacto'
              {...register('comments')}
              required
            />
          </Field>

          <Label sx={{ alignItems: 'center', mt: 'small' }}>
            <Checkbox sx={checkboxStyles} required />
            <Box
              sx={{
                fontSize: [0, 1],
              }}
            >
              Autorizo el tratamiento y la
              <Box
                as='a'
                href='https://www.coninsa.co/politica-de-tratamiento-de-datos-personales-de-coninsa-ramon-h-sa'
                sx={{ color: 'accent', ml: '3px', textDecoration: 'underline' }}
                target='_blank'
              >
                política de datos personales
              </Box>
            </Box>
          </Label>

          <button
            type='submit'
            className='bg-coninsa-green-300 rounded-lg font-bold py-3 text-sm text-white uppercase md:text-base md:py-4 hover:bg-coninsa-green-500'
          >
            Contactarme
          </button>
        </form>
      )}

      {submitted && (
        <div className='text-center'>
          <img src={handIcon} className='my-0 mx-auto' />
          <h3 className='font-bold mb-3 text-xl'>
            ¡Gracias por comunicarte con nosotros!
          </h3>
          <p className=''>
            Pronto nuestro especialista se pondrá en contacto para hablar sobre tu
            proyecto.
          </p>
        </div>
      )}
    </div>
  )
}

export default ContactForm
